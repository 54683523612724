<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side">
      <div class="Nav_main" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner wbpro-scrollbar"
        >
          <h2 class="Nav_title">{{ $t("message.popular") }}</h2>
          <div>
            <div>
              <a
                @click="setNavTab2(1)"
                class="router-link-exact-active router-link-active ALink_default"
              >
                <div
                  :class="[
                    navTab2Show == 1
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.popular')"
                >
                  <flame-sharp
                    class="keepfly-font keepfly-font--navHot NavItem_icon"
                  ></flame-sharp>
                  <span class="NavItem_text">{{ $t("message.popular") }}</span>
                </div>
              </a>
              <a @click="setNavTab2(2)" class="router-link-active ALink_default">
                <div
                  :class="[
                    navTab2Show == 2
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.topic')"
                >
                  <svg
                    class="keepfly-font keepfly-font--navTime NavItem_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 15H7.3l-2.55-3.5V15H3.5V9h1.25l2.5 3.5V9H8.5v6zm5-4.74H11v1.12h2.5v1.26H11v1.11h2.5V15h-4V9h4v1.26zm7 3.74c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9h1.25v4.51h1.13V9.99h1.25v3.51h1.12V9h1.25v5z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span class="NavItem_text">{{ $t("message.topic") }}</span>
                </div>
              </a>
              <a @click="setNavTab2(3)" class="ALink_default">
                <div
                  :class="[
                    navTab2Show == 3
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.goldenSentenceList')"
                >
                  <sparkles-sharp
                    class="keepfly-font keepfly-font--navTopic NavItem_icon"
                  ></sparkles-sharp>
                  <span class="NavItem_text">{{ $t("message.goldenSentenceList") }}</span>
                </div>
              </a>
              <a @click="setNavTab2(4)" class="ALink_default">
                <div
                  :class="[
                    navTab2Show == 4
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.askQuestions')"
                >
                  <help-circle-outline
                    class="keepfly-font keepfly-font--navSearch NavItem_icon"
                  ></help-circle-outline>
                  <span class="NavItem_text">{{ $t("message.askQuestions") }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" v-if="navTab2Show == 1">
          <div>
            <div class="SecBar_secBar SecBar_visable">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="wbpro-tab2 u-col-8">
                  <div
                    :class="[
                      index == tagnum
                        ? 'keepfly-box-item-inlineBlock cur'
                        : 'keepfly-box-item-inlineBlock',
                    ]"
                    :style="{ display: index < 7 ? 'inline-block' : 'none' }"
                    v-for="(item, index) in tagArr"
                    :key="item"
                  >
                    <div
                      v-if="index < 7"
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameAr, index)"
                        :title="item.nameAr"
                        v-if="item.nameAr"
                      >
                        {{ item.nameAr }}
                      </div>
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameEn, index)"
                        :title="item.nameEn"
                        v-if="item.nameEn"
                      >
                        {{ item.nameEn }}
                      </div>
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameJa, index)"
                        :title="item.nameJa"
                        v-if="item.nameJa"
                      >
                        {{ item.nameJa }}
                      </div>
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameKo, index)"
                        :title="item.nameKo"
                        v-if="item.nameKo"
                      >
                        {{ item.nameKo }}
                      </div>
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameRu, index)"
                        :title="item.nameRu"
                        v-if="item.nameRu"
                      >
                        {{ item.nameRu }}
                      </div>
                      <div
                        class="wbpro-textcut"
                        @click="setTagLable(item.nameZh, index)"
                        :title="item.nameZh"
                        v-if="item.nameZh"
                      >
                        {{ item.nameZh }}
                      </div>
                    </div>
                  </div>
                  <div class="keepfly-box-item-inlineBlock">
                    <div
                      :title="$t('message.more')"
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <n-dropdown
                        trigger="hover"
                        :options="tagmoreoptions"
                        @select="tagmorehandleSelect"
                      >
                        <menu-sharp
                          class="keepfly-font keepfly-font--more"
                          style="width: 26px"
                        ></menu-sharp>
                      </n-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <div class="Scroll_container">
                    <div class="Scroll_wrap">
                      <div>
                        <div
                          class="vue-recycle-scroller ready page-mode direction-vertical"
                        >
                          <div class="vue-recycle-scroller__item-wrapper">
                            <div class="" v-if="microblogs.length > 0">
                              <div
                                class="wbpro-scroller-item"
                                v-for="item in microblogs"
                                :key="item"
                              >
                                <article
                                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                                  style="border-radius: 4px"
                                >
                                  <div class="Feed_body">
                                    <header class="keepfly-box-flex">
                                      <a
                                        class="ALink_default"
                                        @click="checkUserInfo(item, 1)"
                                      >
                                        <div
                                          class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                          style="width: 3.25rem; height: 3.25rem"
                                        >
                                          <img
                                            :src="item.avatar"
                                            class="keepfly-avatar-img"
                                          />
                                          <div class="keepfly-avatar-hoverMask"></div>
                                        </div>
                                      </a>
                                      <div class="keepfly-box-item-flex head_main">
                                        <div
                                          class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                          >
                                            <a class="ALink_default head_cut head_name">
                                              <span :title="item.nickname">{{
                                                item.nickname
                                              }}</span>
                                            </a>
                                          </div>
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter head-info_info"
                                          >
                                            <a
                                              :title="item.creatTime"
                                              class="head-info_time"
                                            >
                                              {{ item.creatTime }}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-box-flex"
                                        v-if="item.uid == infodata.id"
                                      >
                                        <button
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                      <div
                                        class="keepfly-box-flex"
                                        v-else-if="item.friendUid == null"
                                      >
                                        <button
                                          @click="addfriend(item, 2)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <!-- {{ $t("message.follow") }} -->
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                      <div class="keepfly-box-flex" v-else>
                                        <button
                                          @click="cancelfollow(item, 2)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <!-- {{ $t("message.tips32") }} -->
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 640 512"
                                              >
                                                <path
                                                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104l-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </header>
                                    <div class="wbpro-feed-content">
                                      <div
                                        class="detail_text detail_ogText wbpro-feed-ogText"
                                      >
                                        <n-ellipsis
                                          expand-trigger="click"
                                          line-clamp="4"
                                          :tooltip="false"
                                        >
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentAr"
                                            v-html="filters(item.contentAr)"
                                          ></div>
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentEn"
                                            v-html="filters(item.contentEn)"
                                          ></div>
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentJa"
                                            v-html="filters(item.contentJa)"
                                          ></div>
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentKo"
                                            v-html="filters(item.contentKo)"
                                          ></div>
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentRu"
                                            v-html="filters(item.contentRu)"
                                          ></div>
                                          <div
                                            class="detail_wbtext"
                                            v-if="item.contentZh"
                                            v-html="filters(item.contentZh)"
                                          ></div>
                                        </n-ellipsis>
                                      </div>
                                      <div class="picture picture-box_row">
                                        <span
                                          v-for="titem in item.tags"
                                          :key="titem"
                                          style="
                                            color: #ff8200;
                                            padding-right: 10px;
                                            font-size: 12px;
                                          "
                                          :title="titem"
                                        >
                                          {{ uqTxtTranslate(titem) }}
                                        </span>
                                      </div>
                                      <div class="picture picture-box_row">
                                        <n-image-group>
                                          <n-space>
                                            <n-image
                                              width="133"
                                              height="133"
                                              v-for="oitem in item.images"
                                              :key="oitem"
                                              :src="oitem"
                                              style="border-radius: 15px"
                                            />
                                          </n-space>
                                        </n-image-group>
                                      </div>
                                      <div v-if="item.videoInfo">
                                        <video
                                          controls="controls"
                                          :src="item.videoInfo"
                                          class="video"
                                          style="
                                            width: 400px;
                                            vertical-align: middle;
                                            height: 225px;
                                            background: #000;
                                            border-radius: 10px;
                                          "
                                        ></video>
                                      </div>
                                    </div>
                                  </div>
                                  <footer aria-label="3233,88,4280" class="">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                                    >
                                      <div
                                        class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                        @click="handleConfirm(item.id)"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        >
                                          <div class="keepfly-pop-wrap">
                                            <span class="keepfly-pop-ctrl">
                                              <div
                                                class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                              >
                                                <div
                                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                                >
                                                  <share-outline
                                                    class="keepfly-font keepfly-font--retweet toolbar_retweetIcon"
                                                  ></share-outline>
                                                </div>
                                                <span class="toolbar_num">{{
                                                  item.fnum == null ? 0 : item.fnum
                                                }}</span>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                        @click="getCommentView(item, 1)"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                          >
                                            <ellipsis-horizontal-circle
                                              class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                            ></ellipsis-horizontal-circle>
                                          </div>
                                          <span class="toolbar_num">
                                            {{ item.cnum == null ? 0 : item.cnum }}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                        >
                                          <button
                                            @click="likeClick(item)"
                                            class="keepfly-like-main toolbar_btn"
                                          >
                                            <svg
                                              style="width: 19px"
                                              class="keepfly-like-iconWrap"
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 32 32"
                                            >
                                              <path
                                                d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                            <span class="keepfly-like-count">{{
                                              item.lnum == null ? 0 : item.lnum
                                            }}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </footer>
                                  <div v-if="item.show">
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                    ></div>
                                    <div class="Feed_box">
                                      <div class="keepfly-box-flex Feed_mar2">
                                        <div
                                          class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                          style="width: 2.125rem; height: 2.125rem"
                                        >
                                          <img
                                            :src="infodata.avatar"
                                            class="keepfly-avatar-img"
                                          />
                                          <div class="keepfly-avatar-hoverMask"></div>
                                        </div>
                                        <div class="keepfly-box-item-flex">
                                          <div>
                                            <div>
                                              <div
                                                class="wbpro-form Form_wbproform"
                                                compser="true"
                                              >
                                                <textarea
                                                  :placeholder="$t('message.tips31')"
                                                  v-model="item.commentValue"
                                                  maxlength="100"
                                                  class="Form_input"
                                                  style="height: 84px"
                                                ></textarea>
                                              </div>
                                            </div>
                                            <div class="Composer_mar1">
                                              <div
                                                class="keepfly-box-flex keepfly-box-alignCenter"
                                              >
                                                <button
                                                  @click="sendInfoComment(item)"
                                                  class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                                >
                                                  <span class="keepfly-button-wrap">
                                                    <span
                                                      class="keepfly-button-content"
                                                      >{{ $t("message.comment") }}</span
                                                    >
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                      ></div>
                                      <div>
                                        <div>
                                          <div class="RepostCommentFeed_mar1">
                                            <div class="wbpro-list">
                                              <div
                                                class="item1"
                                                v-for="oitem in item.commentVos"
                                                :key="oitem"
                                              >
                                                <div class="item1in keepfly-box-flex">
                                                  <div>
                                                    <a
                                                      class="ALink_default"
                                                      @click="checkUserInfo(oitem, 2)"
                                                    >
                                                      <div
                                                        class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                        style="
                                                          width: 2.125rem;
                                                          height: 2.125rem;
                                                        "
                                                      >
                                                        <img
                                                          :src="oitem.fromUser.avatar"
                                                          class="keepfly-avatar-img"
                                                        />
                                                      </div>
                                                    </a>
                                                  </div>
                                                  <div class="con1 keepfly-box-item-flex">
                                                    <div class="text">
                                                      <a class="ALink_default">{{
                                                        oitem.fromUser.nickname
                                                      }}</a>
                                                      <a
                                                        class="ALink_none"
                                                        target="_blank"
                                                      ></a
                                                      >:
                                                      <span v-if="oitem.contentAr">{{
                                                        oitem.contentAr
                                                      }}</span>
                                                      <span v-if="oitem.contentEn">{{
                                                        oitem.contentEn
                                                      }}</span>
                                                      <span v-if="oitem.contentJa">{{
                                                        oitem.contentJa
                                                      }}</span>
                                                      <span v-if="oitem.contentKo">{{
                                                        oitem.contentKo
                                                      }}</span>
                                                      <span v-if="oitem.contentRu">{{
                                                        oitem.contentRu
                                                      }}</span>
                                                      <span v-if="oitem.contentZh">{{
                                                        oitem.contentZh
                                                      }}</span>
                                                    </div>
                                                    <div
                                                      class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                                    >
                                                      <div>
                                                        {{ oitem.createDate }}
                                                      </div>
                                                      <div class="opt keepfly-box-flex">
                                                        <!-- <div
                                                          class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter optHover"
                                                        >
                                                          <alert-circle-outline
                                                            style="width: 22px"
                                                            class="keepfly-font keepfly-font--report"
                                                          ></alert-circle-outline>
                                                        </div> -->
                                                        <div
                                                          class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                        >
                                                          <button
                                                            class="keepfly-like-main IconList_likebox"
                                                            @click="
                                                              commentVoslikeClick(oitem)
                                                            "
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              style="width: 22px"
                                                              class="keepfly-like-iconWrap"
                                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                                              viewBox="0 0 32 32"
                                                            >
                                                              <path
                                                                d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                                fill="currentColor"
                                                              ></path>
                                                            </svg>
                                                            <span
                                                              class="keepfly-like-count"
                                                              >{{
                                                                oitem.lnum == null
                                                                  ? 0
                                                                  : oitem.lnum
                                                              }}</span
                                                            >
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                          ></div>
                                          <a
                                            class="ALink_default"
                                            @click="morecommentVos(item)"
                                          >
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                            >
                                              <div
                                                v-if="
                                                  item.commentVos != null &&
                                                  item.commentVos.length < item.total
                                                "
                                                style="color: #ccc"
                                              >
                                                {{ $t("message.more") }}
                                              </div>
                                              <div style="color: #ccc" v-else>
                                                {{ $t("message.tips74") }}
                                              </div>
                                              <i
                                                class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                              ></i>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                            <div v-else>
                              <div style="background: #fff; padding: 40px 10px">
                                <n-empty :description="$t('message.none')">
                                  <template #extra></template>
                                </n-empty>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab2Show == 2">
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    v-if="TopicArr.length > 0"
                  >
                    <div v-for="(item, index) in TopicArr" :key="item">
                      <div class="wbpro-scroller-item">
                        <div
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                        >
                          <div class="HotTopic_item">
                            <div class="keepfly-box-flex">
                              <div class="keepfly-box-item-flex">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                >
                                  <div
                                    v-if="index == 0"
                                    class="HotTopic_rank1 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div
                                    v-if="index == 1"
                                    class="HotTopic_rank2 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div
                                    v-if="index == 2"
                                    class="HotTopic_rank3 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div v-if="index > 2" class="HotTopic_rankimg">
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicAr"
                                    v-if="item.topicAr"
                                    >#{{ item.topicAr }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicEn"
                                    v-if="item.topicEn"
                                    >#{{ item.topicEn }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicJa"
                                    v-if="item.topicJa"
                                    >#{{ item.topicJa }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicKo"
                                    v-if="item.topicKo"
                                    >#{{ item.topicKo }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicRu"
                                    v-if="item.topicRu"
                                    >#{{ item.topicRu }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.topicZh"
                                    v-if="item.topicZh"
                                    >#{{ item.topicZh }}</a
                                  >
                                  <div></div>
                                </div>
                                <div
                                  class="wbpro-cutword HotTopic_margin HotTopic_wbtext"
                                  v-html="item.topicDescription"
                                ></div>
                                <div class="HotTopic_num">
                                  <span
                                    >{{ $t("message.read") }}：{{ item.readCount }}</span
                                  >
                                  <span
                                    >{{ $t("message.join") }}：{{
                                      item.attendCount
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div
                                class="keepfly-box-flex HotTopic_right"
                                v-if="item.topicPic"
                              >
                                <div class="HotTopic_video">
                                  <div
                                    class="keepfly-picture-main keepfly-picture-square keepfly-picture-hover HotTopic_img"
                                  >
                                    <img
                                      :src="item.topicPic"
                                      class="keepfly-picture-img"
                                    />
                                    <div class="keepfly-picture-cover"></div>
                                    <div class="keepfly-picture-hoverMask"></div>
                                    <div class="keepfly-picture-slot">
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter HotTopic_pos"
                                      >
                                        <i
                                          class="keepfly-font keepfly-font--play HotTopic_icon2"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="background: #fff; padding: 40px 10px">
                      <n-empty :description="$t('message.none')">
                        <template #extra></template>
                      </n-empty>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab2Show == 3">
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    v-if="goldenarr.length > 0"
                  >
                    <!-- <div v-for="(item, index) in goldenarr" :key="item">
                      <div class="wbpro-scroller-item">
                        <div
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                        >
                          <div class="HotTopic_item">
                            <div class="keepfly-box-flex">
                              <div class="keepfly-box-item-flex">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                >
                                  <div
                                    v-if="index == 0"
                                    class="HotTopic_rank1 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div
                                    v-if="index == 1"
                                    class="HotTopic_rank2 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div
                                    v-if="index == 2"
                                    class="HotTopic_rank3 HotTopic_rankimg"
                                  >
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <div v-if="index > 2" class="HotTopic_rankimg">
                                    <span class="HotTopic_ranknum">{{ index + 1 }}</span>
                                  </div>
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentAr"
                                    v-if="item.contentAr"
                                    >{{ item.contentAr }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentEn"
                                    v-if="item.contentEn"
                                    >{{ item.contentEn }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentJa"
                                    v-if="item.contentJa"
                                    >{{ item.contentJa }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentKo"
                                    v-if="item.contentKo"
                                    >{{ item.contentKo }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentRu"
                                    v-if="item.contentRu"
                                    >{{ item.contentRu }}</a
                                  >
                                  <a
                                    class="ALink_default HotTopic_tit"
                                    :title="item.contentZh"
                                    v-if="item.contentZh"
                                    >{{ item.contentZh }}</a
                                  >
                                  <div>{{ item.lnum == null ? 0 : item.lnum }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div v-for="item in goldenarr" :key="item">
                      <div class="sentence-card-wrap list-item">
                        <div class="content content-limit">
                          <div data-v-65785b50="" class="c-highlight">
                            <div data-v-65785b50="">
                              <n-ellipsis
                                expand-trigger="click"
                                line-clamp="2"
                                :tooltip="false"
                              >
                                <span :title="item.contentAr" v-if="item.contentAr">{{
                                  item.contentAr
                                }}</span>
                                <span :title="item.contentEn" v-if="item.contentEn">{{
                                  item.contentEn
                                }}</span>
                                <span :title="item.contenta" v-if="item.contentJa">{{
                                  item.contentJa
                                }}</span>
                                <span :title="item.contentKo" v-if="item.contentKo">{{
                                  item.contentKo
                                }}</span>
                                <span :title="item.contentRu" v-if="item.contentRu">{{
                                  item.contentRu
                                }}</span>
                                <span :title="item.contentZh" v-if="item.contentZh">{{
                                  item.contentZh
                                }}</span>
                              </n-ellipsis>
                            </div>
                          </div>
                        </div>
                        <div class="tool-wrap">
                          <div class="tool-left">
                            <div class="operate-item">
                              <div
                                @click="checkUserInfo(item, 3)"
                                class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                style="width: 2.125rem; height: 2.125rem"
                              >
                                <img
                                  :src="item.avatar"
                                  style="vertical-align: middle"
                                  class="keepfly-avatar-img"
                                />
                              </div>
                              {{ item.nickname }}
                            </div>
                            <div class="operate-item" @click="goldenlikeClick(item)">
                              <svg
                                class="tool-icon like"
                                xmlns="http://www.w3.org/2000/svg"
                                style="width: 22px"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <div>{{ item.lnum == null ? 0 : item.lnum }}</div>
                            </div>
                          </div>
                          <div
                            @click="copyCode(item)"
                            :title="$t('message.copy')"
                            class="copy"
                          >
                            <!-- {{ $t("message.copy") }} -->
                            <svg
                              style="width: 19px; padding-top: 6px"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 16 16"
                            >
                              <g fill="none">
                                <path
                                  d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
                                  fill="currentColor"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="background: #fff; padding: 40px 10px">
                      <n-empty :description="$t('message.none')">
                        <template #extra></template>
                      </n-empty>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab2Show == 4">
          <div class="Scroll_container" v-if="indexdetails == 1">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    v-if="asklistarr.length > 0"
                  >
                    <div v-for="item in asklistarr" :key="item">
                      <div class="wbpro-scroller-item">
                        <div
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                        >
                          <div class="HotTopic_item">
                            <div class="keepfly-box-flex">
                              <div class="keepfly-box-item-flex">
                                <div class="keepfly-box-flex keepfly-box-alignCenter">
                                  <div class="HotTopic_doticon"></div>
                                  <a
                                    class="ALink_default HotTopic_tit_ask"
                                    :title="item.question"
                                    @click="questionView(item, 2)"
                                    >{{ item.question }}</a
                                  >
                                  <div>
                                    <button
                                      @click="addAnswerView(item)"
                                      class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s_3J5Ve"
                                    >
                                      <span class="keepfly-button-wrap">
                                        <span class="keepfly-button-content">
                                          {{ $t("message.quick_answer") }}
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <n-pagination
                      v-model:page="curraskPage"
                      size="medium"
                      :page-count="asklistPage"
                      :page-slot="7"
                      @update-page="onasklistChange"
                    />
                    <div style="height: 36px"></div>
                  </div>
                  <div v-else>
                    <div style="background: #fff; padding: 40px 10px">
                      <n-empty :description="$t('message.none')">
                        <template #extra></template>
                      </n-empty>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Scroll_container" v-if="indexdetails == 2">
            <div class="Scroll_wrap">
              <div>
                <div
                  style="padding: 8px 0"
                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                >
                  <div
                    @click="indexdetails = 1"
                    class="keepfly-box-flex keepfly-box-alignCenter Bar_left Bar_hand"
                  >
                    <chevron-back-outline
                      style="width: 24px"
                      class="keepfly-font keepfly-font--angleLeft Bar_back"
                    ></chevron-back-outline>
                    <div class="Bar_title">{{ $t("message.return") }}</div>
                    <h2 class="Bar_title"></h2>
                  </div>
                </div>
                <div
                  style="padding: 8px 10px"
                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                >
                  {{ $t("message.question") }}：{{ questionInfo.question }}
                  <div style="display: inline-block; float: right">
                    <button
                      @click="addAnswerView(questionInfo)"
                      class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s_3J5Ve"
                    >
                      <span class="keepfly-button-wrap">
                        <span class="keepfly-button-content">
                          {{ $t("message.quick_answer") }}
                        </span>
                      </span>
                    </button>
                  </div>
                  <div style="clear: both"></div>
                </div>
                <div class="vue-recycle-scroller ready page-mode direction-vertical">
                  <div class="vue-recycle-scroller__item-wrapper">
                    <div v-if="AskAnswerlist.length > 0">
                      <div
                        class="wbpro-scroller-item"
                        v-for="item in AskAnswerlist"
                        :key="item"
                      >
                        <article
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                          style="border-radius: 4px"
                        >
                          <div class="Feed_body">
                            <div>
                              <div
                                class="detail_ogText wbpro-feed"
                                style="position: relative"
                              >
                                <div class="detail_wbtext">{{ item.answer }}</div>
                                <div style="position: absolute; top: 0; right: 10px">
                                  {{ item.status == 1 ? $t("message.recommendo") : "" }}
                                </div>
                              </div>
                            </div>
                            <header class="keepfly-box-flex" style="margin-bottom: 8px">
                              <div
                                class="keepfly-box-item-flex head_main"
                                style="text-align: right"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                >
                                  <div class="head_nick">
                                    <a class="ALink_default head_cut head_name">
                                      <span :title="item.nickname">{{
                                        item.nickname
                                      }}</span>
                                    </a>
                                  </div>
                                  <div class="head-info_info">
                                    <a :title="item.createTime" class="head-info_time">
                                      {{ item.createTime }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a class="ALink_default">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                  style="width: 2.125rem; height: 2.125rem"
                                >
                                  <img :src="item.avatar" class="keepfly-avatar-img" />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                              </a>
                            </header>
                          </div>
                        </article>
                      </div>
                    </div>
                    <div v-else>
                      <div style="background: #fff; padding: 40px 10px">
                        <n-empty :description="$t('message.none')">
                          <template #extra></template>
                        </n-empty>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MyAskView v-if="indexdetails == 3" @clickChild="clickEven"></MyAskView>
          <MyAnswerView v-if="indexdetails == 4" @clickChild="clickEven"></MyAnswerView>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 280px">
            <div class="Side_sideBox Side_posSticky" style="top: 65px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div v-if="ask_side == 1">
                  <div>
                    <div
                      class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                    >
                      <div>
                        <div
                          class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                        >
                          <div
                            class="f17 fm cla keepfly-box-item-flex"
                            style="align-self: center"
                          >
                            {{ $t("message.find_it") }}
                          </div>
                          <div
                            class="wbpro-side-tit-angle keepfly-box-flex keepfly-box-alignCenter"
                          >
                            <div
                              @click="reducePages"
                              class="wbpro-iconbed-r keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                            >
                              <chevron-back
                                class="keepfly-font keepfly-font--angleLeft"
                                style="width: 16px; color: #838383"
                              ></chevron-back>
                            </div>
                            <span class="f13 cla">{{ current }}/{{ pages }}</span>
                            <div
                              @click="addPage"
                              class="wbpro-iconbed-r keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                            >
                              <chevron-forward
                                class="keepfly-font keepfly-font--angleRight"
                                style="width: 16px; color: #838383"
                              ></chevron-forward>
                            </div>
                          </div>
                        </div>
                        <div class="keepfly-divider-main keepfly-divider-x"></div>
                      </div>
                      <div
                        class="wbpro-side-card4 wbpro-side-panel wbpro-side-hover"
                        v-for="item in lookForQList"
                        :key="item"
                      >
                        <a class="ALink_none">
                          <div class="item keepfly-box-flex keepfly-box-alignCenter">
                            <div
                              class="keepfly-avatar-main keepfly-avatar-hover"
                              style="width: 3.125rem; height: 3.125rem"
                            >
                              <img :src="item.frontCover" class="keepfly-avatar-img" />
                              <div class="keepfly-avatar-hoverMask"></div>
                            </div>
                            <div
                              class="con keepfly-box-item-flex"
                              style="align-self: center"
                            >
                              <div class="f16 cla wbpro-textcut">
                                {{ item.nameOfMissingPerson }}
                              </div>
                              <div class="f13 clb wbpro-textcut">
                                {{ item.locationOfDisappearance }}
                              </div>
                            </div>
                            <div class="right">
                              <button
                                @click="chakLookForView(item)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn"
                              >
                                <span class="keepfly-button-wrap">
                                  <i
                                    class="keepfly-font keepfly-font--add keepfly-button-icon"
                                  ></i>
                                  <span class="keepfly-button-content">
                                    {{ $t("message.tips28") }}
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </a>
                        <div class="keepfly-divider-main keepfly-divider-x"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="slogan-widget">
                    <div class="slogan-wp">
                      <div class="slogan-content">
                        <div class="not-login-mask"></div>
                        <div class="not-login-slogan">
                          <div class="title" style="position: relative">
                            <span style="position: absolute; top: 16px; left: 0%"
                              >keepfly{{ $t("message.know") }}</span
                            >
                            <a
                              style="
                                position: absolute;
                                top: 13px;
                                right: 10%;
                                width: 20px;
                                height: 20px;
                              "
                              class="icon"
                            ></a>
                            <div
                              class="finish-count"
                              style="position: absolute; top: 86px; left: 83px"
                            >
                              <p class="finish-count-text">{{ $t("message.solve") }}</p>
                              <div class="finish-count-num line">{{ asktotal }}</div>
                              <p class="finish-count-text">
                                {{ $t("message.question") }}
                              </p>
                            </div>
                          </div>

                          <div class="middle-content line">
                            <p class="slogan-text">
                              {{ $t("message.tips54") }}
                              <br />{{ $t("message.tips55") }}
                            </p>
                            <div class="not-login-slogan-bg"></div>
                          </div>

                          <p class="iknow-need-know">
                            <a target="_blank" class="iknow-need-know-link">
                              {{ $t("message.tips56") }}</a
                            >
                          </p>
                          <div class="answer-question-section">
                            <div
                              @click="addAskView"
                              class="not-login-button-item question-button"
                            >
                              <help-circle-outline
                                class="item-logo question-button-logo"
                              ></help-circle-outline>
                              <span class="item-title">{{ $t("message.tips57") }}</span>
                            </div>
                            <div
                              @click="questionView(item, 3)"
                              class="not-login-button-item question-button"
                            >
                              <library-outline
                                class="item-logo question-button-logo"
                              ></library-outline>
                              <span class="item-title">{{ $t("message.tips87") }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <n-button
                    type="warning"
                    @click="myAnswerView"
                    style="width: 100%; margin-top: 13px"
                    ghost
                  >
                    {{ $t("message.my_answer") }}
                  </n-button>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span class="CopyRight_contentItem"
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="showModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.find_it')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div>
        <n-thing>
          <template #avatar>
            <n-image style="width: 100px" :src="lookForInfo.frontCover" />
          </template>
          <template #header>
            {{ $t("message.name_of_missing_person") }}:
            {{ lookForInfo.nameOfMissingPerson }}
          </template>
          <template #description>
            {{ $t("message.debatable_time") }}: {{ lookForInfo.debatableTime }}
            {{ $t("message.gender") }}:
            {{ lookForInfo.gender == 1 ? $t("message.man") : $t("message.woman") }}<br />
            {{ $t("message.location_of_disappearance") }}:
            {{ lookForInfo.locationOfDisappearance }} {{ $t("message.age") }}:
            {{ lookForInfo.age }}
          </template>
          {{ $t("message.contact_number") }}: {{ lookForInfo.contactNumber }}<br />
          {{ $t("message.feature_description") }}:
          <div v-if="lookForInfo.descAr">{{ lookForInfo.descAr }}</div>
          <div v-if="lookForInfo.descZh">{{ lookForInfo.descZh }}</div>
          <div v-if="lookForInfo.descKo">{{ lookForInfo.descKo }}</div>
          <div v-if="lookForInfo.descJa">{{ lookForInfo.descJa }}</div>
          <div v-if="lookForInfo.descRu">{{ lookForInfo.descRu }}</div>
          <div v-if="lookForInfo.descEn">{{ lookForInfo.descEn }}</div>
          <n-collapse>
            <template #arrow></template>
            <n-collapse-item :title="$t('message.view_the_original_article')" name="1">
              <div style="padding-left: 6px">{{ lookForInfo.featureDescription }}</div>
            </n-collapse-item>
          </n-collapse>
          <template #footer>
            <n-input
              v-model:value="lookForInfofrom.content"
              type="textarea"
              status="warning"
              :placeholder="$t('message.message')"
            /><br /><br />
            <n-button
              style="width: 120px"
              @click="sendlookFor(lookForInfo.id)"
              type="warning"
            >
              <!-- {{ $t("message.send") }} -->
              <svg
                style="width: 22px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                enable-background="new 0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill="#fff"
                    d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                  ></path>
                </g>
              </svg>
            </n-button>
          </template>
        </n-thing>
      </div>
      <template #footer>
        <img
          v-show="lookForInfo.photograph"
          :src="lookForInfo.photograph"
          style="width: 100%"
        />
      </template>
    </n-modal>
    <n-modal
      v-model:show="addAskShowModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.askQuestions')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-input
        v-model:value="askcontextValue"
        :placeholder="$t('message.tips57')"
        type="textarea"
        status="warning"
        :autosize="{
          minRows: 3,
          maxRows: 5,
        }"
      />

      <template #footer>
        <n-button round type="warning" @click="sendAskInfo">
          {{ $t("message.submit") }}
        </n-button>
      </template>
    </n-modal>
    <n-modal
      v-model:show="addAnswerShowModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.quick_answer')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div style="width: 70%; float: left">{{ askInfo.question }}</div>
      <div style="width: 30%; float: right">
        <n-select
          @update:value="handleSearch"
          v-model:value="selectedValue"
          status="warning"
          label-field="name"
          value-field="language"
          :theme-overrides="selectThemeOverrides"
          :options="langarr"
          style="width: 136px; margin-left: 16px"
        />
      </div>
      <div style="clear: both"></div>
      <br />
      <div>
        {{ $t("message.translate") }}：<br />
        {{ langtxt }}
      </div>
      <br />
      <n-input
        v-model:value="answercontextValue"
        :placeholder="$t('message.quick_answer')"
        type="textarea"
        status="warning"
        :autosize="{
          minRows: 3,
          maxRows: 5,
        }"
      />

      <template #footer>
        <n-button round type="warning" @click="sendAnswerInfo">
          {{ $t("message.submit") }}
        </n-button>
      </template>
    </n-modal>
    <n-modal
      v-model:show="showUserInfoModal"
      class="custom-card"
      preset="card"
      :style="bodyUserInfoStyle"
      :title="$t('message.userinfo')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
      @scroll="userInfoRef($event)"
      style="overflow-y: scroll; height: 100vh"
    >
      <div>
        <div
          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div class="wbpro-pos">
            <div class="keepfly-picture-main ProfileHeader_pic">
              <div style="padding-bottom: 56.25%"></div>
              <img
                src="../../assets/70ace9b7ly1ggzusnypoej20yi0yiaop.jpg"
                class="keepfly-picture-img"
              />
              <div class="keepfly-picture-cover"></div>
            </div>
          </div>
          <div class="keepfly-box-flex keepfly-box-alignStart ProfileHeader_box1">
            <div
              class="keepfly-avatar-main keepfly-avatar-hover ProfileHeader_avatar2"
              style="width: 6.25rem; height: 6.25rem"
            >
              <img
                src="../../assets/default_avatar_male_180.gif"
                class="keepfly-avatar-img"
                v-if="keepflyUserInfo.avatar == null"
              />
              <img :src="keepflyUserInfo.avatar" class="keepfly-avatar-img" v-else />
              <div class="keepfly-avatar-hoverMask"></div>
            </div>
            <div class="keepfly-box-item-flex" style="overflow: hidden">
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h3">
                <div class="ProfileHeader_name">
                  {{ keepflyUserInfo.nickname }}
                </div>
                <span class="keepfly-icon-wrap" style="margin-left: 5px">
                  <male
                    class="keepfly-icon-main keepfly-icon--male"
                    v-if="keepflyUserInfo.gender == 1"
                    style="width: 1rem; height: 1rem"
                  ></male>
                  <female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else-if="keepflyUserInfo.gender == 2"
                    style="width: 1rem; height: 1rem"
                  >
                  </female>
                  <male-female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else
                    style="width: 1rem; height: 1rem"
                  ></male-female>
                </span>
              </div>
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4">
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.fans") }}<span>{{ userNum.ffnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.follow") }}<span>{{ userNum.fnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.effect") }}<span>{{ userNum.num }}</span>
                  </span>
                </a>
              </div>
              <div
                class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                style="font-size: 13px"
              >
                ID：{{ keepflyUserInfo.kpId }}
              </div>
            </div>

            <div style="margin-right: 26px; padding-top: 33px">
              <n-button
                v-if="keepflyUserInfo.id == infodata.id"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <n-icon>
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </n-icon>
                </template>
                {{ $t("message.self") }}
              </n-button>
              <n-button
                v-else-if="keepflyUserInfo.friendUid == null"
                @click="addfriend(keepflyUserInfo, 2)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <n-icon>
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </n-icon>
                </template>
                {{ $t("message.follow") }}
              </n-button>
              <n-button
                v-else
                @click="cancelfollow(keepflyUserInfo, 2)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <n-icon>
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 640 512"
                    >
                      <path
                        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104l-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </n-icon>
                </template>
                {{ $t("message.tips32") }}
              </n-button>
              &nbsp;&nbsp;
              <n-button
                style="background: purple; color: #fff"
                strong
                secondary
                round
                size="small"
                @click="sendprivateletter(keepflyUserInfo)"
              >
                <template #icon>
                  <n-icon>
                    <chatbubbles-outline />
                  </n-icon>
                </template>
                {{ $t("message.private_letter") }}
              </n-button>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="ProfileHeader_box2">
              <div class="keepfly-box-flex keepfly-box-justifyBetween">
                <div class="keepfly-box-flex keepfly-box-column ProfileHeader_content">
                  <div class="" style="margin-bottom: 12px">
                    <div class="keepfly-box-item-inlineBlock">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <document-text-outline
                            class="keepfly-font keepfly-font--proBintro"
                          ></document-text-outline>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{
                            keepflyUserInfo.profile == null ||
                            keepflyUserInfo.profile == ""
                              ? $t("message.tips16")
                              : keepflyUserInfo.profile
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ProfileHeader_box3"
              v-if="keepflyUserInfo.birthday || keepflyUserInfo.emotionalStatus"
            >
              <div
                v-if="keepflyUserInfo.birthday"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path
                          d="M12 1c-.95 0-1.585.682-1.937 1.282c-.361.619-.563 1.374-.563 1.968c0 .494.063 1.174.432 1.746C10.342 6.63 11.038 7 12 7s1.658-.37 2.068-1.004c.37-.572.432-1.252.432-1.746c0-.594-.202-1.35-.563-1.968C13.586 1.682 12.95 1 12 1zm-1 3.25c0-.31.121-.805.358-1.21c.248-.424.487-.54.642-.54c.155 0 .394.116.642.54c.237.405.358.9.358 1.21c0 .41-.062.73-.193.932c-.09.14-.27.318-.807.318c-.538 0-.717-.178-.807-.318C11.063 4.98 11 4.66 11 4.25zm9.5 6v8.25h.75a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5h.75v-8.25A2.25 2.25 0 0 1 5.75 8h12.5a2.25 2.25 0 0 1 2.25 2.25zm-15.5 0v2.091l2.447 1.58a1.25 1.25 0 0 0 1.356 0l1.747-1.128a2.75 2.75 0 0 1 2.9-.05l2.009 1.199a1.25 1.25 0 0 0 1.359-.05L19 12.36v-2.11a.75.75 0 0 0-.75-.75H5.75a.75.75 0 0 0-.75.75zm12.68 4.87a2.75 2.75 0 0 1-2.99.11l-2.008-1.2a1.25 1.25 0 0 0-1.319.023l-1.747 1.128a2.75 2.75 0 0 1-2.982 0L5 14.126V18.5h14v-4.307l-1.32.926z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.birthday }}
                  </div>
                </div>
              </div>
              <div
                v-if="keepflyUserInfo.emotionalStatus"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d="M26 30h-4a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.946 2.946 0 0 1 3-3h6a2.946 2.946 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2zm-5-18a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M24 9a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M10 20.184V12H8v8.184a3 3 0 1 0 2 0z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M9 30a6.993 6.993 0 0 1-5-11.89V7a5 5 0 0 1 10 0v11.11A6.993 6.993 0 0 1 9 30zM9 4a3.003 3.003 0 0 0-3 3v11.983l-.332.299a5 5 0 1 0 6.664 0L12 18.983V7a3.003 3.003 0 0 0-3-3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ checkfeel(keepflyUserInfo.emotionalStatus) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.hometown">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none">
                        <path
                          d="M8.998 2.389a1.5 1.5 0 0 1 2.005 0l5.5 4.941A1.5 1.5 0 0 1 17 8.446V9.4a2.98 2.98 0 0 0-1-.36v-.595a.5.5 0 0 0-.166-.372l-5.5-4.942a.5.5 0 0 0-.668 0l-5.5 4.942A.5.5 0 0 0 4 8.446V15.5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5V12A1.5 1.5 0 0 1 9 10.5h2a1.5 1.5 0 0 1 1.5 1.5v2.208a2.51 2.51 0 0 0-1 .792v-3a.5.5 0 0 0-.5-.5H9a.5.5 0 0 0-.5.5v3.5A1.5 1.5 0 0 1 7 17H4.5A1.5 1.5 0 0 1 3 15.5V8.446c0-.426.18-.831.498-1.116l5.5-4.941zM17.5 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0zm1.5 4.5c0 1.245-1 2.5-3.5 2.5S12 17.75 12 16.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.hometown }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.address">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ keepflyUserInfo.address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3">
              <div style="position: relative">
                <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                  <div class="keepfly-box-flex keepfly-box-alignStart">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                    >
                      <calendar-outline
                        class="keepfly-font keepfly-font--proTime"
                      ></calendar-outline>
                    </div>
                    <div
                      class="keepfly-box-item-flex ProfileHeader_con3"
                      v-if="keepflyUserInfo.createTime"
                    >
                      {{ keepflyUserInfo.createTime.substring(0, 10) }}
                      {{ $t("message.add") }} keepfly
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    color: darkgray;
                    width: 70px;
                    height: 32px;
                    top: 0;
                    right: 20px;
                    cursor: pointer;
                  "
                  class="ProfileHeader_con3"
                  @click="senduserblacklist(keepflyUserInfo)"
                >
                  {{ $t("message.tips122") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="wbpro-screen-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
        >
          <div>{{ $t("message.all") }} keepfly（{{ userNum.mnum }}）</div>
        </div>
        <div>
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    style="min-height: 684px"
                  >
                    <div class="">
                      <div
                        class="wbpro-scroller-item"
                        v-for="item in usermicroblogs"
                        :key="item"
                      >
                        <article
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                          tabindex="0"
                          style="border-radius: 4px"
                        >
                          <div class="Feed_body">
                            <header class="keepfly-box-flex">
                              <a class="ALink_default">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                  style="width: 3.25rem; height: 3.25rem"
                                >
                                  <img :src="item.avatar" class="keepfly-avatar-img" />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                              </a>
                              <div class="keepfly-box-item-flex head_main">
                                <div
                                  class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                  >
                                    <a class="ALink_default head_cut head_name">
                                      <span :title="item.nickname">{{
                                        item.nickname
                                      }}</span>
                                    </a>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter"
                                    ></div>
                                  </div>
                                  <div
                                    class="keepfly-box-alignCenter keepfly-box-justifyCenter head-info_info"
                                  >
                                    <a class="head-info_time">
                                      {{ item.createTime }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="keepfly-box-flex">
                                <div class="keepfly-pop-wrap morepop_more">
                                  <span class="keepfly-pop-ctrl">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                    >
                                      <chevron-down
                                        class="keepfly-font keepfly-font--angleDown morepop_action"
                                        style="width: 16px"
                                      ></chevron-down>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </header>
                            <div class="Feed_retweet">
                              <div class="detail_text detail_reText wbpro-feed-reText">
                                <n-ellipsis
                                  expand-trigger="click"
                                  line-clamp="4"
                                  :tooltip="false"
                                >
                                  <div
                                    class="detail_wbtext"
                                    v-html="filters(item.content)"
                                  ></div>
                                </n-ellipsis>
                              </div>
                              <div class="picture picture-box_row">
                                <span
                                  v-for="titem in item.tags"
                                  :key="titem"
                                  style="
                                    color: #ff8200;
                                    padding-right: 10px;
                                    font-size: 12px;
                                  "
                                  :title="titem"
                                >
                                  {{ uqTxtTranslate(titem) }}
                                </span>
                              </div>
                              <div
                                class="picture picture-box_row"
                                v-if="item.images != []"
                              >
                                <div class="u-col-3 keepfly-box-wrap picture_inlineNum3">
                                  <n-image-group>
                                    <n-space>
                                      <n-image
                                        width="133"
                                        height="133"
                                        v-for="oitem in item.images"
                                        :key="oitem"
                                        :src="oitem"
                                        style="border-radius: 15px"
                                      />
                                    </n-space>
                                  </n-image-group>
                                </div>
                              </div>
                              <div v-if="item.videoInfo">
                                <video
                                  controls="controls"
                                  :src="item.videoInfo"
                                  class="video"
                                  style="
                                    width: 400px;
                                    vertical-align: middle;
                                    height: 225px;
                                    background: #000;
                                    border-radius: 10px;
                                  "
                                ></video>
                              </div>
                            </div>
                          </div>
                          <footer class="">
                            <div
                              class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                            >
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  @click="getCommentView(item, 1)"
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  style="cursor: pointer"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                  >
                                    <ellipsis-horizontal-circle
                                      class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                    ></ellipsis-horizontal-circle>
                                  </div>
                                  <span class="toolbar_num"> {{ item.cnum }} </span>
                                </div>
                              </div>
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                >
                                  <button
                                    @click="likeClick(item)"
                                    class="keepfly-like-main toolbar_btn"
                                  >
                                    <svg
                                      style="width: 19px"
                                      class="keepfly-like-iconWrap"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 32 32"
                                    >
                                      <path
                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <span class="keepfly-like-count">{{
                                      item.lnum
                                    }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </footer>
                          <div v-if="item.show">
                            <div
                              class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                            ></div>
                            <div class="Feed_box">
                              <div class="keepfly-box-flex Feed_mar2">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                  style="width: 2.125rem; height: 2.125rem"
                                >
                                  <img
                                    :src="infodata.avatar"
                                    class="keepfly-avatar-img"
                                  />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                                <div class="keepfly-box-item-flex">
                                  <div>
                                    <div>
                                      <div
                                        class="wbpro-form Form_wbproform"
                                        compser="true"
                                      >
                                        <textarea
                                          :placeholder="$t('message.tips31')"
                                          v-model="item.commentValue"
                                          maxlength="100"
                                          class="Form_input"
                                          style="height: 84px"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="Composer_mar1">
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter"
                                      >
                                        <button
                                          @click="sendInfoComment(item)"
                                          class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">{{
                                              $t("message.comment")
                                            }}</span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                              ></div>
                              <div>
                                <div>
                                  <div class="RepostCommentFeed_mar1">
                                    <div class="wbpro-list">
                                      <div
                                        class="item1"
                                        v-for="oitem in item.commentVos"
                                        :key="oitem"
                                      >
                                        <div class="item1in keepfly-box-flex">
                                          <div>
                                            <a class="ALink_default">
                                              <div
                                                class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                style="width: 2.125rem; height: 2.125rem"
                                              >
                                                <img
                                                  :src="oitem.fromUser.avatar"
                                                  class="keepfly-avatar-img"
                                                />
                                              </div>
                                            </a>
                                          </div>
                                          <div class="con1 keepfly-box-item-flex">
                                            <div class="text">
                                              <a class="ALink_default">{{
                                                oitem.fromUser.nickname
                                              }}</a>
                                              <a class="ALink_none" target="_blank"></a>:
                                              <span v-if="oitem.contentAr">{{
                                                oitem.contentAr
                                              }}</span>
                                              <span v-if="oitem.contentEn">{{
                                                oitem.contentEn
                                              }}</span>
                                              <span v-if="oitem.contentJa">{{
                                                oitem.contentJa
                                              }}</span>
                                              <span v-if="oitem.contentKo">{{
                                                oitem.contentKo
                                              }}</span>
                                              <span v-if="oitem.contentRu">{{
                                                oitem.contentRu
                                              }}</span>
                                              <span v-if="oitem.contentZh">{{
                                                oitem.contentZh
                                              }}</span>
                                            </div>
                                            <div
                                              class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                            >
                                              <div>
                                                {{ oitem.createDate }}
                                              </div>
                                              <div class="opt keepfly-box-flex">
                                                <div
                                                  class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                >
                                                  <button
                                                    class="keepfly-like-main IconList_likebox"
                                                    :title="$t('message.praise')"
                                                    @click="commentVoslikeClick(oitem)"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      style="width: 22px"
                                                      class="keepfly-like-iconWrap"
                                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                                      viewBox="0 0 32 32"
                                                    >
                                                      <path
                                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                        fill="currentColor"
                                                      ></path>
                                                    </svg>
                                                    <span class="keepfly-like-count">{{
                                                      oitem.lnum == null ? 0 : oitem.lnum
                                                    }}</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                  ></div>
                                  <a class="ALink_default" @click="morecommentVos(item)">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                    >
                                      <div
                                        v-if="
                                          item.commentVos != null &&
                                          item.commentVos.length < item.total
                                        "
                                        style="color: #ccc"
                                      >
                                        {{ $t("message.more") }}
                                      </div>
                                      <div style="color: #ccc" v-else>
                                        {{ $t("message.tips74") }}
                                      </div>
                                      <i
                                        class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                      ></i>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import API from "../../uilts/request";
import {
  FlameSharp,
  SparklesSharp,
  HelpCircleOutline,
  EllipsisHorizontalCircle,
  ShareOutline,
  ChevronBack,
  ChevronForward,
  MenuSharp,
  ChevronBackOutline,
  LibraryOutline,
  Male,
  Female,
  MaleFemale,
  ChatbubblesOutline,
  DocumentTextOutline,
  CalendarOutline,
} from "@vicons/ionicons5";
import i18n from "../../i18n/index";
import MyAskView from "./MyAskView.vue";
import MyAnswerView from "./MyAnswerView.vue";
import {
  NSpace,
  NImageGroup,
  useMessage,
  useDialog,
  NModal,
  NThing,
  NButton,
  NInput,
  NCollapse,
  NCollapseItem,
  NImage,
  NSelect,
  NEmpty,
  NDropdown,
  NIcon,
  NEllipsis,
  NPagination,
} from "naive-ui";
import bus from "../../uilts/bus.js";
const feelOptions = [
  {
    label: i18n.global.t("message.single"),
    value: 1001,
  },
  {
    label: i18n.global.t("message.seeking_a_relationship"),
    value: 1002,
  },
  {
    label: i18n.global.t("message.in_secret_love"),
    value: 1003,
  },
  {
    label: i18n.global.t("message.ambiguous"),
    value: 1004,
  },
  {
    label: i18n.global.t("message.in_love"),
    value: 1005,
  },
  {
    label: i18n.global.t("message.engaged"),
    value: 1006,
  },
  {
    label: i18n.global.t("message.married"),
    value: 1007,
  },
  {
    label: i18n.global.t("message.separated"),
    value: 1008,
  },
  {
    label: i18n.global.t("message.divorced"),
    value: 1009,
  },
  {
    label: i18n.global.t("message.widow"),
    value: 1010,
  },
  {
    label: i18n.global.t("message.not_selected"),
    value: 1011,
  },
];
export default defineComponent({
  name: "HotView",
  components: {
    MyAskView,
    MyAnswerView,
    FlameSharp,
    SparklesSharp,
    HelpCircleOutline,
    NSpace,
    NImageGroup,
    EllipsisHorizontalCircle,
    ShareOutline,
    ChevronBack,
    ChevronForward,
    NModal,
    NThing,
    NButton,
    NInput,
    NCollapse,
    NCollapseItem,
    NImage,
    NSelect,
    NEmpty,
    MenuSharp,
    NDropdown,
    ChevronBackOutline,
    LibraryOutline,
    Male,
    Female,
    MaleFemale,
    ChatbubblesOutline,
    NIcon,
    DocumentTextOutline,
    CalendarOutline,
    NEllipsis,
    NPagination,
  },
  props: ["context"],
  setup(props, context) {
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const message = useMessage();
    const dialog = useDialog();
    const navTab2Show = ref(1);
    const ask_side = ref(1);
    const setNavTab2 = (e) => {
      navTab2Show.value = e;
      ask_side.value = 1;
      if (e == 2) {
        getTopicData();
      } else if (e == 3) {
        getgoldenrankinglist();
      } else if (e == 4) {
        ask_side.value = 0;
        getasklist();
      }
    };
    const TopicArr = ref([]);
    const currTopicPage = ref(1);
    const pageTopicSize = ref(10);
    const getTopicData = () => {
      let param = {
        currPage: currTopicPage.value,
        pageSize: pageTopicSize.value,
        language: localStorage.getItem("language") || "en",
      };
      API({
        url: "/kuke/api/index/getTopicList",
        method: "POST",
        data: param,
      }).then((result) => {
        TopicArr.value = JSON.parse(JSON.stringify(result.data.data.records));
      });
    };

    const tagnum = ref(0);
    const txttag = ref("");
    const setTagLable = (e, index) => {
      tagnum.value = index;
      microblogs.value = [];
      gettagmicrobloglist(e);
    };
    const gettagmicrobloglist = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
        tagtxt: e,
      };
      API({
        url: "/kuke/api/user/gettagmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          microblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mipages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };
    const setTag = (e, index) => {
      tagnum.value = index;
      microblogs.value = [];
      if (index == 0) {
        txttag.value = "";
        getmicrobloglist("");
      } else {
        txttag.value = e;
        getmicrobloglist(e);
      }
    };
    const currPage = ref(1);
    const pageSize = ref(5);
    const microblogs = ref([]);
    const mipages = ref(0);
    const getmicrobloglist = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
        tagtxt: e,
      };
      API({
        url: "/kuke/api/user/getmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          microblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mipages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };
    const topicTranslateList = ref([]);
    const setMicroblogView = (e) => {
      if (e.length == 0) {
        return false;
      }
      let params = {
        language: localStorage.getItem("language"),
        tags: e,
      };
      API({
        url: "/kuke/api/index/setMicroblogView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        let arr = result.data.data;
        for (let i = 0; i < arr.length; i++) {
          topicTranslateList.value.push(arr[i]);
        }
      });
    };
    const uqTxtTranslate = (e) => {
      let orr = topicTranslateList.value;
      for (let i = 0; i < orr.length; i++) {
        if (orr[i].topicTitle == e && orr[i].topicTranslate) {
          return "#" + orr[i].topicTranslate;
        }
      }
    };
    const kukesystemInfo = ref({});
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      console.log(infodata.value);
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getKukeTag();
      getmicrobloglist("");
      getLookFor();
      getKukeLanguage();
      if (props.context == 2) {
        setNavTab2(2);
      }
      window.addEventListener("scroll", handleScroll);
      if (JSON.parse(window.localStorage.getItem("sendTohuati"))) {
        navTab2Show.value = window.localStorage.getItem("sendTohuati");
      }
      if (navTab2Show.value == 2) {
        getTopicData();
        window.localStorage.setItem("sendTohuati", null);
      }
      if (navTab2Show.value == 4) {
        ask_side.value = 0;
        getasklist();
        window.localStorage.setItem("sendTohuati", null);
      }
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (navTab2Show.value == 1) {
            if (currPage.value < mipages.value) {
              ++currPage.value;
              getmicrobloglist(txttag.value);
            }
          } else if (navTab2Show.value == 4) {
            if (indexdetails.value == 2) {
              if (currAskAnswerPage.value < apages.value) {
                ++currAskAnswerPage.value;
                getAskAnswerlist(questionInfo.value.id);
              }
            }
          }
        }
      }
    };

    const tagArr = ref([]);
    const tagmoreoptions = ref([]);
    const getKukeTag = () => {
      let param = {
        language: localStorage.getItem("language") || "en",
      };
      API({
        url: "/kuke/api/index/getKukeTag",
        method: "GET",
        params: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data));
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].nameJa) {
            tagmoreoptions.value.push({
              label: arr[i].nameJa,
              key: arr[i].nameJa,
            });
          }
          if (arr[i].nameRu) {
            tagmoreoptions.value.push({
              label: arr[i].nameRu,
              key: arr[i].nameRu,
            });
          }
          if (arr[i].nameAr) {
            tagmoreoptions.value.push({
              label: arr[i].nameAr,
              key: arr[i].nameAr,
            });
          }
          if (arr[i].nameEn) {
            tagmoreoptions.value.push({
              label: arr[i].nameEn,
              key: arr[i].nameEn,
            });
          }
          if (arr[i].nameKo) {
            tagmoreoptions.value.push({
              label: arr[i].nameKo,
              key: arr[i].nameKo,
            });
          }
          if (arr[i].nameZh) {
            tagmoreoptions.value.push({
              label: arr[i].nameZh,
              key: arr[i].nameZh,
            });
          }
        }
        tagArr.value = arr;
      });
    };
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const ctotal = ref(0);
    const getCommentView = (e, i) => {
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const commentValue = ref("");
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.success(i18n.global.t("message.tips172"));
        }
      });
    };
    const infodata = ref({});
    const handleConfirm = (e) => {
      let param = {
        id: e,
      };
      dialog.warning({
        title: i18n.global.t("message.hint"),
        content: i18n.global.t("message.tips53"),
        positiveText: i18n.global.t("message.determine"),
        onPositiveClick: () => {
          API({
            url: "/kuke/api/user/setmicroblogforwardclick",
            method: "POST",
            data: param,
          }).then((result) => {
            if (result.data.code == 200) {
              message.success(i18n.global.t("message.tips29"));
            } else {
              message.error(i18n.global.t("message.tips30"));
            }
          });
        },
      });
    };
    const addfriend = (e, i) => {
      let param = {};
      if (i == 1) {
        param = {
          userid: e.userId,
        };
      } else if (i == 2) {
        param = {
          userid: e.uid,
        };
      }
      API({
        url: "/kuke/api/user/addfriend",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.friendUid = 1;
          message.success(i18n.global.t("message.tips32"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips32"));
        } else if (result.data.code == 606) {
          message.success(i18n.global.t("message.tips34"));
        } else {
          message.error(i18n.global.t("message.tips146"));
        }
      });
    };
    const currgoldenPage = ref(1);
    const pagegoldenSize = ref(10);
    const goldenarr = ref([]);
    const getgoldenrankinglist = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currgoldenPage.value,
        pageSize: pagegoldenSize.value,
      };
      API({
        url: "/kuke/api/index/getgoldenrankinglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        goldenarr.value = arr;
      });
    };
    const currLookForPage = ref(1);
    const pageLookForSize = ref(5);
    const lookForQList = ref([]);
    const current = ref(0);
    const pages = ref(0);
    const getLookFor = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currLookForPage.value,
        pageSize: pageLookForSize.value,
      };
      API({
        url: "/kuke/api/index/getlookforlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        current.value = result.data.data.current;
        pages.value = result.data.data.pages;
        lookForQList.value = arr;
      });
    };
    const lookForInfo = ref({});
    const chakLookForView = (e) => {
      lookForInfo.value = e;
      showModal.value = true;
    };
    const showModal = ref(false);
    const lookForInfofrom = ref({
      content: "",
      lId: "",
    });
    const sendlookFor = (e) => {
      lookForInfofrom.value.lId = e;
      API({
        url: "/kuke/api/index/sendlookFor",
        method: "POST",
        data: lookForInfofrom.value,
      }).then((result) => {
        if (result.data.code == 200) {
          lookForInfofrom.value.content = "";
          lookForInfofrom.value.lId = "";
          showModal.value = false;
          message.success(i18n.global.t("message.tips164"));
        } else {
          message.error(i18n.global.t("message.tips165"));
        }
      });
    };
    const addPage = () => {
      if (currLookForPage.value == pages.value) {
        return false;
      }
      currLookForPage.value = currLookForPage.value + 1;
      getLookFor();
    };
    const reducePages = () => {
      if (currLookForPage.value == 1) {
        return false;
      }
      currLookForPage.value = currLookForPage.value - 1;
      getLookFor();
    };
    const curraskPage = ref(1);
    const pageaskSize = ref(10);
    const asklistarr = ref([]);
    const asktotal = ref(0);
    const asklistPage = ref(0);
    const onasklistChange = (val) => {
      curraskPage.value = val;
      getasklist();
    };
    const getasklist = () => {
      let param = {
        currPage: curraskPage.value,
        pageSize: pageaskSize.value,
      };
      API({
        url: "/kuke/api/index/getasklist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        asklistarr.value = arr;
        asktotal.value = result.data.data.total;
        asklistPage.value = result.data.data.pages;
      });
    };
    const addAskShowModal = ref(false);
    const addAskView = () => {
      addAskShowModal.value = true;
    };
    const askcontextValue = ref("");
    const sendAskInfo = () => {
      if (!askcontextValue.value) {
        return false;
      }
      API({
        url: "/kuke/api/user/sendAskInfo",
        method: "POST",
        data: {
          question: askcontextValue.value,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          askcontextValue.value = "";
          addAskShowModal.value = false;
          message.success(i18n.global.t("message.tips175"));
          getasklist();
        } else {
          message.error(i18n.global.t("message.tips176"));
        }
      });
    };
    const addAnswerShowModal = ref(false);
    const askInfo = ref({});
    const addAnswerView = (e) => {
      askInfo.value = e;
      addAnswerShowModal.value = true;
    };
    const lang = ref("");
    const langarr = ref([]);
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        langarr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const langtxt = ref("");
    const handleSearch = (e) => {
      if (e == "") {
        return false;
      }
      API({
        url: "/kuke/api/index/getlangtranslate",
        method: "GET",
        params: {
          txt: askInfo.value.question,
          lang: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          langtxt.value = JSON.parse(JSON.stringify(result.data.data));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const selectedValue = ref(null);
    const answercontextValue = ref("");
    const sendAnswerInfo = () => {
      if (!answercontextValue.value) {
        return false;
      }
      API({
        url: "/kuke/api/user/sendAnswerInfo",
        method: "POST",
        data: {
          id: askInfo.value.id,
          answer: answercontextValue.value,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          AskAnswerlist.value.push(result.data.data);
          answercontextValue.value = "";
          addAnswerShowModal.value = false;
          message.success(i18n.global.t("message.tips173"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips91"));
        } else {
          message.error(i18n.global.t("message.tips174"));
        }
      });
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const showUserInfoModal = ref(false);
    const keepflyUserInfo = ref({});
    const checkUserInfo = (e, i) => {
      let id = 0;
      if (i == 1) {
        id = e.uid;
      } else if (i == 3) {
        id = e.userId;
      } else {
        id = e.fromUser.id;
      }
      API({
        url: "/kuke/api/index/checkUserInfo",
        method: "POST",
        data: {
          id: id,
          trt: window.localStorage.getItem("token"),
        },
      }).then((result) => {
        if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips113"));
          return false;
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips114"));
          return false;
        }
        showUserInfoModal.value = true;
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
        keepflyUserInfo.value.friendUid = e.friendUid;
        keepflyUserInfo.value.userId = e.userId;
        keepflyUserInfo.value.uid = e.uid;
        keepflyUserInfo.value.isFriend = e.isFriend;
      });
      getUserInfoNum(id);
      usermicroblogs.value = [];
      getmicrobloguserlist(id);
    };
    const userNum = ref({});
    const getUserInfoNum = (e) => {
      API({
        url: "/kuke/api/index/getUserInfoNum",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          userNum.value = result.data.data;
        }
      });
    };
    const currusermicroblogPage = ref(1);
    const pageusermicroblogSize = ref(4);
    const usermicroblogs = ref([]);
    const mpages = ref(0);
    const getmicrobloguserlist = (e) => {
      let param = {
        currPage: currusermicroblogPage.value,
        pageSize: pageusermicroblogSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getmicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          usermicroblogs.value.push(arr[i]);
        }
        mpages.value = result.data.data.pages;
      });
    };
    const userInfoRef = (e) => {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight >=
        e.srcElement.scrollHeight
      ) {
        if (currusermicroblogPage.value < mpages.value) {
          ++currusermicroblogPage.value;
          getmicrobloguserlist(keepflyUserInfo.value.id);
        }
      }
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const tagmorehandleSelect = (key) => {
      setTag(key, -1);
    };
    const indexdetails = ref(1);
    const questionInfo = ref({});
    const questionView = (e, i) => {
      questionInfo.value = e;
      indexdetails.value = i;
      currAskAnswerPage.value = 1;
      AskAnswerlist.value = [];
      flat0.value = true;
      if (i != 3) {
        getAskAnswerlist(e.id);
      }
    };
    const currAskAnswerPage = ref(1);
    const pageAskAnswerSize = ref(8);
    const AskAnswerlist = ref([]);
    const apages = ref(0);
    const getAskAnswerlist = (e) => {
      let param = {
        currPage: currAskAnswerPage.value,
        pageSize: pageAskAnswerSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getAskAnswerlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          AskAnswerlist.value.push(arr[i]);
        }
        apages.value = result.data.data.pages;
      });
    };
    const clickEven = (val) => {
      indexdetails.value = val;
    };
    const sendprivateletter = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          context.emit("eventParent", 4);
          bus.emit("sendToBrother", 4);
          window.localStorage.setItem("sendTohuati", 4);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips123"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    const senduserblacklist = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/senduserblacklist",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips120"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips121"));
        }
      });
    };
    function copyCode(codecon) {
      let domInput = document.createElement("input");
      if (codecon.contentAr) {
        domInput.value = codecon.contentAr;
      } else if (codecon.contentEn) {
        domInput.value = codecon.contentEn;
      } else if (codecon.contentJa) {
        domInput.value = codecon.contentJa;
      } else if (codecon.contentKo) {
        domInput.value = codecon.contentKo;
      } else if (codecon.contentRu) {
        domInput.value = codecon.contentRu;
      } else if (codecon.contentZh) {
        domInput.value = codecon.contentZh;
      }
      document.body.appendChild(domInput); // 添加input节点
      domInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      message.success(i18n.global.t("message.tips166"));
      domInput.remove();
    }
    const goldenlikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setgoldenlikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const myAnswerView = () => {
      indexdetails.value = 4;
    };
    function checkfeel(e) {
      let arr = feelOptions;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value == e) {
          return arr[i].label;
        }
      }
    }
    return {
      setTagLable,
      onasklistChange,
      asklistPage,
      feelOptions,
      checkfeel,
      myAnswerView,
      goldenlikeClick,
      copyCode,
      senduserblacklist,
      sendprivateletter,
      clickEven,
      AskAnswerlist,
      questionInfo,
      questionView,
      indexdetails,
      tagmoreoptions,
      tagmorehandleSelect,
      kukesystemInfo,
      uqTxtTranslate,
      morecommentVos,
      commentVoslikeClick,
      ctotal,
      userInfoRef,
      usermicroblogs,
      getmicrobloguserlist,
      userNum,
      showUserInfoModal,
      keepflyUserInfo,
      checkUserInfo,
      filters,
      sendAnswerInfo,
      selectedValue,
      answercontextValue,
      handleSearch,
      langtxt,
      langarr,
      lang,
      addAnswerShowModal,
      askInfo,
      addAnswerView,
      sendAskInfo,
      askcontextValue,
      addAskView,
      addAskShowModal,
      asktotal,
      ask_side,
      getasklist,
      asklistarr,
      addPage,
      reducePages,
      showModal,
      lookForInfo,
      chakLookForView,
      lookForInfofrom,
      sendlookFor,
      pages,
      current,
      getLookFor,
      lookForQList,
      getgoldenrankinglist,
      goldenarr,
      handleConfirm,
      addfriend,
      infodata,
      sendInfoComment,
      commentValue,
      getCommentView,
      likeClick,
      getKukeTag,
      tagArr,
      microblogs,
      getmicrobloglist,
      tagnum,
      setTag,
      getTopicData,
      TopicArr,
      navTab2Show,
      setNavTab2,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
      bodyUserInfoStyle: {
        width: "900px",
      },
    };
  },
});
</script>
<style>
.HotTopic_tit_ask {
  margin: 0 6px 0 8px;
  line-height: 25px;
  font-weight: 700;
  font-size: 18px;
  width: 478px;
}
.slogan-widget {
  width: 100%;
  height: 303px;
}
.slogan-wp {
  width: 100%;
}
.slogan-content {
  position: relative;
}
.not-login-mask {
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.not-login-slogan {
  background: #e9e9e9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 303px;
  border-radius: 10px;
}
.not-login-slogan .title {
  height: 39px;
  margin: 0 20px;
  line-height: 39px;
  box-sizing: border-box;
  /* border-bottom: 1px solid #daecf3; */
  font-size: 14px;
  color: #333;
}
.not-login-slogan .middle-content {
  height: 91px;
  margin-bottom: 20px;
}
.not-login-slogan .finish-count {
  text-align: center;
}
.not-login-slogan .iknow-need-know {
  padding-top: 18px;
  text-align: center;
}
.not-login-slogan .answer-question-section {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
}
.not-login-slogan .answer-question-section .not-login-button-item {
  display: inline;
  float: left;
  width: 50%;
  height: 72px;
  background-color: #fff;
  color: #000;
  text-align: center;
  text-decoration: none;
}
.not-login-slogan .answer-question-section .question-button {
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
  cursor: pointer;
}
.not-login-slogan .answer-question-section .answer-button {
  -webkit-border-radius: 0 0 8px;
  -moz-border-radius: 0 0 8px;
  border-radius: 0 0 8px;
}
.slogan-wp .slogan-content .not-login-slogan .title .icon {
  display: inline-block;
  float: right;
  width: 15px;
  height: 15px;
  margin-top: 12px;
  background: url(../../assets/link-icon.3fbd8ee.png) no-repeat;
  background-size: cover;
}
.not-login-slogan .middle-content .slogan-text {
  display: inline;
  float: left;
  width: 140px;
  margin: 29px 20px 0 18px;
  font-size: 20px;
  line-height: 30px;
  color: #ff8747;
}
.slogan-wp .slogan-content .not-login-slogan .middle-content .not-login-slogan-bg {
  width: 60px;
  height: 80px;
  margin-top: 8px;
  background: url(../../assets/not-login-slogan-bg.9f53180.png) no-repeat;
  background-size: cover;
  display: inline;
  float: left;
}
.slogan-wp .slogan-content .not-login-slogan .finish-count {
  text-align: center;
}
.slogan-wp .slogan-content .not-login-slogan .finish-count .finish-count-text {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  font-size: 14px;
  color: #333;
  line-height: 12px;
}
.slogan-wp .slogan-content .not-login-slogan .finish-count .finish-count-num {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  font-size: 22px;
  color: #ee5e0f;
  line-height: 22px;
  height: 22px;
  overflow: hidden;
}
.slogan-wp .slogan-content .not-login-slogan .iknow-need-know .iknow-need-know-link {
  font-size: 14px;
  line-height: 12px;
  color: #333;
  text-decoration: underline;
}
.slogan-wp
  .slogan-content
  .not-login-slogan
  .answer-question-section
  .not-login-button-item
  .item-logo {
  width: 24px;
  height: 24px;
  margin: 11px auto 12px;
  background-size: cover;
  background-repeat: no-repeat;
}
.slogan-wp
  .slogan-content
  .not-login-slogan
  .answer-question-section
  .question-button
  .item-logo {
  width: 22px;
  display: block;
}
.slogan-wp
  .slogan-content
  .not-login-slogan
  .answer-question-section
  .answer-button
  .item-logo {
  width: 19px;
  height: 19px;
  display: block;
  margin: 13px auto 15px;
}
.slogan-wp
  .slogan-content
  .not-login-slogan
  .answer-question-section
  .question-button:hover,
.operate-item:hover {
  color: #ff8747;
}
.slogan-wp
  .slogan-content
  .not-login-slogan
  .answer-question-section
  .answer-button:hover {
  color: #ff8747;
}
.wbpro-side-card4 .con {
  margin: 0 0 0 10px;
  overflow: hidden;
}
.n-tag {
  white-space: normal !important;
}
.Bar_left,
.Bar_right {
  margin: 0 10px;
}
.Bar_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  line-height: 22px;
}
.Bar_hand {
  cursor: pointer;
}

.list-item {
  margin-bottom: 11px;
}
.sentence-card-wrap {
  background: #fff;
  padding: 24px 17px 22px 19px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07);
  border-radius: 9px;
}
.sentence-card-wrap .content-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sentence-card-wrap .content {
  font-size: 16px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.sentence-card-wrap .tool-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 30px;
  margin-top: 16px;
}

.sentence-card-wrap .tool-wrap .tool-left,
.sentence-card-wrap .tool-wrap .tool-left .operate-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.sentence-card-wrap .tool-wrap .tool-left {
  height: 30px;
}
.sentence-card-wrap .tool-wrap .tool-left .operate-item {
  margin-right: 63px;
  font-size: 13px;
  color: #666;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  line-height: 13px;
  letter-spacing: 0;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.sentence-card-wrap .tool-wrap .tool-left .operate-item .tool-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  opacity: 0.7;
}
.sentence-card-wrap .tool-wrap .copy {
  width: 75px;
  height: 30px;
  line-height: 30px;
  border-radius: 40.5px;
  background: #f5f5f5;
  font-size: 14px;
  color: #1f1f1f;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
