import { createRouter, createWebHashHistory } from 'vue-router'
import utils from '../uilts/utils';
import API from "../uilts/request"
import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import EnrollView from '../views/EnrollView.vue'
import SelfService from '../views/SelfService.vue'
import UserView from '../views/user/UserView.vue'
import HomeView from '../views/user/HomeView.vue'
import DeceasedView from '../views/user/DeceasedView.vue'
import ViewerDeceasedView from '../views/user/ViewerDeceasedView'
import DeadView from '../views/DeadView.vue'
import ServiceDetails from '../views/ServiceDetails.vue'
import WebsiteView from '../views/WebsiteView.vue'
import DocumentView from '../views/DocumentView.vue'
import LogOffView from '../views/LogOffView.vue'
import i18n from '../i18n/index'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    meta: {
      title: i18n.global.t('message.home_page')
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: i18n.global.t('message.SignIn')
    },
  },
  {
    path: '/dead/:id',
    name: 'DeadView',
    component: DeadView,
    meta: {
      title: i18n.global.t('message.deceased_square')
    },
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: EnrollView,
    meta: {
      title: i18n.global.t('message.register')
    },
  },
  {
    path: '/selfservice',
    name: 'selfservice',
    component: SelfService,
    meta: {
      title: i18n.global.t('message.help_center')
    },
  },
  {
    path: '/servicedetails/:id',
    name: 'ServiceDetails',
    component: ServiceDetails,
    meta: {
      title: i18n.global.t('message.help_center')
    },
  },
  {
    path: '/website',
    name: 'WebsiteView',
    component: WebsiteView,
    meta: {
      title: i18n.global.t('message.website_related')
    },
  },
  {
    path: '/document',
    name: 'DocumentView',
    component: DocumentView,
    meta: {
      title: i18n.global.t('message.website_related')
    },
  },
  {
    path: '/logoff',
    name: 'LogOffView',
    component: LogOffView,
    meta: {
      requiresAuth: true,
      title: i18n.global.t('message.homepage')
    },
  },
  {
    path: '/user',
    name: 'UserView',
    component: UserView,
    children: [
      {
        path: 'home',
        name: 'HomeView',
        component: HomeView,
        meta: {
          requiresAuth: true,
          title: i18n.global.t('message.homepage')
        },
      },
      {
        path: 'deceased/:id',
        name: 'DeceasedView',
        component: DeceasedView,
        meta: {
          requiresAuth: true,
          title: i18n.global.t('message.homepage')
        },
      },
      {
        path: 'viewerdeceased/:id',
        name: 'ViewerDeceasedView',
        component: ViewerDeceasedView,
        meta: {
          requiresAuth: true,
          title: i18n.global.t('message.homepage')
        },
      },
      
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  console.log(to.path);
  console.log(to.path.includes('document'));
  if (to.path === '/'
  ||to.path === '/login'
  ||to.path === '/enroll'
  ||to.path.includes('dead')
  ||to.path.includes('selfservice')
  ||to.path.includes('servicedetails')
  ||to.path.includes('website')
  ||to.path.includes('document')
  ||to.path.includes('logoff')
  ) {
    next()   // 如果即将进入登录路由，则直接放行
  } else {
    if (to.meta.requireAuth && !localStorage.getItem('token')) {
      next({ path: '/' })
    } else {
      let currtime = new Date().getTime();
      let checktime = utils.dateFormat(currtime);
      let logintime = localStorage.getItem('loginTime');
      var Invalid = utils.GetDateDiff(logintime, checktime, "minute");
      if (Invalid > 50 && Invalid < 60) {
        API({
          url: '/kuke/api/system-admin/tokenRefresh',
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((result) => {
          if (result.data.code == 200) {
            window.localStorage.setItem("token", result.data.data)
            window.localStorage.setItem("loginTime", checktime)
            next();
          } else {
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('loginTime')
            next('/');
          }
        })
      } else if (Invalid > 60 || Invalid == 60) {
        if (window.localStorage.getItem('token')) {
          API({
            url: '/kuke/api/system-admin/userIsLogin',
            method: 'GET',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then((result) => {
            if (result.data.code == 402) {
              next('/');
            } else if (result.data.code == 200) {
              next()
            } else {
              next('/');
            }
          })
        } else {
          next('/');
        }

      } else if (Invalid < 50 || Invalid == 50) {
        next()
      }
    }
  }
})
export default router
