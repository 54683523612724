import { createI18n } from 'vue-i18n';
import ar from './ar';
import ja from './ja';
import ko from './ko';
import ru from './ru';
import en from './en';
import zh_CN from './zh_CN';
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('language') || 'en',
    messages: {
        ar,
        ja,
        ko,
        ru,
        en,
        zh_CN
    }
})
export default i18n


