const zh_CN = {
  message: {
    username: '用户名',
    password: '密 码',
    save: '保存',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    currentLanguage: '中文（简体）',
    search: '搜索',
    popular: '热门',
    topic: '话题',
    goldenSentenceList: '金句榜',
    askQuestions: '提问',
    SignIn: '登 录',
    register: '注册',
    home: '首页',
    information: '消息',
    register_tis: '还没有Keepfly?',
    register_Now: '立即注册',
    sign_in_now: '立即登录',
    homepage: '主页',
    no_account_yet: '还没账号?',
    welcome: '欢迎您',
    account_number: '账 号(邮箱)',
    account_register: '账号注册',
    nickname: '昵 称',
    email: '邮箱',
    mobile: '手机号',
    confirm_password: '确定密码',
    verification_code: '验证码',
    gain: '获取',
    tips1: '请输入邮箱',
    tips2: '请输入密码',
    tips3: '请输入昵称',
    tips4: '请输入确定密码',
    tips5: '两次输入的密码不一致',
    tips6: '请输入确定验证码',
    follow_all: '全部关注',
    up_to_date: '发金句',
    friends: '好友圈',
    deceased: '逝者管理',
    tips7: '有什么新鲜事想分享给大家？',
    emote: '表情',
    picture: '图片',
    essay: '文章',
    more: '查看更多',
    send: '发送',
    all: '全部',
    advanced_search: '高级搜索',
    userinfo: '用户资料',
    log_out: '退出登录',
    tips8: '想用什么话题',
    find_it: '找一找',
    home_page: '主页',
    read: '阅读',
    discuss: '讨论',
    click_refresh: '点击刷新',
    set: '设置',
    edit_data: '编辑资料',
    privacy_setting: '隐私设置',
    tips9: '帐号信息设置',
    sign: '签名',
    tips10: '4～30个字符，支持中英文、数字',
    tips11: '介绍一下自己吧',
    tips12: '1～140个字符',
    tips13: '个人基本资料',
    gender: '性别',
    man: '男',
    woman: '女',
    other: '其他',
    birthday: '生日',
    emotional_status: '感情状况',
    tips14: '选择感情状况',
    address: '所在地',
    hometown: '家乡',
    single: '单身',
    seeking_a_relationship: '求交往',
    in_secret_love: '暗恋中',
    ambiguous: '暧昧中',
    in_love: '恋爱中',
    engaged: '订婚',
    married: '已婚',
    separated: '分居',
    divorced: '离异',
    widow: '丧偶',
    not_selected: '不选',
    tips15: '是否允许陌生人查看我的',
    personal_homepage: '个人主页',
    my_home_page: '我的主页',
    my_attention: '我的关注',
    my_fans: '我的粉丝',
    tips16: '暂无简介',
    belong_to: '归属',
    add: '加入',
    look_around_the_world: '全球找一找',
    registration_of_seeking_relatives: '寻亲发布',
    name_of_missing_person: '失踪人姓名',
    age: '年龄',
    debatable_time: '失踪时间',
    location_of_disappearance: '失踪地点',
    feature_description: '特征描述',
    photograph: '照片',
    check_in: '发布',
    contact_number: '联系电话',
    front_cover: '广场封面',
    missing_people: '失踪人员',
    created_by: '创建人',
    creation_time: '按创建时间',
    comment: '评论',
    praise: '赞',
    system_notification: '系统通知',
    tips17: '请输入正确的邮箱',
    tips17_o: '请输入手机号',
    avatar: '头像',
    tips18: '账号已存在',
    tips19: '注册失败',
    tips20: '验证码错误',
    tips21: '重新获取验证码',
    tips22: '发送验证码成功',
    tips23: '登录成功',
    tips24: '密码错误',
    tips25: '用户不存在',
    tips26: '查看完整热搜榜单',
    help_center: '帮助中心',
    customer: '客服',
    self_service_center: '自助服务中心',
    website_related: '网站相关',
    tips27: '合作服务',
    cooperation_hotline: '合作热线',
    follow: '关注',
    tips28: '提供线索',
    view_the_original_article: '查看原文',
    message: '消息',
    tips29: '添加成功',
    tips30: '添加失败',
    tips31: '发布你的评论',
    tips32: '已关注',
    tips33: '注册成功',
    tips34: '不能自己关注自己',
    cancel: '取关',
    tips35: '删除成功',
    tips36: '删除失败',
    name_of_the_deceased: '逝者姓名',
    nationality: '国籍',
    date_of_birth: '出生日期',
    date_of_death: '逝世日期',
    university: '毕业院校',
    occupation: '职业',
    achievements: '个人成就',
    tips37: '逝者姓名不能为空',
    tips38: '选择头像',
    tips39: '国籍不能为空',
    tips40: '选择出生日期',
    tips41: '选择逝世日期',
    tips42: '创建成功',
    tips43: '创建失败',
    tips44: '全部粉丝',
    account_of_the_deceased: '逝者账号',
    add_an_account: '添加账号',
    view: '查看',
    transfer: '转移',
    newly_increased: '新增',
    opposite_id: '对方ID',
    transfer_failed: '转移失败',
    transfer_successful: '转移成功',
    tips45: '不能自己转给自己',
    tips46: '暂无逝者账号',
    tips47: '暂无关注',
    tips48: '暂无粉丝',
    determine: '确定',
    call_off: '取消',
    tips49: '确定取消关注该用户？',
    tips50: '有什么金句分享给大家？',
    tips51: '修改成功',
    tips52: '修改失败',
    hint: '提示',
    tips53: '确定转发？',
    repeat: '转发',
    hot_search: '热搜',
    know: '知道',
    tips54: '总有一个人知道',
    tips55: '你问题的答案',
    solve: '解决',
    question: '问题',
    tips56: '知道须知',
    tips57: '我要提问',
    submit: '提交',
    quick_answer: '快速回答',
    translate: '翻译',
    tips58: '翻译失败',
    recommend: '关注推荐',
    effect: '影响力',
    number_of_fans: '粉丝数',
    quantity: '内容数',
    fans: '粉丝',
    tips59: '送花成功',
    tips60: '送花失败',
    tips61: '编辑成功',
    tips62: '编辑失败',
    tips63: '请输入姓名',
    tips64: '请输入关系',
    tips65: '请选择图片',
    tips66: '请输入标题',
    tips67: '请输入内容',
    tips68: '请输入板块名',
    tips69: '板块已存在',
    tips70: '确定送花？',
    tips71: '与逝者关系',
    album: '图片集',
    deceased_admin: '逝者管理员',
    creator: '创建者',
    the_deceased: '逝者',
    number_of_views: '浏览次数',
    update_time: '更新时间',
    basic_info: '基本信息',
    full_name: '姓名',
    alias: '别名',
    language: '擅长语言',
    height: '身高',
    motto: '座右铭',
    interaction: '互动',
    input_relationship: '录入关系',
    content_addition: '内容添加',
    image_upload: '图片上传',
    message_management: '留言管理',
    deceased_label: '逝者标签',
    modify: '编辑',
    image_photo: '形象照',
    brief: '简介',
    label: '标签',
    increase: '添加',
    constellation: '星座',
    relationship: '关系',
    title: '标题',
    add_section: '添加板块',
    modify_section: '修改板块',
    module_name: '板块名称',
    content: '内容',
    section: '板块',
    guestbook: '留言',
    operate: '操作',
    early_experiences: '早年经历',
    deductive_experience: '工作成就',
    personal_life: '个人生活',
    award_records: '重大事件',
    character_evaluation: '熟人评价',
    deceased_square: '逝者广场',
    memorial_plaza: '纪念广场',
    ranking_list1: '送花榜',
    release: '发布',
    message_list: '留言榜',
    tips72: '删除请联系管理员',
    tips73: '请输入11位手机号',
    none: '暂无',
    details: '详情',
    log_on: '登录',
    previous_article: '上一篇',
    next_article: '下一篇',
    chapter_1: '第一篇',
    last_article: '最后一篇',
    tips74: '没有更多的数据',
    unknown: '未知',
    tips75: '影响力由用户每天登陆时长、互动数综合体现',
    tips76: '侵犯知识产权',
    tips77: '谣言虚假',
    tips78: '内容质量差',
    tips79: '侵犯名誉',
    tips80: '低俗色情',
    tips81: '涉嫌诈骗',
    tips82: '政治相关',
    tips83: '营销广告',
    tips84: '宣传不良价值观',
    report: '举报',
    report_content: '举报内容',
    reported_image: '举报图片',
    reporting_link: '举报链接',
    reporting_type: '举报类型',
    join: '参与',
    interact: '互动',
    tips85: '用户被停用',
    tips86: '邮箱验证码',
    change_password: '修改密码',
    confirm: '确认',
    return: '返回',
    tips87: '我的提问',
    recommendo: '推荐',
    tips88: '推荐这个答案？',
    tips89: '推荐成功',
    tips90: '推荐失败',
    tips91: '问题不存在',
    tips92: '图片大小不能大于10M！',
    tips93: '图片大小不能为0M！',
    tips94: '图片比例不是1:1',
    tips95: '提示：图片请按照1:1尺寸上传，否则将无法成功；头像图片请将主人物居于正中；整体图片质量低于10M',
    tips96: '图片比例不是16:9',
    tips97: '设置新密码',
    tips98: '不选为未知',
    tips99: '请输入ID',
    tips100: '原创保护：著作权归作者所有',
    tips101: '声明：发布内容与平台无关，如发现不实信息可举报',
    tips102: '建议 上传4:3横长形图片，整体图片质量低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips103: '建议 上传3:4竖长形图片，整体图片质量低于10M，支持jpg, jpeg, bmp, gif, png格式',
    id: 'ID',
    tips104: '收到的评论',
    private_letter: '私信',
    reply: '回复',
    the_original: '原文',
    change: '更改',
    change_avatar: '换头像',
    tips105: '建议 上传1:1尺寸，整体图片质量低于10M，支持jpg, jpeg, bmp, gif, png格式',
    change_email: '更换邮箱',
    new_email: '新邮箱',
    tips106: '该邮箱已注册',
    tips107: '24小时客服邮箱',
    tips108: '著作权归作者所有',
    tips109: '个人主页设置',
    tips110: '设置成功',
    tips111: '设置失败',
    tips112: '忘记密码',
    tips113: '仅该用户自己可以查看',
    tips114: '仅该用户好友可以查看',
    year: '年',
    moon: '月',
    day: '日',
    B_C: '公元前',
    tips115: '按逝世年份',
    tips116: '不能给自己发私信',
    tips117: '按下 ENTER 发送',
    tips118: '查看私信内容~~~',
    tips119: '还没有私信~~~',
    search_result: '搜索结果',
    comprehensive: '综合',
    tips120: '已添加黑名单',
    tips121: '不能添加自己为黑名单',
    tips122: '加入黑名单',
    tips123: '对方已将您加入黑名单，无法对其发送消息',
    tips124: '您已将对方加入黑名单，无法对其发送消息',
    blacklist: '黑名单',
    die_at_the_age_of: '享年',
    tips125: '删除对话？',
    tips126: '不是有效日期格式',
    just: '刚刚',
    tips127: '1分钟前',
    minute_ago: '分钟前',
    tips128: '1小时前',
    hour_ago: '小时前',
    yesterday: '昨天',
    days_ago: '天前',
    weeks_ago: '周前',
    tips129: '姓名 ',
    about_us: '关于我们',
    cooperation: '招商合作',
    walk_into: '走进',
    tips130: '通过网站或电话咨询公司销售经理了解',
    tips131: '洽谈合作：可电话洽谈或直接来参观考察洽谈',
    tips132: '签订协议确定合作方式',
    tips133: '服务热线',
    tips134: '合作电话',
    customer_service_1: '客服一',
    customer_service_2: '客服二',
    tips135: '申请内容添加/纠正',
    tips136: '姓名 关键词',
    tips137: '查看完整留言',
    message_board: '留言板',
    tips138: '逝者主页',
    copy: '复制',
    my_answer: '我的回答',
    my_password: '我的密码',
    tools: '工具',
    height_unit: '身高单位',
    tips139: '建议上传100×138竖长形图片，整体图片质量低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips140: '建议上传220×231竖长形图片，整体图片质量低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips141: '删除板块',
    tips142: '我发布的金句',
    tips143: '禁止私聊',
    tips144: '提示：如果已勾选未知按钮，系统将显示未知',
    replicating_success: '复制成功',
    tips145: '建议图片按1:1尺寸上传，头像图片请将主人物居于正中；整体图片质量低于10M',
    tips146: '关注失败',
    tips147: '已取消关注',
    tips148: '取消关注失败',
    tips149: '已相互关注',
    tips150: '相互关注失败',
    tips151: '已将用户移出黑名单',
    tips152: '将用户移出黑名单失败',
    tips153: '已发布',
    tips154: '发布失败',
    tips155: '已提交',
    tips156: '提交失败',
    self: '自己',
    sending_flowers: '送花',
    successfully_saved:'保存成功',
    save_failed:'保存失败',
    clue: '线索',
    tips157: '填写与逝者关系身份，如丈夫、母亲、朋友',
    tips158: '建议上传1 : 1比例正方形图片，图片大小低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips159: '请上传1 : 1比例正方形图片，否则将无法成功。头像图片请将主人物居于正中，图片大小低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips160: '建议上传220 : 230比例竖长形图片，图片大小低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips161: '建议上传100 : 140比例竖长形图片，图片大小低于10M，支持jpg, jpeg, bmp, gif, png格式',
    tips162: '建议上传100 : 56比例横长形图片，图片大小低于10M，支持jpg, jpeg, bmp, gif, png格式',
    admin: '管理员',
    tourist: '游客',
    tips163: '发布成功',
    id_number_copied: 'ID号已复制',
    tips164: '线索提供成功',
    tips165: '线索提供失败',
    tips166: '金句复制成功',
    tips167: '取消关注成功',
    tips168: '取消关注失败',
    tips169: '发布金句成功',
    tips170: '发布金句失败',
    tips171: '评论成功',
    tips172: '评论失败',
    tips173: '回答成功',
    tips174: '回答失败',
    tips175: '发布提问成功',
    tips176: '发布提问失败',
    tips177: '留言失败',
    deceasedo: '添加逝者',
    tips178: '请输入注销ID',
    tips179: '账号不正确，请重新核查ID',
    tips180: '请输入注销理由',
    tips181: '申请成功！keepfly将在7个工作日内处理您的申请',
    tips182: '申请失败',
    tips183: '注销ID',
    tips184: '注销邮箱',
    tips185: '注销理由',
    apply: '申请',
    tips186: '您正在注销keepfly账号，后续将无法使用keepfly产品和服务，因此请您谨慎操作！',
    tips187: 'keepfly将不保留您任何操作记录，也无法在注销后申请恢复记录。',
    tips188: '注销账号',
    tips189: '请勿为在世人员建立逝者账号，如有法律风险与平台无关',
    video: '视频',
    tips190: '图片视频只能上传一种，确认？',
    tips191: '送花不能超过20分钟',
    delete_account: '删除账号',
  }
}
export default zh_CN