<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side">
      <div class="Nav_main" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner wbpro-scrollbar"
        >
          <h2 class="Nav_title">{{ $t("message.personal_homepage") }}</h2>
          <div>
            <div>
              <a class="ALink_none" @click="setNavTab6(1)">
                <div
                  :class="[
                    navTab6Show == 1
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.my_home_page')"
                >
                  <home-outline
                    class="keepfly-font keepfly-font--navProfile NavItem_icon"
                  ></home-outline>
                  <span class="NavItem_text">{{ $t("message.my_home_page") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab6(2)">
                <div
                  :class="[
                    navTab6Show == 2
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.my_attention')"
                >
                  <svg
                    class="keepfly-font keepfly-font--navFollows NavItem_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 20 20"
                  >
                    <g fill="none">
                      <path
                        d="M3 6a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v3.6a5.465 5.465 0 0 0-1-.393V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h3.207c.099.349.23.683.393 1H6a3 3 0 0 1-3-3V6zm4.974 6.553c.303.151.72.307 1.25.389a5.474 5.474 0 0 0-.194.982a5.22 5.22 0 0 1-1.503-.477a4.194 4.194 0 0 1-.64-.39a2.93 2.93 0 0 1-.217-.181l-.015-.014l-.005-.005l-.002-.002l-.002-.001a.5.5 0 0 1 .705-.71l.003.002l.022.02c.022.02.06.051.111.09c.104.078.266.187.487.297zM7.5 9a1 1 0 1 0 0-2a1 1 0 0 0 0 2zm6-1a1 1 0 1 1-2 0a1 1 0 0 1 2 0zm5.5 6.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-4-2a.5.5 0 0 0-1 0V14h-1.5a.5.5 0 0 0 0 1H14v1.5a.5.5 0 0 0 1 0V15h1.5a.5.5 0 0 0 0-1H15v-1.5z"
                        fill="currentColor"
                      ></path>
                    </g>
                  </svg>
                  <span class="NavItem_text">{{ $t("message.my_attention") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab6(3)">
                <div
                  :class="[
                    navTab6Show == 3
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.my_fans')"
                >
                  <people-outline
                    class="keepfly-font keepfly-font--navFans NavItem_icon"
                  ></people-outline>
                  <span class="NavItem_text">{{ $t("message.my_fans") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab6(4)">
                <div
                  :class="[
                    navTab6Show == 4
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.deceased')"
                >
                  <id-card-outline
                    class="keepfly-font keepfly-font--navMutual NavItem_icon"
                  ></id-card-outline>
                  <span class="NavItem_text">{{ $t("message.deceased") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab6(5)">
                <div
                  :class="[
                    navTab6Show == 5
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.find_it')"
                >
                  <footsteps-outline
                    class="keepfly-font keepfly-font--navMutual NavItem_icon"
                  ></footsteps-outline>
                  <span class="NavItem_text">{{ $t("message.find_it") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab6(6)">
                <div
                  :class="[
                    navTab6Show == 6
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.blacklist')"
                >
                  <svg
                    class="keepfly-font keepfly-font--navMutual NavItem_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 20 20"
                  >
                    <g fill="none">
                      <path
                        d="M9 2a4 4 0 1 0 0 8a4 4 0 0 0 0-8zm-4.991 9A2.001 2.001 0 0 0 2 13c0 1.691.833 2.966 2.135 3.797C5.417 17.614 7.145 18 9 18c.41 0 .816-.019 1.21-.057A5.477 5.477 0 0 1 9 14.5c0-1.33.472-2.55 1.257-3.5H4.01zM10 14.5a4.5 4.5 0 1 0 9 0a4.5 4.5 0 0 0-9 0zm2.404 2.803l4.9-4.9a3.5 3.5 0 0 1-4.9 4.9zm-.707-.707a3.5 3.5 0 0 1 4.9-4.9l-4.9 4.9z"
                        fill="currentColor"
                      ></path>
                    </g>
                  </svg>
                  <span class="NavItem_text">{{ $t("message.blacklist") }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full">
          <div v-if="navTab6Show == 1">
            <div>
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="wbpro-pos">
                  <div class="keepfly-picture-main ProfileHeader_pic">
                    <div style="padding-bottom: 36.25%"></div>
                    <n-image class="keepfly-picture-img" :src="kukesystemInfo.bjurl" />
                    <div class="keepfly-picture-cover"></div>
                  </div>
                </div>
                <div class="keepfly-box-flex keepfly-box-alignStart ProfileHeader_box1">
                  <div
                    class="keepfly-avatar-hover ProfileHeader_avatar2"
                    style="width: 6.25rem; height: 6.25rem"
                  >
                    <n-image
                      class="keepfly-avatar-img"
                      v-if="keepflyUserInfo.avatar == null"
                      src="http://img.keepfly.net/ossimage/banner1685434778000.jpg"
                    />
                    <n-image
                      class="keepfly-avatar-img"
                      v-else
                      :src="keepflyUserInfo.avatar"
                    />
                    <div class="keepfly-avatar-hoverMask"></div>
                  </div>
                  <div class="keepfly-box-item-flex" style="overflow: hidden">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h3"
                    >
                      <div class="ProfileHeader_name">
                        {{ keepflyUserInfo.nickname }}
                      </div>
                      <span class="keepfly-icon-wrap" style="margin-left: 5px">
                        <male
                          class="keepfly-icon-main keepfly-icon--male"
                          v-if="keepflyUserInfo.gender == 1"
                          style="width: 1rem; height: 1rem"
                        ></male>
                        <female
                          class="keepfly-icon-main keepfly-icon--male"
                          v-else-if="keepflyUserInfo.gender == 2"
                          style="width: 1rem; height: 1rem"
                        >
                        </female>
                        <male-female
                          class="keepfly-icon-main keepfly-icon--male"
                          v-else
                          style="width: 1rem; height: 1rem"
                        ></male-female>
                      </span>
                    </div>
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                    >
                      <a
                        class="ALink_none ProfileHeader_alink ProfileHeader_pointer"
                        href="/u/page/follow/7326813782?relate=fans"
                      >
                        <span>
                          {{ $t("message.fans") }}<span>{{ userNum.ffnum }}</span>
                        </span>
                      </a>
                      <a
                        class="ALink_none ProfileHeader_alink ProfileHeader_pointer"
                        href="/u/page/follow/7326813782?relate="
                      >
                        <span>
                          {{ $t("message.follow") }}<span>{{ userNum.fnum }}</span>
                        </span>
                      </a>
                    </div>
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                      style="font-size: 13px"
                    >
                      ID：{{ keepflyUserInfo.kpId }}
                      <div
                        @click="copyCode(keepflyUserInfo.kpId)"
                        style="
                          display: inline-block;
                          background: #f0f1f4;
                          padding: 0 7px;
                          border-radius: 10px;
                          cursor: pointer;
                        "
                      >
                        <svg
                          style="width: 19px; padding-top: 6px"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 16 16"
                        >
                          <g fill="none">
                            <path
                              d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-bottom: 20px">
                  <div class="ProfileHeader_box2">
                    <div class="keepfly-box-flex keepfly-box-justifyBetween">
                      <div
                        class="keepfly-box-flex keepfly-box-column ProfileHeader_content"
                      >
                        <div class="" style="margin-bottom: 12px">
                          <div class="keepfly-box-item-inlineBlock">
                            <div class="keepfly-box-flex keepfly-box-alignStart">
                              <div
                                class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                              >
                                <document-text-outline
                                  class="keepfly-font keepfly-font--proBintro"
                                ></document-text-outline>
                              </div>
                              <div class="keepfly-box-item-flex ProfileHeader_con3">
                                {{
                                  keepflyUserInfo.profile == null ||
                                  keepflyUserInfo.profile == ""
                                    ? $t("message.tips16")
                                    : keepflyUserInfo.profile
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ProfileHeader_box3">
                    <div class="">
                      <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                        <div class="keepfly-box-flex keepfly-box-alignStart">
                          <div
                            class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                          >
                            <calendar-outline
                              class="keepfly-font keepfly-font--proTime"
                            ></calendar-outline>
                          </div>
                          <div
                            class="keepfly-box-item-flex ProfileHeader_con3"
                            v-if="keepflyUserInfo.createTime"
                          >
                            {{ keepflyUserInfo.createTime.substring(0, 10) }}
                            {{ $t("message.add") }} keepfly
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="ProfileHeader_box3"
                    v-if="keepflyUserInfo.birthday || keepflyUserInfo.emotionalStatus"
                  >
                    <div
                      v-if="keepflyUserInfo.birthday"
                      class="keepfly-box-item-inlineBlock ProfileHeader_item3"
                    >
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <svg
                            style="width: 19px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none">
                              <path
                                d="M12 1c-.95 0-1.585.682-1.937 1.282c-.361.619-.563 1.374-.563 1.968c0 .494.063 1.174.432 1.746C10.342 6.63 11.038 7 12 7s1.658-.37 2.068-1.004c.37-.572.432-1.252.432-1.746c0-.594-.202-1.35-.563-1.968C13.586 1.682 12.95 1 12 1zm-1 3.25c0-.31.121-.805.358-1.21c.248-.424.487-.54.642-.54c.155 0 .394.116.642.54c.237.405.358.9.358 1.21c0 .41-.062.73-.193.932c-.09.14-.27.318-.807.318c-.538 0-.717-.178-.807-.318C11.063 4.98 11 4.66 11 4.25zm9.5 6v8.25h.75a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5h.75v-8.25A2.25 2.25 0 0 1 5.75 8h12.5a2.25 2.25 0 0 1 2.25 2.25zm-15.5 0v2.091l2.447 1.58a1.25 1.25 0 0 0 1.356 0l1.747-1.128a2.75 2.75 0 0 1 2.9-.05l2.009 1.199a1.25 1.25 0 0 0 1.359-.05L19 12.36v-2.11a.75.75 0 0 0-.75-.75H5.75a.75.75 0 0 0-.75.75zm12.68 4.87a2.75 2.75 0 0 1-2.99.11l-2.008-1.2a1.25 1.25 0 0 0-1.319.023l-1.747 1.128a2.75 2.75 0 0 1-2.982 0L5 14.126V18.5h14v-4.307l-1.32.926z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div
                          class="keepfly-box-item-flex ProfileHeader_con3"
                          style="width: 110px"
                        >
                          {{ keepflyUserInfo.birthday }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="keepflyUserInfo.emotionalStatus"
                      class="keepfly-box-item-inlineBlock ProfileHeader_item3"
                    >
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <svg
                            style="width: 19px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                          >
                            <path
                              d="M26 30h-4a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.946 2.946 0 0 1 3-3h6a2.946 2.946 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2zm-5-18a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M24 9a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M10 20.184V12H8v8.184a3 3 0 1 0 2 0z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M9 30a6.993 6.993 0 0 1-5-11.89V7a5 5 0 0 1 10 0v11.11A6.993 6.993 0 0 1 9 30zM9 4a3.003 3.003 0 0 0-3 3v11.983l-.332.299a5 5 0 1 0 6.664 0L12 18.983V7a3.003 3.003 0 0 0-3-3z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{ checkfeel(keepflyUserInfo.emotionalStatus) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ProfileHeader_box3" v-if="keepflyUserInfo.hometown">
                    <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <svg
                            style="width: 19px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 20 20"
                          >
                            <g fill="none">
                              <path
                                d="M8.998 2.389a1.5 1.5 0 0 1 2.005 0l5.5 4.941A1.5 1.5 0 0 1 17 8.446V9.4a2.98 2.98 0 0 0-1-.36v-.595a.5.5 0 0 0-.166-.372l-5.5-4.942a.5.5 0 0 0-.668 0l-5.5 4.942A.5.5 0 0 0 4 8.446V15.5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5V12A1.5 1.5 0 0 1 9 10.5h2a1.5 1.5 0 0 1 1.5 1.5v2.208a2.51 2.51 0 0 0-1 .792v-3a.5.5 0 0 0-.5-.5H9a.5.5 0 0 0-.5.5v3.5A1.5 1.5 0 0 1 7 17H4.5A1.5 1.5 0 0 1 3 15.5V8.446c0-.426.18-.831.498-1.116l5.5-4.941zM17.5 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0zm1.5 4.5c0 1.245-1 2.5-3.5 2.5S12 17.75 12 16.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div
                          class="keepfly-box-item-flex ProfileHeader_con3"
                          style="width: 110px"
                        >
                          {{ keepflyUserInfo.hometown }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ProfileHeader_box3" v-if="keepflyUserInfo.address">
                    <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <svg
                            style="width: 19px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{ keepflyUserInfo.address }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div
                class="wbpro-screen-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
              >
                <div>{{ $t("message.all") }} keepfly（{{ userNum.mnum }}）</div>
              </div>
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                      >
                        <div
                          class="vue-recycle-scroller__item-wrapper"
                          style="min-height: 684px"
                        >
                          <div class="" v-if="usermicroblogs.length > 0">
                            <div
                              class="wbpro-scroller-item"
                              v-for="(item, index) in usermicroblogs"
                              :key="item"
                            >
                              <article
                                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                                tabindex="0"
                                style="border-radius: 4px"
                              >
                                <div class="Feed_body">
                                  <header class="keepfly-box-flex">
                                    <a class="ALink_default">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                        style="width: 3.25rem; height: 3.25rem"
                                      >
                                        <img
                                          :src="item.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                    </a>
                                    <div class="keepfly-box-item-flex head_main">
                                      <div
                                        class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                        >
                                          <a class="ALink_default head_cut head_name">
                                            <span :title="item.nickname">{{
                                              item.nickname
                                            }}</span>
                                          </a>
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter"
                                          ></div>
                                        </div>
                                        <div
                                          class="keepfly-box-alignCenter keepfly-box-justifyCenter head-info_info"
                                        >
                                          <a class="head-info_time">
                                            {{ item.createTime }}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="keepfly-box-flex">
                                      <div class="keepfly-pop-wrap morepop_more">
                                        <span class="keepfly-pop-ctrl">
                                          <div
                                            @click="showdeleteview(item)"
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                          >
                                            <chevron-down
                                              class="keepfly-font keepfly-font--angleDown morepop_action"
                                              style="width: 16px"
                                            ></chevron-down>
                                          </div>
                                        </span>
                                        <div
                                          v-if="item.isdelete == 1"
                                          class="keepfly-pop-main keepfly-pop-down keepfly-pop-end"
                                        >
                                          <div class="keepfly-pop-wrap-main">
                                            <div
                                              @click="
                                                deleteusermicroblogsinfo(item, index)
                                              "
                                              class="keepfly-box-flex keepfly-box-alignCenter keepfly-pop-item-main"
                                              role="button"
                                            >
                                              {{ $t("message.delete") }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </header>
                                  <div class="Feed_retweet">
                                    <div
                                      class="detail_text detail_reText wbpro-feed-reText"
                                    >
                                      <n-ellipsis
                                        expand-trigger="click"
                                        line-clamp="4"
                                        :tooltip="false"
                                      >
                                        <div
                                          class="detail_wbtext"
                                          v-html="filters(item.content)"
                                        ></div>
                                      </n-ellipsis>
                                    </div>
                                    <div class="picture picture-box_row">
                                      <span
                                        v-for="titem in item.tags"
                                        :key="titem"
                                        style="
                                          color: #ff8200;
                                          padding-right: 10px;
                                          font-size: 12px;
                                        "
                                        :title="titem"
                                      >
                                        #{{ titem }}
                                      </span>
                                    </div>
                                    <div
                                      class="picture picture-box_row"
                                      v-if="item.images != []"
                                    >
                                      <div
                                        class="u-col-3 keepfly-box-wrap picture_inlineNum3"
                                      >
                                        <n-image-group>
                                          <n-space>
                                            <n-image
                                              width="133"
                                              height="133"
                                              v-for="oitem in item.images"
                                              :key="oitem"
                                              :src="oitem"
                                              style="border-radius: 15px"
                                            />
                                          </n-space>
                                        </n-image-group>
                                      </div>
                                    </div>
                                    <div v-if="item.videoInfo">
                                      <video
                                        controls="controls"
                                        :src="item.videoInfo"
                                        class="video"
                                        style="
                                      width: 400px;
                                      vertical-align: middle;
                                      height: 225px;
                                      background: #000;
                                      border-radius: 10px;
                                    "
                                      ></video>
                                    </div>
                                  </div>
                                </div>
                                <footer class="">
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                                  >
                                    <div
                                      class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                                    >
                                      <div
                                        @click="getCommentView(item, 1)"
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        style="cursor: pointer"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                        >
                                          <ellipsis-horizontal-circle
                                            class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                          ></ellipsis-horizontal-circle>
                                        </div>
                                        <span class="toolbar_num"> {{ item.cnum }} </span>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                                    >
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                      >
                                        <button
                                          @click="likeClick(item)"
                                          class="keepfly-like-main toolbar_btn"
                                        >
                                          <svg
                                            style="width: 19px"
                                            class="keepfly-like-iconWrap"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 32 32"
                                          >
                                            <path
                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                          <span class="keepfly-like-count">{{
                                            item.lnum
                                          }}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </footer>
                                <div v-if="item.show">
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                  ></div>
                                  <div class="Feed_box">
                                    <div class="keepfly-box-flex Feed_mar2">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                        style="width: 2.125rem; height: 2.125rem"
                                      >
                                        <img
                                          :src="infodata.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div class="keepfly-box-item-flex">
                                        <div>
                                          <div>
                                            <div
                                              class="wbpro-form Form_wbproform"
                                              compser="true"
                                            >
                                              <textarea
                                                :placeholder="$t('message.tips31')"
                                                v-model="item.commentValue"
                                                maxlength="100"
                                                class="Form_input"
                                                style="height: 84px"
                                              ></textarea>
                                            </div>
                                          </div>
                                          <div class="Composer_mar1">
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter"
                                            >
                                              <button
                                                @click="sendInfoComment(item)"
                                                class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                              >
                                                <span class="keepfly-button-wrap">
                                                  <span class="keepfly-button-content">{{
                                                    $t("message.comment")
                                                  }}</span>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                    ></div>
                                    <div>
                                      <div>
                                        <div class="RepostCommentFeed_mar1">
                                          <div class="wbpro-list">
                                            <div
                                              class="item1"
                                              v-for="(oitem, index) in item.commentVos"
                                              :key="oitem"
                                            >
                                              <div class="item1in keepfly-box-flex">
                                                <div>
                                                  <a class="ALink_default">
                                                    <div
                                                      class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                      style="
                                                        width: 2.125rem;
                                                        height: 2.125rem;
                                                      "
                                                    >
                                                      <img
                                                        :src="oitem.fromUser.avatar"
                                                        class="keepfly-avatar-img"
                                                      />
                                                    </div>
                                                  </a>
                                                </div>
                                                <div class="con1 keepfly-box-item-flex">
                                                  <div class="text">
                                                    <a class="ALink_default">{{
                                                      oitem.fromUser.nickname
                                                    }}</a>
                                                    <a
                                                      class="ALink_none"
                                                      target="_blank"
                                                    ></a
                                                    >:
                                                    <span v-if="oitem.contentAr">{{
                                                      oitem.contentAr
                                                    }}</span>
                                                    <span v-if="oitem.contentEn">{{
                                                      oitem.contentEn
                                                    }}</span>
                                                    <span v-if="oitem.contentJa">{{
                                                      oitem.contentJa
                                                    }}</span>
                                                    <span v-if="oitem.contentKo">{{
                                                      oitem.contentKo
                                                    }}</span>
                                                    <span v-if="oitem.contentRu">{{
                                                      oitem.contentRu
                                                    }}</span>
                                                    <span v-if="oitem.contentZh">{{
                                                      oitem.contentZh
                                                    }}</span>
                                                  </div>
                                                  <div
                                                    class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                                  >
                                                    <div>
                                                      {{ oitem.createDate }}
                                                    </div>
                                                    <div class="opt keepfly-box-flex">
                                                      <div
                                                        @click="
                                                          closeCommentInfo(
                                                            item,
                                                            oitem,
                                                            index
                                                          )
                                                        "
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter optHover"
                                                      >
                                                        {{ $t("message.delete") }}
                                                      </div>
                                                      <div
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                      >
                                                        <button
                                                          class="keepfly-like-main IconList_likebox"
                                                          :title="$t('message.praise')"
                                                          @click="
                                                            commentVoslikeClick(oitem)
                                                          "
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style="width: 22px"
                                                            class="keepfly-like-iconWrap"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 32 32"
                                                          >
                                                            <path
                                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                          <span
                                                            class="keepfly-like-count"
                                                            >{{
                                                              oitem.lnum == null
                                                                ? 0
                                                                : oitem.lnum
                                                            }}</span
                                                          >
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                        ></div>
                                        <a
                                          class="ALink_default"
                                          @click="morecommentVos(item)"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                          >
                                            <div
                                              v-if="
                                                item.commentVos != null &&
                                                item.commentVos.length < item.total
                                              "
                                              style="color: #ccc"
                                            >
                                              {{ $t("message.more") }}
                                            </div>
                                            <div style="color: #ccc" v-else>
                                              {{ $t("message.tips74") }}
                                            </div>
                                            <i
                                              class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                            ></i>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                          <div v-else>
                            <div style="background: #fff; padding: 40px 10px">
                              <n-empty :description="$t('message.none')">
                                <template #extra></template>
                              </n-empty>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="navTab6Show == 2">
            <div>
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div
                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween ListTitle_tit"
                >
                  <h3 class="ListTitle_th3 ListTitle_f14 ListTitle_cla">
                    {{ $t("message.follow_all") }}（{{ followarrTotal }}）
                  </h3>
                </div>
                <div>
                  <div class="Scroll_container">
                    <div class="Scroll_wrap">
                      <div>
                        <div
                          class="vue-recycle-scroller ready page-mode direction-vertical"
                        >
                          <div class="vue-recycle-scroller__item-wrapper">
                            <div class="">
                              <div
                                v-if="followarr.length > 0"
                                class="wbpro-scroller-item"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-column UserFeedCard_userFeedCard"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter UserFeedCard_item"
                                    v-for="(item, index) in followarr"
                                    :key="item"
                                  >
                                    <a class="ALink_none UserFeedCard_left">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex UserFeedCard_con UserFeedCard_f12"
                                        style="align-self: center"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter UserFeedCard_cla UserFeedCard_fb UserFeedCard_cut"
                                        >
                                          {{ item.nickname }}
                                        </div>
                                        <div class="UserFeedCard_clb UserFeedCard_cut">
                                          {{
                                            item.hometown == null
                                              ? $t("message.tips16")
                                              : item.hometown
                                          }}
                                        </div>
                                      </div>
                                    </a>
                                    <div class="UserFeedCard_right">
                                      <div class="keepfly-pop-wrap">
                                        <span class="keepfly-pop-ctrl">
                                          <button
                                            v-if="item.isFriend == 0"
                                            class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                          >
                                            <span class="keepfly-button-wrap">
                                              <span class="keepfly-button-content">
                                                <svg
                                                  style="width: 19px"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path
                                                    d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                                                    fill="currentColor"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <button
                                            class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                          >
                                            <span class="keepfly-button-wrap">
                                              <span class="keepfly-button-content">
                                                <svg
                                                  style="width: 19px"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                                  viewBox="0 0 1024 1024"
                                                >
                                                  <defs></defs>
                                                  <path
                                                    d="M759 335c0-137-111-248-248-248S263 198 263 335c0 82.8 40.6 156.2 103 201.2c-.4.2-.7.3-.9.4c-44.7 18.9-84.8 46-119.3 80.6c-34.5 34.5-61.5 74.7-80.4 119.5C146.9 780.5 137 827 136 874.8c-.1 4.5 3.5 8.2 8 8.2h59.9c4.3 0 7.9-3.5 8-7.8c2-77.2 32.9-149.5 87.6-204.3C356 614.2 431 583 511 583c137 0 248-111 248-248zM511 507c-95 0-172-77-172-172s77-172 172-172s172 77 172 172s-77 172-172 172zm105 221h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H703.5l47.2-60.1c1.1-1.4 1.7-3.2 1.7-4.9c0-4.4-3.6-8-8-8h-72.6c-4.9 0-9.5 2.3-12.6 6.1l-68.5 87.1c-4.4 5.6-6.8 12.6-6.8 19.8c.1 17.7 14.4 32 32.1 32zm240 64H592c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h176.5l-47.2 60.1c-1.1 1.4-1.7 3.2-1.7 4.9c0 4.4 3.6 8 8 8h72.6c4.9 0 9.5-2.3 12.6-6.1l68.5-87.1c4.4-5.6 6.8-12.6 6.8-19.8c-.1-17.7-14.4-32-32.1-32z"
                                                    fill="currentColor"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                          <button
                                            :title="$t('message.cancel')"
                                            @click="cancelfollow(item, 1, index)"
                                            class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                                          >
                                            <span class="keepfly-button-wrap">
                                              <span class="keepfly-button-content">
                                                <!-- {{ $t("message.cancel") }} -->
                                                <svg
                                                  style="width: 19px"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                                  viewBox="0 0 20 20"
                                                >
                                                  <g fill="none">
                                                    <path
                                                      d="M5 6a4 4 0 1 1 8 0a4 4 0 0 1-8 0zm4-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6zM2 13c0-1.113.903-2 2.009-2h6.248a5.503 5.503 0 0 0-.657 1H4.009C3.448 12 3 12.447 3 13c0 1.309.622 2.284 1.673 2.953C5.743 16.636 7.265 17 9 17c.2 0 .398-.005.592-.015c.173.342.381.662.618.958c-.395.038-.8.057-1.21.057c-1.855 0-3.583-.386-4.865-1.203C2.833 15.967 2 14.69 2 13zm17 1.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.646-1.146a.5.5 0 0 0-.708-.708L14.5 13.793l-1.146-1.147a.5.5 0 0 0-.708.708l1.147 1.146l-1.147 1.146a.5.5 0 0 0 .708.708l1.146-1.147l1.146 1.147a.5.5 0 0 0 .708-.708L15.207 14.5l1.147-1.146z"
                                                      fill="currentColor"
                                                    ></path>
                                                  </g>
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <n-pagination
                                    v-model:page="currfollowarrPage"
                                    size="medium"
                                    :page-count="fpages"
                                    :page-slot="7"
                                    @update-page="onDatafollowarrChange"
                                  />
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x UserFeedCard_line"
                                  ></div>
                                </div>
                              </div>
                              <div v-else style="background: #fff; padding: 40px 10px">
                                <n-empty :description="$t('message.tips47')">
                                  <template #extra></template>
                                </n-empty>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="navTab6Show == 3">
            <div
              class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
            >
              <div
                class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween ListTitle_tit"
              >
                <h3 class="ListTitle_th3 ListTitle_f14 ListTitle_cla">
                  {{ $t("message.tips44") }}（{{ fansarrTotal }}）
                </h3>
              </div>
              <div page="myFans" showextend="">
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                      >
                        <div class="vue-recycle-scroller__item-wrapper">
                          <div class="" v-if="fansarr.length > 0">
                            <div
                              class="wbpro-scroller-item"
                              v-for="item in fansarr"
                              :key="item"
                            >
                              <div class="FollowFeed_wrap">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween FollowFeed_item"
                                >
                                  <a class="ALink_none UserCard_item">
                                    <div class="keepfly-box-flex keepfly-box-alignCenter">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex UserCard_con UserCard_f12"
                                        style="align-self: center"
                                      >
                                        <div
                                          class="UserCard_cla UserCard_fb wbpro-textcut"
                                        >
                                          {{ item.nickname }}
                                          <a class="ALink_none" target="_blank"></a>
                                        </div>
                                        <div class="UserCard_clb wbpro-textcut">
                                          {{
                                            item.hometown == null
                                              ? $t("message.tips16")
                                              : item.hometown
                                          }}
                                        </div>
                                        <!-- <div class="UserCard_clb">
                                          <span> {{ $t("message.fans") }} 8 </span>
                                        </div> -->
                                      </div>
                                    </div>
                                  </a>
                                  <div class="UserCard_right UserCard_followBtn">
                                    <div class="keepfly-pop-wrap">
                                      <span class="keepfly-pop-ctrl">
                                        <button
                                          v-if="item.isFriend == 0"
                                          @click="addfriend(item, 1)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                        <button
                                          v-else
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 1024 1024"
                                              >
                                                <defs></defs>
                                                <path
                                                  d="M759 335c0-137-111-248-248-248S263 198 263 335c0 82.8 40.6 156.2 103 201.2c-.4.2-.7.3-.9.4c-44.7 18.9-84.8 46-119.3 80.6c-34.5 34.5-61.5 74.7-80.4 119.5C146.9 780.5 137 827 136 874.8c-.1 4.5 3.5 8.2 8 8.2h59.9c4.3 0 7.9-3.5 8-7.8c2-77.2 32.9-149.5 87.6-204.3C356 614.2 431 583 511 583c137 0 248-111 248-248zM511 507c-95 0-172-77-172-172s77-172 172-172s172 77 172 172s-77 172-172 172zm105 221h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H703.5l47.2-60.1c1.1-1.4 1.7-3.2 1.7-4.9c0-4.4-3.6-8-8-8h-72.6c-4.9 0-9.5 2.3-12.6 6.1l-68.5 87.1c-4.4 5.6-6.8 12.6-6.8 19.8c.1 17.7 14.4 32 32.1 32zm240 64H592c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h176.5l-47.2 60.1c-1.1 1.4-1.7 3.2-1.7 4.9c0 4.4 3.6 8 8 8h72.6c4.9 0 9.5-2.3 12.6-6.1l68.5-87.1c4.4-5.6 6.8-12.6 6.8-19.8c-.1-17.7-14.4-32-32.1-32z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                        <!-- <button
                                          @click="cancelfans(item.userId, index)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <svg
                                                style="width: 19px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 20 20"
                                              >
                                                <g fill="none">
                                                  <path
                                                    d="M5 6a4 4 0 1 1 8 0a4 4 0 0 1-8 0zm4-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6zM2 13c0-1.113.903-2 2.009-2h6.248a5.503 5.503 0 0 0-.657 1H4.009C3.448 12 3 12.447 3 13c0 1.309.622 2.284 1.673 2.953C5.743 16.636 7.265 17 9 17c.2 0 .398-.005.592-.015c.173.342.381.662.618.958c-.395.038-.8.057-1.21.057c-1.855 0-3.583-.386-4.865-1.203C2.833 15.967 2 14.69 2 13zm17 1.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.646-1.146a.5.5 0 0 0-.708-.708L14.5 13.793l-1.146-1.147a.5.5 0 0 0-.708.708l1.147 1.146l-1.147 1.146a.5.5 0 0 0 .708.708l1.146-1.147l1.146 1.147a.5.5 0 0 0 .708-.708L15.207 14.5l1.147-1.146z"
                                                    fill="currentColor"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </span>
                                        </button> -->
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <n-pagination
                              v-model:page="currfansarrPage"
                              size="medium"
                              :page-count="ffpages"
                              :page-slot="7"
                              @update-page="onDatafansarrChange"
                            />
                          </div>
                          <div v-else style="background: #fff; padding: 40px 10px">
                            <n-empty :description="$t('message.tips48')">
                              <template #extra></template>
                            </n-empty>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="navTab6Show == 4">
            <div
              class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
            >
              <div
                class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween ListTitle_tit"
              >
                <h3 class="ListTitle_th3 ListTitle_f14 ListTitle_cla">
                  {{ $t("message.account_of_the_deceased") }}（{{
                    deceasedarrTotal == null ? 0 : deceasedarrTotal
                  }}）
                </h3>
                <a class="ALink_none">
                  <div
                    @click="deletedeceasedaccountView"
                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter ListTitle_topt ListTitle_f12 ListTitle_clb ListTitle_cll"
                  >
                    <svg
                      class="keepfly-font keepfly-font--angleRight"
                      style="width: 11px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none">
                        <path
                          d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                    <span>{{ $t("message.delete_account") }}</span>
                  </div>
                  <div
                    @click="adddeceasedaccountView"
                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter ListTitle_topt ListTitle_f12 ListTitle_clb ListTitle_cll"
                  >
                    <add
                      class="keepfly-font keepfly-font--angleRight"
                      style="width: 11px"
                    ></add>
                    <span>{{ $t("message.add_an_account") }}</span>
                  </div>
                </a>
              </div>
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                      >
                        <div class="vue-recycle-scroller__item-wrapper">
                          <div class="" v-if="deceasedarr.length > 0">
                            <div
                              class="wbpro-scroller-item"
                              v-for="(item, index) in deceasedarr"
                              :key="item"
                            >
                              <div class="FollowFeed_wrap">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween FollowFeed_item"
                                >
                                  <a class="ALink_none UserCard_item">
                                    <div class="keepfly-box-flex keepfly-box-alignCenter">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.headSculpture"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex UserCard_con UserCard_f12"
                                        style="align-self: center"
                                      >
                                        <div
                                          class="UserCard_cla UserCard_fb wbpro-textcut"
                                        >
                                          {{ item.dname }}
                                          <a class="ALink_none" target="_blank"></a>
                                        </div>
                                        <div class="UserCard_clb wbpro-textcut">
                                          {{ $t("message.nationality") }}:{{
                                            item.nationality
                                          }}
                                        </div>
                                        <div class="UserCard_clb">
                                          <span
                                            style="width: 180px; display: inline-block"
                                            >{{ $t("message.date_of_death") }}:
                                            <!-- {{
                                              item.dateOfDeath == null
                                                ? $t("message.unknown")
                                                : item.dateOfDeath
                                            }} -->
                                            <span v-if="item.year">
                                              {{
                                                item.year < 0
                                                  ? $t("message.B_C") +
                                                    " " +
                                                    Math.abs(item.year)
                                                  : item.year
                                              }}{{ $t("message.year") }}
                                              {{
                                                item.moon < 10
                                                  ? "0" + item.moon
                                                  : item.moon
                                              }}{{ $t("message.moon") }}
                                              {{
                                                item.day < 10 ? "0" + item.day : item.day
                                              }}{{ $t("message.day") }}
                                            </span>
                                            <span v-else>
                                              {{ $t("message.unknown") }}
                                            </span> </span
                                          ><span style="margin-left: 10px"
                                            ><eye-outline
                                              style="width: 19px; vertical-align: middle"
                                            ></eye-outline
                                            >：{{
                                              item.tnum == null ? 0 : item.tnum
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div class="UserCard_right UserCard_followBtn">
                                    <div class="keepfly-pop-wrap">
                                      <span class="keepfly-pop-ctrl">
                                        <button
                                          @click="joinDeceased(item.id)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              {{ $t("message.view") }}
                                            </span>
                                          </span>
                                        </button>
                                        <button
                                          @click="transferView(item.id, index)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              {{ $t("message.transfer") }}
                                            </span>
                                          </span>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <n-pagination
                              v-model:page="currdeceasedarrPage"
                              size="medium"
                              :page-count="dpages"
                              :page-slot="7"
                              @update-page="onDatadeceasedarrChange"
                            />
                          </div>
                          <div v-else style="background: #fff; padding: 40px 10px">
                            <n-empty :description="$t('message.tips46')">
                              <template #extra></template>
                            </n-empty>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="font-size: 12px; color: darkgray; text-align: center">
              {{ $t("message.tips189") }}
            </div>
          </div>
          <div v-if="navTab6Show == 5">
            <div class="SecBar_secBar SecBar_visable">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="wbpro-tab2 u-col-8">
                  <div
                    :class="[
                      indexLookFor == 1
                        ? 'keepfly-box-item-inlineBlock cur'
                        : 'keepfly-box-item-inlineBlock',
                    ]"
                    style="width: 22.5%"
                  >
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <div class="wbpro-textcut" @click="setLookForIndex(1)">
                        {{ $t("message.release") }}（{{
                          lookForTotal == null ? 0 : lookForTotal
                        }}）
                      </div>
                    </div>
                  </div>
                  <div
                    :class="[
                      indexLookFor == 2
                        ? 'keepfly-box-item-inlineBlock cur'
                        : 'keepfly-box-item-inlineBlock',
                    ]"
                    style="width: 22.5%"
                  >
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <div class="wbpro-textcut" @click="setLookForIndex(2)">
                        {{ $t("message.join") }}（{{
                          joinTotal == null ? 0 : joinTotal
                        }}）
                      </div>
                    </div>
                  </div>
                  <div class="keepfly-box-item-inlineBlock" style="float: right">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <a class="ALink_none" @click="addlookForView">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter ListTitle_topt ListTitle_f12 ListTitle_clb ListTitle_cll"
                        >
                          <add
                            class="keepfly-font keepfly-font--angleRight"
                            style="width: 11px"
                          ></add>
                          <span>{{
                            $t("message.registration_of_seeking_relatives")
                          }}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <div
              v-if="indexLookFor == 1"
              class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
            >
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                      >
                        <div class="vue-recycle-scroller__item-wrapper">
                          <div class="" v-if="lookForList.length > 0">
                            <div
                              class="wbpro-scroller-item"
                              v-for="(item, index) in lookForList"
                              :key="item"
                            >
                              <div class="FollowFeed_wrap">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween FollowFeed_item"
                                >
                                  <a class="ALink_none UserCard_item">
                                    <div class="keepfly-box-flex keepfly-box-alignCenter">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.frontCover"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex UserCard_con UserCard_f12"
                                        style="align-self: center"
                                      >
                                        <div
                                          class="UserCard_cla UserCard_fb wbpro-textcut"
                                        >
                                          {{ item.nameOfMissingPerson }}
                                          <a class="ALink_none" target="_blank"></a>
                                        </div>
                                        <div class="UserCard_clb wbpro-textcut">
                                          {{ $t("message.created_by") }}:{{
                                            item.nickname
                                          }}
                                        </div>
                                        <div class="UserCard_clb">
                                          {{ $t("message.creation_time") }}:{{
                                            item.createTime
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div class="UserCard_right UserCard_followBtn">
                                    <div class="keepfly-pop-wrap">
                                      <span class="keepfly-pop-ctrl">
                                        <button
                                          @click="lookForInfoViewo(item)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <!-- {{ $t("message.view") }} -->
                                              <eye style="width: 19px"></eye>
                                            </span>
                                          </span>
                                        </button>
                                        <button
                                          @click="lookForInfoView(item)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <!-- {{ $t("message.modify") }} -->
                                              <create-outline
                                                style="width: 18px"
                                              ></create-outline>
                                            </span>
                                          </span>
                                        </button>
                                        <button
                                          @click="lookFordelete(item.id, index)"
                                          class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">
                                              <!-- {{ $t("message.delete") }} -->
                                              <svg
                                                style="width: 18px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 20 20"
                                              >
                                                <g fill="none">
                                                  <path
                                                    d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                                                    fill="currentColor"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </span>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <n-pagination
                              v-model:page="currLookForPage"
                              size="medium"
                              :page-count="lpages"
                              :page-slot="7"
                              @update-page="onDataLookForChange"
                            />
                          </div>
                          <div v-else style="background: #fff; padding: 40px 10px">
                            <n-empty :description="$t('message.none')">
                              <template #extra></template>
                            </n-empty>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <JoinView v-if="indexLookFor == 2"></JoinView>
          </div>
          <BlacklistView v-if="navTab6Show == 6"></BlacklistView>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 520px">
            <div class="Side_sideBox Side_posSticky" style="top: 65px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div class="crow mt-5">
                      <div class="ccol-md-4">
                        <div class="social-source text-center mt-3">
                          <div class="social-source-icon mb-2">
                            <svg
                              class="mdi mdi-facebook h5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 640 512"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48c0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8c0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8c26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <p class="font-14 text-muted mb-2">{{ userNum.num }}</p>
                          <h6>
                            <n-tooltip trigger="hover">
                              <template #trigger>
                                {{ $t("message.effect") }}
                              </template>
                              {{ $t("message.tips75") }}
                            </n-tooltip>
                          </h6>
                        </div>
                      </div>
                      <div class="ccol-md-4">
                        <div class="social-source text-center mt-3">
                          <div class="social-source-icon mb-2">
                            <svg
                              class="mdi mdi-twitter h5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1c117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm-3.68 152.11c.21-1.2.44-2.4.71-3.59a66.46 66.46 0 0 1 16.29-31.21c12.89-13.73 31.16-21.31 51.45-21.31a74.05 74.05 0 0 1 25.06 4.26a66.69 66.69 0 0 1 26.27 17.2a68.15 68.15 0 0 1 18 42.14a78.46 78.46 0 0 1 0 11.4a86.19 86.19 0 0 1-8.2 31q-.76 1.59-1.59 3.15c-1.11 2.07-2.3 4.1-3.58 6.06a79.47 79.47 0 0 1-8.63 11c-13.12 14-29.92 21.73-47.31 21.73a59.61 59.61 0 0 1-19.17-3.18a63.47 63.47 0 0 1-6.1-2.43a70.76 70.76 0 0 1-22.07-16.12a83.76 83.76 0 0 1-22-51.32q-.27-3.88-.18-7.68a75.62 75.62 0 0 1 1.05-11.08zm-149.73 24.34a59.87 59.87 0 0 1 5.2-20.64a56.76 56.76 0 0 1 2.78-5.3a54.49 54.49 0 0 1 7.19-9.56a55.62 55.62 0 0 1 14-10.82a56.84 56.84 0 0 1 8.11-3.64a63.85 63.85 0 0 1 33.35-2.39a57 57 0 0 1 30.78 17a57.86 57.86 0 0 1 15.41 38.62c.05 2.11 0 4.23-.15 6.38a71.58 71.58 0 0 1-6 23.84a69.49 69.49 0 0 1-5.73 10.42a65.39 65.39 0 0 1-15.76 16.57c-1.5 1.07-3.06 2.07-4.67 3.07a54.21 54.21 0 0 1-10 4.65a49.31 49.31 0 0 1-16.2 2.76c-.93 0-1.86 0-2.78-.08a47.6 47.6 0 0 1-5.48-.62a51.19 51.19 0 0 1-5.35-1.23a53.54 53.54 0 0 1-7.72-2.89c-.84-.39-1.66-.8-2.48-1.23c-18-9.49-31.57-29.16-34.23-52.12c-.12-1.05-.22-2.1-.29-3.16a66.59 66.59 0 0 1 .02-9.63zm53.92 178.6a177.27 177.27 0 0 1-61.94-70.65a4 4 0 0 1 1.62-5.26C117.67 316.69 141.4 311 163.82 311c17 0 30.7 2 42.69 5.88a8 8 0 0 1 2.59 13.77c-23.35 19-38.4 42.54-45.47 70.75a2.77 2.77 0 0 1-4.22 1.65zM256 432a175.12 175.12 0 0 1-65.7-12.72a4 4 0 0 1-2.4-4.46c.4-2.05.84-3.92 1.23-5.48c7.12-28.43 24.76-52 51-68.18c23.29-14.35 53-22.25 83.52-22.25c31.16 0 60 7.58 83.48 21.91a2.72 2.72 0 0 1 .91 3.67A176.1 176.1 0 0 1 256 432z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M161 295.28a47.6 47.6 0 0 1-5.48-.62a47.6 47.6 0 0 0 5.48.62z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M134.64 178.13a55.62 55.62 0 0 0-14 10.82a54.49 54.49 0 0 0-7.19 9.56a54.49 54.49 0 0 1 7.19-9.56a55.62 55.62 0 0 1 14-10.82z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M216.17 257.89a71.58 71.58 0 0 0 6-23.84c.15-2.15.2-4.27.15-6.38q.08 3.15-.15 6.38a71.58 71.58 0 0 1-6 23.84z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M134.64 178.13a56.84 56.84 0 0 1 8.11-3.64a56.84 56.84 0 0 0-8.11 3.64z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M150.21 293.43a53.54 53.54 0 0 1-7.72-2.89a53.54 53.54 0 0 0 7.72 2.89z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M105.78 237.19c2.66 23 16.26 42.63 34.23 52.12c-18.01-9.49-31.57-29.16-34.23-52.12z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M254.34 219a83.76 83.76 0 0 0 22 51.32a70.76 70.76 0 0 0 22.07 16.12a70.76 70.76 0 0 1-22.07-16.12a83.76 83.76 0 0 1-22-51.32q-.27-3.88-.18-7.68q-.09 3.75.18 7.68z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M304.5 288.82a63.47 63.47 0 0 1-6.1-2.43a63.47 63.47 0 0 0 6.1 2.43z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M255.93 196.54a66.46 66.46 0 0 1 16.29-31.21a66.46 66.46 0 0 0-16.29 31.21z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M375 165.46a68.15 68.15 0 0 1 18 42.14a68.15 68.15 0 0 0-18-42.14a66.69 66.69 0 0 0-26.27-17.2a66.69 66.69 0 0 1 26.27 17.2z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M393 219a86.19 86.19 0 0 1-8.2 31a86.19 86.19 0 0 0 8.2-31z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M254.16 211.27a75.62 75.62 0 0 1 1.06-11.14a75.62 75.62 0 0 0-1.06 11.14z"
                                fill="currentColor"
                              ></path>
                              <path d="M383.19 253.16z" fill="currentColor"></path>
                              <path
                                d="M206.88 189.05a57.86 57.86 0 0 1 15.41 38.62a57.86 57.86 0 0 0-15.41-38.62a57 57 0 0 0-30.78-17a57 57 0 0 1 30.78 17z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M190 288a54.21 54.21 0 0 1-10 4.65a54.21 54.21 0 0 0 10-4.65z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M105.49 224.45a59.87 59.87 0 0 1 5.2-20.64a59.87 59.87 0 0 0-5.2 20.64z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M194.68 284.88C193.17 286 191.61 287 190 288c1.61-1 3.17-2 4.68-3.12z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M216.17 257.89a69.49 69.49 0 0 1-5.73 10.42a69.49 69.49 0 0 0 5.73-10.42z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M110.69 203.81a56.76 56.76 0 0 1 2.78-5.3a56.76 56.76 0 0 0-2.78 5.3z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M194.68 284.88a65.39 65.39 0 0 0 15.76-16.57a65.39 65.39 0 0 1-15.76 16.57z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <p class="font-14 text-muted mb-2">{{ userNum.ffnum }}</p>
                          <h6>{{ $t("message.number_of_fans") }}</h6>
                        </div>
                      </div>
                      <div class="ccol-md-4">
                        <div class="social-source text-center mt-3">
                          <div class="social-source-icon mb-2">
                            <svg
                              class="mdi mdi-instagram h5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 20 20"
                            >
                              <g fill="none">
                                <path
                                  d="M9 8.5a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H9.5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H9.5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H9.5a.5.5 0 0 1-.5-.5zm-1-6a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0zm0 3a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0zm-.75 3.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5zM7.085 3A1.5 1.5 0 0 1 8.5 2h3a1.5 1.5 0 0 1 1.415 1H14.5A1.5 1.5 0 0 1 16 4.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 4 16.5v-12A1.5 1.5 0 0 1 5.5 3h1.585zM8.5 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM7.085 4H5.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-1.585A1.5 1.5 0 0 1 11.5 5h-3a1.5 1.5 0 0 1-1.415-1z"
                                  fill="currentColor"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <p class="font-14 text-muted mb-2">{{ userNum.mnum }}</p>
                          <h6>{{ $t("message.quantity") }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div>
                      <div
                        class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                      >
                        <div
                          class="f17 fm cla keepfly-box-item-flex"
                          style="align-self: center"
                        >
                          {{ $t("message.recommend") }}
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter"
                          @click="getRandomUser"
                          style="cursor: pointer"
                        >
                          <refresh
                            class="keepfly-font f14 clb keepfly-font--refresh"
                            style="width: 17px"
                          ></refresh>
                          <span class="f12 clb">{{ $t("message.click_refresh") }}</span>
                        </div>
                      </div>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                    </div>
                    <n-spin :show="hotshow">
                      <div
                        class="wbpro-side-card4 wbpro-side-panel wbpro-side-hover"
                        v-for="(item, index) in lookUserList"
                        :key="item"
                      >
                        <a class="ALink_none">
                          <div class="item keepfly-box-flex keepfly-box-alignCenter">
                            <div
                              class="keepfly-avatar-main keepfly-avatar-hover"
                              style="width: 3.125rem; height: 3.125rem"
                            >
                              <img :src="item.avatar" class="keepfly-avatar-img" />
                              <div class="keepfly-avatar-hoverMask"></div>
                            </div>
                            <div
                              class="con keepfly-box-item-flex"
                              style="align-self: center"
                            >
                              <div class="f16 cla wbpro-textcut">
                                {{ item.nickname }}
                              </div>
                              <div class="f13 clb wbpro-textcut">
                                {{ item.profile }}
                              </div>
                            </div>
                            <div class="right" v-if="item.friendUid == null">
                              <button
                                @click="addfriend(item, 3, index)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn"
                              >
                                <span class="keepfly-button-wrap">
                                  <!-- <add
                                    class="keepfly-font keepfly-font--add"
                                    style="width: 12px"
                                  ></add>
                                  <span class="keepfly-button-content">
                                    {{ $t("message.follow") }}
                                  </span> -->
                                  <svg
                                    style="width: 19px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div class="right" v-else>
                              <button
                                @click="cancelfollow(item, 3, index)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn"
                              >
                                <span class="keepfly-button-wrap">
                                  <span class="keepfly-button-content">
                                    {{ $t("message.tips32") }}
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </a>
                        <div class="keepfly-divider-main keepfly-divider-x"></div>
                      </div>
                    </n-spin>
                  </div>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span class="CopyRight_contentItem"
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="adddeceasedshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.deceasedo')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        :model="deceasedmodel"
        label-placement="left"
        label-width="auto"
        require-mark-placement="right-hanging"
        :size="size"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.front_cover')">
          <img
            v-if="deceasedmodel.brand"
            :src="deceasedmodel.brand"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djdeceasedFileViewo"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips159") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="deceased_fileo"
            @change="selectdeceasedImgo"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.avatar')">
          <img
            v-if="deceasedmodel.headSculpture"
            :src="deceasedmodel.headSculpture"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djdeceasedFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips158") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="deceased_file"
            @change="selectdeceasedImg"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.name_of_the_deceased')">
          <n-input
            status="warning"
            v-model:value="deceasedmodel.dname"
            :placeholder="$t('message.name_of_the_deceased')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.nationality')">
          <n-input
            status="warning"
            v-model:value="deceasedmodel.nationality"
            :placeholder="$t('message.nationality')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.date_of_birth')">
          <n-input-number
            status="warning"
            v-model:value="deceasedmodel.byear"
            :placeholder="$t('message.year')"
            :style="{ width: '25%' }"
          />
          <n-select
            status="warning"
            v-model:value="deceasedmodel.bmoon"
            :style="{ width: '20%' }"
            :placeholder="$t('message.moon')"
            :options="moonoptions"
          />
          <n-select
            status="warning"
            v-model:value="deceasedmodel.bday"
            :style="{ width: '20%' }"
            :placeholder="$t('message.day')"
            :options="dayoptions"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <n-switch v-model:value="bdisabled" />{{ $t("message.unknown") }}
        </n-form-item>
        <n-form-item :label="$t('message.date_of_death')">
          <n-input-number
            status="warning"
            v-model:value="deceasedmodel.year"
            :placeholder="$t('message.year')"
            :style="{ width: '25%' }"
          />
          <n-select
            status="warning"
            v-model:value="deceasedmodel.moon"
            :style="{ width: '20%' }"
            :placeholder="$t('message.moon')"
            :options="moonoptions"
          />
          <n-select
            status="warning"
            v-model:value="deceasedmodel.day"
            :style="{ width: '20%' }"
            :placeholder="$t('message.day')"
            :options="dayoptions"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <n-switch v-model:value="disabled" />{{ $t("message.unknown") }}
        </n-form-item>
        <n-form-item :label="$t('message.die_at_the_age_of')">
          <n-input-number
            v-model:value="deceasedmodel.dieAge"
            :precision="1"
            status="warning"
            :placeholder="$t('message.die_at_the_age_of')"
            :min="0"
          />
        </n-form-item>
        <n-form-item :label="$t('message.university')">
          <n-input
            status="warning"
            v-model:value="deceasedmodel.universities"
            :placeholder="$t('message.university')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.occupation')">
          <n-input
            status="warning"
            v-model:value="deceasedmodel.occupation"
            :placeholder="$t('message.occupation')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.brief')">
          <n-input
            type="textarea"
            v-model:value="deceasedmodel.achievement"
            status="warning"
            show-count
            :maxlength="300"
            :count-graphemes="countGraphemes"
            :allow-input="noSideSpace"
            :placeholder="$t('message.brief')"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="deceasedValidateClick">
            {{ $t("message.newly_increased") }}
          </n-button>
        </n-form-item>
      </n-form>
      <div style="font-size: 12px; color: darkgray">
        {{ $t("message.tips144") }}
      </div>
    </n-modal>
    <n-modal
      v-model:show="transfershow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.transfer')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        :model="transfermodel"
        label-placement="left"
        label-width="auto"
        require-mark-placement="right-hanging"
        :size="size"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.opposite_id')" path="inputValue">
          <n-input
            v-model:value="transfermodel.kpId"
            status="warning"
            :placeholder="$t('message.opposite_id')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.my_password')" path="inputValue">
          <n-input
            v-model:value="transfermodel.pwd"
            status="warning"
            :placeholder="$t('message.my_password')"
          />
        </n-form-item>
        <n-form-item>
          <n-button
            attr-type="button"
            type="warning"
            @click="transferValidateClick(transfermodel.index)"
          >
            {{ $t("message.transfer") }}
          </n-button>
        </n-form-item>
      </n-form>
    </n-modal>
    <n-modal
      v-model:show="lookForshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.registration_of_seeking_relatives')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        :model="lookForModel"
        label-placement="left"
        :label-width="130"
        require-mark-placement="right-hanging"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.front_cover')" path="inputValue">
          <img
            :src="lookForModel.frontCover"
            v-if="lookForModel.frontCover"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div class="n-upload-trigger n-upload-trigger--image-card" @click="djFileView">
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips162") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="lookFor_file"
            @change="selectLookForImg"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.name_of_missing_person')" path="inputValue">
          <n-input
            status="warning"
            v-model:value="lookForModel.nameOfMissingPerson"
            :placeholder="$t('message.name_of_missing_person')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.gender')" path="inputValue">
          <n-switch :rail-style="railStyle" v-model:value="lookForModel.gendero">
            <template #checked>
              {{ $t("message.man") }}
            </template>
            <template #unchecked>
              {{ $t("message.woman") }}
            </template>
          </n-switch>
        </n-form-item>
        <n-form-item :label="$t('message.age')" path="inputValue">
          <n-input
            status="warning"
            v-model:value="lookForModel.age"
            :placeholder="$t('message.age')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.debatable_time')" path="inputValue">
          <n-date-picker
            status="warning"
            v-model:value="lookForModel.debatableTimeo"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
          />
        </n-form-item>
        <n-form-item :label="$t('message.location_of_disappearance')" path="inputValue">
          <n-input
            status="warning"
            v-model:value="lookForModel.locationOfDisappearance"
            :placeholder="$t('message.location_of_disappearance')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.feature_description')" path="inputValue">
          <n-input
            status="warning"
            v-model:value="lookForModel.featureDescription"
            :placeholder="$t('message.feature_description')"
            type="textarea"
            :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
          />
        </n-form-item>
        <n-form-item :label="$t('message.photograph')" path="inputValue">
          <img
            :src="lookForModel.photograph"
            v-if="lookForModel.photograph"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djphotoFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips162") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="lookForPhoto_file"
            @change="selectPhotoLookForImg"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.contact_number')" path="inputValue">
          <n-input
            status="warning"
            v-model:value="lookForModel.contactNumber"
            :placeholder="$t('message.contact_number')"
          />
        </n-form-item>
        <div style="display: flex; justify-content: flex-end">
          <n-button round type="warning" @click="handleValidateButtonClick">
            {{ $t("message.check_in") }}
          </n-button>
        </div>
      </n-form>
    </n-modal>
    <n-modal
      v-model:show="showLookForLyModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.look_around_the_world')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div style="margin-bottom: 10px" v-for="item in showLookForLyArr" :key="item">
        <n-card size="small" hoverable>
          <div class="onimg" v-html="filters(item.content)"></div>
          <template #footer>
            <div style="text-align: right">
              <!-- <span
                @click="delonline(item, index)"
                style="float: left; cursor: pointer; font-size: 12px; color: darkgray"
                >{{ $t("message.delete") }}</span
              > -->
              <img
                v-if="item.avatar == null"
                src="http://img.keepfly.net/ossimage/banner1683607244000.gif"
                style="width: 36px; margin-right: 10px; border-radius: 18px; height: 36px"
              />
              <img
                v-else
                @click="sendprivateletter(item)"
                :src="item.avatar"
                style="width: 36px; margin-right: 10px; border-radius: 18px; height: 36px"
              />
              <span>{{ item.nickname ? item.nickname : $t("message.tourist") }}</span
              ><br />
              <div>{{ item.createTime }}</div>
              <div style="clear: both"></div>
            </div>
          </template>
        </n-card>
      </div>
      <n-pagination
        v-model:page="currLookForLyPage"
        :page-count="lypages"
        @update-page="uppagelookforly"
      />
    </n-modal>
    <n-modal
      v-model:show="deletedeceasedshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.delete_account')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-table striped>
        <thead>
          <tr>
            <th>{{ $t("message.avatar") }}</th>
            <th>{{ $t("message.name_of_the_deceased") }}</th>
            <th>{{ $t("message.operate") }}</th>
          </tr>
        </thead>
        <tbody v-for="item in deceasedarr" :key="item">
          <tr>
            <td>
              <div
                class="keepfly-avatar-main keepfly-avatar-hover"
                style="width: 3.125rem; height: 3.125rem"
              >
                <img :src="item.headSculpture" class="keepfly-avatar-img" />
                <div class="keepfly-avatar-hoverMask"></div>
              </div>
            </td>
            <td>{{ item.dname }}</td>
            <td>
              <n-button secondary strong @click="deletedeceasedinfo(item.id)">
                <template #icon>
                  <n-icon>
                    <close-outline />
                  </n-icon>
                </template>
              </n-button>
            </td>
          </tr>
        </tbody>
      </n-table>
      <template #footer>
        <n-pagination
          v-model:page="currdeceasedarrPage"
          size="medium"
          :page-count="dpages"
          :page-slot="7"
          @update-page="onDatadeceasedarrChange"
        />
      </template>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import {
  HomeOutline,
  CalendarOutline,
  PeopleOutline,
  ChevronDown,
  MaleFemale,
  IdCardOutline,
  EllipsisHorizontalCircle,
  DocumentTextOutline,
  Refresh,
  Add,
  FootstepsOutline,
  Eye as EyeOutline,
  Male,
  Female,
  CreateOutline,
  Eye,
  CloseOutline,
} from "@vicons/ionicons5";
import utils from "../../uilts/utils";
import { client } from "../../uilts/alioss";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import GraphemeSplitter from "grapheme-splitter";
import JoinView from "./JoinView.vue";
import BlacklistView from "./BlacklistView.vue";
import {
  useMessage,
  NModal,
  NInput,
  NButton,
  NForm,
  NFormItem,
  NImageGroup,
  NSpace,
  NImage,
  NSwitch,
  NDatePicker,
  NEmpty,
  NTooltip,
  NInputNumber,
  NSelect,
  NSpin,
  NCard,
  NPagination,
  NEllipsis,
  NTable,
  NIcon,
} from "naive-ui";
import { useRouter } from "vue-router";
import bus from "../../uilts/bus.js";
const railStyle = ({ focused, checked }) => {
  const style = {};
  if (checked) {
    style.background = "#2080f0";
    if (focused) {
      style.boxShadow = "0 0 0 2px #2080f040";
    }
  } else {
    style.background = "#ec80ab";
    if (focused) {
      style.boxShadow = "0 0 0 2px #d0305040";
    }
  }
  return style;
};
const feelOptions = [
  {
    label: i18n.global.t("message.single"),
    value: 1001,
  },
  {
    label: i18n.global.t("message.seeking_a_relationship"),
    value: 1002,
  },
  {
    label: i18n.global.t("message.in_secret_love"),
    value: 1003,
  },
  {
    label: i18n.global.t("message.ambiguous"),
    value: 1004,
  },
  {
    label: i18n.global.t("message.in_love"),
    value: 1005,
  },
  {
    label: i18n.global.t("message.engaged"),
    value: 1006,
  },
  {
    label: i18n.global.t("message.married"),
    value: 1007,
  },
  {
    label: i18n.global.t("message.separated"),
    value: 1008,
  },
  {
    label: i18n.global.t("message.divorced"),
    value: 1009,
  },
  {
    label: i18n.global.t("message.widow"),
    value: 1010,
  },
  {
    label: i18n.global.t("message.not_selected"),
    value: 1011,
  },
];
export default defineComponent({
  name: "UserView",
  components: {
    HomeOutline,
    CalendarOutline,
    PeopleOutline,
    BlacklistView,
    ChevronDown,
    MaleFemale,
    IdCardOutline,
    EllipsisHorizontalCircle,
    DocumentTextOutline,
    Refresh,
    Add,
    NModal,
    NInput,
    NButton,
    NForm,
    NFormItem,
    NImageGroup,
    NSpace,
    NImage,
    FootstepsOutline,
    NSwitch,
    NDatePicker,
    NEmpty,
    NTooltip,
    EyeOutline,
    JoinView,
    NInputNumber,
    NSelect,
    Male,
    Female,
    NSpin,
    CreateOutline,
    Eye,
    NCard,
    NPagination,
    NEllipsis,
    NTable,
    NIcon,
    CloseOutline,
  },
  setup(props, context) {
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const message = useMessage();
    const router = useRouter();
    const navTab6Show = ref(1);
    const setNavTab6 = (e) => {
      navTab6Show.value = e;
      if (e == 1) {
        console.log("");
      } else if (e == 2) {
        followarr.value = [];
        getselffollow();
      } else if (e == 3) {
        fansarr.value = [];
        getfanslist();
      } else if (e == 4) {
        deceasedarr.value = [];
        getdeceasedlist();
      } else if (e == 5) {
        lookForList.value = [];
        getLookForList();
      }
      window.scrollTo(0, 0);
    };
    const followarr = ref([]);
    const currfollowarrPage = ref(1);
    const pagefollowarrSize = ref(10);
    const followarrTotal = ref(0);
    const fpages = ref(0);
    const getselffollow = () => {
      let param = {
        currPage: currfollowarrPage.value,
        pageSize: pagefollowarrSize.value,
      };
      API({
        url: "/kuke/api/user/getselffollow",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          let arr = result.data.data.records;
          for (let i = 0; i < arr.length; i++) {
            followarr.value.unshift(arr[i]);
          }
          followarrTotal.value = result.data.data.total;
          fpages.value = result.data.data.pages;
        }
      });
    };
    const currfansarrPage = ref(1);
    const pagefansarrSize = ref(10);
    const fansarr = ref([]);
    const fansarrTotal = ref(0);
    const ffpages = ref(0);
    const getfanslist = () => {
      let param = {
        currPage: currfansarrPage.value,
        pageSize: pagefansarrSize.value,
      };
      API({
        url: "/kuke/api/user/getfanslist",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          let arr = result.data.data.records;
          for (let i = 0; i < arr.length; i++) {
            fansarr.value.unshift(arr[i]);
          }
          fansarrTotal.value = result.data.data.total;
          ffpages.value = result.data.data.pages;
        }
      });
    };
    const cancelfollow = (e, i, index) => {
      let param = {};
      if (i == 1) {
        param = {
          id: e.id,
          inum: i,
        };
      } else if (i == 2) {
        param = {
          id: e.friendUid,
          inum: i,
        };
      } else if (i == 3) {
        param = {
          id: e.id,
          inum: i,
        };
      }
      API({
        url: "/kuke/api/user/cancelfollow",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips147"));
          if (i == 1) {
            // getselffollow();
            followarr.value.splice(index, 1);
            followarrTotal.value = followarrTotal.value - 1;
          } else if (i == 2 || i == 3) {
            e.friendUid = null;
          }
        } else {
          message.error(i18n.global.t("message.tips148"));
        }
      });
    };
    const cancelfans = (e, index) => {
      let param = {
        userId: e,
      };
      API({
        url: "/kuke/api/user/cancelfans",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips35"));
          fansarr.value.splice(index, 1);
          fansarrTotal.value = fansarrTotal.value - 1;
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const adddeceasedshow = ref(false);
    const adddeceasedaccountView = () => {
      adddeceasedshow.value = true;
    };
    const deceasedmodel = ref({});
    const deceased_file = ref();
    const djdeceasedFileView = () => {
      deceased_file.value.value = "";
      deceased_file.value.click();
    };
    const deceased_fileo = ref();
    const djdeceasedFileViewo = () => {
      deceased_fileo.value.value = "";
      deceased_fileo.value.click();
    };
    const selectdeceasedImg = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 10;
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          // if (image.width / image.height != 1) {
          //   message.error(i18n.global.t("message.tips94"));
          //   return false;
          // }
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              deceasedmodel.value.headSculpture = "http://img.keepfly.net/" + fileName;
            });
        };
      };
      reader.readAsDataURL(file);
    };

    const selectdeceasedImgo = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 2; //1M
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          if (image.width / image.height != 1) {
            message.error(i18n.global.t("message.tips94"));
            return false;
          }
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              deceasedmodel.value.brand = "http://img.keepfly.net/" + fileName;
            });
        };
      };
      reader.readAsDataURL(file);
    };
    const disabled = ref(false);
    const bdisabled = ref(false);
    const splitter = new GraphemeSplitter();
    const deceasedValidateClick = () => {
      let dname = deceasedmodel.value.dname;
      let head_sculpture = deceasedmodel.value.headSculpture;
      let brand = deceasedmodel.value.brand;
      let nationality = deceasedmodel.value.nationality;
      // let date_of_birth = deceasedmodel.value.dateOfBirtho;
      // let date_of_death = deceasedmodel.value.dateOfDeatho;
      if (dname == "" || dname == null || dname == undefined) {
        message.error(i18n.global.t("message.tips37"));
        return false;
      }
      if (brand == "" || brand == null || brand == undefined) {
        message.error(i18n.global.t("message.tips65"));
        return false;
      }
      if (head_sculpture == "" || head_sculpture == null || head_sculpture == undefined) {
        message.error(i18n.global.t("message.tips38"));
        return false;
      }
      if (nationality == "" || nationality == null || nationality == undefined) {
        message.error(i18n.global.t("message.tips39"));
        return false;
      }
      // if (date_of_birth == "" || date_of_birth == null || date_of_birth == undefined) {
      //   message.error(i18n.global.t("message.tips40"));
      //   return false;
      // }
      // if (!bdisabled.value && deceasedmodel.value.byear == null) {
      //   message.error(i18n.global.t("message.tips40"));
      //   return false;
      // }
      if (bdisabled.value) {
        deceasedmodel.value.byear = 0;
        deceasedmodel.value.bmoon = 0;
        deceasedmodel.value.bday = 0;
      }
      // if (!disabled.value && deceasedmodel.value.year == null) {
      //   message.error(i18n.global.t("message.tips41"));
      //   return false;
      // }
      if (disabled.value) {
        deceasedmodel.value.year = 0;
        deceasedmodel.value.moon = 0;
        deceasedmodel.value.day = 0;
      }
      // if (date_of_death == "" || date_of_death == null || date_of_death == undefined) {
      //   message.error(i18n.global.t("message.tips41"));
      //   return false;
      // }
      // deceasedmodel.value.dateOfBirth = utils.dateFormat(date_of_birth);
      // deceasedmodel.value.dateOfDeath =
      //   date_of_death == undefined || date_of_death == null || date_of_death == ""
      //     ? ""
      //     : utils.dateFormat(date_of_death);

      API({
        url: "/kuke/api/user/createdeceasedaccount",
        method: "POST",
        data: deceasedmodel.value,
      }).then((result) => {
        if (result.data.code == 200) {
          deceasedmodel.value.id = result.data.data;
          deceasedarr.value.unshift(deceasedmodel.value);
          deceasedmodel.value = {};
          message.success(i18n.global.t("message.tips42"));
          adddeceasedshow.value = false;
        } else {
          message.error(i18n.global.t("message.tips43"));
        }
      });
    };
    const currdeceasedarrPage = ref(1);
    const pagedeceasedarrSize = ref(10);
    const deceasedarr = ref([]);
    const deceasedarrTotal = ref(0);
    const dpages = ref(0);
    const getdeceasedlist = () => {
      let param = {
        currPage: currdeceasedarrPage.value,
        pageSize: pagedeceasedarrSize.value,
      };
      API({
        url: "/kuke/api/user/getdeceasedlist",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          let arr = result.data.data.records;
          // for (let i = 0; i < arr.length; i++) {
          //   deceasedarr.value.unshift(arr[i]);
          // }
          deceasedarr.value = arr;
          deceasedarrTotal.value = result.data.data.total;
          dpages.value = result.data.data.pages;
        }
      });
    };
    const transfershow = ref(false);
    const transferView = (e, i) => {
      transfermodel.value.did = e;
      transfermodel.value.index = i;
      transfershow.value = true;
    };
    const transfermodel = ref({});
    const transferValidateClick = (index) => {
      let kpId = transfermodel.value.kpId;
      let pwd = transfermodel.value.pwd;
      if (kpId == "" || kpId == null || kpId == undefined) {
        message.error(i18n.global.t("message.tips99"));
        return false;
      }
      if (pwd == "" || pwd == null || pwd == undefined) {
        message.error(i18n.global.t("message.tips2"));
        return false;
      }
      API({
        url: "/kuke/api/user/transferdeceasedaccount",
        method: "POST",
        data: transfermodel.value,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.transfer_successful"));
          transfershow.value = false;
          deceasedarr.value.splice(index, 1);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips24"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips25"));
        } else if (result.data.code == 603) {
          message.error(i18n.global.t("message.tips45"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.transfer_failed"));
        }
      });
    };

    const currfollowermicroblogPage = ref(1);
    const pagefollowermicroblogSize = ref(10);
    const followermicroblogs = ref([]);
    const getfollowermicroblog = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currfollowermicroblogPage.value,
        pageSize: pagefollowermicroblogSize.value,
      };
      API({
        url: "/kuke/api/user/getfollowermicroblog",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
        }
        followermicroblogs.value = arr;
      });
    };
    const removeattention = (e, index) => {
      let param = {
        id: e.uid,
        inum: 2,
      };
      API({
        url: "/kuke/api/user/cancelfollow",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips35"));
          followarr.value.splice(index, 1);
          getfollowermicroblog();
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const joinDeceased = (e) => {
      router.push({
        name: "DeceasedView",
        params: {
          id: e,
        },
      });
    };
    const infodata = ref({});
    const kukesystemInfo = ref({});
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getKeepflyUserInfo();
      getRandomUser();
      getUserInfoNum();
      getmicrobloguserlist();
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (navTab6Show.value == 1) {
            if (currusermicroblogPage.value < mpages.value) {
              ++currusermicroblogPage.value;
              getmicrobloguserlist();
            }
          } else if (navTab6Show.value == 5) {
            if (currLookForPage.value < lpages.value) {
              ++currLookForPage.value;
              getLookForList();
            }
          }
        }
      }
    };
    const keepflyUserInfo = ref({});
    const getKeepflyUserInfo = () => {
      API({
        url: "/kuke/api/kuke-user/getKeepflyUserInfo",
        method: "GET",
      }).then((result) => {
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };

    const lookUserList = ref([]);
    const getRandomUser = () => {
      hotshow.value = true;
      API({
        url: "/kuke/api/user/getRandomUser",
        method: "POST",
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data));
        lookUserList.value = arr;
        hotshow.value = false;
      });
    };
    const addfriend = (e, i) => {
      let param = {};
      if (i == 1) {
        param = {
          userid: e.userId,
        };
      } else if (i == 2) {
        param = {
          userid: e.uid,
        };
      } else if (i == 3) {
        param = {
          userid: e.id,
        };
      }
      API({
        url: "/kuke/api/user/addfriend",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.friendUid = 1;
          e.isFriend = 1;
          message.success(i18n.global.t("message.tips149"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips32"));
        } else if (result.data.code == 606) {
          message.success(i18n.global.t("message.tips34"));
        } else {
          message.error(i18n.global.t("message.tips146"));
        }
      });
    };
    const userNum = ref({});
    const getUserInfoNum = () => {
      API({
        url: "/kuke/api/user/getUserInfoNum",
        method: "GET",
      }).then((result) => {
        if (result.data.code == 200) {
          userNum.value = result.data.data;
        }
      });
    };
    const currusermicroblogPage = ref(1);
    const pageusermicroblogSize = ref(10);
    const usermicroblogs = ref([]);
    const mpages = ref(0);
    const getmicrobloguserlist = () => {
      let param = {
        currPage: currusermicroblogPage.value,
        pageSize: pageusermicroblogSize.value,
      };
      API({
        url: "/kuke/api/user/getmicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          arr[i].isdelete = 0;
          usermicroblogs.value.push(arr[i]);
        }
        console.log(usermicroblogs.value);
        mpages.value = result.data.data.pages;
      });
    };

    const currLookForPage = ref(1);
    const pageLookForSize = ref(10);
    const lookForList = ref([]);
    const lookForTotal = ref(0);
    const joinTotal = ref(0);
    const lpages = ref(0);
    const getLookForList = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currLookForPage.value,
        pageSize: pageLookForSize.value,
      };
      API({
        url: "/kuke/api/kuke-look-for/getLookForList",
        method: "get",
        params: param,
      }).then((result) => {
        let arr = result.data.data.KukeLookFor.records;
        for (let i = 0; i < arr.length; i++) {
          lookForList.value.unshift(arr[i]);
        }
        lookForTotal.value = result.data.data.KukeLookFor.total;
        joinTotal.value = result.data.data.joinTotal;
        lpages.value = result.data.data.pages;
      });
    };
    const lookForModel = ref({
      nameOfMissingPerson: "",
      gender: 1,
      age: "",
      locationOfDisappearance: "",
      featureDescription: "",
      photograph: "",
      contactNumber: "",
      frontCover: "",
      debatableTimeo: null,
      debatableTime: "",
    });
    const handleValidateButtonClick = () => {
      if (lookForModel.value.frontCover == "") {
        message.error(i18n.global.t("message.front_cover"));
        return false;
      } else if (lookForModel.value.nameOfMissingPerson == "") {
        message.error(i18n.global.t("message.name_of_missing_person"));
        return false;
      } else if (lookForModel.value.age == "") {
        message.error(i18n.global.t("message.age"));
        return false;
      } else if (lookForModel.value.debatableTimeo == "") {
        message.error(i18n.global.t("message.debatable_time"));
        return false;
      } else if (lookForModel.value.locationOfDisappearance == "") {
        message.error(i18n.global.t("message.location_of_disappearance"));
        return false;
      } else if (lookForModel.value.featureDescription == "") {
        message.error(i18n.global.t("message.feature_description"));
        return false;
      } else if (lookForModel.value.contactNumber == "") {
        message.error(i18n.global.t("message.contact_number"));
        return false;
      }
      lookForModel.value.debatableTime = utils.dateFormat(
        lookForModel.value.debatableTimeo
      );
      lookForModel.value.gender = lookForModel.value.gendero ? 1 : 0;
      API({
        url: "/kuke/api/kuke-look-for/upKukeLookFor",
        method: "POST",
        data: lookForModel.value,
      }).then((result) => {
        if (result.data.code == 200) {
          lookForshow.value = false;
          if (lookForModel.value.id == null) {
            lookForList.value.unshift(lookForModel.value);
          }
          message.success(i18n.global.t("message.tips51"));
        } else {
          message.error(i18n.global.t("message.tips52"));
        }
      });
    };
    const lookForshow = ref(false);
    const lookforinfolist = ref({});
    const lookForInfoView = (e) => {
      lookForModel.value = e;
      lookForModel.value.gendero = e.gender == 1 ? true : false;
      lookForModel.value.debatableTimeo = new Date(
        lookForModel.value.debatableTime
      ).getTime();

      lookForshow.value = true;
    };
    const showLookForLyModal = ref(false);
    const showLookForLyArr = ref([]);
    const currLookForLyPage = ref(1);
    const pageLookForLySize = ref(10);
    const lypages = ref(0);
    const lookForInfoViewo = (e) => {
      let param = {
        id: e.id,
        currPage: currLookForLyPage.value,
        pageSize: pageLookForLySize.value,
      };
      API({
        url: "/kuke/api/user/lookForInfoLyList",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          showLookForLyArr.value = result.data.data.records;
          lypages.value = result.data.data.pages;
          showLookForLyModal.value = true;
        }
      });
    };
    const uppagelookforly = (val) => {
      currLookForLyPage.value = val;
      lookForInfoViewo();
    };
    const lookFor_file = ref();
    const djFileView = () => {
      lookFor_file.value.value = "";
      lookFor_file.value.click();
    };
    const selectLookForImg = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 10;
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              lookForModel.value.frontCover = "http://img.keepfly.net/" + fileName;
            });
        };
      };
      reader.readAsDataURL(file);
    };
    const lookForPhoto_file = ref();
    const djphotoFileView = () => {
      lookForPhoto_file.value.value = "";
      lookForPhoto_file.value.click();
    };
    const selectPhotoLookForImg = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          lookForModel.value.photograph = "http://img.keepfly.net/" + fileName;
        });
    };
    const lookFordelete = (e, i) => {
      API({
        url: "/kuke/api/kuke-look-for/lookFordelete",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          lookForList.value.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const getCommentView = (e, i) => {
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.error(i18n.global.t("message.tips172"));
        }
      });
    };
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const deleteusermicroblogsinfo = (e, i) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/deletemicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          usermicroblogs.value.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const showdeleteview = (e) => {
      e.isdelete = e.isdelete == 1 ? 0 : 1;
    };
    const indexLookFor = ref(1);
    const setLookForIndex = (e) => {
      indexLookFor.value = e;
    };
    const addlookForView = () => {
      lookForModel.value = {};
      lookForshow.value = true;
    };
    const closeCommentInfo = (r, e, i) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/deleteCommentInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          r.commentVos.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const hotshow = ref(false);
    function copyCode(codecon) {
      let domInput = document.createElement("input");
      domInput.value = codecon;
      document.body.appendChild(domInput); // 添加input节点
      domInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      message.success(i18n.global.t("message.id_number_copied"));
      domInput.remove();
    }
    const sendprivateletter = (e) => {
      let params = {
        id: e.fromId,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          context.emit("eventParent", 4);
          bus.emit("sendToBrother", 4);
          window.localStorage.setItem("sendTohuati", 4);
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    function checkfeel(e) {
      let arr = feelOptions;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value == e) {
          return arr[i].label;
        }
      }
    }
    const onDatafollowarrChange = (val) => {
      currfollowarrPage.value = val;
      getselffollow();
    };
    const onDatafansarrChange = (val) => {
      currfansarrPage.value = val;
      getfanslist();
    };
    const onDatadeceasedarrChange = (val) => {
      currdeceasedarrPage.value = val;
      getdeceasedlist();
    };
    const onDataLookForChange = (val) => {
      currLookForLyPage.value = val;
      getLookForList();
    };
    const deletedeceasedshow = ref(false);
    const deletedeceasedaccountView = () => {
      deletedeceasedshow.value = true;
    };
    const deletedeceasedinfo = (e) => {
      let param = {
        id: e,
      };
      API({
        url: "/kuke/api/user/deletedeceasedinfo",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips35"));
          getdeceasedlist();
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    return {
      deletedeceasedinfo,
      deletedeceasedaccountView,
      deletedeceasedshow,
      dpages,
      onDataLookForChange,
      onDatadeceasedarrChange,
      onDatafansarrChange,
      onDatafollowarrChange,
      feelOptions,
      checkfeel,
      sendprivateletter,
      uppagelookforly,
      currLookForLyPage,
      lypages,
      showLookForLyArr,
      showLookForLyModal,
      lookForInfoViewo,
      copyCode,
      hotshow,
      bdisabled,
      disabled,
      closeCommentInfo,
      joinTotal,
      addlookForView,
      setLookForIndex,
      indexLookFor,
      showdeleteview,
      deleteusermicroblogsinfo,
      kukesystemInfo,
      likeClick,
      deceased_file,
      deceased_fileo,
      infodata,
      getCommentView,
      sendInfoComment,
      commentVoslikeClick,
      morecommentVos,
      filters,
      lookFordelete,
      lookForTotal,
      railStyle,
      lookForPhoto_file,
      lookFor_file,
      lookforinfolist,
      djFileView,
      selectPhotoLookForImg,
      djphotoFileView,
      selectLookForImg,
      lookForInfoView,
      lookForshow,
      lookForList,
      lookForModel,
      handleValidateButtonClick,
      getLookForList,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
      usermicroblogs,
      getmicrobloguserlist,
      userNum,
      getUserInfoNum,
      deceasedmodel,
      addfriend,
      lookUserList,
      getRandomUser,
      fansarr,
      fansarrTotal,
      followarrTotal,
      keepflyUserInfo,
      getKeepflyUserInfo,
      cancelfollow,
      cancelfans,
      adddeceasedshow,
      adddeceasedaccountView,
      djdeceasedFileView,
      selectdeceasedImg,
      djdeceasedFileViewo,
      selectdeceasedImgo,
      countGraphemes: (value) => splitter.countGraphemes(value),
      deceasedValidateClick,
      deceasedarr,
      deceasedarrTotal,
      transfershow,
      transferView,
      transfermodel,
      transferValidateClick,
      getdeceasedlist,
      followermicroblogs,
      getfollowermicroblog,
      removeattention,
      joinDeceased,
      followarr,
      navTab6Show,
      setNavTab6,
      moonoptions: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "11",
          value: 11,
        },
        {
          label: "12",
          value: 12,
        },
      ],
      dayoptions: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "11",
          value: 11,
        },
        {
          label: "12",
          value: 12,
        },
        {
          label: "13",
          value: 13,
        },
        {
          label: "14",
          value: 14,
        },
        {
          label: "15",
          value: 15,
        },
        {
          label: "16",
          value: 16,
        },
        {
          label: "17",
          value: 17,
        },
        {
          label: "18",
          value: 18,
        },
        {
          label: "19",
          value: 19,
        },
        {
          label: "20",
          value: 20,
        },
        {
          label: "21",
          value: 21,
        },
        {
          label: "22",
          value: 22,
        },
        {
          label: "23",
          value: 23,
        },
        {
          label: "24",
          value: 24,
        },
        {
          label: "25",
          value: 25,
        },
        {
          label: "26",
          value: 26,
        },
        {
          label: "27",
          value: 27,
        },
        {
          label: "28",
          value: 28,
        },
        {
          label: "29",
          value: 29,
        },
        {
          label: "30",
          value: 30,
        },
        {
          label: "31",
          value: 31,
        },
      ],
    };
  },
});
</script>
<style>
.text-center {
  text-align: center !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.crow {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ccol-md-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.social-source-icon i {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
}
.text-white {
  width: 32px;
  height: 32px;
  color: #ff8200 !important;
}

.font-14 {
  font-size: 14px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.text-muted {
  color: #707070 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-weight: 600;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.h6,
h6 {
  font-size: 14px;
  line-height: 1.2;
  color: inherit;
}
.morepop_more_3ssan .keepfly-pop-main {
  z-index: 999;
}
.keepfly-pop-down.keepfly-pop-end,
.keepfly-pop-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.keepfly-pop-down.keepfly-pop-end {
  left: 100%;
}
.keepfly-pop-main {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  min-width: 4.25rem !important;
  position: absolute;
  z-index: 9999;
}
.keepfly-pop-item-main:hover {
  background-color: #f8f8fb;
}
.keepfly-pop-wrap-main {
  border-radius: 0.4375rem;
  overflow: hidden;
}
.keepfly-pop-item-main {
  color: #333;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.9375rem 1.25rem;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.ProfileHeader_pic .n-image img {
  width: 100% !important;
}
</style>
