import axios from 'axios'
const API = axios.create({
	baseUrl:'http://localhost:8889', 
	timeout: 5000,
	headers: {
		'Content-Type':'application/x-www-form-urlencoded',
		'X-Requested-With':'XMLHttpRequest'
	},
})
API.interceptors.request.use((config) => {
	if(config.url!="/kuke/api/system-admin/index"){
		if(!(window.localStorage.token===undefined||window.localStorage.token===''||window.localStorage.token===null)){
            config.headers.token = window.localStorage.token
        }
	}
	return config;
},(error) =>{
    // if(error.response.status === 401) {
    //     // 401 说明 token 验证失败
    //     // 可以直接跳转到登录页面，重新登录获取 token
    //     location.href = '/login'
    // }
    return Promise.reject(error);
});
API.interceptors.response.use((res) =>{
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    if(error.response.status === 401) {
        // 401 说明 token 验证失败
        // 可以直接跳转到登录页面，重新登录获取 token
        window.localStorage.setItem("token",null);
		window.localStorage.setItem("infodata",null);
        location.href = '/'
    }
    return Promise.reject(error);
});
export default API