<template>
  <div class="keepfly-box-flex keepfly-box-justifyBetween Frame_content1">
    <div class="Frame_side1">
      <div class="LeftNav_box" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right LeftNav_inner"
        >
          <div class="LeftNav_titL">{{ $t("message.find_it") }}</div>
          ,
          <div class="keepfly-box-flex keepfly-box-column">
            <a
              @click="setNavTab3(1)"
              :class="[
                navTab3Show == 1 ? 'router-link-exact-active router-link-active' : '',
              ]"
            >
              <div
                :class="[
                  navTab3Show == 1
                    ? 'keepfly-box-flex keepfly-box-alignCenter star-f14 LeftNav_item LeftNav_curr'
                    : 'keepfly-box-flex keepfly-box-alignCenter star-f14 LeftNav_item',
                ]"
              >
                <footsteps-outline
                  class="star-font star-font--navHome"
                ></footsteps-outline>
                <span>{{ $t("message.look_around_the_world") }}</span>
              </div>
            </a>
            <a
              @click="setNavTab3(2)"
              :class="[
                navTab3Show == 2 ? 'router-link-exact-active router-link-active' : '',
              ]"
            >
              <div
                :class="[
                  navTab3Show == 2
                    ? 'keepfly-box-flex keepfly-box-alignCenter star-f14 LeftNav_item LeftNav_curr'
                    : 'keepfly-box-flex keepfly-box-alignCenter star-f14 LeftNav_item',
                ]"
              >
                <id-card-outline class="star-font star-font--navFollow"></id-card-outline>
                <span>{{ $t("message.registration_of_seeking_relatives") }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main1">
      <div v-if="navTab3Show == 1">
        <div class="Tit_wrap">
          <div class="keepfly-box-flex keepfly-box-alignEnd">
            <div class="keepfly-box-item-flex" style="align-self: end">
              <div class="keepfly-box-flex keepfly-box-alignEnd">
                <div class="Tit_tit">{{ $t("message.look_around_the_world") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="List1_list">
          <div
            class="u-col-3"
            style="margin-left: -0.5rem; margin-top: -1.375rem"
            v-if="lookForList.length > 0"
          >
            <div
              v-for="item in lookForList"
              :key="item"
              class="keepfly-box-item-inlineBlock"
              style="padding-left: 0.5rem; padding-top: 1.375rem"
            >
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Item_card Item_wrapY"
              >
                <div class="keepfly-box-flex keepfly-box-column Item_D">
                  <div
                    @click="lookForInfoView(item)"
                    class="keepfly-picture-main keepfly-picture-wide169 keepfly-picture-hover Item_pic"
                  >
                    <img :src="item.frontCover" class="keepfly-picture-img" />
                    <div class="keepfly-picture-cover"></div>
                    <div class="keepfly-picture-hoverMask"></div>
                    <div class="keepfly-picture-slot">
                      <div class="Item_duration">
                        {{ $t("message.debatable_time") }}：{{ item.debatableTime }}
                      </div>
                      <div
                        class="keepfly-box-flex keepfly-box-column woo-box-alignEnd Item_optout"
                      ></div>
                    </div>
                  </div>
                  <div class="keepfly-box-item-flex Item_con" style="align-self: center">
                    <div class="star-autocut-2line star-f14 Item_h3">
                      {{ $t("message.missing_people") }}：{{ item.nameOfMissingPerson }}
                    </div>
                    <div class="keepfly-box-flex keepfly-box-alignCenter Item_lr">
                      <div
                        @click="sendprivateletter(item)"
                        class="keepfly-avatar-main keepfly-avatar-hover Item_face"
                        style="width: 2.125rem; height: 2.125rem"
                      >
                        <img :src="item.avatar" class="keepfly-avatar-img" />
                        <div class="keepfly-avatar-hoverMask"></div>
                      </div>
                      <div
                        class="keepfly-box-item-flex Item_conin"
                        style="align-self: center"
                      >
                        <div class="star-autocut Item_h4 Item_h4s">
                          {{ $t("message.created_by") }}：{{ item.nickname }}
                        </div>
                        <div class="star-autocut star-f12 Item_h4">
                          {{ $t("message.creation_time") }}：{{ item.createTime }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <n-pagination
              v-model:page="currLookForPage"
              size="medium"
              :page-count="lookForPages"
              :page-slot="7"
              @update-page="onDataChange"
            />
          </div>
          <div class="vue-recycle-scroller__item-view" v-else>
            <div
              class="vue-recycle-scroller__item-view"
              style="background: #fff; padding: 40px 10px"
            >
              <n-empty :description="$t('message.none')">
                <template #extra></template>
              </n-empty>
            </div>
          </div>
        </div>
      </div>
      <div v-if="navTab3Show == 2">
        <div class="Tit_wrap">
          <div class="keepfly-box-flex keepfly-box-alignEnd">
            <div class="keepfly-box-item-flex" style="align-self: end">
              <div class="keepfly-box-flex keepfly-box-alignEnd">
                <div class="Tit_tit">
                  {{ $t("message.registration_of_seeking_relatives") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="keepfly-panel-main Feed_body">
          <n-form
            ref="formRef"
            :model="lookForModel"
            label-placement="left"
            :label-width="230"
            require-mark-placement="right-hanging"
            :style="{
              maxWidth: '640px',
            }"
          >
            <n-form-item :label="$t('message.front_cover')" path="inputValue">
              <img
                :src="lookForModel.frontCover"
                v-if="lookForModel.frontCover"
                style="width: 96px; height: 96px; margin-right: 10px"
              />
              <div
                class="n-upload-trigger n-upload-trigger--image-card"
                @click="djFileView"
              >
                <div class="n-upload-dragger">
                  <i class="n-base-icon">
                    <svg
                      width="512"
                      height="512"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256 112V400M400 256H112"
                        stroke="currentColor"
                        stroke-width="32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </i>
                </div>
              </div>
              <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
                {{ $t("message.tips162") }}
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .bmp, .gif, .png"
                ref="lookFor_file"
                @change="selectLookForImg"
                style="display: none"
              />
            </n-form-item>
            <n-form-item :label="$t('message.name_of_missing_person')" path="inputValue">
              <n-input
                status="warning"
                v-model:value="lookForModel.nameOfMissingPerson"
                :placeholder="$t('message.name_of_missing_person')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.gender')" path="inputValue">
              <n-switch :rail-style="railStyle" v-model:value="lookForModel.gendero">
                <template #checked>
                  {{ $t("message.man") }}
                </template>
                <template #unchecked>
                  {{ $t("message.woman") }}
                </template>
              </n-switch>
            </n-form-item>
            <n-form-item :label="$t('message.age')" path="inputValue">
              <n-input
                status="warning"
                v-model:value="lookForModel.age"
                :placeholder="$t('message.age')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.debatable_time')" path="inputValue">
              <n-date-picker
                status="warning"
                v-model:value="lookForModel.debatableTimeo"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
              />
            </n-form-item>
            <n-form-item
              :label="$t('message.location_of_disappearance')"
              path="inputValue"
            >
              <n-input
                status="warning"
                v-model:value="lookForModel.locationOfDisappearance"
                :placeholder="$t('message.location_of_disappearance')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.feature_description')" path="inputValue">
              <n-input
                status="warning"
                v-model:value="lookForModel.featureDescription"
                :placeholder="$t('message.feature_description')"
                type="textarea"
                :autosize="{
                  minRows: 3,
                  maxRows: 5,
                }"
              />
            </n-form-item>
            <n-form-item :label="$t('message.photograph')" path="inputValue">
              <img
                :src="lookForModel.photograph"
                v-if="lookForModel.photograph"
                style="width: 96px; height: 96px; margin-right: 10px"
              />
              <div
                class="n-upload-trigger n-upload-trigger--image-card"
                @click="djphotoFileView"
              >
                <div class="n-upload-dragger">
                  <i class="n-base-icon">
                    <svg
                      width="512"
                      height="512"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256 112V400M400 256H112"
                        stroke="currentColor"
                        stroke-width="32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </i>
                </div>
              </div>
              <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
                {{ $t("message.tips162") }}
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .bmp, .gif, .png"
                ref="lookForPhoto_file"
                @change="selectPhotoLookForImg"
                style="display: none"
              />
            </n-form-item>
            <n-form-item :label="$t('message.contact_number')" path="inputValue">
              <n-input
                status="warning"
                v-model:value="lookForModel.contactNumber"
                :placeholder="$t('message.contact_number')"
              />
            </n-form-item>
            <div style="display: flex; justify-content: flex-end">
              <n-button round type="warning" @click="handleValidateButtonClick">
                {{ $t("message.check_in") }}
              </n-button>
            </div>
          </n-form>
        </div>
      </div>
    </div>
    <n-modal
      v-model:show="lookForshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.find_it')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div>
        <n-thing>
          <template #avatar>
            <n-image style="width: 100px" :src="lookforinfolist.frontCover" />
          </template>
          <template #header>
            {{ $t("message.name_of_missing_person") }}:
            {{ lookforinfolist.nameOfMissingPerson }}
          </template>
          <template #description>
            {{ $t("message.debatable_time") }}: {{ lookforinfolist.debatableTime }}
            {{ $t("message.gender") }}: {{ lookforinfolist.gender == 1 ? "男" : "女"
            }}<br />
            {{ $t("message.location_of_disappearance") }}:
            {{ lookforinfolist.locationOfDisappearance }} {{ $t("message.age") }}:
            {{ lookforinfolist.age }}
          </template>
          {{ $t("message.contact_number") }}: {{ lookforinfolist.contactNumber }}<br />
          {{ $t("message.feature_description") }}:
          <div v-if="lookforinfolist.descAr">{{ lookforinfolist.descAr }}</div>
          <div v-if="lookforinfolist.descZh">{{ lookforinfolist.descZh }}</div>
          <div v-if="lookforinfolist.descKo">{{ lookforinfolist.descKo }}</div>
          <div v-if="lookforinfolist.descJa">{{ lookforinfolist.descJa }}</div>
          <div v-if="lookforinfolist.descRu">{{ lookforinfolist.descRu }}</div>
          <div v-if="lookforinfolist.descEn">{{ lookforinfolist.descEn }}</div>
          <n-collapse>
            <template #arrow></template>
            <n-collapse-item :title="$t('message.view_the_original_article')" name="1">
              <div style="padding-left: 6px">
                {{ lookforinfolist.featureDescription }}
              </div>
            </n-collapse-item>
          </n-collapse>
          <template #footer>
            <n-input
              v-model:value="lookForInfofrom.content"
              type="textarea"
              status="warning"
              :placeholder="$t('message.message')"
            /><br /><br />
            <n-button
              style="width: 120px"
              @click="sendlookFor(lookforinfolist.id)"
              type="warning"
            >
              <!-- {{ $t("message.send") }} -->
              <svg
                style="width: 22px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                enable-background="new 0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill="#fff"
                    d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                  ></path>
                </g>
              </svg>
            </n-button>
          </template>
        </n-thing>
      </div>
      <template #footer>
        <img
          v-show="lookforinfolist.photograph"
          :src="lookforinfolist.photograph"
          style="width: 100%"
        />
      </template>
    </n-modal>
  </div>
</template>
<script>
const railStyle = ({ focused, checked }) => {
  const style = {};
  if (checked) {
    style.background = "#2080f0";
    if (focused) {
      style.boxShadow = "0 0 0 2px #2080f040";
    }
  } else {
    style.background = "#ec80ab";
    if (focused) {
      style.boxShadow = "0 0 0 2px #d0305040";
    }
  }
  return style;
};
import { defineComponent, onMounted, ref } from "vue";
import {
  NDatePicker,
  NInput,
  NButton,
  NForm,
  NFormItem,
  NSwitch,
  useMessage,
  NModal,
  NThing,
  NCollapse,
  NCollapseItem,
  NImage,
  NEmpty,
  NPagination,
} from "naive-ui";
import { FootstepsOutline, IdCardOutline } from "@vicons/ionicons5";
import i18n from "../../i18n/index";
import API from "../../uilts/request";
import utils from "../../uilts/utils";
import { client } from "../../uilts/alioss";
import bus from "../../uilts/bus.js";
export default defineComponent({
  name: "LookForView",
  components: {
    NButton,
    FootstepsOutline,
    IdCardOutline,
    NDatePicker,
    NInput,
    NForm,
    NFormItem,
    NSwitch,
    NModal,
    NThing,
    NCollapse,
    NCollapseItem,
    NImage,
    NEmpty,
    NPagination,
  },
  setup(props, context) {
    const message = useMessage();
    const navTab3Show = ref(1);
    const setNavTab3 = (e) => {
      if (e == 1) {
        getLookForList();
      }
      navTab3Show.value = e;
    };
    const currLookForPage = ref(1);
    const pageLookForSize = ref(21);
    const lookForList = ref([]);
    const lookForPages = ref(0);
    const getLookForList = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currLookForPage.value,
        pageSize: pageLookForSize.value,
      };
      API({
        url: "/kuke/api/index/getlookforlist",
        method: "POST",
        data: param,
      }).then((result) => {
        lookForList.value = JSON.parse(JSON.stringify(result.data.data.records));
        lookForPages.value = result.data.data.pages;
      });
    };
    const onDataChange = (val) => {
      currLookForPage.value = val;
      getLookForList();
    };
    onMounted(() => {
      getLookForList();
    });
    const lookForModel = ref({
      nameOfMissingPerson: "",
      gendero: true,
      gender: 1,
      age: "",
      locationOfDisappearance: "",
      featureDescription: "",
      photograph: "",
      contactNumber: "",
      frontCover: "",
      debatableTimeo: null,
      debatableTime: "",
    });
    const handleValidateButtonClick = () => {
      if (lookForModel.value.frontCover == "") {
        message.error(i18n.global.t("message.front_cover"));
        return false;
      } else if (lookForModel.value.nameOfMissingPerson == "") {
        message.error(i18n.global.t("message.name_of_missing_person"));
        return false;
      } else if (lookForModel.value.age == "") {
        message.error(i18n.global.t("message.age"));
        return false;
      } else if (lookForModel.value.debatableTimeo == "") {
        message.error(i18n.global.t("message.debatable_time"));
        return false;
      } else if (lookForModel.value.locationOfDisappearance == "") {
        message.error(i18n.global.t("message.location_of_disappearance"));
        return false;
      } else if (lookForModel.value.featureDescription == "") {
        message.error(i18n.global.t("message.feature_description"));
        return false;
      } else if (lookForModel.value.contactNumber == "") {
        message.error(i18n.global.t("message.contact_number"));
        return false;
      }
      lookForModel.value.debatableTime = utils.dateFormat(
        lookForModel.value.debatableTimeo
      );
      lookForModel.value.gender = lookForModel.value.gendero ? 1 : 0;
      API({
        url: "/kuke/api/kuke-look-for/addKukeLookFor",
        method: "POST",
        data: lookForModel.value,
      }).then((result) => {
        if (result.data.code == 200) {
          lookForModel.value = {
            nameOfMissingPerson: "",
            gendero: true,
            gender: 1,
            age: "",
            locationOfDisappearance: "",
            featureDescription: "",
            photograph: "",
            contactNumber: "",
            frontCover: "",
            debatableTimeo: null,
            debatableTime: "",
          };
          message.success(i18n.global.t("message.tips163"));
        } else {
          message.error(i18n.global.t("message.tips154"));
        }
      });
    };
    const lookForshow = ref(false);
    const lookforinfolist = ref({});
    const lookForInfoView = (e) => {
      lookforinfolist.value = e;
      lookForshow.value = true;
    };
    const lookForInfofrom = ref({
      content: "",
      lId: "",
    });
    const sendlookFor = (e) => {
      lookForInfofrom.value.lId = e;

      API({
        url: "/kuke/api/user/sendlookFor",
        method: "POST",
        data: lookForInfofrom.value,
      }).then((result) => {
        if (result.data.code == 200) {
          lookForInfofrom.value.content = "";
          lookForInfofrom.value.lId = "";
          lookForshow.value = false;
          message.success(i18n.global.t("message.tips164"));
        } else {
          message.error(i18n.global.t("message.tips165"));
        }
      });
    };
    const lookFor_file = ref();
    const djFileView = () => {
      lookFor_file.value.value = "";
      lookFor_file.value.click();
    };
    const selectLookForImg = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 10;
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              lookForModel.value.frontCover = "http://img.keepfly.net/" + fileName;
            });
        };
      };
      reader.readAsDataURL(file);
    };
    const lookForPhoto_file = ref();
    const djphotoFileView = () => {
      lookForPhoto_file.value.value = "";
      lookForPhoto_file.value.click();
    };
    const selectPhotoLookForImg = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          lookForModel.value.photograph = "http://img.keepfly.net/" + fileName;
        });
    };
    const sendprivateletter = (e) => {
      let params = {
        id: e.userId,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          context.emit("eventParent", 4);
          bus.emit("sendToBrother", 4);
          window.localStorage.setItem("sendTohuati", 4);
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        }
      });
    };
    return {
      lookForPages,
      onDataChange,
      sendprivateletter,
      railStyle,
      lookForPhoto_file,
      lookFor_file,
      lookforinfolist,
      djFileView,
      selectPhotoLookForImg,
      djphotoFileView,
      selectLookForImg,
      lookForInfoView,
      lookForInfofrom,
      sendlookFor,
      lookForshow,
      lookForList,
      lookForModel,
      handleValidateButtonClick,
      navTab3Show,
      setNavTab3,
      getLookForList,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
