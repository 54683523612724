<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side">
      <div class="Nav_main" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner wbpro-scrollbar"
        >
          <h2 class="Nav_title">{{ $t("message.set") }}</h2>
          <div>
            <div>
              <a
                @click="setNavTab5(1)"
                :class="[
                  navTab5Show == 1
                    ? 'router-link-exact-active router-link-active ALink_default'
                    : 'ALink_default',
                ]"
              >
                <div
                  :class="[
                    navTab5Show == 1
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.edit_data')"
                >
                  <settings-outline
                    class="keepfly-font keepfly-font--navTedit NavItem_icon"
                  ></settings-outline>
                  <span class="NavItem_text">{{ $t("message.edit_data") }}</span>
                </div>
              </a>
              <!-- <a
                @click="setNavTab5(2)"
                :class="[
                  navTab5Show == 2
                    ? 'router-link-exact-active router-link-active ALink_default'
                    : 'ALink_default',
                ]"
              >
                <div
                  :class="[
                    navTab5Show == 2
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.privacy_setting')"
                >
                  <document-lock-outline
                    class="keepfly-font keepfly-font--navTprivacy NavItem_icon"
                  ></document-lock-outline>
                  <i></i>
                  <span class="NavItem_text">{{ $t("message.privacy_setting") }}</span>
                </div>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" v-if="navTab5Show == 1">
          <div
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
            style="margin-bottom: 30px"
          >
            <div class="wbpro-setup__box">
              <div class="wbpro-setup__tit">{{ $t("message.tips9") }}</div>
              <div class="Avatar_picbox">
                <div class="keepfly-picture-main keepfly-picture-square Avatar_pic">
                  <person-sharp
                    class="keepfly-picture-img"
                    style="background: #a9a9a92e; color: #a9a9a9c2"
                    v-if="keepflyUserInfo.avatar == null"
                  ></person-sharp>
                  <img :src="keepflyUserInfo.avatar" class="keepfly-picture-img" v-eles />
                  <div class="keepfly-picture-slot">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Avatar_picin"
                      style="border-radius: 50px; height: 100%"
                      @click="AvatarView"
                    >
                      <camera-outline
                        class="keepfly-font keepfly-font--camera Avatar_picicon"
                        style="width: 40px"
                      ></camera-outline>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.nickname") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="wbpro-setup__ipt1 keepfly-input-wrap Input_input">
                    <input
                      type="text"
                      v-model="keepflyUserInfo.nickname"
                      :placeholder="$t('message.nickname')"
                      maxlength="30"
                      class="keepfly-input-main"
                    />
                  </div>
                  <div class="wbpro-setup__tip">
                    <span class="wbpro-setup__h5">{{ $t("message.tips10") }}</span>
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.id") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="wbpro-setup__ipt1 keepfly-input-wrap Input_input">
                    <input
                      type="text"
                      v-model="keepflyUserInfo.kpId"
                      class="keepfly-input-main"
                      style="color: rgba(128, 128, 128, 0.845)"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.email") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="wbpro-setup__ipt1 Input_input" style="padding: 0 0.75em">
                    {{ keepflyUserInfo.email }}
                    <n-button type="error" size="tiny" ghost @click="chackemailView">
                      {{ $t("message.change") }}
                    </n-button>
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.sign") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="wbpro-setup__ipt1 keepfly-input-wrap Input_input">
                    <textarea
                      rows="4"
                      :placeholder="$t('message.tips11')"
                      v-model="keepflyUserInfo.profile"
                      maxlength="140"
                      class="keepfly-input-main"
                      style="height: 68px; padding: 10px 0px; resize: none"
                    ></textarea>
                  </div>
                  <div class="wbpro-setup__tip">
                    <span class="wbpro-setup__h5">{{ $t("message.tips12") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="wbpro-setup__line1 keepfly-divider-main keepfly-divider-x"></div>
            <div class="wbpro-setup__box">
              <div class="wbpro-setup__tit">{{ $t("message.tips13") }}</div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.gender") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="wbpro-setup__ipt2 keepfly-box-flex keepfly-box-alignCenter">
                    <label class="keepfly-radio-main">
                      <input
                        type="radio"
                        class="keepfly-radio-input"
                        @click="setGender(1)"
                      />
                      <span
                        :class="[
                          keepflyUserInfo.gender == 1
                            ? 'keepfly-radio-shadow keepfly-radio-checked'
                            : 'keepfly-radio-shadow',
                        ]"
                      ></span>
                      <span class="keepfly-radio-text">
                        {{ $t("message.man") }}
                      </span>
                    </label>
                    <label class="keepfly-radio-main">
                      <input
                        type="radio"
                        class="keepfly-radio-input"
                        @click="setGender(2)"
                      />
                      <span
                        :class="[
                          keepflyUserInfo.gender == 2
                            ? 'keepfly-radio-shadow keepfly-radio-checked'
                            : 'keepfly-radio-shadow',
                        ]"
                      ></span>
                      <span class="keepfly-radio-text">
                        {{ $t("message.woman") }}
                      </span>
                    </label>
                    <label class="keepfly-radio-main">
                      <input
                        type="radio"
                        class="keepfly-radio-input"
                        @click="setGender(0)"
                      />
                      <span
                        :class="[
                          keepflyUserInfo.gender == 0
                            ? 'keepfly-radio-shadow keepfly-radio-checked'
                            : 'keepfly-radio-shadow',
                        ]"
                      ></span>
                      <span class="keepfly-radio-text">
                        {{ $t("message.other") }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.birthday") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="keepfly-box-flex keepfly-box-alignCenter">
                    <n-date-picker
                      status="warning"
                      v-model:formatted-value="keepflyUserInfo.birthday"
                      value-format="yyyy-MM-dd"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">
                    {{ $t("message.emotional_status") }}
                  </div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="keepfly-box-flex keepfly-box-alignCenter">
                    <n-select
                      status="warning"
                      v-model:value="keepflyUserInfo.emotionalStatus"
                      :placeholder="$t('message.tips14')"
                      :options="feelOptions"
                      style="width: 200px"
                    >
                    </n-select>
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.address") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="keepfly-box-flex keepfly-box-alignCenter">
                    <n-input
                      status="warning"
                      v-model:value="keepflyUserInfo.address"
                      :placeholder="$t('message.address')"
                    />
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <div
                  class="wbpro-setup__b1 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyEnd"
                >
                  <div class="wbpro-setup__h3">{{ $t("message.hometown") }}</div>
                </div>
                <div class="keepfly-box-item-flex">
                  <div class="keepfly-box-flex keepfly-box-alignCenter">
                    <n-input
                      status="warning"
                      v-model:value="keepflyUserInfo.hometown"
                      :placeholder="$t('message.hometown')"
                    />
                  </div>
                </div>
              </div>
              <div class="wbpro-setup__f1 keepfly-box-flex">
                <n-button strong secondary type="warning" @click="saveUserInfo">
                  {{ $t("message.save") }}
                </n-button>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab5Show == 2">
          <div
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap_2ibWe Card_bottomGap_2Xjqi"
          >
            <div class="wbpro-setup__box">
              <div class="wbpro-setup__tit">{{ $t("message.tips109") }}</div>
              <div class="wbpro-setup__f3">
                <div>
                  <div class="wbpro-setup__h3">
                    {{ $t("message.privacy_setting") }}
                  </div>
                  <div class="wbpro-setup__h4"></div>
                </div>
                <div class="wbpro-setup__b2" style="width: 130px">
                  <n-select
                    v-model:value="ConcealNum"
                    status="warning"
                    @update:value="handleUpdateValue"
                    :options="ysoptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 736px">
            <div class="Side_sideBox Side_posSticky grayTheme" style="top: 63px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div>
                      <div
                        class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                      >
                        <div
                          class="f14 cla keepfly-box-item-flex"
                          style="align-self: center"
                        >
                          Keepfly {{ $t("message.hot_search") }}
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter"
                          @click="refreshlist"
                        >
                          <refresh
                            class="keepfly-font f14 clb keepfly-font--refresh"
                            style="width: 17px"
                          ></refresh>
                          <span class="f12 clb">{{ $t("message.click_refresh") }}</span>
                        </div>
                      </div>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <n-spin :show="hotshow">
                        <div
                          class="wbpro-side-card7"
                          v-for="(item, index) in hotmicroblogs"
                          :key="item"
                        >
                          <div class="wbpro-side-panel">
                            <a class="ALink_default" target="_blank">
                              <div class="con keepfly-box-flex keepfly-box-alignCenter">
                                <div class="rank top" v-if="index == 0">
                                  <svg
                                    class="keepfly-font keepfly-font--top2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 0 0-12.6 0l-112 141.7a7.98 7.98 0 0 0 6.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                                <div class="rank num" v-if="index == 1">1</div>
                                <div class="rank num" v-if="index == 2">2</div>
                                <div class="rank num" v-if="index == 3">3</div>
                                <div class="rank" v-if="index >= 4">{{ index + 1 }}</div>
                                <div class="wbpro-textcut f12 cla">
                                  <span v-if="item.topicTitle">{{
                                    item.topicTitle
                                  }}</span>
                                </div>
                              </div>
                              <div class="keepfly-divider-main keepfly-divider-x"></div>
                            </a>
                          </div>
                        </div>
                      </n-spin>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <a class="ALink_default">
                        <button
                          @click="joinHot"
                          class="wbpro-side-btn f12 clb keepfly-button-main keepfly-button-simple keepfly-button-default keepfly-button-m keepfly-button-round keepfly-button-fluid"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              {{ $t("message.tips26") }}
                            </span>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span class="CopyRight_contentItem"
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="AvatarshowModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.change_avatar')"
      size="huge"
      :bordered="false"
    >
      <div>
        <div
          v-for="(item, index) in avatarList"
          :key="item"
          style="display: inline-block"
        >
          <n-avatar
            :size="48"
            :src="item.imgsrc"
            @click="checkAvatar(index + 1)"
            :style="{
              color: 'yellow',
              border: '2px solid red',
              margin: '0 10px 0 0',
            }"
            v-if="bindex == index + 1"
          />
          <n-avatar
            :size="48"
            :src="item.imgsrc"
            @click="checkAvatar(index + 1)"
            :style="{
              color: 'yellow',
              border: 'none',
              margin: '0 10px 0 0',
            }"
            v-else
          />
        </div>
        <div
          class="n-upload-trigger n-upload-trigger--image-card"
          @click="djuserFileView"
        >
          <div class="n-upload-dragger">
            <i class="n-base-icon">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 112V400M400 256H112"
                  stroke="currentColor"
                  stroke-width="32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </div>
        </div>
        <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
          {{ $t("message.tips105") }}
        </div>
        <input
          type="file"
          accept=".jpg, .jpeg, .bmp, .gif, .png"
          ref="Avatar_file"
          @change="selectImg"
          style="display: none"
        />
      </div>
      <template #footer>
        <n-button @click="tj_Avatar()" type="warning">
          {{ $t("message.determine") }}
        </n-button>
      </template>
    </n-modal>
    <n-modal
      v-model:show="emailViewshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.change_email')"
      size="huge"
      :bordered="false"
    >
      <div>
        <n-form
          ref="formRef"
          :model="email_model"
          :rules="rules"
          label-placement="left"
          label-width="auto"
          require-mark-placement="right-hanging"
          :size="size"
          :style="{
            maxWidth: '640px',
          }"
        >
          <n-form-item :label="$t('message.email')">
            <n-input
              v-model:value="keepflyUserInfo.email"
              disabled="false"
              :placeholder="$t('message.email')"
              status="warning"
            />
          </n-form-item>
          <n-form-item :label="$t('message.new_email')">
            <n-input
              v-model:value="email_model.newemail"
              :placeholder="$t('message.new_email')"
              status="warning"
            />
          </n-form-item>
          <n-form-item :label="$t('message.verification_code')">
            <n-input
              v-model:value="email_model.yzcode"
              :placeholder="$t('message.verification_code')"
              status="warning"
            />
            <a href="javascript:;" class="e-model" @click="sendCode">
              {{ codeNum == 60 ? $t("message.gain") : `(${codeNum})s` }}
            </a>
          </n-form-item>

          <div style="display: flex; justify-content: flex-end">
            <n-button
              round
              type="warning"
              class="e-button"
              @click="handleValidateButtonClick"
            >
              {{ $t("message.confirm") }}
            </n-button>
          </div>
        </n-form>
      </div>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import {
  PersonSharp,
  CameraOutline,
  Refresh,
  SettingsOutline,
  // DocumentLockOutline,
} from "@vicons/ionicons5";
import API from "../../uilts/request";
import {
  NSelect,
  NDatePicker,
  NInput,
  NButton,
  useMessage,
  NSpin,
  NModal,
  NAvatar,
  NForm,
  NFormItem,
} from "naive-ui";
import i18n from "../../i18n/index";
import { client } from "../../uilts/alioss";
import bus from "../../uilts/bus.js";
const feelOptions = [
  {
    label: i18n.global.t("message.single"),
    value: 1001,
  },
  {
    label: i18n.global.t("message.seeking_a_relationship"),
    value: 1002,
  },
  {
    label: i18n.global.t("message.in_secret_love"),
    value: 1003,
  },
  {
    label: i18n.global.t("message.ambiguous"),
    value: 1004,
  },
  {
    label: i18n.global.t("message.in_love"),
    value: 1005,
  },
  {
    label: i18n.global.t("message.engaged"),
    value: 1006,
  },
  {
    label: i18n.global.t("message.married"),
    value: 1007,
  },
  {
    label: i18n.global.t("message.separated"),
    value: 1008,
  },
  {
    label: i18n.global.t("message.divorced"),
    value: 1009,
  },
  {
    label: i18n.global.t("message.widow"),
    value: 1010,
  },
  {
    label: i18n.global.t("message.not_selected"),
    value: 1011,
  },
];
export default defineComponent({
  name: "IndividualView",
  components: {
    CameraOutline,
    PersonSharp,
    NSelect,
    NDatePicker,
    NInput,
    NButton,
    Refresh,
    NSpin,
    SettingsOutline,
    // DocumentLockOutline,
    NModal,
    NAvatar,
    NForm,
    NFormItem,
  },
  setup(props, context) {
    const message = useMessage();
    const navTab5Show = ref(1);
    const setNavTab5 = (e) => {
      navTab5Show.value = e;
      if (e == 2) {
        getUserConceal();
      }
      window.scrollTo(0, 0);
    };
    const getUserConceal = () => {
      API({
        url: "/kuke/api/kuke-user/getUserConceal",
        method: "GET",
      }).then((result) => {
        ConcealNum.value = result.data.data == null ? 0 : result.data.data;
      });
    };
    const keepflyUserInfo = ref({});
    const getKeepflyUserInfo = () => {
      API({
        url: "/kuke/api/kuke-user/getKeepflyUserInfo",
        method: "GET",
      }).then((result) => {
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const kukesystemInfo = ref({});
    onMounted(() => {
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getKeepflyUserInfo();
      gethotmicrobloglist();
    });
    const setGender = (e) => {
      keepflyUserInfo.value.gender = e;
    };
    const saveUserInfo = () => {
      API({
        url: "/kuke/api/kuke-user/saveUserInfo",
        method: "POST",
        data: keepflyUserInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips51"));
        } else {
          message.error(i18n.global.t("message.tips52"));
        }
      });
    };
    const Avatar_file = ref();
    const submit_Avatar = () => {
      Avatar_file.value.value = "";
      Avatar_file.value.click();
    };
    const Avatar_show = ref(false);
    const AvatarshowModal = ref(false);
    const AvatarView = () => {
      AvatarshowModal.value = true;
      // submit_Avatar();
    };
    const selectImg = function (e) {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          keepflyUserInfo.value.avatar = "http://img.keepfly.net/" + fileName;
          let params = {
            avatar: keepflyUserInfo.value.avatar,
          };

          API({
            url: "/kuke/api/kuke-user/setAvatarInfo",
            method: "POST",
            data: params,
          }).then(() => {
            let user = JSON.parse(window.localStorage.getItem("infodata"));
            user.avatar = keepflyUserInfo.value.avatar;
            window.localStorage.setItem("infodata", JSON.stringify(user));
            bus.emit("avatar");
            AvatarshowModal.value = false;
          });
        });
    };
    const currPage = ref(1);
    const pageSize = ref(8);
    const hotmicroblogs = ref([]);
    const gethotmicrobloglist = () => {
      hotshow.value = true;
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/gethotmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        hotmicroblogs.value = arr;
        hotshow.value = false;
      });
    };
    const refreshlist = () => {
      gethotmicrobloglist();
    };
    const hotshow = ref(false);
    const joinHot = () => {
      context.emit("eventParent", 2);
      bus.emit("sendToBrother", 2);
    };
    const ConcealNum = ref(0);
    const handleUpdateValue = (value) => {
      let param = {
        lookConceal: value,
      };
      API({
        url: "/kuke/api/user/setKeepflyConcealSwitch",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          ConcealNum.value = value;
          message.success(i18n.global.t("message.successfully_saved"));
        } else {
          message.error(i18n.global.t("message.save_failed"));
        }
      });
    };
    const avatarList = ref([
      {
        imgsrc: "http://www.keepfly.net/image/avatar1.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar2.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar3.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar4.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar5.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar6.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar7.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar8.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar9.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar10.jpg",
      },
    ]);
    const djuserFileView = () => {
      Avatar_file.value.value = "";
      Avatar_file.value.click();
    };
    const bindex = ref(1);
    const checkAvatar = (e) => {
      bindex.value = e;
    };
    const tj_Avatar = () => {
      var avatar = avatarList.value[bindex.value - 1];
      let params = {
        avatar: avatar.imgsrc,
      };
      let user = JSON.parse(window.localStorage.getItem("infodata"));
      user.avatar = avatar.imgsrc;
      window.localStorage.setItem("infodata", JSON.stringify(user));
      bus.emit("avatar");
      API({
        url: "/kuke/api/kuke-user/setAvatarInfo",
        method: "POST",
        data: params,
      }).then(() => {
        keepflyUserInfo.value.avatar = avatar.imgsrc;
        AvatarshowModal.value = false;
      });
    };
    const emailViewshow = ref(false);
    const chackemailView = () => {
      emailViewshow.value = true;
    };
    const email_model = ref({});
    //倒计时初始变量
    const codeNum = ref(60);
    // 定时器id
    let clearId;
    // 发送验证码
    const sendCode = async () => {
      if (codeNum.value != 60) return;
      clearId = setInterval(() => {
        // 每次 时间1s -1
        codeNum.value--;
        // 时间=0时 清除定时器
        if (codeNum.value == 0) {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
        }
      }, 1000);
      API({
        url: "/kuke/api/user/getCode",
        method: "GET",
        params: { account: email_model.value.email },
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips22"));
        }
      });
    };
    const handleValidateButtonClick = () => {
      if (
        email_model.value.yzcode == null ||
        email_model.value.yzcode == "" ||
        email_model.value.yzcode == undefined
      ) {
        message.error(i18n.global.t("message.tips6"));
        return false;
      }
      if (
        email_model.value.newemail == null ||
        email_model.value.newemail == "" ||
        email_model.value.newemail == undefined
      ) {
        message.error(i18n.global.t("message.tips1"));
        return false;
      }
      API({
        url: "/kuke/api/user/upuseremail",
        method: "POST",
        data: email_model.value,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips51"));
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips52"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips25"));
        } else if (result.data.code == 603) {
          message.error(i18n.global.t("message.tips106"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips20"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips85"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips21"));
        }
      });
    };
    return {
      handleValidateButtonClick,
      sendCode,
      codeNum,
      email_model,
      emailViewshow,
      chackemailView,
      kukesystemInfo,
      tj_Avatar,
      bindex,
      checkAvatar,
      djuserFileView,
      avatarList,
      AvatarshowModal,
      handleUpdateValue,
      ConcealNum,
      joinHot,
      hotshow,
      gethotmicrobloglist,
      refreshlist,
      hotmicroblogs,
      submit_Avatar,
      Avatar_show,
      AvatarView,
      saveUserInfo,
      setGender,
      keepflyUserInfo,
      getKeepflyUserInfo,
      navTab5Show,
      setNavTab5,
      feelOptions,
      Avatar_file,
      selectImg,
      bodyStyle: {
        width: "600px",
      },
      ysoptions: [
        {
          label: "所有人可见",
          value: 0,
        },
        {
          label: "好友可见",
          value: 1,
        },
        {
          label: "自己可见",
          value: 2,
        },
      ],
    };
  },
});
</script>
