const ar = {
    message: {
      username: 'اسم المستخدم',
      password: 'كلمة السر',
      save: 'حفظ ',
      edit: 'تحرير',
      update: 'تحرير',
      delete: 'حذف ',
      currentLanguage: 'العربية',
      search: 'البحث ',
      popular: 'شعبية ',
      topic: 'الموضوع ',
      goldenSentenceList: 'الجملة الذهبية قائمة ',
      askQuestions: 'استشارة',
      SignIn: 'التوقيع ',
      register: 'التسجيل ',
      home: 'الصفحة الرئيسية',
      information: 'معلومات ',
      register_tis: 'لا keepfly بعد ؟',
      register_Now: 'تسجيل فوري',
      sign_in_now: 'تسجيل الدخول الآن',
      homepage: 'الصفحة الرئيسية',
      no_account_yet: 'لم تسجل بعد ؟',
      welcome: ' مرحبا',
      account_number: 'حساب رقم الحساب (صندوق البريد)',
      account_register: 'حساب التسجيل',
      nickname: 'الاسم المستعار',
      email: 'رقم الجوال ',
      mobile: 'صندوق البريد',
      confirm_password: ' تحديد كلمة السر',
      verification_code: 'رمز التحقق ',
      gain: 'الحصول على',
      tips1: 'الرجاء إدخال صندوق البريد',
      tips2: ' الرجاء إدخال كلمة السر ',
      tips3: 'الرجاء إدخال الاسم المستعار',
      tips4: ' الرجاء إدخال كلمة السر ',
      tips5: ' كلمة السر التي أدخلتها مرتين لا تتطابق ',
      tips6: ' الرجاء إدخال رمز التحقق من صحة ',
      follow_all: 'كل الاهتمام',
      up_to_date: 'الجملة الذهبية',
      friends: 'دائرة الأصدقاء',
      deceased: 'إدارة الموتى',
      tips7: ' هل هناك أي شيء جديد تريد أن تشارك ؟',
      emote: 'التعبير',
      picture: 'صورة',
      essay: 'مقالات',
      more: 'أكثر',
      send: 'إرسال',
      all: 'كل',
      advanced_search: 'تقدم بحث متقدم ',
      userinfo: ' بيانات المستخدم ',
      log_out: 'تسجيل الخروج',
      tips8: ' ما هو الموضوع الذي تريد استخدامه ؟ ',
      find_it: ' العثور على ',
      home_page: 'الصفحة الرئيسية',
      read: 'قراءة',
      discuss: 'مناقشة ',
      click_refresh: 'انقر فوق تحديث ',
      set: 'العمليات',
      edit_data: ' تحرير البيانات',
      privacy_setting: ' إعدادات الخصوصية',
      tips9: 'إعدادات معلومات الحساب',
      sign: 'توقيع',
      tips10: '4 ~ 30 حرفا ، دعم اللغة الإنجليزية ، الأرقام',
      tips11: ' أعرض نفسك',
      tips12: '1 ~ 140 حرفا',
      tips13: 'البيانات الشخصية',
      gender: 'الجنس',
      man: 'الرجل',
      woman: 'المرأة ',
      other: ' أخرى',
      birthday: 'عيد ميلاد',
      emotional_status: 'الحالة العاطفية',
      tips14: ' اختيار الحالة العاطفية',
      address: 'موقع',
      hometown: 'الوطن',
      single: 'أعزب',
      seeking_a_relationship: 'الاتصال',
      in_secret_love: 'سر الحب',
      ambiguous: 'في الغموض',
      in_love: 'في في الحب',
      engaged: 'الاستعداد للزواج',
      married: 'متزوج ',
      separated: 'انفصل',
      divorced: 'الطلاق ',
      widow: ' أرملة ',
      not_selected: 'نو لا اختيار',
      tips15: 'هل تريد أن ترى لي ؟',
      personal_homepage: 'شخصية الصفحة الرئيسية',
      my_home_page: 'الصفحة الرئيسية',
      my_attention: 'أنا أهتم',
      my_fans: 'بلدي المطارد',
      tips16: 'لا ملامح',
      belong_to: 'الانتماء',
      add: ' إضافة',
      look_around_the_world: ' البحث في جميع أنحاء العالم',
      registration_of_seeking_relatives: 'تسجيل',
      name_of_missing_person: 'اسم الشخص المفقود',
      age: 'العمر',
      debatable_time: 'وقت التلاشي',
      location_of_disappearance: 'مكان مفقود ',
      feature_description: 'وصف الميزة',
      photograph: 'صور',
      check_in: 'التسجيل',
      contact_number: ' رقم الهاتف',
      front_cover: 'الصفحة الأولى',
      missing_people: 'الرجل المفقود',
      created_by: 'خلق ',
      creation_time: 'إنشاء الوقت',
      comment: 'تعليق ',
      praise: 'تشجيع',
      system_notification: 'نظام الإخطار',
      tips17: 'الرجاء إدخال علبة البريد الصحيحة',
      tips17_o: 'الرجاء إدخال رقم الهاتف في الشكل الصحيح',
      avatar: 'صورة',
      tips18: ' الحساب موجود بالفعل',
      tips19: 'فشل التسجيل',
      tips20: 'رمز الخطأ',
      tips21: 'الحصول على رمز التحقق مرة أخرى',
      tips22: 'إرسال رمز التحقق بنجاح',
      tips23: 'تسجيل الدخول بنجاح',
      tips24: 'كلمة السر خطأ ',
      tips25: 'المستخدم غير موجود',
      tips26: 'عرض كامل قائمة البحث الساخنة',
      help_center: 'مركز المساعدة',
      customer: 'خدمة العملاء',
      self_service_center: 'مركز الخدمة الذاتية ',
      website_related: 'مواقع ذات صلة',
      tips27: 'التعاون والخدمات',
      cooperation_hotline: 'الخط الساخن',
      follow: 'قيمة ',
      tips28: 'تقديم أدلة',
      view_the_original_article: 'عرض المقال الأصلي',
      message: 'رسالة',
      tips29: 'إضافة النجاح',
      tips30: 'إضافة فشل',
      tips31: 'نشر التعليقات الخاصة بك',
      tips32: 'موجود بالفعل',
      tips33: 'النجاح في التسجيل',
      tips34: 'لا تهتم بنفسك',
      cancel: 'ألغى',
      tips35: 'حذف البيانات بنجاح',
      tips36: 'فشل حذف البيانات',
      name_of_the_deceased: 'اسم المتوفى',
      nationality: 'الجنسية ',
      date_of_birth: 'تاريخ الميلاد',
      date_of_death: 'تاريخ الوفاة',
      university: 'خريج كلية',
      occupation: 'المهنية',
      achievements: 'الإنجازات الشخصية',
      tips37: 'اسم المتوفى لا يمكن أن تكون فارغة',
      tips38: 'اختيار صورة',
      tips39: 'الجنسية لا يمكن أن تكون فارغة',
      tips40: 'اختيار تاريخ الميلاد',
      tips41: 'اختيار تاريخ الوفاة',
      tips42: 'خلق النجاح',
      tips43: 'فشل إنشاء',
      tips44: 'جميع المشجعين',
      account_of_the_deceased: 'حساب المتوفى',
      add_an_account: 'إضافة حساب',
      view: 'عرض',
      transfer: 'مبادلة',
      newly_increased: 'إضافة',
      opposite_id: 'وثائق الهوية',
      transfer_failed: 'فشل نقل',
      transfer_successful: 'نقل ناجحة',
      tips45: 'لا يمكن أن تمر على نفسك',
      tips46: 'لا يوجد حساب المتوفى',
      tips47: 'لا أحد',
      tips48: 'لا المشجعين',
      determine: 'أكّد',
      call_off: 'إلغاء',
      tips49: 'هل أنت متأكد من أنك سوف تتخلى عن التركيز على هذا المستخدم ؟',
      tips50: 'الجملة الذهبية',
      tips51: 'تعديل بنجاح',
      tips52: 'فشل التعديل',
      hint: 'تلميح',
      tips53: 'المحتوى : هل أنت متأكد من الشحن ؟',
      repeat: 'إلى الأمام',
      hot_search: 'البحث الساخنة',
      know: 'فهم',
      tips54: 'هناك دائما شخص يعرف',
      tips55: 'الإجابة على سؤالك ',
      solve: 'حسم',
      question: 'مشكلة',
      tips56: 'معرفة الملاحظات',
      tips57: 'أريد أن أسألك',
      submit: 'نقل',
      quick_answer: 'الجواب السريع',
      translate: 'ترجمة',
      tips58: 'فشل الترجمة',
      recommend: 'التوصية',
      effect: 'تأثير',
      number_of_fans: 'عدد المشجعين',
      quantity: 'عدد المحتوى',
      fans: 'المشجعين',
      tips59: 'إرسال الزهور بنجاح',
      tips60: ' فشل إرسال الزهور',
      tips61: 'تحرير النجاح',
      tips62: 'فشل التحرير',
      tips63: 'الرجاء إدخال اسم',
      tips64: 'الرجاء إدخال العلاقة',
      tips65: 'الرجاء اختيار الصورة',
      tips66: 'الرجاء إدخال عنوان',
      tips67: 'الرجاء إدخال المحتوى',
      tips68: 'الرجاء إدخال اسم لوحة',
      tips69: 'لوحة موجودة بالفعل',
      tips70: 'هل أنت متأكد من إرسال الزهور ؟',
      tips71: 'العلاقة مع المتوفى',
      album: 'معرض الصور',
      deceased_admin: 'مدير الميت',
      creator: 'الخالق',
      the_deceased: 'الميت',
      number_of_views: 'تصفح',
      update_time: 'تحديث الوقت',
      basic_info: 'معلومات',
      full_name: 'الاسم',
      alias: 'الاسم المستعار',
      language: 'جيد في اللغة',
      height: 'الطول',
      motto: 'شعار',
      interaction: 'تفاعلي',
      input_relationship: 'أدخل  العلاقة',
      content_addition: 'إضافة محتوى',
      image_upload: 'تحميل الصور',
      message_management: 'إدارة الرسائل',
      deceased_label: 'علامة',
      modify: 'تعديل',
      image_photo: 'الصورة',
      brief: 'مقدمة',
      label: 'علامة',
      increase: 'إضافة',
      constellation: 'كوكبة',
      relationship: 'العلاقة',
      title: 'العنوان',
      add_section: 'إضافة لوحة',
      modify_section: 'تعديل لوحة',
      module_name: 'اسم لوحة',
      content: 'المحتوى',
      section: 'لوحة',
      guestbook: 'ترك رسالة',
      operate: 'العمليات',
      early_experiences: 'الخبرة السابقة',
      deductive_experience: 'نجاح العمل',
      personal_life: 'الحياة الشخصية',
      award_records: 'حدث كبير',
      character_evaluation: 'تقييم',
      deceased_square: 'ساحة الموتى',
      memorial_plaza: 'ساحة تذكارية',
      ranking_list1: 'قائمة الزهور',
      release: 'صدر',
      message_list: 'قائمة الرسائل',
      tips72: 'حذف يرجى الاتصال بمسؤول',
      tips73: 'الرجاء إدخال رقم الهاتف المحمول 11 بت',
      none: 'غير موجود',
      details: 'تفاصيل',
      log_on: 'سجل',
      previous_article: 'أمامي',
      next_article: 'خلف',
      chapter_1: 'المادة الأولى',
      last_article: 'في النهاية',
      tips74: 'لا مزيد من البيانات',
      unknown: 'غير معروف',
      tips75: 'التأثير من قبل المستخدم تسجيل الدخول في كل يوم طويل ، التفاعلية عدد شامل',
      tips76: 'التعدي على الملكية الفكرية',
      tips77: 'الشائعات الكاذبة',
      tips78: 'سوء نوعية المحتوى',
      tips79: 'التعدي على الشرف',
      tips80: 'الإباحية المبتذلة',
      tips81: 'يشتبه في الاحتيال',
      tips82: 'ذات الصلة سياسيا',
      tips83: 'الإعلان والتسويق',
      tips84: 'تعزيز القيم السيئة',
      report: 'تقرير',
      report_content: 'المحتوى',
      reported_image: 'الصورة',
      reporting_link: 'الإبلاغ عن الرابط',
      reporting_type: 'نوع الإبلاغ',
      join: 'المشاركة',
      interact: 'رسالة تفاعلية',
      tips85: 'المستخدم معطل',
      tips86: 'صندوق البريد رمز التحقق',
      change_password: 'تعديل كلمة المرور',
      confirm: 'أكّد',
      return: 'أمامي',
      tips87: 'سؤالي',
      recommendo: 'التوصية',
      tips88: 'يوصي هذا الجواب ؟',
      tips89: 'يوصي النجاح',
      tips90: 'فشل التوصية',
      tips91: 'لا توجد مشكلة',
      tips92: 'حجم الصورة لا يمكن أن يكون أكبر من 10 M !',
      tips93: 'حجم الصورة لا يمكن أن يكون 0M !',
      tips94: 'الصورة ليست نسبة 1 : 1',
      tips95: 'نصيحة : يرجى تحميل الصور في حجم 1 : 1 ، وإلا فإنه لن ينجح .  يرجى التركيز على الصورة الرئيسية .  عموما جودة الصورة أقل من 10 م ',
      tips96: 'الصورة ليست نسبة 16 : 9',
      tips97: 'تعيين كلمة مرور جديدة',
      tips98: 'غير معروف',
      tips99: 'الرجاء إدخال رقم التعريف',
      tips100: 'الحماية الأصلية : حقوق الطبع والنشر التي يملكها الكاتب',
      tips101: 'البيان : نشر المحتوى ليس له علاقة مع منصة ، إذا وجدت معلومات غير صحيحة يمكن الإبلاغ عنه',
      tips102: 'اقتراح  تحميل 4 : 3 صورة أفقية طويلة ، عموما جودة الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips103: 'اقتراح  تحميل 3 : 4 عمودي خط طويل من الصور ، جودة الصورة الإجمالية أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا شكل',
      id: 'ID',
      tips104: 'تلقى تعليقات',
      private_letter: 'رسالة خاصة',
      reply: 'تغذية مرتدة',
      the_original: 'الأصل',
      change: 'تغير',
      change_avatar: 'تغيير الصورة',
      tips105: 'اقتراح  تحميل 1 : 1 الحجم ، جودة الصورة الإجمالية أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا تنسيق',
      change_email: 'تغيير صندوق البريد',
      new_email: 'صندوق بريد جديد',
      tips106: 'موجود بالفعل',
      tips107: 'خدمة العملاء على مدار 24 ساعة',
      tips108: 'الكاتب يمتلك',
      tips109: 'إعدادات الصفحة الرئيسية الشخصية',
      tips110: 'النجاح',
      tips111: 'هزم',
      tips112: 'نسيت كلمة السر',
      tips113: 'هذا المستخدم فقط يمكن أن نرى',
      tips114: 'هذا المستخدم فقط يمكن أن نرى صديق',
      year: 'السنة',
      moon: 'شهر',
      day: 'اليوم',
      B_C: 'ق',
      tips115: 'حسب سنة الوفاة',
      tips116: 'لا يمكنك إرسال رسائل خاصة إلى نفسك',
      tips117: 'اضغط على دخول لإرسال',
      tips118: 'عرض رسالة خاصة ~',
      tips119: 'لا رسالة خاصة ~',
      search_result: 'نتائج البحث عن',
      comprehensive: 'شامل',
      tips120: 'القائمة السوداء',
      tips121: 'لا يمكنك إضافة نفسك إلى القائمة السوداء',
      tips122: 'إضافة إلى القائمة السوداء',
      tips123: 'الطرف الآخر قد أضيفت إلى القائمة السوداء ، لا يمكن إرسال رسالة',
      tips124: 'لقد قمت بإضافة شخص إلى القائمة السوداء ، لا يمكن إرسال رسالة إلى ذلك',
      blacklist: 'القائمة السوداء',
      die_at_the_age_of: 'كم كبير',
      tips125: 'حذف الحوار ؟',
      tips126: 'لا تاريخ صالح الشكل',
      just: 'أحدث',
      tips127: 'منذ دقيقة',
      minute_ago: 'دقيقة مضت',
      tips128: 'منذ ساعة',
      hour_ago: 'قبل ساعات',
      yesterday: 'البارحة',
      days_ago: 'قبل أيام',
      weeks_ago: 'قبل أسابيع',
      tips129: 'الاسم الكامل',
      about_us: 'عنا',
      cooperation: 'الاستثمار والتعاون',
      walk_into: 'دخل',
      tips130: 'من خلال موقع على شبكة الإنترنت أو الهاتف مستشار مدير',
      tips131: 'التفاوض والتعاون : يمكنك الاتصال أو زيارة مباشرة إلى',
      tips132: 'إبرام اتفاقات لتحديد طرائق التعاون',
      tips133: 'خدمة الخط الساخن',
      tips134: 'الهاتف التعاوني',
      customer_service_1: 'خدمة العملاء',
      customer_service_2: 'خدمة العملاء الثاني',
      tips135: 'طلب إضافة / تصحيح',
      tips136: 'اسم الكلمات الرئيسية',
      tips137: 'عرض رسالة كاملة',
      message_board: 'رسالة المجلس',
      tips138: 'الميت الصفحة الرئيسية',
      copy: 'نسخ',
      my_answer: 'جوابي',
      my_password: 'كلمة السر الخاصة بي',
      tools: 'أداة',
      height_unit: 'وحدة الطول',
      tips139: 'اقتراح  تحميل 100 × 138 عمودي خط طويل من الصور ، جودة الصورة الإجمالية أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips140: 'اقتراح  تحميل 220 × 231 عمودي خط طويل من الصور ، جودة الصورة الإجمالية أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips141: 'حذف لوحة',
      tips142: 'لقد نشرت الجملة الذهبية',
      tips143: 'لا دردشة خاصة',
      tips144: 'نصيحة : إذا كان زر غير معروف هو التحقق من النظام سوف تظهر غير معروف',
      replicating_success: 'النجاح',
      tips145: 'أقترح أن يتم تحميل الصور في 1 : 1 الحجم ، يرجى وضع الصورة الرئيسية في المركز .  عموما جودة الصورة أقل من 10 م',
      tips146: 'الاهتمام بالفشل',
      tips147: 'الاهتمام',
      tips148: 'عدم الاهتمام',
      tips149: 'الاهتمام المتبادل',
      tips150: 'الاهتمام المتبادل الفشل',
      tips151: 'إزالة المستخدم من القائمة السوداء',
      tips152: 'فشل في نقل المستخدم من القائمة السوداء',
      tips153: 'صدر',
      tips154: 'فشل النشر',
      tips155: 'قدم',
      tips156: 'فشل',
      self: 'نفسي',
      sending_flowers: 'إرسال الزهور',
      successfully_saved:'حفظ بنجاح',
      save_failed:'فشل حفظ',
      clue: 'جديلة',
      tips157: 'تحديد العلاقة مع المتوفى ، مثل الزوج ، الأم ، صديق',
      tips158: 'اقترح تحميل 1 : 1 مربع حجم الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips159: 'الرجاء تحميل 1 : 1 مربع الصورة ، أو أنك لن تنجح .  يرجى وضع الصورة الرئيسية في المركز ، حجم الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا شكل',
      tips160: 'اقترح تحميل 220 : 230 الحجم الرأسي صورة طويلة ، حجم الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips161: 'من المستحسن أن تحميل 100 : 140 الحجم الرأسي صورة طويلة ، حجم الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      tips162: 'اقترح تحميل 100 : 56 الحجم الأفقي صورة طويلة ، حجم الصورة أقل من 10 م ، دعم JPG ، JPEG ، BMP ، GIF ، بابوا نيو غينيا الشكل',
      admin: 'مدير',
      tourist: 'السائحين',
      tips163: 'صدر بنجاح',
      id_number_copied: 'رقم الهوية تم نسخها',
      tips164: 'فكرة النجاح',
      tips165: 'فشل العرض',
      tips166: 'الجملة الذهبية نسخة ناجحة',
      tips167: 'إلغاء التركيز على النجاح',
      tips168: 'عدم التركيز',
      tips169: 'نشر الجملة الذهبية بنجاح',
      tips170: 'فشل نشر الجملة الذهبية',
      tips171: 'تعليقات على النجاح',
      tips172: 'فشل التعليق',
      tips173: 'الجواب هو النجاح',
      tips174: 'فشل الرد',
      tips175: 'نشر الأسئلة بنجاح',
      tips176: 'نشر السؤال فشل',
      tips177: 'رسالة فشل',
      deceasedo: 'إضافة الميت',
      tips178: 'الرجاء إدخال رقم تسجيل الخروج',
      tips179: 'رقم الحساب غير صحيح ، يرجى التحقق من الهوية مرة أخرى',
      tips180: 'الرجاء إدخال سبب تسجيل الخروج',
      tips181: 'التطبيق الناجح !  Keepfly سوف عملية التطبيق الخاص بك في غضون 7 أيام عمل',
      tips182: 'فشل التطبيق',
      tips183: 'خروج معرف',
      tips184: 'تسجيل الخروج من صندوق البريد',
      tips185: 'سبب الخروج',
      apply: 'طلب',
      tips186: 'أنت تسجيل الخروج من حساب keepfly . keepfly المنتجات والخدمات لن تكون متاحة في وقت لاحق ، لذا يرجى توخي الحذر !',
      tips187: 'keepfly لن تحتفظ بأي من السجلات الخاصة بك ، أو طلب استرداد بعد تسجيل الخروج',
      tips188: 'تسجيل الخروج من الحساب',
      tips189: 'لا إنشاء حساب المتوفى على قيد الحياة ، إذا كان هناك أي خطر قانوني لا علاقة له مع منصة',
      video: 'فيديو',
      tips190: 'يمكن تحميل الصور والفيديو فقط نوع واحد ، وتأكيد ؟',
      tips191: 'لا يمكن إرسال الزهور أكثر من 20 دقيقة',
      delete_account: 'حذف الحساب',
    }
  }
  export default ar