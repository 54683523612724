<template>
  <div>
    <div id="weibo_top_public">
      <div class="WB_global_nav WB_global_nav_v2" node-type="top_all">
        <div class="gn_header clearfix">
          <div class="gn_logo">
            <a href="" class="box" title="">
              <span class="logo"></span>
            </a>
          </div>
          <div class="gn_search_v2">
            <input
              type="text"
              v-model="searchtxt"
              :placeholder="$t('message.search')"
              class="W_input"
              @keydown.enter="searchKeydown"
            />
            <search-outline class="W_ficon ficon_search S_ficon"></search-outline>
          </div>
          <div
            class="keepfly-box-flex keepfly-box-alignCenter"
            style="margin-left: 16px; width: 20px; display: inline-block"
            onclick="javascript:history.back(-1);"
          >
            <img
              src="../assets/left.png"
              style="width: 26px; height: 26px; margin: 10px"
            />
          </div>
          <div class="gn_position">
            <div class="gn_nav">
              <ul class="gn_nav_list">
                <li>
                  <a
                    @click="$router.push('/')"
                    class="home S_txt1"
                    suda-uatrack="key=topnav_tab&amp;value=homepage"
                    target="_top"
                  >
                    <home-outline
                      class="W_ficon ficon_home S_ficon"
                      style="vertical-align: middle; width: 20px"
                    ></home-outline>
                  </a>
                </li>
              </ul>
            </div>
            <div class="gn_login">
              <ul v-if="!infodata" class="gn_login_list" style="font-size: 14px">
                <li>
                  <a href="/#/enroll" class="S_txt1" target="_top">{{
                    $t("message.register")
                  }}</a>
                </li>
                <li>
                  <a href="/#/login" class="S_txt1">{{ $t("message.log_on") }}</a>
                </li>
              </ul>
              <ul style="width: 100px" v-else>
                <li>{{ infodata.nickname }}</li>
              </ul>
            </div>
            <div class="gn_login">
              <ul class="gn_login_list" style="font-size: 14px">
                <li>
                  <n-select
                    @update:value="handleSearch"
                    v-model:value="selectedValue"
                    label-field="name"
                    value-field="language"
                    size="tiny"
                    status="warning"
                    :options="options"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--spec end-->
    </div>
    <article class="main_content">
      <section class="con_ae">
        <div id="plc_frame">
          <div>
            <div id="plc_main">
              <div>
                <div id="pl_content_selfservice">
                  <div class="csc_card">
                    <div class="card m-panel" style="padding: 5px 0">
                      <a href="javascript:;">
                        <div class="card-wrap">
                          <div class="card-main" style="padding: 5px 0">
                            <div class="m-box">
                              <div class="box-left m-box-col m-box-center-a">
                                <span class="link-text">
                                  <span class="main-link">{{
                                    $t("message.help_center")
                                  }}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="card m-panel card19 m-col-4">
                      <div class="item-list2">
                        <div
                          class="item-box"
                          :style="{ minHeight: `100px`, height: `auto` }"
                        >
                          <div class="item-box_content" ref="txtheight">
                            <h3>
                              <a class="item-box_title" v-if="servicedetaild.problemZh">{{
                                servicedetaild.problemZh
                              }}</a>
                              <a class="item-box_title" v-if="servicedetaild.problemEn">{{
                                servicedetaild.problemEn
                              }}</a>
                              <a class="item-box_title" v-if="servicedetaild.problemKo">{{
                                servicedetaild.problemKo
                              }}</a>
                              <a class="item-box_title" v-if="servicedetaild.problemJa">{{
                                servicedetaild.problemJa
                              }}</a>
                              <a class="item-box_title" v-if="servicedetaild.problemRu">{{
                                servicedetaild.problemRu
                              }}</a>
                              <a class="item-box_title" v-if="servicedetaild.problemAr">{{
                                servicedetaild.problemAr
                              }}</a>
                            </h3>
                            <div style="text-align: right">
                              {{ servicedetaild.createTime }}
                            </div>
                            <div class="intro" v-if="servicedetaild.answerZh">
                              <div v-html="servicedetaild.answerZh"></div>
                            </div>
                            <div class="intro" v-if="servicedetaild.answerEn">
                              <div v-html="servicedetaild.answerEn"></div>
                            </div>
                            <div class="intro" v-if="servicedetaild.answerRu">
                              <div v-html="servicedetaild.answerRu"></div>
                            </div>
                            <div class="intro" v-if="servicedetaild.answerKo">
                              <div v-html="servicedetaild.answerKo"></div>
                            </div>
                            <div class="intro" v-if="servicedetaild.answerJa">
                              <div v-html="servicedetaild.answerJa"></div>
                            </div>
                            <div class="intro" v-if="servicedetaild.answerAr">
                              <div v-html="servicedetaild.answerAr"></div>
                            </div>
                          </div>
                          <div style="clear: both"></div>
                          <div style="padding: 16px">
                            <div>
                              {{ $t("message.previous_article") }}：
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemZh"
                                >{{ servicefront.problemZh }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemEn"
                                >{{ servicefront.problemEn }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemKo"
                                >{{ servicefront.problemKo }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemJa"
                                >{{ servicefront.problemJa }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemRu"
                                >{{ servicefront.problemRu }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(servicefront.id)"
                                v-if="servicefront.problemAr"
                                >{{ servicefront.problemAr }}</a
                              >
                              <a
                                style="cursor: pointer"
                                v-if="Object.keys(servicefront).length == 0"
                                >{{ $t("message.chapter_1") }}</a
                              >
                            </div>
                            <div>
                              {{ $t("message.previous_article") }}：
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemZh"
                                >{{ serviceafter.problemZh }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemEn"
                                >{{ serviceafter.problemEn }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemKo"
                                >{{ serviceafter.problemKo }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemJa"
                                >{{ serviceafter.problemJa }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemRu"
                                >{{ serviceafter.problemRu }}</a
                              >
                              <a
                                style="cursor: pointer"
                                @click="joinservice(serviceafter.id)"
                                v-if="serviceafter.problemAr"
                                >{{ serviceafter.problemAr }}</a
                              >
                              <a
                                style="cursor: pointer"
                                v-if="Object.keys(serviceafter).length == 0"
                                >{{ $t("message.last_article") }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="plc_bot"></div>
      </section>
    </article>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import API from "../uilts/request";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { SearchOutline, HomeOutline } from "@vicons/ionicons5";
import { NSelect } from "naive-ui";
export default defineComponent({
  name: "ServiceDetails",
  components: {
    SearchOutline,
    HomeOutline,
    NSelect,
  },
  setup() {
    const { locale } = useI18n();
    let route = useRoute();
    const id = route.params.id;
    const servicedetaild = ref({});
    const servicefront = ref({});
    const serviceafter = ref({});
    const hHeight = ref(0);
    const txtheight = ref();

    let arr = ref([]);
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", locale.value);
      location.reload();
    };
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const infodata = ref(null);
    onMounted(() => {
      if (window.localStorage.getItem("infodata")) {
        infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
        console.log(infodata.value);
      }
      servicedetaildinfo(id);
      getKukeLanguage();
      if (!localStorage.getItem("language")) {
        var lang = navigator.language;
        if (lang == "zh-CN") {
          localStorage.setItem("language", "zh");
        } else {
          localStorage.setItem("language", "en");
        }
      }
    });
    const langlocal = localStorage.getItem("language");
    const servicedetaildinfo = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        id: e,
      };
      API({
        url: "/kuke/api/index/serviceDetaildinfo",
        method: "POST",
        data: param,
      }).then((result) => {
        servicedetaild.value = result.data.data.kukeHotIssuesinfo;
        if (result.data.data.kukeHotIssuesfront) {
          servicefront.value = result.data.data.kukeHotIssuesfront;
        } else {
          servicefront.value = {};
        }
        if (result.data.data.kukeHotIssuesafter) {
          serviceafter.value = result.data.data.kukeHotIssuesafter;
        } else {
          serviceafter.value = {};
        }
      });
    };
    const searchKeydown = () => {
      sessionStorage.setItem("searchtxt", searchtxt.value);
      window.history.go(-1);
    };
    const searchtxt = ref("");
    const joinservice = (e) => {
      servicedetaildinfo(e);
    };
    return {
      infodata,
      locale,
      selectedValue: ref(langlocal),
      options: arr,
      changeLang,
      joinservice,
      serviceafter,
      servicefront,
      searchKeydown,
      searchtxt,
      txtheight,
      hHeight,
      servicedetaild,
      handleSearch: (value) => {
        changeLang(value);
      },
    };
  },
});
</script>
<style>
.WB_global_nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 48px;
  border-top: 2px solid #fa7d3c;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
}
.WB_global_nav .gn_header {
  position: relative;
  height: 48px;
  margin: 0 auto;
}
.WB_global_nav .gn_logo {
  left: auto;
  top: auto;
  margin: 0;
  background: none;
}
.WB_global_nav .gn_logo {
  float: left;
  position: relative;
  width: 49px;
  height: 48px;
}
.WB_global_nav .gn_search_v2 {
  position: relative;
  float: left;
  width: 288px;
  height: 28px;
  margin: 9px 0 0 10px;
  background-color: #f2f2f5;
  border: 1px solid #ccc;
}
.WB_global_nav_v2 .gn_search_v2 {
  width: 218px;
}
.WB_global_nav .gn_logo .box {
  width: 49px;
  height: 48px;
  cursor: pointer;
}
.WB_global_nav .gn_logo .box {
  position: absolute;
  left: -50px;
  top: 0;
}
.WB_footer a,
.WB_global_nav a {
  text-decoration: none;
  cursor: pointer;
}
.WB_global_nav .gn_logo .box .logo {
  display: block;
  margin-left: 50px;
  width: 49px;
  height: 48px;
  cursor: pointer;
  background: url(../assets/logo.png) no-repeat 0 40%;
  background-size: cover;
}
.WB_global_nav_v2 .gn_search_v2 .W_input {
  width: 175px;
}
.WB_global_nav .gn_search_v2 .W_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 217px;
  padding: 4px 33px 4px 10px;
  height: 27px;
  line-height: 27px;
  box-shadow: none;
  border: none;
  background-color: transparent;
  color: #808080;
  outline-style: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WB_global_nav .gn_search_v2 .ficon_search {
  position: absolute;
  top: 4px;
  right: 10px;
  color: #999999;
}
.WB_global_nav .ficon_search {
  background-position: -50px -175px;
  width: 22px;
}
.WB_global_nav .gn_position {
  width: 38.5%;
  float: right;
  margin-left: -11px;
}
.WB_global_nav .gn_nav {
  float: left;
  margin: 11px 20px 11px 0;
}
.WB_global_nav .gn_login {
  width: 124px;
  float: left;
  height: 24px;
  margin: 12px 0;
}
.WB_global_nav ol,
.WB_global_nav ul {
  list-style: none;
}
.WB_global_nav_v2 .gn_nav_list li {
  margin-left: 26px;
}
.WB_global_nav .gn_nav_list li {
  float: left;
  display: inline;
  position: relative;
  max-width: 139px\0;
  margin-left: 30px;
  font-size: 14px;
}
.WB_global_nav .gn_nav_list li a {
  display: block;
  height: 26px;
  line-height: 26px;
  white-space: nowrap;
}
.WB_global_nav .S_txt1,
.WB_global_nav .SW_fun .S_func1 {
  color: #333;
  text-decoration: none;
}
.WB_global_nav .gn_nav_list li a .W_ficon {
  vertical-align: top;
}
.WB_global_nav .ficon_home {
  background-position: -50px 1px;
}
.WB_global_nav .gn_nav_list li a .S_txt1 {
  display: inline-block;
  overflow: hidden;
}
.WB_global_nav .S_txt1,
.WB_global_nav .SW_fun .S_func1 {
  color: #333;
  text-decoration: none;
}
.WB_global_nav .gn_login_list li {
  float: left;
  display: inline;
  position: relative;
  line-height: 24px;
  margin: 0 15px;
  white-space: nowrap;
}
.WB_global_nav .gn_login_list li.W_vline {
  margin: 5px 0px 0 0;
  height: 14px;
}

.main_content {
  margin: 56px auto 0;
  width: 70%;
}
.csc_card {
  padding-bottom: 0.5625rem;
  margin-bottom: 0;
}
.csc_card {
  padding-bottom: 0.3125rem;
}
.card42::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.1875rem;
  background-color: #ff8200;
}
.item-list2 {
  margin-top: 5px;
}
.item-box {
  background: #fff;
  margin: 0 0 13px;
  padding: 20px;
  border-radius: 20px;
}
.item-box_content {
  width: 90%;
  /* float: left; */
}
.item-box_content {
  color: #999;
  font-size: 12px;
  overflow: hidden;
  margin-left: 20px;
  padding-right: 10px;
}
.item-box_content .item-box_title {
  display: block;
  text-align: center;
  color: #000;
  font-size: 16px;
}
.item-box_category {
  margin-top: 16px;
  margin-bottom: 16px;
}
.item-box_category span {
  line-height: 23px;
  padding: 2px 9px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #ddd;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
}
.item-box_category a {
  color: #787878;
}
.item-box_content .intro {
  color: #787878;
  font-size: 13px;
}

.item-box_detailsItem {
  margin-bottom: 6px;
  color: #787878;
  font-size: 12px;
}
.item-box_footerButtons {
  position: absolute;
  top: 0;
  right: 0;
}
.item-box_alignBottom {
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.item-box_alignBottom .btn_down {
  height: 26px;
  line-height: 26px;
  background: #fff;
  font-size: 12px;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  color: #666;
  width: 50px;
  border-radius: 50px;
  text-align: center;
  margin-right: 5px;
}
</style>
