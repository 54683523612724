import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/uilts/request'
import i18n from './i18n'

const app = createApp(App);
app.use(store).use(router).use(i18n).mount('#app');
app.config.globalProperties.$axios=axios;

app.config.globalProperties.$emoticon = [
    {
      title: "兔兔拜年",
      path: require("./assets/emoji/2023_happynewyear_org.png"),
      name: "兔兔拜年",
      value: "100001",
    },
    {
      title: "财源滚滚",
      path: require("./assets/emoji/2023_getrich_org.png"),
      name: "财源滚滚",
      value: "100002",
    },
    {
      title: "年年有余",
      path: require("./assets/emoji/2023_luckyyear_org.png"),
      name: "年年有余",
      value: "100003",
    },
    {
      title: "兔年红包",
      path: require("./assets/emoji/2023_redpacket_org.png"),
      name: "兔年红包",
      value: "100004",
    },
    {
      title: "鞭炮声声",
      path: require("./assets/emoji/2023_firecrackers_org.png"),
      name: "鞭炮声声",
      value: "100005",
    },
    {
      title: "团圆兔",
      path: require("./assets/emoji/2023_intimaterabbit_org.png"),
      name: "团圆兔",
      value: "100006",
    },
    {
      title: "2023",
      path: require("./assets/emoji/2022_2023_org.png"),
      name: "2023",
      value: "100007",
    },
    {
      title: "心动超崽",
      path: require("./assets/emoji/2022_CHAO_org.png"),
      name: "心动超崽",
      value: "100008",
    },
    {
      title: "锦鲤附体",
      path: require("./assets/emoji/2022_cometrue_org.png"),
      name: "锦鲤附体",
      value: "100009",
    },
    {
      title: "努力",
      path: require("./assets/emoji/2022_Keepgoing_org.png"),
      name: "努力",
      value: "100010",
    },
    {
      title: "蚁人",
      path: require("./assets/emoji/2023_antman_org.png"),
      name: "蚁人",
      value: "100011",
    },
    {
      title: "流浪地球2",
      path: require("./assets/emoji/2023_TheWanderingEarthⅡ_org.png"),
      name: "流浪地球2",
      value: "100012",
    },
    {
      title: "欢乐熊出没",
      path: require("./assets/emoji/2023_BoonieBears_org.png"),
      name: "欢乐熊出没",
      value: "100013",
    },
    {
      title: "奈蒂莉",
      path: require("./assets/emoji/2022_Neytiri_org.png"),
      name: "奈蒂莉",
      value: "100014",
    },
    {
      title: "杰克·萨利",
      path: require("./assets/emoji/2022_JakeSully_org.png"),
      name: "杰克·萨利",
      value: "100015",
    },
    {
      title: "钢镚儿",
      path: require("./assets/emoji/2022_coin_org.png"),
      name: "钢镚儿",
      value: "100016",
    },
    {
      title: "干饭人",
      path: require("./assets/emoji/2022_Foodie_org.png"),
      name: "干饭人",
      value: "100017",
    },
    {
      title: "丰收了",
      path: require("./assets/emoji/2022_harvest_org.png"),
      name: "丰收了",
      value: "100018",
    },
    {
      title: "志愿者",
      path: require("./assets/emoji/2022_volunteer_org.png"),
      name: "志愿者",
      value: "100019",
    },
    {
      title: "玉兔捣药",
      path: require("./assets/emoji/2022_rabbitmash_org.png"),
      name: "玉兔捣药",
      value: "100020",
    },
    {
      title: "新鲜好柿",
      path: require("./assets/emoji/2022_TATO_org.png"),
      name: "新鲜好柿",
      value: "100021",
    },
    {
      title: "开学",
      path: require("./assets/emoji/2022_backschool_org.png"),
      name: "开学",
      value: "100022",
    },
    {
      title: "收到",
      path: require("./assets/emoji/2022_get_org.png"),
      name: "收到",
      value: "100023",
    },
    {
      title: "老师好",
      path: require("./assets/emoji/2022_Teacher_org.png"),
      name: "老师好",
      value: "100024",
    },
    {
      title: "单身青蛙",
      path: require("./assets/emoji/2022_SingleFrog_org.png"),
      name: "单身青蛙",
      value: "100025",
    },
    {
      title: "融化",
      path: require("./assets/emoji/2022_melt_org.png"),
      name: "融化",
      value: "100026",
    },
    {
      title: "许愿星",
      path: require("./assets/emoji/2022_WishingStar_org.png"),
      name: "许愿星",
      value: "100027",
    },
    {
      title: "冲刺",
      path: require("./assets/emoji/2022_Sprint_org.png"),
      name: "冲刺",
      value: "100028",
    },
    {
      title: "奋斗",
      path: require("./assets/emoji/2022_Struggle_org.png"),
      name: "奋斗",
      value: "100029",
    },
    {
      title: "锦鲤附体",
      path: require("./assets/emoji/2022_Koi_org.png"),
      name: "锦鲤附体",
      value: "100030",
    },
    {
      title: "放假",
      path: require("./assets/emoji/2021_fangjia_org.png"),
      name: "放假",
      value: "100031",
    },
    {
      title: "调休",
      path: require("./assets/emoji/2021_tiaoxiu_org.png"),
      name: "调休",
      value: "100032",
    },
    {
      title: "成人礼",
      path: require("./assets/emoji/2022_adults_org.png"),
      name: "成人礼",
      value: "100033",
    },
    {
      title: "毕业帽",
      path: require("./assets/emoji/2022_Graduationcap_org.png"),
      name: "毕业帽",
      value: "100033",
    },
    {
      title: "送花花",
      path: require("./assets/emoji/2022_Flowers_org.png"),
      name: "送花花",
      value: "100034",
    },
    {
      title: "举杠铃",
      path: require("./assets/emoji/2022_Weightlifting_org.png"),
      name: "举杠铃",
      value: "100035",
    },
    {
      title: "小雪人",
      path: require("./assets/emoji/2021_snowman_org.png"),
      name: "小雪人",
      value: "100036",
    },
    {
      title: "春游家族",
      path: require("./assets/emoji/2021_YoungFamily_org.png"),
      name: "春游家族",
      value: "100037",
    },
    {
      title: "微笑",
      path: require("./assets/emoji/2018new_weixioa02_org.png"),
      name: "微笑",
      value: "100038",
    },
    {
      title: "可爱",
      path: require("./assets/emoji/2018new_keai_org.png"),
      name: "可爱",
      value: "100039",
    },
    {
      title: "太开心",
      path: require("./assets/emoji/2018new_taikaixin_org.png"),
      name: "太开心",
      value: "100040",
    },
    {
      title: "鼓掌",
      path: require("./assets/emoji/2018new_guzhang_org.png"),
      name: "鼓掌",
      value: "100041",
    },
    {
      title: "嘻嘻",
      path: require("./assets/emoji/2018new_xixi_org.png"),
      name: "嘻嘻",
      value: "100042",
    },
    {
      title: "哈哈",
      path: require("./assets/emoji/2018new_haha_org.png"),
      name: "哈哈",
      value: "100043",
    },
    {
      title: "笑cry",
      path: require("./assets/emoji/2018new_xiaoku_thumb.png"),
      name: "笑cry",
      value: "100044",
    },
    {
      title: "挤眼",
      path: require("./assets/emoji/2018new_jiyan_org.png"),
      name: "挤眼",
      value: "100045",
    },
    {
      title: "馋嘴",
      path: require("./assets/emoji/2018new_chanzui_org.png"),
      name: "馋嘴",
      value: "100046",
    },
    {
      title: "黑线",
      path: require("./assets/emoji/2018new_heixian_org.png"),
      name: "黑线",
      value: "100047",
    },
    {
      title: "汗",
      path: require("./assets/emoji/2018new_han_org.png"),
      name: "汗",
      value: "100048",
    },
    {
      title: "挖鼻",
      path: require("./assets/emoji/2018new_wabi_thumb.png"),
      name: "挖鼻",
      value: "100049",
    },
    {
      title: "哼",
      path: require("./assets/emoji/2018new_heng_org.png"),
      name: "哼",
      value: "100050",
    },
    {
      title: "怒",
      path: require("./assets/emoji/2018new_nu_org.png"),
      name: "怒",
      value: "100051",
    },
    {
      title: "委屈",
      path: require("./assets/emoji/2018new_weiqu_org.png"),
      name: "委屈",
      value: "100052",
    },
    {
      title: "可怜",
      path: require("./assets/emoji/2018new_kelian_org.png"),
      name: "可怜",
      value: "100053",
    },
    {
      title: "失望",
      path: require("./assets/emoji/2018new_shiwang_org.png"),
      name: "失望",
      value: "100054",
    },
    {
      title: "悲伤",
      path: require("./assets/emoji/2018new_beishang_org.png"),
      name: "悲伤",
      value: "100055",
    },
    {
      title: "泪",
      path: require("./assets/emoji/2018new_leimu_org.png"),
      name: "泪",
      value: "100056",
    },
    {
      title: "允悲",
      path: require("./assets/emoji/2018new_kuxiao_org.png"),
      name: "允悲",
      value: "100057",
    },
    {
      title: "苦涩",
      path: require("./assets/emoji/2021_bitter_org.png"),
      name: "苦涩",
      value: "100058",
    },
    {
      title: "害羞",
      path: require("./assets/emoji/2018new_haixiu_org.png"),
      name: "害羞",
      value: "100059",
    },
    {
      title: "污",
      path: require("./assets/emoji/2018new_wu_org.png"),
      name: "污",
      value: "100060",
    },
    {
      title: "爱你",
      path: require("./assets/emoji/2018new_aini_org.png"),
      name: "爱你",
      value: "100061",
    },
    {
      title: "亲亲",
      path: require("./assets/emoji/2018new_qinqin_org.png"),
      name: "亲亲",
      value: "100062",
    },
    {
      title: "抱一抱",
      path: require("./assets/emoji/2020_hug_org.png"),
      name: "抱一抱",
      value: "100063",
    },
    {
      title: "色",
      path: require("./assets/emoji/2018new_huaxin_org.png"),
      name: "色",
      value: "100064",
    },
    {
      title: "憧憬",
      path: require("./assets/emoji/2018new_chongjing_org.png"),
      name: "憧憬",
      value: "100065",
    },
    {
      title: "舔屏",
      path: require("./assets/emoji/2018new_tianping_org.png"),
      name: "舔屏",
      value: "100066",
    },
    {
      title: "哇",
      path: require("./assets/emoji/2022_wow_org.png"),
      name: "哇",
      value: "100067",
    },
    {
      title: "坏笑",
      path: require("./assets/emoji/2018new_huaixiao_org.png"),
      name: "坏笑",
      value: "100068",
    },
    {
      title: "阴险",
      path: require("./assets/emoji/2018new_yinxian_org.png"),
      name: "阴险",
      value: "100069",
    },
    {
      title: "笑而不语",
      path: require("./assets/emoji/2018new_xiaoerbuyu_org.png"),
      name: "笑而不语",
      value: "100070",
    },
    {
      title: "偷笑",
      path: require("./assets/emoji/2018new_touxiao_org.png"),
      name: "偷笑",
      value: "100071",
    },
    {
      title: "666",
      path: require("./assets/emoji/2022_666_org.png"),
      name: "666",
      value: "100072",
    },
    {
      title: "酷",
      path: require("./assets/emoji/2018new_ku_org.png"),
      name: "酷",
      value: "100073",
    },
    {
      title: "并不简单",
      path: require("./assets/emoji/2018new_bingbujiandan_org.png"),
      name: "并不简单",
      value: "100074",
    },
    {
      title: "思考",
      path: require("./assets/emoji/2018new_sikao_org.png"),
      name: "思考",
      value: "100075",
    },
    {
      title: "疑问",
      path: require("./assets/emoji/2018new_ningwen_org.png"),
      name: "疑问",
      value: "100076",
    },
    {
      title: "费解",
      path: require("./assets/emoji/2018new_wenhao_org.png"),
      name: "费解",
      value: "100077",
    },
    {
      title: "晕",
      path: require("./assets/emoji/2018new_yun_org.png"),
      name: "晕",
      value: "100078",
    },
    {
      title: "衰",
      path: require("./assets/emoji/2018new_shuai_org.png"),
      name: "衰",
      value: "100079",
    },
    {
      title: "骷髅",
      path: require("./assets/emoji/2018new_kulou_org.png"),
      name: "骷髅",
      value: "100080",
    },
    {
      title: "嘘",
      path: require("./assets/emoji/2018new_xu_org.png"),
      name: "嘘",
      value: "100081",
    },
    {
      title: "闭嘴",
      path: require("./assets/emoji/2018new_bizui_org.png"),
      name: "闭嘴",
      value: "100082",
    },
    {
      title: "傻眼",
      path: require("./assets/emoji/2018new_shayan_org.png"),
      name: "傻眼",
      value: "100083",
    },
    {
      title: "裂开",
      path: require("./assets/emoji/202011_liekai_org.png"),
      name: "裂开",
      value: "100084",
    },
    {
      title: "感冒",
      path: require("./assets/emoji/2022_cold_org.png"),
      name: "感冒",
      value: "100085",
    },
    {
      title: "吃惊",
      path: require("./assets/emoji/2018new_chijing_org.png"),
      name: "吃惊",
      value: "100086",
    },
    {
      title: "吐",
      path: require("./assets/emoji/2018new_tu_org.png"),
      name: "吐",
      value: "100087",
    },
    {
      title: "生病",
      path: require("./assets/emoji/2018new_shengbing_org.png"),
      name: "生病",
      value: "100088",
    },
    {
      title: "拜拜",
      path: require("./assets/emoji/2018new_baibai_org.png"),
      name: "拜拜",
      value: "100089",
    },
    {
      title: "鄙视",
      path: require("./assets/emoji/2018new_bishi_org.png"),
      name: "鄙视",
      value: "100090",
    },
    {
      title: "白眼",
      path: require("./assets/emoji/2018new_landelini_org.png"),
      name: "白眼",
      value: "100091",
    },
    {
      title: "左哼哼",
      path: require("./assets/emoji/2018new_zuohengheng_org.png"),
      name: "左哼哼",
      value: "100092",
    },
    {
      title: "右哼哼",
      path: require("./assets/emoji/2018new_youhengheng_org.png"),
      name: "右哼哼",
      value: "100093",
    },
    {
      title: "抓狂",
      path: require("./assets/emoji/2018new_zhuakuang_org.png"),
      name: "抓狂",
      value: "100094",
    },
    {
      title: "怒骂",
      path: require("./assets/emoji/2018new_zhouma_org.png"),
      name: "怒骂",
      value: "100095",
    },
    {
      title: "打脸",
      path: require("./assets/emoji/2018new_dalian_org.png"),
      name: "打脸",
      value: "100096",
    },
    {
      title: "顶",
      path: require("./assets/emoji/2018new_ding_org.png"),
      name: "顶",
      value: "100097",
    },
    {
      title: "互粉",
      path: require("./assets/emoji/2018new_hufen02_org.png"),
      name: "互粉",
      value: "100098",
    },
    {
      title: "钱",
      path: require("./assets/emoji/2018new_qian_org.png"),
      name: "钱",
      value: "100099",
    },
    {
      title: "哈欠",
      path: require("./assets/emoji/2018new_dahaqian_org.png"),
      name: "哈欠",
      value: "100100",
    },
    {
      title: "困",
      path: require("./assets/emoji/2018new_kun_org.png"),
      name: "困",
      value: "100101",
    },
    {
      title: "睡",
      path: require("./assets/emoji/2018new_shuijiao_thumb.png"),
      name: "睡",
      value: "100102",
    },
    {
      title: "赢牛奶",
      path: require("./assets/emoji/2021_yingniunai_org.png"),
      name: "赢牛奶",
      value: "100103",
    },
    {
      title: "开学季",
      path: require("./assets/emoji/2021_kaixueji_org.png"),
      name: "开学季",
      value: "100104",
    },
    {
      title: "求饶",
      path: require("./assets/emoji/moren_qiurao02_org.png"),
      name: "求饶",
      value: "100105",
    },
    {
      title: "吃瓜",
      path: require("./assets/emoji/2018new_chigua_org.png"),
      name: "吃瓜",
      value: "100106",
    },
    {
      title: "打call",
      path: require("./assets/emoji/moren_dacall02_org.png"),
      name: "打call",
      value: "100107",
    },
    {
      title: "awsl",
      path: require("./assets/emoji/moren_awsl02_org.png"),
      name: "awsl",
      value: "100108",
    },
    {
      title: "彩虹屁",
      path: require("./assets/emoji/2022_praise_org.png"),
      name: "彩虹屁",
      value: "100109",
    },
    {
      title: "酸",
      path: require("./assets/emoji/hot_wosuanle_org.png"),
      name: "酸",
      value: "100110",
    },
    {
      title: "doge",
      path: require("./assets/emoji/2018new_doge02_org.png"),
      name: "doge",
      value: "100111",
    },
    {
      title: "二哈",
      path: require("./assets/emoji/2018new_erha_org.png"),
      name: "二哈",
      value: "100112",
    },
    {
      title: "喵喵",
      path: require("./assets/emoji/2018new_miaomiao_org.png"),
      name: "喵喵",
      value: "100113",
    },
    {
      title: "单身狗",
      path: require("./assets/emoji/2021_alongdog_org.png"),
      name: "单身狗",
      value: "100114",
    },
    {
      title: "揣手",
      path: require("./assets/emoji/2022_chuaishou_org.png"),
      name: "揣手",
      value: "100115",
    },
    {
      title: "举手",
      path: require("./assets/emoji/2022_raisehand_org.png"),
      name: "举手",
      value: "100116",
    },
    {
      title: "抱抱",
      path: require("./assets/emoji/2018new_baobao_org.png"),
      name: "抱抱",
      value: "100117",
    },
    {
      title: "摊手",
      path: require("./assets/emoji/2018new_tanshou_org.png"),
      name: "摊手",
      value: "100118",
    },
    {
      title: "跪了",
      path: require("./assets/emoji/2018new_gui_org.png"),
      name: "跪了",
      value: "100119",
    },
    {
      title: "中国赞",
      path: require("./assets/emoji/2018new_zhongguozan_org.png"),
      name: "中国赞",
      value: "100120",
    },
    {
      title: "鲜花",
      path: require("./assets/emoji/2018new_xianhua_org.png"),
      name: "鲜花",
      value: "100121",
    },
    {
      title: "航天员",
      path: require("./assets/emoji/2021_yuhangyuan_org.png"),
      name: "航天员",
      value: "100122",
    },
    {
      title: "烟花",
      path: require("./assets/emoji/2021_fireworks_org.png"),
      name: "烟花",
      value: "100123",
    },
    {
      title: "雪花",
      path: require("./assets/emoji/yunying2020_snowflakes_org.png"),
      name: "雪花",
      value: "100124",
    },
    {
      title: "丘比特",
      path: require("./assets/emoji/2021_qiubite_org.png"),
      name: "丘比特",
      value: "100125",
    },
    {
      title: "小丑",
      path: require("./assets/emoji/2021_xiaochou_org.png"),
      name: "小丑",
      value: "100126",
    },
    {
      title: "杰瑞",
      path: require("./assets/emoji/2021_jerry_org.png"),
      name: "杰瑞",
      value: "100127",
    },
    {
      title: "汤姆",
      path: require("./assets/emoji/2021_tom_org.png"),
      name: "汤姆",
      value: "100128",
    },
    {
      title: "奶瓶",
      path: require("./assets/emoji/2021_naiping_org.png"),
      name: "奶瓶",
      value: "100129",
    },
];