<template>
  <div style="width: 1000px; margin: 20px auto 0">
    <div class="content">
      <div class="leftcon">
        <div>
          <a>
            <img src="../assets/logo.png" style="height: 56px; width: auto" />
          </a>
          <span
            class="keepfly-box-alignCenter"
            style="margin-left: 16px; width: 26px"
            onclick="javascript:history.back(-1);"
          >
            <img src="../assets/left.png" style="width: 26px; height: 26px" />
          </span>
        </div>
        <ul>
          <li>
            <a :title="$t('message.tips188')" class="cur">{{ $t("message.tips188") }}</a>
          </li>
        </ul>
      </div>
      <div class="rightcon">
        <div style="padding: 20px 20px 10px; background: #fff">
          <div style="color: red; text-align: center; padding-bottom: 20px">
            {{ $t("message.tips186") }}<br />{{ $t("message.tips187") }}
          </div>
          <n-form
            :model="datamodel"
            label-placement="left"
            label-width="auto"
            require-mark-placement="right-hanging"
            :size="size"
            :style="{
              maxWidth: '640px',
            }"
          >
            <n-form-item label="ID">
              <n-input
                v-model:value="datamodel.kpid"
                status="warning"
                :placeholder="$t('message.tips183')"
              />
              <n-button @click="getemail" type="warning" ghost>
                {{ $t("message.determine") }}
              </n-button>
            </n-form-item>
            <n-form-item :label="$t('message.tips184')">
              <n-input
                v-model:value="datamodel.email"
                status="warning"
                :placeholder="$t('message.tips184')"
                disabled="true"
              />
            </n-form-item>
            <n-form-item :label="$t('message.verification_code')">
              <n-input
                v-model:value="datamodel.yzcode"
                :placeholder="$t('message.verification_code')"
                status="warning"
              />
              <a href="javascript:;" class="e-model" @click="sendCode">
                <!-- {{$t('message.gain')}} -->
                {{ codeNum == 60 ? $t("message.gain") : `(${codeNum})s` }}
              </a>
            </n-form-item>
            <n-form-item :label="$t('message.tips185')">
              <n-input
                v-model:value="datamodel.logoffReason"
                status="warning"
                type="textarea"
                :placeholder="$t('message.tips185')"
              />
            </n-form-item>
            <div style="display: flex; justify-content: flex-end">
              <n-button round type="warning" @click="handleValidateButtonClick">
                {{ $t("message.apply") }}
              </n-button>
            </div>
          </n-form>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { NForm, NFormItem, NButton, NInput, useMessage } from "naive-ui";
import i18n from "../i18n/index";
import API from "../uilts/request";
export default defineComponent({
  components: { NForm, NFormItem, NButton, NInput },
  setup() {
    const message = useMessage();
    const datamodel = ref({});
    //倒计时初始变量
    const codeNum = ref(60);
    const bindex = ref(1);
    // 定时器id
    let clearId;
    // 发送验证码
    const sendCode = async () => {
      if (
        datamodel.value.kpid == null ||
        datamodel.value.kpid == "" ||
        datamodel.value.kpid == undefined
      ) {
        message.error(i18n.global.t("message.tips178"));
        return false;
      }
      if (codeNum.value != 60) return;
      clearId = setInterval(() => {
        // 每次 时间1s -1
        codeNum.value--;
        // 时间=0时 清除定时器
        if (codeNum.value == 0) {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
        }
      }, 1000);
      API({
        url: "/kuke/api/index/getkpidCode",
        method: "GET",
        params: { kpid: datamodel.value.kpid },
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips22"));
        } else {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
          message.success(i18n.global.t("message.tips179"));
        }
      });
    };
    const getemail = () => {
      if (
        datamodel.value.kpid == null ||
        datamodel.value.kpid == undefined ||
        datamodel.value.kpid == ""
      ) {
        message.success(i18n.global.t("message.tips178"));
        return false;
      }
      API({
        url: "/kuke/api/index/getkpidemail",
        method: "POST",
        data: { kpid: datamodel.value.kpid },
      }).then((result) => {
        if (result.data.code == 200) {
          datamodel.value.email = result.data.data;
        } else {
          message.success(i18n.global.t("message.tips179"));
        }
      });
    };
    const handleValidateButtonClick = () => {
      if (
        datamodel.value.kpid == null ||
        datamodel.value.kpid == undefined ||
        datamodel.value.kpid == ""
      ) {
        message.success(i18n.global.t("message.tips178"));
        return false;
      }
      if (
        datamodel.value.logoffReason == null ||
        datamodel.value.logoffReason == undefined ||
        datamodel.value.logoffReason == ""
      ) {
        message.success(i18n.global.t("message.tips180"));
        return false;
      }
      API({
        url: "/kuke/api/index/sendlogoff",
        method: "POST",
        data: datamodel.value,
      }).then((result) => {
        if (result.data.code == 200) {
          datamodel.value.kpid = "";
          datamodel.value.logoffReason = "";
          datamodel.value.yzcode = "";
          datamodel.value.email = "";
          message.success(i18n.global.t("message.tips181"));
        } else if (result.data.code == 601) {
          message.success(i18n.global.t("message.tips182"));
        } else if (result.data.code == 602) {
          message.success(i18n.global.t("message.tips20"));
        } else if (result.data.code == 603) {
          message.success(i18n.global.t("message.tips21"));
        } else if (result.data.code == 604) {
          message.success(i18n.global.t("message.tips179"));
        }
      });
    };
    return {
      handleValidateButtonClick,
      getemail,
      bindex,
      codeNum,
      sendCode,
      datamodel,
    };
  },
});
</script>
<style>
.e-model {
  width: 122px;
  height: 34px;
  line-height: 34px;
  background: orange;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  right: 0px;
}

.e-model:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
</style>
