<template>
  <div class="Frame_top">
    <div>
      <div class="Nav_wrap grayTheme">
        <div class="keepfly-panel-main keepfly-panel-bottom Nav_panel_YI3">
          <div
            class="keepfly-box-flex keepfly-box-alignCenter Nav_main_user"
            style="width: 1100px"
          >
            <div class="keepfly-box-flex keepfly-box-alignCenter Nav_left">
              <a class="Nav_logoWrap">
                <img
                  :src="kukesystemInfo.logo2"
                  style="width: 38px; margin-right: 10px"
                />
              </a>
              <div class="keepfly-box-flex keepfly-box-alignCenter Nav_search">
                <div
                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter SearchIcon_wrap Nav_icon"
                  style="visibility: hidden"
                >
                  <div class="SearchIcon_icon">
                    <n-icon size="18">
                      <search-outline></search-outline>
                    </n-icon>
                  </div>
                </div>
                <div
                  class="SearchBar_wrap Nav_bar Nav_barForcus"
                  style="visibility: visible"
                >
                  <div class="keepfly-pop-wrap SearchBar_main">
                    <span class="keepfly-pop-ctrl">
                      <form>
                        <div
                          class="keepfly-input-wrap keepfly-input-round keepfly-input-hasIcon SearchBar_main SearchBar_iptbox"
                        >
                          <span class="keepfly-input-icon">
                            <n-icon size="18">
                              <search-outline></search-outline>
                            </n-icon>
                          </span>
                          <input
                            type="text"
                            v-model.trim="searchkeepfly"
                            :placeholder="$t('message.search') + ' keepfly'"
                            class="keepfly-input-main"
                            @input="getKeyWord"
                            @keyup.enter="searchTxt"
                          />
                        </div>
                      </form>
                    </span>
                    <div
                      class="keepfly-pop-main keepfly-pop-fluid keepfly-pop-down keepfly-pop-start"
                      v-show="searchshow"
                    >
                      <div
                        class="keepfly-pop-wrap-main SearchBar_result SearchBar_rNoInputBox"
                      >
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter SearchBar_SchTopBox"
                        >
                          <div class="keepfly-box-item-flex SearchBar_schTopTit">
                            搜索
                          </div>
                        </div>
                        <div class="SearchBar_keyWordsBox">
                          <div
                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-pop-item-main SearchBar_rsItem SearchBar_rsHover"
                          >
                            <div
                              class="keepfly-box-item-flex"
                              style="min-width: 0px"
                              v-for="item in searchTopicArr"
                              :key="item"
                            >
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicAr)"
                                v-if="item.topicAr"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicAr }}
                                  </div>
                                </div>
                              </a>
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicEn)"
                                v-if="item.topicEn"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicEn }}
                                  </div>
                                </div>
                              </a>
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicJa)"
                                v-if="item.topicJa"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicJa }}
                                  </div>
                                </div>
                              </a>
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicKo)"
                                v-if="item.topicKo"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicKo }}
                                  </div>
                                </div>
                              </a>
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicRu)"
                                v-if="item.topicRu"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicRu }}
                                  </div>
                                </div>
                              </a>
                              <a
                                class="ALink_none"
                                @click="gettopicinfo(item.topicZh)"
                                v-if="item.topicZh"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter SearchBar_searchWords"
                                >
                                  <div class="SearchBar_searchIcon">
                                    <n-icon size="18">
                                      <search-outline
                                        class="keepfly-font keepfly-font--search"
                                        style="color: darkgrey"
                                      ></search-outline>
                                    </n-icon>
                                  </div>
                                  <div class="SearchBar_searchText" style="width: 196px">
                                    {{ item.topicZh }}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Nav_mid"
            >
              <div class="Ctrls_main">
                <div class="keepfly-box-flex keepfly-tab-nav">
                  <a @click="CtrlsTab(1)" class="Ctrls_alink">
                    <div
                      :class="[
                        navTab == 1
                          ? 'keepfly-tab-item-main Ctrls_tab keepfly-tab-active'
                          : 'keepfly-tab-item-main Ctrls_tab',
                      ]"
                    >
                      <div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Ctrls_item"
                          :title="$t('message.home')"
                        >
                          <div class="keepfly-badge-box">
                            <svg
                              class="Ctrls_icon"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 20 20"
                            >
                              <g fill="none">
                                <path
                                  d="M17 8.134V5.566C17 4.713 16.296 4 15.4 4H4.6C3.704 4 3 4.713 3 5.566v6.71c0 .853.704 1.566 1.6 1.566h1.6V17h.003l.002-.001l2.167-1.6l-.314 1.254c-.021.086-.037.17-.046.255l-1.213.895a1.009 1.009 0 0 1-1.4-.199a.978.978 0 0 1-.199-.59v-2.172h-.6c-1.436 0-2.6-1.149-2.6-2.566v-6.71C2 4.149 3.164 3 4.6 3h10.8C16.836 3 18 4.149 18 5.566V8.69a2.853 2.853 0 0 0-1-.556zm-2.193 1.412l-4.83 4.83a2.197 2.197 0 0 0-.577 1.02l-.375 1.498a.89.89 0 0 0 1.079 1.079l1.498-.375c.386-.096.739-.296 1.02-.578l4.83-4.83a1.87 1.87 0 1 0-2.645-2.644z"
                                  fill="currentColor"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div :class="[navTab == 1 ? 'keepfly-tab-item-border' : '']"></div>
                    </div>
                  </a>
                  <a @click="CtrlsTab(2)" class="Ctrls_alink">
                    <div
                      :class="[
                        navTab == 2
                          ? 'keepfly-tab-item-main Ctrls_tab keepfly-tab-active'
                          : 'keepfly-tab-item-main Ctrls_tab',
                      ]"
                    >
                      <div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Ctrls_item"
                          :title="$t('message.popular')"
                        >
                          <div class="keepfly-badge-box">
                            <flame-outline class="Ctrls_icon"></flame-outline>
                          </div>
                        </div>
                      </div>
                      <div :class="[navTab == 2 ? 'keepfly-tab-item-border' : '']"></div>
                    </div>
                  </a>
                  <a @click="CtrlsTab(7)" class="Ctrls_alink">
                    <div
                      :class="[
                        navTab == 7
                          ? 'keepfly-tab-item-main Ctrls_tab keepfly-tab-active'
                          : 'keepfly-tab-item-main Ctrls_tab',
                      ]"
                    >
                      <div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Ctrls_item"
                          :title="$t('message.deceased_square')"
                        >
                          <div class="keepfly-badge-box">
                            <home-outline class="Ctrls_icon"></home-outline>
                          </div>
                        </div>
                      </div>
                      <div :class="[navTab == 7 ? 'keepfly-tab-item-border' : '']"></div>
                    </div>
                  </a>
                  <a @click="CtrlsTab(3)" class="Ctrls_alink">
                    <div
                      :class="[
                        navTab == 3
                          ? 'keepfly-tab-item-main Ctrls_tab keepfly-tab-active'
                          : 'keepfly-tab-item-main Ctrls_tab',
                      ]"
                    >
                      <div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Ctrls_item"
                          :title="$t('message.find_it')"
                        >
                          <div class="keepfly-badge-box">
                            <earth-outline class="Ctrls_icon"></earth-outline>
                          </div>
                        </div>
                      </div>
                      <div :class="[navTab == 3 ? 'keepfly-tab-item-border' : '']"></div>
                    </div>
                  </a>
                  <a @click="CtrlsTab(4)" class="Ctrls_alink">
                    <div
                      :class="[
                        navTab == 4
                          ? 'keepfly-tab-item-main Ctrls_tab keepfly-tab-active'
                          : 'keepfly-tab-item-main Ctrls_tab',
                      ]"
                    >
                      <div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Ctrls_item"
                          :title="infodata.nickname"
                        >
                          <div class="keepfly-badge-box" style="position: relative">
                            <chatbox-ellipses-outline
                              class="Ctrls_icon"
                            ></chatbox-ellipses-outline>
                            <span
                              style="
                                display: inline-block;
                                font-size: 12px;
                                position: absolute;
                                top: 0;
                                background: red;
                                border-radius: 9px;
                                width: 19px;
                                color: #fff;
                                padding: 0 3px;
                              "
                              v-if="message_num > 0 && message_num < 10"
                              >{{ message_num > 99 ? "99+" : message_num }}</span
                            >
                            <span
                              style="
                                display: inline-block;
                                font-size: 12px;
                                position: absolute;
                                top: 0;
                                background: red;
                                border-radius: 9px;
                                width: auto;
                                color: #fff;
                                padding: 0 3px;
                              "
                              v-if="message_num > 10"
                              >{{ message_num > 99 ? "99+" : message_num }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div :class="[navTab == 4 ? 'keepfly-tab-item-border' : '']"></div>
                    </div>
                  </a>
                </div>
                <div></div>
              </div>
            </div>
            <div class="keepfly-box-flex keepfly-box-justifyEnd Nav_right ariatheme">
              <div class="keepfly-box-flex keepfly-box-alignCenter Nav_configs ariatheme">
                <div class="ariatheme">
                  <div
                    class="keepfly-pop-wrap ariatheme"
                    @click="CtrlsTab(6)"
                    style="margin-right: 10px"
                  >
                    <span class="keepfly-pop-ctrl ariatheme">
                      <div
                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Configs_item ariatheme"
                        style="position: relative"
                      >
                        <img
                          :src="infodata.avatar"
                          class="Configs_icon"
                          style="
                            border-radius: 15px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 30px;
                            height: 30px;
                          "
                        />
                        <person-sharp class="Configs_icon"></person-sharp>
                      </div>
                    </span>
                  </div>
                  <div class="keepfly-pop-wrap ariatheme">
                    <span class="keepfly-pop-ctrl ariatheme">
                      <n-dropdown :options="options" @select="handleSelect">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Configs_item ariatheme"
                        >
                          <settings-outline class="Configs_icon"></settings-outline>
                        </div>
                      </n-dropdown>
                    </span>
                  </div>
                  <div class="keepfly-pop-wrap ariatheme">
                    <span class="keepfly-pop-ctrl ariatheme">
                      <n-select
                        @update:value="handleSearch"
                        v-model:value="selectedValue"
                        status="warning"
                        label-field="name"
                        value-field="language"
                        :theme-overrides="selectThemeOverrides"
                        :options="arr"
                        style="width: 136px; margin-left: 16px"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Nav_holder"></div>
    </div>
    <n-modal
      v-model:show="pwdviewshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.change_password')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        :model="cpwd_model"
        :rules="rules"
        label-placement="left"
        label-width="auto"
        require-mark-placement="right-hanging"
        :size="size"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.verification_code')">
          <n-input
            v-model:value="cpwd_model.yzcode"
            :placeholder="$t('message.verification_code')"
            status="warning"
          />
          <a href="javascript:;" class="e-model" @click="sendCode">
            {{ codeNum == 60 ? $t("message.gain") : `(${codeNum})s` }}
          </a>
        </n-form-item>
        <n-form-item :label="$t('message.tips97')">
          <n-input
            v-model:value="cpwd_model.password"
            show-password-on="click"
            type="password"
            :placeholder="$t('message.tips97')"
            status="warning"
          />
        </n-form-item>
        <n-form-item :label="$t('message.confirm_password')">
          <n-input
            v-model:value="cpwd_model.confirmpassword"
            show-password-on="click"
            type="password"
            :placeholder="$t('message.confirm_password')"
            status="warning"
          />
        </n-form-item>
        <div style="display: flex; justify-content: flex-end">
          <n-button
            round
            type="warning"
            class="e-button"
            @click="handleValidateButtonClick"
          >
            {{ $t("message.confirm") }}
          </n-button>
        </div>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import i18n from "../i18n/index";
import API from "../uilts/request";
import { useRouter } from "vue-router";
import {
  ChatboxEllipsesOutline,
  FlameOutline,
  EarthOutline,
  SettingsOutline,
  SearchOutline,
  HomeOutline,
} from "@vicons/ionicons5";
import bus from "../uilts/bus.js";
import {
  NIcon,
  NDropdown,
  NSelect,
  useMessage,
  NModal,
  NForm,
  NFormItem,
  NInput,
  NButton,
} from "naive-ui";
export default defineComponent({
  name: "HeaderView",
  components: {
    ChatboxEllipsesOutline,
    FlameOutline,
    EarthOutline,
    SettingsOutline,
    NIcon,
    NDropdown,
    NSelect,
    SearchOutline,
    HomeOutline,
    NModal,
    NForm,
    NFormItem,
    NInput,
    NButton,
  },
  setup(props, context) {
    const { locale } = useI18n();
    const message = useMessage();
    const router = useRouter();
    const arr = ref([]);
    const infodata = ref({});
    const kukesystemInfo = ref({});
    const interval = ref(null);
    const message_num = ref(0);
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getKukeLanguage();
      if (window.localStorage.getItem("sendSiLiao")) {
        navTab.value = 4;
      }
      bus.on("sendToBrother", (data) => {
        navTab.value = data;
      });
      bus.on("avatar", () => {
        infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      });
      interval.value = setInterval(() => {
        API({
          url: "/kuke/api/user/getmessagetotal",
          method: "POST",
        }).then((result) => {
          if (result.data) {
            message_num.value =
              result.data.data.mnum + result.data.data.mlnum + result.data.data.sxnum;
          }
        });
      }, 3000);
    });
    onUnmounted(() => {
      clearInterval(interval.value);
    });
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", locale.value);
      location.reload();
    };
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const options = [
      {
        label: i18n.global.t("message.userinfo"),
        key: "profile",
      },
      {
        label: i18n.global.t("message.change_password"),
        key: "changepwd",
      },
      {
        label: i18n.global.t("message.log_out"),
        key: "logout",
      },
    ];
    const navTab = ref(7);
    const CtrlsTab = (e) => {
      if (e == 1) {
        context.emit("eventParent", 1);
        navTab.value = 1;
      } else if (e == 2) {
        context.emit("eventParent", 2);
        navTab.value = 2;
      } else if (e == 3) {
        context.emit("eventParent", 3);
        navTab.value = 3;
      } else if (e == 4) {
        context.emit("eventParent", 4);
        navTab.value = 4;
      } else if (e == 7) {
        context.emit("eventParent", 7);
        navTab.value = 7;
      } else if (e == 6) {
        context.emit("eventParent", 6);
        navTab.value = 6;
      }
    };
    const searchkeepfly = ref("");
    const searchTxt = () => {
      if (searchkeepfly.value) {
        context.emit("eventParent", 8);
        context.emit("searchTxt", searchkeepfly.value);
        navTab.value = 8;
        searchshow.value = false;
      }
    };
    const gettopicinfo = (e) => {
      searchkeepfly.value = e;
      searchshow.value = false;
      searchTxt();
    };
    const searchTopicArr = ref([]);
    const getKeyWord = () => {
      if (searchkeepfly.value) {
        let param = {
          txt: searchkeepfly.value,
          language: localStorage.getItem("language") || "en",
        };
        API({
          url: "/kuke/api/index/getTopicKeyWord",
          method: "POST",
          data: param,
        }).then((result) => {
          searchTopicArr.value = JSON.parse(JSON.stringify(result.data.data));
          if (searchTopicArr.value.length > 0) {
            searchshow.value = true;
          }
        });
      } else {
        searchshow.value = false;
      }
    };
    const searchshow = ref(false);
    const handleSelect = (key) => {
      if (String(key) == "logout") {
        // clearInterval(interval.value);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("infodata");
        window.localStorage.removeItem("loginTime");
        message.info(String(key));
        router.push({
          name: "login",
          params: {
            id: 1,
          },
        });
      } else if (String(key) == "profile") {
        context.emit("eventParent", 5);
        navTab.value = 5;
      } else if (String(key) == "changepwd") {
        pwdviewshow.value = true;
      }
    };
    const pwdviewshow = ref(false);
    const cpwd_model = ref({});
    //倒计时初始变量
    const codeNum = ref(60);
    // 定时器id
    let clearId;
    // 发送验证码
    const sendCode = async () => {
      if (codeNum.value != 60) return;
      clearId = setInterval(() => {
        // 每次 时间1s -1
        codeNum.value--;
        // 时间=0时 清除定时器
        if (codeNum.value == 0) {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
        }
      }, 1000);
      API({
        url: "/kuke/api/user/getCode",
        method: "GET",
        params: { account: cpwd_model.value.email },
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips22"));
        }
      });
    };
    const handleValidateButtonClick = () => {
      if (
        cpwd_model.value.yzcode == null ||
        cpwd_model.value.yzcode == "" ||
        cpwd_model.value.yzcode == undefined
      ) {
        message.error(i18n.global.t("message.tips6"));
        return false;
      }
      if (
        cpwd_model.value.password == null ||
        cpwd_model.value.password == "" ||
        cpwd_model.value.password == undefined
      ) {
        message.error(i18n.global.t("message.tips4"));
        return false;
      }
      if (cpwd_model.value.password != cpwd_model.value.confirmpassword) {
        message.error(i18n.global.t("message.tips5"));
        return false;
      }
      API({
        url: "/kuke/api/user/upuserpwd",
        method: "POST",
        data: cpwd_model.value,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips51"));
          window.localStorage.setItem("token", null);
          window.localStorage.setItem("infodata", null);
          window.localStorage.setItem("loginTime", null);
          router.push({
            name: "login",
            params: {
              id: 1,
            },
          });
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips25"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips20"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips85"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips21"));
        }
      });
    };

    return {
      gettopicinfo,
      searchTopicArr,
      searchshow,
      getKeyWord,
      searchkeepfly,
      searchTxt,
      message_num,
      handleValidateButtonClick,
      codeNum,
      sendCode,
      cpwd_model,
      pwdviewshow,
      kukesystemInfo,
      handleSelect,
      infodata,
      getKukeLanguage,
      arr,
      changeLang,
      options,
      CtrlsTab,
      navTab,
      selectedValue: ref(localStorage.getItem("language")),
      handleSearch: (value) => {
        changeLang(value);
      },
      bodyStyle: {
        width: "600px",
      },
    };
  },
});
</script>
<style>
.e-model {
  width: 122px;
  height: 34px;
  line-height: 34px;
  background: orange;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  right: 0px;
}
</style>
