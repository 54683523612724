<template>
  <div class="contact">
    <div class="top">
      <div class="left">
        <img class="avatar" :src="infodata.avatar" alt="" />
      </div>
      <div class="right">
        {{ infodata.nickname }}
      </div>
    </div>
    <div v-if="friendList.length" class="bottom">
      <div
        v-for="(friend, i) in friendList"
        :key="i"
        class="friend"
        :class="{ activeColor: isActive(i) }"
        style="padding: 10px; position: relative"
        @click="setContact(i)"
      >
        <div class="left" style="position: relative">
          <img class="avatar" :src="friend.avatar" alt="" />
          <span
            style="
              display: inline-block;
              font-size: 12px;
              position: absolute;
              top: 0;
              right: -8px;
              background: red;
              border-radius: 9px;
              width: 19px;
              height: 19px;
              line-height: 19px;
              color: #fff;
              padding: 0 3px;
            "
            v-if="friend.mnum > 0 && friend.mnum < 10"
          >
            {{ friend.mnum > 99 ? "99+" : friend.mnum }}
          </span>
          <span
            style="
              display: inline-block;
              font-size: 12px;
              position: absolute;
              top: 0;
              right: -8px;
              background: red;
              border-radius: 9px;
              width: auto;
              height: 19px;
              line-height: 19px;
              color: #fff;
              padding: 0 3px;
            "
            v-if="friend.mnum > 10"
            >{{ friend.mnum > 99 ? "99+" : friend.mnum }}</span
          >
        </div>
        <div
          class="right"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 8px;
          "
          :title="friend.nickname"
        >
          <n-tooltip placement="top-start" trigger="hover">
            <template #trigger>
              {{ friend.nickname }}
            </template>
            {{ friend.nickname }}
          </n-tooltip>
        </div>
        <div
          class="right"
          style="
            font-size: 12px;
            color: darkgrey;
            padding-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <n-tooltip placement="top-start" trigger="hover">
            <template #trigger>
              {{ dateup(friend.updateTime) }}
            </template>
            {{ dateup(friend.updateTime) }}
          </n-tooltip>
        </div>
        <span style="width: 13px; position: absolute; top: -16px; right: 5px">
          <n-popconfirm
            :positive-text="$t('message.determine')"
            :negative-text="$t('message.call_off')"
            @positive-click="handlePositiveClick(i)"
          >
            <template #trigger>
              <close></close>
            </template>
            {{ $t("message.tips125") }}
          </n-popconfirm>
        </span>
      </div>
    </div>
    <div v-else class="info">
      <div class="msg">{{ $t("message.tips119") }}</div>
    </div>
  </div>
</template>

<script>
import API from "../uilts/request";
import { NTooltip, NPopconfirm, useMessage } from "naive-ui";
import { Close } from "@vicons/ionicons5";
import i18n from "../i18n/index";
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
export default defineComponent({
  name: "ContactView",
  components: {
    NTooltip,
    Close,
    NPopconfirm,
  },
  setup(props, { emit }) {
    const message = useMessage();
    const infodata = ref({});
    const active = ref(-1);
    const friendList = ref([]);
    const interval = ref(null);
    const setContact = (index) => {
      active.value = index;
      let param = {
        friendList: friendList.value[index],
        index: 1,
      };
      emit("set-contact", param);
    };
    const isActive = (index) => {
      return active.value === index;
    };
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      interval.value = setInterval(() => {
        API({
          url: "/kuke/api/user/getPrivateLetter",
          method: "POST",
        }).then((result) => {
          friendList.value = result.data.data;
        });
      }, 2000);
    });
    onUnmounted(() => {
      clearInterval(interval.value);
    });
    function dateup(time) {
      let date =
        typeof time === "number"
          ? new Date(time)
          : new Date((time || "").replace(/-/g, "/"));
      let diff = (new Date().getTime() - date.getTime()) / 1000;
      let dayDiff = Math.floor(diff / 86400);

      let isValidDate =
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date.getTime());

      if (!isValidDate) {
        window.console.error(i18n.global.t("message.tips126"));
      }
      const formatDate = function (date) {
        let today = new Date(date);
        let year = today.getFullYear();
        let month = ("0" + (today.getMonth() + 1)).slice(-2);
        let day = ("0" + today.getDate()).slice(-2);
        let hour = today.getHours();
        let minute = today.getMinutes();
        let second = today.getSeconds();
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      };

      if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
        return formatDate(date);
      }
      return (
        (dayDiff === 0 &&
          ((diff < 60 && i18n.global.t("message.just")) ||
            (diff < 120 && i18n.global.t("message.tips127")) ||
            (diff < 3600 &&
              Math.floor(diff / 60) + i18n.global.t("message.minute_ago")) ||
            (diff < 7200 && i18n.global.t("message.tips128")) ||
            (diff < 86400 &&
              Math.floor(diff / 3600) + i18n.global.t("message.hour_ago")))) ||
        (dayDiff === 1 && i18n.global.t("message.yesterday")) ||
        (dayDiff < 7 && dayDiff + i18n.global.t("message.days_ago")) ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + i18n.global.t("message.weeks_ago"))
      );
    }
    const handlePositiveClick = (index) => {
      API({
        url: "/kuke/api/user/delfriendInfo",
        method: "GET",
        params: {
          id: friendList.value[index].fromId,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          let param = {
            friendList: friendList.value[index],
            index: 1,
          };
          emit("set-contact", param);
          window.localStorage.removeItem(
            "msgList-" +
              friendList.value[index].fromId +
              "-" +
              friendList.value[index].toId
          );
          friendList.value.splice(index, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    return {
      handlePositiveClick,
      dateup,
      infodata,
      active,
      friendList,
      setContact,
      isActive,
    };
  },
});
</script>

<style scoped>
.contact {
  width: 180px;
  height: 100%;
  float: left;
  border-right: #eee 1px solid;
}

.top {
  padding: 16px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: #e0dfdf 1px solid;
}

.activeColor {
  background-color: #c9cbcb;
}

.top .left {
  flex: 1;
  text-align: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 23px;
}

.top .right {
  padding-left: 16px;
  flex: 3;
}

.friend {
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;
  border-bottom: #faf7f7 1px solid;
}

.friend .left {
  flex: 1;
  text-align: center;
}

.friend .right {
  flex: 3;
  color: #575454;
  font-size: 14px;
}

.friend .avatar {
  width: 36px;
  height: 36px;
}

.info {
  margin-top: 230px;
}

.info .msg {
  text-align: center;
}
</style>
