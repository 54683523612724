<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side grayTheme">
      <div class="Nav_main_user" style="min-height: 255px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner"
        >
          <h2 class="Nav_title">{{ $t("message.home") }}</h2>
          <div>
            <a class="ALink_none" @click="setNavTab1(1)">
              <div
                :class="[
                  navTab1Show == 1
                    ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                    : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                ]"
                :title="$t('message.release')"
              >
                <svg
                  class="keepfly-font keepfly-font--navAll NavItem_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                >
                  <path d="M2 26h28v2H2z" fill="currentColor"></path>
                  <path
                    d="M25.4 9c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2-.8-2.8 0l-15 15V24h6.4l15-15zm-5-5L24 7.6l-3 3L17.4 7l3-3zM6 22v-3.6l10-10l3.6 3.6l-10 10H6z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span class="NavItem_text">{{ $t("message.release") }}</span>
              </div>
            </a>
            <a class="ALink_none" @click="setNavTab1(2)">
              <div
                :class="[
                  navTab1Show == 2
                    ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                    : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                ]"
                :title="$t('message.up_to_date') + 'keepfly'"
              >
                <svg
                  class="keepfly-font keepfly-font--navNew NavItem_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 7l.94-2.06L23 4l-2.06-.94L20 1l-.94 2.06L17 4l2.06.94zM8.5 7l.94-2.06L11.5 4l-2.06-.94L8.5 1l-.94 2.06L5.5 4l2.06.94zM20 12.5l-.94 2.06l-2.06.94l2.06.94l.94 2.06l.94-2.06L23 15.5l-2.06-.94zm-2.29-3.38l-2.83-2.83c-.2-.19-.45-.29-.71-.29c-.26 0-.51.1-.71.29L2.29 17.46a.996.996 0 0 0 0 1.41l2.83 2.83c.2.2.45.3.71.3s.51-.1.71-.29l11.17-11.17c.39-.39.39-1.03 0-1.42zm-3.54-.7l1.41 1.41L14.41 11L13 9.59l1.17-1.17zM5.83 19.59l-1.41-1.41L11.59 11L13 12.41l-7.17 7.18z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span class="NavItem_text">{{ $t("message.up_to_date") }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" v-if="navTab1Show == 1">
          <div class="Home_home">
            <div id="homeWrap" class="Home_wrap">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="index_box">
                  <div class="index_box1">
                    <div
                      :class="[
                        keepflycontextfocus
                          ? 'wbpro-form Form_wbproform focus'
                          : 'wbpro-form Form_wbproform',
                      ]"
                    >
                      <textarea
                        :placeholder="$t('message.tips7')"
                        v-model="keepflycontext"
                        ref="refInput"
                        @input="getinput"
                        @blur="getblur"
                        @focus="getfocus"
                        class="Form_input"
                      ></textarea>
                      <div class="Form_count" v-if="keepflycontext.length > 0">
                        <span>{{ keepflycontextLength }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <n-dynamic-tags v-model:value="tags" :max="5">
                      <template #input="{ submit, deactivate }">
                        <n-auto-complete
                          ref="autoCompleteInstRef"
                          v-model:value="inputValue"
                          size="small"
                          :options="options"
                          :placeholder="$t('message.topic')"
                          status="warning"
                          :clear-after-select="true"
                          @select="submit($event)"
                          @blur="deactivate"
                          @input="handleInput"
                        />
                      </template>
                      <template #trigger="{ activate, disabled }">
                        <n-button
                          size="small"
                          type="warning"
                          dashed
                          :disabled="disabled"
                          @click="activate()"
                        >
                          <template #icon>
                            <n-icon>
                              <Add />
                            </n-icon>
                          </template>
                          {{ $t("message.topic") }}
                        </n-button>
                      </template>
                    </n-dynamic-tags>
                  </div>
                  <div class="index_box2 grayTheme">
                    <div style="width: 50%" v-show="ImageList.length > 0">
                      <div
                        class="u-col-3"
                        style="margin-left: -0.25rem; margin-top: -0.25rem"
                      >
                        <div
                          class="keepfly-box-item-inlineBlock"
                          v-for="(item, index) in ImageList"
                          :key="item"
                          style="padding-left: 0.25rem; padding-top: 0.25rem"
                        >
                          <div class="Image_picbed">
                            <div
                              class="keepfly-picture-main keepfly-picture-square keepfly-picture-hover Image_pic"
                            >
                              <img :src="item" class="keepfly-picture-img" />
                              <div class="keepfly-picture-cover"></div>
                              <div class="keepfly-picture-hoverMask"></div>
                            </div>
                            <close
                              @click="imageClose(index)"
                              :title="$t('message.delete')"
                              class="keepfly-font keepfly-font--close keepfly-fonticon-multi keepfly-fonticon-dark keepfly-fonticon-abovePic Image_close"
                            >
                            </close>
                          </div>
                        </div>
                        <div
                          class="keepfly-box-item-inlineBlock"
                          style="padding-left: 0.25rem; padding-top: 0.25rem"
                        >
                          <div class="Image_picbed" @click="imageView">
                            <div
                              class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter keepfly-box-justifyCenter FileUpload_box"
                            >
                              <add
                                class="keepfly-font keepfly-font--add FileUpload_icon_view"
                              ></add>
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .bmp, .gif, .png, .heif, .heic"
                                multiple="multiple"
                                @change="uploadURL"
                                ref="FileUpload_file"
                                style="display: none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="keepfly-box-item-inlineBlock">
                      <video
                        controls="controls"
                        v-if="videoInfo"
                        :src="videoInfo"
                        class="video"
                        style="
                          width: 400px;
                          vertical-align: middle;
                          height: 225px;
                          background: #000;
                          border-radius: 10px;
                        "
                      ></video>
                    </div>
                  </div>
                  <div class="index_box2"></div>
                  <div class="grayTheme">
                    <div class="keepfly-box-flex keepfly-box-alignCenter">
                      <div class="keepfly-box-item-flex" style="align-self: center">
                        <div class="Tool_mar1">
                          <div class="keepfly-box-item-inlineBlock Tool_iconitem">
                            <div
                              class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
                              :title="$t('message.emote')"
                              @click="emoticonView"
                            >
                              <happy-outline
                                class="keepfly-font keepfly-font--emoticon"
                              ></happy-outline>
                              <!-- <span class="text">{{ $t("message.emote") }}</span> -->
                            </div>
                          </div>
                          <div class="keepfly-box-item-inlineBlock Tool_iconitem">
                            <div class="keepfly-pop-wrap">
                              <span class="keepfly-pop-ctrl">
                                <div
                                  class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                                  :title="$t('message.picture')"
                                  @click="imageView"
                                >
                                  <images-outline
                                    class="keepfly-font keepfly-font--image"
                                  ></images-outline>
                                  <!-- <span class="text abc">{{
                                    $t("message.picture")
                                  }}</span> -->
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="keepfly-box-item-inlineBlock Tool_iconitem">
                            <div class="keepfly-pop-wrap">
                              <span class="keepfly-pop-ctrl">
                                <n-popconfirm
                                  :positive-text="$t('message.confirm')"
                                  :negative-text="$t('message.call_off')"
                                  @positive-click="videoView"
                                >
                                  <template #trigger>
                                    <div
                                      :title="$t('message.video')"
                                      class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                                    >
                                      <svg
                                        class="keepfly-font keepfly-font--video"
                                        style="width: 21px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 24 24"
                                      >
                                        <g fill="none">
                                          <path
                                            d="M13.75 4.5A3.25 3.25 0 0 1 17 7.75v.173l3.864-2.318A.75.75 0 0 1 22 6.248V17.75a.75.75 0 0 1-1.136.643L17 16.075v.175a3.25 3.25 0 0 1-3.25 3.25h-8.5A3.25 3.25 0 0 1 2 16.25v-8.5A3.25 3.25 0 0 1 5.25 4.5h8.5zm0 1.5h-8.5A1.75 1.75 0 0 0 3.5 7.75v8.5c0 .966.784 1.75 1.75 1.75h8.5a1.75 1.75 0 0 0 1.75-1.75v-8.5A1.75 1.75 0 0 0 13.75 6zm6.75 1.573L17 9.674v4.651l3.5 2.1V7.573z"
                                            fill="currentColor"
                                          ></path>
                                        </g>
                                      </svg>
                                      <input
                                        type="file"
                                        accept="video/*"
                                        multiple="multiple"
                                        @change="videoURL"
                                        ref="videoUpload_file"
                                        style="display: none"
                                      />
                                    </div>
                                  </template>
                                  {{ $t("message.tips190") }}
                                </n-popconfirm>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Tool_check">
                        <div class="Tool_checkc"></div>
                        <button
                          @click="sendKeepfly"
                          class="keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Tool_btn"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              <!-- {{ $t("message.send") }} -->
                              <svg
                                style="width: 22px"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                enable-background="new 0 0 512 512"
                                xml:space="preserve"
                              >
                                <g>
                                  <path
                                    fill="#fff"
                                    d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="wbpro-pos">
                    <div>
                      <div class="keepfly-pop-wrap Emoticon_box1">
                        <span class="keepfly-pop-ctrl"></span>
                        <div
                          class="keepfly-pop-main keepfly-pop-down keepfly-pop-start"
                          v-if="Emoticon_show"
                        >
                          <div class="Emoticon_tab">
                            <div class="keepfly-divider-main keepfly-divider-x"></div>
                            <div class="Emoticon_facebox1">
                              <div class="u-col-12 emoticon">
                                <div
                                  class="keepfly-box-item-inlineBlock"
                                  v-for="item in emoticon"
                                  :key="item"
                                >
                                  <div
                                    class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
                                    :title="item.title"
                                    @click="getemoticontxt(item.value)"
                                  >
                                    <img
                                      :src="item.path"
                                      :alt="item.name"
                                      class="Emoticon_img"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grayTheme">
                <div class="SecBar_visable">
                  <div
                    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap Home_card"
                  >
                    <div class="Search_container_2r">
                      <div class="Search_outer">
                        <div class="Search_slot">
                          <div>
                            <div class="wbpro-tab2 u-col-8">
                              <div class="keepfly-box-item-inlineBlock cur">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                                >
                                  <div class="wbpro-textcut">
                                    {{ $t("message.recommendo") }}
                                  </div>
                                </div>
                              </div>
                              <div class="keepfly-box-item-inlineBlock"></div>
                            </div>
                          </div>
                        </div>
                        <div class="Search_senior" style="display: none">
                          <button
                            class="keepfly-button-main keepfly-button-flat keepfly-button-default keepfly-button-s keepfly-button-round Search_btn"
                          >
                            <span class="keepfly-button-wrap">
                              <search-outline
                                class="keepfly-font keepfly-font--search keepfly-button-icon"
                              ></search-outline>
                              <span class="keepfly-button-content">
                                {{ $t("message.advanced_search") }}
                                <caret-down
                                  class="keepfly-font keepfly-font--caretDown"
                                ></caret-down>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Home_feed">
                  <div class="Scroll_container">
                    <div class="Scroll_wrap">
                      <div>
                        <div
                          class="vue-recycle-scroller ready page-mode direction-vertical"
                        >
                          <div class="vue-recycle-scroller__item-wrapper">
                            <div v-if="followermicroblogs.length > 0">
                              <div
                                class="wbpro-scroller-item"
                                v-for="item in followermicroblogs"
                                :key="item"
                              >
                                <article
                                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                                  style="border-radius: 4px"
                                >
                                  <div class="Feed_body">
                                    <header
                                      class="keepfly-box-flex"
                                      style="margin-bottom: 8px"
                                    >
                                      <a
                                        class="ALink_default"
                                        @click="checkUserInfo(item, 1)"
                                      >
                                        <div
                                          class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                          style="width: 3.125rem; height: 3.125rem"
                                        >
                                          <img
                                            :src="item.avatar"
                                            class="keepfly-avatar-img"
                                          />
                                          <div class="keepfly-avatar-hoverMask"></div>
                                        </div>
                                      </a>
                                      <div class="keepfly-box-item-flex head_main">
                                        <div
                                          class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                          >
                                            <a class="ALink_default head_cut head_name">
                                              <span :title="item.nickname">{{
                                                item.nickname
                                              }}</span>
                                            </a>
                                          </div>
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter head-info_info"
                                          >
                                            <a
                                              :title="item.createTime"
                                              class="head-info_time"
                                            >
                                              {{ item.createTime }}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="keepfly-box-flex">
                                        <div class="keepfly-pop-wrap morepop_more">
                                          <n-popconfirm
                                            :positive-text="$t('message.determine')"
                                            :negative-text="$t('message.call_off')"
                                            @positive-click="removeattention(item)"
                                          >
                                            <template #trigger>
                                              <span class="keepfly-pop-ctrl">
                                                <div
                                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                                >
                                                  <close
                                                    class="keepfly-font keepfly-font--cross morepop_action morepop_cross"
                                                  ></close>
                                                </div>
                                              </span>
                                            </template>
                                            {{ $t("message.tips49") }}
                                          </n-popconfirm>
                                        </div>
                                      </div>
                                    </header>
                                    <div class="wbpro-feed-content">
                                      <div class="detail_ogText wbpro-feed">
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentAr"
                                          v-html="filters(item.contentAr)"
                                        ></div>
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentEn"
                                          v-html="filters(item.contentEn)"
                                        ></div>
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentJa"
                                          v-html="filters(item.contentJa)"
                                        ></div>
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentKo"
                                          v-html="filters(item.contentKo)"
                                        ></div>
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentRu"
                                          v-html="filters(item.contentRu)"
                                        ></div>
                                        <div
                                          class="detail_wbtext"
                                          v-if="item.contentZh"
                                          v-html="filters(item.contentZh)"
                                        ></div>
                                      </div>
                                      <div class="picture picture-box_row">
                                        <span
                                          v-for="titem in item.tags"
                                          :key="titem"
                                          style="
                                            color: #ff8200;
                                            padding-right: 10px;
                                            font-size: 12px;
                                          "
                                          :title="titem"
                                        >
                                          {{ uqTxtTranslate(titem) }}
                                        </span>
                                      </div>
                                      <div class="content_row" v-if="item.images != []">
                                        <div
                                          class="u-col-3 keepfly-box-wrap picture_inlineNum3"
                                        >
                                          <n-image-group>
                                            <n-space>
                                              <n-image
                                                width="133"
                                                height="133"
                                                v-for="oitem in item.images"
                                                :key="oitem"
                                                :src="oitem"
                                                style="border-radius: 15px"
                                              />
                                            </n-space>
                                          </n-image-group>
                                        </div>
                                      </div>
                                      <div v-if="item.videoInfo">
                                        <video
                                          controls="controls"
                                          :src="item.videoInfo"
                                          class="video"
                                          style="
                                            width: 400px;
                                            vertical-align: middle;
                                            height: 225px;
                                            background: #000;
                                            border-radius: 10px;
                                          "
                                        ></video>
                                      </div>
                                    </div>
                                  </div>
                                  <footer class="">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                                    >
                                      <div
                                        class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                        @click="handleConfirm(item.id)"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        >
                                          <div class="keepfly-pop-wrap">
                                            <span class="keepfly-pop-ctrl">
                                              <div
                                                class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                              >
                                                <div
                                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                                >
                                                  <share-outline
                                                    :title="$t('message.repeat')"
                                                    class="keepfly-font keepfly-font--retweet toolbar_retweetIcon"
                                                  ></share-outline>
                                                </div>
                                                <span class="toolbar_num">
                                                  {{ item.fnum == null ? 0 : item.fnum }}
                                                </span>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                        @click="getCommentView(item, 1)"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                          >
                                            <ellipsis-horizontal-circle
                                              :title="$t('message.comment')"
                                              class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                            ></ellipsis-horizontal-circle>
                                          </div>
                                          <span class="toolbar_num">
                                            {{ item.cnum == null ? 0 : item.cnum }}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                        >
                                          <button
                                            @click="likeClick(item)"
                                            class="keepfly-like-main toolbar_btn"
                                            :title="$t('message.praise')"
                                          >
                                            <svg
                                              style="width: 19px"
                                              class="keepfly-like-iconWrap"
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 32 32"
                                            >
                                              <path
                                                d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                            <span class="keepfly-like-count">{{
                                              item.lnum == null ? 0 : item.lnum
                                            }}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </footer>
                                  <div v-if="item.show">
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                    ></div>
                                    <div class="Feed_box">
                                      <div class="keepfly-box-flex Feed_mar2">
                                        <div
                                          class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                          style="width: 2.125rem; height: 2.125rem"
                                        >
                                          <img
                                            :src="infodata.avatar"
                                            class="keepfly-avatar-img"
                                          />
                                          <div class="keepfly-avatar-hoverMask"></div>
                                        </div>
                                        <div class="keepfly-box-item-flex">
                                          <div>
                                            <div>
                                              <div
                                                class="wbpro-form Form_wbproform"
                                                compser="true"
                                              >
                                                <textarea
                                                  :placeholder="$t('message.tips31')"
                                                  v-model="item.commentValue"
                                                  maxlength="100"
                                                  class="Form_input"
                                                  style="height: 84px"
                                                ></textarea>
                                              </div>
                                            </div>
                                            <div class="Composer_mar1">
                                              <div
                                                class="keepfly-box-flex keepfly-box-alignCenter"
                                              >
                                                <button
                                                  @click="sendInfoComment(item)"
                                                  class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                                >
                                                  <span class="keepfly-button-wrap">
                                                    <span
                                                      class="keepfly-button-content"
                                                      >{{ $t("message.comment") }}</span
                                                    >
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                      ></div>
                                      <div>
                                        <div>
                                          <div class="RepostCommentFeed_mar1">
                                            <div class="wbpro-list">
                                              <div
                                                class="item1"
                                                v-for="oitem in item.commentVos"
                                                :key="oitem"
                                              >
                                                <div class="item1in keepfly-box-flex">
                                                  <div>
                                                    <a
                                                      class="ALink_default"
                                                      @click="checkUserInfo(oitem, 2)"
                                                    >
                                                      <div
                                                        class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                        style="
                                                          width: 2.125rem;
                                                          height: 2.125rem;
                                                        "
                                                      >
                                                        <img
                                                          :src="oitem.fromUser.avatar"
                                                          class="keepfly-avatar-img"
                                                        />
                                                      </div>
                                                    </a>
                                                  </div>
                                                  <div class="con1 keepfly-box-item-flex">
                                                    <div class="text">
                                                      <a class="ALink_default">{{
                                                        oitem.fromUser.nickname
                                                      }}</a>
                                                      <a
                                                        class="ALink_none"
                                                        target="_blank"
                                                      ></a
                                                      >:
                                                      <span v-if="oitem.contentAr">{{
                                                        oitem.contentAr
                                                      }}</span>
                                                      <span v-if="oitem.contentEn">{{
                                                        oitem.contentEn
                                                      }}</span>
                                                      <span v-if="oitem.contentJa">{{
                                                        oitem.contentJa
                                                      }}</span>
                                                      <span v-if="oitem.contentKo">{{
                                                        oitem.contentKo
                                                      }}</span>
                                                      <span v-if="oitem.contentRu">{{
                                                        oitem.contentRu
                                                      }}</span>
                                                      <span v-if="oitem.contentZh">{{
                                                        oitem.contentZh
                                                      }}</span>
                                                    </div>
                                                    <div
                                                      class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                                    >
                                                      <div>
                                                        {{ oitem.createDate }}
                                                      </div>
                                                      <div class="opt keepfly-box-flex">
                                                        <div
                                                          class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                        >
                                                          <button
                                                            class="keepfly-like-main IconList_likebox"
                                                            :title="$t('message.praise')"
                                                            @click="
                                                              commentVoslikeClick(oitem)
                                                            "
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              style="width: 22px"
                                                              class="keepfly-like-iconWrap"
                                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                                              viewBox="0 0 32 32"
                                                            >
                                                              <path
                                                                d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                                fill="currentColor"
                                                              ></path>
                                                            </svg>
                                                            <span
                                                              class="keepfly-like-count"
                                                              >{{
                                                                oitem.lnum == null
                                                                  ? 0
                                                                  : oitem.lnum
                                                              }}</span
                                                            >
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                          ></div>
                                          <a
                                            class="ALink_default"
                                            @click="morecommentVos(item)"
                                          >
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                            >
                                              <div
                                                v-if="
                                                  item.commentVos != null &&
                                                  item.commentVos.length < item.total
                                                "
                                                style="color: #ccc"
                                              >
                                                {{ $t("message.more") }}
                                              </div>
                                              <div style="color: #ccc" v-else>
                                                {{ $t("message.tips74") }}
                                              </div>
                                              <i
                                                class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                              ></i>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                            <div v-else>
                              <div style="background: #fff; padding: 40px 10px">
                                <n-empty :description="$t('message.none')">
                                  <template #extra></template>
                                </n-empty>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab1Show == 2">
          <div class="Home_home">
            <div id="homeWrap" class="Home_wrap">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="index_box">
                  <div class="index_box1">
                    <div
                      :class="[
                        keepflycontextfocus
                          ? 'wbpro-form Form_wbproform focus'
                          : 'wbpro-form Form_wbproform',
                      ]"
                    >
                      <textarea
                        :placeholder="$t('message.tips50')"
                        v-model="keepflygolden"
                        @input="getgoldeninput"
                        class="Form_input"
                      ></textarea>
                      <div class="Form_count" v-if="keepflygolden.length > 0">
                        <span>{{ keepflygoldenLength }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="index_box2"></div>
                  <div class="grayTheme">
                    <div class="keepfly-box-flex keepfly-box-alignCenter">
                      <div class="Tool_check">
                        <div class="Tool_checkc"></div>
                        <button
                          @click="sendGolden"
                          class="keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Tool_btn"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              <!-- {{ $t("message.send") }} -->
                              <svg
                                style="width: 22px"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                enable-background="new 0 0 512 512"
                                xml:space="preserve"
                              >
                                <g>
                                  <path
                                    fill="#fff"
                                    d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Home_feed">
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                      >
                        <div class="vue-recycle-scroller__item-wrapper">
                          <div>{{ $t("message.tips142") }}</div>
                          <div v-if="goldenarr.length > 0">
                            <div
                              class="wbpro-scroller-item"
                              v-for="(item, index) in goldenarr"
                              :key="item"
                            >
                              <article
                                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                                style="border-radius: 4px"
                              >
                                <div class="Feed_body">
                                  <div>
                                    <div class="detail_ogText wbpro-feed">
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentAr"
                                        v-html="filters(item.contentAr)"
                                      ></div>
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentEn"
                                        v-html="filters(item.contentEn)"
                                      ></div>
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentJa"
                                        v-html="filters(item.contentJa)"
                                      ></div>
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentKo"
                                        v-html="filters(item.contentKo)"
                                      ></div>
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentRu"
                                        v-html="filters(item.contentRu)"
                                      ></div>
                                      <div
                                        class="detail_wbtext"
                                        v-if="item.contentZh"
                                        v-html="filters(item.contentZh)"
                                      ></div>
                                    </div>
                                  </div>
                                  <header
                                    class="keepfly-box-flex"
                                    style="margin-bottom: 8px"
                                  >
                                    <div
                                      class="keepfly-box-item-flex head_main"
                                      style="text-align: right"
                                    >
                                      <div
                                        class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                        style="padding-top: 10px"
                                      >
                                        <!-- <div class="head_nick">
                                          &nbsp;
                                          <a class="ALink_default head_cut head_name">
                                            <span :title="item.nickname">{{
                                              item.nickname
                                            }}</span>
                                          </a>
                                        </div> -->
                                        <div class="head_nick">
                                          <a
                                            :title="item.createTime"
                                            class="head-info_time"
                                            style="
                                              font-size: 12px;
                                              color: darkgrey;
                                              font-weight: 400;
                                            "
                                          >
                                            {{ item.createTime }}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <a class="ALink_default">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                        style="width: 2.125rem; height: 2.125rem"
                                      >
                                        <img
                                          :src="item.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                    </a> -->
                                  </header>
                                  <footer
                                    class="keepfly-box-flex"
                                    style="
                                      position: absolute;
                                      padding: 0px 0px 10px;
                                      bottom: 0;
                                    "
                                  >
                                    <div
                                      class="keepfly-box-flex"
                                      style="padding-top: 3px"
                                    >
                                      <div class="keepfly-pop-wrap morepop_more">
                                        <span class="keepfly-pop-ctrl">
                                          <div
                                            @click="goldenlikeClick(item)"
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              style="width: 22px"
                                              class="keepfly-font keepfly-font--cross morepop_action morepop_cross"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 32 32"
                                            >
                                              <path
                                                d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="keepfly-box-flex">
                                      <span class="keepfly-like-count">{{
                                        item.lnum == null ? 0 : item.lnum
                                      }}</span>
                                    </div>
                                    <div
                                      @click="goldendeleteClick(item.id, index)"
                                      :title="$t('message.delete')"
                                      class="keepfly-box-flex"
                                      style="
                                        padding-left: 10px;
                                        font-size: 12px;
                                        cursor: pointer;
                                      "
                                    >
                                      <svg
                                        style="width: 19px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 20 20"
                                      >
                                        <g fill="none">
                                          <path
                                            d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                                            fill="currentColor"
                                          ></path>
                                        </g>
                                      </svg>
                                      <!-- {{ $t("message.delete") }} -->
                                    </div>
                                  </footer>
                                </div>
                              </article>
                            </div>
                          </div>
                          <div v-else>
                            <div style="background: #fff; padding: 40px 10px">
                              <n-empty :description="$t('message.none')">
                                <template #extra></template>
                              </n-empty>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 745px">
            <div class="Side_sideBox Side_posSticky grayTheme" style="top: 63px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div>
                      <div
                        class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                      >
                        <div
                          class="f14 cla keepfly-box-item-flex"
                          style="align-self: center"
                        >
                          Keepfly {{ $t("message.hot_search") }}
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter"
                          @click="refreshlist"
                        >
                          <refresh
                            class="keepfly-font f14 clb keepfly-font--refresh"
                            style="width: 17px"
                          ></refresh>
                          <span class="f12 clb">{{ $t("message.click_refresh") }}</span>
                        </div>
                      </div>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <n-spin :show="hotshow">
                        <div
                          class="wbpro-side-card7"
                          v-for="(item, index) in hotmicroblogs"
                          :key="item"
                        >
                          <div class="wbpro-side-panel">
                            <a class="ALink_default" target="_blank">
                              <div class="con keepfly-box-flex keepfly-box-alignCenter">
                                <!-- <div class="rank top" v-if="index == 0">
                                  <svg
                                    class="keepfly-font keepfly-font--top2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 0 0-12.6 0l-112 141.7a7.98 7.98 0 0 0 6.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div> -->
                                <div class="rank num" v-if="index == 0">1</div>
                                <div class="rank num" v-if="index == 1">2</div>
                                <div class="rank num" v-if="index == 2">3</div>
                                <div class="rank" v-if="index >= 3">{{ index + 1 }}</div>
                                <div class="wbpro-textcut f12 cla">
                                  <span v-if="item.topicTitle">{{
                                    item.topicTitle
                                  }}</span>
                                </div>
                              </div>
                              <div class="keepfly-divider-main keepfly-divider-x"></div>
                            </a>
                          </div>
                        </div>
                      </n-spin>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <a class="ALink_default">
                        <button
                          @click="joinHot"
                          class="wbpro-side-btn f12 clb keepfly-button-main keepfly-button-simple keepfly-button-default keepfly-button-m keepfly-button-round keepfly-button-fluid"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              {{ $t("message.tips26") }}
                            </span>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          @click="helpview"
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span class="CopyRight_contentItem"
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="showUserInfoModal"
      class="custom-card"
      preset="card"
      :style="bodyUserInfoStyle"
      :title="$t('message.userinfo')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
      @scroll="userInfoRef($event)"
      style="overflow-y: scroll; height: 100vh"
    >
      <div>
        <div
          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div class="wbpro-pos">
            <div class="keepfly-picture-main ProfileHeader_pic">
              <div style="padding-bottom: 56.25%"></div>
              <img :src="kukesystemInfo.bjurl" class="keepfly-picture-img" />
              <div class="keepfly-picture-cover"></div>
            </div>
          </div>
          <div class="keepfly-box-flex keepfly-box-alignStart ProfileHeader_box1">
            <div
              class="keepfly-avatar-main keepfly-avatar-hover ProfileHeader_avatar2"
              style="width: 6.25rem; height: 6.25rem"
            >
              <img
                src="../../assets/default_avatar_male_180.gif"
                class="keepfly-avatar-img"
                v-if="keepflyUserInfo.avatar == null"
              />
              <img :src="keepflyUserInfo.avatar" class="keepfly-avatar-img" v-else />
              <div class="keepfly-avatar-hoverMask"></div>
            </div>
            <div class="keepfly-box-item-flex" style="overflow: hidden">
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h3">
                <div class="ProfileHeader_name">
                  {{ keepflyUserInfo.nickname }}
                </div>
                <span class="keepfly-icon-wrap" style="margin-left: 5px">
                  <male
                    class="keepfly-icon-main keepfly-icon--male"
                    v-if="keepflyUserInfo.gender == 1"
                    style="width: 1rem; height: 1rem"
                  ></male>
                  <female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else-if="keepflyUserInfo.gender == 2"
                    style="width: 1rem; height: 1rem"
                  >
                  </female>
                  <male-female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else
                    style="width: 1rem; height: 1rem"
                  ></male-female>
                </span>
              </div>
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4">
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.fans") }}<span>{{ userNum.ffnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.follow") }}<span>{{ userNum.fnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.effect") }}<span>{{ userNum.num }}</span>
                  </span>
                </a>
              </div>
              <div
                class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                style="font-size: 13px"
              >
                ID：{{ keepflyUserInfo.kpId }}
              </div>
            </div>
            <div style="margin-right: 26px; padding-top: 33px">
              <n-button
                style="background: purple; color: #fff"
                strong
                secondary
                round
                size="small"
                @click="sendprivateletter(keepflyUserInfo)"
              >
                <template #icon>
                  <n-icon>
                    <chatbubbles-outline />
                  </n-icon>
                </template>
                {{ $t("message.private_letter") }}
              </n-button>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="ProfileHeader_box2">
              <div class="keepfly-box-flex keepfly-box-justifyBetween">
                <div class="keepfly-box-flex keepfly-box-column ProfileHeader_content">
                  <div style="margin-bottom: 12px">
                    <div class="keepfly-box-item-inlineBlock">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <document-text-outline
                            class="keepfly-font keepfly-font--proBintro"
                          ></document-text-outline>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{
                            keepflyUserInfo.profile == null ||
                            keepflyUserInfo.profile == ""
                              ? $t("message.tips16")
                              : keepflyUserInfo.profile
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ProfileHeader_box3"
              v-if="keepflyUserInfo.birthday || keepflyUserInfo.emotionalStatus"
            >
              <div
                v-if="keepflyUserInfo.birthday"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M448 384c-28.02 0-31.26-32-74.5-32c-43.43 0-46.825 32-74.75 32c-27.695 0-31.454-32-74.75-32c-42.842 0-47.218 32-74.5 32c-28.148 0-31.202-32-74.75-32c-43.547 0-46.653 32-74.75 32v-80c0-26.5 21.5-48 48-48h16V112h64v144h64V112h64v144h64V112h64v144h16c26.5 0 48 21.5 48 48v80zm0 128H0v-96c43.356 0 46.767-32 74.75-32c27.951 0 31.253 32 74.75 32c42.843 0 47.217-32 74.5-32c28.148 0 31.201 32 74.75 32c43.357 0 46.767-32 74.75-32c27.488 0 31.252 32 74.5 32v96zM96 96c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32c0-31 32-23 32-64c12 0 32 29.5 32 56s-14.25 40-32 40z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.birthday }}
                  </div>
                </div>
              </div>
              <div
                v-if="keepflyUserInfo.emotionalStatus"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none">
                        <path
                          d="M10.496 16.803L15.255 12H14.25c-.123 0-.243-.015-.36-.044l-3.865 3.902l-3.863-3.902c-.117.029-.238.044-.362.044H4.799l4.755 4.803c.26.263.682.263.942 0zm6.228-12.49a4.416 4.416 0 0 1 .978 4.7A1.514 1.514 0 0 0 17.5 9h-.889a3.415 3.415 0 0 0-.598-3.984A3.306 3.306 0 0 0 11.3 5l-.951.963a.5.5 0 0 1-.711 0l-.96-.97a3.302 3.302 0 0 0-4.706-.016C2.899 6.061 2.713 7.711 3.42 9H2.5c-.06 0-.12.004-.18.01a4.398 4.398 0 0 1 .941-4.736a4.301 4.301 0 0 1 6.127.016l.605.61l.596-.603a4.306 4.306 0 0 1 6.135.016zM7.962 6.307a.5.5 0 0 0-.922-.004L5.47 10H2.5a.5.5 0 0 0 0 1h3.3a.5.5 0 0 0 .46-.304l1.235-2.907l2.043 4.903a.5.5 0 0 0 .886.073l2.143-3.429l1.307 1.493a.5.5 0 0 0 .376.171h3.25a.5.5 0 0 0 0-1h-3.023l-1.6-1.83a.5.5 0 0 0-.801.065l-1.987 3.179l-2.127-5.106z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ checkfeel(keepflyUserInfo.emotionalStatus) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.hometown">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none">
                        <path
                          d="M8.998 2.389a1.5 1.5 0 0 1 2.005 0l5.5 4.941A1.5 1.5 0 0 1 17 8.446V9.4a2.98 2.98 0 0 0-1-.36v-.595a.5.5 0 0 0-.166-.372l-5.5-4.942a.5.5 0 0 0-.668 0l-5.5 4.942A.5.5 0 0 0 4 8.446V15.5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5V12A1.5 1.5 0 0 1 9 10.5h2a1.5 1.5 0 0 1 1.5 1.5v2.208a2.51 2.51 0 0 0-1 .792v-3a.5.5 0 0 0-.5-.5H9a.5.5 0 0 0-.5.5v3.5A1.5 1.5 0 0 1 7 17H4.5A1.5 1.5 0 0 1 3 15.5V8.446c0-.426.18-.831.498-1.116l5.5-4.941zM17.5 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0zm1.5 4.5c0 1.245-1 2.5-3.5 2.5S12 17.75 12 16.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.hometown }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.address">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ keepflyUserInfo.address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3">
              <div style="position: relative">
                <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                  <div class="keepfly-box-flex keepfly-box-alignStart">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                    >
                      <calendar-outline
                        class="keepfly-font keepfly-font--proTime"
                      ></calendar-outline>
                    </div>
                    <div
                      class="keepfly-box-item-flex ProfileHeader_con3"
                      v-if="keepflyUserInfo.createTime"
                    >
                      {{ keepflyUserInfo.createTime.substring(0, 10) }}
                      {{ $t("message.add") }} keepfly
                    </div>
                  </div>
                </div>

                <div
                  style="
                    position: absolute;
                    color: darkgray;
                    width: 70px;
                    height: 32px;
                    top: 0;
                    right: 20px;
                    cursor: pointer;
                  "
                  class="ProfileHeader_con3"
                  @click="senduserblacklist(keepflyUserInfo)"
                >
                  {{ $t("message.tips122") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="wbpro-screen-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
        >
          <div>{{ $t("message.all") }} keepfly（{{ userNum.mnum }}）</div>
        </div>
        <div>
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    style="min-height: 684px"
                  >
                    <div class="">
                      <div
                        class="wbpro-scroller-item"
                        v-for="item in usermicroblogs"
                        :key="item"
                      >
                        <article
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                          tabindex="0"
                          style="border-radius: 4px"
                        >
                          <div class="Feed_body">
                            <header class="keepfly-box-flex">
                              <a class="ALink_default">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                  style="width: 3.25rem; height: 3.25rem"
                                >
                                  <img :src="item.avatar" class="keepfly-avatar-img" />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                              </a>
                              <div class="keepfly-box-item-flex head_main">
                                <div
                                  class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                  >
                                    <a class="ALink_default head_cut head_name">
                                      <span :title="item.nickname">{{
                                        item.nickname
                                      }}</span>
                                    </a>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter"
                                    ></div>
                                  </div>
                                  <div
                                    class="keepfly-box-alignCenter keepfly-box-justifyCenter head-info_info"
                                  >
                                    <a class="head-info_time">
                                      {{ item.createTime }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="keepfly-box-flex">
                                <div class="keepfly-pop-wrap morepop_more">
                                  <span class="keepfly-pop-ctrl">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                    >
                                      <chevron-down
                                        class="keepfly-font keepfly-font--angleDown morepop_action"
                                        style="width: 16px"
                                      ></chevron-down>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </header>
                            <div class="Feed_retweet">
                              <div class="detail_text detail_reText wbpro-feed-reText">
                                <div
                                  class="detail_wbtext"
                                  v-html="filters(item.content)"
                                ></div>
                              </div>
                              <div class="picture picture-box_row">
                                <span
                                  v-for="titem in item.tags"
                                  :key="titem"
                                  style="
                                    color: #ff8200;
                                    padding-right: 10px;
                                    font-size: 12px;
                                  "
                                  :title="titem"
                                >
                                  {{ uqTxtTranslate(titem) }}
                                </span>
                              </div>
                              <div
                                class="picture picture-box_row"
                                v-if="item.images != []"
                              >
                                <div class="u-col-3 keepfly-box-wrap picture_inlineNum3">
                                  <n-image-group>
                                    <n-space>
                                      <n-image
                                        width="133"
                                        height="133"
                                        v-for="oitem in item.images"
                                        :key="oitem"
                                        :src="oitem"
                                        style="border-radius: 15px"
                                      />
                                    </n-space>
                                  </n-image-group>
                                </div>
                              </div>
                              <div v-if="item.videoInfo">
                                <video
                                  controls="controls"
                                  :src="item.videoInfo"
                                  class="video"
                                  style="
                                    width: 400px;
                                    vertical-align: middle;
                                    height: 225px;
                                    background: #000;
                                    border-radius: 10px;
                                  "
                                ></video>
                              </div>
                            </div>
                          </div>
                          <footer class="">
                            <div
                              class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                            >
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  @click="getCommentView(item, 1)"
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  style="cursor: pointer"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                  >
                                    <ellipsis-horizontal-circle
                                      class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                    ></ellipsis-horizontal-circle>
                                  </div>
                                  <span class="toolbar_num"> {{ item.cnum }} </span>
                                </div>
                              </div>
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                >
                                  <button
                                    @click="likeClick(item)"
                                    class="keepfly-like-main toolbar_btn"
                                  >
                                    <svg
                                      style="width: 19px"
                                      class="keepfly-like-iconWrap"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 32 32"
                                    >
                                      <path
                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <span class="keepfly-like-count">{{
                                      item.lnum
                                    }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </footer>
                          <div v-if="item.show">
                            <div
                              class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                            ></div>
                            <div class="Feed_box">
                              <div class="keepfly-box-flex Feed_mar2">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                  style="width: 2.125rem; height: 2.125rem"
                                >
                                  <img
                                    :src="infodata.avatar"
                                    class="keepfly-avatar-img"
                                  />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                                <div class="keepfly-box-item-flex">
                                  <div>
                                    <div>
                                      <div
                                        class="wbpro-form Form_wbproform"
                                        compser="true"
                                      >
                                        <textarea
                                          :placeholder="$t('message.tips31')"
                                          v-model="item.commentValue"
                                          maxlength="100"
                                          class="Form_input"
                                          style="height: 84px"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="Composer_mar1">
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter"
                                      >
                                        <button
                                          @click="sendInfoComment(item)"
                                          class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">{{
                                              $t("message.comment")
                                            }}</span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                              ></div>
                              <div>
                                <div>
                                  <div class="RepostCommentFeed_mar1">
                                    <div class="wbpro-list">
                                      <div
                                        class="item1"
                                        v-for="oitem in item.commentVos"
                                        :key="oitem"
                                      >
                                        <div class="item1in keepfly-box-flex">
                                          <div>
                                            <a class="ALink_default">
                                              <div
                                                class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                style="width: 2.125rem; height: 2.125rem"
                                              >
                                                <img
                                                  :src="oitem.fromUser.avatar"
                                                  class="keepfly-avatar-img"
                                                />
                                              </div>
                                            </a>
                                          </div>
                                          <div class="con1 keepfly-box-item-flex">
                                            <div class="text">
                                              <a class="ALink_default">{{
                                                oitem.fromUser.nickname
                                              }}</a>
                                              <a class="ALink_none" target="_blank"></a>:
                                              <span v-if="oitem.contentAr">{{
                                                oitem.contentAr
                                              }}</span>
                                              <span v-if="oitem.contentEn">{{
                                                oitem.contentEn
                                              }}</span>
                                              <span v-if="oitem.contentJa">{{
                                                oitem.contentJa
                                              }}</span>
                                              <span v-if="oitem.contentKo">{{
                                                oitem.contentKo
                                              }}</span>
                                              <span v-if="oitem.contentRu">{{
                                                oitem.contentRu
                                              }}</span>
                                              <span v-if="oitem.contentZh">{{
                                                oitem.contentZh
                                              }}</span>
                                            </div>
                                            <div
                                              class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                            >
                                              <div>
                                                {{ oitem.createDate }}
                                              </div>
                                              <div class="opt keepfly-box-flex">
                                                <div
                                                  class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                >
                                                  <button
                                                    class="keepfly-like-main IconList_likebox"
                                                    :title="$t('message.praise')"
                                                    @click="commentVoslikeClick(oitem)"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      style="width: 22px"
                                                      class="keepfly-like-iconWrap"
                                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                                      viewBox="0 0 32 32"
                                                    >
                                                      <path
                                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                        fill="currentColor"
                                                      ></path>
                                                    </svg>
                                                    <span class="keepfly-like-count">{{
                                                      oitem.lnum == null ? 0 : oitem.lnum
                                                    }}</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                  ></div>
                                  <a class="ALink_default" @click="morecommentVos(item)">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                    >
                                      <div
                                        v-if="
                                          item.commentVos != null &&
                                          item.commentVos.length < item.total
                                        "
                                        style="color: #ccc"
                                      >
                                        {{ $t("message.more") }}
                                      </div>
                                      <div style="color: #ccc" v-else>
                                        {{ $t("message.tips74") }}
                                      </div>
                                      <i
                                        class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                      ></i>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </n-modal>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  ref,
  nextTick,
  watch,
  computed,
  onUnmounted,
  getCurrentInstance,
} from "vue";
import API from "../../uilts/request";
import utils from "../../uilts/utils";
import { client } from "../../uilts/alioss";
import bus from "../../uilts/bus.js";
const feelOptions = [
  {
    label: i18n.global.t("message.single"),
    value: 1001,
  },
  {
    label: i18n.global.t("message.seeking_a_relationship"),
    value: 1002,
  },
  {
    label: i18n.global.t("message.in_secret_love"),
    value: 1003,
  },
  {
    label: i18n.global.t("message.ambiguous"),
    value: 1004,
  },
  {
    label: i18n.global.t("message.in_love"),
    value: 1005,
  },
  {
    label: i18n.global.t("message.engaged"),
    value: 1006,
  },
  {
    label: i18n.global.t("message.married"),
    value: 1007,
  },
  {
    label: i18n.global.t("message.separated"),
    value: 1008,
  },
  {
    label: i18n.global.t("message.divorced"),
    value: 1009,
  },
  {
    label: i18n.global.t("message.widow"),
    value: 1010,
  },
  {
    label: i18n.global.t("message.not_selected"),
    value: 1011,
  },
];
import {
  CaretDown,
  SearchOutline,
  Images as ImagesOutline,
  HappyOutline,
  Close,
  Add,
  EllipsisHorizontalCircle,
  ShareOutline,
  Refresh,
  Male,
  Female,
  MaleFemale,
  ChatbubblesOutline,
  DocumentTextOutline,
  CalendarOutline,
} from "@vicons/ionicons5";
import {
  NPopconfirm,
  useMessage,
  useDialog,
  NDynamicTags,
  NAutoComplete,
  NButton,
  NIcon,
  NSpin,
  NImageGroup,
  NSpace,
  NImage,
  NModal,
  NEmpty,
} from "naive-ui";
import i18n from "../../i18n/index";
export default defineComponent({
  name: "IndexView",
  components: {
    NDynamicTags,
    NPopconfirm,
    CaretDown,
    SearchOutline,
    ImagesOutline,
    HappyOutline,
    Close,
    Add,
    NAutoComplete,
    NButton,
    NIcon,
    EllipsisHorizontalCircle,
    ShareOutline,
    Refresh,
    NSpin,
    NImageGroup,
    NSpace,
    NImage,
    NModal,
    NEmpty,
    Male,
    Female,
    MaleFemale,
    ChatbubblesOutline,
    DocumentTextOutline,
    CalendarOutline,
  },
  setup(props, context) {
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const dialog = useDialog();
    const navTab1Show = ref(1);
    const setNavTab1 = (e) => {
      navTab1Show.value = e;
      if (e == 2) {
        getgoldenlist();
      }
      window.scrollTo(0, 0);
    };
    const kukesystemInfo = ref({});
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getfollowermicroblog();
      gethotmicrobloglist();
      // 模拟外部点击
      document.addEventListener("click", (e) => {
        let arr = [
          "u-col-12 emoticon",
          "keepfly-pop-main keepfly-pop-down keepfly-pop-start",
          "wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "Emoticon_tab",
          "Emoticon_facebox1",
          "text",
        ];
        let flat = false;
        for (let i = 0; i < arr.length; i++) {
          if (
            e.target.className == arr[i] ||
            e.target.className.animVal == "keepfly-font keepfly-font--emoticon"
          ) {
            flat = true;
            break;
          }
        }
        if (flat) {
          Emoticon_show.value = true;
        } else {
          Emoticon_show.value = false;
        }
        if (Suggest_show.value) {
          if (e.target.className != "keepfly-box-item-flex Suggest_title") {
            Suggest_show.value = false;
          }
          let orr = [
            "wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter suggest",
          ];
          for (let i = 0; i < orr.length; i++) {
            if (
              e.target.className == orr[i] ||
              e.target.className.animVal == "keepfly-font keepfly-font--hash suggest"
            ) {
              Suggest_show.value = true;
            }
          }
        }
      });
      const textarea = document.querySelector("textarea");
      utils.autoTextarea(textarea);
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (navTab1Show.value == 1) {
            if (currfollowermicroblogPage.value < fpages.value) {
              ++currfollowermicroblogPage.value;
              getfollowermicroblog();
            }
          } else if (navTab1Show.value == 2) {
            if (currgoldenPage.value < gpages.value) {
              ++currgoldenPage.value;
              getgoldenlist();
            }
          }
        }
      }
    };
    const userInfoRef = (e) => {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight >=
        e.srcElement.scrollHeight
      ) {
        if (currusermicroblogPage.value < mpages.value) {
          ++currusermicroblogPage.value;
          getmicrobloguserlist(keepflyUserInfo.value.id);
        }
      }
    };
    const currfollowermicroblogPage = ref(1);
    const pagefollowermicroblogSize = ref(5);
    const followermicroblogs = ref([]);
    const fpages = ref(0);
    const getfollowermicroblog = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currfollowermicroblogPage.value,
        pageSize: pagefollowermicroblogSize.value,
      };
      API({
        url: "/kuke/api/user/getfollowermicroblog",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          followermicroblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        fpages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };
    const Emoticon_show = ref(false);
    const emoticonView = () => {
      Emoticon_show.value = true;
    };
    const FileUpload_file = ref(null);
    const imageView = () => {
      if (videoInfo.value != null) {
        message.error(i18n.global.t("message.tips190"));
        videoInfo.value == null;
      }
      if (FileUpload_file.value != null) {
        FileUpload_file.value.value = "";
      }
      FileUpload_file.value.click();
    };
    const videoUpload_file = ref(null);
    const videoView = () => {
      ImageList.value = [];
      if (videoUpload_file.value != null) {
        videoUpload_file.value.value = "";
      }
      videoUpload_file.value.click();
    };
    const refInput = ref();
    const getemoticontxt = (e) => {
      let emoticontxt = "[" + e + "]";
      const myField = document.querySelector("textarea");
      if (myField.selectionStart || myField.selectionStart === 0) {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        keepflycontext.value =
          myField.value.substring(0, startPos) +
          emoticontxt +
          myField.value.substring(endPos, myField.value.length);
        nextTick(() => {
          refInput.value.focus();
          refInput.value.setSelectionRange(
            endPos + emoticontxt.length,
            endPos + emoticontxt.length
          );
        });
      } else {
        keepflycontext.value += emoticontxt;
      }
      keepflycontextLength.value = utils.CountChineseCharacters(keepflycontext.value);
      // Suggest_show = false;
    };
    const Suggest_show = ref(false);
    const keepflycontext = ref("");
    const keepflycontextLength = ref(0);
    const ImageList = ref([]);
    const videoInfo = ref(null);
    const keepflycontextfocus = ref(false);
    const getfocus = () => {
      keepflycontextfocus.value = true;
    };
    const getblur = () => {
      keepflycontextfocus.value = false;
    };
    const getinput = () => {
      keepflycontextLength.value = utils.CountChineseCharacters(keepflycontext.value);
    };
    const message = useMessage();
    const sendKeepfly = () => {
      if (keepflycontext.value.length == 0) {
        return false;
      }
      let params = {
        keepflycontext: keepflycontext.value,
        imageList: ImageList.value,
        videoInfo: videoInfo.value,
        tags: tags.value,
      };
      API({
        url: "/kuke/api/user/sendKeepfly",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          keepflycontext.value = "";
          tags.value = [];
          ImageList.value = [];
          videoInfo.value = null;
          result.data.data.images = JSON.parse(result.data.data.images);
          microblogs.value.unshift(result.data.data);
          message.success(i18n.global.t("message.tips153"));
        } else {
          message.error(i18n.global.t("message.tips154"));
        }
      });
    };
    const microblogs = ref([]);
    const uploadURL = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then((res) => {
          console.log(res);
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          ImageList.value.push("http://img.keepfly.net/" + fileName);
        });
    };
    const videoURL = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossvideo/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then((res) => {
          console.log(res);
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          videoInfo.value = "http://img.keepfly.net/" + fileName;
        });
    };
    const imageClose = (e) => {
      ImageList.value.splice(e, 1);
    };
    const currgoldenPage = ref(1);
    const pagegoldenSize = ref(8);
    const goldenarr = ref([]);
    const gpages = ref(0);
    const getgoldenlist = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currgoldenPage.value,
        pageSize: pagegoldenSize.value,
      };
      API({
        url: "/kuke/api/user/getgoldenlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          goldenarr.value.push(arr[i]);
        }
        gpages.value = result.data.data.pages;
      });
    };
    const sendGolden = () => {
      if (
        keepflygolden.value == "" ||
        keepflygolden.value == null ||
        keepflygolden.value == undefined
      ) {
        return false;
      }
      let params = {
        language: localStorage.getItem("language"),
        keepflygolden: keepflygolden.value,
      };
      API({
        url: "/kuke/api/user/sendGolden",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          keepflygolden.value = "";
          goldenarr.value.unshift(result.data.data);
          message.success(i18n.global.t("message.tips163"));
        } else {
          message.error(i18n.global.t("message.tips154"));
        }
      });
    };
    const keepflygolden = ref("");
    const keepflygoldenLength = ref(0);
    const getgoldeninput = () => {
      keepflygoldenLength.value = utils.CountChineseCharacters(keepflygolden.value);
    };
    const tags = ref([]);
    const autoCompleteInstRef = ref(null);
    watch(autoCompleteInstRef, (value) => {
      if (value) nextTick(() => value.focus());
    });
    const inputValueRef = ref("");
    const suggestItemList = ref([]);
    const handleInput = () => {
      let orr = [];
      if (inputValueRef.value) {
        let param = {
          txt: inputValueRef.value,
        };
        orr.push(inputValueRef.value);
        API({
          url: "/kuke/api/user/getSuggestItemList",
          method: "POST",
          data: param,
        }).then((result) => {
          let arr = result.data.data;
          for (let i = 0; i < arr.length; i++) {
            orr.push(arr[i].topicTitle);
          }
          suggestItemList.value = orr;
        });
      }
    };
    const options = computed(() => {
      if (inputValueRef.value === null) {
        return [];
      }
      const prefix = inputValueRef.value.split("@")[0];
      const inputSuffix = inputValueRef.value.split("@")[1];
      if (inputSuffix) {
        return [
          {
            label: prefix,
            value: prefix,
          },
        ];
      }
      return suggestItemList.value.map((suffix) => {
        return {
          label: suffix,
          value: suffix,
        };
      });
    });
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const getCommentView = (e, i) => {
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const commentValue = ref("");
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.success(i18n.global.t("message.tips172"));
        }
      });
    };
    const infodata = ref({});
    const handleConfirm = (e) => {
      let param = {
        id: e,
      };
      dialog.warning({
        title: i18n.global.t("message.hint"),
        content: i18n.global.t("message.tips53"),
        positiveText: i18n.global.t("message.determine"),
        onPositiveClick: () => {
          API({
            url: "/kuke/api/user/setmicroblogforwardclick",
            method: "POST",
            data: param,
          }).then((result) => {
            if (result.data.code == 200) {
              message.success(i18n.global.t("message.tips29"));
            } else {
              message.error(i18n.global.t("message.tips30"));
            }
          });
        },
      });
    };
    const goldenlikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setgoldenlikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const currPage = ref(1);
    const pageSize = ref(8);
    const hotmicroblogs = ref([]);
    const gethotmicrobloglist = () => {
      hotshow.value = true;
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/gethotmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        hotmicroblogs.value = arr;
        hotshow.value = false;
      });
    };
    const refreshlist = () => {
      gethotmicrobloglist();
    };
    const hotshow = ref(false);
    const joinHot = () => {
      context.emit("eventParent", 2);
      bus.emit("sendToBrother", 2);
      window.localStorage.setItem("sendTohuati", 2);
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const showUserInfoModal = ref(false);
    const keepflyUserInfo = ref({});
    const checkUserInfo = (e, i) => {
      let id = i == 1 ? e.uid : e.fromUser.id;
      API({
        url: "/kuke/api/index/checkUserInfo",
        method: "POST",
        data: {
          id: id,
          trt: window.localStorage.getItem("token"),
        },
      }).then((result) => {
        if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips113"));
          return false;
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips114"));
          return false;
        }
        showUserInfoModal.value = true;
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
      });
      getUserInfoNum(id);
      usermicroblogs.value = [];
      getmicrobloguserlist(id);
    };
    const userNum = ref({});
    const getUserInfoNum = (e) => {
      API({
        url: "/kuke/api/index/getUserInfoNum",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          userNum.value = result.data.data;
        }
      });
    };
    const currusermicroblogPage = ref(1);
    const pageusermicroblogSize = ref(4);
    const usermicroblogs = ref([]);
    const mpages = ref(0);
    const getmicrobloguserlist = (e) => {
      let param = {
        currPage: currusermicroblogPage.value,
        pageSize: pageusermicroblogSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getmicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          usermicroblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mpages.value = result.data.data.pages;
        setMicroblogView(qrr);
      });
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const topicTranslateList = ref([]);
    const setMicroblogView = (e) => {
      let params = {
        language: localStorage.getItem("language"),
        tags: e,
      };
      API({
        url: "/kuke/api/index/setMicroblogView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        let arr = result.data.data;
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
            topicTranslateList.value.push(arr[i]);
          }
        }
      });
    };
    const uqTxtTranslate = (e) => {
      let orr = topicTranslateList.value;
      for (let i = 0; i < orr.length; i++) {
        if (orr[i].topicTitle == e) {
          return "#" + orr[i].topicTranslate;
        }
      }
    };
    const sendprivateletter = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          context.emit("eventParent", 4);
          bus.emit("sendToBrother", 4);
          window.localStorage.setItem("sendTohuati", 4);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips123"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    const senduserblacklist = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/senduserblacklist",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips120"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips121"));
        }
      });
    };
    const goldendeleteClick = (e, i) => {
      let params = {
        id: e,
      };
      API({
        url: "/kuke/api/user/goldendeleteClick",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          goldenarr.value.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const removeattention = (e) => {
      let param = {
        id: e.uid,
        inum: 2,
      };
      API({
        url: "/kuke/api/user/cancelfollow",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips167"));
          let arr = [];
          for (let i = 0; i < followermicroblogs.value.length; i++) {
            if (followermicroblogs.value[i].uid != e.uid) {
              arr.push(followermicroblogs.value[i]);
            }
          }
          followermicroblogs.value = arr;
        } else {
          message.error(i18n.global.t("message.tips168"));
        }
      });
    };
    function checkfeel(e) {
      let arr = feelOptions;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value == e) {
          return arr[i].label;
        }
      }
    }
    return {
      videoURL,
      videoUpload_file,
      videoView,
      feelOptions,
      checkfeel,
      removeattention,
      keepflygoldenLength,
      goldendeleteClick,
      senduserblacklist,
      sendprivateletter,
      uqTxtTranslate,
      setMicroblogView,
      topicTranslateList,
      kukesystemInfo,
      commentVoslikeClick,
      morecommentVos,
      userInfoRef,
      usermicroblogs,
      getmicrobloguserlist,
      userNum,
      showUserInfoModal,
      keepflyUserInfo,
      checkUserInfo,
      filters,
      joinHot,
      hotshow,
      refreshlist,
      hotmicroblogs,
      goldenlikeClick,
      handleConfirm,
      infodata,
      sendInfoComment,
      commentValue,
      getCommentView,
      likeClick,
      handleInput,
      autoCompleteInstRef,
      inputValue: inputValueRef,
      options,
      tags,
      emoticon,
      FileUpload_file,
      navTab1Show,
      goldenarr,
      keepflygolden,
      getgoldeninput,
      sendGolden,
      setNavTab1,
      getgoldenlist,
      uploadURL,
      imageClose,
      microblogs,
      sendKeepfly,
      getinput,
      getblur,
      getfocus,
      getemoticontxt,
      keepflycontextfocus,
      ImageList,
      videoInfo,
      keepflycontextLength,
      keepflycontext,
      imageView,
      emoticonView,
      Emoticon_show,
      Suggest_show,
      followermicroblogs,
      getfollowermicroblog,
      bodyUserInfoStyle: {
        width: "900px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
<style>
.n-spin {
  color: #ff8200 !important;
}
</style>
