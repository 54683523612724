<template>
  <div class="enroll_main">
    <div class="K_reg_header K_logo_wave">
      <a href="index.html">
        <div class="K_nologin_logo_big"></div>
      </a>
    </div>
    <div class="K_nologin_main main_radius_bg">
      <div style="margin-top: -36px; padding: 40px 40px 0px 40px">
        <div class="head-left">
          <a class="txt" href="/#/">
            {{ $t("message.homepage") }}
          </a>
        </div>
        <div class="head-rigth">
          <n-select
            @update:value="handleSearch"
            v-model:value="selectedValue"
            label-field="name"
            value-field="language"
            size="tiny"
            status="warning"
            :options="options"
          />
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="main_tab_line">
        <a class="K_f22 K_Yahei cur" href="javascript:void(0);">{{
          $t("message.account_register")
        }}</a>
      </div>
      <div class="K_reg_info clearfix">
        <div class="K_reg_form">
          <n-form
            class="form-horizontal"
            :model="model"
            label-width="auto"
            :style="{ maxWidth: '640px' }"
            label-placement="left"
          >
            <n-form-item :label="$t('message.avatar')">
              <!-- <n-input v-model:value="model.avatar" :placeholder="$t('message.avatar')" /> -->
              <div>
                <div
                  v-for="(item, index) in avatarList"
                  :key="item"
                  style="display: inline-block"
                >
                  <n-avatar
                    :size="48"
                    :src="item.imgsrc"
                    @click="checkAvatar(index + 1)"
                    :style="{
                      color: 'yellow',
                      border: '2px solid red',
                      margin: '0 10px 0 0',
                    }"
                    v-if="bindex == index + 1"
                  />
                  <n-avatar
                    :size="48"
                    :src="item.imgsrc"
                    @click="checkAvatar(index + 1)"
                    :style="{
                      color: 'yellow',
                      border: 'none',
                      margin: '0 10px 0 0',
                    }"
                    v-else
                  />
                </div>
                <div
                  class="n-upload-trigger n-upload-trigger--image-card"
                  @click="djuserFileView"
                >
                  <div class="n-upload-dragger">
                    <i class="n-base-icon">
                      <svg
                        width="512"
                        height="512"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256 112V400M400 256H112"
                          stroke="currentColor"
                          stroke-width="32"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </i>
                  </div>
                </div>
                <div
                  style="width: 172px; font-size: 12px; color: grey; margin-left: 10px"
                >
                  {{ $t("message.tips158") }}
                </div>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .bmp, .gif, .png"
                  ref="user_file"
                  @change="selectuserImg"
                  style="display: none"
                />
              </div>
            </n-form-item>
            <n-form-item :label="$t('message.nickname')">
              <n-input
                v-model:value="model.nickname"
                status="warning"
                :placeholder="$t('message.nickname')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.email')">
              <n-input
                v-model:value="model.email"
                status="warning"
                :placeholder="$t('message.email')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.mobile')">
              <n-input
                v-model:value="model.mobile"
                status="warning"
                :placeholder="$t('message.mobile')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.password')">
              <n-input
                type="password"
                show-password-on="mousedown"
                status="warning"
                v-model:value="model.pwd"
                :placeholder="$t('message.password')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.confirm_password')">
              <n-input
                type="password"
                show-password-on="mousedown"
                status="warning"
                v-model:value="model.confirmPwd"
                :placeholder="$t('message.confirm_password')"
              />
            </n-form-item>
            <n-form-item :label="$t('message.tips86')">
              <n-input
                v-model:value="model.yzcode"
                status="warning"
                :placeholder="$t('message.tips86')"
              />
              <a href="javascript:;" class="e-model" @click="sendCode">
                <!-- {{$t('message.gain')}} -->
                {{ codeNum == 60 ? $t("message.gain") : `(${codeNum})s` }}
              </a>
            </n-form-item>
            <div style="display: flex; justify-content: flex-end">
              <n-button round type="warning" @click="handleValidateButtonClick">
                {{ $t("message.register") }}
              </n-button>
            </div>
          </n-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { client } from "../uilts/alioss";
import {
  NForm,
  NFormItem,
  NInput,
  NButton,
  NSelect,
  useMessage,
  NAvatar,
} from "naive-ui";
import { useI18n } from "vue-i18n";
import i18n from "../i18n/index";
import API from "../uilts/request";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "EnrollView",
  components: {
    NForm,
    NFormItem,
    NInput,
    NButton,
    NSelect,
    NAvatar,
  },
  setup() {
    const router = useRouter();
    const { locale } = useI18n();
    const message = useMessage();
    const avatarList = ref([
      {
        imgsrc: "http://www.keepfly.net/image/avatar1.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar2.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar3.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar4.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar5.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar6.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar7.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar8.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar9.jpg",
      },
      {
        imgsrc: "http://www.keepfly.net/image/avatar10.jpg",
      },
    ]);
    const model = ref({
      avatar: "",
      nickname: "",
      email: "",
      mobile: "",
      pwd: "",
      confirmPwd: "",
      yzcode: "",
    });
    //倒计时初始变量
    const codeNum = ref(60);
    const bindex = ref(1);
    // 定时器id
    let clearId;
    // 发送验证码
    const sendCode = async () => {
      if (
        model.value.email == null ||
        model.value.email == "" ||
        model.value.email == undefined
      ) {
        message.error(i18n.global.t("message.tips1"));
        return false;
      }
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!mailReg.test(model.value.email)) {
        message.error(i18n.global.t("message.tips17"));
        return false;
      }
      if (codeNum.value != 60) return;
      clearId = setInterval(() => {
        // 每次 时间1s -1
        codeNum.value--;
        // 时间=0时 清除定时器
        if (codeNum.value == 0) {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
        }
      }, 1000);
      API({
        url: "/kuke/api/index/getCode",
        method: "GET",
        params: { account: model.value.email },
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips22"));
        }
      });
    };
    let arr = ref([]);
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", locale.value);
      location.reload();
    };
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };

    onMounted(() => {
      getKukeLanguage();
      if (!localStorage.getItem("language")) {
        var lang = navigator.language;
        if (lang == "zh-CN") {
          localStorage.setItem("language", "zh");
        } else {
          localStorage.setItem("language", "en");
        }
      }
    });
    const langlocal = localStorage.getItem("language");
    const handleValidateButtonClick = () => {
      var avatar = avatarList.value[bindex.value - 1];
      var nickname = model.value.nickname;
      var email = model.value.email;
      var mobile = model.value.mobile;
      var pwd = model.value.pwd;
      var confirmPwd = model.value.confirmPwd;
      var yzcode = model.value.yzcode;
      if (nickname == "") {
        message.error(i18n.global.t("message.tips3"));
        return false;
      }
      if (email == "") {
        message.error(i18n.global.t("message.tips1"));
        return false;
      }
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!mailReg.test(model.value.email)) {
        message.error(i18n.global.t("message.tips17"));
        return false;
      }
      if (model.value.mobile == "") {
        message.error(i18n.global.t("message.tips17_o"));
        return false;
      }
      if (pwd == "") {
        message.error(i18n.global.t("message.tips2"));
        return false;
      }
      if (confirmPwd == "") {
        message.error(i18n.global.t("message.tips4"));
        return false;
      }
      if (confirmPwd != pwd) {
        message.error(i18n.global.t("message.tips5"));
        return false;
      }
      if (yzcode == "") {
        message.error(i18n.global.t("message.tips6"));
        return false;
      }
      let param = {
        avatar: avatar.imgsrc,
        nickname: nickname,
        email: email,
        mobile: mobile,
        pwd: pwd,
        yzcode: yzcode,
      };
      API({
        url: "/kuke/api/index/enrollUser",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips33"));
          model.value = {
            nickname: "",
            email: "",
            mobile: "",
            pwd: "",
            confirmPwd: "",
            yzcode: "",
          };
          setTimeout(function () {
            router.push({
              name: "login",
            });
          }, 3000);
        } else {
          if (result.data.code == 601) {
            message.error(i18n.global.t("message.tips18"));
          } else if (result.data.code == 602) {
            message.error(i18n.global.t("message.tips19"));
          } else if (result.data.code == 603) {
            message.error(i18n.global.t("message.tips20"));
          } else if (result.data.code == 604) {
            message.error(i18n.global.t("message.tips21"));
          }
        }
      });
    };
    const checkAvatar = (e) => {
      bindex.value = e;
    };
    const user_file = ref();
    const djuserFileView = () => {
      user_file.value.value = "";
      user_file.value.click();
    };
    const selectuserImg = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 10;
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          // if (image.width / image.height != 1) {
          //   message.error(i18n.global.t("message.tips94"));
          //   return false;
          // }
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              avatarList.value.push({
                imgsrc: "http://img.keepfly.net/" + fileName,
              });
              bindex.value = avatarList.value.length;
            });
        };
      };
      reader.readAsDataURL(file);
    };
    return {
      selectuserImg,
      user_file,
      djuserFileView,
      checkAvatar,
      bindex,
      avatarList,
      options: arr,
      changeLang,
      model,
      selectedValue: ref(langlocal),
      handleSearch: (value) => {
        changeLang(value);
      },
      codeNum,
      sendCode,
      handleValidateButtonClick,
    };
  },
});
</script>
<style scoped>
.head-left {
  float: left;
  width: 80%;
}
.head-rigth {
  float: right;
  width: 20%;
}
.enroll_main {
  background: url(../assets/reg_repeat_bg.png) repeat-x #bee1f5;
}
a {
  color: #0a8cd2;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.main_radius_bg {
  width: 900px;
  margin: 0 auto 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 3px #98b4c4;
  -moz-box-shadow: 0px 2px 3px #98b4c4;
  -webkit-box-shadow: 0px 2px 3px #98b4c4;
}

.K_nologin_main {
  width: 900px;
  margin: 0 auto 35px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 2px 3px #98b4c4;
  -moz-box-shadow: 0px 2px 3px #98b4c4;
  -webkit-box-shadow: 0px 2px 3px #98b4c4;
}

.main_radius_bg .main_tab_line {
  padding: 24px 0 22px 40px;
}

.K_reg_info {
  background-position: 630px -45px;
}

.main_tab_line .cur {
  border-bottom: 3px solid #ffa00a;
}

.main_tab_line a {
  display: inline-block;
  font-size: 22px;
  color: #333;
  line-height: 33px;
}

.main_tab_line a:hover {
  text-decoration: none;
}

.K_f22 {
  font-size: 22px;
}

.K_Yahei {
  font-family: "Microsoft Yahei";
}

.main_radius_bg .K_reg_form {
  margin: 29px 0 0 20px;
  padding-bottom: 78px;
}

.K_reg_form {
  /* float: left; */
  width: 600px;
  /* margin: 30px 0 0 30px; */
  margin: 0 auto !important;
}

.clearfix {
  display: block;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.K_input {
  font-size: 14px;
}

input[type="text"],
input[type="password"],
textarea {
  outline-style: none;
  -webkit-appearance: none;
}

.K_input {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 100%;
  height: 34px;
  padding: 0 0 0 10px;
  vertical-align: middle;
  color: #808080;
  -webkit-box-shadow: 0px 1px 1px 0px #eaeaea inset;
  -moz-box-shadow: 0px 1px 1px 0px #eaeaea inset;
  box-shadow: 0px 1px 1px 0px #eaeaea inset;
  height: 20px\9;
  padding: 8px 0 0 2px\9;
}

.e-model {
  width: 122px;
  height: 34px;
  line-height: 34px;
  background: orange;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  right: 0px;
}

.e-model:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.K_btn_big {
  border: 1px solid #ffb33b;
  border-radius: 2px;
  color: #fff;
  background: #ffa00a;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  display: inline-block;
  cursor: pointer;
  width: 196px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.K_logo_wave {
  height: 128px;
  padding-top: 23px;
}

.K_nologin_logo_big {
  width: 200px;
  height: 72px;
  margin: 0 auto;
  margin-top: 26px;
  background: url(../assets/logo.png) no-repeat;
}
</style>
