<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side">
      <div class="Nav_main" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner wbpro-scrollbar"
        >
          <h2 class="Nav_title">{{ $t("message.information") }}</h2>
          <div>
            <div>
              <a class="ALink_none" @click="setNavTab4(1)">
                <div
                  :class="[
                    navTab4Show == 1
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <chatbox-ellipses-outline
                    class="keepfly-font keepfly-font--navComment NavItem_icon"
                  ></chatbox-ellipses-outline>
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: 19px;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="mnum > 0 && mnum < 10"
                    >{{ mnum > 99 ? "99+" : mnum }}</span
                  >
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: auto;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="mnum > 10"
                    >{{ mnum > 99 ? "99+" : mnum }}</span
                  >
                  <span class="NavItem_text">{{ $t("message.comment") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab4(2)">
                <div
                  :class="[
                    navTab4Show == 2
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <svg
                    class="keepfly-font keepfly-font--navGroup NavItem_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 32 32"
                  >
                    <path
                      d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: 19px;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="mlnum > 0 && mlnum < 10"
                    >{{ mlnum > 99 ? "99+" : mlnum }}</span
                  >
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: auto;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="mlnum > 10"
                    >{{ mlnum > 99 ? "99+" : mlnum }}</span
                  >
                  <span class="NavItem_text">{{ $t("message.praise") }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab4(3)">
                <div
                  :class="[
                    navTab4Show == 3
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <notifications-outline
                    class="keepfly-font keepfly-font--navGroup NavItem_icon"
                  ></notifications-outline>
                  <span class="NavItem_text">{{
                    $t("message.system_notification")
                  }}</span>
                </div>
              </a>
              <a class="ALink_none" @click="setNavTab4(4)">
                <div
                  :class="[
                    navTab4Show == 4
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <chatbubbles-outline
                    class="keepfly-font keepfly-font--navGroup NavItem_icon"
                  ></chatbubbles-outline>
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: 19px;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="sxnum > 0 && sxnum < 10"
                    >{{ sxnum > 99 ? "99+" : sxnum }}</span
                  >
                  <span
                    style="
                      display: inline-block;
                      font-size: 12px;
                      position: absolute;
                      top: 0;
                      left: 30px;
                      background: red;
                      border-radius: 9px;
                      width: auto;
                      color: #fff;
                      padding: 0 3px;
                      text-align: center;
                    "
                    v-if="sxnum > 10"
                    >{{ sxnum > 99 ? "99+" : sxnum }}</span
                  >
                  <span class="NavItem_text">{{ $t("message.private_letter") }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" v-if="navTab4Show == 1">
          <div class="home">
            <div>
              <div class="SecBar_secBar SecBar_visable">
                <div
                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                >
                  <div>
                    <div class="wbpro-tab2">
                      <div class="keepfly-box-item-inlineBlock cur">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                        >
                          <div class="text Tab_nowrap">
                            <div class="keepfly-badge-box">
                              {{ $t("message.tips104") }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="keepfly-box-item-inlineBlock"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                        v-if="evaluateArr.length > 0"
                      >
                        <div
                          class="vue-recycle-scroller__item-wrapper"
                          v-for="item in evaluateArr"
                          :key="item"
                        >
                          <div>
                            <div data-index="0" class="wbpro-scroller-item">
                              <div
                                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap MessageCmt_wrap"
                              >
                                <div class="keepfly-box-flex MessageCmt_item1">
                                  <div>
                                    <a class="ALink_default">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.fromavatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="keepfly-box-item-flex MessageCmt_con1">
                                    <div class="MessageCmt_h3">
                                      <a class="ALink_default">{{ item.fromnickname }}</a>
                                    </div>
                                    <div class="MessageCmt_from">
                                      <a>{{ item.createTime }}</a>
                                    </div>
                                    <div class="MessageCmt_wbtext MessageCmt_textImg">
                                      {{ $t("message.reply")
                                      }}<a>@{{ item.tonickname }}</a
                                      >:{{ filters(item.content) }}
                                    </div>
                                  </div>
                                </div>

                                <div class="MessageCmt_repeatbox MessageCmt_repeatbgbox">
                                  <div class="MessageCmt_wbtext MessageCmt_replyComment">
                                    <span class="MessageCmt_replyCname"
                                      >{{ $t("message.the_original") }}:</span
                                    >
                                    <span v-html="filters(item.mcontent)"></span>
                                  </div>
                                  <div class="content_row" v-if="item.images != []">
                                    <div
                                      class="u-col-3 keepfly-box-wrap picture_inlineNum3"
                                    >
                                      <n-image-group>
                                        <n-space>
                                          <n-image
                                            width="133"
                                            height="133"
                                            v-for="oitem in item.images"
                                            :key="oitem"
                                            :src="oitem"
                                            style="border-radius: 15px"
                                          />
                                        </n-space>
                                      </n-image-group>
                                    </div>
                                  </div>
                                  <div v-if="item.videoInfo">
                                    <video
                                      controls="controls"
                                      :src="item.videoInfo"
                                      class="video"
                                      style="
                                        width: 400px;
                                        vertical-align: middle;
                                        height: 225px;
                                        background: #000;
                                        border-radius: 10px;
                                      "
                                    ></video>
                                  </div>
                                </div>
                                <footer>
                                  <div
                                    class="keepfly-box-flex keepfly-box-justifyBetween bottom_side bottom_left bottom_main"
                                  >
                                    <div
                                      class="keepfly-box-item-flex bottom_item"
                                      style="width: 50%"
                                    >
                                      <div
                                        @click="getCommentView(item, 1)"
                                        style="cursor: pointer"
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter bottom_wrap"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter bottom_iconWrap"
                                        >
                                          <chatbubble-ellipses-outline
                                            class="keepfly-font keepfly-font--comment bottom_commentIcon"
                                          >
                                          </chatbubble-ellipses-outline>
                                        </div>
                                        <span class="bottom_num">
                                          {{ item.cnum }}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-box-item-flex bottom_item"
                                      style="width: 50%"
                                    >
                                      <button
                                        @click="likeClick(item)"
                                        class="keepfly-like-main bottom_btn"
                                        tabindex="0"
                                      >
                                        <span class="keepfly-like-iconWrap">
                                          <svg
                                            class="keepfly-like-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 32 32"
                                          >
                                            <path
                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </span>
                                        <span class="keepfly-like-count">{{
                                          item.lnum
                                        }}</span>
                                      </button>
                                    </div>
                                  </div>
                                </footer>
                                <div v-if="item.show">
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                  ></div>
                                  <div class="Feed_box">
                                    <div class="keepfly-box-flex Feed_mar2">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                        style="width: 2.125rem; height: 2.125rem"
                                      >
                                        <img
                                          :src="infodata.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div class="keepfly-box-item-flex">
                                        <div>
                                          <div>
                                            <div
                                              class="wbpro-form Form_wbproform"
                                              compser="true"
                                            >
                                              <textarea
                                                :placeholder="$t('message.tips31')"
                                                v-model="item.commentValue"
                                                maxlength="100"
                                                class="Form_input"
                                                style="height: 84px"
                                              ></textarea>
                                            </div>
                                          </div>
                                          <div class="Composer_mar1">
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter"
                                            >
                                              <button
                                                @click="sendInfoComment(item)"
                                                class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                              >
                                                <span class="keepfly-button-wrap">
                                                  <span class="keepfly-button-content">{{
                                                    $t("message.comment")
                                                  }}</span>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                    ></div>
                                    <div>
                                      <div>
                                        <div class="RepostCommentFeed_mar1">
                                          <div class="wbpro-list">
                                            <div
                                              class="item1"
                                              v-for="oitem in item.commentVos"
                                              :key="oitem"
                                            >
                                              <div class="item1in keepfly-box-flex">
                                                <div>
                                                  <a class="ALink_default">
                                                    <div
                                                      class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                      style="
                                                        width: 2.125rem;
                                                        height: 2.125rem;
                                                      "
                                                    >
                                                      <img
                                                        :src="oitem.fromUser.avatar"
                                                        class="keepfly-avatar-img"
                                                      />
                                                    </div>
                                                  </a>
                                                </div>
                                                <div class="con1 keepfly-box-item-flex">
                                                  <div class="text">
                                                    <a class="ALink_default">{{
                                                      oitem.fromUser.nickname
                                                    }}</a>
                                                    <a
                                                      class="ALink_none"
                                                      target="_blank"
                                                    ></a
                                                    >:
                                                    <span v-if="oitem.contentAr">{{
                                                      oitem.contentAr
                                                    }}</span>
                                                    <span v-if="oitem.contentEn">{{
                                                      oitem.contentEn
                                                    }}</span>
                                                    <span v-if="oitem.contentJa">{{
                                                      oitem.contentJa
                                                    }}</span>
                                                    <span v-if="oitem.contentKo">{{
                                                      oitem.contentKo
                                                    }}</span>
                                                    <span v-if="oitem.contentRu">{{
                                                      oitem.contentRu
                                                    }}</span>
                                                    <span v-if="oitem.contentZh">{{
                                                      oitem.contentZh
                                                    }}</span>
                                                  </div>
                                                  <div
                                                    class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                                  >
                                                    <div>
                                                      {{ oitem.createDate }}
                                                    </div>
                                                    <div class="opt keepfly-box-flex">
                                                      <div
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter optHover"
                                                      >
                                                        <alert-circle-outline
                                                          style="width: 22px"
                                                          class="keepfly-font keepfly-font--report"
                                                        ></alert-circle-outline>
                                                      </div>
                                                      <div
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                      >
                                                        <button
                                                          class="keepfly-like-main IconList_likebox"
                                                          :title="$t('message.praise')"
                                                          @click="
                                                            commentVoslikeClick(oitem)
                                                          "
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style="width: 22px"
                                                            class="keepfly-like-iconWrap"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 32 32"
                                                          >
                                                            <path
                                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                          <span
                                                            class="keepfly-like-count"
                                                            >{{
                                                              oitem.lnum == null
                                                                ? 0
                                                                : oitem.lnum
                                                            }}</span
                                                          >
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                        ></div>
                                        <a
                                          class="ALink_default"
                                          @click="morecommentVos(item)"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                          >
                                            <div
                                              v-if="
                                                item.commentVos != null &&
                                                item.commentVos.length < item.total
                                              "
                                              style="color: #ccc"
                                            >
                                              {{ $t("message.more") }}
                                            </div>
                                            <div style="color: #ccc" v-else>
                                              {{ $t("message.tips74") }}
                                            </div>
                                            <i
                                              class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                            ></i>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <n-pagination
                          v-model:page="currEvaluatePage"
                          size="medium"
                          :page-count="epages"
                          :page-slot="7"
                          @update-page="onDataEvaluateChange"
                        />
                      </div>
                      <div v-else>
                        <div style="background: #fff; padding: 40px 10px">
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab4Show == 2">
          <div class="home">
            <div>
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                        v-if="beautifulArr.length > 0"
                      >
                        <div
                          class="vue-recycle-scroller__item-wrapper"
                          v-for="item in beautifulArr"
                          :key="item"
                        >
                          <div>
                            <div data-index="0" class="wbpro-scroller-item">
                              <div
                                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap MessageCmt_wrap"
                              >
                                <div class="keepfly-box-flex MessageCmt_item1">
                                  <div>
                                    <a class="ALink_default">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          :src="item.fromavatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="keepfly-box-item-flex MessageCmt_con1">
                                    <div class="MessageCmt_h3">
                                      <a class="ALink_default">{{ item.fromnickname }}</a>
                                    </div>
                                    <div class="MessageCmt_from">
                                      <a>{{ item.createTime }}</a>
                                    </div>
                                  </div>
                                </div>
                                <div class="MessageCmt_repeatbox MessageCmt_repeatbgbox">
                                  <div class="MessageCmt_wbtext MessageCmt_replyComment">
                                    <span class="MessageCmt_replyCname">原文:</span>
                                    <span v-html="filters(item.mcontent)"></span>
                                  </div>
                                  <div class="content_row" v-if="item.images != []">
                                    <div
                                      class="u-col-3 keepfly-box-wrap picture_inlineNum3"
                                    >
                                      <n-image-group>
                                        <n-space>
                                          <n-image
                                            width="133"
                                            height="133"
                                            v-for="oitem in item.images"
                                            :key="oitem"
                                            :src="oitem"
                                            style="border-radius: 15px"
                                          />
                                        </n-space>
                                      </n-image-group>
                                    </div>
                                  </div>
                                  <div v-if="item.videoInfo">
                                    <video
                                      controls="controls"
                                      :src="item.videoInfo"
                                      class="video"
                                      style="
                                        width: 400px;
                                        vertical-align: middle;
                                        height: 225px;
                                        background: #000;
                                        border-radius: 10px;
                                      "
                                    ></video>
                                  </div>
                                </div>
                                <footer>
                                  <div
                                    class="keepfly-box-flex keepfly-box-justifyBetween bottom_side bottom_left bottom_main"
                                  >
                                    <div
                                      class="keepfly-box-item-flex bottom_item"
                                      style="width: 50%"
                                    >
                                      <div
                                        @click="getCommentView(item, 1)"
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter bottom_wrap"
                                        style="cursor: pointer"
                                      >
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter bottom_iconWrap"
                                        >
                                          <chatbubble-ellipses-outline
                                            class="keepfly-font keepfly-font--comment bottom_commentIcon"
                                          >
                                          </chatbubble-ellipses-outline>
                                        </div>
                                        <span class="bottom_num"> {{ item.cnum }} </span>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-box-item-flex bottom_item"
                                      style="width: 50%"
                                    >
                                      <button
                                        @click="likeClick(item)"
                                        class="keepfly-like-main bottom_btn"
                                      >
                                        <span class="keepfly-like-iconWrap">
                                          <svg
                                            class="keepfly-like-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 32 32"
                                          >
                                            <path
                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </span>
                                        <span class="keepfly-like-count">{{
                                          item.lnum
                                        }}</span>
                                      </button>
                                    </div>
                                  </div>
                                </footer>
                                <div v-if="item.show">
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                  ></div>
                                  <div class="Feed_box">
                                    <div class="keepfly-box-flex Feed_mar2">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                        style="width: 2.125rem; height: 2.125rem"
                                      >
                                        <img
                                          :src="infodata.avatar"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                      <div class="keepfly-box-item-flex">
                                        <div>
                                          <div>
                                            <div
                                              class="wbpro-form Form_wbproform"
                                              compser="true"
                                            >
                                              <textarea
                                                :placeholder="$t('message.tips31')"
                                                v-model="item.commentValue"
                                                maxlength="100"
                                                class="Form_input"
                                                style="height: 84px"
                                              ></textarea>
                                            </div>
                                          </div>
                                          <div class="Composer_mar1">
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter"
                                            >
                                              <button
                                                @click="sendInfoComment(item)"
                                                class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                              >
                                                <span class="keepfly-button-wrap">
                                                  <span class="keepfly-button-content">{{
                                                    $t("message.comment")
                                                  }}</span>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                    ></div>
                                    <div>
                                      <div>
                                        <div class="RepostCommentFeed_mar1">
                                          <div class="wbpro-list">
                                            <div
                                              class="item1"
                                              v-for="oitem in item.commentVos"
                                              :key="oitem"
                                            >
                                              <div class="item1in keepfly-box-flex">
                                                <div>
                                                  <a class="ALink_default">
                                                    <div
                                                      class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                      style="
                                                        width: 2.125rem;
                                                        height: 2.125rem;
                                                      "
                                                    >
                                                      <img
                                                        :src="oitem.fromUser.avatar"
                                                        class="keepfly-avatar-img"
                                                      />
                                                    </div>
                                                  </a>
                                                </div>
                                                <div class="con1 keepfly-box-item-flex">
                                                  <div class="text">
                                                    <a class="ALink_default">{{
                                                      oitem.fromUser.nickname
                                                    }}</a>
                                                    <a
                                                      class="ALink_none"
                                                      target="_blank"
                                                    ></a
                                                    >:
                                                    <span v-if="oitem.contentAr">{{
                                                      oitem.contentAr
                                                    }}</span>
                                                    <span v-if="oitem.contentEn">{{
                                                      oitem.contentEn
                                                    }}</span>
                                                    <span v-if="oitem.contentJa">{{
                                                      oitem.contentJa
                                                    }}</span>
                                                    <span v-if="oitem.contentKo">{{
                                                      oitem.contentKo
                                                    }}</span>
                                                    <span v-if="oitem.contentRu">{{
                                                      oitem.contentRu
                                                    }}</span>
                                                    <span v-if="oitem.contentZh">{{
                                                      oitem.contentZh
                                                    }}</span>
                                                  </div>
                                                  <div
                                                    class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                                  >
                                                    <div>
                                                      {{ oitem.createDate }}
                                                    </div>
                                                    <div class="opt keepfly-box-flex">
                                                      <div
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter optHover"
                                                      >
                                                        <alert-circle-outline
                                                          style="width: 22px"
                                                          class="keepfly-font keepfly-font--report"
                                                        ></alert-circle-outline>
                                                      </div>
                                                      <div
                                                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                      >
                                                        <button
                                                          class="keepfly-like-main IconList_likebox"
                                                          :title="$t('message.praise')"
                                                          @click="
                                                            commentVoslikeClick(oitem)
                                                          "
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style="width: 22px"
                                                            class="keepfly-like-iconWrap"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 32 32"
                                                          >
                                                            <path
                                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                          <span
                                                            class="keepfly-like-count"
                                                            >{{
                                                              oitem.lnum == null
                                                                ? 0
                                                                : oitem.lnum
                                                            }}</span
                                                          >
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                        ></div>
                                        <a
                                          class="ALink_default"
                                          @click="morecommentVos(item)"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                          >
                                            <div
                                              v-if="
                                                item.commentVos != null &&
                                                item.commentVos.length < item.total
                                              "
                                              style="color: #ccc"
                                            >
                                              {{ $t("message.more") }}
                                            </div>
                                            <div style="color: #ccc" v-else>
                                              {{ $t("message.tips74") }}
                                            </div>
                                            <i
                                              class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                            ></i>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <n-pagination
                          v-model:page="currBeautifulPage"
                          size="medium"
                          :page-count="bpages"
                          :page-slot="7"
                          @update-page="onDataBeautifulChange"
                        />
                      </div>
                      <div v-else>
                        <div style="background: #fff; padding: 40px 10px">
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab4Show == 3">
          <div class="home">
            <div>
              <div>
                <div class="Scroll_container">
                  <div class="Scroll_wrap">
                    <div>
                      <div
                        class="vue-recycle-scroller ready page-mode direction-vertical"
                        id="scroller"
                        v-if="systemArr.length > 0"
                      >
                        <div
                          class="vue-recycle-scroller__item-wrapper"
                          v-for="item in systemArr"
                          :key="item"
                        >
                          <div>
                            <div class="wbpro-scroller-item">
                              <div
                                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap MessageCmt_wrap"
                              >
                                <div class="keepfly-box-flex MessageCmt_item1">
                                  <div>
                                    <a class="ALink_default">
                                      <div
                                        class="keepfly-avatar-main keepfly-avatar-hover"
                                        style="width: 3.125rem; height: 3.125rem"
                                      >
                                        <img
                                          src="../../assets/systemuser.jpg"
                                          class="keepfly-avatar-img"
                                        />
                                        <div class="keepfly-avatar-hoverMask"></div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="keepfly-box-item-flex MessageCmt_con1">
                                    <div class="MessageCmt_h3">
                                      <a class="ALink_default">{{
                                        $t("message.system_notification")
                                      }}</a>
                                    </div>
                                    <div class="MessageCmt_from">
                                      <a>{{ item.createTime }}</a>
                                    </div>
                                  </div>
                                </div>
                                <div class="MessageCmt_repeatbox MessageCmt_repeatbgbox">
                                  <div
                                    class="MessageCmt_wbtext MessageCmt_replyComment"
                                    @click="checknoticeview(item)"
                                  >
                                    <span>{{ item.noticeTitle }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <n-pagination
                          v-model:page="currSystemPage"
                          size="medium"
                          :page-count="spages"
                          :page-slot="7"
                          @update-page="onDataSystemChange"
                        />
                      </div>
                      <div v-else>
                        <div style="background: #fff; padding: 40px 10px">
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_full" v-if="navTab4Show == 4">
          <div class="main">
            <Contact @set-contact="set" />
            <Dialog :contacto="contacto" ref="childComp" />
          </div>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 652px">
            <div class="Side_sideBox Side_posSticky" style="top: 63px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div>
                      <div
                        class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                      >
                        <div
                          class="f14 cla keepfly-box-item-flex"
                          style="align-self: center"
                        >
                          Keepfly {{ $t("message.hot_search") }}
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter"
                          @click="refreshlist"
                        >
                          <refresh
                            class="keepfly-font f14 clb keepfly-font--refresh"
                            style="width: 17px"
                          ></refresh>
                          <span class="f12 clb">{{ $t("message.click_refresh") }}</span>
                        </div>
                      </div>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <n-spin :show="hotshow">
                        <div
                          class="wbpro-side-card7"
                          v-for="(item, index) in hotmicroblogs"
                          :key="item"
                        >
                          <div class="wbpro-side-panel">
                            <a class="ALink_default" target="_blank">
                              <div class="con keepfly-box-flex keepfly-box-alignCenter">
                                <!-- <div class="rank top" v-if="index == 0">
                                  <svg
                                    class="keepfly-font keepfly-font--top2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 0 0-12.6 0l-112 141.7a7.98 7.98 0 0 0 6.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div> -->
                                <div class="rank num" v-if="index == 0">1</div>
                                <div class="rank num" v-if="index == 1">2</div>
                                <div class="rank num" v-if="index == 2">3</div>
                                <div class="rank" v-if="index >= 3">{{ index + 1 }}</div>
                                <div class="wbpro-textcut f12 cla">
                                  <span v-if="item.topicTitle">{{
                                    item.topicTitle
                                  }}</span>
                                </div>
                              </div>
                              <div class="keepfly-divider-main keepfly-divider-x"></div>
                            </a>
                          </div>
                        </div>
                      </n-spin>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <a class="ALink_default">
                        <button
                          @click="joinHot"
                          class="wbpro-side-btn f12 clb keepfly-button-main keepfly-button-simple keepfly-button-default keepfly-button-m keepfly-button-round keepfly-button-fluid"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              {{ $t("message.tips26") }}
                            </span>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="CopyRight_contentItem">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span class="CopyRight_contentItem"
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="noticeShow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.system_notification')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      {{ noticeInfo.noticeContent }}
      <template #footer>
        <div style="text-align: right">
          {{ $t("message.created_by") }}：{{ noticeInfo.createBy }}<br />
          {{ $t("message.creation_time") }}：{{ noticeInfo.createTime }}
        </div>
      </template>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import bus from "../../uilts/bus.js";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import Contact from "../../components/ContactView";
import Dialog from "../../components/DialogView";
import {
  ChatbubbleEllipsesOutline,
  ChatboxEllipsesOutline,
  NotificationsOutline,
  Refresh,
  ChatbubblesOutline,
} from "@vicons/ionicons5";
import {
  NSpin,
  NImageGroup,
  NSpace,
  NImage,
  NModal,
  useMessage,
  NEmpty,
  NPagination,
} from "naive-ui";
export default defineComponent({
  name: "MessageView",
  components: {
    ChatbubbleEllipsesOutline,
    ChatboxEllipsesOutline,
    NotificationsOutline,
    Refresh,
    NSpin,
    NImageGroup,
    NSpace,
    NImage,
    NModal,
    NEmpty,
    ChatbubblesOutline,
    Dialog,
    Contact,
    NPagination,
  },
  props: ["navnum"],
  setup(props, context) {
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const message = useMessage();
    const navTab4Show = ref(1);
    const setNavTab4 = (e) => {
      navTab4Show.value = e;
      if (e == 1) {
        getevaluatekeepflylist();
      } else if (e == 2) {
        getbeautifulkeepflylist();
      }
      window.scrollTo(0, 0);
    };
    const infodata = ref({});
    const kukesystemInfo = ref({});
    const interval = ref(null);
    const mnum = ref(0);
    const mlnum = ref(0);
    const sxnum = ref(0);
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      if (props.navnum == 4) {
        navTab4Show.value = 4;
      }
      gethotmicrobloglist();
      getevaluatekeepflylist();
      getsystemkeepflylist();
      window.addEventListener("scroll", handleScroll);
      if (JSON.parse(window.localStorage.getItem("sendTohuati"))) {
        navTab4Show.value = window.localStorage.getItem("sendTohuati");
      }
      if (navTab4Show.value == 4) {
        window.localStorage.setItem("sendTohuati", null);
      }
      interval.value = setInterval(() => {
        API({
          url: "/kuke/api/user/getmessagetotal",
          method: "POST",
        }).then((result) => {
          if (result.data) {
            mnum.value = result.data.data.mnum;
            mlnum.value = result.data.data.mlnum;
            sxnum.value = result.data.data.sxnum;
          }
        });
      }, 1000);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(interval.value);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      // let clientHeight = document.documentElement.clientHeight;
      // let scrollHeight = document.body.scrollHeight;
      // let scrollTopo = document.documentElement.scrollTop;
      // let distance = 30;
      // if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
      //   if (flat0.value) {
      //     flat0.value = false;
      //     if (navTab4Show.value == 1) {
      //       if (currEvaluatePage.value < epages.value) {
      //         ++currEvaluatePage.value;
      //         getevaluatekeepflylist();
      //       }
      //     } else if (navTab4Show.value == 2) {
      //       if (currBeautifulPage.value < bpages.value) {
      //         ++currBeautifulPage.value;
      //         getbeautifulkeepflylist();
      //       }
      //     }
      //   }
      // }
    };
    const currPage = ref(1);
    const pageSize = ref(8);
    const hotmicroblogs = ref([]);
    const gethotmicrobloglist = () => {
      hotshow.value = true;
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/gethotmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        hotmicroblogs.value = arr;
        hotshow.value = false;
      });
    };
    const refreshlist = () => {
      gethotmicrobloglist();
    };
    const hotshow = ref(false);
    const joinHot = () => {
      context.emit("eventParent", 2);
      bus.emit("sendToBrother", 2);
      window.localStorage.setItem("sendTohuati", 2);
    };

    const currEvaluatePage = ref(1);
    const pageEvaluateSize = ref(4);
    const evaluateArr = ref([]);
    const epages = ref(0);
    //评价的keepfly
    const getevaluatekeepflylist = () => {
      let param = {
        currPage: currEvaluatePage.value,
        pageSize: pageEvaluateSize.value,
      };
      API({
        url: "/kuke/api/user/getevaluatekeepflylist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          if (arr[i].isRead == 0) {
            qrr.push(arr[i].cid);
          }
        }
        evaluateArr.value = arr;
        epages.value = result.data.data.pages;

        setEvaluateView(qrr);
      });
    };
    const setEvaluateView = (e) => {
      if (e.length == 0) {
        return false;
      }
      let params = {
        tags: e,
      };
      API({
        url: "/kuke/api/user/setEvaluateView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then(() => {});
    };
    const currBeautifulPage = ref(1);
    const pageBeautifulSize = ref(4);
    const beautifulArr = ref([]);
    const bpages = ref(0);
    //点赞的keepfly
    const getbeautifulkeepflylist = () => {
      let param = {
        currPage: currBeautifulPage.value,
        pageSize: pageBeautifulSize.value,
      };
      API({
        url: "/kuke/api/user/getbeautifulkeepflylist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          if (arr[i].isRead == 0) {
            qrr.push(arr[i].cid);
          }
        }
        beautifulArr.value = arr;
        bpages.value = result.data.data.pages;
        flat0.value = true;
        setBeautifulView(qrr);
      });
    };
    const setBeautifulView = (e) => {
      if (e.length == 0) {
        return false;
      }
      let params = {
        tags: e,
      };
      API({
        url: "/kuke/api/user/setBeautifulView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then(() => {});
    };
    const currSystemPage = ref(1);
    const pageSystemSize = ref(10);
    const systemArr = ref([]);
    const spages = ref(0);
    //系统通知
    const getsystemkeepflylist = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currSystemPage.value,
        pageSize: pageSystemSize.value,
      };
      API({
        url: "/kuke/api/user/getsystemkeepflylist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        systemArr.value = arr;
        spages.value = result.data.data.pages;
      });
    };
    const noticeShow = ref(false);
    const noticeInfo = ref({});
    const checknoticeview = (e) => {
      noticeInfo.value = e;
      noticeShow.value = true;
    };
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const getCommentView = (e, i) => {
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.error(i18n.global.t("message.tips172"));
        }
      });
    };
    const contacto = ref({});
    const childComp = ref();

    const set = (e) => {
      if (e.index == 0) {
        childComp.value.delChatData();
      } else {
        contacto.value = e;
        childComp.value.getChatData(contacto.value);
      }
    };
    function filters(txt) {
      if (txt) {
        for (let i = 0; i < emoticon.length; i++) {
          if (
            txt.includes("[" + emoticon[i].value + "]") ||
            txt.includes(" [ " + emoticon[i].value + " ] ")
          ) {
            txt = txt.replace(
              "[" + emoticon[i].value + "]",
              '<img src="' + emoticon[i].path + '" />'
            );
          }
        }
      }

      return txt;
    }
    const onDataEvaluateChange = (val) => {
      currEvaluatePage.value = val;
      getevaluatekeepflylist();
    };
    const onDataBeautifulChange = (val) => {
      currBeautifulPage.value = val;
      getbeautifulkeepflylist();
    };
    const onDataSystemChange = (val) => {
      currSystemPage.value = val;
      getsystemkeepflylist();
    };
    return {
      spages,
      onDataSystemChange,
      onDataBeautifulChange,
      bpages,
      epages,
      onDataEvaluateChange,
      filters,
      childComp,
      mnum,
      mlnum,
      sxnum,
      set,
      contacto,
      kukesystemInfo,
      sendInfoComment,
      commentVoslikeClick,
      morecommentVos,
      infodata,
      getCommentView,
      likeClick,
      noticeInfo,
      noticeShow,
      checknoticeview,
      getsystemkeepflylist,
      getbeautifulkeepflylist,
      getevaluatekeepflylist,
      systemArr,
      beautifulArr,
      evaluateArr,
      joinHot,
      refreshlist,
      hotshow,
      hotmicroblogs,
      navTab4Show,
      setNavTab4,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
<style>
.main {
  height: 648px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
</style>
