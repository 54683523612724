<template>
  <div
    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
  >
    <div>
      <div class="Scroll_container">
        <div class="Scroll_wrap">
          <div>
            <div
              class="vue-recycle-scroller ready page-mode direction-vertical"
              id="scroller"
            >
              <div class="vue-recycle-scroller__item-wrapper">
                <div class="" v-if="lookForList.length > 0">
                  <div
                    class="wbpro-scroller-item"
                    v-for="(item, index) in lookForList"
                    :key="item"
                  >
                    <div class="FollowFeed_wrap">
                      <div
                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween FollowFeed_item"
                      >
                        <a class="ALink_none UserCard_item">
                          <div class="keepfly-box-flex keepfly-box-alignCenter">
                            <div
                              class="keepfly-box-item-flex UserCard_con UserCard_f12"
                              style="align-self: center"
                            >
                              <div class="UserCard_cla UserCard_fb wbpro-textcut">
                                {{ $t("message.clue") }}:<span>{{ item.content }}</span>
                                <a class="ALink_none" target="_blank"></a>
                              </div>
                              <div class="UserCard_clb wbpro-textcut">
                                {{ $t("message.created_by") }}:{{ item.nickname }}
                              </div>
                              <div class="UserCard_clb">
                                {{ $t("message.creation_time") }}:{{ item.createTime }}
                              </div>
                            </div>
                          </div>
                        </a>
                        <div class="UserCard_right UserCard_followBtn">
                          <div class="keepfly-pop-wrap">
                            <span class="keepfly-pop-ctrl">
                              <button
                                @click="lookForInfoView(item)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                              >
                                <span class="keepfly-button-wrap">
                                  <span class="keepfly-button-content">
                                    <!-- {{ $t("message.view") }} -->
                                    <eye style="width: 19px"></eye>
                                  </span>
                                </span>
                              </button>
                              <p style="display: none">{{ index }}</p>
                              <button
                                @click="lookForLydelete(item.id, index)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                              >
                                <span class="keepfly-button-wrap">
                                  <span class="keepfly-button-content">
                                    <!-- {{ $t("message.delete") }} -->
                                    <svg
                                      style="width: 18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 20 20"
                                    >
                                      <g fill="none">
                                        <path
                                          d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                                          fill="currentColor"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <n-pagination
                    v-model:page="currLookForPage"
                    size="medium"
                    :page-count="lpages"
                    :page-slot="7"
                    @update-page="onDataLookForChange"
                  />
                </div>
                <div v-else style="background: #fff; padding: 40px 10px">
                  <n-empty :description="$t('message.none')">
                    <template #extra></template>
                  </n-empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <n-modal
      v-model:show="lookForshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.registration_of_seeking_relatives')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        :model="lookForModel"
        label-placement="left"
        :label-width="130"
        require-mark-placement="right-hanging"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.front_cover')" path="inputValue">
          <img
            :src="lookForModel.frontCover"
            v-if="lookForModel.frontCover"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
        </n-form-item>
        <n-form-item :label="$t('message.name_of_missing_person')" path="inputValue">
          {{ lookForModel.nameOfMissingPerson }}
        </n-form-item>
        <n-form-item :label="$t('message.gender')" path="inputValue">
          {{ lookForModel.gender }}
        </n-form-item>
        <n-form-item :label="$t('message.age')" path="inputValue">
          {{ lookForModel.age }}
        </n-form-item>
        <n-form-item :label="$t('message.debatable_time')" path="inputValue">
          {{ lookForModel.debatableTime }}
        </n-form-item>
        <n-form-item :label="$t('message.location_of_disappearance')" path="inputValue">
          {{ lookForModel.locationOfDisappearance }}
        </n-form-item>
        <n-form-item :label="$t('message.feature_description')" path="inputValue">
          {{ lookForModel.featureDescription }}
        </n-form-item>
        <n-form-item :label="$t('message.photograph')" path="inputValue">
          <img
            :src="lookForModel.photograph"
            v-if="lookForModel.photograph"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
        </n-form-item>
        <n-form-item :label="$t('message.contact_number')" path="inputValue">
          {{ lookForModel.contactNumber }}
        </n-form-item>
      </n-form>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import API from "../../uilts/request";
import { Eye } from "@vicons/ionicons5";
import i18n from "../../i18n/index";

import { NEmpty, NModal, NForm, NFormItem, useMessage, NPagination } from "naive-ui";
export default defineComponent({
  name: "JoinView",
  components: {
    NEmpty,
    NModal,
    NForm,
    NFormItem,
    Eye,
    NPagination,
  },
  setup() {
    const message = useMessage();
    onMounted(() => {
      console.log("joinview");
      getLookForList();
      // window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      // window.removeEventListener("scroll", handleScroll);
    });
    // const flat0 = ref(true);
    // const handleScroll = () => {
    //   let clientHeight = document.documentElement.clientHeight;
    //   let scrollHeight = document.body.scrollHeight;
    //   let scrollTopo = document.documentElement.scrollTop;
    //   let distance = 30;
    //   if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
    //     if (flat0.value) {
    //       flat0.value = false;
    //       if (currLookForPage.value < lpages.value) {
    //         ++currLookForPage.value;
    //         getLookForList();
    //       }
    //     }
    //   }
    // };
    const currLookForPage = ref(1);
    const pageLookForSize = ref(10);
    const lookForList = ref([]);
    const lpages = ref(0);
    const getLookForList = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currLookForPage.value,
        pageSize: pageLookForSize.value,
      };
      API({
        url: "/kuke/api/kuke-look-for/getJoinLookForList",
        method: "get",
        params: param,
      }).then((result) => {
        let arr = result.data.data.records;
        for (let i = 0; i < arr.length; i++) {
          lookForList.value.unshift(arr[i]);
        }
        lpages.value = result.data.data.pages;
      });
    };
    const lookForModel = ref({
      nameOfMissingPerson: "",
      gender: 1,
      age: "",
      locationOfDisappearance: "",
      featureDescription: "",
      photograph: "",
      contactNumber: "",
      frontCover: "",
      debatableTimeo: null,
      debatableTime: "",
    });
    const lookForshow = ref(false);
    const lookforinfolist = ref({});
    const lookForInfoView = (e) => {
      let param = {
        id: e.lid,
      };
      API({
        url: "/kuke/api/kuke-look-for/getLookForInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        lookForModel.value = result.data.data;
      });
      lookForshow.value = true;
    };
    const lookForLydelete = (e, i) => {
      API({
        url: "/kuke/api/kuke-look-for/lookForLydelete",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          lookForList.value.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const onDataLookForChange = (val) => {
      currLookForPage.value = val;
      getLookForList();
    };
    return {
      onDataLookForChange,
      lookForLydelete,
      lookForshow,
      lookForModel,
      lookForInfoView,
      lookforinfolist,
      lookForList,
      lpages,
      getLookForList,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
