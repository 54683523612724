<template>
  <n-message-provider>
    <router-view />
    <div class="service" @click="service">
      <div class="s-back-top">
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
        >
          <path
            d="M384 224v184a40 40 0 0 1-40 40H104a40 40 0 0 1-40-40V168a40 40 0 0 1 40-40h167.48"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          ></path>
          <path
            d="M459.94 53.25a16.06 16.06 0 0 0-23.22-.56L424.35 65a8 8 0 0 0 0 11.31l11.34 11.32a8 8 0 0 0 11.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38z"
            fill="currentColor"
          ></path>
          <path
            d="M399.34 90L218.82 270.2a9 9 0 0 0-2.31 3.93L208.16 299a3.91 3.91 0 0 0 4.86 4.86l24.85-8.35a9 9 0 0 0 3.93-2.31L422 112.66a9 9 0 0 0 0-12.66l-9.95-10a9 9 0 0 0-12.71 0z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <div class="tools" style="display: none" @click="toTop">
      <div class="s-back-top">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g
            stroke="#626675"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M12 4.952l-6.5 6.951M12 4.952l6.5 6.951M12 17.816v1.5M12 4.952v10.599"
            ></path>
          </g>
        </svg>
      </div>
    </div>
    <n-modal
      v-model:show="showModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.report')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div style="color: red">{{ tipstxt }}</div>
      <n-form
        :model="model"
        label-placement="left"
        label-width="auto"
        require-mark-placement="right-hanging"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.reporting_type')">
          <n-checkbox-group :value="model.reportTypeo" @update:value="handleUpdateValue">
            <n-space item-style="display: flex;" align="center">
              <n-checkbox :value="$t('message.tips76')" :label="$t('message.tips76')" />
              <n-checkbox :value="$t('message.tips77')" :label="$t('message.tips77')" />
              <n-checkbox :value="$t('message.tips78')" :label="$t('message.tips78')" />
              <n-checkbox :value="$t('message.tips79')" :label="$t('message.tips79')" />
              <n-checkbox :value="$t('message.tips80')" :label="$t('message.tips80')" />
              <n-checkbox :value="$t('message.tips81')" :label="$t('message.tips81')" />
              <n-checkbox :value="$t('message.tips82')" :label="$t('message.tips82')" />
              <n-checkbox :value="$t('message.tips83')" :label="$t('message.tips83')" />
              <n-checkbox :value="$t('message.tips84')" :label="$t('message.tips84')" />
              <n-checkbox :value="$t('message.other')" :label="$t('message.other')" />
            </n-space>
          </n-checkbox-group>
        </n-form-item>
        <n-form-item :label="$t('message.reported_image')">
          <img
            :src="model.imgUrl"
            v-if="model.imgUrl"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djuqContentFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="uqContent_file"
            @change="selectuqContentInfo"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.report_content')">
          <n-input
            v-model:value="model.reason"
            status="warning"
            type="textarea"
            placeholder=""
          />
        </n-form-item>
        <n-form-item :label="$t('message.reporting_link')">
          <n-input v-model:value="model.url" status="warning" placeholder="" />
        </n-form-item>
      </n-form>
      <div style="display: flex; justify-content: flex-end">
        <n-button round type="warning" @click="handleValidateButtonClick">
          {{ $t("message.submit") }}
        </n-button>
      </div>
    </n-modal>
  </n-message-provider>
</template>
<script>
import { defineComponent, ref } from "vue";
import API from "./uilts/request";
import i18n from "./i18n/index";
import {
  NMessageProvider,
  NModal,
  NForm,
  NFormItem,
  NButton,
  NInput,
  NCheckboxGroup,
  NSpace,
  NCheckbox,
} from "naive-ui";
import { client } from "./uilts/alioss";
import { useRouter } from "vue-router";
const themeOverrides = {
  Select: {
    peers: {
      InternalSelection: {
        textColor: "#FF0000",
      },
      InternalSelectMenu: {
        borderRadius: "6px",
      },
    },
  },
};
export default defineComponent({
  components: {
    NMessageProvider,
    NModal,
    NForm,
    NFormItem,
    NButton,
    NInput,
    NCheckboxGroup,
    NSpace,
    NCheckbox,
  },
  setup() {
    const toTop = () => {
      document.documentElement.scrollTop = 0;
    };
    const model = ref({});
    const uqContent_file = ref();
    const djuqContentFileView = () => {
      uqContent_file.value.value = "";
      uqContent_file.value.click();
    };
    const selectuqContentInfo = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          model.value.imgUrl = "http://img.keepfly.net/" + fileName;
        });
    };
    const tipstxt = ref("");
    const showModal = ref(false);
    const handleValidateButtonClick = () => {
      API({
        url: "/kuke/api/user/addreportInfo",
        method: "POST",
        data: model.value,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      }).then((result) => {
        if (result.data.code == 200) {
          tipstxt.value = i18n.global.t("message.tips42");
          setTimeout(function () {
            tipstxt.value = "";
          }, 4000);
          model.value = {
            imgUrl: "",
            reason: "",
            url: "",
            reportTypeo: [],
          };
        } else {
          tipstxt.value = i18n.global.t("message.tips43");
        }
      });
    };
    const router = useRouter();
    const service = () => {
      if (!window.localStorage.getItem("token")) {
        router.push({
          name: "login",
          params: {
            id: 1,
          },
        });
      } else {
        showModal.value = true;
      }
    };
    return {
      handleUpdateValue(value) {
        model.value.reportTypeo = value;
      },
      service,
      tipstxt,
      handleValidateButtonClick,
      selectuqContentInfo,
      uqContent_file,
      djuqContentFileView,
      model,
      toTop,
      themeOverrides,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
      showModal,
    };
  },
});
let totop = document.getElementsByClassName("tools");
window.onscroll = function () {
  let high = document.documentElement.scrollTop || document.body.scrollTop;
  // 获取浏览器卷去的高度
  if (high >= 600) {
    //当高度大于等于1200px后盒子出现，注意不要带单位
    totop[0].style.display = "flex";
  } else {
    totop[0].style.display = "none";
  }
};
</script>
<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: text;
}
body {
  overflow-x: hidden;
  background: #f5f5f5;
}
.tools {
  position: fixed;
  right: 40px;
  bottom: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(51, 54, 57);
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 22px;
  height: 44px;
  line-height: 0px;
  min-width: 44px;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.service {
  position: fixed;
  z-index: 99;
  right: 40px;
  bottom: 94px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(51, 54, 57);
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 22px;
  height: 44px;
  line-height: 0px;
  min-width: 44px;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.n-upload-trigger.n-upload-trigger--image-card {
  width: 96px;
  height: 96px;
}
.n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger {
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
