<template>
  <div>
    <div class="Scroll_container" v-if="indexdetails == 1">
      <div class="Scroll_wrap">
        <div>
          <div
            style="padding: 8px 0"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            <div
              @click="returnTop"
              class="keepfly-box-flex keepfly-box-alignCenter Bar_left Bar_hand"
            >
              <chevron-back-outline
                style="width: 24px"
                class="keepfly-font keepfly-font--angleLeft Bar_back"
              ></chevron-back-outline>
              <div class="Bar_title">{{ $t("message.return") }}</div>
              <h2 class="Bar_title" style="padding-left: 35%">
                {{ $t("message.tips87") }}
              </h2>
            </div>
          </div>
          <div
            class="vue-recycle-scroller ready page-mode direction-vertical"
            id="scroller"
          >
            <div class="vue-recycle-scroller__item-wrapper" v-if="myAskList.length > 0">
              <div v-for="(item, index) in myAskList" :key="item">
                <div class="wbpro-scroller-item">
                  <div
                    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                  >
                    <div class="HotTopic_item">
                      <div class="keepfly-box-flex">
                        <div class="keepfly-box-item-flex">
                          <div class="keepfly-box-flex keepfly-box-alignCenter">
                            <div class="HotTopic_doticon"></div>
                            <a
                              class="ALink_default HotTopic_tit_ask"
                              :title="item.question"
                              @click="myquestionView(item, 2)"
                              >{{ item.question }}</a
                            >
                            <div>
                              <button
                                @click="deleteAnswerView(item, index)"
                                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s"
                              >
                                <span class="keepfly-button-wrap">
                                  <span class="keepfly-button-content">
                                    {{ $t("message.delete") }}
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="background: #fff; padding: 40px 10px">
                <n-empty :description="$t('message.none')">
                  <template #extra></template>
                </n-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Scroll_container" v-if="indexdetails == 2">
      <div class="Scroll_wrap">
        <div>
          <div
            style="padding: 8px 0"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            <div
              @click="indexdetails = 1"
              class="keepfly-box-flex keepfly-box-alignCenter Bar_left Bar_hand"
            >
              <chevron-back-outline
                style="width: 24px"
                class="keepfly-font keepfly-font--angleLeft Bar_back"
              ></chevron-back-outline>
              <div class="Bar_title">{{ $t("message.return") }}</div>
              <h2 class="Bar_title" style="padding-left: 35%">
                {{ $t("message.tips87") }}
              </h2>
            </div>
          </div>
          <div
            style="padding: 8px 10px"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            {{ $t("message.question") }}：{{ questionInfo.question }}
            <div style="display: inline-block; float: right">
              <button
                @click="deleteAnswerView(questionInfo, -1)"
                class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn_s_3J5Ve"
              >
                <span class="keepfly-button-wrap">
                  <span class="keepfly-button-content">
                    {{ $t("message.delete") }}
                  </span>
                </span>
              </button>
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="vue-recycle-scroller ready page-mode direction-vertical">
            <div class="vue-recycle-scroller__item-wrapper">
              <div v-if="AskAnswerlist.length > 0">
                <div
                  class="wbpro-scroller-item"
                  v-for="(item, index) in AskAnswerlist"
                  :key="item"
                >
                  <article
                    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                    style="border-radius: 4px"
                  >
                    <div class="Feed_body">
                      <div style="position: relative">
                        <div class="detail_ogText wbpro-feed" style="position: relative">
                          <div class="detail_wbtext" style="padding-right: 42px">
                            {{ item.answer }}
                          </div>
                          <div style="position: absolute; top: 0; right: 10px">
                            {{ item.status == 1 ? $t("message.recommendo") : "" }}
                          </div>
                        </div>
                        <n-popconfirm
                          :positive-text="$t('message.confirm')"
                          :negative-text="$t('message.call_off')"
                          @positive-click="handlePositiveClick(item, index)"
                        >
                          <template #trigger>
                            <n-icon style="cursor: pointer"
                              ><ribbon-outline style="width: 24px; height: 24px"
                            /></n-icon>
                          </template>
                          {{ $t("message.tips88") }}
                        </n-popconfirm>
                      </div>
                      <header class="keepfly-box-flex" style="margin-bottom: 8px">
                        <div
                          class="keepfly-box-item-flex head_main"
                          style="text-align: right"
                        >
                          <div
                            class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                          >
                            <div class="head_nick">
                              <a class="ALink_default head_cut head_name">
                                <span :title="item.nickname">{{ item.nickname }}</span>
                              </a>
                            </div>
                            <div class="head-info_info">
                              <a :title="item.createTime" class="head-info_time">
                                {{ item.createTime }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <a class="ALink_default">
                          <div
                            class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                            style="width: 2.125rem; height: 2.125rem"
                          >
                            <img :src="item.avatar" class="keepfly-avatar-img" />
                            <div class="keepfly-avatar-hoverMask"></div>
                          </div>
                        </a>
                      </header>
                    </div>
                  </article>
                </div>
              </div>
              <div v-else>
                <div style="background: #fff; padding: 40px 10px">
                  <n-empty :description="$t('message.none')">
                    <template #extra></template>
                  </n-empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import { NEmpty, NIcon, NPopconfirm, useMessage } from "naive-ui";
import { ChevronBackOutline, RibbonOutline } from "@vicons/ionicons5";
export default defineComponent({
  name: "MyAskView",
  components: {
    ChevronBackOutline,
    NEmpty,
    NIcon,
    RibbonOutline,
    NPopconfirm,
  },
  setup(props, { emit }) {
    const message = useMessage();
    const myAskList = ref([]);
    const currPage = ref(1);
    const pageSize = ref(10);
    const apages = ref(0);
    onMounted(() => {
      getMyAskList();
      indexdetails.value = 1;
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (indexdetails.value == 1) {
            if (currPage.value < apages.value) {
              ++currPage.value;
              getMyAskList();
            }
          } else if (indexdetails.value == 2) {
            if (currAskAnswerPage.value < aapages.value) {
              ++currAskAnswerPage.value;
              getmyAskAnswerlist(questionInfo.value.id);
            }
          }
        }
      }
    };
    const getMyAskList = () => {
      let param = {
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/getMyAskList",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          myAskList.value.unshift(arr[i]);
        }
        apages.value = result.data.data.pages;
      });
    };
    const indexdetails = ref(1);
    const questionInfo = ref({});
    const myquestionView = (e, i) => {
      questionInfo.value = e;
      indexdetails.value = i;
      currAskAnswerPage.value = 1;
      AskAnswerlist.value = [];
      flat0.value = true;
      getmyAskAnswerlist(e.id);
    };
    const currAskAnswerPage = ref(1);
    const pageAskAnswerSize = ref(8);
    const AskAnswerlist = ref([]);
    const aapages = ref(0);
    const recommend_i = ref(0);
    const getmyAskAnswerlist = (e) => {
      let param = {
        currPage: currAskAnswerPage.value,
        pageSize: pageAskAnswerSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getAskAnswerlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].status == 1) {
            recommend_i.value = i;
          }
          AskAnswerlist.value.push(arr[i]);
        }
        aapages.value = result.data.data.pages;
      });
    };
    const returnTop = () => {
      emit("clickChild", 1);
    };
    const handlePositiveClick = (e, i) => {
      if (e.status != 1) {
        let param = {
          id: e.id,
        };
        API({
          url: "/kuke/api/user/setAskAnswerRecommend",
          method: "POST",
          data: param,
        }).then((result) => {
          if (result.data.code == 200) {
            e.status = 1;
            AskAnswerlist.value[recommend_i.value].status = 0;
            recommend_i.value = i;
            message.success(i18n.global.t("message.tips89"));
          } else {
            message.error(i18n.global.t("message.tips90"));
          }
        });
      }
    };
    const deleteAnswerView = (e, i) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/deleteAnswerInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          if (i > -1) {
            myAskList.value.splice(i, 1);
          }
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    return {
      deleteAnswerView,
      handlePositiveClick,
      returnTop,
      questionInfo,
      myquestionView,
      AskAnswerlist,
      indexdetails,
      myAskList,
      getMyAskList,
    };
  },
});
</script>
