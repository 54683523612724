<template>
  <div>
    <div class="Scroll_container" v-if="indexdetails == 1">
      <div class="Scroll_wrap">
        <div>
          <div
            style="padding: 8px 0"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            <div
              @click="returnTop"
              class="keepfly-box-flex keepfly-box-alignCenter Bar_left Bar_hand"
            >
              <chevron-back-outline
                style="width: 24px"
                class="keepfly-font keepfly-font--angleLeft Bar_back"
              ></chevron-back-outline>
              <div class="Bar_title">{{ $t("message.return") }}</div>
              <h2 class="Bar_title" style="padding-left: 35%">
                {{ $t("message.my_answer") }}
              </h2>
            </div>
          </div>
          <div
            class="vue-recycle-scroller ready page-mode direction-vertical"
            id="scroller"
          >
            <div
              class="vue-recycle-scroller__item-wrapper"
              v-if="myAnswerAskList.length > 0"
            >
              <div v-for="item in myAnswerAskList" :key="item">
                <div class="wbpro-scroller-item">
                  <div
                    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                  >
                    <div class="HotTopic_item">
                      <div class="keepfly-box-flex">
                        <div class="keepfly-box-item-flex">
                          <div class="keepfly-box-flex keepfly-box-alignCenter">
                            <div class="HotTopic_doticon"></div>
                            <a
                              class="ALink_default HotTopic_tit_ask"
                              :title="item.question"
                              @click="myquestionView(item, 2)"
                              >{{ item.question }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="background: #fff; padding: 40px 10px">
                <n-empty :description="$t('message.none')">
                  <template #extra></template>
                </n-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Scroll_container" v-if="indexdetails == 2">
      <div class="Scroll_wrap">
        <div>
          <div
            style="padding: 8px 0"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            <div
              @click="indexdetails = 1"
              class="keepfly-box-flex keepfly-box-alignCenter Bar_left Bar_hand"
            >
              <chevron-back-outline
                style="width: 24px"
                class="keepfly-font keepfly-font--angleLeft Bar_back"
              ></chevron-back-outline>
              <div class="Bar_title">{{ $t("message.return") }}</div>
              <h2 class="Bar_title" style="padding-left: 35%">
                {{ $t("message.my_answer") }}
              </h2>
            </div>
          </div>
          <div
            style="padding: 8px 10px"
            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
          >
            {{ $t("message.question") }}：{{ questionInfo.question }}
            <div style="clear: both"></div>
          </div>
          <div class="vue-recycle-scroller ready page-mode direction-vertical">
            <div class="vue-recycle-scroller__item-wrapper">
              <div v-if="AnswerAskAnswerlist.length > 0">
                <div
                  class="wbpro-scroller-item"
                  v-for="(item, index) in AnswerAskAnswerlist"
                  :key="item"
                >
                  <article
                    class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                    style="border-radius: 4px"
                  >
                    <div class="Feed_body">
                      <div style="position: relative">
                        <div class="detail_ogText wbpro-feed" style="position: relative">
                          <div class="detail_wbtext" style="padding-right: 42px">
                            {{ item.answer }}
                          </div>
                          <div
                            style="
                              position: absolute;
                              top: 0;
                              right: 10px;
                              cursor: pointer;
                            "
                            @click="deleteAnswer(item, index)"
                          >
                            <!-- {{ $t("message.delete") }} -->
                            <svg
                              style="width: 19px"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 20 20"
                            >
                              <g fill="none">
                                <path
                                  d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                                  fill="currentColor"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <header class="keepfly-box-flex" style="margin-bottom: 8px">
                        <div
                          class="keepfly-box-item-flex head_main"
                          style="text-align: right"
                        >
                          <div
                            class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                          >
                            <div class="head-info_info">
                              <a :title="item.createTime" class="head-info_time">
                                {{ item.createTime }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </header>
                    </div>
                  </article>
                </div>
              </div>
              <div v-else>
                <div style="background: #fff; padding: 40px 10px">
                  <n-empty :description="$t('message.none')">
                    <template #extra></template>
                  </n-empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import { NEmpty, useMessage } from "naive-ui";
import { ChevronBackOutline } from "@vicons/ionicons5";
export default defineComponent({
  name: "MyAnswerView",
  components: {
    ChevronBackOutline,
    NEmpty,
  },
  setup(props, { emit }) {
    const message = useMessage();
    const myAnswerAskList = ref([]);
    const currPage = ref(1);
    const pageSize = ref(10);
    const apages = ref(0);
    onMounted(() => {
      getMyAnswerAskList();
      indexdetails.value = 1;
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const flat0 = ref(true);
    const handleScroll = () => {
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (indexdetails.value == 1) {
            if (currPage.value < apages.value) {
              ++currPage.value;
              getMyAnswerAskList();
            }
          } else if (indexdetails.value == 2) {
            if (currAnswerAskAnswerPage.value < aapages.value) {
              ++currAnswerAskAnswerPage.value;
              getmyAnswerAskAnswerlist(questionInfo.value.id);
            }
          }
        }
      }
    };
    const getMyAnswerAskList = () => {
      let param = {
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/getMyAnswerAskList",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          myAnswerAskList.value.unshift(arr[i]);
        }
        apages.value = result.data.data.pages;
      });
    };
    const indexdetails = ref(1);
    const questionInfo = ref({});
    const myquestionView = (e, i) => {
      questionInfo.value = e;
      indexdetails.value = i;
      currAnswerAskAnswerPage.value = 1;
      AnswerAskAnswerlist.value = [];
      flat0.value = true;
      getmyAnswerAskAnswerlist(e.id);
    };
    const currAnswerAskAnswerPage = ref(1);
    const pageAnswerAskAnswerSize = ref(8);
    const AnswerAskAnswerlist = ref([]);
    const aapages = ref(0);
    const getmyAnswerAskAnswerlist = (e) => {
      let param = {
        currPage: currAnswerAskAnswerPage.value,
        pageSize: pageAnswerAskAnswerSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/user/getAnswerAskAnswerlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          AnswerAskAnswerlist.value.push(arr[i]);
        }
        aapages.value = result.data.data.pages;
      });
    };
    const returnTop = () => {
      emit("clickChild", 1);
    };
    const deleteAnswer = (e, i) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/deleteAnswerAskInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          AnswerAskAnswerlist.value.splice(i, 1);
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    return {
      deleteAnswer,
      returnTop,
      questionInfo,
      myquestionView,
      AnswerAskAnswerlist,
      indexdetails,
      myAnswerAskList,
      getMyAnswerAskList,
    };
  },
});
</script>
