<template>
  <div v-if="JSON.stringify(contact) != '{}' && msgList" class="dialog">
    <div class="top">
      <div class="name">
        {{ contact.nickname }}
      </div>
    </div>
    <div class="middle" style="position: relative" @mouseover="over" @mouseout="out">
      <div v-if="msgList">
        <div v-for="msg in msgList" :key="msg">
          <div
            class="msg"
            :style="
              msg.from === contact.fromId
                ? 'flex-direction: row;'
                : 'flex-direction: row-reverse;'
            "
          >
            <div class="avatar">
              <img alt="" :src="msg.avatar" />
            </div>
            <div v-if="msg.from === contact.fromId" style="flex: 13">
              <div
                v-if="!msg.message.includes('http://img.keepfly.net')"
                class="bubble-msg-left"
                v-html="filters(msg.message)"
                style="margin-right: 75px"
              ></div>
              <div class="bubble-msg-lefto" v-else>
                <n-image height="100" :src="msg.message" />
              </div>
            </div>
            <div v-else style="flex: 13">
              <div
                v-if="!msg.message.includes('http://img.keepfly.net')"
                class="bubble-msg-right"
                v-html="filters(msg.message)"
                style="margin-left: 75px"
              ></div>
              <div class="bubble-msg-righto" v-else>
                <n-image height="100" :src="msg.message" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          color: darkgray;
        "
        v-if="tipsshow"
      >
        {{ tipstxt }}
      </div>
    </div>
    <div class="line"></div>
    <div>
      <div class="keepfly-box-item-inlineBlock Tool_iconitem">
        <div
          class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
          :title="$t('message.emote')"
          @click="emoticonView"
        >
          <happy-outline class="keepfly-font keepfly-font--emoticon"></happy-outline>
          <span class="text">{{ $t("message.emote") }}</span>
        </div>
      </div>
      <div class="keepfly-box-item-inlineBlock Tool_iconitem">
        <div class="keepfly-pop-wrap">
          <span class="keepfly-pop-ctrl">
            <div
              class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
              :title="$t('message.picture')"
              @click="imageView"
            >
              <images-outline class="keepfly-font keepfly-font--image"></images-outline>
              <span class="text abc">{{ $t("message.picture") }}</span>
            </div>
            <input
              type="file"
              accept=".jpg, .jpeg, .bmp, .gif, .png, .heif, .heic"
              multiple="multiple"
              @change="uploadURL"
              ref="FileUpload_file"
              style="display: none"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="wbpro-pos">
      <div>
        <div class="keepfly-pop-wrap Emoticon_box1">
          <span class="keepfly-pop-ctrl"></span>
          <div
            class="keepfly-pop-main keepfly-pop-down keepfly-pop-start"
            v-if="Emoticon_show"
          >
            <div class="Emoticon_tab">
              <div class="keepfly-divider-main keepfly-divider-x"></div>
              <div class="Emoticon_facebox1">
                <div class="u-col-12 emoticon">
                  <div
                    class="keepfly-box-item-inlineBlock"
                    v-for="item in emoticon"
                    :key="item"
                  >
                    <div
                      class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
                      :title="item.title"
                      @click="getemoticontxt(item.value)"
                    >
                      <img :src="item.path" :alt="item.name" class="Emoticon_img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <label>
        <textarea
          class="messageText"
          maxlength="256"
          v-model="msg"
          :placeholder="hint"
          @keydown.enter="sendMsg($event)"
        ></textarea>
      </label>
      <button
        class="send"
        :class="{ emptyText: isEmptyText }"
        :title="$t('message.tips117')"
        @click="sendMsg()"
      >
        <!-- {{ $t("message.send") }} -->
        <svg
          style="width: 22px"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          enable-background="new 0 0 512 512"
          xml:space="preserve"
        >
          <g>
            <path
              fill="#fff"
              d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
            ></path>
          </g>
        </svg>
      </button>
    </div>
  </div>
  <div v-else class="info">
    <div class="msg">{{ $t("message.tips118") }}</div>
  </div>
</template>

<script>
import API from "../uilts/request";
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  watchEffect,
  nextTick,
  getCurrentInstance,
} from "vue";
import { HappyOutline, ImagesOutline } from "@vicons/ionicons5";
import { client } from "../uilts/alioss";
import utils from "../uilts/utils";
import i18n from "../i18n/index";
import { NImage } from "naive-ui";
export default defineComponent({
  name: "DialogView",
  components: {
    HappyOutline,
    ImagesOutline,
    NImage,
  },
  props: {
    contacto: {
      type: Object,
    },
  },
  setup(props) {
    const infodata = ref({});
    const msg = ref("");
    const hint = ref("");
    const socket = ref(null);
    const bubbleMsg = ref("");
    const interval = ref(null);
    const isEmptyText = ref(true);
    const msgList = ref(null);
    const contact = ref(null);
    const flat_1 = ref(true);
    const tipstxt = ref("");
    const tipsshow = ref(false);
    watchEffect(() => {
      contact.value = props.contacto.friendList;
      flat_1.value = true;
    });
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      let url =
        "ws://www.keepfly.net:8888/websocket/" + window.localStorage.getItem("token");
      socket.value = new WebSocket(url);
      socket.value.onmessage = (event) => {
        let ddarr = JSON.parse(event.data);
        msgList.value.push(ddarr);
        let arr = unique(
          JSON.parse(
            window.localStorage.getItem(
              "msgList-" + contact.value.fromId + "-" + contact.value.toId
            )
          ),
          ddarr
        );
        window.localStorage.setItem(
          "msgList-" + contact.value.fromId + "-" + contact.value.toId,
          JSON.stringify(arr)
        );
        const mid = document.querySelector(".middle");
        nextTick(() => {
          mid && (mid.scrollTop = mid.scrollHeight);
          document.querySelector(".messageText").focus();
        });
        API({
          url: "/kuke/api/user/sendprivateletterInfo",
          method: "GET",
          params: {
            id: ddarr.from,
            index: 0,
          },
        }).then(() => {});
      };
      // 模拟外部点击
      document.addEventListener("click", (e) => {
        let arr = [
          "u-col-12 emoticon",
          "keepfly-pop-main keepfly-pop-down keepfly-pop-start",
          "wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "Emoticon_tab",
          "Emoticon_facebox1",
          "text",
        ];
        let flat = false;
        for (let i = 0; i < arr.length; i++) {
          if (
            e.target.className == arr[i] ||
            e.target.className.animVal == "keepfly-font keepfly-font--emoticon"
          ) {
            flat = true;
            break;
          }
        }
        if (flat) {
          Emoticon_show.value = true;
        } else {
          Emoticon_show.value = false;
        }
      });
    });
    const getChatData = (e) => {
      contact.value = e.friendList;
      if (contact.value && contact.value.id) {
        msgList.value = JSON.parse(
          window.localStorage.getItem(
            "msgList-" + contact.value.fromId + "-" + contact.value.toId
          )
        );
        API({
          url: "/kuke/api/kuke-pull/pullMsg",
          method: "POST",
          params: {
            from: window.localStorage.getItem("token"),
            to: contact.value.fromId,
          },
        }).then((result) => {
          if (result.data.rdata) {
            let arr = [];
            if (
              window.localStorage.getItem(
                "msgList-" + contact.value.fromId + "-" + contact.value.toId
              )
            ) {
              arr = unique(
                JSON.parse(
                  window.localStorage.getItem(
                    "msgList-" + contact.value.fromId + "-" + contact.value.toId
                  )
                ),
                result.data.rdata
              );
            } else {
              arr = result.data.rdata;
            }
            window.localStorage.setItem(
              "msgList-" + contact.value.fromId + "-" + contact.value.toId,
              JSON.stringify(arr)
            );
            msgList.value = JSON.parse(
              window.localStorage.getItem(
                "msgList-" + contact.value.fromId + "-" + contact.value.toId
              )
            );
            const mid = document.querySelector(".middle");
            nextTick(() => {
              mid && (mid.scrollTop = mid.scrollHeight);
              document.querySelector(".messageText").focus();
            });
            delMsg(result.data.key);
          }
        });
      }
    };
    const delChatData = () => {
      msgList.value = null;
    };
    const delMsg = (e) => {
      API({
        url: "/kuke/api/kuke-pull/delMsg",
        method: "GET",
        params: {
          key: e,
        },
      }).then(() => {});
      let arr = JSON.parse(
        window.localStorage.getItem(
          "msgList-" + contact.value.fromId + "-" + contact.value.toId
        )
      );
      if (arr.length > 100000) {
        arr = arr.slice(30000);
        window.localStorage.setItem(
          "msgList-" + contact.value.fromId + "-" + contact.value.toId,
          JSON.stringify(arr)
        );
      }
    };
    onUnmounted(() => {
      msgList.value = null;
      socket.value.close();
    });
    function unique(arr, orr) {
      let _union = [];
      if (orr instanceof Array) {
        _union = arr.concat(orr);
      } else {
        arr.push(orr);
        _union = arr;
      }
      // 缓存用于记录
      let newArr = [];
      for (let t of _union) {
        t.time = utils.dateFormat(t.time);
        // 检查缓存中是否已经存在
        if (newArr.find((c) => c.from === t.from && c.to === t.to && c.time === t.time)) {
          // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
          continue;
        }
        // 不存在就说明以前没遇到过，把它记录下来
        newArr.push(t);
      }
      return newArr;
    }
    const sendprivateletterInfo = (e) => {
      let arr = unique(
        JSON.parse(
          window.localStorage.getItem(
            "msgList-" + contact.value.fromId + "-" + contact.value.toId
          )
        ),
        e
      );
      window.localStorage.setItem(
        "msgList-" + contact.value.fromId + "-" + contact.value.toId,
        JSON.stringify(arr)
      );
      API({
        url: "/kuke/api/user/sendprivateletterInfo",
        method: "GET",
        params: {
          id: e.to,
          index: 1,
        },
      }).then(() => {});
    };
    const over = () => {
      // setColor("#c9c7c7");
    };
    const out = () => {
      // setColor("#0000");
    };
    const setColor = (color) => {
      document.documentElement.style.setProperty("--scroll-color", `${color}`);
    };
    const sendMsg = (e) => {
      if (e) {
        e.preventDefault();
      }
      if (!msg.value) {
        hint.value = "信息不可为空！";
        return;
      }
      let entity = {
        from: window.localStorage.getItem("token"),
        to: contact.value.fromId,
        message: msg.value,
        time: utils.dateFormat(new Date().getTime()),
        nickname: infodata.value.nickname,
        avatar: infodata.value.avatar,
      };
      API({
        url: "/kuke/api/user/getuserblackInfo",
        method: "GET",
        params: {
          id: contact.value.fromId,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          socket.value.send(JSON.stringify(entity));
          entity.from = contact.value.toId;
          msgList.value.push(entity);
          msg.value = "";
          hint.value = "";
          const mid = document.querySelector(".middle");
          nextTick(() => {
            mid && (mid.scrollTop = mid.scrollHeight);
            document.querySelector(".messageText").focus();
          });
          sendprivateletterInfo(entity);
        } else if (result.data.code == 601) {
          tipsshow.value = true;
          tipstxt.value = i18n.global.t("message.tips124");
        } else if (result.data.code == 602) {
          tipsshow.value = true;
          tipstxt.value = i18n.global.t("message.tips123");
        }
      });
    };
    const Emoticon_show = ref(false);
    const emoticonView = () => {
      Emoticon_show.value = true;
    };
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const getemoticontxt = (e) => {
      let emoticontxt = "[" + e + "]";
      msg.value += emoticontxt;
      Emoticon_show.value = false;
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" style="width: 19px;" />'
          );
        }
      }
      return txt;
    }
    const FileUpload_file = ref(null);
    const imageView = () => {
      if (FileUpload_file.value != null) {
        FileUpload_file.value.value = "";
      }
      FileUpload_file.value.click();
    };
    const uploadURL = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "chat/" + "chat" + `${Date.parse(new Date())}` + "." + suffix;
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          msg.value = "http://img.keepfly.net/" + fileName;
          let entity = {
            from: window.localStorage.getItem("token"),
            to: contact.value.fromId,
            message: msg.value,
            time: new Date(),
            nickname: infodata.value.nickname,
            avatar: infodata.value.avatar,
          };
          socket.value.send(JSON.stringify(entity));
          entity.from = contact.value.toId;
          msgList.value.push(entity);
          msg.value = "";
          hint.value = "";
          const mid = document.querySelector(".middle");
          nextTick(() => {
            mid && (mid.scrollTop = mid.scrollHeight);
            document.querySelector(".messageText").focus();
          });
          sendprivateletterInfo(entity);
        });
    };
    return {
      tipsshow,
      tipstxt,
      delChatData,
      getChatData,
      FileUpload_file,
      imageView,
      uploadURL,
      filters,
      Emoticon_show,
      emoticonView,
      emoticon,
      getemoticontxt,
      sendprivateletterInfo,
      msgList,
      contact,
      sendMsg,
      setColor,
      out,
      over,
      msg,
      hint,
      socket,
      bubbleMsg,
      interval,
      isEmptyText,
    };
  },
});
</script>

<style scoped>
:root {
  --scroll-color: #0000;
}

.dialog {
  width: 71.8%;
  height: 100%;
  float: right;
}

.name {
  position: relative;
  top: 0px;
  font-size: 12px;
  color: darkgrey;
}

.info {
  height: 100%;
  display: flex;
  align-items: center;
}

.info .msg {
  flex: 1;
  text-align: center;
}

.top {
  padding: 14px;
  width: 100%;
  height: 40px;
  border-bottom: #eee 1px solid;
}

.middle {
  height: 432px;
  overflow: auto;
  padding: 10px;
  margin: 6px 0 11px 0;
}

.middle::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

.middle::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scroll-color);
}

.middle::-webkit-scrollbar-track {
  background: #efeded;
  border-radius: 4px;
}

.middle .msg {
  display: flex;
}

.avatar {
  margin: 8px;
  flex: 1;
}

.avatar img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.bubble-msg-left,
.bubble-msg-right {
  padding: 10px;
  font-size: 12px;
  margin-top: 8px;
  line-height: 20px;
  border-radius: 5px;
  width: fit-content;
  line-break: anywhere;
}

.bubble-msg-left {
  float: left;
  color: #000;
  text-indent: -0.5em;
  background-color: rgb(218, 224, 248);
}

.bubble-msg-right {
  float: right;
  color: #000;
  background-color: rgb(218, 224, 248);
}

.bubble-msg-right::before {
  content: " ";
  float: right;
  position: relative;
  left: 18px;
  border: 4px solid #0000;
  border-left-color: rgb(218, 224, 248);
}

.bubble-msg-left::before {
  content: " ";
  float: left;
  position: relative;
  left: -18px;
  border: 4px solid #0000;
  border-right-color: rgb(218, 224, 248);
}
.bubble-msg-lefto,
.bubble-msg-righto {
  font-size: 12px;
  margin-top: 8px;
  line-height: 20px;
  border-radius: 5px;
  width: fit-content;
  line-break: anywhere;
}

.bubble-msg-lefto {
  float: left;
  color: #000;
  text-indent: -0.5em;
}

.bubble-msg-righto {
  float: right;
  color: #000;
}
.line {
  width: 100%;
  height: 0;
  position: relative;
  top: -6px;
  border-top: #d0d0d0 1px solid;
}

.dialog .bottom {
  padding-left: 10px;
  padding-right: 10px;
}

.messageText {
  position: relative;
  padding: 12px;
  font: 14px/1.5 Helvetica, Arial, Tahoma, 微软雅黑;
  width: 100%;
  height: 80px;
  outline: none;
  background: #efeded;
  border: 0 none;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  vertical-align: middle;
  display: inline-block;
}

.send {
  float: right;
  position: relative;
  top: -31px;
  left: 0px;
  background-color: #51a5e6;
  border: #87ceeb;
  color: #fff;
  font-size: 12px;
  width: 62px;
  height: 31px;
  border-radius: 3px;
}

.send:focus {
  outline: none;
}

.emptyText {
  background-color: #ff8200;
}
</style>
