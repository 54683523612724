<template>
  <div style="width: 1000px; margin: 20px auto 0">
    <div class="content">
      <div class="leftcon">
        <div>
          <a>
            <img src="../assets/logo.png" style="height: 56px; width: auto" />
          </a>
          <span
            class="keepfly-box-alignCenter"
            style="margin-left: 16px; width: 26px"
            onclick="javascript:history.back(-1);"
          >
            <img src="../assets/left.png" style="width: 26px; height: 26px" />
          </span>
        </div>
        <ul>
          <li>
            <a title="服务条款" @click="setNavTab(2)" :class="[tabShow == 2 ? 'cur' : '']"
              >服务条款</a
            >
          </li>

          <li>
            <a title="免责声明" @click="setNavTab(3)" :class="[tabShow == 3 ? 'cur' : '']"
              >免责声明</a
            >
          </li>
          <li>
            <a title="隐私政策" @click="setNavTab(4)" :class="[tabShow == 4 ? 'cur' : '']"
              >隐私政策</a
            >
          </li>
          <li>
            <a title="权利声明" @click="setNavTab(1)" :class="[tabShow == 1 ? 'cur' : '']"
              >权利声明</a
            >
          </li>
          <li>
            <a title="用户手册" @click="setNavTab(5)" :class="[tabShow == 5 ? 'cur' : '']"
              >用户手册</a
            >
          </li>
        </ul>
      </div>
      <div class="rightcon">
        <div
          style="padding: 20px 20px 10px; background: #fff"
          v-if="tabShow == 1"
          v-html="dataInfo.txt1"
        ></div>
        <div
          style="padding: 20px 20px 10px; background: #fff"
          v-if="tabShow == 2"
          v-html="dataInfo.txt2"
        ></div>
        <div
          style="padding: 20px 20px 10px; background: #fff"
          v-if="tabShow == 3"
          v-html="dataInfo.txt3"
        ></div>
        <div
          style="padding: 20px 20px 10px; background: #fff"
          v-if="tabShow == 4"
          v-html="dataInfo.txt4"
        ></div>
        <div
          class="abc"
          style="padding: 20px 20px 10px; background: #fff"
          v-if="tabShow == 5"
          v-html="dataInfo.txt5"
        ></div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import API from "../uilts/request";
export default defineComponent({
  components: {},
  setup() {
    const tabShow = ref(2);
    let dataInfo = ref([]);
    // const message = useMessage();
    onMounted(() => {
      getData();
    });
    const getData = () => {
      API({
        url: "/kuke/api/index/getDocumentData",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((result) => {
        dataInfo.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const setNavTab = (e) => {
      tabShow.value = e;
    };
    return {
      setNavTab,
      tabShow,
      dataInfo,
    };
  },
});
</script>
<style>
.n-tabs .n-tabs-pane-wrapper {
  width: 100%;
  background: #fff;
  overflow: revert !important;
}
.abc p img {
  width: 100%;
}
.content {
  padding: 20px 10px 20px 10px;
}
.leftcon {
  position: fixed;
  width: 200px;
  float: left;
  background: #fff;
}
.rightcon {
  width: 770px;
  float: right;
  background: #fff;
}
ul > li {
  line-height: 45px;
  font-size: 16px;
}
ul > li > a,
ul > li > span {
  display: block;
  color: #333;
  text-decoration: none;
  padding-left: 26px;
  font-weight: 550;
  cursor: pointer;
}
ul > li > .cur {
  background-size: 10px 6px;
  color: #ff8200;
}
</style>
