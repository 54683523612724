<template>
  <div>
    <div
      class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
    >
      <div
        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween ListTitle_tit"
      >
        <h3 class="ListTitle_th3 ListTitle_f14 ListTitle_cla">
          {{ $t("message.blacklist") }}（{{ blacklistTotal }}）
          <span style="font-size: 12px; color: darkgray">{{
            $t("message.tips143")
          }}</span>
        </h3>
      </div>
      <div>
        <div class="Scroll_container">
          <div class="Scroll_wrap">
            <div>
              <div class="vue-recycle-scroller ready page-mode direction-vertical">
                <div class="vue-recycle-scroller__item-wrapper">
                  <div class="">
                    <div v-if="blacklistarr.length > 0" class="wbpro-scroller-item">
                      <div
                        class="keepfly-box-flex keepfly-box-column UserFeedCard_userFeedCard"
                      >
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter UserFeedCard_item"
                          v-for="(item, index) in blacklistarr"
                          :key="item"
                        >
                          <a class="ALink_none UserFeedCard_left">
                            <div
                              class="keepfly-avatar-main keepfly-avatar-hover"
                              style="width: 3.125rem; height: 3.125rem"
                            >
                              <img :src="item.avatar" class="keepfly-avatar-img" />
                              <div class="keepfly-avatar-hoverMask"></div>
                            </div>
                            <div
                              class="keepfly-box-item-flex UserFeedCard_con UserFeedCard_f12"
                              style="align-self: center"
                            >
                              <div
                                class="keepfly-box-flex keepfly-box-alignCenter UserFeedCard_cla UserFeedCard_fb UserFeedCard_cut"
                              >
                                {{ item.nickname }}
                              </div>
                              <div class="UserFeedCard_clb UserFeedCard_cut">
                                {{
                                  item.hometown == null
                                    ? $t("message.tips16")
                                    : item.hometown
                                }}
                              </div>
                            </div>
                          </a>
                          <div class="UserFeedCard_right">
                            <div class="keepfly-pop-wrap">
                              <span class="keepfly-pop-ctrl">
                                <button
                                  @click="cancelblacklist(item, index)"
                                  class="keepfly-button-main keepfly-button-line keepfly-button-default keepfly-button-s keepfly-button-round FollowBtn_s"
                                >
                                  <span class="keepfly-button-wrap">
                                    <span class="keepfly-button-content">
                                      <!-- {{ $t("message.call_off") }} -->
                                      <close style="width: 19px"></close>
                                    </span>
                                  </span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <n-pagination
                          v-model:page="currblacklistPage"
                          size="medium"
                          :page-count="bpages"
                          :page-slot="7"
                          @update-page="onDatablacklistChange"
                        />
                        <div
                          class="keepfly-divider-main keepfly-divider-x UserFeedCard_line"
                        ></div>
                      </div>
                    </div>
                    <div v-else style="background: #fff; padding: 40px 10px">
                      <n-empty :description="$t('message.none')">
                        <template #extra></template>
                      </n-empty>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { NEmpty, useMessage, NPagination } from "naive-ui";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import { Close } from "@vicons/ionicons5";
export default defineComponent({
  name: "BlacklistView",
  components: {
    NEmpty,
    Close,
    NPagination,
  },
  setup() {
    const message = useMessage();
    const blacklistarr = ref([]);
    const blacklistTotal = ref(0);
    onMounted(() => {
      getuserblacklist();
    });
    const currblacklistPage = ref(1);
    const pageblacklistSize = ref(10);
    const bpages = ref(0);
    const getuserblacklist = () => {
      let param = {
        currPage: currblacklistPage.value,
        pageSize: pageblacklistSize.value,
      };
      API({
        url: "/kuke/api/user/getselfblacklist",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          let arr = result.data.data.records;
          for (let i = 0; i < arr.length; i++) {
            blacklistarr.value.push(arr[i]);
          }
          blacklistTotal.value = result.data.data.total;
          bpages.value = result.data.data.pages;
        }
      });
    };
    const cancelblacklist = (e, index) => {
      let param = {
        id: e.buid,
      };
      API({
        url: "/kuke/api/user/cancelblacklist",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips151"));
          blacklistarr.value.splice(index, 1);
          blacklistTotal.value = blacklistTotal.value - 1;
        } else {
          message.error(i18n.global.t("message.tips152"));
        }
      });
    };
    const onDatablacklistChange = (val) => {
      currblacklistPage.value = val;
      getuserblacklist();
    };
    return {
      onDatablacklistChange,
      bpages,
      currblacklistPage,
      cancelblacklist,
      blacklistarr,
      blacklistTotal,
    };
  },
});
</script>
