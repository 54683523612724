const ko = {
    message: {
        username:'사용자 이름',
        password:'비밀번호',
        save:'저장',
        edit:'편집',
        update:'업데이트',
        delete:'삭제',
        currentLanguage: '한국어',
        search: '검색',
        popular: '핫',
        topic: '화제',
        goldenSentenceList: '금구방',
        askQuestions: '질문',
        SignIn: '로그인',
        register: '로그인',
        home: '첫 페이지',
        information: '메시지',
        register_tis: '아직 Keepfly 없어?',
        register_Now: '지금 등록',
        sign_in_now: '지금 로그인',
        homepage: '홈',
        no_account_yet: '계정 아직?',
        welcome: '환영합니다',
        account_number: '계정(이메일)',
        account_register: '계정 등록',
        nickname: '닉네임',
        email: '사서함',
        mobile: '핸드폰 번호',
        confirm_password: '비밀번호 확인',
        verification_code: '인증 코드',
        gain: '가져오기',
        tips1: '사서함을 입력하십시오',
        tips2: '비밀번호를 입력하십시오',
        tips3: '닉네임을 입력하십시오',
        tips4: '확인 암호를 입력하십시오',
        tips5: '두 번 입력한 비밀번호가 일치하지 않습니다',
        tips6: '확인 확인 코드를 입력하십시오',
        follow_all: '모두 주목',
        up_to_date: '금구',
        friends: '친구권',
        deceased: '죽은 자 관리',
        tips7: '어떤 새로운 것을 여러분에게 공유하고 싶습니까?',
        emote: '이모티콘',
        picture: '그림',
        essay: '문장',
        more: '더 보기',
        send: '보내기',
        all: '모두',
        advanced_search: '고급 검색',
        userinfo: '사용자 정보',
        log_out: '로그인 종료',
        tips8: '무슨 얘기를 하고 싶어요',
        find_it: '찾아봐',
        home_page: '홈',
        read: '읽기',
        discuss: '토론',
        click_refresh: '새로 고침 클릭',
        set: '설정',
        edit_data: '자료 편집',
        privacy_setting: '프라이버시 설정',
        tips9: '계정 정보 설정',
        sign: '사인',
        tips10: '4~30자, 중국어, 영어, 숫자 지원',
        tips11: '자신을 소개합시다',
        tips12: '1~140자',
        tips13: '개인 기본 정보',
        gender: '성별',
        man: '남',
        woman: '여',
        other: '기타',
        birthday: '생일',
        emotional_status: '감정상태',
        tips14: '감정 상태 선택',
        address: '소재지',
        hometown: '고향',
        single: '솔로',
        seeking_a_relationship: '사귀자',
        in_secret_love: '짝사랑 중',
        ambiguous: '썸타는 중',
        in_love: '연애중',
        engaged: '약혼',
        married: '기혼',
        separated: '별거',
        divorced: '이혼',
        widow: '사우',
        not_selected: '선택하지 않음',
        tips15: '내 거 볼래',
        personal_homepage: '개인 홈페이지',
        my_home_page: '마이 홈',
        my_attention: '내 관심',
        my_fans: '내 팬',
        tips16: '잠시 소개 없음',
        belong_to: '소속',
        add: '가입',
        look_around_the_world: '세상에서 찾아봐',
        registration_of_seeking_relatives: '친척 찾기 등록',
        name_of_missing_person: '실종자 이름',
        age: '나이',
        debatable_time: '실종시간',
        location_of_disappearance: '실종지점',
        feature_description: '피처 설명',
        photograph: '사진',
        check_in: '등록',
        contact_number: '연락처',
        front_cover: '표지',
        missing_people: '실종자',
        created_by: '창설자',
        creation_time: '작성 시간',
        comment: '댓글',
        praise: '좋아요',
        system_notification: '시스템 알림',
        tips17: '정확한 메일박스를 입력하십시오',
        tips17_o: '정확한 핸드폰 번호 형식을 입력하십시오',
        avatar: '프로필 사진',
        tips18: '계정이 이미 존재합니다',
        tips19: '등록 실패',
        tips20: '인증 코드 오류',
        tips21: '인증 코드 다시 가져오기',
        tips22: '인증 코드 전송 성공',
        tips23: '로그인 성공',
        tips24: '비밀번호 오류',
        tips25: '사용자가 존재하지 않습니다',
        tips26: '전체 검색어 차트 보기',
        help_center: '도움말 센터',
        customer: '고객센터',
        self_service_center: '셀프 서비스 센터',
        website_related: '사이트 관련',
        tips27: '콜라보 서비스',
        cooperation_hotline: '협력 핫라인',
        follow: '관심',
        tips28: '단서를 제공하다',
        view_the_original_article: '원본 기사 보기',
        message: '메시지',
        tips29: '추가 성공',
        tips30: '추가 실패',
        tips31: '너의 의견을 올려라',
        tips32: '이미 있음',
        tips33: '등록 성공',
        tips34: '혼자 신경 안 써도 돼요',
        cancel: '닫기',
        tips35: '데이터 삭제 성공',
        tips36: '데이터 삭제 실패',
        name_of_the_deceased: '고인의 성명',
        nationality: '국적',
        date_of_birth: '생년월일',
        date_of_death: '죽은 날짜',
        university: '졸업 대학',
        occupation: '직업',
        achievements: '개인 성과',
        tips37: '죽은 사람의 이름은 비워둘 수 없습니다',
        tips38: '프로필 사진 선택',
        tips39: '국적은 비워둘 수 없습니다',
        tips40: '생년월일 선택',
        tips41: '죽은 날짜 선택',
        tips42: '만들기 성공',
        tips43: '생성 실패',
        tips44: '모든 팬',
        account_of_the_deceased: '사망자 계정',
        add_an_account: '계정 추가',
        view: '보기',
        transfer: '인계',
        newly_increased: '신규',
        opposite_id: '상대 ID',
        transfer_failed: '이전 실패',
        transfer_successful: '이전 성공',
        tips45: '자신에게 직접 전달할 수 없음',
        tips46: '죽은 사람 계정 없음',
        tips47: '관심 없음',
        tips48: 'fans 없음',
        determine: '확인',
        call_off: '취소',
        tips49: '이 사용자에 대한 관심을 취소하시겠습니까?',
        tips50: '여러분께 나눠드릴 금구절이 있나요?',
        tips51: '수정 성공',
        tips52: '수정 실패',
        hint: '팁',
        tips53: '전송하시겠습니까?',
        repeat: '전달',
        hot_search: '실시간 검색',
        know: '알아',
        tips54: '언젠가는 아는 사람이 있다',
        tips55: '너의 질문에 대한 답',
        solve: '해결',
        question: '문제',
        tips56: '알음알음',
        tips57: '질문하겠습니다',
        submit: '제출',
        quick_answer: '빠른 대답',
        translate: '번역',
        tips58: '번역 실패',
        recommend: '추천',
        effect:'영향력',
        number_of_fans:'팔로워 수',
        quantity:'컨텐츠 수',
        fans:'팬',
        tips59:'꽃배달 성공',
        tips60:'꽃배달 실패',
        tips61:'편집 성공',
        tips62:'편집 실패',
        tips63:'이름을 입력하십시오',
        tips64:'관계를 입력하십시오',
        tips65:'그림을 선택하십시오',
        tips66:'제목을 입력하십시오',
        tips67:'내용을 입력하십시오',
        tips68:'섹션 이름을 입력하십시오',
        tips69:'섹션이 이미 있습니다',
        tips70:'꽃을 보내시겠습니까?',
        tips71:'죽은 자와의 관계',
        album:'사진집',
        deceased_admin:'죽은 자 관리자',
        creator:'작성자',
        the_deceased:'죽은 자',
        number_of_views:'조회수',
        update_time:'업데이트 시간',
        basic_info:'기본 정보',
        full_name:'이름',
        alias:'별칭',
        language:'언어 잘하기',
        height:'키',
        motto:'좌우명',
        interaction:'상호 작용',
        input_relationship:'관계 입력',
        content_addition:'컨텐트 추가',
        image_upload:'그림 업로드',
        message_management:'메시지 관리',
        deceased_label:'죽은 자 태그',
        modify:'수정',
        image_photo:'이미지 사진',
        brief:'프로파일',
        label:'해시태그',
        increase:'추가',
        constellation:'별자리',
        relationship:'관계',
        title:'제목',
        add_section:'섹션 추가',
        modify_section:'판 수정',
        module_name:'섹션 이름',
        content: '콘텐츠',
        section:'섹션',
        guestbook:'메모',
        operate:'조작',
        early_experiences:'이전 경력',
        deductive_experience:'일의 성취',
        personal_life:'개인의 삶',
        award_records:'중대한 이벤트',
        character_evaluation:'지인 평가',
        deceased_square:'죽은 자의 광장',
        memorial_plaza: '기념 광장',
        ranking_list1: '송화방',
        release: '게시',
        message_list: '게시판',
        tips72: '삭제하려면 관리자에게 문의하십시오',
        tips73: '핸드폰 번호 11분 입력하세요',
        none: '없음',
        details: '자세한 정보',
        log_on: '로그인',
        previous_article: '이전',
        next_article: '이전',
        chapter_1: '1편',
        last_article: '마지막 편',
        tips74: '데이터가 더 이상 없음',
        unknown: '알 수 없음',
        tips75: '영향력은 사용자가 매일 로그인하는 시간, 상호작용 수가 종합적으로 나타난다',
        tips76: '지적재산권 침해',
        tips77: '루머 거짓',
        tips78: '콘텐츠 품질 저하',
        tips79: '명예침해',
        tips80: '저속한 포르노',
        tips81: '사기 혐의',
        tips82: '정치 관련',
        tips83: '마케팅 광고',
        tips84: '불량한 가치관 홍보',
        report: '제보',
        report_content: '제보 내용',
        reported_image: '제보 사진',
        reporting_link: '제보 링크',
        reporting_type: '신고 유형',
        join: '참여',
        interact: '인터랙티브 메시지',
        tips85: '사용자 비활성화',
        tips86: '사서함 인증번호',
        change_password: '암호 수정',
        confirm: '확인',
        return: '되돌리기',
        tips87: '내 질문',
        recommendo: '추천',
        tips88: '이 답 추천?',
        tips89: '추천 성공',
        tips90: '추천 실패',
        tips91: '문제가 존재하지 않습니다',
        tips92: '그림 크기는 10M보다 클 수 없습니다!',
        tips93: '그림 크기는 0M이 될 수 없습니다!',
        tips94: '그림 비율은 1:1이 아닙니다',
        tips95: '팁: 사진은 1: 1 크기로 업로드하십시오. 그렇지 않으면 성공할 수 없습니다. 프로필 사진은 주요 인물을 정중앙에 놓으십시오. 전체 그림의 질은 10M 미만입니다.',
        tips96: '그림 비율이 16: 9가 아닙니다',
        tips97: '새 암호 설정',
        tips98: '알 수 없음으로 선택 안 함',
        tips99: 'ID 를 입력하십시오',
        tips100: '오리지널 보호: 저작권은 작가의 소유',
        tips101: '성명: 발표 내용은 플랫폼과 무관합니다. 만약 부실한 정보를 발견하면 신고할 수 있습니다',
        tips102: '제안 4:3 가로 길이 이미지를 업로드하고 전체 이미지 품질이 10M 미만이며 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips103: '권장 사항 3:4 세로 세로 행 이미지를 업로드하고 전체 이미지 품질이 10M 미만이며 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        id: 'ID',
        tips104: '받은 댓글',
        private_letter: '개인 편지',
        reply: '답장',
        the_original: '원문',
        change: '변경',
        change_avatar: '프로필 사진을 바꾸다',
        tips105: '권장 업로드 1: 1 크기, 전체 이미지 품질 10M 미만, jpg, jpeg, bmp, gif, png 형식 지원',
        change_email: '메일박스 교체',
        new_email: '새 메일박스',
        tips106: '메일박스 등록됨',
        tips107: '24시간 고객지원 메일박스',
        tips108: '저작권은 작가의 소유이다',
        tips109: '개인 홈페이지 설정',
        tips110: '설정 성공',
        tips111: '설정 실패',
        tips112: '비밀번호 잊음',
        tips113: '이 사용자만 볼 수 있습니다',
        tips114: '이 사용자 친구만 볼 수 있습니다',
        year: '연',
        moon: '월',
        day: '일',
        B_C: '기원전',
        tips115: '죽은 연도에 따라 정렬',
        tips116: '자신에게 사신을 보낼 수 없다',
        tips117: 'Enter 키를 눌러 보내기',
        tips118: '컨텐츠 보기 ~ ~ ~',
        tips119: '아직 정보가 없습니다~~~',
        search_result: '검색 결과',
        comprehensive: '종합',
        tips120: '블랙리스트 추가됨',
        tips121: '블랙리스트로 자신을 추가할 수 없음',
        tips122: '블랙리스트 추가',
        tips123: '상대방이 당신을 블랙리스트에 추가했습니다. 메시지를 보낼 수 없습니다',
        tips124: '상대방을 블랙리스트에 추가했습니다. 메시지를 보낼 수 없습니다',
        blacklist: '블랙리스트',
        die_at_the_age_of: '향년',
        tips125: '대화 삭제?',
        tips126: '유효한 날짜 형식이 아닙니다',
        just: '방금',
        tips127: '1분 전',
        minute_ago: '분 전',
        tips128: '1시간 전',
        hour_ago: '시간 전',
        yesterday: '어제',
        days_ago: '일',
        weeks_ago: '주',
        tips129: '이름 ',
        about_us: '우리에 대하여',
        cooperation: '투자 유치 협력',
        walk_into: '진입',
        tips130: '사이트 또는 전화로 회사 영업 관리자에게 문의',
        tips131: '협력 상담: 전화 상담 또는 직접 방문 상담 가능',
        tips132: '계약을 체결하여 합작 방식을 확정한다',
        tips133: '서비스 핫라인',
        tips134: '협력 전화',
        customer_service_1: '고객센터 1',
        customer_service_2: '고객센터 2',
        tips135: '신청 내용 추가 / 수정',
        tips136: '이름 키워드',
        tips137: '전체 댓글 보기',
        message_board: '메모판',
        tips138: '고인 홈페이지',
        copy: '복제',
        my_answer: '나의 대답',
        my_password: '내 비밀번호',
        tools: '도구',
        height_unit: '키 단위',
        tips139: '제안 100 × 138 세로 길이 사진을 업로드하고 전체 이미지 품질이 10M 미만이며 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips140: '제안 220 × 231 세로 길이 그림을 업로드하고 전체 그림 품질이 10M 미만이며 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips141: '섹션 삭제',
        tips142: '내가 발표한 금구',
        tips143: '사담 금지',
        tips144: '팁: 알 수 없는 버튼이 선택된 경우 알 수 없음이 표시됩니다',
        replicating_success: '복제 성공',
        tips145: '사진은 1: 1 사이즈로 업로드하는 것을 권장하며, 프로필 사진은 주요 인물을 정중앙에 배치하십시오.전체 이미지 품질이 10M 미만입니다',
        tips146: '팔로우 실패',
        tips147:'관심이 취소됨',
        tips148:'관심 취소 실패',
        tips149:'이미 상호 주목',
        tips150:'상호 팔로우 실패',
        tips151:'사용자를 블랙리스트에서 옮겼습니다',
        tips152:'사용자를 블랙리스트에서 옮기는 데 실패했습니다',
        tips153:'발표됨',
        tips154:'발표 실패',
        tips155:'커밋됨',
        tips156:'제출 실패',
        self: '자기',
        sending_flowers: '꽃배달',
        successfully_saved:'저장 성공',
        save_failed:'저장 실패',
        clue: '단서',
        tips157: '남편, 어머니, 친구 등 고인과의 관계 신분을 기입하다',
        tips158: '1:1 비율의 정사각형 그림을 업로드하는 것이 좋습니다. 그림 크기는 10M 미만이고 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips159: '1:1 배율 정사각형 그림을 업로드하십시오. 그렇지 않으면 성공할 수 없습니다.프로필 사진 주요 인물을 정중앙에 배치하십시오. 그림 크기는 10M 미만이고 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips160: '220:230 비율의 세로 길이 그림을 업로드하는 것이 좋습니다. 그림 크기가 10M 미만이고 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips161: '100: 140 비율의 세로 길이 그림을 업로드하는 것이 좋습니다. 그림 크기가 10M 미만이고 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        tips162: '100:56 비율의 가로 길이 그림을 업로드하는 것이 좋습니다. 그림 크기가 10M 미만이고 jpg, jpeg, bmp, gif, png 형식을 지원합니다',
        admin: '관리자',
        tourist: '관광객',
        tips163: '게시 성공',
        id_number_copied: 'ID 번호가 복사됨',
        tips164:'실마리 제공 성공',
        tips165:'실마리 제공 실패',
        tips166:'금구 복사 성공',
        tips167:'관심 해제 성공',
        tips168:'관심 취소 실패',
        tips169:'금구 발표 성공',
        tips170:'금구 발표 실패',
        tips171:'댓글 성공',
        tips172:'댓글 실패',
        tips173:'응답 성공',
        tips174:'응답 실패',
        tips175:'발표 질문 성공',
        tips176:'질문 게시 실패',
        tips177:'메시지 실패',
        deceasedo:'죽은 자 추가',
        tips178:'로그아웃 ID를 입력하십시오',
        tips179:'계정이 잘못되었습니다. ID를 다시 확인하십시오',
        tips180:'로그아웃 이유를 입력하십시오',
        tips181:'신청 성공!keepfly 는 7 영업일 이내에 신청서를 처리합니다',
        tips182:'신청 실패',
        tips183:'로그아웃 ID',
        tips184:'사서함 로그아웃',
        tips185:'로그아웃 이유',
        apply:'신청',
        tips186:'keepfly 계정을 로그아웃하고 있습니다. 이후에 keepfly 제품과 서비스를 사용할 수 없으니 조심하세요!',
        tips187:'keepfly는 귀하의 작업 기록을 보존하지 않으며 로그아웃 후 기록 복구를 신청할 수 없습니다.',
        tips188:'계정 로그아웃',
        tips189:'살아있는 사람들을 위해 고인의 계정을 만들지 마십시오. 법적 위험이 있으면 플랫폼과 무관합니다.',
        video: '비디오',
        tips190: '사진 영상은 한 가지만 올릴 수 있어요, 확인?',
        tips191: '꽃배달은 20분을 넘으면 안 됩니다',
        delete_account: '계정 삭제',
    }
}
export default ko