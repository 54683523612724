<template>
    <n-dialog-provider>
        <router-view />
    </n-dialog-provider>
</template>
<script>
import { defineComponent } from "vue";
import { NDialogProvider } from "naive-ui";
export default defineComponent({
    name: 'UserView',
    components: {
        NDialogProvider
    },
    setup() {

    },
})
</script>
<style>

</style>