<template>
  <div class="_wrap">
    <div class="_top">
      <div class="_topin">
        <div class="_content">
          <div
            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween _box"
          >
            <div class="keepfly-box-flex keepfly-box-alignCenter">
              <div class="keepfly-box-flex keepfly-box-alignCenter">
                <div class="_wrap _logo">
                  <img src="../../assets/logo.png" style="width: 30px" />
                </div>
                <div class="_line">
                  <div class="keepfly-divider-main keepfly-divider-y">
                    <div class="keepfly-divider-shadow"></div>
                  </div>
                </div>
                <div class="_tit">{{ $t("message.deceased") }}</div>
                <div
                  class="keepfly-box-flex keepfly-box-alignCenter"
                  style="margin-left: 16px; width: 20px"
                  onclick="javascript:history.back(-1);"
                >
                  <img src="../../assets/left.png" style="width: 26px; height: 26px" />
                </div>
              </div>
            </div>
            <div class="keepfly-box-flex keepfly-box-alignCenter">
              <div
                class="keepfly-avatar-main keepfly-avatar-hover _avatar"
                style="width: 2rem; height: 2rem"
              >
                <img :src="infodata.avatar" class="keepfly-avatar-img _avatar" />
                <div class="keepfly-avatar-hoverMask"></div>
              </div>
              <div class="_name" style="margin-right: 10px">{{ infodata.nickname }}</div>
              <n-select
                style="width: 108px"
                @update:value="handleSearch"
                v-model:value="selectedValue"
                status="warning"
                label-field="name"
                value-field="language"
                :theme-overrides="selectThemeOverrides"
                :options="arr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-wrapper">
      <div class="before-content">
        <div class="second-container second-container-normal J-second-head">
          <div class="layout">
            <a class="posterFlag excellent-icon" target="_blank"></a>
            <div class="lemma-title-wrapper">
              <dl class="lemmaWgt-lemmaTitle lemmaWgt-lemmaTitle-">
                <dd class="lemmaWgt-lemmaTitle-title J-lemma-title">
                  <span>
                    <h1>{{ deceasedInfo.dname }}</h1>
                  </span>
                  <span class="btn-list">
                    <a
                      @click="headView"
                      class="edit-lemma cmn-btn-hover-blue cmn-btn-28 j-edit-link"
                      data-entry="lemma_pc"
                      style="display: inline-block"
                    >
                      <brush-outline
                        style="width: 12px"
                        class="cmn-icon wiki-lemma-icons wiki-lemma-icons_edit-lemma"
                      ></brush-outline>
                      {{ $t("message.edit") }}
                    </a>
                  </span>
                </dd>
                <div class="lemma-desc" v-if="deceasedInfo.year">
                  {{ $t("message.date_of_death") }}：{{
                    deceasedInfo.year < 0
                      ? $t("message.B_C") + " " + Math.abs(deceasedInfo.year)
                      : deceasedInfo.year
                  }}{{ $t("message.year") }} {{ deceasedInfo.moon
                  }}{{ $t("message.moon") }} {{ deceasedInfo.day }}{{ $t("message.day") }}
                </div>
                <div class="lemma-desc" v-else>
                  {{ $t("message.date_of_death") }}：{{ $t("message.unknown") }}
                </div>
              </dl>
            </div>
            <div
              class="flower-sender flower-sender-dark"
              style="right: -23px"
              id="starFlower"
            >
              <n-popconfirm
                positive-text="ok"
                negative-text="not ok"
                @positive-click="deliverflower"
              >
                <template #trigger>
                  <div class="bg">
                    <div
                      style="
                        position: absolute;
                        min-width: 40px;
                        top: -9px;
                        left: 43px;
                        background: #f5f5f5;
                        color: red;
                        padding: 3px;
                        border-radius: 5px;
                      "
                    >
                      {{ $t("message.sending_flowers") }}
                    </div>
                    <em></em>
                    <p class="numBox">
                      <i class="count deep-color">{{ deceasedInfo.fnum }}</i>
                    </p>
                  </div>
                </template>
                {{ $t("message.tips70") }}
              </n-popconfirm>
            </div>
            <div style="min-height: 162px">
              <div
                style="
                  max-width: 660px;
                  margin: 12px;
                  font-size: 14px;
                  padding: 0 31px 0 0px;
                "
              >
                <n-ellipsis
                  expand-trigger="click"
                  line-clamp="7"
                  style="white-space: pre-wrap"
                  :tooltip="false"
                >
                  {{ deceasedInfo.achievement }}
                </n-ellipsis>
              </div>
              <img class="miaodong-logo" :src="deceasedInfo.brand" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="star-mod-top">
      <div class="star-top-widget star-relation" id="star" style="margin-top: 0">
        <div class="star-top-hd">
          <h2 style="margin: 0">{{ $t("message.tips71") }}</h2>
          <div class="module-paging fl">
            <!-- <a class="prev prev_off" href="javascript:;"></a> -->
            <a class="prev" @click="reducerelationship" href="javascript:;"></a>
            <a class="next" @click="addrelationship" href="javascript:;"></a>
          </div>
        </div>
        <div class="star-top-bd">
          <ul style="width: 448px">
            <li v-for="item in relationshipArrsubset" :key="item">
              <a :title="item.relationship + ' ' + item.fullName" style="padding-left: 0">
                <!-- <img :src="item.photo" /> -->
                <n-image :src="item.photo" />
                <div class="mask"></div>
                <p>{{ item.relationship }}&nbsp;{{ item.fullName }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="star-top-widget hot-album" id="hot" style="margin-top: 0">
        <div class="star-top-hd">
          <h2 style="margin: 0">{{ $t("message.album") }}</h2>
          <div class="module-paging fr">
            <a class="prev" href="javascript:;" @click="reduceImages"></a>
            <a class="next" href="javascript:;" @click="addImages"></a>
          </div>
        </div>
        <div class="star-top-bd">
          <ul style="width: 2800px">
            <li v-for="item in imagesArrsubset" :key="item">
              <a :title="item.title" data-log="bk_hottp1" style="padding-left: 0">
                <!-- <img :src="item.imgUrl" :alt="item.title" /> -->
                <n-image :src="item.imgUrl" :alt="item.title" />
                <div class="mask"></div>
                <p>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="star-top-widget praise-news">
        <div class="columns ctxx" style="padding: 13px 0 0 0">
          <h2 class="col-h2">{{ $t("message.deceased_admin") }}</h2>
          <dl class="col-dl twhp2">
            <dd>
              <a class="a-img1">
                <img
                  :src="infodata.avatar"
                  width="50"
                  height="50"
                  style="border-radius: 24px"
                />
              </a>
            </dd>
            <dd>
              <span class="gray9">{{ $t("message.admin") }}</span>
              <a class="s-msg clink" target="_blank">{{ infodata.nickname }}</a>

              <!-- <em class="f12">
                <img src="https://renwu.rbaike.com/style/default/jb.gif" title="金币" />
                4034</em
              > -->
            </dd>
            <dd>
              <span class="gray9">{{ $t("message.the_deceased") }}</span>
              <a class="s-msg clink">{{ deceasedInfo.dname }}</a>
            </dd>
          </dl>
          <ul class="col-ul" style="font-size: 14px; margin: 8px 6px 5px 25px">
            <li>
              <span class="gray" style="display: inline-block"
                >{{ $t("message.number_of_views") }}：</span
              >{{ infouser.bnum }}
            </li>
            <!-- <li>
              <span class="gray">{{ $t("message.update_time") }}：</span>2021-10-29
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="newslist-content clearfix">
      <div class="newslist-left newsdetail-left">
        <div class="border-bg">
          <div class="detail-content">
            <div class="module">
              <div class="mod-title" style="position: relative">
                <h3>{{ $t("message.basic_info") }}</h3>
                <div
                  @click="basicView"
                  style="
                    position: absolute;
                    right: 14px;
                    top: 0;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  <create-outline
                    style="vertical-align: middle; width: 18px"
                  ></create-outline>
                  <!-- {{ $t("message.edit") }} -->
                </div>
              </div>
              <div class="mod-content">
                <ul class="basic-info clearfloat">
                  <li>
                    <b>{{ $t("message.full_name") }}：</b>{{ deceasedInfo.dname }}
                  </li>
                  <li v-if="deceasedInfo.alias">
                    <b>{{ $t("message.alias") }}：</b>{{ deceasedInfo.alias }}
                  </li>
                  <li v-if="deceasedInfo.byear">
                    <b>{{ $t("message.date_of_birth") }}：</b>
                    {{
                      deceasedInfo.byear < 0
                        ? $t("message.B_C") + " " + Math.abs(deceasedInfo.byear)
                        : deceasedInfo.byear
                    }}-{{
                      deceasedInfo.bmoon > 9
                        ? deceasedInfo.bmoon
                        : "0" + deceasedInfo.bmoon
                    }}-{{
                      deceasedInfo.bday > 9 ? deceasedInfo.bday : "0" + deceasedInfo.bday
                    }}
                  </li>
                  <li v-else>
                    <b>{{ $t("message.date_of_birth") }}：</b>
                    {{ $t("message.unknown") }}
                  </li>
                  <li v-if="deceasedInfo.year">
                    <b>{{ $t("message.date_of_death") }}：</b>
                    {{
                      deceasedInfo.year < 0
                        ? $t("message.B_C") + " " + Math.abs(deceasedInfo.year)
                        : deceasedInfo.year
                    }}-{{
                      deceasedInfo.moon > 9 ? deceasedInfo.moon : "0" + deceasedInfo.moon
                    }}-{{
                      deceasedInfo.day > 9 ? deceasedInfo.day : "0" + deceasedInfo.day
                    }}
                  </li>
                  <li v-else>
                    <b>{{ $t("message.date_of_death") }}：</b>
                    {{ $t("message.unknown") }}
                  </li>
                  <li>
                    <b>{{ $t("message.die_at_the_age_of") }}：</b
                    >{{ deceasedInfo.dieAge ? deceasedInfo.dieAge : "未知" }}
                  </li>
                  <li>
                    <b>{{ $t("message.occupation") }}：</b>{{ deceasedInfo.occupation }}
                  </li>
                  <li v-if="deceasedInfo.language">
                    <b>{{ $t("message.language") }}：</b>{{ deceasedInfo.language }}
                  </li>
                  <li v-if="deceasedInfo.nationality">
                    <b>{{ $t("message.nationality") }}：</b>{{ deceasedInfo.nationality }}
                  </li>
                  <li v-if="deceasedInfo.universities">
                    <b>{{ $t("message.university") }}：</b>{{ deceasedInfo.universities }}
                  </li>
                  <li v-if="deceasedInfo.height">
                    <b>{{ $t("message.height") }}：</b>{{ deceasedInfo.height }}
                    {{ deceasedInfo.heightunit }}
                  </li>
                  <li v-if="deceasedInfo.constellation">
                    <b>{{ $t("message.constellation") }}：</b
                    >{{ deceasedInfo.constellation }}
                  </li>
                  <li
                    v-if="deceasedInfo.motto"
                    style="white-space: break-spaces; line-height: inherit"
                  >
                    <b>{{ $t("message.motto") }}：</b>{{ deceasedInfo.motto }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newslist-right" style="margin-top: 10px">
        <div
          style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04)"
          class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div>
            <div>
              <div class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter">
                <div class="f16 fm cla keepfly-box-item-flex" style="align-self: center">
                  {{ $t("message.tools") }}
                </div>
              </div>
              <div class="keepfly-divider-main keepfly-divider-x"></div>
            </div>
          </div>
          <div>
            <div class="wbpro-side-card-3 keepfly-box-flex keepfly-box-column col-4">
              <div class="row keepfly-box-flex">
                <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    style="cursor: pointer"
                    @click="enterrelationship"
                  >
                    <n-tooltip trigger="hover">
                      <template #trigger>
                        <div
                          class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                        >
                          <people-outline
                            class="keepfly-font keepfly-font--ccnavData SideCard3_icon SideCard3_c1"
                          ></people-outline>
                          <div class="f12 cla wbpro-textcut SideCard3_name">
                            {{ $t("message.input_relationship") }}
                          </div>
                        </div>
                      </template>
                      {{ $t("message.input_relationship") }}
                    </n-tooltip>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a class="ALink_none" style="cursor: pointer" @click="entercontent">
                    <n-tooltip trigger="hover">
                      <template #trigger>
                        <div
                          class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                        >
                          <library
                            class="keepfly-font keepfly-font--ccnavContent SideCard3_icon SideCard3_c2"
                          ></library>
                          <div class="f12 cla wbpro-textcut SideCard3_name">
                            {{ $t("message.content_addition") }}
                          </div>
                        </div>
                      </template>
                      {{ $t("message.content_addition") }}
                    </n-tooltip>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a class="ALink_none" style="cursor: pointer" @click="enterimages">
                    <n-tooltip trigger="hover">
                      <template #trigger>
                        <div
                          class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                        >
                          <image-outline
                            class="keepfly-font keepfly-font--ccnavProfit SideCard3_icon SideCard3_c3"
                          ></image-outline>
                          <div class="f12 cla wbpro-textcut SideCard3_name">
                            {{ $t("message.image_upload") }}
                          </div>
                        </div>
                      </template>
                      {{ $t("message.image_upload") }}
                    </n-tooltip>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    @click="getdeceasedonlineView"
                    style="cursor: pointer"
                  >
                    <n-tooltip trigger="hover">
                      <template #trigger>
                        <div
                          :title="$t('message.message_management')"
                          class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                        >
                          <ellipsis-horizontal-circle
                            class="keepfly-font keepfly-font--ccnavFans SideCard3_icon SideCard3_c4"
                          ></ellipsis-horizontal-circle>
                          <div class="f12 cla wbpro-textcut SideCard3_name">
                            {{ $t("message.message_management") }}
                          </div>
                        </div>
                      </template>
                      {{ $t("message.message_management") }}
                    </n-tooltip>
                  </a>
                </div>
              </div>
              <!-- <a class="ALink_none" style="cursor: pointer">
                <div
                  class="wbpro-side-opt f13 clf keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter SideCard3_btn"
                >
                  <span>进入</span>
                </div>
              </a> -->
            </div>
          </div>
        </div>
        <div class="news-info border-bg">
          <div class="news-info-title">
            <div class="widget">
              <h3 style="position: relative">
                <span>{{ $t("message.deceased_label") }}</span>
                <div
                  @click="sendTagsView"
                  style="
                    position: absolute;
                    right: 14px;
                    top: 0;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  <create-outline
                    style="vertical-align: middle; width: 18px"
                  ></create-outline>
                  <!-- {{ $t("message.edit") }} -->
                </div>
              </h3>
              <div class="widge_tags">
                <div class="tag-items">
                  <a v-for="item in TagTxtList" :key="item">{{ item.tagLabel }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newslist-content clearfix" v-for="item in ContentArr" :key="item">
      <div class="newslist-left newsdetail-left">
        <div class="border-bg">
          <div class="detail-content">
            <div class="module">
              <div class="mod-title">
                <h3 v-if="item.modelName == '1'">
                  {{ $t("message.early_experiences") }}
                </h3>
                <h3 v-else-if="item.modelName == '2'">
                  {{ $t("message.deductive_experience") }}
                </h3>
                <h3 v-else-if="item.modelName == '3'">
                  {{ $t("message.personal_life") }}
                </h3>
                <h3 v-else-if="item.modelName == '4'">
                  {{ $t("message.award_records") }}
                </h3>
                <h3 v-else-if="item.modelName == '5'">
                  {{ $t("message.character_evaluation") }}
                </h3>
                <h3 v-else-if="item.modelName == '6'">
                  {{ $t("message.other") }}
                </h3>
                <h3 v-else>{{ item.modelName }}</h3>
                <div
                  @click="updataContentView(item)"
                  style="
                    position: absolute;
                    right: 14px;
                    top: 0;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  <create-outline
                    style="vertical-align: middle; width: 18px"
                  ></create-outline>
                  <!-- {{ $t("message.edit") }} -->
                </div>
              </div>
              <div class="mod-content" v-if="item.imgUrl">
                <p
                  style="
                    width: 70%;
                    float: left;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  {{ item.modelContent }}
                </p>
                <img
                  :src="item.imgUrl"
                  style="width: 220px; height: 231px; float: right"
                />
                <div style="clear: both"></div>
              </div>
              <div class="mod-content" v-else>
                <p style="white-space: pre-wrap">
                  {{ item.modelContent }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="newslist-content clearfix"
      style="
        height: 78px;
        line-height: 25px;
        padding: 10px;
        clear: both;
        text-align: center;
        color: gray;
      "
    >
      <p>{{ $t("message.tips108") }}</p>
      <p>{{ $t("message.tips101") }}</p>
    </div>
    <n-modal
      v-model:show="showHeadModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.modify')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div>
        <span>{{ $t("message.front_cover") }}：</span>
        <img
          :src="deceasedInfo.brand"
          v-if="deceasedInfo.brand"
          style="width: 96px; height: 96px; margin-right: 10px; display: inline-block"
        />
        <div
          class="n-upload-trigger n-upload-trigger--image-card"
          style="display: inline-block"
          @click="djFileView"
        >
          <div class="n-upload-dragger">
            <i class="n-base-icon">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 112V400M400 256H112"
                  stroke="currentColor"
                  stroke-width="32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </div>
        </div>
        <div
          style="
            display: table-row-group;
            width: 172px;
            font-size: 12px;
            color: grey;
            margin-left: 10px;
          "
        >
          {{ $t("message.tips159") }}
        </div>
        <input
          type="file"
          accept=".jpg, .jpeg, .bmp, .gif, .png"
          ref="deceasedbrand_file"
          @change="selectBrandForImg"
          style="display: none"
        />
      </div>
      <div>
        <span>{{ $t("message.avatar") }}：</span>
        <img
          :src="deceasedInfo.headSculpture"
          v-if="deceasedInfo.headSculpture"
          style="width: 96px; height: 96px; margin-right: 10px; display: inline-block"
        />
        <div
          class="n-upload-trigger n-upload-trigger--image-card"
          style="display: inline-block"
          @click="djheadSculptureFileView"
        >
          <div class="n-upload-dragger">
            <i class="n-base-icon">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 112V400M400 256H112"
                  stroke="currentColor"
                  stroke-width="32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </div>
        </div>
        <div
          style="
            display: table-row-group;
            width: 172px;
            font-size: 12px;
            color: grey;
            margin-left: 10px;
          "
        >
          {{ $t("message.tips158") }}
        </div>
        <input
          type="file"
          accept=".jpg, .jpeg, .bmp, .gif, .png"
          ref="deceasedheadSculpture_file"
          @change="selectheadSculptureForImg"
          style="display: none"
        />
      </div>
      <div style="margin-top: 10px">
        <span>{{ $t("message.brief") }}：</span>
        <n-input
          v-model:value="deceasedInfo.achievement"
          placeholder="Textarea"
          type="textarea"
          status="warning"
          :autosize="{
            minRows: 3,
            maxRows: 9,
          }"
          style="width: 80%; vertical-align: middle"
        />
      </div>
      <template #footer>
        <n-button @click="senddeceasedInfo" type="warning">
          {{ $t("message.confirm") }}
        </n-button>
      </template>
    </n-modal>
    <n-modal
      v-model:show="showTagsModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.deceased_label')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="formValue"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.label')">
          <n-input
            v-model:value="formValue.tagtxt"
            status="warning"
            :placeholder="$t('message.label')"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="handleTagTxtClick">
            {{ $t("message.increase") }}
          </n-button>
        </n-form-item>
      </n-form>
      <n-data-table
        :columns="columns"
        :data="TagTxtList"
        :pagination="pagination"
        :bordered="false"
      />
    </n-modal>
    <n-modal
      v-model:show="basicInfoShow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.basic_info')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="deceasedInfo"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.full_name')">
          <n-input
            v-model:value="deceasedInfo.dname"
            status="warning"
            :placeholder="$t('message.full_name')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.alias')">
          <n-input
            v-model:value="deceasedInfo.alias"
            status="warning"
            :placeholder="$t('message.alias')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.date_of_birth')">
          <!-- <n-input
            v-model:value="deceasedInfo.dateOfBirth"
            status="warning"
            :placeholder="$t('message.date_of_birth')"
          /> -->
          <n-input-number
            status="warning"
            v-model:value="deceasedInfo.byear"
            :placeholder="$t('message.year')"
            :style="{ width: '20%' }"
          />
          <n-select
            status="warning"
            v-model:value="deceasedInfo.bmoon"
            :style="{ width: '20%' }"
            :placeholder="$t('message.moon')"
            :options="moonoptions"
          />
          <n-select
            status="warning"
            v-model:value="deceasedInfo.bday"
            :style="{ width: '20%' }"
            :placeholder="$t('message.day')"
            :options="dayoptions"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <n-switch v-model:value="bdisabled" />{{ $t("message.unknown") }}
        </n-form-item>
        <n-form-item :label="$t('message.date_of_death')">
          <!-- <n-input
            v-model:value="deceasedInfo.dateOfDeath"
            status="warning"
            :placeholder="$t('message.date_of_death')"
          /> -->
          <n-input-number
            status="warning"
            v-model:value="deceasedInfo.year"
            :placeholder="$t('message.year')"
            :style="{ width: '20%' }"
          />
          <n-select
            status="warning"
            v-model:value="deceasedInfo.moon"
            :style="{ width: '20%' }"
            :placeholder="$t('message.moon')"
            :options="moonoptions"
          />
          <n-select
            status="warning"
            v-model:value="deceasedInfo.day"
            :style="{ width: '20%' }"
            :placeholder="$t('message.day')"
            :options="dayoptions"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <n-switch v-model:value="disabled" />{{ $t("message.unknown") }}
        </n-form-item>
        <n-form-item :label="$t('message.die_at_the_age_of')">
          <n-input-number
            v-model:value="deceasedInfo.dieAge"
            :precision="1"
            status="warning"
            :placeholder="$t('message.die_at_the_age_of')"
            :min="0"
          />
        </n-form-item>
        <n-form-item :label="$t('message.occupation')">
          <n-input
            v-model:value="deceasedInfo.occupation"
            status="warning"
            :placeholder="$t('message.occupation')"
            :style="{ width: '60%' }"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <n-switch v-model:value="odisabled" />{{ $t("message.unknown") }}
        </n-form-item>
        <n-form-item :label="$t('message.language')">
          <n-input
            v-model:value="deceasedInfo.language"
            status="warning"
            :placeholder="$t('message.language')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.nationality')">
          <n-input
            v-model:value="deceasedInfo.nationality"
            status="warning"
            :placeholder="$t('message.nationality')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.university')">
          <n-input
            v-model:value="deceasedInfo.universities"
            status="warning"
            :placeholder="$t('message.university')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.height')">
          <n-input
            v-model:value="deceasedInfo.height"
            status="warning"
            :placeholder="$t('message.height')"
            :style="{ width: '60%' }"
          />
          <n-select
            status="warning"
            v-model:value="deceasedInfo.heightunit"
            :style="{ width: '40%' }"
            :placeholder="$t('message.height_unit')"
            :options="heightunitoptions"
          />
        </n-form-item>
        <n-form-item :label="$t('message.constellation')">
          <n-input
            v-model:value="deceasedInfo.constellation"
            status="warning"
            :placeholder="$t('message.constellation')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.motto')">
          <n-input
            v-model:value="deceasedInfo.motto"
            status="warning"
            :placeholder="$t('message.motto')"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="basicInfoClick">
            {{ $t("message.submit") }}
          </n-button>
        </n-form-item>
      </n-form>
      <div style="font-size: 12px; color: darkgray">
        {{ $t("message.tips144") }}
      </div>
    </n-modal>
    <n-modal
      v-model:show="relationshipshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.relationship')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="relationshipInfo"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.photograph')">
          <img
            :src="relationshipInfo.photo"
            v-if="relationshipInfo.photo"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djphotoFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips161") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="photo_file"
            @change="selectPhotoImg"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.full_name')">
          <n-input
            v-model:value="relationshipInfo.fullName"
            status="warning"
            :placeholder="$t('message.full_name')"
          />
        </n-form-item>
        <n-form-item :label="$t('message.relationship')">
          <n-input
            v-model:value="relationshipInfo.relationship"
            status="warning"
            :placeholder="$t('message.tips157')"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="handleRelationshipClick">
            {{ $t("message.increase") }}
          </n-button>
        </n-form-item>
      </n-form>
      <!-- <n-data-table
        class="imagesData"
        :columns="relationshipcolumns"
        :data="relationshipArr"
        :pagination="pagination"
        :bordered="false"
      /> -->
      <div class="drag-box">
        <Draggable
          :list="relationshipArr"
          :animation="100"
          item-key="id"
          class="list-group"
          :forceFallback="true"
          ghost-class="ghost"
          @change="onMoveCallback"
        >
          <template #item="{ element }">
            <div class="items">
              <table style="width: 100%; cursor: pointer">
                <tr>
                  <td style="width: 25%">
                    <img :src="element.photo" style="height: 60px" />
                  </td>
                  <td style="width: 25%">{{ element.fullName }}</td>
                  <td style="width: 25%">{{ element.relationship }}</td>
                  <td style="width: 25%" @click="deleterelationshipInfo(element.id)">
                    ×
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </Draggable>
      </div>
    </n-modal>

    <n-modal
      v-model:show="imagesshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.picture')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="imagesInfo"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.photograph')">
          <img
            :src="imagesInfo.imgUrl"
            v-if="imagesInfo.imgUrl"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djimagesInfoFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips161") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="images_file"
            @change="selectimagesInfo"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.title')">
          <n-input
            v-model:value="imagesInfo.title"
            status="warning"
            :placeholder="$t('message.title')"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="handleImagesClick">
            {{ $t("message.increase") }}
          </n-button>
        </n-form-item>
      </n-form>
      <!-- <n-data-table
        class="imagesData"
        :columns="imagescolumns"
        :data="imagesArr"
        :pagination="pagination"
        :bordered="false"
      /> -->
      <Draggable
        :list="imagesArr"
        :animation="100"
        item-key="id"
        class="list-group"
        :forceFallback="true"
        ghost-class="ghost"
        @change="onImagesMoveCallback"
      >
        <template #item="{ element }">
          <div class="items">
            <table style="width: 100%; cursor: pointer">
              <tr>
                <td style="width: 25%">
                  <img :src="element.imgUrl" style="height: 60px" />
                </td>
                <td style="width: 25%">{{ element.title }}</td>
                <td style="width: 25%" @click="deleteImagesInfo(element.id)">×</td>
              </tr>
            </table>
          </div>
        </template>
      </Draggable>
    </n-modal>

    <n-modal
      v-model:show="contentshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.add_section')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="contentInfo"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.photograph')">
          <img
            :src="contentInfo.imgUrl"
            v-if="contentInfo.imgUrl"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djContentFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips160") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="Content_file"
            @change="selectContentInfo"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.module_name')">
          <n-select
            v-model:value="contentInfo.modelName"
            filterable
            status="warning"
            tag
            :options="options"
          />
        </n-form-item>
        <n-form-item :label="$t('message.content')">
          <n-input
            v-model:value="contentInfo.modelContent"
            status="warning"
            :placeholder="$t('message.content')"
            type="textarea"
            size="small"
            :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="handleContentClick">
            {{ $t("message.increase") }}
          </n-button>
        </n-form-item>
      </n-form>
    </n-modal>
    <n-modal
      v-model:show="updataContentShow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.modify_section')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="updataContentInfo"
        :style="{
          maxWidth: '640px',
        }"
        :size="size"
      >
        <n-form-item :label="$t('message.photograph')">
          <img
            :src="updataContentInfo.imgUrl"
            v-if="updataContentInfo.imgUrl"
            style="width: 96px; height: 96px; margin-right: 10px"
          />
          <div
            class="n-upload-trigger n-upload-trigger--image-card"
            @click="djuqContentFileView"
          >
            <div class="n-upload-dragger">
              <i class="n-base-icon">
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 112V400M400 256H112"
                    stroke="currentColor"
                    stroke-width="32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div style="width: 172px; font-size: 12px; color: grey; margin-left: 10px">
            {{ $t("message.tips160") }}
          </div>
          <input
            type="file"
            accept=".jpg, .jpeg, .bmp, .gif, .png"
            ref="uqContent_file"
            @change="selectuqContentInfo"
            style="display: none"
          />
        </n-form-item>
        <n-form-item :label="$t('message.section')">
          <n-select
            v-model:value="updataContentInfo.modelName"
            filterable
            status="warning"
            tag
            :options="options"
          />
        </n-form-item>
        <n-form-item :label="$t('message.content')">
          <n-input
            v-model:value="updataContentInfo.modelContent"
            status="warning"
            :placeholder="$t('message.content')"
            type="textarea"
            size="small"
            :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
          />
        </n-form-item>
        <n-form-item>
          <n-button attr-type="button" type="warning" @click="handleuqContentClick">
            {{ $t("message.modify_section") }}
          </n-button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <n-button attr-type="button" type="error" @click="handledelContentClick">
            {{ $t("message.tips141") }}
          </n-button>
        </n-form-item>
      </n-form>
    </n-modal>
    <n-modal
      v-model:show="deceasedonlineshow"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.guestbook')"
    >
      <div style="margin-bottom: 10px">
        <n-input
          v-model:value="guestbookcontent.content"
          status="warning"
          type="textarea"
          size="large"
          style="width: 80%; vertical-align: top; margin-right: 10px"
          :placeholder="$t('message.tips67')"
        />
      </div>
      <div class="index_box2 grayTheme">
        <div style="width: 50%" v-show="ImageList.length > 0">
          <div class="u-col-3" style="margin-left: -0.25rem; margin-top: -0.25rem">
            <div
              class="keepfly-box-item-inlineBlock"
              v-for="(item, index) in ImageList"
              :key="item"
              style="padding-left: 0.25rem; padding-top: 0.25rem"
            >
              <div class="Image_picbed">
                <div
                  class="keepfly-picture-main keepfly-picture-square keepfly-picture-hover Image_pic"
                >
                  <img :src="item" class="keepfly-picture-img" />
                  <div class="keepfly-picture-cover"></div>
                  <div class="keepfly-picture-hoverMask"></div>
                </div>
                <close
                  @click="imageClose(index)"
                  :title="$t('message.delete')"
                  class="keepfly-font keepfly-font--close keepfly-fonticon-multi keepfly-fonticon-dark keepfly-fonticon-abovePic Image_close"
                >
                </close>
              </div>
            </div>
            <div
              class="keepfly-box-item-inlineBlock"
              style="padding-left: 0.25rem; padding-top: 0.25rem"
            >
              <div class="Image_picbed" @click="imageView">
                <div
                  class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter keepfly-box-justifyCenter FileUpload_box"
                >
                  <add class="keepfly-font keepfly-font--add FileUpload_icon_view"></add>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .bmp, .gif, .png, .heif, .heic"
                    multiple="multiple"
                    @change="uploadURL"
                    ref="FileUpload_file"
                    style="display: none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grayTheme">
        <div class="keepfly-box-flex keepfly-box-alignCenter">
          <div class="keepfly-box-item-flex" style="align-self: center">
            <div class="Tool_mar1">
              <div class="keepfly-box-item-inlineBlock Tool_iconitem">
                <div
                  class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
                  :title="$t('message.emote')"
                  @click="emoticonView"
                >
                  <happy-outline
                    class="keepfly-font keepfly-font--emoticon"
                  ></happy-outline>
                  <!-- <span class="text">{{ $t("message.emote") }}</span> -->
                </div>
              </div>
              <div class="keepfly-box-item-inlineBlock Tool_iconitem">
                <div class="keepfly-pop-wrap">
                  <span class="keepfly-pop-ctrl">
                    <div
                      class="wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                      :title="$t('message.picture')"
                      @click="imageView"
                    >
                      <images-outline
                        class="keepfly-font keepfly-font--image"
                      ></images-outline>
                      <!-- <span class="text abc">{{ $t("message.picture") }}</span> -->
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="Tool_check">
            <div class="Tool_checkc"></div>
            <button
              @click="sendguestbook"
              class="keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Tool_btn"
            >
              <span class="keepfly-button-wrap">
                <span class="keepfly-button-content">
                  <!-- {{ $t("message.send") }} -->
                  <svg
                    style="width: 22px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                      ></path>
                    </g>
                  </svg>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="wbpro-pos">
        <div>
          <div class="keepfly-pop-wrap Emoticon_box1">
            <span class="keepfly-pop-ctrl"></span>
            <div
              class="keepfly-pop-main keepfly-pop-down keepfly-pop-start"
              v-if="Emoticon_show"
            >
              <div class="Emoticon_tab">
                <div class="keepfly-divider-main keepfly-divider-x"></div>
                <div class="Emoticon_facebox1">
                  <div class="u-col-12 emoticon">
                    <div
                      class="keepfly-box-item-inlineBlock"
                      v-for="item in emoticon"
                      :key="item"
                    >
                      <div
                        class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon"
                        :title="item.title"
                        @click="getemoticontxt(item.value)"
                      >
                        <img :src="item.path" :alt="item.name" class="Emoticon_img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="margin-bottom: 10px"
        v-for="(item, index) in deceasedonlineArr"
        :key="item"
      >
        <n-card size="small" hoverable>
          <div class="onimg" v-html="filters(item.content)"></div>
          <div class="content_row" v-if="item.images != []">
            <div class="u-col-3 keepfly-box-wrap picture_inlineNum3">
              <n-image-group>
                <n-space>
                  <n-image
                    width="133"
                    height="133"
                    v-for="oitem in item.images"
                    :key="oitem"
                    :src="oitem"
                    style="border-radius: 15px"
                  />
                </n-space>
              </n-image-group>
            </div>
          </div>
          <template #footer>
            <div style="text-align: right">
              <span
                @click="delonline(item, index)"
                style="float: left; cursor: pointer; font-size: 12px; color: darkgray"
              >
                <!-- {{ $t("message.delete") }} -->
                <svg
                  style="width: 19px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 20 20"
                >
                  <g fill="none">
                    <path
                      d="M11.5 4a1.5 1.5 0 0 0-3 0h-1a2.5 2.5 0 0 1 5 0H17a.5.5 0 0 1 0 1h-.554L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.553 5H3a.5.5 0 0 1-.492-.41L2.5 4.5A.5.5 0 0 1 3 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 0 0 .994.885h6.326a1 1 0 0 0 .993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437c-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </span>
              <img
                @click="checkUserInfo(item, 1)"
                :src="item.avatar"
                style="width: 36px; margin-right: 10px; border-radius: 18px; height: 36px"
              />{{ item.nickname }}<br />
              <div>{{ item.createTime }}</div>
              <div style="clear: both"></div>
            </div>
          </template>
        </n-card>
      </div>
      <n-pagination
        v-model:page="currentPage"
        :page-count="pagenum"
        @update-page="pagingdeceasedonlineNum"
      />
    </n-modal>
    <n-modal
      v-model:show="showUserInfoModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.userinfo')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
      @scroll="userInfoRef($event)"
      style="overflow-y: scroll; height: 100vh"
    >
      <div>
        <div
          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div class="wbpro-pos">
            <div class="keepfly-picture-main ProfileHeader_pic">
              <div style="padding-bottom: 56.25%"></div>
              <img :src="kukesystemInfo.bjurl" class="keepfly-picture-img" />
              <div class="keepfly-picture-cover"></div>
            </div>
          </div>
          <div class="keepfly-box-flex keepfly-box-alignStart ProfileHeader_box1">
            <div
              class="keepfly-avatar-main keepfly-avatar-hover ProfileHeader_avatar2"
              style="width: 6.25rem; height: 6.25rem"
            >
              <img
                src="../../assets/default_avatar_male_180.gif"
                class="keepfly-avatar-img"
                v-if="keepflyUserInfo.avatar == null"
              />
              <img :src="keepflyUserInfo.avatar" class="keepfly-avatar-img" v-else />
              <div class="keepfly-avatar-hoverMask"></div>
            </div>
            <div class="keepfly-box-item-flex" style="overflow: hidden">
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h3">
                <div class="ProfileHeader_name">
                  {{ keepflyUserInfo.nickname }}
                </div>
                <span class="keepfly-icon-wrap" style="margin-left: 5px">
                  <male
                    class="keepfly-icon-main keepfly-icon--male"
                    v-if="keepflyUserInfo.gender == 1"
                    style="width: 1rem; height: 1rem"
                  ></male>
                  <female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else-if="keepflyUserInfo.gender == 2"
                    style="width: 1rem; height: 1rem"
                  >
                  </female>
                  <male-female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else
                    style="width: 1rem; height: 1rem"
                  ></male-female>
                </span>
              </div>
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4">
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.fans") }}<span>{{ userNum.ffnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.follow") }}<span>{{ userNum.fnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.effect") }}<span>{{ userNum.num }}</span>
                  </span>
                </a>
              </div>
              <div
                class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                style="font-size: 13px"
              >
                ID：{{ keepflyUserInfo.kpId }}{{ keepflyUserInfo.id == userInfo.id }}
              </div>
            </div>
            <div style="margin-right: 26px; padding-top: 33px">
              <n-button
                v-if="keepflyUserInfo.id == userInfo.id"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <n-icon>
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </n-icon>
                </template>
              </n-button>
              <n-button
                v-else-if="keepflyUserInfo.friendUid == null"
                @click="addfriend(keepflyUserInfo, 3)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <svg
                    style="width: 19px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </template>
              </n-button>
              <n-button
                v-else
                @click="cancelfollow(keepflyUserInfo, 2)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <svg
                    style="width: 19px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104l-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </template>
              </n-button>
              &nbsp;&nbsp;
              <n-button
                style="background: purple; color: #fff"
                strong
                secondary
                round
                size="small"
                @click="senduserprivateletter(keepflyUserInfo)"
              >
                <template #icon>
                  <n-icon>
                    <chatbubbles-outline style="width: 19px" />
                  </n-icon>
                </template>
                {{ $t("message.private_letter") }}
              </n-button>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="ProfileHeader_box2">
              <div class="keepfly-box-flex keepfly-box-justifyBetween">
                <div class="keepfly-box-flex keepfly-box-column ProfileHeader_content">
                  <div style="margin-bottom: 12px">
                    <div class="keepfly-box-item-inlineBlock">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <document-text-outline
                            class="keepfly-font keepfly-font--proBintro"
                          ></document-text-outline>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{
                            keepflyUserInfo.profile == null ||
                            keepflyUserInfo.profile == ""
                              ? $t("message.tips16")
                              : keepflyUserInfo.profile
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3">
              <div style="position: relative">
                <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                  <div class="keepfly-box-flex keepfly-box-alignStart">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                    >
                      <calendar-outline
                        class="keepfly-font keepfly-font--proTime"
                      ></calendar-outline>
                    </div>
                    <div
                      class="keepfly-box-item-flex ProfileHeader_con3"
                      v-if="keepflyUserInfo.createTime"
                    >
                      {{ keepflyUserInfo.createTime.substring(0, 10) }}
                      {{ $t("message.add") }} keepfly
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    color: darkgray;
                    width: 70px;
                    height: 32px;
                    top: 0;
                    right: 20px;
                    cursor: pointer;
                  "
                  class="ProfileHeader_con3"
                  @click="senduserblacklist(keepflyUserInfo)"
                >
                  {{ $t("message.tips122") }}
                </div>
                <div
                  class="keepfly-box-item-inlineBlock ProfileHeader_item3"
                  style="display: none"
                >
                  <div class="keepfly-box-flex keepfly-box-alignStart">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                    >
                      <ribbon-outline
                        class="keepfly-font keepfly-font--proCredit"
                      ></ribbon-outline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="wbpro-screen-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
        >
          <div>{{ $t("message.all") }} keepfly（{{ userNum.mnum }}）</div>
        </div>
        <div>
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    style="min-height: 684px"
                  >
                    <div class="">
                      <div
                        class="wbpro-scroller-item"
                        v-for="item in usermicroblogs"
                        :key="item"
                      >
                        <article
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                          tabindex="0"
                          style="border-radius: 4px"
                        >
                          <div class="Feed_body">
                            <header class="keepfly-box-flex">
                              <a class="ALink_default">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                  style="width: 3.25rem; height: 3.25rem"
                                >
                                  <img :src="item.avatar" class="keepfly-avatar-img" />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                              </a>
                              <div class="keepfly-box-item-flex head_main">
                                <div
                                  class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                  >
                                    <a class="ALink_default head_cut head_name">
                                      <span :title="item.nickname">{{
                                        item.nickname
                                      }}</span>
                                    </a>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter"
                                    ></div>
                                  </div>
                                  <div
                                    class="keepfly-box-alignCenter keepfly-box-justifyCenter head-info_info"
                                  >
                                    <a class="head-info_time">
                                      {{ item.createTime }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="keepfly-box-flex">
                                <div class="keepfly-pop-wrap morepop_more">
                                  <span class="keepfly-pop-ctrl">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                    >
                                      <chevron-down
                                        class="keepfly-font keepfly-font--angleDown morepop_action"
                                        style="width: 16px"
                                      ></chevron-down>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </header>
                            <div class="Feed_retweet">
                              <div class="detail_text detail_reText wbpro-feed-reText">
                                <n-ellipsis
                                  expand-trigger="click"
                                  line-clamp="4"
                                  :tooltip="false"
                                >
                                  <div
                                    class="detail_wbtext"
                                    v-html="filters(item.content)"
                                  ></div>
                                </n-ellipsis>
                              </div>
                              <div class="picture picture-box_row">
                                <span
                                  v-for="titem in item.tags"
                                  :key="titem"
                                  style="
                                    color: #ff8200;
                                    padding-right: 10px;
                                    font-size: 12px;
                                  "
                                  :title="titem"
                                >
                                  {{ uqTxtTranslate(titem) }}
                                </span>
                              </div>
                              <div
                                class="picture picture-box_row"
                                v-if="item.images != []"
                              >
                                <div class="u-col-3 keepfly-box-wrap picture_inlineNum3">
                                  <n-image-group>
                                    <n-space>
                                      <n-image
                                        width="133"
                                        height="133"
                                        v-for="oitem in item.images"
                                        :key="oitem"
                                        :src="oitem"
                                        style="border-radius: 15px"
                                      />
                                    </n-space>
                                  </n-image-group>
                                </div>
                              </div>
                              <div v-if="item.videoInfo">
                                <video
                                  controls="controls"
                                  :src="item.videoInfo"
                                  class="video"
                                  style="
                                      width: 400px;
                                      vertical-align: middle;
                                      height: 225px;
                                      background: #000;
                                      border-radius: 10px;
                                    "
                                ></video>
                              </div>
                            </div>
                          </div>
                          <footer class="">
                            <div
                              class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                            >
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  @click="getCommentView(item, 1)"
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  style="cursor: pointer"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                  >
                                    <ellipsis-horizontal-circle
                                      class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                    ></ellipsis-horizontal-circle>
                                  </div>
                                  <span class="toolbar_num"> {{ item.cnum }} </span>
                                </div>
                              </div>
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                >
                                  <button
                                    @click="likeClick(item)"
                                    class="keepfly-like-main toolbar_btn"
                                  >
                                    <svg
                                      style="width: 19px"
                                      class="keepfly-like-iconWrap"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 32 32"
                                    >
                                      <path
                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <span class="keepfly-like-count">{{
                                      item.lnum
                                    }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </footer>
                          <div v-if="item.show">
                            <div
                              class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                            ></div>
                            <div class="Feed_box">
                              <div class="keepfly-box-flex Feed_mar2">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                  style="width: 2.125rem; height: 2.125rem"
                                >
                                  <img
                                    :src="userDInfo.avatar"
                                    class="keepfly-avatar-img"
                                  />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                                <div class="keepfly-box-item-flex">
                                  <div>
                                    <div>
                                      <div
                                        class="wbpro-form Form_wbproform"
                                        compser="true"
                                      >
                                        <textarea
                                          :placeholder="$t('message.tips31')"
                                          v-model="item.commentValue"
                                          maxlength="100"
                                          class="Form_input"
                                          style="height: 84px"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="Composer_mar1">
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter"
                                      >
                                        <button
                                          @click="sendInfoComment(item)"
                                          class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">{{
                                              $t("message.comment")
                                            }}</span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                              ></div>
                              <div>
                                <div>
                                  <div class="RepostCommentFeed_mar1">
                                    <div class="wbpro-list">
                                      <div
                                        class="item1"
                                        v-for="oitem in item.commentVos"
                                        :key="oitem"
                                      >
                                        <div class="item1in keepfly-box-flex">
                                          <div>
                                            <a class="ALink_default">
                                              <div
                                                class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                style="width: 2.125rem; height: 2.125rem"
                                              >
                                                <img
                                                  :src="oitem.fromUser.avatar"
                                                  class="keepfly-avatar-img"
                                                />
                                              </div>
                                            </a>
                                          </div>
                                          <div class="con1 keepfly-box-item-flex">
                                            <div class="text">
                                              <a class="ALink_default">{{
                                                oitem.fromUser.nickname
                                              }}</a>
                                              <a class="ALink_none" target="_blank"></a>:
                                              <span v-if="oitem.contentAr">{{
                                                oitem.contentAr
                                              }}</span>
                                              <span v-if="oitem.contentEn">{{
                                                oitem.contentEn
                                              }}</span>
                                              <span v-if="oitem.contentJa">{{
                                                oitem.contentJa
                                              }}</span>
                                              <span v-if="oitem.contentKo">{{
                                                oitem.contentKo
                                              }}</span>
                                              <span v-if="oitem.contentRu">{{
                                                oitem.contentRu
                                              }}</span>
                                              <span v-if="oitem.contentZh">{{
                                                oitem.contentZh
                                              }}</span>
                                            </div>
                                            <div
                                              class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                            >
                                              <div>
                                                {{ oitem.createDate }}
                                              </div>
                                              <div class="opt keepfly-box-flex">
                                                <div
                                                  class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                >
                                                  <button
                                                    class="keepfly-like-main IconList_likebox"
                                                    :title="$t('message.praise')"
                                                    @click="commentVoslikeClick(oitem)"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      style="width: 22px"
                                                      class="keepfly-like-iconWrap"
                                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                                      viewBox="0 0 32 32"
                                                    >
                                                      <path
                                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                        fill="currentColor"
                                                      ></path>
                                                    </svg>
                                                    <span class="keepfly-like-count">{{
                                                      oitem.lnum == null ? 0 : oitem.lnum
                                                    }}</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                  ></div>
                                  <a class="ALink_default" @click="morecommentVos(item)">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                    >
                                      <div
                                        v-if="
                                          item.commentVos != null &&
                                          item.commentVos.length < item.total
                                        "
                                        style="color: #ccc"
                                      >
                                        {{ $t("message.more") }}
                                      </div>
                                      <div style="color: #ccc" v-else>
                                        {{ $t("message.tips74") }}
                                      </div>
                                      <i
                                        class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                      ></i>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </n-modal>
  </div>
</template>
<script>
const createColumns = ({ play }) => {
  return [
    {
      title: "#",
      key: "key",
      render: (_, index) => {
        return `${index + 1}`;
      },
    },
    {
      title: i18n.global.t("message.label"),
      key: "tagLabel",
    },
    {
      title: i18n.global.t("message.operate"),
      key: "actions",
      render(row) {
        return h(
          NButton,
          {
            strong: true,
            tertiary: true,
            size: "small",
            onClick: () => play(row),
          },
          { default: () => "×" }
        );
      },
    },
  ];
};
const options = [
  {
    label: i18n.global.t("message.early_experiences"),
    value: 1,
  },
  {
    label: i18n.global.t("message.deductive_experience"),
    value: 2,
  },
  {
    label: i18n.global.t("message.personal_life"),
    value: 3,
  },
  {
    label: i18n.global.t("message.award_records"),
    value: 4,
  },
  {
    label: i18n.global.t("message.character_evaluation"),
    value: 5,
  },
  {
    label: i18n.global.t("message.other"),
    value: 6,
  },
];
import { defineComponent, onMounted, ref, h, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import API from "../../uilts/request";
import { useI18n } from "vue-i18n";
import Draggable from "vuedraggable";
import {
  BrushOutline,
  Library,
  Image as ImageOutline,
  PeopleOutline,
  EllipsisHorizontalCircle,
  CreateOutline,
  HappyOutline,
  ImagesOutline,
  Add,
  Close,
  ChatbubblesOutline,
} from "@vicons/ionicons5";
import i18n from "../../i18n/index";
import { client } from "../../uilts/alioss";
import {
  NEllipsis,
  NImage,
  NModal,
  NInput,
  NButton,
  useMessage,
  NPopconfirm,
  NForm,
  NFormItem,
  NDataTable,
  NSelect,
  NCard,
  // NDivider,
  NPagination,
  NTooltip,
  NImageGroup,
  NSpace,
  NInputNumber,
  NSwitch,
} from "naive-ui";
export default defineComponent({
  name: "DeceasedView",
  components: {
    BrushOutline,
    ImageOutline,
    Library,
    PeopleOutline,
    EllipsisHorizontalCircle,
    CreateOutline,
    NEllipsis,
    NModal,
    NInput,
    NButton,
    NPopconfirm,
    NForm,
    NFormItem,
    NDataTable,
    NSelect,
    NCard,
    // NDivider,
    NPagination,
    NTooltip,
    HappyOutline,
    ImagesOutline,
    Add,
    Close,
    NImageGroup,
    NSpace,
    NImage,
    NInputNumber,
    NSwitch,
    ChatbubblesOutline,
    Draggable,
  },
  setup() {
    let route = useRoute();
    const message = useMessage();
    const router = useRouter();
    const id = route.params.id;
    const infodata = ref({});
    const kukesystemInfo = ref({});
    const userInfo = ref({});
    onMounted(() => {
      userInfo.value = JSON.parse(window.localStorage.getItem("infodata"));
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getDeceasedInfo();
      getTagTxtList();
      getRelationshiplist();
      getImageslist();
      getContentList();
      getKukeLanguage();
      getDeceasedAdmin();
      // 模拟外部点击
      document.addEventListener("click", (e) => {
        let arr = [
          "u-col-12 emoticon",
          "keepfly-pop-main keepfly-pop-down keepfly-pop-start",
          "wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "wbpro-iconbed wbpro-iconbed-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter emoticon",
          "Emoticon_tab",
          "Emoticon_facebox1",
          "Emoticon_img",
          "text",
        ];
        let flat = false;
        for (let i = 0; i < arr.length; i++) {
          if (
            e.target.className == arr[i] ||
            e.target.className.animVal == "keepfly-font keepfly-font--emoticon"
          ) {
            flat = true;
            break;
          }
        }
        if (flat) {
          Emoticon_show.value = true;
        } else {
          Emoticon_show.value = false;
        }
      });
    });
    const deceasedInfo = ref({});
    const getDeceasedInfo = () => {
      let param = {
        id: id,
      };
      API({
        url: "/kuke/api/user/getdeceasedInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        deceasedInfo.value = result.data.data;
      });
    };
    const toTop = () => {
      document.documentElement.scrollTop = 0;
    };
    const showHeadModal = ref(false);
    const headView = () => {
      showHeadModal.value = true;
    };
    const deceasedbrand_file = ref();
    const djFileView = () => {
      deceasedbrand_file.value.value = "";
      deceasedbrand_file.value.click();
    };
    const deceasedheadSculpture_file = ref();
    const djheadSculptureFileView = () => {
      deceasedheadSculpture_file.value.value = "";
      deceasedheadSculpture_file.value.click();
    };
    const selectheadSculptureForImg = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          deceasedInfo.value.headSculpture = "http://img.keepfly.net/" + fileName;
        });
    };

    const selectBrandForImg = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      var fileMaxSize = 1024 * 10;
      var fileSize = file.size;
      var size = fileSize / 1024;
      if (size > fileMaxSize) {
        message.error(i18n.global.t("message.tips92"));
        return false;
      } else if (size <= 0) {
        message.error(i18n.global.t("message.tips93"));
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (evt) {
        var replaceSrc = evt.target.result;
        var image = new Image();
        image.src = replaceSrc;
        image.onload = function () {
          if (image.width / image.height != 1) {
            message.error(i18n.global.t("message.tips94"));
            return false;
          }
          var fileName =
            "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
          client()
            .multipartUpload(fileName, file, {
              progress: function (percentage, cpt) {
                console.log(cpt);
                console.log("打印进度", percentage);
              },
            })
            .then(() => {
              deceasedInfo.value.brand = "http://img.keepfly.net/" + fileName;
            });
        };
      };
      reader.readAsDataURL(file);
    };
    const senddeceasedInfo = () => {
      API({
        url: "/kuke/api/user/senddeceasedInfo",
        method: "POST",
        data: deceasedInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          showHeadModal.value = false;
          message.success(i18n.global.t("message.tips51"));
        } else {
          message.error(i18n.global.t("message.tips52"));
        }
      });
    };
    const deliverflower = () => {
      API({
        url: "/kuke/api/user/adddeliverflower",
        method: "POST",
        data: {
          id: deceasedInfo.value.id,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          showHeadModal.value = false;
          deceasedInfo.value.fnum += 1;
          message.success(i18n.global.t("message.tips59"));
        } else {
          message.error(i18n.global.t("message.tips60"));
        }
      });
    };
    const showTagsModal = ref(false);
    const sendTagsView = () => {
      showTagsModal.value = true;
    };
    const formValue = ref({});
    const handleTagTxtClick = () => {
      if (!formValue.value.tagtxt) {
        return false;
      }
      API({
        url: "/kuke/api/user/addTagTxtClick",
        method: "POST",
        data: {
          id: id,
          tagtxt: formValue.value.tagtxt,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          getTagTxtList();
          message.success(i18n.global.t("message.tips42"));
        } else {
          message.error(i18n.global.t("message.tips43"));
        }
      });
    };
    const TagTxtList = ref([]);
    const getTagTxtList = () => {
      API({
        url: "/kuke/api/user/getTagTxtList",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        TagTxtList.value = result.data.data;
      });
    };
    const basicInfoShow = ref(false);
    const disabled = ref(false);
    const bdisabled = ref(false);
    const odisabled = ref(false);
    const basicView = () => {
      disabled.value = deceasedInfo.value.year == 0 ? true : false;
      bdisabled.value = deceasedInfo.value.byear == 0 ? true : false;
      odisabled.value =
        deceasedInfo.value.occupation == null || deceasedInfo.value.occupation == ""
          ? true
          : false;
      basicInfoShow.value = true;
    };
    const basicInfoClick = () => {
      if (bdisabled.value) {
        deceasedInfo.value.byear = 0;
        deceasedInfo.value.bmoon = 0;
        deceasedInfo.value.bday = 0;
      }
      if (disabled.value) {
        deceasedInfo.value.year = 0;
        deceasedInfo.value.moon = 0;
        deceasedInfo.value.day = 0;
      }
      if (odisabled.value) {
        deceasedInfo.value.occupation = "";
      }
      API({
        url: "/kuke/api/user/senddeceasedInfo",
        method: "POST",
        data: deceasedInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          basicInfoShow.value = false;
          message.success(i18n.global.t("message.tips51"));
        } else {
          message.error(i18n.global.t("message.tips52"));
        }
      });
    };
    const relationshipshow = ref(false);
    const enterrelationship = () => {
      relationshipshow.value = true;
    };
    const relationshipArr = ref([]);
    const relationshipArrsubset = ref([]);
    const relationshipInfo = ref({});
    const handleRelationshipClick = () => {
      relationshipInfo.value.dId = id;
      if (!relationshipInfo.value.fullName) {
        message.error(i18n.global.t("message.tips63"));
        return false;
      } else if (!relationshipInfo.value.relationship) {
        message.error(i18n.global.t("message.tips64"));
        return false;
      } else if (!relationshipInfo.value.photo) {
        message.error(i18n.global.t("message.tips65"));
        return false;
      }
      API({
        url: "/kuke/api/user/sendRelationshipInfo",
        method: "POST",
        data: relationshipInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          relationshipInfo.value = {};
          relationshipshow.value = false;
          getRelationshiplist();
          message.success(i18n.global.t("message.tips29"));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const photo_file = ref();
    const djphotoFileView = () => {
      photo_file.value.value = "";
      photo_file.value.click();
    };
    const selectPhotoImg = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          relationshipInfo.value.photo = "http://img.keepfly.net/" + fileName;
        });
    };
    const getRelationshiplist = () => {
      API({
        url: "/kuke/api/user/getRelationshiplist",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        relationshipArr.value = result.data.data;
        relationshipArrsubset.value = result.data.data;
      });
    };
    const inum = ref(0);
    const addrelationship = () => {
      if (inum.value < relationshipArr.value.length % 3) {
        inum.value++;
      }
      relationshipArrsubset.value = relationshipArr.value.slice(
        3 * inum.value,
        3 + inum.value * 3
      );
    };
    const reducerelationship = () => {
      if (inum.value > 0) {
        inum.value--;
      }
      relationshipArrsubset.value = relationshipArr.value.slice(
        3 * inum.value,
        3 + inum.value * 3
      );
    };
    const imagesshow = ref(false);
    const enterimages = () => {
      imagesshow.value = true;
    };
    const imagesInfo = ref({});
    const images_file = ref();
    const djimagesInfoFileView = () => {
      images_file.value.value = "";
      images_file.value.click();
    };
    const selectimagesInfo = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          imagesInfo.value.imgUrl = "http://img.keepfly.net/" + fileName;
        });
    };
    const handleImagesClick = () => {
      imagesInfo.value.dId = id;
      if (!imagesInfo.value.imgUrl) {
        message.error(i18n.global.t("message.tips65"));
        return false;
      } else if (!imagesInfo.value.title) {
        message.error(i18n.global.t("message.tips66"));
        return false;
      }
      API({
        url: "/kuke/api/user/sendImagesInfo",
        method: "POST",
        data: imagesInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          imagesInfo.value = {};
          imagesshow.value = false;
          getImageslist();
          message.success(i18n.global.t("message.tips29"));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const imagesArr = ref([]);
    const imagesArrsubset = ref([]);
    const getImageslist = () => {
      API({
        url: "/kuke/api/user/getImageslist",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        imagesArr.value = result.data.data;
        imagesArrsubset.value = result.data.data;
      });
    };
    const iImagesnum = ref(0);
    const addImages = () => {
      if (iImagesnum.value < imagesArr.value.length % 4) {
        iImagesnum.value++;
      }
      imagesArrsubset.value = imagesArr.value.slice(
        4 * iImagesnum.value,
        4 + iImagesnum.value * 4
      );
    };
    const reduceImages = () => {
      if (iImagesnum.value > 0) {
        iImagesnum.value--;
      }
      imagesArrsubset.value = imagesArr.value.slice(
        4 * iImagesnum.value,
        4 + iImagesnum.value * 4
      );
    };
    const contentshow = ref(false);
    const entercontent = () => {
      contentshow.value = true;
    };
    const contentInfo = ref({});
    const handleContentClick = () => {
      contentInfo.value.dId = id;
      if (!contentInfo.value.modelName) {
        message.error(i18n.global.t("message.tips68"));
        return false;
      } else if (!contentInfo.value.modelContent) {
        message.error(i18n.global.t("message.tips67"));
        return false;
      }
      API({
        url: "/kuke/api/user/sendContentInfo",
        method: "POST",
        data: contentInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          contentInfo.value = {};
          contentshow.value = false;
          getContentList();
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips69"));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const Content_file = ref();
    const djContentFileView = () => {
      Content_file.value.value = "";
      Content_file.value.click();
    };
    const selectContentInfo = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          contentInfo.value.imgUrl = "http://img.keepfly.net/" + fileName;
        });
    };
    const ContentArr = ref([]);
    const getContentList = () => {
      API({
        url: "/kuke/api/user/getContentList",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        ContentArr.value = result.data.data;
        ContentArr.value.modelName = parseInt(ContentArr.value.modelName);
      });
    };
    const updataContentShow = ref(false);
    const updataContentInfo = ref({});
    const updataContentView = (e) => {
      updataContentInfo.value = e;
      updataContentInfo.value.modelName = parseInt(updataContentInfo.value.modelName);
      updataContentShow.value = true;
    };

    const uqContent_file = ref();
    const djuqContentFileView = () => {
      uqContent_file.value.value = "";
      uqContent_file.value.click();
    };
    const selectuqContentInfo = (e) => {
      let file = e.target.files[0];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then(() => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          updataContentInfo.value.imgUrl = "http://img.keepfly.net/" + fileName;
        });
    };
    const handleuqContentClick = () => {
      API({
        url: "/kuke/api/user/senduqContentInfo",
        method: "POST",
        data: updataContentInfo.value,
      }).then((result) => {
        if (result.data.code == 200) {
          updataContentInfo.value = {};
          updataContentShow.value = false;
          getContentList();
          message.success(i18n.global.t("message.tips51"));
        } else {
          message.error(i18n.global.t("message.tips52"));
        }
      });
    };
    const handledelContentClick = () => {
      API({
        url: "/kuke/api/user/senddelContentInfo",
        method: "POST",
        data: { id: updataContentInfo.value.id },
      }).then((result) => {
        if (result.data.code == 200) {
          updataContentInfo.value = {};
          updataContentShow.value = false;
          getContentList();
          message.success(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const pagetotal = ref(0);
    const currPage = ref(0);
    const pageSize = ref(8);
    const deceasedonlineshow = ref(false);
    const deceasedonlineArr = ref([]);
    const getdeceasedonlineView = () => {
      let param = {
        id: id,
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/getdeceasedonlinelist",
        method: "POST",
        data: param,
      }).then((res) => {
        let arr = res.data.data.records;
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
        }
        deceasedonlineArr.value = arr;
        currPage.value = res.data.data.current;
        pagetotal.value = res.data.data.pages;
        deceasedonlineshow.value = true;
      });
    };
    const pagingdeceasedonlineNum = (e) => {
      currPage.value = e;
      getdeceasedonlineView();
    };
    const langlocal = ref(localStorage.getItem("language"));
    const { locale } = useI18n();
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", value);
      location.reload();
    };
    const arr = ref([]);
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const selectThemeOverrides = {
      Select: {
        peers: {
          InternalSelection: {
            textColor: "#FF0000",
          },
        },
      },
    };
    const guestbookcontent = ref({
      dId: 0,
      content: "",
    });
    const sendguestbook = () => {
      guestbookcontent.value.dId = Number(id);
      if (!guestbookcontent.value.content) {
        message.error(i18n.global.t("message.tips67"));
        return false;
      }
      let params = {
        did: Number(id),
        guestbookcontent: guestbookcontent.value,
        imageList: ImageList.value,
      };
      API({
        url: "/kuke/api/user/sendguestbook",
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      }).then((result) => {
        if (result.data.code == 200) {
          guestbookcontent.value = {
            dId: 0,
            content: "",
          };
          ImageList.value = [];
          getdeceasedonlineView();
        } else {
          message.error(i18n.global.t("message.tips177"));
        }
      });
    };
    const infouser = ref({});
    const getDeceasedAdmin = () => {
      let param = {
        id: id,
      };
      API({
        url: "/kuke/api/index/getDeceasedAdmin",
        method: "POST",
        data: param,
      }).then((result) => {
        infouser.value = result.data.data;
      });
    };
    const Emoticon_show = ref(false);
    const emoticonView = () => {
      Emoticon_show.value = true;
    };
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const getemoticontxt = (e) => {
      let emoticontxt = "[" + e + "]";
      guestbookcontent.value.content += emoticontxt;
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const FileUpload_file = ref(null);
    const imageView = () => {
      if (FileUpload_file.value != null) {
        FileUpload_file.value.value = "";
      }
      FileUpload_file.value.click();
    };
    const uploadURL = (e) => {
      let file = e.target.files[0];
      let filename = file.name.split(".");
      let suffix = filename[filename.length - 1];
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "ossimage/" + "banner" + `${Date.parse(new Date())}` + "." + suffix;
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file, {
          progress: function (percentage, cpt) {
            console.log(cpt);
            console.log("打印进度", percentage);
          },
        })
        .then((res) => {
          console.log(res);
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          ImageList.value.push("http://img.keepfly.net/" + fileName);
        });
    };
    const imageClose = (e) => {
      ImageList.value.splice(e, 1);
    };
    const ImageList = ref([]);
    const delonline = (e, index) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/delonline",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          deceasedonlineArr.value.splice(index, 1);
          message.error(i18n.global.t("message.tips35"));
        } else {
          message.error(i18n.global.t("message.tips36"));
        }
      });
    };
    const sendprivateletter = () => {
      let params = {
        id: id,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          window.localStorage.setItem("sendSiLiao", 4);
          router.go(-1);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips123"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    const showUserInfoModal = ref(false);
    const keepflyUserInfo = ref({});
    const checkUserInfo = (e, i) => {
      let param = {};
      if (i == 0) {
        param = {
          id: infouser.value.id,
          trt: window.localStorage.getItem("token"),
        };
      } else {
        param = {
          id: e.userId,
          trt: window.localStorage.getItem("token"),
        };
      }
      API({
        url: "/kuke/api/index/checkUserInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips113"));
          return false;
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips114"));
          return false;
        }
        showUserInfoModal.value = true;
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
        keepflyUserInfo.value.friendUid = null;
        checkfriend(keepflyUserInfo.value, 3);
        getUserInfoNum(param.id);
        usermicroblogs.value = [];
        getmicrobloguserlist(param.id);
      });
    };
    const userInfoRef = (e) => {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight >=
        e.srcElement.scrollHeight
      ) {
        if (currusermicroblogPage.value < mpages.value) {
          ++currusermicroblogPage.value;
          getmicrobloguserlist(keepflyUserInfo.value.id);
        }
      }
    };
    const userNum = ref({});
    const getUserInfoNum = (e) => {
      API({
        url: "/kuke/api/index/getUserInfoNum",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          userNum.value = result.data.data;
        }
      });
    };
    const currusermicroblogPage = ref(1);
    const pageusermicroblogSize = ref(5);
    const usermicroblogs = ref([]);
    const mpages = ref(0);
    const getmicrobloguserlist = (e) => {
      let param = {
        currPage: currusermicroblogPage.value,
        pageSize: pageusermicroblogSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getmicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          usermicroblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mpages.value = result.data.data.pages;
        setMicroblogView(qrr);
      });
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const userDInfo = ref({});
    const getCommentView = (e, i) => {
      userDInfo.value = JSON.parse(window.localStorage.getItem("infodata"));
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const topicTranslateList = ref([]);
    const setMicroblogView = (e) => {
      let params = {
        language: localStorage.getItem("language"),
        tags: e,
      };
      API({
        url: "/kuke/api/index/setMicroblogView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        let arr = result.data.data;
        for (let i = 0; i < arr.length; i++) {
          topicTranslateList.value.push(arr[i]);
        }
      });
    };
    const uqTxtTranslate = (e) => {
      let orr = topicTranslateList.value;
      for (let i = 0; i < orr.length; i++) {
        if (orr[i].topicTitle == e) {
          return "#" + orr[i].topicTranslate;
        }
      }
    };
    const senduserprivateletter = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          window.localStorage.setItem("sendSiLiao", 4);
          router.go(-1);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips123"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    const senduserblacklist = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/senduserblacklist",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips120"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips121"));
        }
      });
    };
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.success(i18n.global.t("message.tips172"));
        }
      });
    };
    const addfriend = (e, i) => {
      let param = {};
      if (i == 1) {
        param = {
          userid: e.userId,
        };
      } else if (i == 2) {
        param = {
          userid: e.uid,
        };
      } else if (i == 3) {
        param = {
          userid: e.id,
        };
      }
      API({
        url: "/kuke/api/user/addfriend",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          if (i == 3) {
            e.friendUid = 1;
          } else {
            e.friendUid = 1;
            message.success(i18n.global.t("message.tips29"));
          }
        } else if (result.data.code == 605) {
          if (i == 3) {
            e.friendUid = 1;
          } else {
            e.friendUid = 1;
            message.success(i18n.global.t("message.tips32"));
          }
        } else if (result.data.code == 606) {
          message.success(i18n.global.t("message.tips34"));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const checkfriend = (e, i) => {
      let param = {};
      if (i == 1) {
        param = {
          userid: e.userId,
        };
      } else if (i == 2) {
        param = {
          userid: e.uid,
        };
      } else if (i == 3) {
        param = {
          userid: e.id,
        };
      }
      API({
        url: "/kuke/api/user/checkfriend",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          if (i == 3) {
            e.friendUid = 1;
          } else {
            e.friendUid = 1;
          }
        }
      });
    };
    const onMoveCallback = (val) => {
      console.log("拖动前的索引 :" + val.moved.newIndex);
      console.log("拖动后的索引 :" + val.moved.oldIndex);
      let param = {
        newIndex: val.moved.newIndex,
        oldIndex: val.moved.oldIndex,
        newid: relationshipArr.value[val.moved.newIndex].id,
        oldid: relationshipArr.value[val.moved.oldIndex].id,
      };
      API({
        url: "/kuke/api/user/moverelationshipArrno",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          console.log("拖拽成功");
        } else {
          console.log("拖拽失败");
        }
      });
    };
    const onImagesMoveCallback = (val) => {
      console.log("拖动前的索引 :" + val.moved.newIndex);
      console.log("拖动后的索引 :" + val.moved.oldIndex);
      let param = {
        newIndex: val.moved.newIndex,
        oldIndex: val.moved.oldIndex,
        newid: imagesArr.value[val.moved.newIndex].id,
        oldid: imagesArr.value[val.moved.oldIndex].id,
      };
      API({
        url: "/kuke/api/user/moveimagesArrno",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          console.log("拖拽成功");
        } else {
          console.log("拖拽失败");
        }
      });
    };
    const deleteImagesInfo = (val) => {
      API({
        url: "/kuke/api/user/deleteImagesInfo",
        method: "POST",
        data: {
          id: val,
        },
      }).then(() => {
        getImageslist();
      });
    };
    const deleterelationshipInfo = (val) => {
      API({
        url: "/kuke/api/user/deleterelationshipInfo",
        method: "POST",
        data: {
          id: val,
        },
      }).then(() => {
        getRelationshiplist();
      });
    };
    return {
      onImagesMoveCallback,
      onMoveCallback,
      userInfo,
      checkfriend,
      addfriend,
      sendInfoComment,
      userDInfo,
      getCommentView,
      likeClick,
      usermicroblogs,
      userNum,
      senduserprivateletter,
      senduserblacklist,
      uqTxtTranslate,
      setMicroblogView,
      topicTranslateList,
      kukesystemInfo,
      commentVoslikeClick,
      morecommentVos,
      keepflyUserInfo,
      userInfoRef,
      showUserInfoModal,
      checkUserInfo,
      sendprivateletter,
      selectheadSculptureForImg,
      djheadSculptureFileView,
      deceasedheadSculpture_file,
      delonline,
      odisabled,
      bdisabled,
      disabled,
      ImageList,
      imageClose,
      uploadURL,
      imageView,
      FileUpload_file,
      filters,
      getemoticontxt,
      emoticon,
      Emoticon_show,
      emoticonView,
      infouser,
      guestbookcontent,
      sendguestbook,
      selectThemeOverrides,
      arr,
      selectedValue: langlocal,
      handleSearch: (value) => {
        changeLang(value);
      },
      id,
      pagingdeceasedonlineNum,
      currentPage: currPage,
      pagenum: pagetotal,
      deceasedonlineArr,
      getdeceasedonlineView,
      deceasedonlineshow,
      handleuqContentClick,
      handledelContentClick,
      selectuqContentInfo,
      djuqContentFileView,
      uqContent_file,
      updataContentInfo,
      updataContentShow,
      updataContentView,
      ContentArr,
      getContentList,
      selectContentInfo,
      djContentFileView,
      Content_file,
      handleContentClick,
      contentInfo,
      options,
      entercontent,
      contentshow,
      addImages,
      reduceImages,
      imagesArr,
      getImageslist,
      imagesArrsubset,
      images_file,
      handleImagesClick,
      selectimagesInfo,
      djimagesInfoFileView,
      imagesInfo,
      enterimages,
      imagesshow,
      reducerelationship,
      addrelationship,
      deleteImagesInfo,
      deleterelationshipInfo,
      getRelationshiplist,
      selectPhotoImg,
      djphotoFileView,
      photo_file,
      handleRelationshipClick,
      relationshipArrsubset,
      relationshipArr,
      relationshipInfo,
      relationshipshow,
      enterrelationship,
      basicInfoClick,
      basicInfoShow,
      basicView,
      TagTxtList,
      getTagTxtList,
      handleTagTxtClick,
      columns: createColumns({
        play(row) {
          API({
            url: "/kuke/api/user/deleteTagTxtInfo",
            method: "POST",
            data: {
              id: row.id,
            },
          }).then(() => {
            getTagTxtList();
          });
        },
      }),
      pagination: false,
      formValue,
      sendTagsView,
      showTagsModal,
      deliverflower,
      deceasedbrand_file,
      djFileView,
      selectBrandForImg,
      senddeceasedInfo,
      headView,
      showHeadModal,
      toTop,
      infodata,
      deceasedInfo,
      bodyStyle: {
        width: "900px",
        height: "100%",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
      moonoptions: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "11",
          value: 11,
        },
        {
          label: "12",
          value: 12,
        },
      ],
      dayoptions: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "11",
          value: 11,
        },
        {
          label: "12",
          value: 12,
        },
        {
          label: "13",
          value: 13,
        },
        {
          label: "14",
          value: 14,
        },
        {
          label: "15",
          value: 15,
        },
        {
          label: "16",
          value: 16,
        },
        {
          label: "17",
          value: 17,
        },
        {
          label: "18",
          value: 18,
        },
        {
          label: "19",
          value: 19,
        },
        {
          label: "20",
          value: 20,
        },
        {
          label: "21",
          value: 21,
        },
        {
          label: "22",
          value: 22,
        },
        {
          label: "23",
          value: 23,
        },
        {
          label: "24",
          value: 24,
        },
        {
          label: "25",
          value: 25,
        },
        {
          label: "26",
          value: 26,
        },
        {
          label: "27",
          value: 27,
        },
        {
          label: "28",
          value: 28,
        },
        {
          label: "29",
          value: 29,
        },
        {
          label: "30",
          value: 30,
        },
        {
          label: "31",
          value: 31,
        },
      ],
      heightunitoptions: [
        {
          label: "cm",
          value: "cm",
        },
        {
          label: "m",
          value: "m",
        },
        {
          label: "in",
          value: "in",
        },
        {
          label: "ft",
          value: "ft",
        },
      ],
    };
  },
});
</script>
<style>
.n-pagination
  .n-pagination-item:not(.n-pagination-item--disabled).n-pagination-item--active {
  color: #ff8200 !important;
  border: 1px solid #ff8200 !important;
}
._wrap {
  min-height: 100%;
  height: 100%;
}
.keepfly-box-column {
  flex-direction: column;
}
.keepfly-box-flex {
  display: flex;
}
._top {
  margin-bottom: 3.5rem;
}
._topin {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.75rem;
  background: #f9f9f9;
  border-top: 2px solid #ff8200;
  box-sizing: border-box;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
}
._content {
  width: 70rem;
  height: 100%;
  margin: auto;
}
._box {
  height: 100%;
}
.keepfly-box-justifyBetween {
  justify-content: space-between;
}
.keepfly-box-alignCenter {
  align-items: center;
}
._logo {
  width: 1.625rem;
  height: 1.875rem;
  cursor: pointer;
}
._line {
  height: 1.25rem;
  margin: 0 0.625rem;
}
.keepfly-divider-y {
  display: inline-block;
  width: 0;
  height: 100%;
  position: relative;
}
.keepfly-divider-main {
  color: #f9f9f9;
}
._avatar {
  cursor: pointer;
}
.keepfly-avatar-main {
  display: inline-block;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  font-size: 1rem;
}
.keepfly-avatar-main:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 9;
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);
}
.keepfly-avatar-img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
._name {
  margin-left: 0.5rem;
  font-size: 0.875rem;
}
.body-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.second-container.second-container-normal {
  background-color: #f1ebe9;
  background-position: center bottom;
  background-size: 100% auto;
  margin-bottom: -20px;
}
.second-container {
  margin-bottom: -270px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 1440px auto;
  background-color: #f5f5f5;
  z-index: 0;
}

body.wiki-lemma .layout {
  width: 1139px;
}
.second-container .layout {
  position: relative;
  padding: 30px 0;
  z-index: 2;
}
body.w-small .layout,
.layout {
  width: 980px;
}
.layout {
  margin: 0 auto;
}
body.w-small .layout,
.layout {
  width: 980px;
}
.layout {
  margin: 0 auto;
}
.large-feature .posterFlag.excellent-icon,
.J-second-head .posterFlag.excellent-icon {
  width: 50px;
  height: 100px;
  background: url(../../assets/aside-flag-excellent_fa9b2e5.png) no-repeat 0 0;
  background-size: cover;
}
.large-feature .posterFlag,
.J-second-head .posterFlag {
  left: -48px;
  top: -1px;
}
.posterFlag.excellent-icon {
  position: absolute;
  width: 45px;
  height: 90px;
  background-size: cover;
  top: 5px;
  left: -45px;
}
.posterFlag {
  display: block;
  z-index: 1;
}
.second-container .lemma-title-wrapper {
  margin-top: -3px;
  margin-bottom: 24px;
  padding-left: 30px;
}
.second-container-normal .lemmaWgt-lemmaTitle {
  width: auto;
  padding-right: 82px;
}
.lemmaWgt-lemmaTitle {
  margin: 0 0 10px;
  width: 700px;
}
.lemmaWgt-lemmaTitle .lemmaWgt-lemmaTitle-title {
  margin: 0 0 5px;
  line-height: 40px;
  margin-bottom: -8px;
  position: relative;
}
.lemmaWgt-lemmaTitle .lemmaWgt-lemmaTitle-title h1 {
  margin: 0 10px 0 0;
  color: #000;
  display: inline;
  font-size: 34px;
  line-height: 1.15;
  font-weight: 400;
  vertical-align: sub;
  *vertical-align: text-bottom;
  word-break: break-all;
  position: relative;
  z-index: 2;
}
.second-container .lemma-title-wrapper h1 {
  font-size: 30px !important;
  font-weight: 500;
}
.lemmaWgt-lemmaTitle .btn-list {
  font-size: 0;
  display: inline-flex;
  position: relative;
  top: -5px;
  z-index: 2;
}
.second-container-normal .cmn-btn-hover-blue {
  background: #fcfeff;
  box-shadow: 0 1px 1px 0 #bfd6f2;
  border-radius: 4px;
  border-color: #fcfeff !important;
}

.body-wrapper a {
  color: #136ec2;
  text-decoration: none;
}
.cmn-btn-hover-blue {
  display: inline-block;
  zoom: 1;
  color: #666;
  border: solid 1px #e2e2e2;
  border-radius: 2px;
  background: #fff;
  text-align: center;
  line-height: 2;
  padding: 4px 4px 3px;
  font-size: 12px;
}
.body-wrapper .title-audio-play em {
  margin: 0 2px 0 0;
  line-height: 12px;
  font-size: 15px;
  vertical-align: text-top;
  color: #459df5;
  vertical-align: -3px;
}
.cmn-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  outline: 0;
}
.wiki-lemma-icons {
  font-weight: 400;
  font-style: normal;
}
.body-wrapper .title-audio-play {
  position: relative;
  display: inline-block;
  -moz-box-orient: vertical;
  display: inline-block;
  zoom: 1;
  display: inline;
  margin: 0 6px 0 0;
  border-color: #ccc;
  border-radius: 4px;
  outline: 0;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #666;
}

.body-wrapper .title-audio-play:hover {
  padding: 7px 10px;
  border: 0;
  color: #fff;
  background: #459df5;
  text-decoration: none;
}

.body-wrapper .title-audio-play:hover em {
  color: #fff;
}

.body-wrapper .title-audio-play em {
  margin: 0 2px 0 0;
  line-height: 12px;
  font-size: 15px;
  vertical-align: text-top;
  color: #459df5;
  vertical-align: -3px;
}

.lemmaWgt-lemmaTitle-light .title-audio-play {
  padding: 7px 10px;
  border: 0;
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.lemmaWgt-lemmaTitle-light .title-audio-play:hover {
  background: rgba(82, 163, 245, 0.7);
}

.lemmaWgt-lemmaTitle-light .title-audio-play em {
  color: #fff;
}

.lemmaWgt-lemmaTitle-dark .title-audio-play {
  padding: 7px 10px;
  border: 0;
  background: rgba(255, 255, 255, 0.7);
}

.lemmaWgt-lemmaTitle .edit-lemma,
.lemmaWgt-lemmaTitle .lemma-discussion {
  position: relative;
  -moz-box-orient: vertical;
  zoom: 1;
  margin: 0 6px 0 0;
  border-color: #ccc;
  border-radius: 4px;
  outline: 0;
  height: 32px;
  line-height: 25px;
  font-size: 12px;
  color: #666;
}
.lemmaWgt-lemmaTitle .lemma-desc {
  font-size: 16px;
  color: #626675;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}
.second-container .flower-sender:hover {
  outline: 0;
}
.second-container .flower-sender {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: transparent;
}
.flower-sender-dark:hover {
  box-shadow: 0 0 0;
}
.flower-sender {
  position: relative;
  min-width: 81px;
  float: right;
  height: 73px;
  text-align: center;
  background: #fff;
  cursor: pointer;
  z-index: 100;
  font-size: 12px;
  font-family: "Microsoft Yahei";
}
.flower-sender .bg {
  display: inline-block;
  min-width: 52px;
  text-align: center;
  box-shadow: none;
  padding: 46px 15px 6px;
  position: relative;
}
.flower-sender .flower-tip {
  width: 36px;
  height: 25px;
  position: absolute;
  background-image: url(../../assets/flower-tip_8d377fd.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: -5px;
  right: -6px;
  z-index: 100;
}
.flower-sender .bg em {
  display: block;
  font-style: normal;
  width: 55px;
  height: 45px;
  background-size: cover;
  background-image: url(../../assets/flower-list_0c7a140.png);
  background-repeat: no-repeat;
  background-position: 0 center;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -26px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: none;
  transition: none;
  opacity: 0.85;
}
.flower-sender .bg .numBox {
  width: 120px;
  height: 18px;
  position: absolute;
  left: 50%;
  margin-left: -60px;
  margin-top: 6px;
  text-align: center;
}
.flower-sender .bg .numBox .count.deep-color {
  color: #ff546e;
}
.flower-sender .bg .numBox .count {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  line-height: 12px;
  font-weight: 500;
}
.flower-sender .bg i {
  display: block;
  font-style: normal;
  font-size: 16px;
  color: #f18167;
  line-height: 21px;
}
.second-container .second-wrapper {
  padding-left: 30px;
}
.video-list-container {
  position: relative;
  max-width: 846px;
  height: 153px;
}

.second-container .miaodong-logo {
  width: auto;
  height: 360px;
  position: absolute;
  bottom: -40px;
  right: -53px;
}

.star-mod-top {
  background: #fff;
  border-bottom: none;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 0 0 30px;
  position: relative;
  width: 1108px;
  top: 0;
  border-radius: 12px;
}
.star-top-widget {
  float: left;
  margin: 8px 20px 0 0;
}
.star-relation {
  height: 200px;
  width: 321px;
  margin-top: 18px;
}
.hot-album,
.star-brand {
  height: 200px;
  width: 431px;
  margin-top: 18px;
}
.star-top-hd {
  height: 60px;
  position: relative;
}
.star-top-hd h2,
.star-top-hd .title {
  color: #222;
  font-size: 20px;
  line-height: 60px;
}
.star-mod-top .module-paging {
  border: 1px solid #d1d1d1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  height: 21px;
  position: absolute;
  right: 0;
  top: 21px;
  width: 48px;
}
.star-mod-top .module-paging .prev_off {
  background-position: -75px -30px;
}
.star-mod-top .module-paging .prev {
  border-right: 1px solid #e5e5e6;
}
.star-mod-top .module-paging a {
  background: url(../../assets/t0180ec38896600d3eb.gif) no-repeat -50px -30px;
  float: left;
  height: 19px;
  width: 23px;
}
.star-mod-top .module-paging .next {
  background-position: -50px -50px;
}
.star-mod-top .module-paging a {
  background: url(../../assets/t0180ec38896600d3eb.gif) no-repeat -50px -30px;
  float: left;
  height: 19px;
  width: 23px;
}
.star-top-bd {
  height: 140px;
  overflow: hidden;
  position: relative;
}
.star-top-bd ul {
  left: 0;
  position: absolute;
  top: 0;
}
.star-top-bd li:first-child {
  margin: 0 !important;
}
.star-top-bd li:first-child {
  margin: 0 !important;
}
.star-top-bd li {
  border: 1px solid #d9e0e7;
  float: left;
  height: 140px;
  margin-left: 10px;
  position: relative;
  width: 100px;
}
.star-top-bd img {
  height: 138px;
  width: 100px;
}
.star-top-bd .mask {
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.star-top-bd p,
.star-top-bd .mask {
  bottom: 0;
  color: #fff;
  height: 22px;
  left: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  width: 100px;
  font-size: 12px;
}
.news-hd {
  color: #222;
  font-size: 18px;
  line-height: 46px;
}
.news-bd ul {
  padding-top: 2px;
  width: 235px;
}

.columns {
  width: 264px;
  display: block;
  line-height: 22px;
  margin-top: 8px;
  padding: 13px 0 10px 0;
}
.bor-ccc,
.columns,
.bor-c_dl dl {
  border: 1px #e9e9e9 solid;
  background-color: #fff;
}
.col-h2 {
  font-size: 20px;
  line-height: 22px;
  padding: 0 0 12px 0;
  border-bottom: #e9e9e9 solid 1px;
  margin: 0 25px 10px 25px;
}

dl {
  margin-top: 0;
  margin-bottom: 21px;
}
dd {
  margin-left: 0;
}
dd,
dt {
  line-height: 1.5;
}
.twhp2 .a-img1 {
  margin-left: -60px;
  margin-top: 4px;
  float: left;
}
img {
  vertical-align: middle;
}
.col-dl dt {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 100;
}
.ctxx .twhp2 > dt > a {
  display: inline-block;
  min-width: 50%;
}
.twhp2 em {
  font-style: normal;
  display: inline-block;
}
.f12 {
  font-size: 12px;
}
.ctxx .twhp2 > dd > .gray9 {
  display: inline-block;
  width: 50%;
}
.gray9 {
  color: #999;
}
.gray {
  color: #666;
}
.u-stars {
  display: inline-block;
}
.doc-aside.group2 .col-ul {
  margin-bottom: 0;
}
.ctxx .col-ul {
  border-top: #e9e9e9 solid 1px;
  margin-top: 0;
  padding-top: 15px;
}
.col-ul,
.col-dl,
.col-p {
  margin: 8px 6px 12px 25px;
  padding: 0 0 3px 0;
}
.twhp2 {
  margin-left: 85px;
  min-height: 60px;
}
.doc-aside.group2 .col-ul > li {
  margin-bottom: 5px;
}
.col-ul li {
  clear: both;
  list-style: none;
}

.newslist-content {
  width: 1108px;
  margin: 0 auto;
}
.newslist-content .newslist-left {
  width: 812px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.newslist-content .newslist-right {
  width: 280px;
  float: right;
}
.border-bg {
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
}
.newsdetail-left .detail-header {
  border-bottom: 0;
}
.newsdetail-left .detail-header {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #eee;
}
.newsdetail-left .detail-content {
  padding: 20px 10px 0px;
}
.newsdetail-left .detail-page {
  padding: 20px;
  height: 104px;
}
.news-info {
  width: 280px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.newsdetail-left .detail-header .detail-title h1 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.widget {
  margin-bottom: 10px;
  clear: both;
  position: relative;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  padding: 22px;
}
.widget h3 {
  font-size: 18px;
  color: #282828;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 18px;
  margin-bottom: 28px;
  position: relative;
}
.widget h3:after {
  content: "";
  background-color: #ff8200;
  left: 0;
  width: 50px;
  height: 2px;
  bottom: -2px;
  position: absolute;
}
.widge_tags a {
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 17px;
  margin: 0 10px 7px 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.66);
  background-color: rgba(255, 255, 255, 0.97);
}

.module {
  position: relative;
}
.module .mod-title h3 {
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #333;
  padding: 0;
  margin: 0;
}
.module .mod-title h3:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 18px;
  margin-right: 6px;
  background-color: #ff8200;
  float: left;
}
.module .mod-content {
  margin-top: 20px;
  color: #333;
  padding: 0px 14px 28px;
}
.basic-info,
.details-info {
  overflow: hidden;
}
.clearfloat {
  zoom: 1;
}
.basic-info li,
.goods-list li {
  width: 50%;
  line-height: 35px;
  color: #333;
  border-bottom: 1px dashed #dadada;
  white-space: nowrap;
  float: left;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.basic-info b,
.goods-list b {
  width: 90px;
  min-width: 90px;
  padding: 0 5px 0 12px;
  color: #999;
  white-space: nowrap;
  letter-spacing: 2px;
}
.basic-info-mask,
.details-info-mask {
  line-height: 40px;
  position: relative;
  text-align: center;
}
.wbpro-side-card-3.col-4 {
  padding: 24px 14px 14px;
}
.SideCard3_c1 {
  background: rgba(105, 138, 246, 0.1);
  color: #698af6;
}
.SideCard3_c2 {
  background: rgba(79, 201, 230, 0.1);
  color: #4fc9e6;
}
.SideCard3_c3 {
  background: rgba(255, 189, 4, 0.1);
  color: #ffbd04;
}
.SideCard3_c4 {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
}
.SideCard3_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 26px;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.wbpro-side-card-3 .wbpro-textcut {
  margin-top: 8px;
}
.wbpro-side-opt {
  height: 38px;
}
.SideCard3_btn {
  margin-top: 22px;
  background: #f9f9f9;
  border-radius: 4px;
}
.onimg img {
  width: 19px;
}
.imagesData .n-image img {
  width: 100px !important;
}
</style>
