<template>
  <div>
    <div class="keepfly-box-flex keepfly-box-column Frame_wrap">
      <HeaderView @eventParent="setNavTabHome" @searchTxt="hsearchTxt"></HeaderView>
      <IndexView @eventParent="setNavTabHome" v-if="navTab == 1"></IndexView>
      <HotView
        :context="icontext"
        v-if="navTab == 2"
        @eventParent="setNavTabHome"
      ></HotView>
      <LookForView @eventParent="setNavTabHome" v-if="navTab == 3"></LookForView>
      <MessageView
        :navnum="navnum"
        @eventParent="setNavTabHome"
        v-if="navTab == 4"
      ></MessageView>
      <IndividualView @eventParent="setNavTabHome" v-if="navTab == 5"></IndividualView>
      <CenterView v-if="navTab == 6"></CenterView>
      <SquareView v-if="navTab == 7"></SquareView>
      <SearchView
        @eventParent="setNavTabHome"
        :context="searchcontext"
        ref="childComp"
        v-if="navTab == 8"
      ></SearchView>
    </div>
  </div>
</template>

<script>
import HeaderView from "../../components/HeaderView.vue";
import IndexView from "./IndexView.vue";
import HotView from "./HotView.vue";
import LookForView from "./LookForView.vue";
import MessageView from "./MessageView.vue";
import CenterView from "./CentreView.vue";
import IndividualView from "./IndividualView.vue";
import SquareView from "./SquareView.vue";
import SearchView from "./SearchView.vue";
import { defineComponent, ref, onMounted } from "vue";
import "vue-cropper/dist/index.css";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderView,
    IndexView,
    HotView,
    LookForView,
    MessageView,
    CenterView,
    IndividualView,
    SquareView,
    SearchView,
  },
  setup() {
    const rowsnum = ref(0);
    const columnsNum = ref(0);
    const hiddenText = ref("");
    const navTab = ref(7);
    const icontext = ref(1);
    const navnum = ref(1);
    onMounted(() => {
      if (window.localStorage.getItem("sendSiLiao")) {
        window.localStorage.removeItem("sendSiLiao");
        navTab.value = 4;
        navnum.value = 4;
      } else {
        navnum.value = 1;
      }
    });
    //接收消息
    const setNavTabHome = (data) => {
      navTab.value = data;
      if (data == 2) {
        icontext.value = 1;
      }
      window.scrollTo(0, 0);
    };
    const searchcontext = ref("");
    const childComp = ref();
    const hsearchTxt = (txt) => {
      searchcontext.value = txt;
      childComp.value.getmicrobloglist(txt);
    };
    return {
      navnum,
      childComp,
      searchcontext,
      hsearchTxt,
      icontext,
      setNavTabHome,
      navTab,
      noSideSpace: (value) => !value.startsWith(" ") && !value.endsWith(" "),
      hiddenText,
      rowsnum,
      columnsNum,
    };
  },
});
</script>
<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.Form_input pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.Frame_wrap {
  min-height: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.keepfly-box-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.keepfly-box-flex {
  display: flex;
}

.Frame_top {
  margin-bottom: 8px;
}

.Frame_content {
  max-width: 1122px;
  margin: auto;
}

.Frame_noside1 {
  max-width: 832px;
}

.Frame_side {
  width: 182px;
  margin-right: 8px;
}

.Main_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Main_side {
  width: 282px;
  margin-left: 8px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.Main_full {
  width: 642px;
}

.Nav_wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Nav_wrap {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  padding-top: 0;
  border-top: 2px solid #ff8200;
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.Nav_holder {
  height: 60px;
}

.Nav_panel_YI3 {
  height: 100%;
  padding: 0 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-image: none;
}

.Nav_panel_YI3,
.Nav_wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.keepfly-panel-bottom {
  border-bottom: 1px solid #f9f9f9;
}

.keepfly-panel-main {
  background-color: #fff;
}

.Nav_main_user {
  /* max-width: 1122px; */
  width: 100%;
  /* height: 100%; */
  margin: auto;
}

.keepfly-box-alignCenter {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.Nav_left {
  width: 20%;
  margin-right: 8px;
}

.Nav_logoWrap,
.Nav_logoWrap:hover {
  color: #231916;
}

.Nav_logoWrap {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

a {
  color: #eb7350;
  text-decoration: none;
}

.Nav_search {
  position: relative;
  height: 57px;
  margin-left: 1px;
}

.Nav_icon {
  margin-left: 10px;
  position: absolute;
  cursor: pointer;
}

.SearchIcon_wrap {
  width: 34px;
  height: 34px;
  background-color: #f0f1f4;
  border-radius: 50%;
}

.keepfly-box-justifyCenter {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.SearchIcon_icon {
  width: 16px;
  height: 16px;
}

.SearchIcon_wrap {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.Nav_bar {
  position: absolute;
}

.Nav_bar,
.Nav_barForcus {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Nav_bar {
  width: 220px;
  padding: 0 10px;
}

.SearchBar_wrap {
  position: relative;
  z-index: 99;
}

.SearchBar_main {
  width: 220px;
  height: 34px;
}
.SearchBar_SchTopBox {
  padding: 6px 10px 5px;
}
.SearchBar_SchTopBox .SearchBar_schTopTit {
  font-size: 15px;
  color: #939393;
}
.keepfly-font--clear:hover {
  background: rgba(255, 130, 0, 0.1);
}
.SearchBar_rsItem.SearchBar_rsHover {
  background-color: #f2f2f2;
  color: #ff8200;
}
.SearchBar_rsItem > span {
  margin-right: 4px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 15px;
  color: #838383;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
.SearchBar_searchText {
  width: 100%;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  color: #333;
}
.keepfly-pop-wrap {
  display: inline-block;
  position: relative;
}

.SearchBar_main .keepfly-input-hasIcon {
  padding-left: 33px;
}

.SearchBar_iptbox,
.SearchBar_iptbox input {
  color: #333;
}

.SearchBar_iptbox {
  background-color: #f0f1f4;
  border-color: #f0f1f4;
}

.keepfly-input-hasIcon {
  padding-left: 2.25em;
}

.keepfly-input-round {
  border-radius: 6.25rem !important;
}

.keepfly-input-wrap {
  background-color: #f0f1f4;
  border: 1px solid #f0f1f4;
  border-radius: 0.5em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  padding: 0 0.75em;
  position: relative;
}

.SearchBar_main .keepfly-input-icon {
  left: 8px;
}

.keepfly-input-icon {
  font-size: 1em;
  left: 0.75em;
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.SearchBar_iptbox_oYj,
.SearchBar_iptbox_oYj input {
  color: #333;
}

.SearchBar_main input {
  height: 100%;
}

.keepfly-input-main {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  font-size: 0.875em;
  height: 2.375em;
  outline: none;
  padding: 0;
  width: 100%;
}

@media (max-width: 1319px) {
  .Nav_icon {
    visibility: visible;
  }

  .Nav_bar {
    visibility: hidden;
  }
}

.Nav_mid {
  position: relative;
  width: 642px;
  height: 100%;
}

.Ctrls_main,
.Ctrls_main .keepfly-tab-nav {
  height: 100%;
}

.Ctrls_main,
.Ctrls_main .keepfly-tab-nav {
  height: 100%;
}

.keepfly-tab-nav {
  position: relative;
}

.Ctrls_alink,
.Ctrls_alink:hover {
  color: #000;
}

.Ctrls_tab.keepfly-tab-active {
  color: #ff8200;
}

.keepfly-tab-active {
  color: #ff8200;
}

.keepfly-tab-item-border {
  background-color: #ff8200;
  bottom: 0;
  height: 0.125rem;
  left: 0;
  position: absolute;
  width: 100%;
}

.Ctrls_item {
  width: 70px;
  height: 38px;
  text-align: center;
  border-radius: 4px;
}

.keepfly-badge-box {
  display: inline-block;
  position: relative;
}

.Ctrls_item .keepfly-badge-main {
  z-index: 9;
}

.keepfly-badge-bubble {
  position: absolute;
  right: 0.3125rem;
  top: -0.3125rem;
  -webkit-transform: translate(100%);
  transform: translate(100%);
}

.keepfly-badge-main {
  background-color: #ff2026;
  border: 1px solid #fff;
  border-radius: 0.9375rem;
  border-bottom-left-radius: 0.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  height: 0.9375rem;
  line-height: 0;
  overflow: hidden;
  padding: 0 0.5rem 0 0.125rem;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
}

.Ctrls_icon {
  width: 30px;
  height: 30px;
}

.Ctrls_tab {
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
}

.Ctrls_tab {
  margin: 0 5px;
}

.keepfly-tab-item-main {
  cursor: pointer;
  position: relative;
}

.Nav_right {
  width: 282px;
  margin-left: 8px;
  z-index: 1;
}

.keepfly-box-justifyEnd {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Configs_item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: #e5e6eb;
}

.Configs_icon {
  width: 18px;
  height: 18px;
}

.Ctrls_tab div:hover {
  background: #eee;
}

.Nav_inner {
  overflow-y: auto;
  border-radius: 4px 4px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 2px 0px;
}

.keepfly-panel-left {
  border-left: 1px solid #f9f9f9;
}

.keepfly-panel-right {
  border-right: 1px solid #f9f9f9;
}

.keepfly-panel-top {
  border-top: 1px solid #f9f9f9;
}

.Nav_title {
  margin: 0;
  padding: 10px 16px;
  line-height: 26px;
  font-size: 22px;
  font-weight: 500;
}

.ALink_none,
.ALink_none:active,
.ALink_none:hover,
.ALink_none:link,
.ALink_none:visited {
  color: inherit;
  text-decoration: none;
}

.NavItem_cur,
.NavItem_cur .NavItem_icon {
  color: #ff8200 !important;
}

.NavItem_cur {
  font-weight: 500;
}

.NavItem_main {
  position: relative;
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #333;
}

.keepfly-font {
  font-family: keepfly;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  line-height: 1;
}

.NavItem_icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  font-size: 24px;
  color: #838383;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.NavItem_text {
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Home_card,
.Home_home,
.Home_wrap {
  display: flex;
}

.Home_home,
.Home_wrap {
  /* height: 100%; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.Card_bottomGap {
  margin-bottom: 8px;
}

.Card_wrap {
  border-color: #f2f2f2;
  border-radius: 4px;
}

.index_box {
  padding: 20px 20px 10px;
}

.index_box1 {
  margin: 0 0 10px;
}

.wbpro-form {
  padding: 6px 11px;
  background: #f0f1f4;
  border: 1px solid #f0f1f4;
  border-radius: 8px;
  line-height: 0;
}

.Form_wbproform {
  position: relative;
  min-height: 67.6px;
}

.wbpro-form input,
.wbpro-form textarea {
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.wbpro-form > div,
.wbpro-form input,
.wbpro-form textarea {
  line-height: 24px;
  font-size: 14px;
  color: #333;
}

.Form_input {
  min-height: 24px;
  resize: none;
}

.Form_count {
  text-align: right;
}

form.focus {
  background: #fff;
}

.wbpro-form.focus {
  border-color: #ff8200;
}

.index_box2 {
  margin: 10px 0;
}

.u-col-3 [class*="box-item"],
.u-col-3 [data-type="w-box-item"] {
  width: 32.33333%;
}

.keepfly-box-item-inlineBlock {
  display: inline-block;
  font-size: 1rem;
}

.Image_picbed {
  cursor: move;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  line-height: 0;
}

.FileUpload_box {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid;
  border-radius: inherit;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  color: #ccc;
}

.FileUpload_icon {
  font-size: 24px;
}

.FileUpload_file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.keepfly-box-item-flex {
  align-self: stretch;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: stretch;
}

.Tool_mar1 {
  margin-left: -8px;
}

.Tool_iconitem {
  margin: 0 2px 0 0;
}

.wbpro-iconbed-v2 {
  width: auto;
  height: 30px;
}

.wbpro-iconbed {
  position: relative;
  width: 78px;
  height: 34px;
  cursor: pointer;
  line-height: 0;
}

.wbpro-iconbed-v2:before {
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
}

.wbpro-iconbed-r {
  position: relative;
  width: 28px;
  height: 34px;
  cursor: pointer;
  line-height: 0;
}
.wbpro-iconbed-r:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.wbpro-iconbed-v2 .keepfly-font {
  width: 20px;
  text-align: center;
}
.wbpro-side-card4 .item {
  padding: 14px 0;
}
.wbpro-iconbed .keepfly-font {
  position: relative;
  font-size: 18px;
  color: #838383;
}

.wbpro-iconbed-v2 .text {
  margin: 0 8px 0 2px;
  font-size: 12px;
  color: #838383;
}

.Tool_check {
  position: relative;
}

.keepfly-button-flat.keepfly-button-primary {
  background: #ff8200;
  color: #fff;
}

.Tool_btn {
  width: 68px;
  padding-left: 0;
  padding-right: 0;
}

.keepfly-button-m {
  font-size: 0.875rem;
  padding: 0.5625rem 0.875rem;
}

.keepfly-button-main {
  background-color: transparent;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  /* font-weight: 600; */
  line-height: 1;
  outline: 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.SecBar_visable {
  opacity: 1;
}

.Home_card,
.Home_home,
.Home_wrap {
  display: flex;
}

.Search_container_2r,
.Search_outer {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Search_container_2r {
  width: 100%;
  position: relative;
}

.Search_outer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Search_container_2r,
.Search_outer {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Search_inner_1Vl1i,
.Search_slot {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.wbpro-tab2 .keepfly-box-item-inlineBlock {
  vertical-align: top;
}

.u-col-8 [class*="box-item"],
.u-col-8 [data-type="w-box-item"] {
  width: 12.5%;
}

.wbpro-tab2 .keepfly-box-flex {
  height: 44px;
  line-height: 10px;
}

.wbpro-tab2 .cur .wbpro-textcut {
  font-weight: 700;
  color: #ff8200;
}

.wbpro-tab2 .wbpro-textcut {
  width: 100%;
  margin: 0 4px;
  padding: 5px;
  border-radius: 15px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.wbpro-cutword,
.wbpro-textcut {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wbpro-textcut {
  white-space: nowrap;
}

.Search_senior {
  cursor: pointer;
}

.Search_btn.Search_btn {
  pointer-events: none;
  padding: 8px 3px 8px 10px;
  color: #939393;
  margin-right: 18px;
}

.keepfly-button-flat.keepfly-button-default {
  background: #eee;
  color: #333;
}

.keepfly-button-main.keepfly-button-round,
.keepfly-button-main.keepfly-button-round .keepfly-button-inset {
  border-radius: 2.25rem;
}

.keepfly-button-s {
  font-weight: 400;
}

.keepfly-button-s {
  font-size: 0.75rem;
  padding: 0.5rem 0.875rem;
}

.keepfly-button-flat .keepfly-button-wrap {
  border: 1px solid transparent;
}

.keepfly-button-icon {
  font-size: inherit !important;
  height: 1.5em;
  vertical-align: bottom;
}

.keepfly-button-icon + .keepfly-button-content {
  margin-left: 0.375em;
}

.Search_btn .keepfly-font--caretDown {
  width: 8px;
  margin-left: 2px;
  margin-right: 8px;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.Home_feed {
  height: 100%;
  overflow: hidden;
}

.Scroll_container {
  max-width: 100%;
  height: 100%;
}

.Scroll_wrap {
  position: relative;
  height: 100%;
}

.vue-recycle-scroller {
  position: relative;
}

.vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
  width: 100%;
}

.vue-recycle-scroller__item-wrapper {
  overflow: visible;
  overflow: initial;
}

.vue-recycle-scroller__item-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.vue-recycle-scroller.ready.direction-vertical .vue-recycle-scroller__item-view {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.vue-recycle-scroller.ready.direction-vertical .vue-recycle-scroller__item-view {
  width: 100%;
}

.vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
  position: absolute;
  top: 0;
  left: 0;
}

.vue-recycle-scroller__item-view[more] {
  z-index: 1;
}

.wbpro-scroller-item {
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Feed_normal {
  margin-bottom: 8px;
}

.Feed_wrap {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  border-color: #f2f2f2;
}

.Feed_body {
  padding: 20px 20px 0;
}

.ALink_default,
.ALink_default:active,
.ALink_default:link,
.ALink_default:visited {
  color: inherit;
  text-decoration: none;
}

.head_avatar {
  margin-right: 10px;
  cursor: pointer;
}

.keepfly-avatar-main {
  border-radius: 50%;
  display: inline-block;
  font-size: 1rem;
  height: 2.5em;
  position: relative;
  width: 2.5em;
}

.keepfly-avatar-main:before {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.keepfly-avatar-img {
  vertical-align: top;
}

.keepfly-avatar-hoverMask,
.keepfly-avatar-img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.keepfly-avatar-hoverMask,
.keepfly-avatar-img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.keepfly-avatar-hoverMask {
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.head_main {
  min-width: 0;
  margin-right: 10px;
  margin-bottom: -4px;
}

.head_nick,
.head_suffixbox {
  font-size: 14px;
}

.head_nick {
  color: #333;
  line-height: 20px;
  margin-bottom: 7px;
  font-weight: bolder;
}

.head_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.head-info_info {
  color: #939393;
  font-size: 12px;
  line-height: 12px;
}

.wbpro-auth-tag {
  display: inline-block;
  min-width: 26px;
  height: 13px;
  border: 1px solid #ccc;
  border-radius: 2px;
  line-height: 13px;
  color: #939393;
  text-align: center;
}

.head-info_authtag {
  margin-right: 4px;
}

.head-info_info a {
  color: #939393;
  text-decoration: none;
}

.head-info_time {
  margin-right: 5px;
  min-width: 0;
}

.head-info_from {
  min-width: 0;
  -ms-flex-item-align: center;
  align-self: center;
}

.head-info_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.morepop_more {
  margin-top: -8px;
  position: relative;
  z-index: 11;
}

.morepop_moreIcon {
  font-size: 0;
  width: 24px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 24px;
  margin-right: -3px;
  cursor: pointer;
}

.morepop_moreIcon .morepop_cross {
  font-size: 12px;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
}

.wbpro-feed-content {
  padding-left: 60px;
  overflow: hidden;
}

.detail_ogText {
  color: #333;
  font-size: 14px;
  line-height: 24px;
}

.detail_wbtext {
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* .content_row {
  margin-top: 10px;
} */

.toolbar_main {
  height: 40px;
  line-height: 40px;
  margin: 0 15px;
}

.toolbar_left .toolbar_btn,
.toolbar_left .toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toolbar_cursor {
  cursor: pointer;
}

.toolbar_wrap {
  margin-left: -3px;
  height: 100%;
}

.toolbar_left .toolbar_btn,
.toolbar_left .toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toolbar_iconWrap {
  width: 24px;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

.toolbar_retweetIcon {
  font-size: 13px;
}

.toolbar_num {
  font-size: 12px;
  line-height: 12px;
}

.toolbar_likebox {
  height: 100%;
}

.toolbar_likebox .toolbar_btn {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.toolbar_left .toolbar_btn,
.toolbar_left .toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.keepfly-like-main {
  background: none;
  border: 0;
  color: currentColor;
  cursor: pointer;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  font-size: 1rem;
  height: 100%;
  line-height: 1;
  outline: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.keepfly-like-iconWrap {
  display: inline-flex;
  line-height: 1;
  position: relative;
  vertical-align: bottom;
}

.keepfly-like-iconWrap:before {
  background-color: rgba(255, 130, 0, 0.1);
  border-radius: 50%;
  bottom: -0.3125em;
  content: "";
  left: -0.3125em;
  opacity: 0;
  position: absolute;
  right: -0.25em;
  top: -0.25em;
}

.keepfly-like-icon {
  height: 0.9375em;
  vertical-align: bottom;
  width: 0.9375em;
}

.keepfly-like-count {
  font-size: 0.75em;
  margin-left: 0.625em;
  vertical-align: bottom;
}

.Main_sideMain {
  height: 100%;
}

.Side_posSticky {
  position: sticky;
  z-index: 99;
}

.Side_sideBox {
  width: 282px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.wbpro-side-tit {
  height: 40px;
  padding: 0 18px;
}

.wbpro-side .cla {
  color: #333;
}

.wbpro-side .f14 {
  font-size: 14px;
  line-height: 18px;
}

.wbpro-side .f12 {
  font-size: 12px;
  line-height: 16px;
}

.Nav_main_user {
  position: sticky;
  top: 56px;
}

.Card_wrap .keepfly-divider-main {
  color: #f9f9f9;
}

.keepfly-divider-x {
  border-bottom: 1px solid;
}

.keepfly-divider-main {
  color: #f9f9f9;
}

.wbpro-side-panel {
  padding: 0 18px;
}

.wbpro-side-card7 .con {
  position: relative;
  height: 40px;
  padding: 0 30px 0 24px;
  overflow: hidden;
  white-space: nowrap;
}

.wbpro-side-card7 .top {
  font-size: 12px;
  color: #f26d5f;
}

.wbpro-side-card7 .rank {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-size: 15px;
  color: #ff8200;
}

.wbpro-side-card7 .wbpro-textcut {
  margin-right: 10px;
}

.wbpro-side .cla {
  color: #333;
}

.wbpro-side .f12 {
  font-size: 12px;
  line-height: 16px;
}

.wbpro-cutword,
.wbpro-textcut {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wbpro-textcut {
  white-space: nowrap;
}

.wbpro-side-card7 .icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin: -10px 0 0;
}

.wbpro-side-card7 [class^="wbpro-icon-search"] {
  margin-right: -2px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.wbpro-icon-search {
  background: #ff9406;
}

[class^="wbpro-icon-search-2"],
[class^="wbpro-icon-search"] {
  display: inline-block;
  height: 16px;
  border-radius: 4px;
  line-height: 16px;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

[class^="wbpro-icon-search"] {
  width: 20px;
}

.wbpro-side-card7 .con {
  position: relative;
  height: 40px;
  padding: 0 30px 0 24px;
  overflow: hidden;
  white-space: nowrap;
}

.wbpro-side-card7 .num {
  font-size: 16px;
  font-weight: 700;
  color: #f26d5f;
}

.wbpro-side .clb {
  color: #939393;
}

.keepfly-button-main.keepfly-button-fluid {
  width: 100%;
}

.keepfly-button-wrap {
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: inline-flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
  position: relative;
  vertical-align: top;
  z-index: 9;
}

.keepfly-button-content {
  display: inline-flex;
  vertical-align: bottom;
}

.wbpro-form.error,
.wbpro-form.focus {
  background: #fff;
}

.wbpro-form.focus {
  border-color: #ff8200;
}

.wbpro-pos {
  position: relative;
}

.Emoticon_box1 {
  position: absolute !important;
}

.Emoticon_box1 > div {
  width: 480px;
}

.Emoticon_tabtit {
  width: 456px;
  margin: 2px 12px 0;
  overflow-x: hidden;
}

.keepfly-tab-item-main {
  cursor: pointer;
  position: relative;
}

.Emoticon_tab .wbpro-iconbed {
  width: 38px;
  height: 38px;
}

.keepfly-tab-active .Emoticon_img,
.keepfly-tab-item-main:hover .Emoticon_img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.Emoticon_img {
  width: 24px;
  height: 24px;
}

.keepfly-pop-main {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 10%);
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 10%);
  min-width: 6.25rem;
  position: absolute;
  z-index: 9999;
}

.keepfly-tab-border {
  background-color: #ff8200;
  bottom: 0;
  height: 0.125rem;
  left: 0;
  position: absolute;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.u-col-12 [class*="box-item"],
.u-col-12 [data-type="w-box-item"] {
  width: 8.333%;
}

.Emoticon_facebox1 {
  height: 266px;
  margin: 12px 2px 12px 12px;
  padding: 0 10px 0 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.Image_pic {
  border-radius: inherit;
}

.keepfly-picture-main {
  display: inline-block;
  position: relative;
  width: 100%;
}

.keepfly-picture-square:before {
  padding-bottom: 100%;
}

.keepfly-picture-main:before {
  content: "";
  display: block;
  width: 100%;
}

.keepfly-picture-img {
  -o-object-fit: cover;
  object-fit: cover;
}

.keepfly-picture-hoverMask,
.keepfly-picture-img {
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.keepfly-picture-cover {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: inherit;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.keepfly-picture-hoverMask {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
}

.Image_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 15px;
  height: 15px;
}

.keepfly-fonticon-abovePic {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.keepfly-fonticon-dark {
  background-color: rgba(0, 0, 0, 0.2);
}

.keepfly-fonticon-multi {
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.keepfly-fonticon-multi:hover {
  color: #ff8200;
}

.keepfly-font--close:before {
  content: "";
}

.Image_focus {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 24px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 12px;
  color: #fff;
}

.FileUpload_icon_view {
  width: 30px;
  height: 30px;
}

.Form_suggest {
  z-index: 1024;
}

.Suggest_container {
  position: absolute;
  min-width: 193px;
  padding: 2px;
  background: #fff;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Suggest_item,
.Suggest_title {
  padding: 10px;
  border-radius: 8px;
  line-height: 1;
  font-size: 14px;
  color: #333;
}

.Suggest_list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  max-width: 300px;
}

.Suggest_active {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
  border-radius: 0;
  cursor: pointer;
}

.Nav_main {
  position: sticky;
  top: 56px;
}

.head_content_wrap {
  height: 100%;
}

.keepfly-button-line {
  border: 1px solid;
}

.follow-btn_followbtn {
  width: 64px;
  height: 28px;
  padding: 0 !important;
}

.keepfly-button-line.keepfly-button-primary {
  color: #ff8200;
}

.morepop_action {
  font-size: 16px;
  font-weight: 700 !important;
  color: #585858;
}

.morepop_moreIcon:hover,
.morepop_moreIcon:hover .morepop_action {
  color: #ff8200;
}

.morepop_moreIcon:hover {
  background-color: rgba(255, 130, 0, 0.1);
}

/* .picture-box_row {
  margin-top: 10px;
} */

.picture_inlineNum3 {
  width: 78.6%;
}

.picture_pic {
  z-index: 8;
  vertical-align: top;
  overflow: hidden;
  border-radius: 8px;
}

.wbpro-side .clf {
  color: #838383;
}

.wbpro-side .f13 {
  font-size: 13px;
  line-height: 18px;
}

.wbpro-side-opt {
  height: 38px;
}

.cardHotSearch_btn {
  background: #f9f9f9;
  border-radius: 4px;
}

.wbpro-tab2 .cur .text {
  font-weight: 500;
  color: #ff8200;
}

.wbpro-tab2 .text:hover {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
}

.wbpro-tab2 .text {
  padding: 5px 12px;
  border-radius: 15px;
  line-height: 20px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.Tab_nowrap {
  white-space: nowrap;
}

.MessageCmt_wrap {
  width: 100%;
}

.MessageCmt_item1 {
  padding: 20px 20px 0;
}

.MessageCmt_con1 {
  margin: 0 0 0 10px;
  overflow: hidden;
}

.MessageCmt_h3 {
  color: #333;
  font-size: 15px;
  line-height: 21px;
  margin: 8px 0 7px;
  font-weight: bolder;
}

.MessageCmt_from {
  color: #939393;
  font-size: 13px;
  line-height: 13px;
}

.MessageCmt_textImg {
  color: #333;
  font-size: 16px;
  line-height: 25px;
  margin-top: 7px;
}

.MessageCmt_wbtext {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.MessageCmt_from a {
  cursor: pointer;
  text-decoration: none;
  color: #939393;
}

.MessageCmt_repeatbgbox {
  margin: 10px 0 0;
  padding: 0 20px 10px 80px;
  background-color: #f9f9f9;
}

.MessageCmt_repeatbox {
  padding: 0 20px 0 80px;
  cursor: pointer;
}

.MessageCmt_replyComment {
  padding: 10px 0 16px;
  color: #333;
  font-size: 15px;
  line-height: 24px;
}
.MessageCmt_replyComment span img {
  width: 19px;
}
.MessageCmt_replyCname {
  color: #eb7350;
}

.feed-card-repost {
  background-color: #fff;
}

.card-link_wrap {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 8px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  overflow: hidden;
  cursor: pointer;
  color: #333;
}

.card-link_mid {
  min-width: 0;
  padding: 10px;
}

.card-link_title {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}

.card-link_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-link_messText {
  color: #333;
}

.card-link_text {
  font-size: 13px;
  line-height: 20px;
  color: #939393;
}

.card-link_cut2 {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.bottom_main {
  height: 40px;
}

.bottom_left .bottom_btn,
.bottom_left .bottom_item {
  text-align: left;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: 100%;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom_side .bottom_item {
  -webkit-box-flex: initial;
  -ms-flex: initial;
  flex: initial;
}

.bottom_wrap {
  width: 100%;
  height: 100%;
}

.bottom_iconWrap {
  width: 18px;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

.bottom_num {
  font-size: 12px;
  line-height: 12px;
}

.keepfly-box-justifyBetween {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wbpro-setup__box {
  padding: 20px;
}

.wbpro-setup__tit {
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}

.Avatar_picbox {
  width: 100px;
  margin: 18px auto 30px;
}

.Avatar_pic {
  border-radius: 50%;
  vertical-align: top;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.keepfly-picture-slot {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Avatar_picin .Avatar_picicon {
  opacity: 0;
  font-size: 40px;
  color: hsla(0, 0%, 100%, 0.8);
  text-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
}

.Avatar_picin:hover .Avatar_picicon {
  opacity: 1;
}

.Avatar_picin:hover {
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.wbpro-setup__f1 {
  margin: 20px 0 0;
}

.wbpro-setup__b1 {
  width: 76px;
  height: 34px;
  margin: 0 20px 0 -20px;
}

.wbpro-setup__h3 {
  line-height: 18px;
  font-size: 14px;
  color: #333;
}

.wbpro-setup__ipt1 {
  width: 100%;
}

.wbpro-setup__box .keepfly-input-main {
  height: 32px;
  font-size: 12px;
}

.wbpro-setup__tip {
  position: relative;
  margin: 8px 0 0;
}

.wbpro-setup__h5 {
  line-height: 16px;
  font-size: 12px;
  color: #939393;
  vertical-align: top;
}

.wbpro-setup__ipt2 {
  height: 34px;
}

.keepfly-radio-main {
  position: relative;
}

.keepfly-radio-input {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.wbpro-setup__box .keepfly-radio-main + .keepfly-radio-main {
  margin-left: 30px;
}

.keepfly-radio-checked {
  background-color: #ff8200;
  color: #ff8200;
}

.keepfly-radio-shadow {
  border: 1px solid;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #ccc;
  display: inline-block;
  height: 0.875em;
  position: relative;
  vertical-align: middle;
  width: 0.875em;
}

.keepfly-radio-shadow:before {
  background-color: #fff;
  border-radius: 50%;
  bottom: 0.1875em;
  content: "";
  left: 0.1875em;
  position: absolute;
  right: 0.1875em;
  top: 0.1875em;
  visibility: hidden;
}

.keepfly-radio-checked:before {
  visibility: visible;
}

.ProfileHeader_pic {
  width: calc(100% + 2px);
  height: 230px;
  margin: -1px -1px 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.ProfileHeader_box1 {
  margin: 10px 20px 10px 22px;
}

.ProfileHeader_avatar2 {
  cursor: pointer;
  z-index: 10;
  margin: -50px 20px 0 0;
}

.ProfileHeader_h3 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
}

.ProfileHeader_h3 + .ProfileHeader_h4 {
  margin-top: 7px;
}

.ProfileHeader_h4 {
  line-height: 18px;
  font-size: 14px;
  color: #939393;
}

.ProfileHeader_name {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.keepfly-icon-main {
  display: inline-block;
  height: 0.875em;
  position: relative;
  vertical-align: top;
  width: 0.875em;
}

.ProfileHeader_pointer {
  cursor: pointer;
}

.ProfileHeader_h4 span span {
  margin-left: 6px;
  color: #333;
}

.ProfileHeader_h4 a + a {
  margin-left: 20px;
}

.ProfileHeader_content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ProfileHeader_icon3 {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  font-size: 12px;
  color: #838383;
}

.ProfileHeader_con3 {
  line-height: 16px;
  font-size: 12px;
  color: #939393;
}

.ProfileHeader_box2 {
  margin: 0 20px 0 26px;
}

.ProfileHeader_item3 {
  margin: 0 0 12px;
  vertical-align: top;
}

.ProfileHeader_box3 {
  margin: 0 20px 0 26px;
}

.ProfileHeader_item3 + .ProfileHeader_item3 {
  margin-left: 20px;
}

.NavItem_icon {
  width: 22px;
  height: 22px;
  margin: 0 5px 0 1px;
  overflow: hidden;
  font-size: 22px;
  color: #838383;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.detail_wbtext img {
  width: 18px;
  height: 18px;
  vertical-align: -4px;
}

.Feed_retweet {
  background-color: #f9f9f9;
  margin: 8px -18px 0;
  padding: 6px 18px 12px 80px;
}

.detail_nick,
.detail_reText {
  color: #333;
  font-size: 15px;
  line-height: 24px;
}

.Frame_side1 {
  width: 202px;
}

.LeftNav_box {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
}

.LeftNav_inner {
  padding: 0 4px 40px;
  height: 100%;
  border-color: #f2f2f2 !important;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  overflow-y: auto;
  color: #333;
}

.LeftNav_tit,
.LeftNav_titL {
  padding: 10px 16px;
  font-weight: 500;
}

.LeftNav_titL {
  line-height: 26px;
  font-size: 22px;
}

.LeftNav_item {
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #333;
}

.star-f14 {
  line-height: 20px;
  font-size: 14px;
}

.LeftNav_curr,
.LeftNav_curr i {
  color: #ff8200;
}

.Frame_main1 {
  width: 904px;
}

.Tit_wrap {
  margin: 10px 0 18px;
}

.Tit_tit {
  line-height: 26px;
  font-size: 22px;
  font-weight: 700;
  color: #333;
}

.List1_list {
  margin-bottom: 22px;
  overflow: hidden;
}

.Item_card {
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
}

.Item_wrapY .Item_pic {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}

.keepfly-picture-main {
  display: inline-block;
  position: relative;
  width: 100%;
}

.Item_pic {
  line-height: 0;
}

.keepfly-picture-wide169:before {
  padding-bottom: 56.25%;
}

.keepfly-picture-main:before {
  content: "";
  display: block;
  width: 100%;
}

.Item_duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 10;
  height: 18px;
  padding: 0 4px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.Item_D .Item_con {
  padding: 7px 10px 10px;
}

.star-autocut-2line {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.star-autocut,
.star-autocut-2line {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Item_h3 {
  height: 40px;
  font-weight: 700;
  color: #333;
}

.Item_C .Item_h3,
.Item_lr {
  margin-top: 8px;
}

.keepfly-avatar-main {
  border-radius: 50%;
  display: inline-block;
  font-size: 1rem;
  height: 2.5em;
  position: relative;
  width: 2.5em;
}

.Item_face {
  margin-right: 10px;
}

.Item_conin {
  overflow: hidden;
}

.Item_D .Item_h4s {
  line-height: 17px;
  font-size: 12px;
}

.Item_con {
  width: 100% !important;
  overflow: hidden;
}

.star-f12 {
  line-height: 17px;
  font-size: 12px;
}

.star-autocut,
.star-autocut-2line {
  overflow: hidden;
  text-overflow: ellipsis;
}

.star-autocut {
  white-space: nowrap;
}

.Item_h4 {
  color: #939393;
}

.Frame_content1 {
  width: 1122px;
}

.Frame_content1,
.Frame_content2 {
  margin: 0 auto;
}

.keepfly-modal-mask,
.keepfly-modal-wrap {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.keepfly-modal-wrap {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  z-index: 9999;
}

.keepfly-modal-main {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 9999;
}

.Avatar_picbox .wbpro-layer {
  width: 549px !important;
}

.wbpro-layer {
  width: 600px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.wbpro-layer-tit {
  height: 48px;
}

.wbpro-layer-tit-text,
.wbpro-layer-tit .tit {
  margin: 0 0 0 20px;
  font-weight: 700;
  color: #333;
}

.keepfly-box-item-flex,
.keepfly-box-item-inlineBlock {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wbpro-layer-tit-opt,
.wbpro-layer-tit .opt {
  width: 38px;
  cursor: pointer;
}

.Avatar_editbox {
  padding: 20px;
}

.Avatar_blt {
  width: 360px;
}

.Avatar_bltb {
  margin: 10px 0 0;
}

.Avatar_brt {
  width: 140px;
  height: 360px;
  background: #f2f2f2;
  border-radius: 4px;
  line-height: 16px;
}

.Avatar_tit {
  margin: 20px 0 0;
  font-size: 14px;
}

.Avatar_face1 {
  width: 100px;
  height: 100px;
  margin: 32px 0 0;
  outline: 3px solid #fff;
}

.Avatar_face {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
}

.Avatar_text {
  margin: 8px 0 0;
  font-size: 12px;
  color: #939393;
}

.Avatar_face2 {
  width: 50px;
  height: 50px;
  margin: 50px 0 0;
}

.wbpro-layer-btn {
  margin: 20px 0;
}

.keepfly-button-flat.keepfly-button-default {
  background: #eee;
  color: #333;
}

.wbpro-layer-btn-item,
.wbpro-layer-btn .btn {
  margin: 0 5px;
}

.keepfly-button-flat.keepfly-button-primary:not(:disabled):hover {
  background: #ff5900;
}

.keepfly-button-flat.keepfly-button-primary {
  background: #ff8200;
  color: #fff;
}

.Avatar_bltb .custom-btn-link {
  color: #eb7350;
}

.keepfly-button-simple:hover {
  opacity: 0.8;
}

.keepfly-modal-mask {
  background-color: #000;
  opacity: 0.5;
  z-index: 999;
}
.wbpro-setup__f3 {
  position: relative;
  margin: 24px 0 0;
  padding: 6px 160px 6px 0;
}
.wbpro-setup__b2 {
  position: absolute;
  right: 0;
  top: 6px;
}
.wbpro-setup__h3 {
  line-height: 18px;
  font-size: 14px;
  color: #333;
}
.wbpro-setup__h3 + .wbpro-setup__h4 {
  margin: 8px 0 0;
}
.wbpro-setup__b2 {
  position: absolute;
  right: 0;
  top: 6px;
}
.wbpro-setup__b2 .keepfly-switch-main {
  margin: 2px 0 0;
}
.keepfly-switch-main {
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.keepfly-switch-input {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.wbpro-setup__box .keepfly-switch-checked,
.wbpro-setup__box .keepfly-switch-shadow {
  width: 32px;
  height: 14px;
  background: hsla(0, 0%, 63.5%, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.keepfly-switch-shadow,
.keepfly-switch-shadow:after {
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.keepfly-switch-shadow {
  border-radius: 2em;
  -webkit-box-shadow: inset 0 0 0 0.09375em #e9e9e9;
  box-shadow: inset 0 0 0 0.09375em #e9e9e9;
  display: inline-block;
  height: 2em;
  position: relative;
  -webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.43, 0.98);
  transition: -webkit-box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.43, 0.98);
  transition: box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.43, 0.98);
  transition: box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.43, 0.98),
    -webkit-box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.43, 0.98);
  vertical-align: top;
  width: 3.1875em;
}
.wbpro-setup__box .keepfly-switch-shadow:after {
  left: -4px;
  background: #f9f9f9;
}
.wbpro-setup__box .keepfly-switch-checked:after,
.wbpro-setup__box .keepfly-switch-shadow:after {
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.keepfly-switch-shadow:after {
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  -webkit-box-shadow: 0 0.1875em 0.5em 0 rgba(0, 0, 0, 0.15),
    0 0.0625em 0.0625em 0 rgba(0, 0, 0, 0.16), 0 0.1875em 0.0625em 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1875em 0.5em 0 rgba(0, 0, 0, 0.15),
    0 0.0625em 0.0625em 0 rgba(0, 0, 0, 0.16), 0 0.1875em 0.0625em 0 rgba(0, 0, 0, 0.1);
  content: "";
  height: 1.875em;
  left: 0;
  position: absolute;
  top: 0.0625em;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.42, 0.42, 0.25, 1.25);
  transition: -webkit-transform 0.3s cubic-bezier(0.42, 0.42, 0.25, 1.25);
  transition: transform 0.3s cubic-bezier(0.42, 0.42, 0.25, 1.25);
  transition: transform 0.3s cubic-bezier(0.42, 0.42, 0.25, 1.25),
    -webkit-transform 0.3s cubic-bezier(0.42, 0.42, 0.25, 1.25);
  width: 1.875em;
}
.keepfly-switch-shadow,
.keepfly-switch-shadow:after {
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.keepfly-switch-checked {
  -webkit-box-shadow: inset 0 0 0 2em #ff8200;
  box-shadow: inset 0 0 0 2em #ff8200;
}
.wbpro-setup__box .keepfly-switch-checked,
.wbpro-setup__box .keepfly-switch-shadow {
  width: 32px;
  height: 14px;
  background: hsla(0, 0%, 63.5%, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wbpro-setup__box .keepfly-switch-checked:after {
  right: -4px;
  background: #78d15d;
}
.wbpro-setup__box .keepfly-switch-checked:after,
.wbpro-setup__box .keepfly-switch-shadow:after {
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.keepfly-switch-checked:after {
  -webkit-transform: translate3d(1.25em, 0, 0);
  transform: translate3d(1.25em, 0, 0);
}
.UserFeedCard_userFeedCard {
  width: 100%;
  cursor: pointer;
}
.UserFeedCard_item {
  padding: 20px;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.UserFeedCard_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.UserFeedCard_f12 {
  font-size: 12px;
  line-height: 16px;
}
.UserFeedCard_con {
  margin: 0 0 0 10px;
  overflow: hidden;
}
.UserFeedCard_fb {
  font-weight: 700;
}
.UserFeedCard_cla {
  color: #333;
}
.UserFeedCard_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.UserFeedCard_clb {
  color: #939393;
}
.UserFeedCard_userFeedCard:hover,
.FollowFeed_wrap:hover {
  background-color: #f2f2f2;
}
.ListTitle_tit {
  height: 44px;
  padding: 0 10px 0 20px;
}
.ListTitle_th3 {
  font-weight: 400;
}
.ListTitle_cla {
  color: #333;
}
.ListTitle_f14 {
  font-size: 14px;
  line-height: 18px;
}
.FollowFeed_wrap {
  width: 100%;
}
.FollowFeed_item {
  padding: 20px;
  cursor: pointer;
}
.UserCard_item {
  cursor: pointer;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.UserCard_con {
  margin: 0 0 0 10px;
  overflow: hidden;
}
.UserCard_f12 {
  font-size: 12px;
  line-height: 16px;
}
.UserCard_cla {
  color: #333;
}
.UserCard_fb {
  font-weight: 700;
}
.UserCard_clb {
  margin-top: 3px;
  color: #939393;
}
.UserCard_rightContent {
  margin: 0 10px 0 20px;
}
.UserCard_followBtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.UserCard_right {
  margin: 0 0 0 10px;
}
.ListTitle_topt {
  cursor: pointer;
}
.ListTitle_f12 {
  font-size: 12px;
  line-height: 16px;
}
.wbpro-tab2 .wbpro-textcut:hover {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
}
.LeftNav_item svg {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  overflow: hidden;
  font-size: 24px;
}
.toolbar_likebox:hover .toolbar_btn {
  color: #ff8200;
}
.keepfly-like-main:not(:disabled):hover,
.keepfly-like-main:not(:disabled):hover .keepfly-like-count {
  color: #ff8200;
}
.Feed_mar1 {
  margin: 10px 20px 0;
}
.Feed_box {
  padding-top: 10px;
}
.Feed_mar2 {
  margin: 0 20px;
}
.MainComposer_mar2 {
  margin: 0 10px 0 0;
}
.Form_input {
  min-height: 24px;
  max-height: 456px;
  resize: none;
}
.Composer_mar1 {
  margin: 10px 0 0;
}
.wbpro-list .item1 {
  padding: 7px 20px;
}
.wbpro-list .con1 {
  margin: -2px 0 0 10px;
}
.wbpro-list .text {
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}
.wbpro-list .text a {
  color: #eb7350;
  text-decoration: none;
  cursor: pointer;
}
.wbpro-list .info {
  height: 22px;
  margin: 1px 0 0;
  color: #939393;
}
.wbpro-list {
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.RepostCommentFeed_divider {
  margin: 7px 20px 0 20px;
}
.RepostCommentFeed_more:hover {
  color: #eb7350;
}
.RepostCommentFeed_more {
  height: 44px;
  background: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}
.n-upload-dragger {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  padding: 24px;
  opacity: 1;
  transition: opacity 0.3s var(--n-bezier), border-color 0.3s var(--n-bezier),
    background-color 0.3s var(--n-bezier);
  background-color: var(--n-dragger-color);
  border: 1px dashed rgb(224, 224, 230);
}
.n-upload-trigger.n-upload-trigger--image-card {
  width: 96px;
  height: 96px;
}
.n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger {
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-upload-dragger:hover {
  border: 1px dashed #eb7350;
}
.HotTopic_item {
  padding: 12px 10px;
  line-height: 1;
  font-size: 14px;
  color: #333;
}

.HotTopic_titout {
  margin: 10px 0 0;
}

.HotTopic_ranknum {
  position: absolute;
  bottom: 2px;
  left: 50%;
  -webkit-transform: translate(-50%) skewX(-10deg);
  transform: translate(-50%) skewX(-10deg);
}

.HotTopic_tit {
  margin: 0 6px 0 8px;
  height: 25px;
  line-height: 25px;
  font-weight: 700;
  font-size: 14px;
  width: 402px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HotTopic_wbtext {
  font-size: 14px;
  line-height: 20px;
  -webkit-line-clamp: 1;
  color: #838383;
}

.HotTopic_margin {
  margin: 8px 0 0;
}

.HotTopic_wbtext a {
  color: #838383;
}

.HotTopic_wbtext img {
  width: 18px;
  height: 18px;
  vertical-align: -4px;
}

.HotTopic_wbtext img.icon-link {
  width: 1em;
  height: 1em;
  margin-right: 2px;
  vertical-align: -2px;
  -webkit-filter: sepia(100%) saturate(38%) contrast(75%);
  filter: sepia(100%) saturate(38%) contrast(75%);
}

.HotTopic_num {
  font-size: 12px;
  color: #939393;
  margin-top: 10px;
  line-height: 17px;
}

.HotTopic_num span {
  margin: 0 20px 0 0;
}

.HotTopic_num {
  font-size: 12px;
  color: #939393;
  margin-top: 10px;
  line-height: 17px;
}

.HotTopic_right {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 0 0 10px;
}

.HotTopic_pos,
.HotTopic_video {
  width: 100%;
  height: 100%;
}

.HotTopic_videomask {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 37px;
  height: 18px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  z-index: 1;
}

.HotTopic_videotime {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  height: 17px;
  line-height: 17px;
  text-align: center;
  width: 100%;
}

.HotTopic_wrap {
  width: 100%;
  cursor: pointer;
}

.HotTopic_img {
  border-radius: 8px;
  overflow: hidden;
}

.HotTopic_pos {
  position: absolute;
  top: 0;
  left: 0;
}

.HotTopic_tit:hover,
.HotTopic_wbtext:hover {
  color: #ff8200;
}

.wbpro-cutword {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.HotTopic_wbtext:hover a {
  color: #ff8200;
  text-decoration: none;
}

.HotTopic_focusImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.HotTopic_rankimg {
  width: 32px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(../../assets/hotRankDefault.png);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.HotTopic_rank1 {
  background-image: url(../../assets/hotRank1.png);
}

.HotTopic_rank2 {
  background-image: url(../../assets/hotRank2.png);
}

.HotTopic_rank3 {
  background-image: url(../../assets/hotRank3.png);
}

.HotTopic_rank1,
.HotTopic_rank2 {
  width: 45.46px;
  height: 22.79px;
  margin-top: -5px;
}

.HotTopic_rank1 > .HotTopic_ranknum,
.HotTopic_rank2 > .HotTopic_ranknum,
.HotTopic_rank3 > .HotTopic_ranknum {
  left: auto;
  right: 28.5%;
  bottom: 1px;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.HotTopic_rank3 {
  width: 45.46px;
  height: 22.79px;
  margin-top: -5px;
  background-image: url(../../assets/hotRank3.png);
}
.HotTopic_doticon {
  background: #ff8747;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 0 10px;
}

.wbpro-side-copy,
.wbpro-side-copy-inner {
  position: relative;
  z-index: 999;
}

.wbpro-side-copy {
  width: 100%;
  height: 242px;
  /* padding-top: 18px; */
}

.wbpro-side-copy-inner {
  height: 100%;
}

.wbpro-side-copy,
.wbpro-side-copy-inner {
  position: relative;
  z-index: 999;
}

.wbpro-side-copy .scale {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  width: 330px;
  margin: 0 0 0 -165px;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  font-size: 12px;
}
.CopyRight_item {
  margin-left: 20px;
  /* margin-top: 16px; */
}

.CopyRight_title {
  font-size: 11px;
  line-height: 18px;
  color: #606060;
}

.CopyRight_icon {
  position: absolute;
  left: 0;
  font-size: 14px;
}

.CopyRight_content {
  margin-top: 3px;
  font-size: 10px;
  color: #939393;
  line-height: 18px;
}
.wbpro-side-copy a {
  color: #939393;
  text-decoration: none;
}

.CopyRight_contentItem {
  margin-left: 12px;
}

.CopyRight_cricon {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  white-space: nowrap;
}

.CopyRight_aria {
  height: 36px;
  margin-left: 15px;
  border: 1px solid #939393;
}
.wbpro-screen-v2 {
  height: 24px;
  margin: 0 0 6px;
  padding: 0 4px;
  font-size: 14px;
}
</style>
