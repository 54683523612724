<template>
  <div>
    <div class="keepfly_head" v-show="head_show">
      <div
        class="keepfly-box-flex keepfly-box-justifyCenter keepfly-box-alignCenter HomeTopNav_wrap keepfly-box-column"
      >
        <div class="HomeTopNav_logo">
          <img :src="kukesystemInfo.logo1" class="Logo_main" />
        </div>
        <div style="color: #fff; z-index: 1">{{ txtstr }}</div>
        <div class="HomeTopNav_searchbox">
          <div class="HomeTopNav_search">
            <div class="keepfly-input-wrap keepfly-input-hasIcon HomeTopNav_ipt">
              <span class="keepfly-input-icon">
                <n-icon size="30">
                  <search-outline></search-outline>
                </n-icon>
              </span>
              <input
                type="text"
                :placeholder="$t('message.search') + ' keepfly'"
                class="keepfly-input-main"
              />
            </div>
            <button
              @click="$router.push('login')"
              class="keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m HomeTopNav_btn"
            >
              <span class="keepfly-button-wrap">
                <span class="keepfly-button-content">{{ $t("message.search") }}</span>
              </span>
            </button>
          </div>
        </div>
        <div class="HomeTopNav_videoc" style="background: #ff8200">
          <video
            loop="loop"
            autoplay="autoplay"
            preload=""
            muted="muted"
            src="../assets/weibo_login.mp4"
            poster="../assets/18weibo_login.png"
            class="HomeTopNav_video"
          >
            抱歉，您的浏览器不支持内嵌视频
          </video>
        </div>
      </div>
    </div>
    <nav :class="['header_nav', { fix: nav_show }]" v-show="nav_show">
      <div class="keepfly-box-flex keepfly-box-alignCenter Nav_main">
        <div class="keepfly-box-flex keepfly-box-alignCenter Nav_left">
          <a href=""><img :src="kukesystemInfo.logo2" class="Nav_logoWrap" /></a>
          <div class="keepfly-pop-wrap SearchBar_main">
            <span class="keepfly-pop-ctrl">
              <form>
                <div
                  class="keepfly-input-wrap keepfly-input-round keepfly-input-hasIcon SearchBar_main SearchBar_iptbox"
                >
                  <span class="keepfly-input-icon">
                    <n-icon size="16">
                      <search-outline></search-outline>
                    </n-icon>
                  </span>
                  <input
                    type="text"
                    @keydown.enter="$router.push('login')"
                    :placeholder="$t('message.search') + ' keepfly'"
                    class="keepfly-input-main"
                  />
                </div>
              </form>
            </span>
          </div>
        </div>
        <div
          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter Nav_mid"
        >
          <ul class="header_lists clear-fix">
            <li>
              <a @click="$router.push('/login')">
                {{ $t("message.information") }}
              </a>
            </li>
            <li>
              <a @click="deceased_square_view()">
                {{ $t("message.memorial_plaza") }}
              </a>
            </li>
            <li>
              <a @click="$router.push('/')">
                {{ $t("message.home") }}
              </a>
            </li>
          </ul>
        </div>
        <div class="keepfly-box-flex keepfly-box-justifyEnd Nav_right">
          <div class="keepfly-box-flex">
            <a href="/#/login" class="LoginBtn_btn LoginBtn_btna loginBtn">{{
              $t("message.SignIn")
            }}</a>
            <a href="/#/enroll" class="LoginBtn_btn LoginBtn_btnb registerBtn">{{
              $t("message.register")
            }}</a>
          </div>
          <div class="keepfly-box-flex keepfly-box-alignCenter Nav_configs">
            <n-select
              @update:value="handleSearch"
              v-model:value="selectedValue"
              status="warning"
              label-field="name"
              value-field="language"
              :theme-overrides="selectThemeOverrides"
              :options="options"
            />
          </div>
        </div>
      </div>
    </nav>
    <div
      class="keepfly-box-flex Frame_content"
      :style="{ height: content_height + 'px' }"
    >
      <div
        class="Frame_side"
        :style="{
          marginTop: flat ? '0' : '94px',
          height: content_height + 'px',
          minHeight: 1005 + 'px',
        }"
      >
        <div class="Nav_main">
          <div
            class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner"
          >
            <h2 class="Nav_title"></h2>
            <div>
              <div @click="changeNav(1)" class="router-link-active ALink_default">
                <div
                  :class="[
                    1 == indexActive
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <n-icon class="keepfly-font NavItem_icon" size="20">
                    <flame-sharp></flame-sharp>
                  </n-icon>
                  <span class="NavItem_text">{{ $t("message.popular") }}</span>
                </div>
              </div>
              <div @click="changeNav(5)" class="router-link-active ALink_default">
                <div
                  :class="[
                    5 == indexActive
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <n-icon class="keepfly-font NavItem_icon" size="20">
                    <home-outline></home-outline>
                  </n-icon>
                  <span class="NavItem_text">{{ $t("message.memorial_plaza") }}</span>
                </div>
              </div>
              <div @click="changeNav(2)" class="router-link-active ALink_default">
                <div
                  :class="[
                    2 == indexActive
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <n-icon class="keepfly-font NavItem_icon" size="20">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 15H7.3l-2.55-3.5V15H3.5V9h1.25l2.5 3.5V9H8.5v6zm5-4.74H11v1.12h2.5v1.26H11v1.11h2.5V15h-4V9h4v1.26zm7 3.74c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9h1.25v4.51h1.13V9.99h1.25v3.51h1.12V9h1.25v5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </n-icon>
                  <span class="NavItem_text">{{ $t("message.topic") }}</span>
                </div>
              </div>
              <div
                @click="changeNav(3)"
                class="router-link-exact-active router-link-active ALink_default"
              >
                <div
                  :class="[
                    3 == indexActive
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <n-icon class="keepfly-font NavItem_icon" size="20">
                    <sparkles-sharp></sparkles-sharp>
                  </n-icon>
                  <span class="NavItem_text">{{ $t("message.goldenSentenceList") }}</span>
                </div>
              </div>
              <div @click="changeNav(4)" class="router-link-active ALink_default">
                <div
                  :class="[
                    4 == indexActive
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                >
                  <n-icon class="keepfly-font NavItem_icon" size="20">
                    <help-circle-outline></help-circle-outline>
                  </n-icon>
                  <span class="NavItem_text">{{ $t("message.askQuestions") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="Frame_main"
        :style="{
          width: '80%',
          marginTop: flat ? '0' : '94px',
          height: content_height + 'px',
        }"
      >
        <main
          class="keepfly-box-flex Main_wrap"
          :style="{ height: content_height + 'px' }"
        >
          <div class="Main_full" v-show="1 == indexActive">
            <div :class="['SecBar_visable', { SecBar_secBar: SecBar_secBar }]">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="wbpro-tab2 u-col-8">
                  <span v-for="(item, index) in tagArr" :key="item">
                    <div
                      :class="[
                        index == tagnum
                          ? 'keepfly-box-item-inlineBlock cur'
                          : 'keepfly-box-item-inlineBlock',
                      ]"
                      :style="{ display: index < 7 ? 'inline-block' : 'none' }"
                    >
                      <div
                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                      >
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameAr, index)"
                          :title="item.nameAr"
                          v-if="item.nameAr"
                        >
                          {{ item.nameAr }}
                        </div>
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameEn, index)"
                          :title="item.nameEn"
                          v-if="item.nameEn"
                        >
                          {{ item.nameEn }}
                        </div>
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameJa, index)"
                          :title="item.nameJa"
                          v-if="item.nameJa"
                        >
                          {{ item.nameJa }}
                        </div>
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameKo, index)"
                          :title="item.nameKo"
                          v-if="item.nameKo"
                        >
                          {{ item.nameKo }}
                        </div>
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameRu, index)"
                          :title="item.nameRu"
                          v-if="item.nameRu"
                        >
                          {{ item.nameRu }}
                        </div>
                        <div
                          class="wbpro-textcut"
                          @click="setTagLable(item.nameZh, index)"
                          :title="item.nameZh"
                          v-if="item.nameZh"
                        >
                          {{ item.nameZh }}
                        </div>
                      </div>
                    </div>
                  </span>
                  <div class="keepfly-box-item-inlineBlock" v-if="tagArr.length > 7">
                    <div
                      :title="$t('message.more')"
                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                    >
                      <n-dropdown
                        trigger="hover"
                        :options="tagmoreoptions"
                        @select="tagmorehandleSelect"
                      >
                        <menu-sharp
                          class="keepfly-font keepfly-font--more"
                          style="width: 26px"
                        ></menu-sharp>
                      </n-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="Scroll_container">
                <div class="Scroll_wrap">
                  <div
                    class="vue-recycle-scroller ready page-mode direction-vertical"
                    id="scroller"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div
                        class="vue-recycle-scroller__item-view"
                        v-if="microblogs.length > 0"
                      >
                        <div
                          class="wbpro-scroller-item"
                          v-for="item in microblogs"
                          :key="item"
                        >
                          <article
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                            style="border-radius: 4px"
                          >
                            <div class="Feed_body">
                              <header class="keepfly-box-flex">
                                <a class="ALink_default" @click="$router.push('login')">
                                  <div
                                    class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                    style="width: 3.125rem; height: 3.125rem"
                                  >
                                    <img :src="item.avatar" class="keepfly-avatar-img" />
                                  </div>
                                </a>
                                <div class="keepfly-box-item-flex head_main">
                                  <div
                                    class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                    >
                                      <a class="ALink_default head_cut head_name">
                                        <span :title="item.uid">{{ item.nickname }}</span>
                                      </a>
                                    </div>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter head-info_info"
                                    >
                                      <a
                                        :title="item.createTime"
                                        class="head-info_time"
                                        >{{ item.createTime }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="keepfly-box-flex">
                                  <button
                                    @click="$router.push('login')"
                                    class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                  >
                                    <span class="keepfly-button-wrap">
                                      <span class="keepfly-button-content">
                                        <i
                                          class="keepfly-font glyphicon glyphicon-plus"
                                        ></i>
                                        {{ $t("message.follow") }}
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </header>
                              <div class="wbpro-feed-content">
                                <div class="detail_text detail_ogText wbpro-feed-ogText">
                                  <n-ellipsis
                                    expand-trigger="click"
                                    line-clamp="4"
                                    :tooltip="false"
                                  >
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentAr"
                                      :title="item.content"
                                      v-html="filters(item.contentAr)"
                                    ></div>
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentEn"
                                      :title="item.content"
                                      v-html="filters(item.contentEn)"
                                    ></div>
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentJa"
                                      :title="item.content"
                                      v-html="filters(item.contentJa)"
                                    ></div>
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentKo"
                                      :title="item.content"
                                      v-html="filters(item.contentKo)"
                                    ></div>
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentRu"
                                      :title="item.content"
                                      v-html="filters(item.contentRu)"
                                    ></div>
                                    <div
                                      class="detail_wbtext"
                                      v-if="item.contentZh"
                                      :title="item.content"
                                      v-html="filters(item.contentZh)"
                                    ></div>
                                  </n-ellipsis>
                                </div>
                                <div class="picture picture-box_row">
                                  <span
                                    v-for="titem in item.tags"
                                    :key="titem"
                                    style="
                                      color: #ff8200;
                                      padding-right: 10px;
                                      font-size: 12px;
                                    "
                                    :title="titem"
                                  >
                                    #{{ uqTxtTranslate(titem) }}
                                  </span>
                                </div>
                                <div class="picture content_row" v-if="item.images != []">
                                  <div
                                    class="u-col-3 keepfly-box-wrap picture_inlineNum3"
                                  >
                                    <n-image-group>
                                      <n-space>
                                        <n-image
                                          width="133"
                                          height="133"
                                          v-for="oitem in item.images"
                                          :key="oitem"
                                          :src="oitem"
                                          style="border-radius: 15px"
                                        />
                                      </n-space>
                                    </n-image-group>
                                  </div>
                                </div>
                                <div v-if="item.videoInfo">
                                  <video
                                    controls="controls"
                                    :src="item.videoInfo"
                                    class="video"
                                    style="
                                      width: 400px;
                                      vertical-align: middle;
                                      height: 225px;
                                      background: #000;
                                      border-radius: 10px;
                                    "
                                  ></video>
                                </div>
                              </div>
                              <footer class="">
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                                >
                                  <div
                                    @click="$router.push('login')"
                                    class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                    >
                                      <div class="keepfly-pop-wrap">
                                        <span class="keepfly-pop-ctrl">
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                          >
                                            <div
                                              class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                            >
                                              <share-outline
                                                class="keepfly-font toolbar_retweetIcon"
                                              ></share-outline>
                                            </div>
                                            <span class="toolbar_num">
                                              {{ item.fnum == null ? 0 : item.fnum }}
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    @click="$router.push('login')"
                                    class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                    >
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                      >
                                        <i
                                          class="keepfly-font glyphicon glyphicon-comment toolbar_commentIcon"
                                        ></i>
                                        <ellipsis-horizontal-circle
                                          class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                        ></ellipsis-horizontal-circle>
                                      </div>
                                      <span class="toolbar_num">
                                        {{ item.cnum == null ? 0 : item.cnum }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                    >
                                      <button
                                        class="keepfly-like-main toolbar_btn"
                                        @click="$router.push('login')"
                                      >
                                        <span class="keepfly-like-iconWrap">
                                          <svg
                                            style="width: 19px"
                                            class="keepfly-like-iconWrap"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 32 32"
                                          >
                                            <path
                                              d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </span>
                                        <span class="keepfly-like-count">{{
                                          item.lnum == null ? 0 : item.lnum
                                        }}</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </article>
                        </div>
                      </div>
                      <div class="vue-recycle-scroller__item-view" v-else>
                        <div
                          class="vue-recycle-scroller__item-view"
                          style="background: #fff; padding: 40px 10px"
                        >
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Main_full" v-show="2 == indexActive">
            <div class="Scroll_container">
              <div class="Scroll_wrap">
                <div>
                  <div
                    class="vue-recycle-scroller ready page-mode direction-vertical"
                    id="scroller"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div v-if="TopicArr.length > 0">
                        <div
                          class="wbpro-scroller-item"
                          v-for="(item, index) in TopicArr"
                          :key="item"
                        >
                          <div
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                          >
                            <div class="HotTopic_item">
                              <div class="keepfly-box-flex">
                                <div class="keepfly-box-item-flex">
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                  >
                                    <div
                                      v-if="index == 0"
                                      class="HotTopic_rank1 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div
                                      v-if="index == 1"
                                      class="HotTopic_rank2 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div
                                      v-if="index == 2"
                                      class="HotTopic_rank3 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div v-if="index > 2" class="HotTopic_rankimg">
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicAr"
                                      v-if="item.topicAr"
                                      >#{{ item.topicAr }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicEn"
                                      v-if="item.topicEn"
                                      >#{{ item.topicEn }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicJa"
                                      v-if="item.topicJa"
                                      >#{{ item.topicJa }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicKo"
                                      v-if="item.topicKo"
                                      >#{{ item.topicKo }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicRu"
                                      v-if="item.topicRu"
                                      >#{{ item.topicRu }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      :title="item.topicZh"
                                      v-if="item.topicZh"
                                      >#{{ item.topicZh }}</a
                                    >
                                    <div></div>
                                  </div>
                                  <div
                                    class="wbpro-cutword HotTopic_margin HotTopic_wbtext"
                                    v-html="item.topicDescription"
                                  ></div>
                                  <div class="HotTopic_num">
                                    <span
                                      >{{ $t("message.read") }}：{{
                                        item.readCount
                                      }}</span
                                    >
                                    <span
                                      >{{ $t("message.join") }}：{{
                                        item.attendCount
                                      }}</span
                                    >
                                  </div>
                                </div>
                                <div
                                  class="keepfly-box-flex HotTopic_right"
                                  v-if="item.topicPic"
                                >
                                  <div class="HotTopic_video">
                                    <div
                                      class="keepfly-picture-main keepfly-picture-square keepfly-picture-hover HotTopic_img"
                                    >
                                      <img
                                        :src="item.topicPic"
                                        class="keepfly-picture-img"
                                      />
                                      <div class="keepfly-picture-cover"></div>
                                      <div class="keepfly-picture-hoverMask"></div>
                                      <div class="keepfly-picture-slot">
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter HotTopic_pos"
                                        >
                                          <i
                                            class="keepfly-font keepfly-font--play HotTopic_icon2"
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vue-recycle-scroller__item-view" v-else>
                        <div
                          class="vue-recycle-scroller__item-view"
                          style="background: #fff; padding: 40px 10px"
                        >
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Main_full" v-show="3 == indexActive">
            <div class="Scroll_container">
              <div class="Scroll_wrap">
                <div>
                  <div
                    class="vue-recycle-scroller ready page-mode direction-vertical"
                    id="scroller"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div v-if="goldenarr.length > 0">
                        <!-- <div
                          class="wbpro-scroller-item"
                          v-for="(item, index) in goldenarr"
                          :key="item"
                        >
                          <div
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                          >
                            <div class="HotTopic_item">
                              <div class="keepfly-box-flex">
                                <div class="keepfly-box-item-flex">
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                  >
                                    <div
                                      v-if="index == 0"
                                      class="HotTopic_rank1 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div
                                      v-if="index == 1"
                                      class="HotTopic_rank2 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div
                                      v-if="index == 2"
                                      class="HotTopic_rank3 HotTopic_rankimg"
                                    >
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <div v-if="index > 2" class="HotTopic_rankimg">
                                      <span class="HotTopic_ranknum">{{
                                        index + 1
                                      }}</span>
                                    </div>
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contentAr"
                                      v-if="item.contentAr"
                                      >{{ item.contentAr }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contentEn"
                                      v-if="item.contentEn"
                                      >{{ item.contentEn }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contenta"
                                      v-if="item.contentJa"
                                      >{{ item.contentJa }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contentKo"
                                      v-if="item.contentKo"
                                      >{{ item.contentKo }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contentRu"
                                      v-if="item.contentRu"
                                      >{{ item.contentRu }}</a
                                    >
                                    <a
                                      class="ALink_default HotTopic_tit"
                                      href=""
                                      :title="item.contentZh"
                                      v-if="item.contentZh"
                                      >{{ item.contentZh }}</a
                                    >
                                    <div>{{ item.lnum == null ? 0 : item.lnum }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div v-for="item in goldenarr" :key="item">
                          <div class="sentence-card-wrap list-item">
                            <div class="content content-limit">
                              <div data-v-65785b50="" class="c-highlight">
                                <div data-v-65785b50="">
                                  <n-ellipsis
                                    expand-trigger="click"
                                    line-clamp="2"
                                    :tooltip="false"
                                  >
                                    <span :title="item.contentAr" v-if="item.contentAr">{{
                                      item.contentAr
                                    }}</span>
                                    <span :title="item.contentEn" v-if="item.contentEn">{{
                                      item.contentEn
                                    }}</span>
                                    <span :title="item.contenta" v-if="item.contentJa">{{
                                      item.contentJa
                                    }}</span>
                                    <span :title="item.contentKo" v-if="item.contentKo">{{
                                      item.contentKo
                                    }}</span>
                                    <span :title="item.contentRu" v-if="item.contentRu">{{
                                      item.contentRu
                                    }}</span>
                                    <span :title="item.contentZh" v-if="item.contentZh">{{
                                      item.contentZh
                                    }}</span>
                                  </n-ellipsis>
                                </div>
                              </div>
                            </div>
                            <div class="tool-wrap">
                              <div class="tool-left">
                                <div class="operate-item">
                                  <div class="operate-item">
                                    <div
                                      @click="$router.push('login')"
                                      class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                      style="width: 2.125rem; height: 2.125rem"
                                    >
                                      <img
                                        :src="item.avatar"
                                        style="vertical-align: middle"
                                        class="keepfly-avatar-img"
                                      />
                                    </div>
                                    {{ item.nickname }}
                                  </div>
                                  <svg
                                    @click="$router.push('login')"
                                    class="tool-icon like"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 22px"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 32 32"
                                  >
                                    <path
                                      d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  <div>{{ item.lnum == null ? 0 : item.lnum }}</div>
                                </div>
                              </div>
                              <div @click="copyCode(item)" class="copy">
                                <!-- {{ $t("message.copy") }} -->
                                <svg
                                  style="width: 19px; padding-top: 6px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 16 16"
                                >
                                  <g fill="none">
                                    <path
                                      d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
                                      fill="currentColor"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vue-recycle-scroller__item-view" v-else>
                        <div
                          class="vue-recycle-scroller__item-view"
                          style="background: #fff; padding: 40px 10px"
                        >
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Main_full" v-show="4 == indexActive">
            <div class="Scroll_container">
              <div class="Scroll_wrap">
                <div>
                  <div
                    class="vue-recycle-scroller ready page-mode direction-vertical"
                    id="scroller"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div v-if="asklistarr.length > 0">
                        <div
                          class="wbpro-scroller-item"
                          v-for="item in asklistarr"
                          :key="item"
                        >
                          <div
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                          >
                            <div class="HotTopic_item">
                              <div class="keepfly-box-flex">
                                <div class="keepfly-box-item-flex">
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                  >
                                    <div class="HotTopic_doticon"></div>
                                    <a
                                      @click="$router.push('login')"
                                      class="ALink_default HotTopic_tit"
                                      :title="item.question"
                                      >{{ item.question }}</a
                                    >
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vue-recycle-scroller__item-view" v-else>
                        <div
                          class="vue-recycle-scroller__item-view"
                          style="background: #fff; padding: 40px 10px"
                        >
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Main_full" v-show="5 == indexActive">
            <div class="SecBar_secBar SecBar_visable">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div>
                  <div class="wbpro-tab2">
                    <div class="keepfly-box-item-inlineBlock cur">
                      <div
                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                      >
                        <div class="text Tab_nowrap">
                          <div class="keepfly-badge-box" style="color: rgb(153, 51, 127)">
                            {{ $t("message.memorial_plaza") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="keepfly-box-item-inlineBlock">
                      <div style="position: absolute; top: 6px; right: 6px">
                        <n-input-group>
                          <input
                            class="search_ss"
                            v-model="searchTxt"
                            :placeholder="$t('message.tips136')"
                          />
                          <button class="search_btn" @click="searchView">
                            <search style="width: 22px; padding-top: 5px"></search>
                            <!-- {{ $t("message.search") }} -->
                          </button>
                        </n-input-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Scroll_container">
              <div class="Scroll_wrap">
                <div>
                  <div
                    class="vue-recycle-scroller ready page-mode direction-vertical"
                    id="scroller"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div v-if="deceasedlistarr.length > 0">
                        <div class="wbpro-scroller-item">
                          <div
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                          >
                            <div class="HotTopic_item">
                              <div class="keepfly-box-flex">
                                <div class="keepfly-box-item-flex">
                                  <div style="float: right">
                                    <span>
                                      <n-button
                                        style="padding: 10px"
                                        icon-placement="right"
                                        @click="setCreateState"
                                        tertiary
                                      >
                                        <template #icon>
                                          <n-icon>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 24 24"
                                            >
                                              <g
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path d="M3 9l4-4l4 4M7 5v14"></path>
                                                <path d="M21 15l-4 4l-4-4m4 4V5"></path>
                                              </g>
                                            </svg>
                                          </n-icon>
                                        </template>
                                        {{ $t("message.creation_time") }}
                                      </n-button>
                                    </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>
                                      <n-button
                                        style="padding: 10px"
                                        icon-placement="right"
                                        @click="setYearState"
                                        tertiary
                                      >
                                        <template #icon>
                                          <n-icon>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              viewBox="0 0 24 24"
                                            >
                                              <g
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path d="M3 9l4-4l4 4M7 5v14"></path>
                                                <path d="M21 15l-4 4l-4-4m4 4V5"></path>
                                              </g>
                                            </svg>
                                          </n-icon>
                                        </template>
                                        {{ $t("message.tips115") }}
                                      </n-button>
                                    </span>
                                  </div>
                                  <div style="clear: both"></div>
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                    v-for="item in deceasedlistarr"
                                    :key="item"
                                    style="float: left; margin-right: 6px; width: 182px"
                                  >
                                    <n-card
                                      :title="item.dname"
                                      size="small"
                                      @click="deadview(item)"
                                      hoverable
                                    >
                                      <template #cover>
                                        <img
                                          :src="item.brand"
                                          style="
                                            height: 160px;
                                            margin: 18px auto 12px;
                                            width: auto;
                                            border-radius: 90px;
                                            border: 8px solid #dae0f8;
                                          "
                                        />
                                      </template>
                                      <div v-if="item.year">
                                        {{ item.constellation
                                        }}<span style="float: right; font-size: 12px">
                                          {{
                                            item.year < 0
                                              ? $t("message.B_C") +
                                                " " +
                                                Math.abs(item.year)
                                              : item.year
                                          }}{{ $t("message.year") }}
                                          {{ item.moon < 10 ? "0" + item.moon : item.moon
                                          }}{{ $t("message.moon") }}
                                          {{ item.day < 10 ? "0" + item.day : item.day
                                          }}{{ $t("message.day") }}
                                        </span>
                                      </div>
                                      <div v-else>
                                        {{ item.constellation
                                        }}<span style="float: right; font-size: 12px">
                                          {{ $t("message.unknown") }}</span
                                        >
                                      </div>
                                    </n-card>
                                    <div></div>
                                  </div>
                                  <div style="clear: both"></div>
                                  <n-pagination
                                    v-model:page="currdeceasedPage"
                                    size="medium"
                                    :page-count="dpages"
                                    :page-slot="7"
                                    @update-page="onDataChange"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vue-recycle-scroller__item-view" v-else>
                        <div
                          class="vue-recycle-scroller__item-view"
                          style="background: #fff; padding: 40px 10px"
                        >
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Main_side" style="height: 892px">
            <div id="__sidebar" class="Main_sideMain">
              <div class="Side_sideBox Side_posSticky">
                <div
                  class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap LoginCard_wrap"
                >
                  <div
                    class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter keepfly-box-justifyCenter LoginCard_con"
                  >
                    <div class="LoginCard_t1"></div>
                    <button
                      @click="$router.push('login')"
                      class="keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round LoginCard_btn_Jp_u1"
                      style="height: 33.6px"
                    >
                      <span class="keepfly-button-wrap">
                        <span class="keepfly-button-content">{{
                          $t("message.sign_in_now")
                        }}</span>
                      </span>
                    </button>
                    <div class="LoginCard_t2">
                      {{ $t("message.register_tis")
                      }}<a href="/#/enroll">{{ $t("message.register_Now") }}！</a>
                    </div>
                  </div>
                </div>
                <div class="wbpro-side-main SideIndex_sideMain">
                  <div>
                    <div>
                      <div
                        class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                      >
                        <div>
                          <div
                            class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                          >
                            <div
                              class="f17 fm cla keepfly-box-item-flex"
                              style="align-self: center"
                            >
                              {{ $t("message.find_it") }}
                            </div>
                            <div
                              class="wbpro-side-tit-angle keepfly-box-flex keepfly-box-alignCenter"
                            >
                              <div
                                class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                              >
                                <chevron-back
                                  @click="reducePages"
                                  class="keepfly-font keepfly-font--angleLeft"
                                  style="width: 16px"
                                ></chevron-back>
                              </div>
                              <span class="f13 cla">{{ current }}/{{ pages }}</span>
                              <div
                                class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                              >
                                <chevron-forward
                                  @click="addPage"
                                  class="keepfly-font keepfly-font--angleRight"
                                  style="width: 16px"
                                ></chevron-forward>
                              </div>
                            </div>
                          </div>
                          <div class="keepfly-divider-main keepfly-divider-x"></div>
                        </div>
                        <div
                          class="wbpro-side-card4 wbpro-side-panel wbpro-side-hover"
                          v-for="item in lookForQList"
                          :key="item"
                        >
                          <a class="ALink_none">
                            <div class="item keepfly-box-flex keepfly-box-alignCenter">
                              <div
                                class="keepfly-avatar-main keepfly-avatar-hover"
                                style="width: 3.125rem; height: 3.125rem"
                              >
                                <img :src="item.frontCover" class="keepfly-avatar-img" />
                                <div class="keepfly-avatar-hoverMask"></div>
                              </div>
                              <div
                                class="con keepfly-box-item-flex"
                                style="align-self: center"
                              >
                                <div class="f16 cla wbpro-textcut">
                                  {{ item.nameOfMissingPerson }}
                                </div>
                                <div class="f13 clb wbpro-textcut">
                                  {{ item.locationOfDisappearance }}
                                </div>
                              </div>
                              <div class="right">
                                <button
                                  @click="chakLookForView(item)"
                                  class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round FollowBtn"
                                >
                                  <span class="keepfly-button-wrap">
                                    <i
                                      class="keepfly-font keepfly-font--add keepfly-button-icon"
                                    ></i>
                                    <span class="keepfly-button-content">
                                      {{ $t("message.tips28") }}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </a>
                          <div class="keepfly-divider-main keepfly-divider-x"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wbpro-side-copy">
                    <div class="wbpro-side-copy-inner">
                      <div class="scale">
                        <div class="CopyRight_item">
                          <div
                            class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                          >
                            <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                            <div>{{ $t("message.help_center") }}</div>
                          </div>
                          <div
                            class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                          >
                            <a
                              @click="$router.push('selfservice')"
                              target="_blank"
                              class="CopyRight_contentItem"
                              style="cursor: pointer"
                            >
                              {{ $t("message.self_service_center") }}
                            </a>
                            <a
                              @click="$router.push('website')"
                              target="_blank"
                              class="CopyRight_contentItem"
                              style="cursor: pointer"
                            >
                              {{ $t("message.website_related") }}
                            </a>
                          </div>
                          <div
                            class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                          >
                            <a target="_blank" class="CopyRight_contentItem">
                              keepfly {{ $t("message.customer") }}
                              {{ kukesystemInfo.serviceNumber }}
                            </a>
                          </div>

                          <div
                            class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                          >
                            <a target="_blank" class="CopyRight_contentItem">
                              {{ $t("message.tips107") }}
                              {{ kukesystemInfo.serviceEmail }}
                            </a>
                          </div>
                        </div>
                        <div class="CopyRight_item">
                          <div
                            class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                          >
                            <i
                              class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                            ></i>
                            <div>{{ $t("message.tips27") }}</div>
                          </div>
                          <div
                            class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                          >
                            <span class="CopyRight_contentItem"
                              >{{ $t("message.cooperation_hotline") }}
                              {{ kukesystemInfo.hotLine }}</span
                            >
                            <div
                              class="link keepfly-pop-wrap"
                              @click="$router.push('document')"
                              style="cursor: pointer"
                            >
                              <span class="keepfly-pop-ctrl">
                                <span class="CopyRight_contentItem">
                                  {{ $t("message.more") }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          class="CopyRight_item"
                          style="margin-top: 46px; text-align: center; color: darkgray"
                        >
                          <p>{{ $t("message.tips108") }}</p>
                          <p>{{ $t("message.tips101") }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <n-modal
      v-model:show="showModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.find_it')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <div>
        <n-thing>
          <template #avatar>
            <n-image style="width: 100px" :src="lookForInfo.frontCover" />
          </template>
          <template #header>
            {{ $t("message.name_of_missing_person") }}:
            {{ lookForInfo.nameOfMissingPerson }}
          </template>
          <template #description>
            {{ $t("message.debatable_time") }}: {{ lookForInfo.debatableTime }}
            {{ $t("message.gender") }}:
            {{ lookForInfo.gender == 1 ? $t("message.man") : $t("message.woman") }}<br />
            {{ $t("message.location_of_disappearance") }}:
            {{ lookForInfo.locationOfDisappearance }} {{ $t("message.age") }}:
            {{ lookForInfo.age }}
          </template>
          {{ $t("message.contact_number") }}: {{ lookForInfo.contactNumber }}<br />
          {{ $t("message.feature_description") }}:
          <div v-if="lookForInfo.descAr">{{ lookForInfo.descAr }}</div>
          <div v-if="lookForInfo.descZh">{{ lookForInfo.descZh }}</div>
          <div v-if="lookForInfo.descKo">{{ lookForInfo.descKo }}</div>
          <div v-if="lookForInfo.descJa">{{ lookForInfo.descJa }}</div>
          <div v-if="lookForInfo.descRu">{{ lookForInfo.descRu }}</div>
          <div v-if="lookForInfo.descEn">{{ lookForInfo.descEn }}</div>
          <n-collapse>
            <template #arrow></template>
            <n-collapse-item :title="$t('message.view_the_original_article')" name="1">
              <div style="padding-left: 6px">{{ lookForInfo.featureDescription }}</div>
            </n-collapse-item>
          </n-collapse>
          <template #footer>
            <n-input
              v-model:value="lookForInfofrom.content"
              type="textarea"
              status="warning"
              :placeholder="$t('message.message')"
            /><br /><br />
            <n-button
              style="width: 120px"
              @click="sendlookFor(lookForInfo.id)"
              type="warning"
            >
              <!-- {{ $t("message.send") }} -->
              <svg
                style="width: 22px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                enable-background="new 0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill="#fff"
                    d="M435.9,64.9L68.8,224.9c-6.5,3.1-6.3,12.4,0.3,15.3l99.3,56.1c5.9,3.3,13.2,2.6,18.3-1.8l195.8-168.8
		c1.3-1.1,4.4-3.2,5.6-2c1.3,1.3-0.7,4.3-1.8,5.6L216.9,320.1c-4.7,5.3-5.4,13.1-1.6,19.1l64.9,104.1c3.2,6.3,12.3,6.2,15.2-0.2
		L447.2,76C450.5,68.8,443,61.5,435.9,64.9z"
                  ></path>
                </g>
              </svg>
            </n-button>
          </template>
        </n-thing>
      </div>
      <template #footer>
        <img
          v-show="lookForInfo.photograph"
          :src="lookForInfo.photograph"
          style="width: 100%"
        />
      </template>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import i18n from "../i18n/index";
import {
  NIcon,
  NSelect,
  NImageGroup,
  NSpace,
  NImage,
  NModal,
  NThing,
  NButton,
  NCollapse,
  NCollapseItem,
  NInput,
  useMessage,
  NEmpty,
  NCard,
  NDropdown,
  NPagination,
  NInputGroup,
  NEllipsis,
} from "naive-ui";
import {
  SearchOutline,
  FlameSharp,
  SparklesSharp,
  HelpCircleOutline,
  MenuSharp,
  ChevronBack,
  ChevronForward,
  ShareOutline,
  EllipsisHorizontalCircle,
  HomeOutline,
  Search,
} from "@vicons/ionicons5";
import API from "../uilts/request";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "IndexView",
  components: {
    SearchOutline,
    MenuSharp,
    ShareOutline,
    EllipsisHorizontalCircle,
    FlameSharp,
    NModal,
    NInput,
    NThing,
    NButton,
    SparklesSharp,
    NCollapse,
    NCollapseItem,
    HelpCircleOutline,
    NEmpty,
    NIcon,
    ChevronBack,
    ChevronForward,
    NSelect,
    NImageGroup,
    NSpace,
    NImage,
    NCard,
    HomeOutline,
    NDropdown,
    NPagination,
    NInputGroup,
    NEllipsis,
    Search,
  },
  setup() {
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    const message = useMessage();
    const router = useRouter();
    const { locale } = useI18n();
    const arr = ref([]);
    const microblogs = ref([]);
    const nav_show = ref(false);
    const head_show = ref(true);
    const SecBar_secBar = ref(false);
    const flat = ref(true);
    const content_height = ref(document.body.scrollHeight);
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", value);
      location.reload();
    };
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const tagArr = ref([]);
    const tagmoreoptions = ref([]);
    const getKukeTag = () => {
      let param = {
        language: localStorage.getItem("language"),
      };
      API({
        url: "/kuke/api/index/getKukeTag",
        method: "GET",
        params: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data));
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].nameJa) {
            tagmoreoptions.value.push({
              label: arr[i].nameJa,
              key: arr[i].nameJa,
            });
          }
          if (arr[i].nameRu) {
            tagmoreoptions.value.push({
              label: arr[i].nameRu,
              key: arr[i].nameRu,
            });
          }
          if (arr[i].nameAr) {
            tagmoreoptions.value.push({
              label: arr[i].nameAr,
              key: arr[i].nameAr,
            });
          }
          if (arr[i].nameEn) {
            tagmoreoptions.value.push({
              label: arr[i].nameEn,
              key: arr[i].nameEn,
            });
          }
          if (arr[i].nameKo) {
            tagmoreoptions.value.push({
              label: arr[i].nameKo,
              key: arr[i].nameKo,
            });
          }
          if (arr[i].nameZh) {
            tagmoreoptions.value.push({
              label: arr[i].nameZh,
              key: arr[i].nameZh,
            });
          }
        }
        tagArr.value = arr;
      });
    };
    const currPage = ref(1);
    const pageSize = ref(10);
    const mpages = ref(0);
    const getmicrobloglist = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
        tagtxt: e,
      };
      API({
        url: "/kuke/api/index/getmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          microblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mpages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };

    onMounted(() => {
      getKukeLanguage();
      getkukesystemInfo();
      getmicrobloglist("");
      getKukeTag();
      getLookFor();
      getpropagandaInfo();
      window.addEventListener("scroll", handleScroll);
      if (!localStorage.getItem("language")) {
        var lang = navigator.language;
        if (lang == "zh-CN") {
          localStorage.setItem("language", "zh_CN");
        } else {
          localStorage.setItem("language", "en");
        }
      }
    });
    let langlocal = localStorage.getItem("language");
    const txtstr = ref("");
    const getpropagandaInfo = () => {
      let param = {
        language: localStorage.getItem("language"),
      };
      API({
        url: "/kuke/api/index/getpropagandaInfo",
        method: "GET",
        params: param,
      }).then((result) => {
        txtstr.value = result.data.data;
      });
    };
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const oldScrollTop = ref(0);
    const flat0 = ref(true);
    const handleScroll = () => {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (flat.value) {
        if (scrollTop > 330) {
          nav_show.value = true;
        } else {
          nav_show.value = false;
        }
      } else {
        nav_show.value = true;
      }
      let scrollStep = scrollTop - oldScrollTop.value;
      oldScrollTop.value = scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.body.scrollHeight;
      let scrollTopo = document.documentElement.scrollTop;
      let distance = 30;
      if (scrollStep > 0) {
        SecBar_secBar.value = false;
        content_height.value = scrollHeight - distance - 330;
      } else {
        SecBar_secBar.value = true;
      }

      if (Math.floor(scrollTopo + clientHeight) >= scrollHeight - distance) {
        if (flat0.value) {
          flat0.value = false;
          if (indexActive.value == 1) {
            if (currPage.value < mpages.value) {
              ++currPage.value;
              getmicrobloglist(microblogkeyword.value);
            }
          } else if (indexActive.value == 2) {
            if (currTopicPage.value < tpages.value) {
              ++currTopicPage.value;
              getTopicData();
            }
          } else if (indexActive.value == 3) {
            if (currgoldenPage.value < gpages.value) {
              ++currgoldenPage.value;
              getgoldenrankinglist();
            }
          } else if (indexActive.value == 4) {
            if (curraskPage.value < apages.value) {
              ++curraskPage.value;
              getasklist();
            }
          } else if (indexActive.value == 5) {
            if (curraskPage.value < dpages.value) {
              ++curraskPage.value;
              getdeceasedlist();
            }
          }
        }
      }
    };
    const indexActive = ref(1);

    const changeNav = (value) => {
      if (value == 2) {
        getTopicData();
      } else if (value == 3) {
        getgoldenrankinglist();
      } else if (value == 4) {
        getasklist();
      } else if (value == 5) {
        getdeceasedlist();
      }
      indexActive.value = value;
      nav_show.value = true;
      flat.value = false;
      head_show.value = false;
      window.scrollTo(0, 0);
    };
    const TopicArr = ref([]);
    const currTopicPage = ref(1);
    const pageTopicSize = ref(10);
    const tpages = ref(0);
    const getTopicData = () => {
      let param = {
        currPage: currTopicPage.value,
        pageSize: pageTopicSize.value,
        language: localStorage.getItem("language") || "en",
      };
      API({
        url: "/kuke/api/index/getTopicList",
        method: "POST",
        data: param,
      }).then((result) => {
        TopicArr.value = JSON.parse(JSON.stringify(result.data.data.records));
        tpages.value = result.data.data.pages;
        flat0.value = true;
      });
    };
    const selectThemeOverrides = {
      Select: {
        peers: {
          InternalSelection: {
            textColor: "#FF0000",
          },
        },
      },
    };

    const currLookForPage = ref(1);
    const pageLookForSize = ref(5);
    const lookForQList = ref([]);
    const current = ref(0);
    const pages = ref(0);
    const getLookFor = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currLookForPage.value,
        pageSize: pageLookForSize.value,
      };
      API({
        url: "/kuke/api/index/getlookforlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        current.value = result.data.data.current;
        pages.value = result.data.data.pages;
        lookForQList.value = arr;
      });
    };
    const lookForInfo = ref({});
    const chakLookForView = (e) => {
      lookForInfo.value = e;
      showModal.value = true;
    };
    const showModal = ref(false);
    const lookForInfofrom = ref({
      content: "",
      lId: "",
    });
    const sendlookFor = (e) => {
      lookForInfofrom.value.lId = e;
      API({
        url: "/kuke/api/index/sendlookFor",
        method: "POST",
        data: lookForInfofrom.value,
      }).then((result) => {
        if (result.data.code == 200) {
          lookForInfofrom.value.content = "";
          lookForInfofrom.value.lId = "";
          showModal.value = false;
          message.success(i18n.global.t("message.tips164"));
        } else {
          message.error(i18n.global.t("message.tips165"));
        }
      });
    };
    const addPage = () => {
      if (currLookForPage.value == pages.value) {
        return false;
      }
      currLookForPage.value = currLookForPage.value + 1;
      getLookFor();
    };
    const reducePages = () => {
      if (currLookForPage.value == 1) {
        return false;
      }
      currLookForPage.value = currLookForPage.value - 1;
      getLookFor();
    };
    const tagnum = ref(0);
    const microblogkeyword = ref("");
    const setTagLable = (e, index) => {
      tagnum.value = index;
      microblogs.value = [];
      gettagmicrobloglist(e, index);
    };
    const setTag = (e, index) => {
      tagnum.value = index;
      microblogkeyword.value = e;
      microblogs.value = [];
      if (index == 0) {
        getmicrobloglist("");
      } else {
        getmicrobloglist(e);
      }
    };
    const gettagmicrobloglist = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
        tagtxt: e,
      };
      API({
        url: "/kuke/api/index/gettagmicroblogindexlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          microblogs.value.push(arr[i]);
          qrr.push(arr[i].id);
        }
        mpages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };
    const tagmorehandleSelect = (key) => {
      setTag(key, -1);
    };
    const currgoldenPage = ref(1);
    const pagegoldenSize = ref(10);
    const goldenarr = ref([]);
    const gpages = ref(0);
    const getgoldenrankinglist = () => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currgoldenPage.value,
        pageSize: pagegoldenSize.value,
      };
      API({
        url: "/kuke/api/index/getgoldenrankinglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        goldenarr.value = arr;
        gpages.value = result.data.data.pages;
        flat0.value = true;
      });
    };
    const curraskPage = ref(1);
    const pageaskSize = ref(10);
    const asklistarr = ref([]);
    const asktotal = ref(0);
    const apages = ref(0);
    const getasklist = () => {
      let param = {
        currPage: curraskPage.value,
        pageSize: pageaskSize.value,
      };
      API({
        url: "/kuke/api/index/getasklist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        asklistarr.value = arr;
        asktotal.value = result.data.data.total;
        apages.value = result.data.data.pages;
        flat0.value = true;
      });
    };
    const currdeceasedPage = ref(1);
    const pagedeceasedSize = ref(6);
    const deceasedlistarr = ref([]);
    const dpages = ref(0);
    const getdeceasedlist = () => {
      let param = {
        currPage: currdeceasedPage.value,
        pageSize: pagedeceasedSize.value,
        createStateShow: createStateShow.value ? 1 : 0,
        yearStateShow: yearStateShow.value ? 1 : 0,
        fflat: fflat.value,
        txt: searchTxt.value,
      };
      API({
        url: "/kuke/api/index/getindexdeceasedlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        deceasedlistarr.value = arr;
        dpages.value = result.data.data.pages;
        flat0.value = true;
      });
    };
    function onDataChange(val) {
      currdeceasedPage.value = val;
      getdeceasedlist();
    }
    const fflat = ref(1);
    const createStateShow = ref(false);
    const setCreateState = () => {
      fflat.value = 1;
      createStateShow.value = !createStateShow.value;
      getdeceasedlist();
    };
    const yearStateShow = ref(false);
    const setYearState = () => {
      fflat.value = 2;
      yearStateShow.value = !yearStateShow.value;
      getdeceasedlist();
    };
    const deadview = (e) => {
      router.push({
        name: "DeadView",
        params: {
          id: e.id,
        },
      });
    };
    const deceased_square_view = () => {
      indexActive.value = 5;
      getdeceasedlist();
      window.scrollTo(0, 0);
    };
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const topicTranslateList = ref([]);
    const setMicroblogView = (e) => {
      if (e.length == 0) {
        return false;
      }
      let params = {
        language: localStorage.getItem("language"),
        tags: e,
      };
      API({
        url: "/kuke/api/index/setMicroblogView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        let arr = result.data.data;
        for (let i = 0; i < arr.length; i++) {
          topicTranslateList.value.push(arr[i]);
        }
      });
    };
    const uqTxtTranslate = (e) => {
      let orr = topicTranslateList.value;
      for (let i = 0; i < orr.length; i++) {
        if (orr[i].topicTitle == e) {
          return orr[i].topicTranslate;
        }
      }
    };
    const kukesystemInfo = ref({});
    const getkukesystemInfo = () => {
      let params = {
        language: localStorage.getItem("language"),
      };
      API({
        url: "/kuke/api/index/getkukesystemInfo",
        method: "POST",
        data: params,
      }).then((result) => {
        kukesystemInfo.value = JSON.parse(JSON.stringify(result.data.data));
        window.localStorage.setItem("kukesystemInfo", JSON.stringify(result.data.data));
      });
    };
    const searchTxt = ref("");
    const searchView = () => {
      currdeceasedPage.value = 1;
      getdeceasedlist();
    };
    function copyCode(codecon) {
      let domInput = document.createElement("input");
      if (codecon.contentAr) {
        domInput.value = codecon.contentAr;
      } else if (codecon.contentEn) {
        domInput.value = codecon.contentEn;
      } else if (codecon.contentJa) {
        domInput.value = codecon.contentJa;
      } else if (codecon.contentKo) {
        domInput.value = codecon.contentKo;
      } else if (codecon.contentRu) {
        domInput.value = codecon.contentRu;
      } else if (codecon.contentZh) {
        domInput.value = codecon.contentZh;
      }
      document.body.appendChild(domInput); // 添加input节点
      domInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      message.success(i18n.global.t("message.tips166"));
      domInput.remove();
    }
    return {
      setTagLable,
      copyCode,
      searchView,
      searchTxt,
      dpages,
      onDataChange,
      currdeceasedPage,
      setYearState,
      yearStateShow,
      setCreateState,
      createStateShow,
      tagmorehandleSelect,
      tagmoreoptions,
      kukesystemInfo,
      uqTxtTranslate,
      getkukesystemInfo,
      filters,
      txtstr,
      deceased_square_view,
      deadview,
      deceasedlistarr,
      getdeceasedlist,
      asktotal,
      getasklist,
      asklistarr,
      getgoldenrankinglist,
      goldenarr,
      tagnum,
      setTag,
      addPage,
      reducePages,
      pages,
      current,
      sendlookFor,
      lookForInfofrom,
      lookForInfo,
      showModal,
      chakLookForView,
      lookForQList,
      getLookFor,
      TopicArr,
      tagArr,
      getKukeTag,
      nav_show: nav_show,
      head_show,
      SecBar_secBar,
      indexActive,
      flat,
      content_height,
      selectedValue: ref(langlocal),
      handleSearch: (value) => {
        changeLang(value);
      },
      options: arr,
      microblogs,
      changeNav,
      handleScroll,
      selectThemeOverrides,
      bodyStyle: {
        width: "600px",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
<style scoped>
.n-layout {
  background: #f5f5f5;
  overflow-x: none;
}

.n-layout .n-layout-scroll-container {
  overflow-x: none;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.keepfly_head {
  position: relative;
  width: 100%;
  height: 330px;
  margin-bottom: 8px;
  background: #f5f5f5;
}

.HomeTopNav_video {
  height: 330px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  z-index: 0;
}

.keepfly-box-flex {
  display: flex;
}

.keepfly-box-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.HomeTopNav_logo {
  width: 92px;
  height: 90px;
  z-index: 1;
}

.Logo_main {
  height: 100%;
  vertical-align: top;
}

.keepfly-box-justifyCenter {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.keepfly-box-alignCenter {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.HomeTopNav_wrap {
  height: 100%;
}

.HomeTopNav_searchbox {
  position: relative;
  margin: 38px 0 0;
  z-index: 1;
}

.HomeTopNav_videoc {
  width: 100%;
  overflow: hidden;
  height: 330px;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.HomeTopNav_search {
  position: relative;
  width: 704px;
  height: 48px;
  border-radius: 24px;
  overflow: hidden;
}

.keepfly-input-wrap {
  background-color: #f0f1f4;
  border: 1px solid #f0f1f4;
  border-radius: 0.5em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  padding: 0 0.75em;
  position: relative;
}

.keepfly-input-hasIcon {
  padding-left: 2.25em;
}

.HomeTopNav_ipt {
  width: 100%;
  height: 100%;
  padding-left: 54px;
  padding-right: 96px;
  border-radius: inherit;
  color: #838383;
}

.HomeTopNav_ipt .keepfly-input-icon {
  line-height: 0;
  font-size: 22px;
}

.keepfly-input-main {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  font-size: 0.675em;
  height: 2.375em;
  outline: none;
  padding: 0;
  width: 100%;
}

.HomeTopNav_ipt .keepfly-input-main {
  height: 100%;
  font-size: 16px;
}

.HomeTopNav_btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 100%;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  border: 0;
}

.HomeTopNav_btn:hover {
  background: orangered;
}

.keepfly-button-m {
  font-size: 0.875rem;
}

.keepfly-input-icon {
  font-size: 1em;
  left: 0.75em;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header_nav {
  width: 100%;
  background: white;
  border-top: 2px solid #ff8200;
  padding: 10px 46px;
}

.header_lists {
  height: 60px;
  margin: 0 auto;
  padding-right: 30px;
}

.header_nav .header_lists li {
  float: right;
  margin: 15px;
  font: 18px/30px;
}

.header_nav .header_lists li a {
  color: black;
}

.header_nav .header_lists li a:hover {
  color: red;
}

.clear-fix::after {
  content: "";
  width: 0;
  height: 0;
}

.Nav_logoWrap {
  position: relative;
  width: 46px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}

.Nav_left {
  width: 22%;
  margin-right: 8px;
}

.Nav_mid {
  position: relative;
  width: 78%;
  height: 100%;
}

.Nav_right {
  width: 282px;
  margin-left: 18px;
  z-index: 1;
}

.loginBtn {
  background: #ff8200;
  color: #fff;
}

.LoginBtn_btna {
  background: #ff8200;
  border-radius: 15px;
  color: #fff;
}

.LoginBtn_btn {
  display: block;
  width: 60px;
  height: 30px;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.registerBtn {
  color: #939393;
}

.LoginBtn_btnb {
  color: #939393;
}

.SearchBar_main {
  width: 100%;
  height: 34px;
}

.keepfly-pop-wrap {
  display: inline-block;
  position: relative;
}

.SearchBar_main .keepfly-input-hasIcon {
  padding-left: 33px;
}

.SearchBar_main .keepfly-input-icon {
  left: 8px;
}

.SearchBar_main input {
  height: 100%;
}

.keepfly-input-round {
  border-radius: 6.25rem;
}

.fix {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

.Frame_content {
  max-width: 1122px;
  margin: auto;
}

.Frame_side {
  width: 182px;
  margin-right: 8px;
}

.Nav_main {
  position: sticky;
  top: 88px;
}

.Nav_inner {
  overflow-y: auto;
  border-radius: 4px 4px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  padding: 0 4px 40px;
}

.keepfly-panel-left {
  border-left: 1px solid #f9f9f9;
}

.keepfly-panel-right {
  border-right: 1px solid #f9f9f9;
}

.keepfly-panel-top {
  border-top: 1px solid #f9f9f9;
}

.keepfly-panel-main {
  background-color: #fff;
}

.Nav_title {
  margin: 0;
  padding: 10px 16px;
  line-height: 26px;
  font-size: 22px;
  font-weight: 500;
}

.NavItem_cur,
.NavItem_cur .NavItem_icon {
  color: #ff8200 !important;
}

.NavItem_main {
  position: relative;
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #333;
}

.Nav_configs {
  width: 134px;
}

.keepfly-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  line-height: 1;
}

.NavItem_icon {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  overflow: hidden;
  font-size: 21px;
  color: #838383;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.NavItem_text {
  line-height: 26px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Main_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Main_full {
  width: 100%;
}

.SecBar_visable {
  opacity: 1;
}

.SecBar_secBar {
  position: sticky;
  z-index: 89;
  top: 81px;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.Card_bottomGap {
  margin-bottom: 8px;
}

.Card_wrap {
  border-color: #f2f2f2;
  border-radius: 4px;
}

.keepfly-panel-bottom {
  border-bottom: 1px solid #f9f9f9;
}

.wbpro-tab2 .keepfly-box-item-inlineBlock {
  vertical-align: top;
}

.keepfly-box-item-inlineBlock {
  display: inline-block;
  font-size: 1rem;
}

.u-col-8 [class*="box-item"],
.u-col-8 [data-type="w-box-item"] {
  width: 11.5%;
}

.wbpro-tab2 .cur .wbpro-textcut {
  font-weight: 700;
  color: #ff8200;
}

.wbpro-tab2 .wbpro-textcut {
  width: 100%;
  margin: 0 4px;
  padding: 5px;
  border-radius: 15px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.wbpro-cutword,
.wbpro-textcut {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wbpro-textcut {
  white-space: nowrap;
}

.wbpro-tab2 .keepfly-box-flex {
  height: 44px;
}

.wbpro-tab2 .wbpro-textcut:hover {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
}

.wbpro-tab2 .keepfly-font--more {
  cursor: pointer;
  font-size: 12px;
  color: #838383;
}

.Scroll_container {
  max-width: 100%;
  height: 100%;
}

.Scroll_wrap {
  position: relative;
  height: 100%;
}

.vue-recycle-scroller {
  position: relative;
}

.vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
  width: 100%;
  min-height: 486px;
}

.vue-recycle-scroller__item-wrapper {
  overflow: visible;
  overflow: initial;
}

.vue-recycle-scroller__item-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.vue-recycle-scroller.ready.direction-vertical .vue-recycle-scroller__item-view {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.vue-recycle-scroller.ready.direction-vertical .vue-recycle-scroller__item-view {
  width: 100%;
}

.vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
  position: absolute;
  top: 0;
  left: 0;
}

.wbpro-scroller-item {
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Feed_normal {
  margin-bottom: 8px;
}

.Feed_wrap {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  border-color: #f2f2f2;
}

.Feed_body {
  padding: 20px 20px 0;
}

.ALink_default,
.ALink_default:active,
.ALink_default:link,
.ALink_default:visited {
  color: inherit;
  text-decoration: none;
}

.head_avatar {
  margin-right: 10px;
  cursor: pointer;
}

.keepfly-avatar-main {
  border-radius: 50%;
  display: inline-block;
  font-size: 1rem;
  height: 2.5em;
  position: relative;
  width: 2.5em;
}

.keepfly-avatar-img {
  vertical-align: top;
}

.keepfly-avatar-hoverMask,
.keepfly-avatar-img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.head_main {
  min-width: 0;
  margin-right: 10px;
  margin-bottom: -4px;
}

.head_content_wrap {
  height: 100%;
}

.head_nick,
.head_suffixbox {
  font-size: 14px;
}

.head_nick {
  color: #333;
  line-height: 20px;
  margin-bottom: 7px;
  font-weight: bolder;
}

.head_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.IconVip_icon {
  margin: 0 4px 0;
  font-size: 16px;
  cursor: pointer;
}

.keepfly-icon-wrap {
  display: inline-flex;
  line-height: 1;
}

.IconVip_icon .keepfly-icon-main {
  width: auto !important;
}

.IconVip_icon img {
  width: auto !important;
  height: 100% !important;
  vertical-align: top !important;
}

.keepfly-icon-vipimg {
  height: 100%;
  width: auto;
}

.keepfly-icon-main {
  display: inline-block;
  height: 0.875em;
  position: relative;
  vertical-align: top;
  width: 0.875em;
}

.head-info_info {
  color: #939393;
  font-size: 12px;
  line-height: 12px;
}

.head-info_info a {
  color: #939393;
  text-decoration: none;
}

.head-info_time {
  margin-right: 5px;
  min-width: 0;
}

.head-info_from {
  min-width: 0;
  -ms-flex-item-align: center;
  align-self: center;
}

.head-info_cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.keepfly-button-line.keepfly-button-primary {
  color: #ff8200;
}

.keepfly-button-main.keepfly-button-round,
.keepfly-button-main.keepfly-button-round .keepfly-button-inset {
  border-radius: 2.25rem;
}

.follow-btn_followbtn {
  width: 64px;
  height: 28px;
  padding: 0 !important;
}

.keepfly-button-s {
  font-weight: 400;
}

.keepfly-button-s {
  font-size: 0.75rem;
  padding: 0.5rem 0.875rem;
}

.keepfly-button-line {
  border: 1px solid #ff8200 !important;
}

.keepfly-button-main {
  background-color: transparent;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 600;
  line-height: 1;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.keepfly-button-wrap {
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: inline-flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
  position: relative;
  vertical-align: top;
  z-index: 9;
}

.keepfly-button-content {
  display: inline-flex;
  vertical-align: bottom;
}

.wbpro-feed-content {
  padding-left: 60px;
  margin-top: 6px;
  overflow: hidden;
}

.keepfly-box-item-flex {
  align-self: stretch;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: stretch;
}

.detail_ogText {
  color: #333;
  font-size: 14px;
  line-height: 24px;
}

/* .detail_wbtext {
  padding-top: 10px;
} */

.detail_text a {
  color: #eb7340;
}

/* .content_row {
  margin-top: 10px;
} */

.picture_inlineNum3 {
  width: 84.6%;
}

.keepfly-box-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.u-col-3 [class*="box-item"],
.u-col-3 [data-type="w-box-item"] {
  width: 32.33333%;
}

.picture_cursor {
  cursor: zoom-in;
}

.picture_item {
  position: relative;
}

.keepfly-box-item-flex,
.keepfly-box-item-inlineBlock {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.picture_pic {
  vertical-align: top;
  overflow: hidden;
  border-radius: 8px;
}

.keepfly-picture-main {
  display: inline-block;
  position: relative;
  width: 100%;
}

.keepfly-picture-hoverMask,
.keepfly-picture-img {
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.keepfly-picture-hoverMask {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
}

.keepfly-picture-slot {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture_focusImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.content_picLoading {
  width: 100%;
  height: 100%;
}

.keepfly-box-item-flex,
.keepfly-box-item-inlineBlock {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.keepfly-picture-square:before {
  padding-bottom: 100%;
}

.keepfly-picture-main:before {
  content: "";
  display: block;
  width: 100%;
}

.toolbar_main {
  height: 40px;
  line-height: 40px;
  margin: 0 15px;
}

.toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toolbar_cursor {
  cursor: pointer;
}

.toolbar_wrap {
  margin-left: -3px;
  height: 100%;
}

.keepfly-pop-wrap {
  display: inline-block;
  position: relative;
}

.toolbar_left .toolbar_btn,
.toolbar_left .toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toolbar_iconWrap {
  width: 24px;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

.toolbar_num {
  font-size: 12px;
  line-height: 12px;
}

.toolbar_retweetIcon,
.toolbar_commentIcon {
  font-size: 13px;
}

.toolbar_likebox {
  height: 100%;
}

.toolbar_likebox .toolbar_btn {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.toolbar_left .toolbar_btn,
.toolbar_left .toolbar_item {
  text-align: center;
  padding: 0;
  font-size: 16px;
  color: grey;
  font-weight: 400;
  height: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.keepfly-like-main {
  background: none;
  border: 0;
  color: currentColor;
  cursor: pointer;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  font-size: 1rem;
  height: 100%;
  line-height: 1;
  outline: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.keepfly-like-icon {
  height: 0.9375em;
  vertical-align: bottom;
  width: 0.9375em;
}

.keepfly-like-count {
  font-size: 0.75em;
  margin-left: 0.625em;
  vertical-align: bottom;
}

.Main_side {
  width: 282px;
  margin-left: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.Main_sideMain {
  height: 721px;
}

.Side_posSticky {
  position: sticky;
  z-index: 99;
}

.Side_sideBox {
  width: 282px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.LoginCard_wrap {
  height: 184px;
  background-size: 100%;
}

.LoginCard_con {
  height: 100%;
}

.LoginCard_t1 {
  width: 140px;
  height: 63px;
  margin: 6px 0 0;
}

.keepfly-button-flat.keepfly-button-primary {
  background: #ff8200;
  color: #fff;
}

.keepfly-button-main.keepfly-button-round,
.keepfly-button-main.keepfly-button-round .keepfly-button-inset {
  border-radius: 2.25rem;
}

.LoginCard_btn_Jp_u1 {
  width: 160px;
  margin: 18px 0 12px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(255, 130, 0, 0.4);
  box-shadow: 0 2px 10px 0 rgba(255, 130, 0, 0.4);
}

.LoginCard_t2 {
  line-height: 20px;
  font-size: 14px;
  color: #939393;
}

.LoginCard_t2 a {
  color: #ff8200;
  text-decoration: none;
}

.wbpro-side-main > div:last-child:not(.wbpro-side-main) .wbpro-side:last-child {
  margin-bottom: 0;
}

.wbpro-side-tit {
  height: 40px;
  padding: 0 18px;
}

.wbpro-side .cla {
  color: #333;
}

.wbpro-side .f14 {
  width: 17px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}

.wbpro-side-tit .keepfly-font + span,
.wbpro-side-tit span + .keepfly-font {
  margin-left: 6px;
}

.wbpro-side .clb {
  color: #939393;
}

.wbpro-side .f12 {
  font-size: 12px;
  line-height: 16px;
}

.Card_wrap_2ibWe .keepfly-divider-main {
  color: #f9f9f9;
}

.keepfly-divider-x {
  border-bottom: 1px solid;
}

.keepfly-divider-main {
  color: #f9f9f9;
}

.wbpro-side-panel {
  padding: 0 18px;
}

.wbpro-side-card7 .con {
  position: relative;
  height: 40px;
  padding: 0 30px 0 24px;
  overflow: hidden;
  white-space: nowrap;
}

.wbpro-side-card7 .top {
  font-size: 12px;
  color: #f26d5f;
}

.wbpro-side-card7 .rank {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-size: 15px;
  color: #ff8200;
}

.wbpro-side-card7 .icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin: -10px 0 0;
}

.wbpro-side-card7 .wbpro-textcut {
  margin-right: 10px;
}

.wbpro-side-card7 [class^="wbpro-icon-search-"] {
  margin-right: -2px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

[class^="wbpro-icon-search-2"],
[class^="wbpro-icon-search-"] {
  display: inline-block;
  height: 20px;
  border-radius: 4px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

[class^="wbpro-icon-search-"] {
  width: 20px;
}

.keepfly-button-simple.keepfly-button-default {
  color: currentColor;
}

.keepfly-button-main.keepfly-button-fluid {
  width: 100%;
}

.wbpro-side-copy,
.wbpro-side-copy-inner {
  position: relative;
  z-index: 999;
}

.wbpro-side-copy {
  width: 100%;
  height: 242px;
  padding-top: 18px;
}

.wbpro-side-copy-inner {
  height: 100%;
}

.wbpro-side-copy,
.wbpro-side-copy-inner {
  position: relative;
  z-index: 999;
}

.wbpro-side-copy .scale {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  width: 330px;
  margin: 0 0 0 -165px;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  font-size: 12px;
}

.CopyRight_item {
  margin-left: 20px;
  margin-top: 16px;
}

.CopyRight_title {
  font-size: 11px;
  line-height: 18px;
  color: #606060;
}

.CopyRight_icon {
  position: absolute;
  left: 0;
  font-size: 14px;
}

.CopyRight_content {
  margin-top: 3px;
  font-size: 10px;
  color: #939393;
  line-height: 18px;
}

.keepfly-box-justifyBetween {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wbpro-side-copy a {
  color: #939393;
  text-decoration: none;
}

.CopyRight_contentItem {
  margin-left: 12px;
}

.CopyRight_cricon {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  white-space: nowrap;
}

.CopyRight_aria {
  height: 36px;
  margin-left: 15px;
  border: 1px solid #939393;
}

.LoginBtn_btn:hover,
.LoginBtn_btnb:hover {
  text-decoration: none;
  /* color: #000; */
}

.ALink_default:hover {
  display: block;
  background-color: #f2f2f2;
}

.Scroll_container {
  max-width: 100%;
  height: 100%;
}

.Scroll_wrap {
  position: relative;
  height: 100%;
}

.wbpro-scroller-item {
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.HotTopic_wrap {
  width: 100%;
  cursor: pointer;
}

.HotTopic_item {
  padding: 12px 10px;
  line-height: 1;
  font-size: 14px;
  color: #333;
}

.HotTopic_titout {
  margin: 10px 0 0;
}

.HotTopic_ranknum {
  position: absolute;
  bottom: 2px;
  left: 50%;
  -webkit-transform: translate(-50%) skewX(-10deg);
  transform: translate(-50%) skewX(-10deg);
}

.HotTopic_tit {
  margin: 0 6px 0 8px;
  height: 25px;
  line-height: 25px;
  font-weight: 700;
  font-size: 14px;
  width: 496px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HotTopic_wbtext {
  font-size: 14px;
  line-height: 20px;
  -webkit-line-clamp: 1;
  color: #838383;
}

.HotTopic_margin {
  margin: 8px 0 0;
}

.HotTopic_wbtext a {
  color: #838383;
}

.HotTopic_wbtext img {
  width: 18px;
  height: 18px;
  vertical-align: -4px;
}

.HotTopic_wbtext img.icon-link {
  width: 1em;
  height: 1em;
  margin-right: 2px;
  vertical-align: -2px;
  -webkit-filter: sepia(100%) saturate(38%) contrast(75%);
  filter: sepia(100%) saturate(38%) contrast(75%);
}

.HotTopic_num {
  font-size: 12px;
  color: #939393;
  margin-top: 10px;
  line-height: 17px;
}

.HotTopic_num span {
  margin: 0 20px 0 0;
}

.HotTopic_num {
  font-size: 12px;
  color: #939393;
  margin-top: 10px;
  line-height: 17px;
}

.HotTopic_right {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 0 0 10px;
}

.HotTopic_pos,
.HotTopic_video {
  width: 100%;
  height: 100%;
}

.HotTopic_videomask {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 37px;
  height: 18px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  z-index: 1;
}

.HotTopic_videotime {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  height: 17px;
  line-height: 17px;
  text-align: center;
  width: 100%;
}
.HotTopic_wrap {
  width: 100%;
  cursor: pointer;
}

.HotTopic_img {
  border-radius: 8px;
  overflow: hidden;
}

.HotTopic_pos {
  position: absolute;
  top: 0;
  left: 0;
}

.HotTopic_tit:hover,
.HotTopic_wbtext:hover {
  color: #ff8200;
}

.wbpro-cutword {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.HotTopic_wbtext:hover a {
  color: #ff8200;
  text-decoration: none;
}

.HotTopic_focusImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.HotTopic_rankimg {
  width: 32px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(../assets/hotRankDefault.png);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.HotTopic_rank1 {
  background-image: url(../assets/hotRank1.png);
}

.HotTopic_rank2 {
  background-image: url(../assets/hotRank2.png);
}

.HotTopic_rank3 {
  background-image: url(../assets/hotRank3.png);
}

.HotTopic_rank1,
.HotTopic_rank2 {
  width: 45.46px;
  height: 22.79px;
  margin-top: -5px;
}

.HotTopic_rank1 > .HotTopic_ranknum,
.HotTopic_rank2 > .HotTopic_ranknum,
.HotTopic_rank3 > .HotTopic_ranknum {
  left: auto;
  right: 28.5%;
  bottom: 1px;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.HotTopic_rank3 {
  width: 45.46px;
  height: 22.79px;
  margin-top: -5px;
  background-image: url(../assets/hotRank3.png);
}
.HotTopic_doticon {
  background: #ff8747;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 0 10px;
}
.wbpro-ui-m .wbpro-side-tit .f17.fm {
  font-size: 16px;
}
.wbpro-side .cla {
  color: #333;
}
.wbpro-side .fm {
  font-weight: 500;
}
.wbpro-side .f17 {
  font-size: 17px;
  line-height: 24px;
}
.wbpro-side-tit-angle {
  margin-right: -10px;
}
.wbpro-side-tit .wbpro-iconbed {
  width: 24px;
  height: 24px;
}
.wbpro-iconbed {
  position: relative;
  width: 34px;
  height: 34px;
  cursor: pointer;
  line-height: 0;
}
.wbpro-iconbed:hover .keepfly-font {
  color: #ff8200;
}
.wbpro-iconbed:hover:before {
  background: rgba(255, 130, 0, 0.1);
}
.wbpro-side-card4 .item {
  padding: 14px 0;
}
.wbpro-side-card4 .con {
  margin: 0 0 0 10px;
  overflow: hidden;
}
.wbpro-side-hover:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.n-card {
  max-width: 184px;
}
.search_ss {
  height: 33px;
  line-height: 33px;
  border: 1px solid rgb(153, 51, 127);
  padding: 0 9px;
  font-size: 14px;
}

.search_ss:focus {
  outline: #cdc8cf;
}
.search_btn {
  width: 50px;
  border: 1px solid rgb(153, 51, 127);
  color: rgb(153, 51, 127);
  background: transparent;
}

.list-item {
  margin-bottom: 11px;
}
.sentence-card-wrap {
  background: #fff;
  padding: 24px 17px 22px 19px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07);
  border-radius: 9px;
}
.sentence-card-wrap .content-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sentence-card-wrap .content {
  font-size: 16px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.sentence-card-wrap .tool-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 30px;
  margin-top: 16px;
}

.sentence-card-wrap .tool-wrap .tool-left,
.sentence-card-wrap .tool-wrap .tool-left .operate-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.sentence-card-wrap .tool-wrap .tool-left {
  height: 30px;
}
.sentence-card-wrap .tool-wrap .tool-left .operate-item {
  margin-right: 63px;
  font-size: 13px;
  color: #666;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  line-height: 13px;
  letter-spacing: 0;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.sentence-card-wrap .tool-wrap .tool-left .operate-item .tool-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  opacity: 0.7;
}
.sentence-card-wrap .tool-wrap .copy {
  width: 75px;
  height: 30px;
  line-height: 30px;
  border-radius: 40.5px;
  background: #f5f5f5;
  font-size: 14px;
  color: #1f1f1f;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
