const ru = {
    message: {
        username: 'Имя пользователя',
        password: 'Пароль',
        save: 'Сохранить',
        edit: 'Редактор',
        update: 'Обновление',
        delete: 'Удалить',
        currentLanguage: 'Русский язык',
        search: 'Посмотреть',
        popular: 'Горячо',
        topic: 'Темы',
        goldenSentenceList: 'Золотой список',
        askQuestions: 'Вопросы',
        SignIn: 'Войти',
        register: 'Регистрация',
        home: 'Главная страница',
        information: 'Новости',
        register_tis: 'Еще нет Keepfly?',
        register_Now: 'Зарегистрируйтесь немедленно',
        sign_in_now: 'Войдите немедленно',
        homepage: 'домашняя страница',
        no_account_yet: 'Еще нет аккаунта?',
        welcome: 'Добро пожаловать',
        account_number: 'Номер счета (почтовый ящик)',
        account_register: 'Регистрация учетной записи',
        nickname: 'Под псевдонимом',
        email: 'Почтовый ящик',
        mobile: 'номер мобильного телефона',
        confirm_password: 'Определение пароля',
        verification_code: 'Код проверки',
        gain: 'Приобретение',
        tips1: 'Пожалуйста, введите почтовый ящик',
        tips2: 'Написать пароль',
        tips3: 'Написать псевдоним',
        tips4: 'Написать пароль',
        tips5: 'Два ввода пароля не совпадают',
        tips6: 'Запись кода проверки',
        follow_all: 'Все внимание',
        up_to_date: 'Золотое предложение',
        friends: 'Круг друзей',
        deceased: 'Управление мертвыми',
        tips7: 'Что нового вы хотите поделиться со всеми?',
        emote: 'Лицо',
        picture: 'Фотографии',
        essay: 'Статьи',
        more: 'Смотреть больше',
        send: 'Отправить',
        all: 'В целом',
        advanced_search: 'Посмотреть',
        userinfo: 'Данные пользователя',
        log_out: 'Выход из регистрации',
        tips8: 'Какую тему вы хотите использовать?',
        find_it: 'Ищу',
        home_page: 'домашняя страница',
        read: 'Читать',
        discuss: 'Обсуждение',
        click_refresh: 'Новый',
        set: 'Корректировка',
        edit_data: 'Редактирование данных',
        privacy_setting: 'Настройки конфиденциальности',
        tips9: 'Настройка информации учетной записи',
        sign: 'Подпись',
        tips10: '4 - 30 символов, поддерживающих китайский английский, цифры',
        tips11: 'Представьте себя',
        tips12: '1 - 140 символов',
        tips13: 'Личные базовые данные',
        gender: 'Мужчиныиженщины ',
        man: 'Мужчины',
        woman: 'Женщины',
        other: 'Другие',
        birthday: 'День рождения',
        emotional_status: 'Эмоциональное состояние',
        tips14: 'Выберите эмоциональное состояние',
        address: 'Месторасположение',
        hometown: 'Место жительства',
        single: 'Одинокие',
        seeking_a_relationship: 'Взаимодействие',
        in_secret_love: 'В тайной любови',
        ambiguous: 'В двусмысленности',
        in_love: 'Влюбленный',
        engaged: 'Обручение',
        married: 'Замужем',
        separated: 'Расставание',
        divorced: 'Расставание',
        widow: 'Вдовы',
        not_selected: 'Не выбирать',
        tips15: 'Смотреть ли мою',
        personal_homepage: 'Личная домашняя страница',
        my_home_page: 'Моя домашняя страница',
        my_attention: 'Меня это волнует',
        my_fans: 'Мои поклонники',
        tips16: 'Пока нет профиля',
        belong_to: 'Принадлежность',
        add: 'Присоединиться',
        look_around_the_world: 'глобальный поиск',
        registration_of_seeking_relatives: 'Регистрация поиска родственников',
        name_of_missing_person: 'Имя пропавшего лица',
        age: 'Сколько жить',
        debatable_time: 'Время пропадания',
        location_of_disappearance: 'Место исчезновения',
        feature_description: 'Особенности',
        photograph: 'Фотография',
        check_in: 'Регистрация',
        contact_number: 'Контактный телефон',
        front_cover: 'Обложка',
        missing_people: 'Пропавшие без вести',
        created_by: 'Создатель',
        creation_time: 'Время создания',
        comment: 'Комментарии',
        praise: 'Хён',
        system_notification: 'Системные уведомления',
        tips17: 'Введите правильный почтовый ящик',
        tips17_o: 'Введите правильный формат номера телефона',
        avatar: 'Лицо',
        tips18: 'Счет уже существует',
        tips19: 'Регистрация не удалась',
        tips20: 'Несчастные случаи',
        tips21: 'Повторно получить код проверки',
        tips22: 'Отправить код проверки успешно',
        tips23: 'Войти успешно',
        tips24: 'аварии',
        tips25: 'Пользователя не существует',
        tips26: 'Посмотреть полный список горячих поисков',
        help_center: 'Центр помощи',
        customer: 'Обслуживание клиентов',
        self_service_center: 'Центр самообслуживания',
        website_related: 'Веб - сайт',
        tips27: 'Сотрудничество Услуги ',
        cooperation_hotline: 'Горячая линия сотрудничества',
        follow: 'Связь',
        tips28: 'Дайте подсказку',
        view_the_original_article: 'Посмотреть оригинальную статью',
        message: 'Сообщение',
        tips29: 'Добавить успех',
        tips30: 'Добавить неудачу',
        tips31: 'Публикуй свой комментарий',
        tips32: 'Существуют',
        tips33: 'Регистрация успешно',
        tips34: 'Не стоит заботиться о себе',
        cancel: 'Закрыть',
        tips35: 'Удаление данных успешно',
        tips36: 'Ошибка удаления данных',
        name_of_the_deceased: 'Имя умершего',
        nationality: 'Гражданство',
        date_of_birth: 'Дата рождения',
        date_of_death: 'Дата смерти',
        university: 'Выпускники',
        occupation: 'Профессия',
        achievements: 'Личные достижения',
        tips37: 'Имя умершего не может быть пустым',
        tips38: 'Выберите аватар',
        tips39: 'Гражданство не может быть пустым',
        tips40: 'Выберите дату рождения',
        tips41: 'Выберите дату смерти',
        tips42: 'Создание успеха',
        tips43: 'Не удалось создать',
        tips44: 'Все вентиляторы',
        account_of_the_deceased: 'Счет мертвых',
        add_an_account: 'Добавить аккаунт',
        view: 'Смотреть',
        transfer: 'Передача',
        newly_increased: 'Дополнительные',
        opposite_id: 'Противоположный ID',
        transfer_failed: 'Провал передачи',
        transfer_successful: 'Перевод удался',
        tips45: 'Не могу передать себя сам',
        tips46: 'Нет учетной записи умершего',
        tips47: 'Пока нет внимания',
        tips48: 'Нет фанатов',
        determine: 'Определение',
        call_off: 'Отменить',
        tips49: 'Отменить внимание к этому пользователю?',
        tips50: 'Какие золотые фразы можно поделиться с вами?',
        tips51: 'Изменить успешно',
        tips52: 'Ошибка изменения',
        hint: 'Советы',
        tips53: 'Вы уверены пересылать?',
        repeat: 'Передача',
        hot_search: 'Горячий поиск',
        know: 'Знай',
        tips54: 'Всегда есть кто - то, кто знает',
        tips55: 'Ответы на ваши вопросы',
        solve: 'Решение',
        question: 'Вопрос',
        tips56: 'Знай знать',
        tips57: 'Я хочу задать вопрос',
        submit: 'Представлено',
        quick_answer: 'Быстрый ответ',
        translate: 'Перевод',
        tips58: 'Неудача перевода',
        recommend: 'Рекомендации',
        effect: 'Влияние',
        number_of_fans: 'Количество фанатов',
        quantity: 'Количество содержимого',
        fans: 'Фанаты',
        tips59: 'Доставка цветов успешно',
        tips60: 'Неудача с доставкой цветов',
        tips61: 'Редактировать успешно',
        tips62: 'Ошибка редактирования',
        tips63: 'Введите имя',
        tips64: 'Пожалуйста, введите отношения',
        tips65: 'Пожалуйста, выберите картинку',
        tips66: 'Введите заголовок',
        tips67: 'Пожалуйста, введите содержимое',
        tips68: 'Введите имя плиты',
        tips69: 'Плита уже существует',
        tips70: 'Определить доставку цветов?',
        tips71: 'Отношения с умершими',
        album: 'Фотоальбом',
        deceased_admin: 'Администратор мертвых',
        creator: 'Создатель',
        the_deceased: 'Мертвец',
        number_of_views: 'Количество просмотров',
        update_time: 'Время обновления',
        basic_info: 'Базовая информация',
        full_name: 'Имя',
        alias: 'Другие имена',
        language: 'Хорошо владеет языком',
        height: 'Рост',
        motto: 'Девиз',
        interaction: 'Взаимодействие',
        input_relationship: 'Введите отношения',
        content_addition: 'Добавление контента',
        image_upload: 'Загрузка изображений',
        message_management: 'Управление сообщениями',
        deceased_label: 'Метки мертвых',
        modify: 'Изменить',
        image_photo: 'Изображения',
        brief: 'Профиль',
        label: 'Метки',
        increase: 'Добавить',
        constellation: 'Созвездие',
        relationship: 'Отношения',
        title: 'Заголовок',
        add_section: 'Добавить плиту',
        modify_section: 'Изменить плиту',
        module_name: 'Название плиты',
        content: 'Содержание',
        section: 'Плита',
        guestbook: 'Сообщение',
        operate: 'Операция',
        early_experiences: 'Ранний опыт',
        deductive_experience: 'Достижения в работе',
        personal_life: 'Личная жизнь',
        award_records: 'Важные события',
        character_evaluation: 'Оценка знакомых',
        deceased_square: 'Площадь мертвых',
        memorial_plaza: 'Площадь памяти',
        ranking_list1: 'Цветочный список',
        release: 'Опубликовано',
        message_list: 'Список сообщений',
        tips72: 'Удалить Пожалуйста, свяжитесь с администратором',
        tips73: 'Пожалуйста, введите номер 11',
        none: 'Нет',
        details: 'Подробности',
        log_on: 'Войти',
        previous_article: 'Предыдущая статья',
        next_article: 'Следующая статья',
        chapter_1: 'Первая часть',
        last_article: 'Последняя статья',
        tips74: 'Нет больше данных',
        unknown: 'Неизвестно',
        tips75: 'Влияние воплощено в сочетании с продолжительностью ежедневной посадки пользователя и количеством взаимодействий',
        tips76: 'Нарушение прав интеллектуальной собственности',
        tips77: 'Слухи ложные',
        tips78: 'Плохое качество контента',
        tips79: 'Преступление чести',
        tips80: 'вульгарное порно',
        tips81: 'Подозреваемый в мошенничестве',
        tips82: 'Политически связанные',
        tips83: 'Маркетинговая реклама',
        tips84: 'Пропаганда плохих ценностей',
        report: 'Отчет',
        report_content: 'Содержание сообщения',
        reported_image: 'Отчетное изображение',
        reporting_link: 'Сообщения по ссылке',
        reporting_type: 'Тип сообщения',
        join: 'Участие',
        interact: 'Интерактивные сообщения',
        tips85: 'Пользователь отключен',
        tips86: 'Код проверки почтового ящика',
        change_password: 'Изменить пароль',
        confirm: 'Подтверждение',
        return: 'Возвращение',
        tips87: 'Мой вопрос',
        recommendo: 'РЕКОМЕНДАЦИЯ',
        tips88: 'Рекомендуем этот ответ?',
        tips89: 'Рекомендуем успех',
        tips90: 'Рекомендуемый провал',
        tips91: 'Проблемы не существует',
        tips92: 'Размер изображения не может быть больше 10M!',
        tips93: 'Размер изображения не может быть 0M!',
        tips94: 'Соотношение изображений не 1: 1',
        tips95: 'Советы: изображение, пожалуйста, загрузите по размеру 1: 1, иначе не получится; Изображение головы, пожалуйста, поместите главного персонажа в центр; общее качество изображения ниже 10M',
        tips96: 'Соотношение изображений не 16:9',
        tips97: 'Установить новый пароль',
        tips98: 'Не выбрано как неизвестное',
        tips99: 'Введите ID',
        tips100: 'Оригинальная защита: Авторское право принадлежит автору',
        tips101: 'Заявление: публикация контента не имеет ничего общего с платформой, если вы обнаружите ложную информацию',
        tips102: 'Рекомендации загрузить 4: 3 поперечных длинных изображений, общее качество изображения ниже 10M, поддержка jpg, jpeg, bmp, gif, png формат',
        tips103: 'Рекомендации загрузить 3: 4 вертикальные длинные линии изображения, общее качество изображения ниже 10M, поддержка jpg, jpeg, bmp, gif, png формат',
        id: 'ID',
        tips104: 'Полученные комментарии',
        private_letter: 'Личное письмо',
        reply: 'Ответить',
        the_original: 'Подлинный текст',
        change: 'Изменить',
        change_avatar: 'Изменить картинку',
        tips105: 'Рекомендация загрузить размер 1: 1, общее качество изображения ниже 10M, поддержка jpg, jpeg, bmp, gif, png формат',
        change_email: 'Замена почтового ящика',
        new_email: 'Новый почтовый ящик',
        tips106: 'Существуют',
        tips107: 'Круглосуточный почтовый ящик',
        tips108: 'Авторское право принадлежит автору',
        tips109: 'Настройка персональной домашней страницы',
        tips110: 'Настройка успешно',
        tips111: 'Ошибка настройки',
        tips112: 'Забудь пароль',
        tips113: 'Только этот пользователь может просматривать',
        tips114: 'Смотреть могут только друзья пользователя',
        year: 'Год',
        moon: 'Месяц',
        day: 'День',
        B_C: 'До н.э.',
        tips115: 'Сортировка по году смерти',
        tips116: 'Не пишите себе личные письма',
        tips117: 'Нажмите Enter для отправки',
        tips118: 'Посмотреть информационный контент',
        tips119: 'Пока нет личного письма',
        search_result: 'Результаты поиска',
        comprehensive: 'Интеграция',
        tips120: 'Добавлен черный список',
        tips121: 'Не могу добавить себя в черный список',
        tips122: 'Присоединяйтесь к черному списку',
        tips123: 'Противоположная сторона добавила вас в черный список и не может отправить сообщение на него',
        tips124: 'Вы добавили друг друга в черный список и не можете отправить ему сообщение',
        blacklist: 'Черный список',
        die_at_the_age_of: 'Возраст',
        tips125: 'Удалить диалог?',
        tips126: 'Не формат даты',
        just: 'Только что',
        tips127: '1 минута назад',
        minute_ago: ' Минуту назад',
        tips128: '1 час назад',
        hour_ago: 'Часы назад',
        yesterday: 'Вчера',
        days_ago: 'Накануне',
        weeks_ago: 'Неделю назад',
        tips129: 'Имя ',
        about_us: 'О нас',
        cooperation: 'Сотрудничество с инвесторами',
        walk_into: 'Входит',
        tips130: 'Узнайте об этом через менеджера по продажам веб - сайта или консалтинговой компании по телефону',
        tips131: 'Переговоры о сотрудничестве: можно обсудить по телефону или посетить непосредственно ознакомительные переговоры',
        tips132: 'Подписать соглашение для определения способа сотрудничества',
        tips133: 'Горячая линия обслуживания',
        tips134: 'Кооперативный телефон',
        customer_service_1: 'Обслуживание клиентов 1',
        customer_service_2: 'Обслуживание клиентов 2',
        tips135: 'Запрос на добавление / исправление содержимого',
        tips136: "Имя ключевое слово",
        tips137: 'Просмотреть полное сообщение',
        message_board: 'Сообщение',
        tips138: 'Домашняя страница мертвых',
        copy: 'Копирование',
        my_answer: 'Мой ответ',
        my_password: 'Мой пароль',
        tools: 'Инструменты',
        height_unit: 'Единицы роста',
        tips139: 'Рекомендация загрузить 100 × 138 вертикальных длинных строк изображения, общее качество изображения ниже 10M, поддержка JPG, JPEG, BMP, GIF, PNG формат',
        tips140: 'Рекомендации загрузить 220 × 231 вертикальные длинные изображения, общее качество изображения ниже 10M, поддержка jpg, jpeg, bmp, gif, png формат',
        tips141: 'Удалить панель',
        tips142: 'Золотая фраза, которую я выпустил',
        tips143: 'Запрет на личные разговоры',
        tips144: 'Совет: Если была отмечена кнопка Неизвестное, система покажет Неизвестное',
        replicating_success: 'Копировать успешно',
        tips145: 'Рекомендуется загрузить изображение в размере 1: 1, изображение головы, пожалуйста, поместите главного персонажа в центр;  Общее качество изображения ниже 10 м',
        tips146: 'Забота о неудаче',
        tips147: 'Отменено внимание',
        tips148: 'Отменить фокус неудачи',
        tips149: 'Обеспокоены друг другом',
        tips150: 'Взаимное внимание к неудаче',
        tips151: 'Пользователи были удалены из черного списка',
        tips152: 'Не удалось удалить пользователя из черного списка',
        tips153: "Опубликовано",
        tips154: 'Не удалось опубликовать',
        tips155: "Представлено",
        tips156: "Ошибка подачи",
        self: 'Сам',
        sending_flowers: 'Доставка цветов',
        successfully_saved:'Сохранить успех',
        save_failed:'Не удалось сохранить',
        clue: 'Подсказка',
        tips157: 'Заполните статус отношений с умершим, например, муж, мать, друг',
        tips158: 'Рекомендуется загрузить 1: 1 пропорциональное квадратное изображение, размер изображения меньше 10M, поддержка JPG, JPEG, BMP, GIF, PNG формат',
        tips159: 'Пожалуйста, загрузите квадратное изображение в масштабе 1: 1, иначе оно не будет успешным.  Изображение головы Пожалуйста, поместите главного персонажа посередине, размер изображения ниже 10M, поддержка JPG, JPEG, BMP, GIF, PNG формат',
        tips160: 'Рекомендуется загрузить 220: 230 пропорциональное вертикальное длинное изображение, размер изображения ниже 10M, поддержка jpg, jpeg, bmp, gif, png формат',
        tips161: 'Рекомендуется загрузить 100: 140 пропорциональное вертикальное длинное изображение, размер изображения ниже 10M, поддержка JPG, JPEG, BMP, GIF, PNG формат',
        tips162: 'Рекомендуется загрузить 100: 56 пропорциональное поперечно - длинное изображение, размер изображения меньше 10M, поддерживает формат jpg, jpeg, bmp, gif, png',
        admin: 'Администратор',
        tourist: 'Туристы',
        tips163: 'Опубликовано успешно',
        id_number_copied: 'Номер ID скопирован',
        tips164: "Подсказки обеспечивают успех",
        tips165: "Подсказки предлагают неудачу",
        tips166: "Золотое предложение скопировано успешно",
        tips167: "Отменить внимание к успеху",
        tips168: "Отменить фокус неудачи",
        tips169: "Выпуск золотого предложения успешно",
        tips170: "Не удалось выпустить золотое предложение",
        tips171: "Комментарии к успеху",
        tips172: "Ошибка комментариев",
        tips173: "Ответить успешно",
        tips174: "Неудача ответа",
        tips175: "Выпуск вопросов успешно",
        tips176: "Не удалось опубликовать вопрос",
        tips177: "Сообщение неудачно",
        deceasedo: 'Добавление мертвых',
        tips178: 'Введите идентификатор аннулирования',
        tips179: 'Номер учетной записи не правильный, пожалуйста, проверьте ID заново',
        tips180: 'Пожалуйста, введите причину списания',
        tips181: "Заявка удалась!  Keepfly обработает вашу заявку в течение 7 рабочих дней",
        tips182: 'Неудачная заявка',
        tips183: 'Аннулированный ID',
        tips184: 'Списание почтового ящика',
        tips185: 'Причины списания',
        apply: "Заявка",
        tips186: 'Вы списываете свою учетную запись Keepfly, и в дальнейшем вы не сможете использовать продукты и услуги Keepfly, поэтому будьте осторожны!',
        tips187: 'Keepfly не будет хранить никаких записей ваших операций и не сможет подать заявку на восстановление записей после их списания',
        tips188: 'Номер списания',
        tips189: 'Не создавайте учетную запись умершего для живых людей, если существует юридический риск, не связанный с платформой',
        video: 'Видео',
        tips190: 'Фото видео можно загрузить только один вид, подтверждаю?',
        tips191: 'Доставка цветов не должна превышать 20 минут',
        delete_account: 'Удалить учётную запись',
    }
}
export default ru