const en = {
  message: {
    username: 'username',
    password: 'password',
    save: 'Save',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    currentLanguage: 'English',
    search: 'search',
    popular: 'popular',
    topic: 'topic',
    goldenSentenceList: 'Golden sentence list',
    askQuestions: 'ask questions',
    SignIn: 'Sign in',
    register: 'register',
    home: 'home',
    information: 'information',
    register_tis: 'Have you not Keepfly yet?',
    register_Now: 'Register Now',
    sign_in_now: 'Sign in now',
    homepage: 'homepage',
    no_account_yet: 'No account yet?',
    welcome: 'Welcome',
    account_number: 'Account number(email)',
    account_register: 'account register',
    nickname: 'nickname',
    email: 'Email',
    mobile: 'mobile number',
    confirm_password: 'Confirm Password',
    verification_code: 'Verification Code',
    gain: 'gain',
    tips1: 'Please enter email',
    tips2: 'Please enter the password',
    tips3: 'Please enter a nickname',
    tips4: 'Please enter the confirmation password',
    tips5: 'The passwords entered twice are inconsistent',
    tips6: 'Please enter the confirmation verification code',
    follow_all: 'Follow All',
    up_to_date: 'Golden sentence',
    friends: 'Friends',
    deceased: 'deceased',
    tips7: "What's new to share with you?",
    emote: 'emote',
    picture: 'picture',
    essay: 'essay',
    more: 'See more',
    send: 'send',
    all: 'all',
    advanced_search: 'advanced search',
    userinfo: 'user data',
    log_out: 'log out',
    tips8: 'What topic do you want to use',
    find_it: 'find it',
    home_page: 'home page',
    read: 'read',
    discuss: 'discuss',
    click_refresh: 'click refresh',
    set: 'set',
    edit_data: 'Edit data',
    privacy_setting: 'privacy setting',
    tips9: 'Account information settings',
    sign: 'sign',
    tips10: '4 to 30 characters, supporting Chinese, English, and numbers',
    tips11: "Let's introduce ourselves",
    tips12: '1-140 characters',
    tips13: 'Personal Profile',
    gender: 'gender',
    man: 'man',
    woman: 'woman',
    other: 'other',
    birthday: 'birthday',
    emotional_status: 'emotional status',
    tips14: 'choose an emotional situation',
    address: 'address',
    hometown: 'hometown',
    single: 'Single',
    seeking_a_relationship: 'Seeking a relationship',
    in_secret_love: 'In secret love',
    ambiguous: 'Ambiguous',
    in_love: 'In love',
    engaged: 'engaged',
    married: 'Married',
    separated: 'Separated',
    divorced: 'Divorced',
    widow: 'Widow',
    not_selected: 'Not selected',
    tips15: 'Do you want to view my',
    personal_homepage: 'Personal homepage',
    my_home_page: 'My Home Page',
    my_attention: 'My attention',
    my_fans: 'My fans',
    tips16: 'No profile available',
    belong_to: ' belong to',
    add: 'add',
    look_around_the_world: 'look around the world',
    registration_of_seeking_relatives: 'registration of seeking relatives',
    name_of_missing_person: 'name of missing person',
    age: 'age',
    debatable_time: 'debatable time',
    location_of_disappearance: 'location of disappearance',
    feature_description: 'feature description',
    photograph: 'photograph',
    check_in: 'check in',
    contact_number: 'contact number',
    front_cover: 'front cover',
    missing_people: 'missing people',
    created_by: 'created by',
    creation_time: 'creation time',
    comment: 'comment',
    praise: 'praise',
    system_notification: 'system notification',
    tips17: 'Please enter the correct email address',
    tips17_o: 'Please enter mobile phone number format',
    avatar: 'avatar',
    tips18: 'Account already exists',
    tips19: 'Registration failed',
    tips20: 'Captcha error',
    tips21: 'Re-obtain the verification code',
    tips22: 'The verification code was sent successfully',
    tips23: 'Login successful',
    tips24: 'bad password',
    tips25: 'User does not exist',
    tips26: 'Check out the full list of hot searches',
    help_center: 'Help Center',
    customer: 'customer',
    self_service_center: 'Self-service center',
    website_related: 'website related',
    tips27: 'Cooperation Service',
    cooperation_hotline: 'Cooperation hotline',
    follow: 'follow',
    tips28: 'Provide clues',
    view_the_original_article: 'view the original article',
    message: 'message',
    tips29: 'Successfully added',
    tips30: 'Add failed',
    tips31: 'Post your comment',
    tips32: 'Followed',
    tips33: 'Registration successful',
    tips34: "Don't have to pay attention to yourself",
    cancel: 'cancel',
    tips35: 'Successfully deleted',
    tips36: 'Failed to delete',
    name_of_the_deceased: 'name of the deceased',
    nationality: 'nationality',
    date_of_birth: 'date of birth',
    date_of_death: 'date of death',
    university: 'university',
    occupation: 'occupation',
    achievements: 'achievements',
    tips37: 'The name of the deceased cannot be empty',
    tips38: 'Select avatar',
    tips39: 'Nationality cannot be empty',
    tips40: 'Select date of birth',
    tips41: 'Select the date of the deceased',
    tips42: 'Created successfully',
    tips43: 'Creation failed',
    tips44: 'All fans',
    account_of_the_deceased: 'account of the deceased',
    add_an_account: 'add an account',
    view: 'view',
    transfer: 'transfer',
    newly_increased: 'newly increased',
    opposite_id: 'Opposite ID',
    transfer_failed: 'transfer failed',
    transfer_successful: 'transfer successful',
    tips45: 'Cannot transfer to oneself',
    tips46: 'There are currently no accounts for the deceased',
    tips47: 'Currently not following',
    tips48: 'Currently no fans',
    determine: 'determine',
    call_off: 'call off',
    tips49: 'Are you sure to cancel following this user?',
    tips50: 'What are some golden sentences to share with everyone?',
    tips51: 'Modified successfully',
    tips52: 'Modification failed',
    hint: 'hint',
    tips53: 'sure you want to forward it',
    repeat: 'repeat',
    hot_search: 'hot search',
    know: 'know',
    tip54: 'someone always knows',
    tips55: 'The answer to your question',
    solve: 'solve',
    question: 'question',
    tips56: 'Know what to do',
    tips57: 'I need to ask a question',
    submit: 'submit',
    quick_answer: 'quick answer',
    translate: 'translate',
    tips58: 'Translation failed',
    recommend: 'recommend',
    effect: 'effect',
    number_of_fans: 'number of fans',
    quantity: 'Number of contents',
    fans: 'fans',
    tips59: 'Successfully sent flowers',
    tips60: 'Flower sending failed',
    tips61: 'Edited successfully',
    tips62: 'Editing failed',
    tips63: 'Please enter your name',
    tips64: 'Please enter a relationship',
    tips65: 'Please select an image',
    tips66: 'Please enter a title',
    tips67: 'Please enter content',
    tips68: 'Please enter a section name',
    tips69: 'Section already exists',
    tips70: 'Are you sure to send flowers?',
    tips71: 'Relationship with the deceased',
    album: 'Album',
    deceased_admin: 'deceased administrator',
    creator: 'Creator',
    the_deceased: 'The deceased',
    number_of_views: 'Number of views',
    update_time: 'Update time',
    basic_info: 'Basic information',
    full_name: 'full name',
    alias: 'alias',
    language: 'language',
    height: 'height',
    motto: 'motto',
    interaction: 'interaction',
    input_relationship: 'input relationship',
    content_addition: 'content addition',
    image_upload: 'image upload',
    message_management: 'message management',
    deceased_label: 'deceased label',
    modify: 'modify',
    image_photo: 'image photo',
    brief: 'brief',
    label: 'label',
    increase: 'increase',
    constellation: 'constellation',
    relationship: 'relationship',
    title: 'title',
    add_section: 'add section',
    modify_section: 'modify section',
    module_name: 'module name',
    content: 'content',
    section: 'section',
    guestbook: 'guestbook',
    operate: 'operate',
    early_experiences: 'Early experiences',
    deductive_experience: 'Work achievements',
    personal_life: 'personal life',
    award_records: 'Major events',
    character_evaluation: 'Acquaintance evaluation',
    deceased_square: 'deceased square',
    memorial_plaza: 'memorial plaza',
    ranking_list1: 'Flower Ranking',
    release: 'release',
    message_list: 'message list',
    tips72: 'Please contact the administrator for deletion',
    tips73: 'Please enter an 11 digit phone number',
    none: 'none',
    details: 'details',
    log_on: 'log on',
    previous_article: 'previous article',
    next_article: 'next article',
    chapter_1: 'chapter 1',
    last_article: 'last article',
    tips74: 'No more data available',
    unknown: 'unknown',
    tips75: "The influence is reflected by the user's daily login duration and interaction count",
    tips76: 'Infringement of intellectual property rights',
    tips77: 'False rumors',
    tips78: 'Poor content quality',
    tips79: 'Infringement of reputation',
    tips80: 'vulgar pornography',
    tips81: 'Suspected of fraud',
    tips82: 'Political relevance',
    tips83: 'Marketing Advertising',
    tips84: 'Promoting Bad Values',
    report: 'report',
    report_content: 'report content',
    reported_image: 'reported image',
    reporting_link: 'reporting link',
    reporting_type: 'reporting type',
    join: 'join',
    interact: 'interact',
    tips85: 'User disabled',
    tips86: 'Email verification code',
    change_password: 'change password',
    confirm: 'confirm',
    return: 'return',
    tips87: 'my question',
    recommendo: 'recommend',
    tips88: 'Recommend this answer?',
    tips89: 'Recommendation successful',
    tips90: 'Recommendation failed',
    tips91: 'The problem does not exist',
    tips92: 'The image size cannot be greater than 10M!',
    tips93: 'Image size cannot be 0M!',
    tips94: 'The image scale is not 1:1',
    tips95: 'Please upload the image in a 1:1 size, otherwise it will not be successful. Please center the main character in the avatar image. The overall image quality is less than 10M',
    tips96: 'The image scale is not 16:9',
    tips97: 'Set new password',
    tips98: 'Uncheck as unknown',
    tips99: 'Please enter an ID',
    tips100: 'Original protection: copyright belongs to the author',
    tips101: 'Declaration: The published content is not related to the platform, and if any false information is found, it can be reported',
    tips102: 'Suggestion Upload a 4:3 horizontal long image with an overall image quality of less than 10M and support for jpg, jpeg, bmp, gif, png formats',
    tips103: 'Suggestion Upload a 3:4 vertical long line image with an overall image quality of less than 10M and support for jpg, jpeg, bmp, gif, png formats',
    id: 'ID',
    tips104: 'Received comments',
    private_letter: 'direct message',
    reply: 'reply',
    the_original: 'the original',
    change: 'change',
    change_avatar: 'change avatar',
    tips105: 'Suggestion Upload a 1:1 size, with overall image quality below 10M, and support for jpg, jpeg, bmp, gif, png formats',
    change_email: 'change email',
    new_email: 'new email',
    tips106: 'Email registered',
    tips107: '24-hour customer service email',
    tips108: 'copyright belongs to the author',
    tips109: 'Personal homepage settings',
    tips110: 'Successfully set',
    tips111: 'Setting failed',
    tips112: 'Forgot password',
    tips113: 'Only the user can view it themselves',
    tips114: "Only this user's friends can view",
    year: 'year',
    moon: 'month',
    day: 'day',
    B_C: 'B.C',
    tips115: 'Sort by year of death',
    tips116: 'Cannot send private messages to oneself',
    tips117: 'Press ENTER to send',
    tips118: 'View private message content~~',
    tips119: 'No private messages yet~~',
    search_result: 'search result',
    comprehensive: 'comprehensive',
    tips120: 'Blacklist added',
    tips121: 'Cannot add oneself as a blacklist',
    tips122: 'Add to blacklist',
    tips123: 'The other party has added you to the blacklist and cannot send messages to them',
    tips124: 'You have added the other party to the blacklist and cannot send messages to them',
    blacklist: 'blacklist',
    die_at_the_age_of: 'die at the age of',
    tips125: 'Delete conversation?',
    tips126: 'Not in a valid date format',
    just: 'just',
    tips127: '1 minute ago',
    minute_ago: ' minute ago',
    tips128: '1 hour ago',
    hour_ago: ' hour ago',
    yesterday: 'yesterday',
    days_ago: ' days ago',
    weeks_ago: ' weeks ago',
    tips129: 'full name',
    about_us: 'about us',
    cooperation: 'cooperation',
    walk_into: 'walk into',
    tips130: "Consult the company's sales manager through the website or phone to learn more",
    tips131: 'Negotiating cooperation: You can negotiate by phone or come directly to visit, inspect, and negotiate',
    tips132: 'Sign an agreement to determine the cooperation method',
    tips133: 'Service hotline',
    tips134: 'Cooperation phone number',
    customer_service_1: 'customer service 1',
    customer_service_2: 'customer service 2',
    tips135: 'Application content addition/correction',
    tips136: 'Name keywords',
    tips137: 'View complete message',
    message_board: 'message board',
    tips138: "The deceased's homepage",
    copy: 'copy',
    my_answer: 'my answer',
    my_password: 'my password',
    tools: 'tools',
    height_unit: 'height unit',
    tips139: 'Suggestion Upload a 100 × 138 vertical long line image, with an overall image quality of less than 10M and support for jpg, jpeg, bmp, gif, png formats',
    tips140: 'Suggestion Upload a 220 × 231 vertical long line image, with an overall image quality of less than 10M and support for jpg, jpeg, bmp, gif, png formats',
    tips141: 'Delete section',
    tips142: 'The golden sentence I posted',
    tips143: 'Prohibit private chatting',
    tips144: 'Tip: If the Unknown button is checked, the system will display Unknown',
    replicating_success: 'replicating success',
    tips145: "Suggest uploading images in a 1:1 size, with the main character centered in the portrait image; The overall image quality is less than 10M",
    tips146: 'Follow failed',
    tips147: 'Cancelled following',
    tips148: 'Unfollowing failed',
    tips149: 'We have followed each other',
    tips150: 'Mutual attention failed',
    tips151: 'The user has been removed from the blacklist',
    tips152: 'Failed to remove user from blacklist',
    tips153: 'Published',
    tips154: 'Publishing failed',
    tips155: 'Submitted',
    tips156: 'Submission failed',
    self: 'self',
    sending_flowers: 'sending flowers',
    successfully_saved:'successfully saved',
    save_failed:'save failed',
    clue: 'clue',
    tips157: 'Fill in the identity of the relationship with the deceased, such as husband, mother, or friend',
    tips158: 'It is recommended to upload a 1:1 square image with a size less than 10M and support jpg, jpeg, bmp, gif, png formats',
    tips159: 'Please upload a 1:1 scale square image, otherwise it will not be successful. Please center the main character in the avatar image. The image size is less than 10M and supports jpg, jpeg, bmp, gif, png formats',
    tips160: 'It is recommended to upload a 220: 230 scale vertical long image with a size less than 10M, supporting jpg, jpeg, bmp, gif, png formats',
    tips161: 'It is recommended to upload a vertical long image with a ratio of 100 to 140, with an image size of less than 10M, and supports formats such as jpg, jpeg, bmp, gif, and png',
    tips162: 'It is recommended to upload a horizontal and elongated image with a ratio of 100 to 56, with an image size of less than 10M, and supports formats such as jpg, jpeg, bmp, gif, and png',
    admin: 'admin',
    tourist: 'tourist',
    tips163: 'Successfully published',
    id_number_copied: 'ID number copied',
    tips164: 'Successfully provided clues',
    tips165: 'Failed to provide clues',
    tips166: 'Golden sentence copied successfully',
    tips167: 'Unfollowing succeeded',
    tips168: 'Unfollowing failed',
    tips169: 'Successfully published golden sentence',
    tips170: 'Failed to publish golden sentence',
    tips171: 'Comment successful',
    tips172: 'Comment failed',
    tips173: 'Successfully answered',
    tips174: 'Answer failed',
    tips175: 'Successfully posted the question',
    tips176: 'Failed to post question',
    tips177: 'Message failed',
    deceasedo: 'Add the deceased',
    tips178: 'Please enter the logout ID',
    tips179: 'The account is incorrect, please recheck the ID',
    tips180: 'Please enter the reason for cancellation',
    tips181: 'Application successful! Keepfly will process your application within 7 working days',
    tips182: 'Application failed',
    tips183: 'Unregister ID',
    tips184: 'Log off email',
    tips185: 'Reason for cancellation',
    apply: 'Apply',
    tips186: 'You are currently cancelling your Keepfly account and will not be able to use Keepfly products and services in the future. Therefore, please proceed with caution!',
    tips187: 'Keepfly will not retain any of your operation records and cannot apply for recovery of records after cancellation.',
    tips188: 'Account cancellation',
    tips189: 'Please do not establish a deceased account for living individuals. If there are legal risks, it is not related to the platform.',
    video: 'video',
    tips190: 'Only one type of image or video can be uploaded. Are you sure?',
    tips191: 'Sending flowers cannot exceed 20 minutes',
    delete_account: 'delete account',
  }
}
export default en