<template>
  <div class="keepfly-box-flex Frame_content">
    <div class="Frame_side">
      <div class="Nav_main" style="height: 440px">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner wbpro-scrollbar"
        >
          <h2 class="Nav_title">{{ $t("message.search_result") }}</h2>
          <div>
            <div>
              <a
                @click="setNavTab8(1)"
                class="router-link-exact-active router-link-active ALink_default"
              >
                <div
                  :class="[
                    navTab8Show == 1
                      ? 'keepfly-box-flex keepfly-box-alignCenter NavItem_main NavItem_cur'
                      : 'keepfly-box-flex keepfly-box-alignCenter NavItem_main',
                  ]"
                  :title="$t('message.comprehensive')"
                >
                  <grid-outline
                    class="keepfly-font keepfly-font--navHot NavItem_icon"
                  ></grid-outline>
                  <span class="NavItem_text">{{ $t("message.comprehensive") }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" v-if="navTab8Show == 1">
          <div>
            <div class="Scroll_container">
              <div class="Scroll_wrap">
                <div>
                  <div class="vue-recycle-scroller ready page-mode direction-vertical">
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div class="" v-if="microblogs.length > 0">
                        <div
                          class="wbpro-scroller-item"
                          v-for="item in microblogs"
                          :key="item"
                        >
                          <article
                            class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                            style="border-radius: 4px"
                          >
                            <div class="Feed_body">
                              <header class="keepfly-box-flex">
                                <a class="ALink_default" @click="checkUserInfo(item, 1)">
                                  <div
                                    class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                    style="width: 3.25rem; height: 3.25rem"
                                  >
                                    <img :src="item.avatar" class="keepfly-avatar-img" />
                                    <div class="keepfly-avatar-hoverMask"></div>
                                  </div>
                                </a>
                                <div class="keepfly-box-item-flex head_main">
                                  <div
                                    class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                    >
                                      <a class="ALink_default head_cut head_name">
                                        <span :title="item.nickname">{{
                                          item.nickname
                                        }}</span>
                                      </a>
                                    </div>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter head-info_info"
                                    >
                                      <a :title="item.creatTime" class="head-info_time">
                                        {{ item.creatTime }}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="keepfly-box-flex"
                                  v-if="item.uid == infodata.id"
                                >
                                  <button
                                    class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                  >
                                    <span class="keepfly-button-wrap">
                                      <span class="keepfly-button-content">
                                        <svg
                                          style="width: 19px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                                <div
                                  class="keepfly-box-flex"
                                  v-else-if="item.friendUid == null"
                                >
                                  <button
                                    @click="addfriend(item, 2)"
                                    class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                  >
                                    <span class="keepfly-button-wrap">
                                      <span class="keepfly-button-content">
                                        <add
                                          class="keepfly-font keepfly-font--add"
                                          style="width: 12px"
                                        ></add>
                                        <!-- {{ $t("message.follow") }} -->
                                        <svg
                                          style="width: 19px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                                <div class="keepfly-box-flex" v-else>
                                  <button
                                    @click="cancelfollow(item, 2)"
                                    class="keepfly-button-main keepfly-button-line keepfly-button-primary keepfly-button-s keepfly-button-round follow-btn_followbtn"
                                  >
                                    <span class="keepfly-button-wrap">
                                      <span class="keepfly-button-content">
                                        {{ $t("message.tips32") }}
                                        <svg
                                          style="width: 19px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          viewBox="0 0 640 512"
                                        >
                                          <path
                                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104l-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </header>
                              <div class="wbpro-feed-content">
                                <div class="detail_text detail_ogText wbpro-feed-ogText">
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentAr"
                                    v-html="filters(item.contentAr)"
                                  ></div>
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentEn"
                                    v-html="filters(item.contentEn)"
                                  ></div>
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentJa"
                                    v-html="filters(item.contentJa)"
                                  ></div>
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentKo"
                                    v-html="filters(item.contentKo)"
                                  ></div>
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentRu"
                                    v-html="filters(item.contentRu)"
                                  ></div>
                                  <div
                                    class="detail_wbtext"
                                    v-if="item.contentZh"
                                    v-html="filters(item.contentZh)"
                                  ></div>
                                </div>
                                <div class="picture picture-box_row">
                                  <span
                                    v-for="titem in item.tags"
                                    :key="titem"
                                    style="
                                      color: #ff8200;
                                      padding-right: 10px;
                                      font-size: 12px;
                                    "
                                    :title="titem"
                                  >
                                    {{ uqTxtTranslate(titem) }}
                                  </span>
                                </div>
                                <div class="picture picture-box_row">
                                  <n-image-group>
                                    <n-space>
                                      <n-image
                                        width="133"
                                        height="133"
                                        v-for="oitem in item.images"
                                        :key="oitem"
                                        :src="oitem"
                                        style="border-radius: 15px"
                                      />
                                    </n-space>
                                  </n-image-group>
                                </div>
                              </div>
                            </div>
                            <footer aria-label="3233,88,4280" class="">
                              <div
                                class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                              >
                                <div
                                  class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                  @click="handleConfirm(item.id)"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  >
                                    <div class="keepfly-pop-wrap">
                                      <span class="keepfly-pop-ctrl">
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                        >
                                          <div
                                            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                          >
                                            <share-outline
                                              class="keepfly-font keepfly-font--retweet toolbar_retweetIcon"
                                            ></share-outline>
                                          </div>
                                          <span class="toolbar_num">{{
                                            item.fnum == null ? 0 : item.fnum
                                          }}</span>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                  @click="getCommentView(item, 1)"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  >
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                    >
                                      <ellipsis-horizontal-circle
                                        class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                      ></ellipsis-horizontal-circle>
                                    </div>
                                    <span class="toolbar_num">
                                      {{ item.cnum == null ? 0 : item.cnum }}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="keepfly-box-item-flex toolbar_item toolbar_cursor"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                  >
                                    <button
                                      @click="likeClick(item)"
                                      class="keepfly-like-main toolbar_btn"
                                    >
                                      <svg
                                        style="width: 19px"
                                        class="keepfly-like-iconWrap"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 32 32"
                                      >
                                        <path
                                          d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                      <span class="keepfly-like-count">{{
                                        item.lnum == null ? 0 : item.lnum
                                      }}</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </footer>
                            <div v-if="item.show">
                              <div
                                class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                              ></div>
                              <div class="Feed_box">
                                <div class="keepfly-box-flex Feed_mar2">
                                  <div
                                    class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                    style="width: 2.125rem; height: 2.125rem"
                                  >
                                    <img
                                      :src="infodata.avatar"
                                      class="keepfly-avatar-img"
                                    />
                                    <div class="keepfly-avatar-hoverMask"></div>
                                  </div>
                                  <div class="keepfly-box-item-flex">
                                    <div>
                                      <div>
                                        <div
                                          class="wbpro-form Form_wbproform"
                                          compser="true"
                                        >
                                          <textarea
                                            :placeholder="$t('message.tips31')"
                                            v-model="item.commentValue"
                                            maxlength="100"
                                            class="Form_input"
                                            style="height: 84px"
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div class="Composer_mar1">
                                        <div
                                          class="keepfly-box-flex keepfly-box-alignCenter"
                                        >
                                          <button
                                            @click="sendInfoComment(item)"
                                            class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                          >
                                            <span class="keepfly-button-wrap">
                                              <span class="keepfly-button-content">{{
                                                $t("message.comment")
                                              }}</span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                                ></div>
                                <div>
                                  <div>
                                    <div class="RepostCommentFeed_mar1">
                                      <div class="wbpro-list">
                                        <div
                                          class="item1"
                                          v-for="oitem in item.commentVos"
                                          :key="oitem"
                                        >
                                          <div class="item1in keepfly-box-flex">
                                            <div>
                                              <a
                                                class="ALink_default"
                                                @click="checkUserInfo(oitem, 2)"
                                              >
                                                <div
                                                  class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                  style="
                                                    width: 2.125rem;
                                                    height: 2.125rem;
                                                  "
                                                >
                                                  <img
                                                    :src="oitem.fromUser.avatar"
                                                    class="keepfly-avatar-img"
                                                  />
                                                </div>
                                              </a>
                                            </div>
                                            <div class="con1 keepfly-box-item-flex">
                                              <div class="text">
                                                <a class="ALink_default">{{
                                                  oitem.fromUser.nickname
                                                }}</a>
                                                <a class="ALink_none" target="_blank"></a
                                                >:
                                                <span v-if="oitem.contentAr">{{
                                                  oitem.contentAr
                                                }}</span>
                                                <span v-if="oitem.contentEn">{{
                                                  oitem.contentEn
                                                }}</span>
                                                <span v-if="oitem.contentJa">{{
                                                  oitem.contentJa
                                                }}</span>
                                                <span v-if="oitem.contentKo">{{
                                                  oitem.contentKo
                                                }}</span>
                                                <span v-if="oitem.contentRu">{{
                                                  oitem.contentRu
                                                }}</span>
                                                <span v-if="oitem.contentZh">{{
                                                  oitem.contentZh
                                                }}</span>
                                              </div>
                                              <div
                                                class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                              >
                                                <div>
                                                  {{ oitem.createDate }}
                                                </div>
                                                <div class="opt keepfly-box-flex">
                                                  <div
                                                    class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                  >
                                                    <button
                                                      class="keepfly-like-main IconList_likebox"
                                                      @click="commentVoslikeClick(oitem)"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style="width: 22px"
                                                        class="keepfly-like-iconWrap"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        viewBox="0 0 32 32"
                                                      >
                                                        <path
                                                          d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                          fill="currentColor"
                                                        ></path>
                                                      </svg>
                                                      <span class="keepfly-like-count">{{
                                                        oitem.lnum == null
                                                          ? 0
                                                          : oitem.lnum
                                                      }}</span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                    ></div>
                                    <a
                                      class="ALink_default"
                                      @click="morecommentVos(item)"
                                    >
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                      >
                                        <div
                                          v-if="
                                            item.commentVos != null &&
                                            item.commentVos.length < item.total
                                          "
                                          style="color: #ccc"
                                        >
                                          {{ $t("message.more") }}
                                        </div>
                                        <div style="color: #ccc" v-else>
                                          {{ $t("message.tips74") }}
                                        </div>
                                        <i
                                          class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                        ></i>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                      <div v-else>
                        <div style="background: #fff; padding: 40px 10px">
                          <n-empty :description="$t('message.none')">
                            <template #extra></template>
                          </n-empty>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Main_side">
          <div id="__sidebar" class="Main_sideMain" style="min-height: 280px">
            <div class="Side_sideBox Side_posSticky" style="top: 65px">
              <div class="wbpro-side-main SideIndex_sideMain">
                <div>
                  <div
                    class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
                  >
                    <div>
                      <div
                        class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter"
                      >
                        <div
                          class="f14 cla keepfly-box-item-flex"
                          style="align-self: center"
                        >
                          Keepfly {{ $t("message.hot_search") }}
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter"
                          @click="refreshlist"
                        >
                          <refresh
                            class="keepfly-font f14 clb keepfly-font--refresh"
                            style="width: 17px"
                          ></refresh>
                          <span class="f12 clb">{{ $t("message.click_refresh") }}</span>
                        </div>
                      </div>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <n-spin :show="hotshow">
                        <div
                          class="wbpro-side-card7"
                          v-for="(item, index) in hotmicroblogs"
                          :key="item"
                        >
                          <div class="wbpro-side-panel">
                            <a class="ALink_default" target="_blank">
                              <div class="con keepfly-box-flex keepfly-box-alignCenter">
                                <!-- <div class="rank top" v-if="index == 0">
                                  <svg
                                    class="keepfly-font keepfly-font--top2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 0 0-12.6 0l-112 141.7a7.98 7.98 0 0 0 6.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div> -->
                                <div class="rank num" v-if="index == 0">1</div>
                                <div class="rank num" v-if="index == 1">2</div>
                                <div class="rank num" v-if="index == 2">3</div>
                                <div class="rank" v-if="index >= 3">{{ index + 1 }}</div>
                                <div class="wbpro-textcut f12 cla">
                                  <span v-if="item.topicTitle">{{
                                    item.topicTitle
                                  }}</span>
                                </div>
                              </div>
                              <div class="keepfly-divider-main keepfly-divider-x"></div>
                            </a>
                          </div>
                        </div>
                      </n-spin>
                      <div class="keepfly-divider-main keepfly-divider-x"></div>
                      <a class="ALink_default">
                        <button
                          @click="joinHot"
                          class="wbpro-side-btn f12 clb keepfly-button-main keepfly-button-simple keepfly-button-default keepfly-button-m keepfly-button-round keepfly-button-fluid"
                        >
                          <span class="keepfly-button-wrap">
                            <span class="keepfly-button-content">
                              {{ $t("message.tips26") }}
                            </span>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="wbpro-side-copy">
                  <div class="wbpro-side-copy-inner">
                    <div class="scale">
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i class="keepfly-font keepfly-font--help CopyRight_icon"></i>
                          <div>{{ $t("message.help_center") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank" class="">
                            keepfly {{ $t("message.customer") }}
                            {{ kukesystemInfo.serviceNumber }}
                          </a>
                          <a
                            @click="$router.push('/selfservice')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.self_service_center") }}
                          </a>
                          <a
                            @click="$router.push('/website')"
                            target="_blank"
                            class="CopyRight_contentItem"
                            style="cursor: pointer"
                          >
                            {{ $t("message.website_related") }}
                          </a>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <a target="_blank">
                            {{ $t("message.tips107") }}
                            {{ kukesystemInfo.serviceEmail }}
                          </a>
                        </div>
                      </div>
                      <div class="CopyRight_item">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter CopyRight_title"
                        >
                          <i
                            class="keepfly-font keepfly-font--cooperation CopyRight_icon"
                          ></i>
                          <div>{{ $t("message.tips27") }}</div>
                        </div>
                        <div
                          class="keepfly-box-flex keepfly-box-justifyBetween CopyRight_content"
                        >
                          <span
                            >{{ $t("message.cooperation_hotline") }}
                            {{ kukesystemInfo.hotLine }}</span
                          >
                          <div
                            class="link keepfly-pop-wrap"
                            @click="$router.push('/document')"
                          >
                            <span class="keepfly-pop-ctrl">
                              <span class="CopyRight_contentItem">
                                {{ $t("message.more") }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="CopyRight_item"
                        style="margin-top: 46px; text-align: center; color: darkgray"
                      >
                        <p>{{ $t("message.tips108") }}</p>
                        <p>{{ $t("message.tips101") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <n-modal
      v-model:show="showUserInfoModal"
      class="custom-card"
      preset="card"
      :style="bodyUserInfoStyle"
      :title="$t('message.userinfo')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
      @scroll="userInfoRef($event)"
      style="overflow-y: scroll; height: 100vh"
    >
      <div>
        <div
          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div class="wbpro-pos">
            <div class="keepfly-picture-main ProfileHeader_pic">
              <div style="padding-bottom: 56.25%"></div>
              <img
                src="../../assets/70ace9b7ly1ggzusnypoej20yi0yiaop.jpg"
                class="keepfly-picture-img"
              />
              <div class="keepfly-picture-cover"></div>
            </div>
          </div>
          <div class="keepfly-box-flex keepfly-box-alignStart ProfileHeader_box1">
            <div
              class="keepfly-avatar-main keepfly-avatar-hover ProfileHeader_avatar2"
              style="width: 6.25rem; height: 6.25rem"
            >
              <img
                src="../../assets/default_avatar_male_180.gif"
                class="keepfly-avatar-img"
                v-if="keepflyUserInfo.avatar == null"
              />
              <img :src="keepflyUserInfo.avatar" class="keepfly-avatar-img" v-else />
              <div class="keepfly-avatar-hoverMask"></div>
            </div>
            <div class="keepfly-box-item-flex" style="overflow: hidden">
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h3">
                <div class="ProfileHeader_name">
                  {{ keepflyUserInfo.nickname }}
                </div>
                <span class="keepfly-icon-wrap" style="margin-left: 5px">
                  <male
                    class="keepfly-icon-main keepfly-icon--male"
                    v-if="keepflyUserInfo.gender == 1"
                    style="width: 1rem; height: 1rem"
                  ></male>
                  <female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else-if="keepflyUserInfo.gender == 2"
                    style="width: 1rem; height: 1rem"
                  >
                  </female>
                  <male-female
                    class="keepfly-icon-main keepfly-icon--male"
                    v-else
                    style="width: 1rem; height: 1rem"
                  ></male-female>
                </span>
              </div>
              <div class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4">
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.fans") }}<span>{{ userNum.ffnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.follow") }}<span>{{ userNum.fnum }}</span>
                  </span>
                </a>
                <a class="ALink_none ProfileHeader_alink ProfileHeader_pointer">
                  <span>
                    {{ $t("message.effect") }}<span>{{ userNum.num }}</span>
                  </span>
                </a>
              </div>
              <div
                class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_h4"
                style="font-size: 13px"
              >
                ID：{{ keepflyUserInfo.kpId }}
              </div>
            </div>

            <div style="margin-right: 26px; padding-top: 33px">
              <n-button
                v-if="keepflyUserInfo.id == infodata.id"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <!-- <template #icon>
                  <n-icon>
                    <add />
                  </n-icon>
                </template> -->
                <!-- {{ $t("message.follow") }} -->
                <span class="keepfly-button-content">
                  <svg
                    style="width: 19px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                      fill="currentColor"
                    ></path></svg
                  >{{ $t("message.self") }}
                </span>
              </n-button>
              <n-button
                v-else-if="keepflyUserInfo.friendUid == null"
                @click="addfriend(keepflyUserInfo, 2)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <svg
                    style="width: 19px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zM9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm6.39 8.56C13.71 13.7 11.53 13 9 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 1 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM15 18H3v-.78c0-.38.2-.72.52-.88C4.71 15.73 6.63 15 9 15c2.37 0 4.29.73 5.48 1.34c.32.16.52.5.52.88V18z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </template>
                <!-- {{ $t("message.follow") }} -->
                <span class="keepfly-button-content">
                  {{ $t("message.follow") }}
                </span>
              </n-button>
              <n-button
                v-else
                @click="cancelfollow(keepflyUserInfo, 2)"
                style="background: rgb(255, 130, 0); color: #fff"
                strong
                secondary
                round
                size="small"
              >
                <template #icon>
                  <svg
                    style="width: 19px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104l-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </template>
                {{ $t("message.tips32") }}
              </n-button>
              &nbsp;&nbsp;
              <n-button
                style="background: purple; color: #fff"
                strong
                secondary
                round
                size="small"
                @click="sendprivateletter(keepflyUserInfo)"
              >
                <template #icon>
                  <n-icon>
                    <chatbubbles-outline />
                  </n-icon>
                </template>
                {{ $t("message.private_letter") }}
              </n-button>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="ProfileHeader_box2">
              <div class="keepfly-box-flex keepfly-box-justifyBetween">
                <div class="keepfly-box-flex keepfly-box-column ProfileHeader_content">
                  <div class="" style="margin-bottom: 12px">
                    <div class="keepfly-box-item-inlineBlock">
                      <div class="keepfly-box-flex keepfly-box-alignStart">
                        <div
                          class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                        >
                          <document-text-outline
                            class="keepfly-font keepfly-font--proBintro"
                          ></document-text-outline>
                        </div>
                        <div class="keepfly-box-item-flex ProfileHeader_con3">
                          {{
                            keepflyUserInfo.profile == null ||
                            keepflyUserInfo.profile == ""
                              ? $t("message.tips16")
                              : keepflyUserInfo.profile
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ProfileHeader_box3"
              v-if="keepflyUserInfo.birthday || keepflyUserInfo.emotionalStatus"
            >
              <div
                v-if="keepflyUserInfo.birthday"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path
                          d="M12 1c-.95 0-1.585.682-1.937 1.282c-.361.619-.563 1.374-.563 1.968c0 .494.063 1.174.432 1.746C10.342 6.63 11.038 7 12 7s1.658-.37 2.068-1.004c.37-.572.432-1.252.432-1.746c0-.594-.202-1.35-.563-1.968C13.586 1.682 12.95 1 12 1zm-1 3.25c0-.31.121-.805.358-1.21c.248-.424.487-.54.642-.54c.155 0 .394.116.642.54c.237.405.358.9.358 1.21c0 .41-.062.73-.193.932c-.09.14-.27.318-.807.318c-.538 0-.717-.178-.807-.318C11.063 4.98 11 4.66 11 4.25zm9.5 6v8.25h.75a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5h.75v-8.25A2.25 2.25 0 0 1 5.75 8h12.5a2.25 2.25 0 0 1 2.25 2.25zm-15.5 0v2.091l2.447 1.58a1.25 1.25 0 0 0 1.356 0l1.747-1.128a2.75 2.75 0 0 1 2.9-.05l2.009 1.199a1.25 1.25 0 0 0 1.359-.05L19 12.36v-2.11a.75.75 0 0 0-.75-.75H5.75a.75.75 0 0 0-.75.75zm12.68 4.87a2.75 2.75 0 0 1-2.99.11l-2.008-1.2a1.25 1.25 0 0 0-1.319.023l-1.747 1.128a2.75 2.75 0 0 1-2.982 0L5 14.126V18.5h14v-4.307l-1.32.926z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.birthday }}
                  </div>
                </div>
              </div>
              <div
                v-if="keepflyUserInfo.emotionalStatus"
                class="keepfly-box-item-inlineBlock ProfileHeader_item3"
              >
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d="M26 30h-4a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.946 2.946 0 0 1 3-3h6a2.946 2.946 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2zm-5-18a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M24 9a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M10 20.184V12H8v8.184a3 3 0 1 0 2 0z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M9 30a6.993 6.993 0 0 1-5-11.89V7a5 5 0 0 1 10 0v11.11A6.993 6.993 0 0 1 9 30zM9 4a3.003 3.003 0 0 0-3 3v11.983l-.332.299a5 5 0 1 0 6.664 0L12 18.983V7a3.003 3.003 0 0 0-3-3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ checkfeel(keepflyUserInfo.emotionalStatus) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.hometown">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none">
                        <path
                          d="M8.998 2.389a1.5 1.5 0 0 1 2.005 0l5.5 4.941A1.5 1.5 0 0 1 17 8.446V9.4a2.98 2.98 0 0 0-1-.36v-.595a.5.5 0 0 0-.166-.372l-5.5-4.942a.5.5 0 0 0-.668 0l-5.5 4.942A.5.5 0 0 0 4 8.446V15.5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5V12A1.5 1.5 0 0 1 9 10.5h2a1.5 1.5 0 0 1 1.5 1.5v2.208a2.51 2.51 0 0 0-1 .792v-3a.5.5 0 0 0-.5-.5H9a.5.5 0 0 0-.5.5v3.5A1.5 1.5 0 0 1 7 17H4.5A1.5 1.5 0 0 1 3 15.5V8.446c0-.426.18-.831.498-1.116l5.5-4.941zM17.5 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0zm1.5 4.5c0 1.245-1 2.5-3.5 2.5S12 17.75 12 16.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    class="keepfly-box-item-flex ProfileHeader_con3"
                    style="width: 110px"
                  >
                    {{ keepflyUserInfo.hometown }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3" v-if="keepflyUserInfo.address">
              <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                <div class="keepfly-box-flex keepfly-box-alignStart">
                  <div
                    class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                  >
                    <svg
                      style="width: 19px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="keepfly-box-item-flex ProfileHeader_con3">
                    {{ keepflyUserInfo.address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ProfileHeader_box3">
              <div style="position: relative">
                <div class="keepfly-box-item-inlineBlock ProfileHeader_item3">
                  <div class="keepfly-box-flex keepfly-box-alignStart">
                    <div
                      class="keepfly-box-flex keepfly-box-alignCenter ProfileHeader_icon3"
                    >
                      <calendar-outline
                        class="keepfly-font keepfly-font--proTime"
                      ></calendar-outline>
                    </div>
                    <div
                      class="keepfly-box-item-flex ProfileHeader_con3"
                      v-if="keepflyUserInfo.createTime"
                    >
                      {{ keepflyUserInfo.createTime.substring(0, 10) }}
                      {{ $t("message.add") }} keepfly
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    color: darkgray;
                    width: 70px;
                    height: 32px;
                    top: 0;
                    right: 20px;
                    cursor: pointer;
                  "
                  class="ProfileHeader_con3"
                  @click="senduserblacklist(keepflyUserInfo)"
                >
                  {{ $t("message.tips122") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="wbpro-screen-v2 keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
        >
          <div>{{ $t("message.all") }} keepfly（{{ userNum.mnum }}）</div>
        </div>
        <div>
          <div class="Scroll_container">
            <div class="Scroll_wrap">
              <div>
                <div
                  class="vue-recycle-scroller ready page-mode direction-vertical"
                  id="scroller"
                >
                  <div
                    class="vue-recycle-scroller__item-wrapper"
                    style="min-height: 684px"
                  >
                    <div class="">
                      <div
                        class="wbpro-scroller-item"
                        v-for="item in usermicroblogs"
                        :key="item"
                      >
                        <article
                          class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Feed_wrap Feed_normal"
                          tabindex="0"
                          style="border-radius: 4px"
                        >
                          <div class="Feed_body">
                            <header class="keepfly-box-flex">
                              <a class="ALink_default">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover head_avatar"
                                  style="width: 3.25rem; height: 3.25rem"
                                >
                                  <img :src="item.avatar" class="keepfly-avatar-img" />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                              </a>
                              <div class="keepfly-box-item-flex head_main">
                                <div
                                  class="keepfly-box-flex keepfly-box-column keepfly-box-justifyCenter head_content_wrap"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter head_nick"
                                  >
                                    <a class="ALink_default head_cut head_name">
                                      <span :title="item.nickname">{{
                                        item.nickname
                                      }}</span>
                                    </a>
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter"
                                    ></div>
                                  </div>
                                  <div
                                    class="keepfly-box-alignCenter keepfly-box-justifyCenter head-info_info"
                                  >
                                    <a class="head-info_time">
                                      {{ item.createTime }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="keepfly-box-flex">
                                <div class="keepfly-pop-wrap morepop_more">
                                  <span class="keepfly-pop-ctrl">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter morepop_moreIcon"
                                    >
                                      <chevron-down
                                        class="keepfly-font keepfly-font--angleDown morepop_action"
                                        style="width: 16px"
                                      ></chevron-down>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </header>
                            <div class="Feed_retweet">
                              <div class="detail_text detail_reText wbpro-feed-reText">
                                <div
                                  class="detail_wbtext"
                                  v-html="filters(item.content)"
                                ></div>
                              </div>
                              <div class="picture picture-box_row">
                                <span
                                  v-for="titem in item.tags"
                                  :key="titem"
                                  style="
                                    color: #ff8200;
                                    padding-right: 10px;
                                    font-size: 12px;
                                  "
                                  :title="titem"
                                >
                                  {{ uqTxtTranslate(titem) }}
                                </span>
                              </div>
                              <div
                                class="picture picture-box_row"
                                v-if="item.images != []"
                              >
                                <div class="u-col-3 keepfly-box-wrap picture_inlineNum3">
                                  <n-image-group>
                                    <n-space>
                                      <n-image
                                        width="133"
                                        height="133"
                                        v-for="oitem in item.images"
                                        :key="oitem"
                                        :src="oitem"
                                        style="border-radius: 15px"
                                      />
                                    </n-space>
                                  </n-image-group>
                                </div>
                              </div>
                              <div v-if="item.videoInfo">
                                <video
                                  controls="controls"
                                  :src="item.videoInfo"
                                  class="video"
                                  style="
                                    width: 400px;
                                    vertical-align: middle;
                                    height: 225px;
                                    background: #000;
                                    border-radius: 10px;
                                  "
                                ></video>
                              </div>
                            </div>
                          </div>
                          <footer class="">
                            <div
                              class="keepfly-box-flex keepfly-box-alignCenter toolbar_left toolbar_main"
                            >
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  @click="getCommentView(item, 1)"
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_wrap"
                                  style="cursor: pointer"
                                >
                                  <div
                                    class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_iconWrap"
                                  >
                                    <ellipsis-horizontal-circle
                                      class="keepfly-font keepfly-font--comment toolbar_commentIcon"
                                    ></ellipsis-horizontal-circle>
                                  </div>
                                  <span class="toolbar_num"> {{ item.cnum }} </span>
                                </div>
                              </div>
                              <div
                                class="keepfly-box-item-flex toolbar_item_1ky_D toolbar_cursor"
                              >
                                <div
                                  class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter toolbar_likebox toolbar_wrap"
                                >
                                  <button
                                    @click="likeClick(item)"
                                    class="keepfly-like-main toolbar_btn"
                                  >
                                    <svg
                                      style="width: 19px"
                                      class="keepfly-like-iconWrap"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 32 32"
                                    >
                                      <path
                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <span class="keepfly-like-count">{{
                                      item.lnum
                                    }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </footer>
                          <div v-if="item.show">
                            <div
                              class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                            ></div>
                            <div class="Feed_box">
                              <div class="keepfly-box-flex Feed_mar2">
                                <div
                                  class="keepfly-avatar-main keepfly-avatar-hover MainComposer_mar2"
                                  style="width: 2.125rem; height: 2.125rem"
                                >
                                  <img
                                    :src="infodata.avatar"
                                    class="keepfly-avatar-img"
                                  />
                                  <div class="keepfly-avatar-hoverMask"></div>
                                </div>
                                <div class="keepfly-box-item-flex">
                                  <div>
                                    <div>
                                      <div
                                        class="wbpro-form Form_wbproform"
                                        compser="true"
                                      >
                                        <textarea
                                          :placeholder="$t('message.tips31')"
                                          v-model="item.commentValue"
                                          maxlength="100"
                                          class="Form_input"
                                          style="height: 84px"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="Composer_mar1">
                                      <div
                                        class="keepfly-box-flex keepfly-box-alignCenter"
                                      >
                                        <button
                                          @click="sendInfoComment(item)"
                                          class="disabled keepfly-button-main keepfly-button-flat keepfly-button-primary keepfly-button-m keepfly-button-round Composer_btn"
                                        >
                                          <span class="keepfly-button-wrap">
                                            <span class="keepfly-button-content">{{
                                              $t("message.comment")
                                            }}</span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="keepfly-divider-main keepfly-divider-x Feed_mar1"
                              ></div>
                              <div>
                                <div>
                                  <div class="RepostCommentFeed_mar1">
                                    <div class="wbpro-list">
                                      <div
                                        class="item1"
                                        v-for="oitem in item.commentVos"
                                        :key="oitem"
                                      >
                                        <div class="item1in keepfly-box-flex">
                                          <div>
                                            <a class="ALink_default">
                                              <div
                                                class="keepfly-avatar-main keepfly-avatar-hover Comment_cursor"
                                                style="width: 2.125rem; height: 2.125rem"
                                              >
                                                <img
                                                  :src="oitem.fromUser.avatar"
                                                  class="keepfly-avatar-img"
                                                />
                                              </div>
                                            </a>
                                          </div>
                                          <div class="con1 keepfly-box-item-flex">
                                            <div class="text">
                                              <a class="ALink_default">{{
                                                oitem.fromUser.nickname
                                              }}</a>
                                              <a class="ALink_none" target="_blank"></a>:
                                              <span v-if="oitem.contentAr">{{
                                                oitem.contentAr
                                              }}</span>
                                              <span v-if="oitem.contentEn">{{
                                                oitem.contentEn
                                              }}</span>
                                              <span v-if="oitem.contentJa">{{
                                                oitem.contentJa
                                              }}</span>
                                              <span v-if="oitem.contentKo">{{
                                                oitem.contentKo
                                              }}</span>
                                              <span v-if="oitem.contentRu">{{
                                                oitem.contentRu
                                              }}</span>
                                              <span v-if="oitem.contentZh">{{
                                                oitem.contentZh
                                              }}</span>
                                            </div>
                                            <div
                                              class="info keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween"
                                            >
                                              <div>
                                                {{ oitem.createDate }}
                                              </div>
                                              <div class="opt keepfly-box-flex">
                                                <div
                                                  class="wbpro-iconbed keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter IconList_autoIcon"
                                                >
                                                  <button
                                                    class="keepfly-like-main IconList_likebox"
                                                    :title="$t('message.praise')"
                                                    @click="commentVoslikeClick(oitem)"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      style="width: 22px"
                                                      class="keepfly-like-iconWrap"
                                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                                      viewBox="0 0 32 32"
                                                    >
                                                      <path
                                                        d="M26 12h-6V6a3.003 3.003 0 0 0-3-3h-2.133a2.01 2.01 0 0 0-1.98 1.717l-.845 5.917L8.465 16H2v14h21a7.008 7.008 0 0 0 7-7v-7a4.005 4.005 0 0 0-4-4zM8 28H4V18h4zm20-5a5.006 5.006 0 0 1-5 5H10V17.303l3.958-5.937l.91-6.366H17a1 1 0 0 1 1 1v8h8a2.002 2.002 0 0 1 2 2z"
                                                        fill="currentColor"
                                                      ></path>
                                                    </svg>
                                                    <span class="keepfly-like-count">{{
                                                      oitem.lnum == null ? 0 : oitem.lnum
                                                    }}</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="keepfly-divider-main keepfly-divider-x RepostCommentFeed_divider"
                                  ></div>
                                  <a class="ALink_default" @click="morecommentVos(item)">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter RepostCommentFeed_more"
                                    >
                                      <div
                                        v-if="
                                          item.commentVos != null &&
                                          item.commentVos.length < item.total
                                        "
                                        style="color: #ccc"
                                      >
                                        {{ $t("message.more") }}
                                      </div>
                                      <div style="color: #ccc" v-else>
                                        {{ $t("message.tips74") }}
                                      </div>
                                      <i
                                        class="keepfly-font keepfly-font--angleRight RepostCommentFeed_icon2"
                                      ></i>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, getCurrentInstance, watchEffect } from "vue";
import API from "../../uilts/request";
import i18n from "../../i18n/index";
import {
  GridOutline,
  Add,
  EllipsisHorizontalCircle,
  ShareOutline,
  ChatbubblesOutline,
  DocumentTextOutline,
  CalendarOutline,
  ChevronDown,
} from "@vicons/ionicons5";
import {
  NEmpty,
  useMessage,
  useDialog,
  NModal,
  NButton,
  NIcon,
  NSpin,
  NImageGroup,
  NSpace,
  NImage,
} from "naive-ui";
import bus from "../../uilts/bus.js";
const feelOptions = [
  {
    label: i18n.global.t("message.single"),
    value: 1001,
  },
  {
    label: i18n.global.t("message.seeking_a_relationship"),
    value: 1002,
  },
  {
    label: i18n.global.t("message.in_secret_love"),
    value: 1003,
  },
  {
    label: i18n.global.t("message.ambiguous"),
    value: 1004,
  },
  {
    label: i18n.global.t("message.in_love"),
    value: 1005,
  },
  {
    label: i18n.global.t("message.engaged"),
    value: 1006,
  },
  {
    label: i18n.global.t("message.married"),
    value: 1007,
  },
  {
    label: i18n.global.t("message.separated"),
    value: 1008,
  },
  {
    label: i18n.global.t("message.divorced"),
    value: 1009,
  },
  {
    label: i18n.global.t("message.widow"),
    value: 1010,
  },
  {
    label: i18n.global.t("message.not_selected"),
    value: 1011,
  },
];
export default defineComponent({
  name: "SearchView",
  components: {
    NEmpty,
    GridOutline,
    Add,
    EllipsisHorizontalCircle,
    ShareOutline,
    NModal,
    NButton,
    NIcon,
    ChatbubblesOutline,
    DocumentTextOutline,
    CalendarOutline,
    ChevronDown,
    NSpin,
    NImageGroup,
    NSpace,
    NImage,
  },
  props: ["context"],
  setup(props, context) {
    const message = useMessage();
    const dialog = useDialog();
    const flat0 = ref(false);
    const txt = ref("");
    const kukesystemInfo = ref({});
    onMounted(() => {
      infodata.value = JSON.parse(window.localStorage.getItem("infodata"));
      txt.value = props.context;
      getmicrobloglist(txt.value);
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      gethotmicrobloglist();
    });
    watchEffect(() => {
      txt.value = props.context;
    });
    const navTab8Show = ref(1);
    const setNavTab8 = (e) => {
      navTab8Show.value = e;
    };
    const currPage = ref(1);
    const pageSize = ref(5);
    const microblogs = ref([]);
    const mipages = ref(0);
    const getmicrobloglist = (e) => {
      let param = {
        language: localStorage.getItem("language"),
        currPage: currPage.value,
        pageSize: pageSize.value,
        tagtxt: e,
      };
      API({
        url: "/kuke/api/user/getmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        let qrr = [];
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          qrr.push(arr[i].id);
        }
        microblogs.value = arr;
        mipages.value = result.data.data.pages;
        flat0.value = true;
        setMicroblogView(qrr);
      });
    };
    const topicTranslateList = ref([]);
    const setMicroblogView = (e) => {
      if (e.length == 0) {
        return false;
      }
      let params = {
        language: localStorage.getItem("language"),
        tags: e,
      };
      API({
        url: "/kuke/api/index/setMicroblogView",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: params,
      }).then((result) => {
        let arr = result.data.data;
        for (let i = 0; i < arr.length; i++) {
          topicTranslateList.value.push(arr[i]);
        }
      });
    };
    const uqTxtTranslate = (e) => {
      let orr = topicTranslateList.value;
      for (let i = 0; i < orr.length; i++) {
        if (orr[i].topicTitle == e) {
          return "#" + orr[i].topicTranslate;
        }
      }
    };
    const emoticon = getCurrentInstance()?.appContext.config.globalProperties.$emoticon;
    function filters(txt) {
      for (let i = 0; i < emoticon.length; i++) {
        if (
          txt.includes("[" + emoticon[i].value + "]") ||
          txt.includes(" [ " + emoticon[i].value + " ] ")
        ) {
          txt = txt.replace(
            "[" + emoticon[i].value + "]",
            '<img src="' + emoticon[i].path + '" />'
          );
        }
      }
      return txt;
    }
    const currhotPage = ref(1);
    const pagehotSize = ref(8);
    const hotmicroblogs = ref([]);
    const gethotmicrobloglist = () => {
      hotshow.value = true;
      let param = {
        language: localStorage.getItem("language"),
        currPage: currhotPage.value,
        pageSize: pagehotSize.value,
      };
      API({
        url: "/kuke/api/user/gethotmicrobloglist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        hotmicroblogs.value = arr;
        hotshow.value = false;
      });
    };
    const hotshow = ref(false);
    const joinHot = () => {
      context.emit("eventParent", 2);
      bus.emit("sendToBrother", 2);
      window.localStorage.setItem("sendTohuati", 2);
    };
    const refreshlist = () => {
      gethotmicrobloglist();
    };
    const addfriend = (e, i) => {
      let param = {};
      if (i == 1) {
        param = {
          userid: e.userId,
        };
      } else if (i == 2) {
        param = {
          userid: e.uid,
        };
      }
      API({
        url: "/kuke/api/user/addfriend",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.friendUid = 1;
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips32"));
        } else if (result.data.code == 606) {
          message.success(i18n.global.t("message.tips34"));
        } else {
          message.error(i18n.global.t("message.tips30"));
        }
      });
    };
    const handleConfirm = (e) => {
      let param = {
        id: e,
      };
      dialog.warning({
        title: i18n.global.t("message.hint"),
        content: i18n.global.t("message.tips53"),
        positiveText: i18n.global.t("message.determine"),
        onPositiveClick: () => {
          API({
            url: "/kuke/api/user/setmicroblogforwardclick",
            method: "POST",
            data: param,
          }).then((result) => {
            if (result.data.code == 200) {
              message.success(i18n.global.t("message.tips29"));
            } else {
              message.error(i18n.global.t("message.tips30"));
            }
          });
        },
      });
    };
    const likeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/setmicrobloglikeclick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const currAllCommentPage = ref(1);
    const pageAllCommentSize = ref(4);
    const cpages = ref(0);
    const ctotal = ref(0);
    const getCommentView = (e, i) => {
      if (i == 1) {
        e.show = !e.show;
        if (!e.show) {
          return false;
        }
        if (e.current != null) {
          return false;
        }
        currAllCommentPage.value = 1;
      } else {
        currAllCommentPage.value == e.current;
        if (currAllCommentPage.value < cpages.value) {
          ++currAllCommentPage.value;
        }
      }
      let param = {
        language: localStorage.getItem("language"),
        currPage: currAllCommentPage.value,
        pageSize: pageAllCommentSize.value,
      };
      if (e.show) {
        API({
          url: "/kuke/api/index/microblog/" + e.id,
          method: "GET",
          params: param,
        }).then((result) => {
          cpages.value = result.data.data.pages;
          e.total = result.data.data.total;
          e.current = result.data.data.current;
          let arr = result.data.data.commentVos;
          for (let i = 0; i < arr.length; i++) {
            if (!e.commentVos) {
              e.commentVos = [];
            }
            e.commentVos.push(arr[i]);
          }
        });
      }
    };
    const commentValue = ref("");
    const sendInfoComment = (e) => {
      let param = {
        toUid: e.uid,
        microblogId: e.id,
        commentValue: e.commentValue,
      };
      API({
        url: "/kuke/api/user/sendInfoComment",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.commentValue = "";
          if (e.commentVos == null) {
            e.commentVos = [];
            e.commentVos.push(result.data.data);
          } else {
            e.commentVos.unshift(result.data.data);
          }
          message.success(i18n.global.t("message.tips171"));
        } else {
          message.success(i18n.global.t("message.tips172"));
        }
      });
    };
    const infodata = ref({});
    const commentVoslikeClick = (e) => {
      let param = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/commentVoslikeClick",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          e.lnum = e.lnum + 1;
        } else if (result.data.code == 201) {
          if (e.lnum != 0) {
            e.lnum = e.lnum - 1;
          }
        }
      });
    };
    const morecommentVos = (e) => {
      if (currAllCommentPage.value < cpages.value) {
        getCommentView(e, 2);
      }
    };

    const showUserInfoModal = ref(false);
    const keepflyUserInfo = ref({});
    const checkUserInfo = (e, i) => {
      let id = i == 1 ? e.uid : e.fromUser.id;
      API({
        url: "/kuke/api/index/checkUserInfo",
        method: "POST",
        data: {
          id: id,
          trt: window.localStorage.getItem("token"),
        },
      }).then((result) => {
        if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips113"));
          return false;
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips114"));
          return false;
        }
        showUserInfoModal.value = true;
        keepflyUserInfo.value = JSON.parse(JSON.stringify(result.data.data));
        keepflyUserInfo.value.friendUid = e.friendUid;
        keepflyUserInfo.value.userId = e.userId;
        keepflyUserInfo.value.uid = e.uid;
      });
      getUserInfoNum(id);
      usermicroblogs.value = [];
      getmicrobloguserlist(id);
    };
    const userNum = ref({});
    const getUserInfoNum = (e) => {
      API({
        url: "/kuke/api/index/getUserInfoNum",
        method: "POST",
        data: {
          id: e,
        },
      }).then((result) => {
        if (result.data.code == 200) {
          userNum.value = result.data.data;
        }
      });
    };
    const currusermicroblogPage = ref(1);
    const pageusermicroblogSize = ref(4);
    const usermicroblogs = ref([]);
    const mpages = ref(0);
    const getmicrobloguserlist = (e) => {
      let param = {
        currPage: currusermicroblogPage.value,
        pageSize: pageusermicroblogSize.value,
        id: e,
      };
      API({
        url: "/kuke/api/index/getmicrobloguserinfolist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        for (let i = 0; i < arr.length; i++) {
          arr[i].images = JSON.parse(arr[i].images);
          arr[i].tags = JSON.parse(arr[i].tags);
          usermicroblogs.value.push(arr[i]);
        }
        mpages.value = result.data.data.pages;
      });
    };
    const userInfoRef = (e) => {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight >=
        e.srcElement.scrollHeight
      ) {
        if (currusermicroblogPage.value < mpages.value) {
          ++currusermicroblogPage.value;
          getmicrobloguserlist(keepflyUserInfo.value.id);
        }
      }
    };
    const sendprivateletter = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/sendprivateletter",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200 || result.data.code == 603) {
          context.emit("eventParent", 4);
          bus.emit("sendToBrother", 4);
          window.localStorage.setItem("sendTohuati", 4);
        } else if (result.data.code == 601) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 602) {
          message.error(i18n.global.t("message.tips116"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips123"));
        } else if (result.data.code == 605) {
          message.error(i18n.global.t("message.tips124"));
        }
      });
    };
    const senduserblacklist = (e) => {
      let params = {
        id: e.id,
      };
      API({
        url: "/kuke/api/user/senduserblacklist",
        method: "POST",
        data: params,
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips29"));
        } else if (result.data.code == 604) {
          message.error(i18n.global.t("message.tips30"));
        } else if (result.data.code == 605) {
          message.success(i18n.global.t("message.tips120"));
        } else if (result.data.code == 606) {
          message.error(i18n.global.t("message.tips121"));
        }
      });
    };
    function checkfeel(e) {
      let arr = feelOptions;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value == e) {
          return arr[i].label;
        }
      }
    }
    return {
      feelOptions,
      checkfeel,
      senduserblacklist,
      hotshow,
      sendprivateletter,
      usermicroblogs,
      userInfoRef,
      showUserInfoModal,
      keepflyUserInfo,
      checkUserInfo,
      userNum,
      getUserInfoNum,
      morecommentVos,
      commentVoslikeClick,
      infodata,
      ctotal,
      getCommentView,
      commentValue,
      sendInfoComment,
      likeClick,
      handleConfirm,
      addfriend,
      joinHot,
      hotmicroblogs,
      refreshlist,
      getmicrobloglist,
      filters,
      microblogs,
      kukesystemInfo,
      uqTxtTranslate,
      navTab8Show,
      setNavTab8,
      txt,
      segmented: {
        content: "soft",
        footer: "soft",
      },
      bodyUserInfoStyle: {
        width: "900px",
      },
    };
  },
});
</script>
<style></style>
