const ja = {
    message: {
        username: 'ユーザー名',
        password: 'パスワード',
        save: '保存',
        edit:'編集',
        update:'更新',
        delete:'削除',
        currentLanguage: '日本語',
        search: '検索',
        popular: 'ヒット',
        topic: 'トピック',
        goldenSentenceList: '金言ランキング',
        askQuestions: '質問',
        SignIn: 'ログイン',
        register: '登録',
        home: 'トップページ',
        information: 'メッセージ',
        register_tis: 'Keepflyはまだ？',
        register_Now: '今すぐ登録',
        sign_in_now: '今すぐログイン',
        homepage: 'ホーム',
        no_account_yet: 'まだアカウントがありませんか？',
        welcome: 'ようこそ',
        account_number: 'アカウント（メールアドレス）',
        account_register: 'アカウント登録',
        nickname: 'ニックネーム',
        email: 'メールボックス',
        mobile: '携帯番号',
        confirm_password: 'パスワードを確定する',
        verification_code: '認証コード',
        gain: '取得',
        tips1: 'メールボックスに入力してください',
        tips2: 'パスワードを入力してください',
        tips3: 'ニックネームを入力してください',
        tips4: '確定パスワードを入力してください',
        tips5: '2回入力されたパスワードが一致しない',
        tips6: '確定検証コードを入力してください',
        follow_all: 'すべてに注目',
        up_to_date: '金文',
        friends: '友達の輪',
        deceased: '死者管理',
        tips7: '何か新しいことがありますか？',
        emote: '表情',
        picture: 'ピクチャー',
        essay: '文章',
        more: '詳細を表示',
        send: '送信',
        all: 'すべて',
        advanced_search: 'Advanced Search',
        userinfo: 'ユーザー資料',
        log_out: 'ログオンを終了する',
        tips8: 'どんな話題を使いたいのか',
        find_it: '探してみて',
        home_page: 'ホームページ',
        read: '読む',
        discuss: 'ディスカッション',
        click_refresh: 'クリック更新',
        set: '設定',
        edit_data: '資料の編集',
        privacy_setting: 'プライバシー設定',
        tips9: 'アカウント情報設定',
        sign: '署名',
        tips10: "4～30文字、中英数字'をサポートし",
        tips11: '自分を紹介しよう',
        tips12: '1～140文字',
        tips13: '個人基本資料',
        gender: '性別',
        man: '男',
        woman: '女',
        other: 'その他',
        birthday: '誕生日',
        emotional_status: '感情状況',
        tips14: '感情状況を選択する',
        address: '所在地',
        hometown: 'ふるさと',
        single: '独身',
        seeking_a_relationship: '交際を求める',
        in_secret_love: '片思い中',
        ambiguous: '曖昧中',
        in_love: '恋愛中',
        engaged: '婚約',
        married: '既婚',
        separated: '別居',
        divorced: '離婚',
        widow: '配偶者を亡くした',
        not_selected: '選択しない',
        tips15: '私のを見るかどうか',
        personal_homepage: '個人用ページ',
        my_home_page: 'マイホーム',
        my_attention: '私の関心',
        my_fans: '私のファン',
        tips16: 'プロフィールなし',
        belong_to: '帰属',
        add: '加入',
        look_around_the_world: 'グローバルに探してみて',
        registration_of_seeking_relatives: '縁談登録',
        name_of_missing_person: '行方不明者の名前',
        age: '年齢',
        debatable_time: '失踪時間',
        location_of_disappearance: '行方不明場所',
        feature_description: 'フィーチャー記述',
        photograph: '写真',
        check_in: '登録',
        contact_number: '連絡先電話',
        front_cover: '表紙',
        missing_people: '行方不明者',
        created_by: '作成者',
        creation_time: '作成時間',
        comment: 'コメント',
        praise: 'いいね',
        system_notification: 'システム通知',
        tips17: '正しいメールアドレスを入力してください',
        tips17_o: '正しい携帯番号フォーマットを入力してください',
        avatar: 'アバター',
        tips18: 'アカウントは存在します',
        tips19: '登録に失敗しました',
        tips20: '認証コードエラー',
        tips21: '認証コードを再取得する',
        tips22: '認証コードの送信に成功しました',
        tips23: 'ログイン成功',
        tips24: 'パスワードエラー',
        tips25: 'ユーザーは存在しません',
        tips26: '完全なホット検索ランキングを表示する',
        help_center: 'ヘルプセンター',
        customer: 'カスタマーサービス',
        self_service_center: 'セルフサービスセンター',
        website_related: '出会い系サイト',
        tips27: 'コラボレーション サービス',
        cooperation_hotline: '提携ホットライン',
        follow: 'フォロー',
        tips28: '手がかりを提供する',
        view_the_original_article: '元の記事を見る',
        message: 'メッセージ',
        tips29: '追加に成功しました',
        tips30: '追加に失敗しました',
        tips31: 'あなたのコメントを投稿します',
        tips32: '既存',
        tips33: '登録に成功しました',
        tips34: '自分で自分に注目しなくてもいい',
        cancel: 'フォロー解除',
        tips35: 'データの削除に成功しました',
        tips36: 'データ削除に失敗しました',
        name_of_the_deceased: '死亡者名',
        nationality: '国籍',
        date_of_birth: '誕生日',
        date_of_death: '死亡日',
        university: '出身校',
        occupation: '職業',
        achievements: '個人の業績',
        tips37: '犠牲者の名前を空にすることはできない',
        tips38: '選択アバター',
        tips39: '国籍は空にできない',
        tips40: '生年月日の選択',
        tips41: '故人の日付の選択',
        tips42: '作成に成功しました',
        tips43: '作成に失敗しました',
        tips44: 'すべてのファン',
        account_of_the_deceased: '故人のアカウント',
        add_an_account: 'アカウントの追加',
        view: '表示',
        transfer: '転送',
        newly_increased: '新規作成',
        opposite_id: '相手ID',
        transfer_failed: '転送に失敗しました',
        transfer_successful: '移行成功',
        tips45: '自分で自分に渡すことはできない',
        tips46: '死者のアカウントはありません',
        tips47: 'しばらく注目していない',
        tips48: 'しばらくファンがいない',
        determine: 'OK',
        call_off: 'キャンセル',
        tips49: 'ユーザーのフォローを解除しますか？',
        tips50: 'どんな金言がありますか？',
        tips51: '修正成功',
        tips52: '修正に失敗しました',
        hint: 'ヒント',
        tips53: '本当に転送しますか？',
        repeat: '転送',
        hot_search: 'ホットサーチ',
        know: '知っている',
        tips54: 'いつか知っている人がいる',
        tips55: 'あなたの質問の答え',
        solve: '解決',
        question: '質問',
        tips56: '知っていること',
        tips57: '質問します',
        submit: '回答',
        quick_answer: 'クイック回答',
        translate: '翻訳',
        tips58: '翻訳に失敗しました',
        recommend: '推奨',
        effect: '影響力',
        number_of_fans: 'ファン数',
        quantity:'コンテンツ数',
        fans: 'ファン',
        tips59: '花を贈ることに成功した',
        tips60: '花送りに失敗した',
        tips61: '編集に成功しました',
        tips62: '編集に失敗しました',
        tips63: '名前を入力してください',
        tips64: '関係を入力してください',
        tips65: '画像を選択してください',
        tips66: 'タイトルを入力してください',
        tips67: 'コンテンツを入力してください',
        tips68: 'プレート名を入力してください',
        tips69: 'プレートは既に存在する',
        tips70: '花を贈ることを確定する？',
        tips71: '死者との関係',
        album:'ピクチャーセット',
        deceased_admin: '死者管理者',
        creator: '創建者',
        the_deceased: '死者',
        number_of_views: 'ブラウズ回数',
        update_time: '更新時間',
        basic_info: '基本情報',
        full_name:'名前',
        alias:'エイリアス',
        language: '言語が得意',
        height: '身長',
        motto: '座右の銘',
        interaction: 'インタラクティブ',
        input_relationship: '入力関係',
        content_addition: 'コンテンツ追加',
        image_upload: '画像アップロード',
        message_management: '伝言管理',
        deceased_label: '死者ラベル',
        modify: '修正',
        image_photo: 'イメージ写真',
        brief:'概要',
        label: 'ラベル',
        increase: '追加',
        constellation: '星座',
        relationship:'関係',
        title:'タイトル',
        add_section:'プレートの追加',
        modify_section:'修正プレート',
        module_name:'プレート名',
        content: 'コンテンツ',
        section:'プレート',
        guestbook: '伝言',
        operate: '操作',
        early_experiences: '昔の経歴',
        deductive_experience: '仕事の成果',
        personal_life:'一人暮らし',
        award_records: '仕事の成果',
        character_evaluation: '知人評価',
        deceased_square: '死者広場',
        memorial_plaza: '記念広場',
        ranking_list1: '花札を贈る',
        release: 'リリース',
        message_list: 'コメント',
        tips72: '削除管理者にお問い合わせください',
        tips73: '11桁の携帯番号を入力してください',
        none: 'しばらくありません',
        details: '詳細',
        log_on: 'ログイン',
        previous_article: '前編',
        next_article: '次へ',
        chapter_1: '第1編',
        last_article: '最後の一編',
        tips74: 'これ以上のデータはありません',
        unknown: '不明',
        tips75: '影響力はユーザーが毎日ログインする時間が長く、インタラクション数が総合的に現れる',
        tips76: '知的財産権侵害',
        tips77: 'デマ偽り',
        tips78: 'コンテンツの品質が悪い',
        tips79: '名誉侵害',
        tips80: '低俗ポルノ',
        tips81: '詐欺の疑い',
        tips82: '政治関連',
        tips83: 'マーケティング広告',
        tips84: '宣伝の悪い価値観',
        report: '通報',
        report_content: '通報内容',
        reported_image: '告発画像',
        reporting_link: '通報リンク',
        reporting_type: '通報タイプ',
        join: '参加',
        interact: 'インタラクティブメッセージ',
        tips85: 'ユーザーが非アクティブ化されている',
        tips86: 'メールボックス認証コード',
        change_password: 'パスワードの変更',
        confirm: '確認',
        return: '戻る',
        tips87: '私の質問',
        recommendo: '推奨',
        tips88: 'この答えをお勧めしますか？',
        tips89: '推薦成功',
        tips90: '推薦失敗',
        tips91: '問題は存在しない',
        tips92: '画像サイズは10 M以上にすることはできません！',
        tips93: '画像サイズは0 Mにできません！',
        tips94: '画像の比率は1:1ではありません',
        tips95: 'ヒント：画像は1：1サイズでアップロードしてください。そうしないと成功しません。アイコン画像は主要人物を真ん中にしてください。全体の画像の品質は10 M未満です',
        tips96: '画像の比率は16:9ではありません',
        tips97: '新しいパスワードを設定する',
        tips98: '未知として選択しない',
        tips99: 'IDを入力してください',
        tips100: 'オリジナル保護：著作権は著者の所有',
        tips101: '声明：発表内容はプラットフォームとは関係なく、もし不実な情報が発見されたら通報することができる',
        tips102: '提案 4：3横長型画像をアップロードして、全体の画像の品質は10 Mより低くて、jpg、jpeg、bmp、gif、pngフォーマットをサポートします',
        tips103: '提案 3：4縦長ライン画像をアップロードして、全体の画像の品質は10 Mより低くて、jpg、jpeg、bmp、gif、pngフォーマットをサポートして',
        id: 'ID',
        tips104:'受け取ったコメント',
        private_letter: '私信',
        reply: '返信',
        the_original: 'テキスト',
        change: '変更',
        change_avatar: 'アバターを変える',
        tips105: "提案 1：1サイズアップロードして、全体のピクチャーの品質は10 Mより低くて、jpg、jpeg、bmp、gif、pngフォーマット'を支持して",
        change_email: 'メールボックスを交換してください',
        new_email: '新しいメールボックス',
        tips106: 'このメールボックスは登録されている',
        tips107: '24時間対応カスタマーサービスメール',
        tips108: '著作権は著者の所有とする',
        tips109: '個人用ページの設定',
        tips110: '設定成功',
        tips111: '設定に失敗しました',
        tips112: 'パスワードを忘れる',
        tips113: 'このユーザだけが自分で見ることができる',
        tips114: 'このユーザーの友人だけが見ることができる',
        year: '年',
        moon: '月',
        day: '日',
        B_C: '紀元前',
        tips115: '逝去年順',
        tips116: '自分に私信を送ってはいけない',
        tips117: 'ENTERを押して送信する',
        tips118: '私信の内容を見る~~',
        tips119: 'まだ私信はありません~~',
        search_result: '検索結果',
        comprehensive: '統合',
        tips120: 'ブラックリストが追加されました',
        tips121: 'ブラックリストに自分を追加することはできない',
        tips122: 'ブラックリストに参加する',
        tips123: '相手はブラックリストに登録しており、メッセージを送信できません',
        tips124: '相手をブラックリストに追加しました。メッセージを送信できません',
        blacklist: 'ブラックリスト',
        die_at_the_age_of: '享年',
        tips125: '会話を削除する？',
        tips126: '有効な日付フォーマットではありません',
        just: 'さっき',
        tips127: '1分前',
        minute_ago: '分前',
        tips128: '1時間前',
        hour_ago: '時間前',
        yesterday: '昨日',
        days_ago: '先日',
        weeks_ago: '週間前',
        tips129: '名前 ',
        about_us: '私たちについて',
        cooperation: '企業誘致協力',
        walk_into: '入って',
        tips130: 'ウェブサイトや電話で会社の営業マネージャに問い合わせて理解する',
        tips131: '商談協力：電話で商談したり、直接見学に来たりして商談を考察することができる',
        tips132: '協定を締結して協力方式を確定する',
        tips133: 'サービスホットライン',
        tips134: '提携電話',
        customer_service_1: 'カスタマーサービス1',
        customer_service_2: 'カスタマーサービス2',
        tips135: '申請内容追加/訂正',
        tips136: '名前キーワード',
        tips137: '完全なメッセージを表示する',
        message_board: '伝言版',
        tips138: '故人のホームページ',
        copy: 'レプリケーション',
        my_answer: '私の答え',
        my_password: 'マイパスワード',
        tools: 'ツール',
        height_unit: '身長単位',
        tips139: '提案 100×138縦長ライン画像をアップロードして、全体の画像の品質は10 Mより低くて、jpg、jpeg、bmp、gif、pngフォーマットをサポートして',
        tips140: '提案 100×138縦長ライン画像をアップロードして、全体の画像の品質は10 Mより低くて、jpg、jpeg、bmp、gif、pngフォーマットをサポートして',
        tips141: 'プレートの削除',
        tips142: '私が発表した金文',
        tips143: '雑談禁止',
        tips144: 'ヒント：不明なボタンがチェックされている場合は、不明なボタンが表示されます',
        replicating_success: 'コピー成功',
        tips145: '画像は1:1サイズでアップロードすることをお勧めします。アイコン画像は主要人物を真ん中にしてください。全体の画像の品質は10 M未満',
        tips146: '失敗に注目',
        tips147: 'フォロー解除済み',
        tips148: 'フォロー解除失敗',
        tips149: '相互に関心を持っている',
        tips150: '相互フォロー失敗',
        tips151: 'ユーザーをブラックリストから削除しました',
        tips152: 'ユーザーをブラックリストから削除できませんでした',
        tips153: 'リリース済み',
        tips154: 'リリース失敗',
        tips155: 'コミット済み',
        tips156: 'コミットに失敗しました',
        self: '自分自身',
        sending_flowers: '花を贈る',
        successfully_saved:'保存に成功しました',
        save_failed:'保存に失敗しました',
        clue: '手がかり',
        tips157: '夫、母親、友人など故人との関係を記入する',
        tips158: 'は1：1スケール正方形ピクチャをアップロードすることを提案し、ピクチャサイズは10 M未満で、jpg、jpeg、bmp、gif、pngフォーマット',
        tips159: '1:1スケール正方形画像をアップロードしてください。そうしないと成功しません。アバター画像は、主な人物を真ん中にしてください。画像サイズは10 M未満で、jpg、jpeg、bmp、gif、pngフォーマット',
        tips160: 'アップロード推奨220：230スケール縦長形画像、画像サイズ10 M未満、jpg、jpeg、bmp、gif、pngフォーマットをサポートする',
        tips161: '提案アップロード100：140スケール縦長形画像、画像サイズは10 M未満、jpg、jpeg、bmp、gif、pngフォーマットをサポートする',
        tips162: '提案アップロード100：56スケール横長形画像、画像サイズ10 M未満、サポートjpg、jpeg、bmp、gif、pngフォーマット',
        admin: '管理者',
        tourist: '観光客',
        tips163: '公開に成功しました',
        id_number_copied: 'ID番号がコピーされました',
        tips164:'手がかり提供成功',
        tips165:'スレッド提供失敗',
        tips166:'金文コピー成功',
        tips167:'フォロー解除成功',
        tips168:'フォロー解除失敗',
        tips169:'金文リリース成功',
        tips170:'金文リリース失敗',
        tips171:'コメント成功',
        tips172:'コメントに失敗しました',
        tips173:'答えは成功した',
        tips174:'回答に失敗しました',
        tips175:'投稿質問成功',
        tips176:'質問の投稿に失敗しました',
        tips177:'メッセージ失敗',
        deceasedo:'死者の追加',
        tips178: 'ログアウトIDを入力してください',
        tips179: 'アカウントが正しくないので、IDを再確認してください',
        tips180: 'ログアウト理由を入力してください',
        tips181: '申し込み成功！keepflyは7営業日以内に応募を処理します',
        tips182: '申請に失敗しました',
        tips183: 'IDをログアウトする',
        tips184: 'メールボックスをログアウトする',
        tips185: 'ログアウト理由',
        apply: '申請',
        tips186:'keepflyアカウントをログアウトしています。その後はkeepfly製品やサービスを使用できませんので、慎重に操作してください!',
        tips187:'keepflyは操作記録を保持せず、ログアウト後に記録の復元を申請することはできません。',
        tips188: 'アカウントをログアウトする',
        tips189: '生存者のために死者のアカウントを作らないでください。法的リスクがあればプラットフォームとは関係ありません',
        video: 'ビデオ',
        tips190: '画像ビデオは1種類しかアップロードできませんが、確認？',
        tips191: '花を贈るには20分以上かかりません',
        delete_account: 'アカウントの削除',
    }
}
export default ja