<template>
  <div class="limiter">
    <div class="container-login">
      <div class="login-box">
        <div style="margin-bottom: 20px; margin-top: -36px">
          <div class="head-left">
            <a class="txt" href="/#/">
              {{ $t("message.homepage") }}
            </a>
          </div>
          <div class="head-rigth">
            <n-select
              @update:value="handleSearch"
              v-model:value="selectedValue"
              label-field="name"
              value-field="language"
              size="tiny"
              status="warning"
              :options="options"
            />
          </div>
          <div style="clear: both"></div>
        </div>
        <n-form
          class="login-form validate-form"
          ref="formRef"
          :model="formValue"
          :rules="rules"
        >
          <span class="login-form-title p-b-48">
            <a href="/#/">
              <img
                class="login-form-logo"
                :src="kukesystemInfo.logo2"
                style="
                  background-color: #fff;
                  border-radius: 5px;
                  border: 1px solid #ccc;
                  width: 96px;
                "
              />
            </a>
          </span>
          <span class="login-form-title p-b-26">{{ $t("message.welcome") }}！</span>
          <div class="login-input-wrap validate-input" path="account">
            <span>{{ $t("message.account_number") }}</span>
            <input class="login-input" type="text" v-model="formValue.account" />
            <span class="input-focus" placeholder=""></span>
          </div>
          <div class="login-input-wrap validate-input" path="pwd">
            <span>{{ $t("message.password") }}</span>
            <input
              class="login-input"
              type="password"
              data="passworedtype"
              v-model="formValue.pwd"
            />
            <span class="input-focus" placeholder=""></span>
          </div>
          <div class="container-login-form-btn">
            <div class="wrap-login-form-btn">
              <div class="login-form-bgbtn"></div>
              <button class="login-form-btn" @click="handleValidateClick">
                {{ $t("message.SignIn") }}
              </button>
            </div>
            <div
              @click="showModal = true"
              class="txt1"
              style="cursor: pointer; margin-top: 16px; width: 100%; text-align: right"
            >
              {{ $t("message.tips112") }}
            </div>
          </div>
          <div class="text-center p-t-115">
            <span class="txt1">{{ $t("message.no_account_yet") }}</span>
            <a class="txt2" href="/#/enroll">{{ $t("message.register") }}</a>
          </div>
        </n-form>
      </div>
    </div>
    <n-modal
      v-model:show="showModal"
      class="custom-card"
      preset="card"
      :style="bodyStyle"
      :title="$t('message.tips86')"
      size="huge"
      :bordered="false"
      :segmented="segmented"
    >
      <n-form
        ref="formRef"
        :model="login_model"
        :rules="rules"
        label-placement="left"
        label-width="auto"
        require-mark-placement="right-hanging"
        :size="size"
        :style="{
          maxWidth: '640px',
        }"
      >
        <n-form-item :label="$t('message.email')">
          <n-input
            v-model:value="login_model.email"
            :placeholder="$t('message.email')"
            status="warning"
          />
        </n-form-item>
        <n-form-item :label="$t('message.verification_code')">
          <n-input
            v-model:value="login_model.yzcode"
            :placeholder="$t('message.verification_code')"
            status="warning"
          />
          <a href="javascript:;" class="e-model" @click="sendCode">
            {{ codeNum == 60 ? $t("message.gain") : `(${codeNum})s` }}
          </a>
        </n-form-item>
        <div style="display: flex; justify-content: flex-end">
          <n-button
            round
            type="warning"
            class="e-button"
            @click="handleValidateButtonClick"
          >
            {{ $t("message.log_on") }}
          </n-button>
        </div>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import i18n from "../i18n/index";
import { NSelect, useMessage, NForm, NModal, NFormItem, NInput, NButton } from "naive-ui";
import { useRouter } from "vue-router";
import API from "../uilts/request";
import utils from "../uilts/utils";
export default defineComponent({
  name: "LoginView",
  components: {
    NSelect,
    NForm,
    NFormItem,
    NModal,
    NInput,
    NButton,
  },
  props: {
    msg: String,
  },
  setup() {
    const { locale } = useI18n();
    const message = useMessage();
    const router = useRouter();
    let arr = ref([]);
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", locale.value);
      location.reload();
    };
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    onMounted(() => {
      getKukeLanguage();
      getkukesystemInfo();
      if (!localStorage.getItem("language")) {
        var lang = navigator.language;
        if (lang == "zh-CN") {
          localStorage.setItem("language", "zh_CN");
        } else {
          localStorage.setItem("language", "en");
        }
      }
    });
    const langlocal = localStorage.getItem("language");
    const formValue = ref({
      account: "",
      pwd: "",
    });
    const handleValidateClick = () => {
      var account = formValue.value.account;
      var pwd = formValue.value.pwd;
      if (account == "") {
        message.error(i18n.global.t("message.tips1"));
        return false;
      }
      if (pwd == "") {
        message.error(i18n.global.t("message.tips2"));
        return false;
      }
      let param = {
        account: account,
        password: pwd,
      };
      let currtime = new Date().getTime();
      let logintime = utils.dateFormat(currtime);
      API({
        url: "/kuke/api/index/login",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          window.localStorage.setItem("token", result.data.data.sign);
          window.localStorage.setItem(
            "infodata",
            JSON.stringify(result.data.data.infodata)
          );
          window.localStorage.setItem("loginTime", logintime);
          message.success(i18n.global.t("message.tips23"));
          router.push({
            name: "HomeView",
            params: {
              id: 1,
            },
          });
        } else {
          if (result.data.code == 601) {
            message.error(i18n.global.t("message.tips24"));
          } else if (result.data.code == 602) {
            message.error(i18n.global.t("message.tips25"));
          } else if (result.data.code == 603) {
            message.error(i18n.global.t("message.tips17"));
          } else if (result.data.code == 604) {
            message.error(i18n.global.t("message.tips85"));
          }
        }
      });
    };
    const handleValidateButtonClick = () => {
      var email = login_model.value.email;
      var yzcode = login_model.value.yzcode;
      if (email == "") {
        message.error(i18n.global.t("message.tips17"));
        return false;
      }
      if (yzcode == "") {
        message.error(i18n.global.t("message.tips6"));
        return false;
      }
      let param = {
        email: email,
        yzcode: yzcode,
      };
      let currtime = new Date().getTime();
      let logintime = utils.dateFormat(currtime);
      API({
        url: "/kuke/api/index/emaillogin",
        method: "POST",
        data: param,
      }).then((result) => {
        if (result.data.code == 200) {
          window.localStorage.setItem("token", result.data.data.sign);
          window.localStorage.setItem(
            "infodata",
            JSON.stringify(result.data.data.infodata)
          );
          window.localStorage.setItem("loginTime", logintime);
          message.success(i18n.global.t("message.tips23"));
          router.push({
            name: "HomeView",
            params: {
              id: 1,
            },
          });
        } else {
          if (result.data.code == 602) {
            message.error(i18n.global.t("message.tips25"));
          } else if (result.data.code == 603) {
            message.error(i18n.global.t("message.tips17"));
          } else if (result.data.code == 604) {
            message.error(i18n.global.t("message.tips85"));
          } else if (result.data.code == 605) {
            message.error(i18n.global.t("message.tips20"));
          } else if (result.data.code == 606) {
            message.error(i18n.global.t("message.tips21"));
          }
        }
      });
    };
    const formRef = ref(null);
    const kukesystemInfo = ref({});
    const getkukesystemInfo = () => {
      let params = {
        language: localStorage.getItem("language"),
      };
      API({
        url: "/kuke/api/index/getkukesystemInfo",
        method: "POST",
        data: params,
      }).then((result) => {
        kukesystemInfo.value = JSON.parse(JSON.stringify(result.data.data));
        window.localStorage.setItem("kukesystemInfo", JSON.stringify(result.data.data));
      });
    };
    const showModal = ref(false);
    const login_model = ref({});

    //倒计时初始变量
    const codeNum = ref(60);
    // 定时器id
    let clearId;
    // 发送验证码
    const sendCode = async () => {
      if (
        login_model.value.email == null ||
        login_model.value.email == "" ||
        login_model.value.email == undefined
      ) {
        message.error(i18n.global.t("message.tips1"));
        return false;
      }
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!mailReg.test(login_model.value.email)) {
        message.error(i18n.global.t("message.tips17"));
        return false;
      }
      if (codeNum.value != 60) return;
      clearId = setInterval(() => {
        // 每次 时间1s -1
        codeNum.value--;
        // 时间=0时 清除定时器
        if (codeNum.value == 0) {
          clearInterval(clearId);
          // 还原 倒计时60s
          codeNum.value = 60;
        }
      }, 1000);
      API({
        url: "/kuke/api/index/getCode",
        method: "GET",
        params: { account: login_model.value.email },
      }).then((result) => {
        if (result.data.code == 200) {
          message.success(i18n.global.t("message.tips22"));
        }
      });
    };
    return {
      handleValidateButtonClick,
      codeNum,
      sendCode,
      login_model,
      showModal,
      kukesystemInfo,
      formRef,
      selectedValue: ref(langlocal),
      handleSearch: (value) => {
        changeLang(value);
      },
      options: arr,
      handleValidateClick,
      formValue,
      bodyStyle: {
        width: "600px",
      },
    };
  },
});
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.head-left {
  float: left;
  width: 60%;
}
.head-rigth {
  float: right;
  width: 40%;
}
input {
  outline: none;
  border: none;
}

button,
input {
  overflow: visible;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.text-center {
  text-align: center !important;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.login-box {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
  padding: 77px 55px 33px 55px;
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 100%;
}

.login-form-title {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.validate-input {
  position: relative;
}

.login-input-wrap {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  border-bottom: 2px solid #adadad;
}

.login-input {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

.input-focus {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.input-focus::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2.5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  background: #6a7dfe;
  background: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
  background: -o-linear-gradient(to left, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to left, #21d4fd, #b721ff);
  background: linear-gradient(to left, #21d4fd, #b721ff);
}

.input-focus::after {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #999999;
  line-height: 1.2;
  content: attr(placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login-input:focus ~ .input-focus::before,
.login-input:valid ~ .input-focus::before {
  width: 100%;
}

.login-input:focus ~ .input-focus::after,
.login-input:valid ~ .input-focus::after {
  top: -10px;
}

.btn-show-pass {
  font-size: 15px;
  color: #999999;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 13px;
  right: 0;
  padding-right: 5px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.gg-eye,
.gg-eye-close {
  width: 16px;
  height: 16px;
  border: solid 2px #999999;
  border-radius: 70% 20%;
  position: relative;
  transform: rotate(45deg);
}

.gg-eye::before,
.gg-eye-close::before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border: solid 2px #999999;
  border-radius: 50%;
  left: 2px;
  top: 2px;
}

.gg-eye-close::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 5px;
  width: 1.5px;
  height: 22px;
  background: #999999;
  transform: rotate(80deg);
}

.gg-eye:hover,
.gg-eye-close:hover,
.gg-eye-close:hover::before,
.gg-eye:hover::before {
  border: solid 2px #6a7dfe;
}

.gg-eye-close:hover::after {
  background: #6a7dfe;
}

.container-login-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: -webkit-linear-gradient(to right, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: -o-linear-gradient(to right, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to right, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: linear-gradient(to right, #21d4fd, #b721ff, #21d4fd, #b721ff);
  top: 0;
  left: -100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login-form-btn {
  cursor: pointer;
  font-family: Poppins-Medium;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login-form-btn:hover .login-form-bgbtn {
  left: 0px;
}

.txt1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}
.txt {
  color: #333333;
}
.p-b-26 {
  padding-bottom: 26px;
}

.p-b-48 {
  padding-bottom: 20px;
}

.p-t-115 {
  padding-top: 76px;
}
.e-model {
  width: 122px;
  height: 34px;
  line-height: 34px;
  background: orange;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  right: 0px;
}
.e-button {
  width: 122px;
  height: 34px;
  line-height: 34px;
  background: orange;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
}
.e-model:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
</style>
