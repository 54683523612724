<template>
  <div class="keepfly-box-flex Frame_content" style="max-width: 1190px">
    <div class="Frame_side grayTheme">
      <div class="Nav_main_user">
        <div
          class="keepfly-panel-main keepfly-panel-left keepfly-panel-top keepfly-panel-right Nav_inner"
        >
          <h2 class="Nav_title">{{ $t("message.ranking_list1") }}</h2>
          <div>
            <div class="drop" v-if="DeceasedFlowerlistarr.length > 0">
              <div
                class="drop_container"
                v-for="(item, index) in DeceasedFlowerlistarr"
                :key="item"
                id="drop-items"
              >
                <div class="drop_card" @click="deadview(item.id)">
                  <div class="drop_data">
                    <img :src="item.headSculpture" class="drop_img" />
                    <img src="../../assets/1.png" v-if="index == 0" class="level" />
                    <img src="../../assets/2.png" v-if="index == 1" class="level" />
                    <img src="../../assets/3.png" v-if="index == 2" class="level" />
                    <div>
                      <h1 class="drop_name">{{ item.dname }}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="background: #fff; padding: 40px 10px">
                <n-empty :description="$t('message.none')">
                  <template #extra></template>
                </n-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Frame_main">
      <main class="keepfly-box-flex Main_wrap">
        <div class="Main_full" style="width: 1014px">
          <div class="Home_home">
            <div class="SecBar_secBar SecBar_visable">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div>
                  <div class="wbpro-tab2" style="position: relative">
                    <div class="keepfly-box-item-inlineBlock cur">
                      <div
                        class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyCenter"
                      >
                        <div class="text Tab_nowrap">
                          <div
                            class="keepfly-badge-box"
                            style="color: #99337f; font-weight: bold"
                          >
                            {{ $t("message.memorial_plaza") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="keepfly-box-item-inlineBlock">
                      <div
                        class="marquee_list"
                        :class="{ marquee_top: animate }"
                        style="
                          width: 686px;
                          height: 44px;
                          line-height: 44px;
                          text-align: center;
                          color: rgb(153, 51, 127);
                          font-size: 13px;
                        "
                      >
                        <svg
                          style="
                            width: 19px;
                            vertical-align: middle;
                            margin-right: 10px;
                            height: 44px;
                            line-height: 44px;
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39c.4-.53.8-1.07 1.2-1.6c-.99-.74-2.24-1.68-3.2-2.4c-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6c-.99.74-2.24 1.68-3.2 2.4c.4.53.8 1.07 1.2 1.6c.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm5.03 1.71L11 9.53v4.94l-1.97-1.18l-.48-.29H4v-2h4.55l.48-.29zM15.5 12c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <!-- <svg
                          style="
                            width: 19px;
                            vertical-align: middle;
                            transform: rotate(43deg);
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 20 20"
                        >
                          <g fill="none">
                            <path
                              d="M14.158 1.026a.5.5 0 0 1 .317.632l-.5 1.5a.5.5 0 0 1-.95-.316l.5-1.5a.5.5 0 0 1 .633-.316zm3.697 1.828a.5.5 0 1 0-.708-.707l-2 2a.5.5 0 0 0 .707.707l2-2zm-10.248.292a2 2 0 0 1 3.261-.515l6.587 6.98a2 2 0 0 1-.648 3.203L12.87 14.55a3.504 3.504 0 0 1-3.37 4.45a3.498 3.498 0 0 1-2.975-1.655l-1.2.529a1.5 1.5 0 0 1-1.661-.308l-1.222-1.21a1.5 1.5 0 0 1-.299-1.71l5.464-11.5zm-.154 13.789a2.5 2.5 0 0 0 4.488-1.977l-4.488 1.977zm2.688-13.617a1 1 0 0 0-1.63.257l-5.465 11.5a.5.5 0 0 0 .1.569l1.222 1.211a.5.5 0 0 0 .553.102L16.403 11.9a1 1 0 0 0 .325-1.601l-6.587-6.98zM17 6a.5.5 0 0 0 0 1h1.5a.5.5 0 1 0 0-1H17z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg> -->
                        <n-tooltip trigger="hover">
                          <template #trigger>
                            <n-ellipsis style="max-width: 686px">
                              {{ marqueeList[0] }}
                            </n-ellipsis>
                          </template>
                          {{ marqueeList[0] }}
                        </n-tooltip>
                      </div>
                    </div>
                    <div class="keepfly-box-item-inlineBlock">
                      <div style="position: absolute; top: 6px; right: 6px">
                        <n-input-group>
                          <input
                            class="search_ss"
                            v-model="searchTxt"
                            :placeholder="$t('message.tips136')"
                          />
                          <button class="search_btn" @click="searchView">
                            <search style="width: 22px; padding-top: 5px"></search>
                            <!-- {{ $t("message.search") }} -->
                          </button>
                        </n-input-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="homeWrap" class="Home_wrap">
              <div
                class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
              >
                <div class="index_box">
                  <div class="index_box1">
                    <div
                      class="vue-recycle-scroller ready page-mode direction-vertical"
                      id="scroller"
                    >
                      <div style="float: right">
                        <span>
                          <n-button
                            icon-placement="right"
                            @click="setCreateState"
                            tertiary
                          >
                            <template #icon>
                              <n-icon>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="M3 9l4-4l4 4M7 5v14"></path>
                                    <path d="M21 15l-4 4l-4-4m4 4V5"></path>
                                  </g>
                                </svg>
                              </n-icon>
                            </template>
                            {{ $t("message.creation_time") }}
                          </n-button>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <n-button icon-placement="right" @click="setYearState" tertiary>
                            <template #icon>
                              <n-icon>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="M3 9l4-4l4 4M7 5v14"></path>
                                    <path d="M21 15l-4 4l-4-4m4 4V5"></path>
                                  </g>
                                </svg>
                              </n-icon>
                            </template>
                            {{ $t("message.tips115") }}
                          </n-button>
                        </span>
                      </div>
                      <div style="clear: both"></div>
                      <div class="vue-recycle-scroller__item-wrapper">
                        <div>
                          <div class="wbpro-scroller-item">
                            <div
                              class="keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap HotTopic_wrap"
                            >
                              <div class="HotTopic_item">
                                <div
                                  class="keepfly-box-flex"
                                  v-if="deceasedlistarr.length > 0"
                                >
                                  <div class="keepfly-box-item-flex">
                                    <div
                                      class="keepfly-box-flex keepfly-box-alignCenter HotTopic_titout"
                                      v-for="item in deceasedlistarr"
                                      :key="item"
                                      style="float: left; margin-right: 6px"
                                    >
                                      <n-card
                                        class="deceasedcard"
                                        :title="item.dname"
                                        size="small"
                                        style="width: 184px"
                                        @click="deadview(item.id)"
                                        hoverable
                                      >
                                        <template #cover>
                                          <img
                                            :src="item.brand"
                                            style="
                                              height: 160px;
                                              margin: 18px auto 12px;
                                              width: auto;
                                              border-radius: 90px;
                                              border: 8px solid #dae0f8;
                                            "
                                          />
                                        </template>
                                        <div style="font-size: 12px" v-if="item.year">
                                          <div>
                                            <n-ellipsis style="max-width: 120px">
                                              {{
                                                item.occupation == null
                                                  ? $t("message.unknown")
                                                  : item.occupation
                                              }}
                                            </n-ellipsis>
                                          </div>
                                          <span style="float: right; font-size: 12px">
                                            {{
                                              item.year < 0
                                                ? $t("message.B_C") +
                                                  " " +
                                                  Math.abs(item.year)
                                                : item.year
                                            }}-{{
                                              item.moon > 9 ? item.moon : "0" + item.moon
                                            }}-{{
                                              item.day > 9 ? item.day : "0" + item.day
                                            }}
                                          </span>
                                        </div>
                                        <div style="font-size: 12px" v-else>
                                          <div>
                                            <n-ellipsis style="max-width: 120px">
                                              {{
                                                item.occupation == null
                                                  ? $t("message.unknown")
                                                  : item.occupation
                                              }}
                                            </n-ellipsis>
                                          </div>
                                          <span style="float: right; font-size: 12px">
                                            {{ $t("message.unknown") }}</span
                                          >
                                        </div>
                                      </n-card>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div style="background: #fff; padding: 40px 10px">
                                    <n-empty :description="$t('message.none')">
                                      <template #extra></template>
                                    </n-empty>
                                  </div>
                                </div>
                              </div>
                              <n-pagination
                                v-model:page="currdeceasedPage"
                                size="medium"
                                :page-count="dpages"
                                :page-slot="7"
                                @update-page="onDataChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {
  NCard,
  NEmpty,
  NButton,
  NIcon,
  NInputGroup,
  NPagination,
  NEllipsis,
  NTooltip,
} from "naive-ui";
import API from "../../uilts/request";
import { Search } from "@vicons/ionicons5";
export default defineComponent({
  name: "SquareView",
  components: {
    NCard,
    NEmpty,
    NButton,
    NIcon,
    NInputGroup,
    NPagination,
    NEllipsis,
    NTooltip,
    Search,
  },
  setup() {
    const kukesystemInfo = ref({});
    onMounted(() => {
      kukesystemInfo.value = JSON.parse(window.localStorage.getItem("kukesystemInfo"));
      getdeceasedlist();
      getDeceasedFlowerList();
      getDeceasedMessageList();
      getscrollingtextList();
      setInterval(showMarquee, 10000);
    });
    const router = useRouter();
    const currdeceasedPage = ref(1);
    const pagedeceasedSize = ref(35);
    const deceasedlistarr = ref([]);
    const dpages = ref(0);
    const getdeceasedlist = () => {
      let param = {
        currPage: currdeceasedPage.value,
        pageSize: pagedeceasedSize.value,
        createStateShow: createStateShow.value ? 1 : 0,
        yearStateShow: yearStateShow.value ? 1 : 0,
        fflat: fflat.value,
        txt: searchTxt.value,
      };
      API({
        url: "/kuke/api/index/getindexdeceasedlist",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        deceasedlistarr.value = arr;
        dpages.value = result.data.data.pages;
      });
    };
    function onDataChange(val) {
      currdeceasedPage.value = val;
      getdeceasedlist();
    }
    const currDeceasedFlowerPage = ref(1);
    const pageDeceasedFlowerSize = ref(8);
    const DeceasedFlowerlistarr = ref([]);
    const getDeceasedFlowerList = () => {
      let param = {
        currPage: currDeceasedFlowerPage.value,
        pageSize: pageDeceasedFlowerSize.value,
      };
      API({
        url: "/kuke/api/index/getDeceasedFlowerList",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        DeceasedFlowerlistarr.value = arr;
      });
    };
    const currDeceasedMessagePage = ref(1);
    const pageDeceasedMessageSize = ref(10);
    const DeceasedMessagelistarr = ref([]);
    const getDeceasedMessageList = () => {
      let param = {
        currPage: currDeceasedMessagePage.value,
        pageSize: pageDeceasedMessageSize.value,
      };
      API({
        url: "/kuke/api/index/getDeceasedMessageList",
        method: "POST",
        data: param,
      }).then((result) => {
        let arr = JSON.parse(JSON.stringify(result.data.data.records));
        DeceasedMessagelistarr.value = arr;
      });
    };
    const deadview = (e) => {
      router.push({
        name: "ViewerDeceasedView",
        params: {
          id: e,
        },
      });
    };
    const fflat = ref(1);
    const createStateShow = ref(false);
    const setCreateState = () => {
      fflat.value = 1;
      createStateShow.value = !createStateShow.value;
      getdeceasedlist();
    };
    const yearStateShow = ref(false);
    const setYearState = () => {
      fflat.value = 2;
      yearStateShow.value = !yearStateShow.value;
      getdeceasedlist();
    };
    const searchTxt = ref("");
    const searchView = () => {
      currdeceasedPage.value = 1;
      getdeceasedlist();
    };
    const animate = ref(false);
    const marqueeList = ref([]);
    const getscrollingtextList = () => {
      let param = {
        language: localStorage.getItem("language"),
      };
      API({
        url: "/kuke/api/index/getscrollingtextList",
        method: "GET",
        params: param,
      }).then((result) => {
        marqueeList.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const showMarquee = () => {
      animate.value = true;
      setTimeout(() => {
        marqueeList.value.push(marqueeList.value[0]);
        marqueeList.value.shift();
        animate.value = false;
      }, 500);
    };
    return {
      marqueeList,
      onDataChange,
      dpages,
      currdeceasedPage,
      searchView,
      searchTxt,
      setYearState,
      yearStateShow,
      setCreateState,
      createStateShow,
      kukesystemInfo,
      deadview,
      DeceasedMessagelistarr,
      getDeceasedMessageList,
      DeceasedFlowerlistarr,
      getDeceasedFlowerList,
      deceasedlistarr,
      getdeceasedlist,
    };
  },
});
</script>
<style>
.drop {
  align-items: center;
}
.drop_container {
  row-gap: 1rem;
  padding: 0 0 0.35rem;
  /* box-shadow: 4px 4px 16px #e1e1e1; */
}
.drop,
.drop_container {
  display: grid;
}
.drop_card {
  justify-content: space-between;
  padding: 0.45rem 0.1rem 0.35rem 0.4rem;
  background-color: #f8f8fc;
  box-shadow: 4px 4px 16px #e1e1e1, -2px -2px 16px #fff;
  border-radius: 2.5rem;
  cursor: pointer;
}
.drop_card,
.drop_data {
  position: relative;
  display: flex;
  align-items: center;
}
.drop_img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 1rem;
}
.drop_name {
  font-size: 0.6rem;
  color: #000;
  font-weight: 500;
  width: 81px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.level {
  display: block;
  position: absolute;
  top: -7.5px;
  left: -7.5px;
  width: 25px;
  z-index: 10;
}
.enWpSO {
  background: rgb(255, 255, 255);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  height: 100%;
}
.gJeVfO {
  background: rgb(238, 239, 247);
  border-radius: 12px 12px 0px 0px;
  position: relative;
  height: 45px;
  display: flex;
  flex-shrink: 0;
}
.fKJkBq {
  padding-left: 15px;
  font-size: 19px;
  font-weight: 500;
  line-height: 21px;
  z-index: 2;
  position: relative;
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
}
.TbVOA {
  position: relative;
}
.iOgTEN {
  padding: 17px 16px 14px 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.dGmCqD {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  min-width: 0px;
}
.itnWtT {
  color: rgb(255, 128, 60);
  word-break: normal;
  display: inline-block;
}
.kilzJR {
  width: 34px;
  height: 34px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 100%;
}
.btHDII {
  font-size: 12px;
  font-weight: 500;
  color: rgb(21, 22, 31);
  line-height: 21px;
  -webkit-box-align: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dlsvNH {
  font-size: 11px;
  font-weight: 400;
  color: rgb(175, 177, 188);
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hCsBbL {
  font-size: 12px;
  font-weight: 500;
  color: rgb(110, 114, 122);
  line-height: 17px;
  margin: 0px 9px 0px 8px;
  word-break: normal;
}
.HotTopic_wrap
  .n-pagination
  .n-pagination-item:not(.n-pagination-item--disabled).n-pagination-item--active {
  color: rgb(153, 51, 127) !important;
  border: 1px solid rgb(153, 51, 127) !important;
}
.HotTopic_wrap .n-pagination .n-pagination-item:hover {
  color: rgb(153, 51, 127) !important;
}
.search_ss {
  height: 33px;
  line-height: 33px;
  border: 1px solid rgb(153, 51, 127);
  padding: 0 9px;
  font-size: 14px;
}

.search_ss:focus {
  outline: #cdc8cf;
}
.search_btn {
  width: 50px;
  border: 1px solid rgb(153, 51, 127);
  color: rgb(153, 51, 127);
  background: transparent;
}

.marquee {
  width: 100%;
  height: 50px;
  align-items: center;
  color: #3a3a3a;
  background-color: #ffe4ca;
  display: flex;
  box-sizing: border-box;
}

.marquee_title {
  padding: 0 20px;
  height: 30px;
  font-size: 14px;
  border-right: 1px solid #fff;
  align-items: center;
}

.marquee_box {
  display: block;
  position: relative;
  width: 60%;
  height: 30px;
  overflow: hidden;
}

.marquee_list {
  display: block;
}
.marquee_top {
  transition: all 30s;
  margin-top: -30px;
}

.marquee_list p {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 20px;
  padding: 0 2px;
}
.n-card > .n-card-header .n-card-header__main {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
