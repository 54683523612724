<template>
  <div class="_wrap">
    <div class="_top">
      <div class="_topin">
        <div class="_content">
          <div
            class="keepfly-box-flex keepfly-box-alignCenter keepfly-box-justifyBetween _box"
          >
            <div class="keepfly-box-flex keepfly-box-alignCenter">
              <div class="keepfly-box-flex keepfly-box-alignCenter">
                <div class="_wrap _logo" onclick="javascript:history.back(-1);">
                  <img src="../assets/logo.png" style="width: 30px" />
                </div>
                <div class="_line">
                  <div class="keepfly-divider-main keepfly-divider-y">
                    <div class="keepfly-divider-shadow"></div>
                  </div>
                </div>
                <div class="_tit">{{ $t("message.tips138") }}</div>
              </div>
              <div
                class="keepfly-box-flex keepfly-box-alignCenter"
                style="margin-left: 16px; width: 20px"
                onclick="javascript:history.back(-1);"
              >
                <img src="../assets/left.png" style="width: 26px; height: 26px" />
              </div>
            </div>
            <div class="keepfly-box-flex keepfly-box-alignCenter">
              <n-select
                style="width: 108px"
                @update:value="handleSearch"
                v-model:value="selectedValue"
                status="warning"
                label-field="name"
                value-field="language"
                :theme-overrides="selectThemeOverrides"
                :options="arr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-wrapper">
      <div class="before-content">
        <div class="second-container second-container-normal J-second-head">
          <div class="layout">
            <a class="posterFlag excellent-icon" target="_blank"></a>
            <div class="lemma-title-wrapper">
              <dl class="lemmaWgt-lemmaTitle lemmaWgt-lemmaTitle-">
                <dd class="lemmaWgt-lemmaTitle-title J-lemma-title">
                  <span>
                    <h1>{{ deceasedInfo.dname }}</h1>
                  </span>
                </dd>
                <div class="lemma-desc" v-if="deceasedInfo.year">
                  {{ $t("message.date_of_death") }}：{{
                    deceasedInfo.year < 0
                      ? $t("message.B_C") + " " + Math.abs(deceasedInfo.year)
                      : deceasedInfo.year
                  }}{{ $t("message.year") }} {{ deceasedInfo.moon
                  }}{{ $t("message.moon") }} {{ deceasedInfo.day }}{{ $t("message.day") }}
                </div>
                <div class="lemma-desc" v-else>
                  {{ $t("message.date_of_death") }}：{{ $t("message.unknown") }}
                </div>
              </dl>
            </div>
            <div
              class="flower-sender flower-sender-dark"
              id="starFlower"
              @click="$router.push('/login')"
            >
              <div class="bg">
                <div class="flower-tip"></div>
                <em></em>
                <p class="numBox">
                  <i class="count deep-color">{{ deceasedInfo.fnum }}</i>
                </p>
              </div>
            </div>
            <div class="widge_tags">
              <div class="tag-items">
                <a v-for="item in TagTxtList" :key="item">{{ item.tagLabel }}</a>
              </div>
            </div>
            <div style="min-height: 162px">
              <div
                style="
                  max-width: 660px;
                  margin: 12px;
                  font-size: 14px;
                  padding-right: 31px;
                "
              >
                <n-ellipsis
                  expand-trigger="click"
                  line-clamp="7"
                  style="white-space: pre-wrap"
                  :tooltip="false"
                >
                  {{ deceasedInfo.achievement }}
                </n-ellipsis>
              </div>
              <img class="miaodong-logo" :src="deceasedInfo.brand" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="star-mod-top">
      <div class="star-top-widget star-relation" id="star" style="margin-top: 0">
        <div class="star-top-hd">
          <h2 style="margin: 0">{{ $t("message.tips71") }}</h2>
          <div class="module-paging fl">
            <!-- <a class="prev prev_off" href="javascript:;"></a> -->
            <a class="prev" @click="reducerelationship" href="javascript:;"></a>
            <a class="next" @click="addrelationship" href="javascript:;"></a>
          </div>
        </div>
        <div class="star-top-bd">
          <ul style="width: 448px">
            <li v-for="item in relationshipArrsubset" :key="item">
              <a :title="item.relationship + ' ' + item.fullName" style="padding-left: 0">
                <!-- <img :src="item.photo" /> -->
                <n-image :src="item.photo" />
                <div class="mask"></div>
                <p>{{ item.relationship }}&nbsp;{{ item.fullName }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="star-top-widget hot-album" id="hot" style="margin-top: 0">
        <div class="star-top-hd">
          <h2 style="margin: 0">{{ $t("message.album") }}</h2>
          <div class="module-paging fr">
            <a class="prev" href="javascript:;" @click="reduceImages"></a>
            <a class="next" href="javascript:;" @click="addImages"></a>
          </div>
        </div>
        <div class="star-top-bd">
          <ul style="width: 2800px">
            <li v-for="item in imagesArrsubset" :key="item">
              <a :title="item.title" data-log="bk_hottp1" style="padding-left: 0">
                <!-- <img :src="item.imgUrl" :alt="item.title" /> -->
                <n-image :src="item.imgUrl" :alt="item.title" />
                <div class="mask"></div>
                <p>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="star-top-widget praise-news">
        <div class="columns ctxx" style="padding: 13px 0 0 0">
          <h2 class="col-h2">{{ $t("message.deceased_admin") }}</h2>
          <dl class="col-dl twhp2">
            <dd>
              <a @click="$router.push('/login')" class="a-img1">
                <img
                  :src="infouser.avatar"
                  width="50"
                  height="50"
                  style="border-radius: 24px"
                />
              </a>
            </dd>
            <dd>
              <span class="gray9">{{ $t("message.admin") }}</span>
              <a class="s-msg clink">{{ infouser.nickname }}</a>
              <!-- <em class="f12">
                  <img src="https://renwu.rbaike.com/style/default/jb.gif" title="金币" />
                  4034</em
                > -->
            </dd>
            <dd>
              <span class="gray9">{{ $t("message.the_deceased") }}</span>
              <a class="s-msg clink">{{ deceasedInfo.dname }}</a>
            </dd>
          </dl>
          <ul class="col-ul" style="font-size: 14px; margin: 8px 6px 5px 25px">
            <li>
              <span class="gray" style="display: inline-block"
                >{{ $t("message.number_of_views") }}：</span
              >{{ infouser.bnum }}
            </li>
            <!-- <li>
              <span class="gray">{{ $t("message.update_time") }}：</span>2021-10-29
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="newslist-content clearfix">
      <div class="newslist-left newsdetail-left">
        <div class="border-bg">
          <div class="detail-content">
            <div class="module">
              <div class="mod-title" style="position: relative">
                <h3>{{ $t("message.basic_info") }}</h3>
              </div>
              <div class="mod-content">
                <ul class="basic-info clearfloat">
                  <li>
                    <b>{{ $t("message.full_name") }}：</b>{{ deceasedInfo.dname }}
                  </li>
                  <li v-if="deceasedInfo.alias">
                    <b>{{ $t("message.alias") }}：</b>{{ deceasedInfo.alias }}
                  </li>
                  <li v-if="deceasedInfo.byear">
                    <b>{{ $t("message.date_of_birth") }}：</b>
                    {{
                      deceasedInfo.byear < 0
                        ? $t("message.B_C") + " " + Math.abs(deceasedInfo.byear)
                        : deceasedInfo.byear
                    }}{{ $t("message.year") }} {{ deceasedInfo.bmoon
                    }}{{ $t("message.moon") }} {{ deceasedInfo.bday
                    }}{{ $t("message.day") }}
                  </li>
                  <li v-else>
                    <b>{{ $t("message.date_of_death") }}：</b>
                    {{ $t("message.unknown") }}
                  </li>
                  <li v-if="deceasedInfo.year">
                    <b>{{ $t("message.date_of_death") }}：</b>
                    {{
                      deceasedInfo.year < 0
                        ? $t("message.B_C") + " " + Math.abs(deceasedInfo.year)
                        : deceasedInfo.year
                    }}{{ $t("message.year") }} {{ deceasedInfo.moon
                    }}{{ $t("message.moon") }} {{ deceasedInfo.day
                    }}{{ $t("message.day") }}
                  </li>
                  <li v-else>
                    <b>{{ $t("message.date_of_death") }}：</b>
                    {{ $t("message.unknown") }}
                  </li>
                  <li>
                    <b>{{ $t("message.die_at_the_age_of") }}：</b
                    >{{ deceasedInfo.dieAge ? deceasedInfo.dieAge : "未知" }}
                  </li>
                  <li>
                    <b>{{ $t("message.occupation") }}：</b>{{ deceasedInfo.occupation }}
                  </li>
                  <li v-if="deceasedInfo.language">
                    <b>{{ $t("message.language") }}：</b>{{ deceasedInfo.language }}
                  </li>
                  <li v-if="deceasedInfo.nationality">
                    <b>{{ $t("message.nationality") }}：</b>{{ deceasedInfo.nationality }}
                  </li>
                  <li v-if="deceasedInfo.universities">
                    <b>{{ $t("message.university") }}：</b>{{ deceasedInfo.universities }}
                  </li>
                  <li v-if="deceasedInfo.height">
                    <b>{{ $t("message.height") }}：</b>{{ deceasedInfo.height }}
                    {{ deceasedInfo.heightunit }}
                  </li>
                  <li v-if="deceasedInfo.constellation">
                    <b>{{ $t("message.constellation") }}：</b
                    >{{ deceasedInfo.constellation }}
                  </li>
                  <li
                    v-if="deceasedInfo.motto"
                    style="white-space: break-spaces; line-height: inherit"
                  >
                    <b>{{ $t("message.motto") }}：</b>{{ deceasedInfo.motto }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newslist-right" style="margin-top: 10px">
        <div
          style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04)"
          class="wbpro-side keepfly-panel-main keepfly-panel-top keepfly-panel-right keepfly-panel-bottom keepfly-panel-left Card_wrap Card_bottomGap"
        >
          <div>
            <div>
              <div class="wbpro-side-tit keepfly-box-flex keepfly-box-alignCenter">
                <div class="f16 fm cla keepfly-box-item-flex" style="align-self: center">
                  {{ $t("message.interaction") }}
                </div>
              </div>
              <div class="keepfly-divider-main keepfly-divider-x"></div>
            </div>
          </div>
          <div>
            <div class="wbpro-side-card-3 keepfly-box-flex keepfly-box-column col-4">
              <div class="row keepfly-box-flex">
                <!-- <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    style="cursor: pointer"
                    @click="$router.push('/login')"
                  >
                    <div
                      class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                    >
                      <people-outline
                        class="keepfly-font keepfly-font--ccnavData SideCard3_icon SideCard3_c1"
                      ></people-outline>
                      <div
                        class="f12 cla wbpro-textcut SideCard3_name"
                        :title="$t('message.input_relationship')"
                      >
                        <n-popover trigger="hover">
                          <template #trigger>
                            {{ $t("message.input_relationship") }}
                          </template>
                          <span>{{ $t("message.input_relationship") }}</span>
                        </n-popover>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    style="cursor: pointer"
                    @click="$router.push('/login')"
                  >
                    <div
                      class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                    >
                      <library
                        class="keepfly-font keepfly-font--ccnavContent SideCard3_icon SideCard3_c2"
                      ></library>
                      <div
                        class="f12 cla wbpro-textcut SideCard3_name"
                        :title="$t('message.content_addition')"
                      >
                        <n-popover trigger="hover">
                          <template #trigger>
                            {{ $t("message.content_addition") }}
                          </template>
                          <span>{{ $t("message.content_addition") }}</span>
                        </n-popover>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    style="cursor: pointer"
                    @click="$router.push('/login')"
                  >
                    <div
                      class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                    >
                      <image-outline
                        class="keepfly-font keepfly-font--ccnavProfit SideCard3_icon SideCard3_c3"
                      ></image-outline>
                      <div
                        class="f12 cla wbpro-textcut SideCard3_name"
                        :title="$t('message.image_upload')"
                      >
                        <n-popover trigger="hover">
                          <template #trigger>
                            {{ $t("message.image_upload") }}
                          </template>
                          <span>{{ $t("message.image_upload") }}</span>
                        </n-popover>
                      </div>
                    </div>
                  </a>
                </div> -->
                <div class="keepfly-box-item-flex">
                  <a class="ALink_none" style="cursor: pointer">
                    <div
                      @click="$router.push('/login')"
                      class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                    >
                      <ellipsis-horizontal-circle
                        class="keepfly-font keepfly-font--ccnavFans SideCard3_icon SideCard3_c4"
                      ></ellipsis-horizontal-circle>
                      <div
                        class="f12 cla wbpro-textcut SideCard3_name"
                        :title="$t('message.message_board')"
                      >
                        <n-popover trigger="hover">
                          <template #trigger>
                            {{ $t("message.message_board") }}
                          </template>
                          <span>{{ $t("message.message_board") }}</span>
                        </n-popover>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="keepfly-box-item-flex">
                  <a
                    class="ALink_none"
                    @click="$router.push('/login')"
                    style="cursor: pointer"
                  >
                    <n-tooltip trigger="hover">
                      <template #trigger>
                        <div
                          :title="$t('message.message_management')"
                          class="keepfly-box-flex keepfly-box-column keepfly-box-alignCenter SideCard3_box"
                        >
                          <document-attach-outline
                            class="keepfly-font keepfly-font--ccnavFans SideCard3_icon SideCard3_c2"
                          ></document-attach-outline>
                          <div class="f12 cla wbpro-textcut SideCard3_name">
                            {{ $t("message.tips135") }}
                          </div>
                        </div>
                      </template>
                      {{ $t("message.tips135") }}
                    </n-tooltip>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="news-info border-bg">
          <div class="news-info-title">
            <div class="widget">
              <h3 style="position: relative">
                <span>{{ $t("message.deceased_label") }}</span>
                <div
                  @click="$router.push('/login')"
                  style="
                    position: absolute;
                    right: 14px;
                    top: 0;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  <create-outline
                    style="vertical-align: middle; width: 18px"
                  ></create-outline>
                  {{ $t("message.edit") }}
                </div>
              </h3>
              <div class="widge_tags">
                <div class="tag-items">
                  <a v-for="item in TagTxtList" :key="item">{{ item.tagLabel }}</a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="newslist-content clearfix" v-for="item in ContentArr" :key="item">
      <div class="newslist-left newsdetail-left">
        <div class="border-bg">
          <div class="detail-content">
            <div class="module">
              <div class="mod-title">
                <h3 v-if="item.modelName == '1'">
                  {{ $t("message.early_experiences") }}
                </h3>
                <h3 v-else-if="item.modelName == '2'">
                  {{ $t("message.deductive_experience") }}
                </h3>
                <h3 v-else-if="item.modelName == '3'">
                  {{ $t("message.personal_life") }}
                </h3>
                <h3 v-else-if="item.modelName == '4'">
                  {{ $t("message.award_records") }}
                </h3>
                <h3 v-else-if="item.modelName == '5'">
                  {{ $t("message.character_evaluation") }}
                </h3>
                <h3 v-else-if="item.modelName == '6'">
                  {{ $t("message.other") }}
                </h3>
                <h3 v-else>{{ item.modelName }}</h3>
                <!-- <div
                  @click="$router.push('/login')"
                  style="
                    position: absolute;
                    right: 14px;
                    top: 0;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  <create-outline
                    style="vertical-align: middle; width: 18px"
                  ></create-outline>
                  {{ $t("message.edit") }}
                </div> -->
              </div>
              <div class="mod-content" v-if="item.imgUrl">
                <p
                  style="
                    width: 70%;
                    float: left;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  {{ item.modelContent }}
                </p>
                <img
                  :src="item.imgUrl"
                  style="width: 220px; height: 231px; float: right"
                />
                <div style="clear: both"></div>
              </div>
              <div class="mod-content" v-else>
                <p style="white-space: pre-wrap">
                  {{ item.modelContent }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="newslist-content clearfix"
      style="
        height: 78px;
        line-height: 25px;
        padding: 10px;
        clear: both;
        text-align: center;
        color: gray;
      "
    >
      <p>{{ $t("message.tips108") }}</p>
      <p>{{ $t("message.tips101") }}</p>
    </div>
  </div>
</template>
<script>
const imagesColumns = ({ play }) => {
  return [
    {
      title: "#",
      key: "key",
      render: (_, index) => {
        return `${index + 1}`;
      },
    },
    {
      title: i18n.global.t("message.photograph"),
      render(row) {
        return h(NImage, {
          width: "100",
          src: row.imgUrl,
        });
      },
    },
    {
      title: i18n.global.t("message.title"),
      key: "title",
    },
    {
      title: i18n.global.t("message.operate"),
      key: "actions",
      render(row) {
        return h(
          NButton,
          {
            strong: true,
            tertiary: true,
            size: "small",
            onClick: () => play(row),
          },
          { default: () => "×" }
        );
      },
    },
  ];
};
const relationshipColumns = ({ play }) => {
  return [
    {
      title: "#",
      key: "key",
      render: (_, index) => {
        return `${index + 1}`;
      },
    },
    {
      title: i18n.global.t("message.photograph"),
      render(row) {
        return h(NImage, {
          width: "100",
          src: row.photo,
        });
      },
    },
    {
      title: i18n.global.t("message.full_name"),
      key: "fullName",
    },
    {
      title: i18n.global.t("message.relationship"),
      key: "relationship",
    },
    {
      title: i18n.global.t("message.operate"),
      key: "actions",
      render(row) {
        return h(
          NButton,
          {
            strong: true,
            tertiary: true,
            size: "small",
            onClick: () => play(row),
          },
          { default: () => "×" }
        );
      },
    },
  ];
};
const createColumns = ({ play }) => {
  return [
    {
      title: "#",
      key: "key",
      render: (_, index) => {
        return `${index + 1}`;
      },
    },
    {
      title: i18n.global.t("message.label"),
      key: "tagLabel",
    },
    {
      title: i18n.global.t("message.operate"),
      key: "actions",
      render(row) {
        return h(
          NButton,
          {
            strong: true,
            tertiary: true,
            size: "small",
            onClick: () => play(row),
          },
          { default: () => "×" }
        );
      },
    },
  ];
};
const options = [
  {
    label: i18n.global.t("message.early_experiences"),
    value: 1,
  },
  {
    label: i18n.global.t("message.deductive_experience"),
    value: 2,
  },
  {
    label: i18n.global.t("message.personal_life"),
    value: 3,
  },
  {
    label: i18n.global.t("message.award_records"),
    value: 4,
  },
  {
    label: i18n.global.t("message.character_evaluation"),
    value: 5,
  },
  {
    label: i18n.global.t("message.other"),
    value: 6,
  },
];
import { defineComponent, onMounted, ref, h } from "vue";
import { useRoute } from "vue-router";
import API from "../uilts/request";
import { useI18n } from "vue-i18n";
import {
  // Library,
  // Image as ImageOutline,
  // PeopleOutline,
  EllipsisHorizontalCircle,
  // CreateOutline,
  DocumentAttachOutline,
} from "@vicons/ionicons5";
import i18n from "../i18n/index";
import { NEllipsis, NImage, NPopover, NButton, NSelect, NTooltip } from "naive-ui";
export default defineComponent({
  name: "DeadView",
  components: {
    // ImageOutline,
    // Library,
    // PeopleOutline,
    EllipsisHorizontalCircle,
    // CreateOutline,
    NEllipsis,
    NPopover,
    NSelect,
    DocumentAttachOutline,
    NTooltip,
    NImage,
  },
  setup() {
    let route = useRoute();
    const id = route.params.id;
    onMounted(() => {
      getDeceasedInfo();
      getTagTxtList();
      getRelationshiplist();
      getImageslist();
      getContentList();
      getDeceasedAdmin();
      getKukeLanguage();
    });
    const infouser = ref({});
    const getDeceasedAdmin = () => {
      let param = {
        id: id,
      };
      API({
        url: "/kuke/api/index/getDeceasedAdmin",
        method: "POST",
        data: param,
      }).then((result) => {
        infouser.value = result.data.data;
      });
    };
    const deceasedInfo = ref({});
    const getDeceasedInfo = () => {
      let param = {
        id: id,
      };
      API({
        url: "/kuke/api/index/getdeceasedInfo",
        method: "POST",
        data: param,
      }).then((result) => {
        deceasedInfo.value = result.data.data;
      });
    };

    const deceasedbrand_file = ref();
    const djFileView = () => {
      deceasedbrand_file.value.value = "";
      deceasedbrand_file.value.click();
    };
    const TagTxtList = ref([]);
    const getTagTxtList = () => {
      API({
        url: "/kuke/api/index/getTagTxtList",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        TagTxtList.value = result.data.data;
      });
    };
    const relationshipArr = ref([]);
    const relationshipArrsubset = ref([]);
    const relationshipInfo = ref({});
    const getRelationshiplist = () => {
      API({
        url: "/kuke/api/index/getRelationshiplist",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        relationshipArr.value = result.data.data;
        relationshipArrsubset.value = result.data.data;
      });
    };
    const inum = ref(0);
    const addrelationship = () => {
      if (inum.value < relationshipArr.value.length % 3) {
        inum.value++;
      }
      relationshipArrsubset.value = relationshipArr.value.slice(
        3 * inum.value,
        3 + inum.value * 3
      );
    };
    const reducerelationship = () => {
      if (inum.value > 0) {
        inum.value--;
      }
      relationshipArrsubset.value = relationshipArr.value.slice(
        3 * inum.value,
        3 + inum.value * 3
      );
    };

    const imagesArr = ref([]);
    const imagesArrsubset = ref([]);
    const getImageslist = () => {
      API({
        url: "/kuke/api/index/getImageslist",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        imagesArr.value = result.data.data;
        imagesArrsubset.value = result.data.data;
      });
    };
    const iImagesnum = ref(0);
    const addImages = () => {
      if (iImagesnum.value < imagesArr.value.length % 4) {
        iImagesnum.value++;
      }
      imagesArrsubset.value = imagesArr.value.slice(
        4 * iImagesnum.value,
        4 + iImagesnum.value * 4
      );
    };
    const reduceImages = () => {
      if (iImagesnum.value > 0) {
        iImagesnum.value--;
      }
      imagesArrsubset.value = imagesArr.value.slice(
        4 * iImagesnum.value,
        4 + iImagesnum.value * 4
      );
    };
    const ContentArr = ref([]);
    const getContentList = () => {
      API({
        url: "/kuke/api/index/getContentList",
        method: "POST",
        data: {
          id: id,
        },
      }).then((result) => {
        ContentArr.value = result.data.data;
      });
    };

    const pagetotal = ref(0);
    const currPage = ref(0);
    const pageSize = ref(8);
    const deceasedonlineshow = ref(false);
    const deceasedonlineArr = ref([]);
    const getdeceasedonlineView = () => {
      let param = {
        id: id,
        currPage: currPage.value,
        pageSize: pageSize.value,
      };
      API({
        url: "/kuke/api/user/getdeceasedonlinelist",
        method: "POST",
        data: param,
      }).then((res) => {
        deceasedonlineArr.value = res.data.data.records;
        currPage.value = res.data.data.current;
        pagetotal.value = res.data.data.pages;
        deceasedonlineshow.value = true;
      });
    };
    const pagingdeceasedonlineNum = (e) => {
      currPage.value = e;
      getdeceasedonlineView();
    };
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }
    const langlocal = ref(localStorage.getItem("language"));
    const { locale } = useI18n();
    const changeLang = (value) => {
      locale.value = value;
      localStorage.setItem("language", value);
      location.reload();
    };
    const arr = ref([]);
    const getKukeLanguage = () => {
      API({
        url: "/kuke/api/index/getKukeLanguage",
        method: "GET",
      }).then((result) => {
        arr.value = JSON.parse(JSON.stringify(result.data.data));
      });
    };
    const selectThemeOverrides = {
      Select: {
        peers: {
          InternalSelection: {
            textColor: "#FF0000",
          },
        },
      },
    };
    return {
      selectThemeOverrides,
      arr,
      selectedValue: langlocal,
      handleSearch: (value) => {
        changeLang(value);
      },
      infouser,
      pagingdeceasedonlineNum,
      currentPage: currPage,
      pagenum: pagetotal,
      deceasedonlineArr,
      getdeceasedonlineView,
      deceasedonlineshow,
      ContentArr,
      getContentList,
      options,
      addImages,
      reduceImages,
      imagesArr,
      getImageslist,
      imagesArrsubset,
      reducerelationship,
      addrelationship,
      imagescolumns: imagesColumns({
        play(row) {
          API({
            url: "/kuke/api/user/deleteImagesInfo",
            method: "POST",
            data: {
              id: row.id,
            },
          }).then(() => {
            getImageslist();
          });
        },
      }),
      relationshipcolumns: relationshipColumns({
        play(row) {
          API({
            url: "/kuke/api/user/deleterelationshipInfo",
            method: "POST",
            data: {
              id: row.id,
            },
          }).then(() => {
            getRelationshiplist();
          });
        },
      }),
      getRelationshiplist,
      relationshipArrsubset,
      relationshipArr,
      relationshipInfo,
      TagTxtList,
      getTagTxtList,
      columns: createColumns({
        play(row) {
          API({
            url: "/kuke/api/user/deleteTagTxtInfo",
            method: "POST",
            data: {
              id: row.id,
            },
          }).then(() => {
            getTagTxtList();
          });
        },
      }),
      pagination: false,
      deceasedbrand_file,
      djFileView,
      deceasedInfo,
      bodyStyle: {
        width: "600px",
        height: "100%",
      },
      segmented: {
        content: "soft",
        footer: "soft",
      },
    };
  },
});
</script>
<style>
._wrap {
  min-height: 100%;
  height: 100%;
}
.keepfly-box-column {
  flex-direction: column;
}
.keepfly-box-flex {
  display: flex;
}
._top {
  margin-bottom: 3.5rem;
}
._topin {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.75rem;
  background: #f9f9f9;
  border-top: 2px solid #ff8200;
  box-sizing: border-box;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
}
._content {
  width: 70rem;
  height: 100%;
  margin: auto;
}
._box {
  height: 100%;
}
.keepfly-box-justifyBetween {
  justify-content: space-between;
}
.keepfly-box-alignCenter {
  align-items: center;
}
._logo {
  width: 1.625rem;
  height: 1.875rem;
  cursor: pointer;
}
._line {
  height: 1.25rem;
  margin: 0 0.625rem;
}
.keepfly-divider-y {
  display: inline-block;
  width: 0;
  height: 100%;
  position: relative;
}
.keepfly-divider-main {
  color: #f9f9f9;
}
._avatar {
  cursor: pointer;
}
.keepfly-avatar-main {
  display: inline-block;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  font-size: 1rem;
}
.keepfly-avatar-main:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 9;
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);
}
.keepfly-avatar-img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
._name {
  margin-left: 0.5rem;
  font-size: 0.875rem;
}
.body-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.second-container.second-container-normal {
  background-color: #f1ebe9;
  background-position: center bottom;
  background-size: 100% auto;
  margin-bottom: -20px;
}
.second-container {
  margin-bottom: -270px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 1440px auto;
  background-color: #f5f5f5;
  z-index: 0;
}

body.wiki-lemma .layout {
  width: 1139px;
}
.second-container .layout {
  position: relative;
  padding: 30px 0;
  z-index: 2;
}
body.w-small .layout,
.layout {
  width: 980px;
}
.layout {
  margin: 0 auto;
}
body.w-small .layout,
.layout {
  width: 980px;
}
.layout {
  margin: 0 auto;
}
.large-feature .posterFlag.excellent-icon,
.J-second-head .posterFlag.excellent-icon {
  width: 50px;
  height: 100px;
  background: url(../assets/aside-flag-excellent_fa9b2e5.png) no-repeat 0 0;
  background-size: cover;
}
.large-feature .posterFlag,
.J-second-head .posterFlag {
  left: -48px;
  top: -1px;
}
.posterFlag.excellent-icon {
  position: absolute;
  width: 45px;
  height: 90px;
  background-size: cover;
  top: 5px;
  left: -45px;
}
.posterFlag {
  display: block;
  z-index: 1;
}
.second-container .lemma-title-wrapper {
  margin-top: -3px;
  margin-bottom: 24px;
  padding-left: 30px;
}
.second-container-normal .lemmaWgt-lemmaTitle {
  width: auto;
  padding-right: 82px;
}
.lemmaWgt-lemmaTitle {
  margin: 0 0 10px;
  width: 700px;
}
.lemmaWgt-lemmaTitle .lemmaWgt-lemmaTitle-title {
  margin: 0 0 5px;
  line-height: 40px;
  margin-bottom: -8px;
  position: relative;
}
.lemmaWgt-lemmaTitle .lemmaWgt-lemmaTitle-title h1 {
  margin: 0 10px 0 0;
  color: #000;
  display: inline;
  font-size: 34px;
  line-height: 1.15;
  font-weight: 400;
  vertical-align: sub;
  *vertical-align: text-bottom;
  word-break: break-all;
  position: relative;
  z-index: 2;
}
.second-container .lemma-title-wrapper h1 {
  font-size: 30px !important;
  font-weight: 500;
}
.lemmaWgt-lemmaTitle .btn-list {
  font-size: 0;
  display: inline-flex;
  position: relative;
  top: -5px;
  z-index: 2;
}
.second-container-normal .cmn-btn-hover-blue {
  background: #fcfeff;
  box-shadow: 0 1px 1px 0 #bfd6f2;
  border-radius: 4px;
  border-color: #fcfeff !important;
}

.body-wrapper a {
  color: #136ec2;
  text-decoration: none;
}
.cmn-btn-hover-blue {
  display: inline-block;
  zoom: 1;
  color: #666;
  border: solid 1px #e2e2e2;
  border-radius: 2px;
  background: #fff;
  text-align: center;
  line-height: 2;
  padding: 4px 4px 3px;
  font-size: 12px;
}
.body-wrapper .title-audio-play em {
  margin: 0 2px 0 0;
  line-height: 12px;
  font-size: 15px;
  vertical-align: text-top;
  color: #459df5;
  vertical-align: -3px;
}
.cmn-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  outline: 0;
}
.wiki-lemma-icons {
  font-weight: 400;
  font-style: normal;
}
.body-wrapper .title-audio-play {
  position: relative;
  display: inline-block;
  -moz-box-orient: vertical;
  display: inline-block;
  zoom: 1;
  display: inline;
  margin: 0 6px 0 0;
  border-color: #ccc;
  border-radius: 4px;
  outline: 0;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #666;
}

.body-wrapper .title-audio-play:hover {
  padding: 7px 10px;
  border: 0;
  color: #fff;
  background: #459df5;
  text-decoration: none;
}

.body-wrapper .title-audio-play:hover em {
  color: #fff;
}

.body-wrapper .title-audio-play em {
  margin: 0 2px 0 0;
  line-height: 12px;
  font-size: 15px;
  vertical-align: text-top;
  color: #459df5;
  vertical-align: -3px;
}

.lemmaWgt-lemmaTitle-light .title-audio-play {
  padding: 7px 10px;
  border: 0;
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.lemmaWgt-lemmaTitle-light .title-audio-play:hover {
  background: rgba(82, 163, 245, 0.7);
}

.lemmaWgt-lemmaTitle-light .title-audio-play em {
  color: #fff;
}

.lemmaWgt-lemmaTitle-dark .title-audio-play {
  padding: 7px 10px;
  border: 0;
  background: rgba(255, 255, 255, 0.7);
}

.lemmaWgt-lemmaTitle .edit-lemma,
.lemmaWgt-lemmaTitle .lemma-discussion {
  position: relative;
  -moz-box-orient: vertical;
  zoom: 1;
  margin: 0 6px 0 0;
  border-color: #ccc;
  border-radius: 4px;
  outline: 0;
  height: 32px;
  line-height: 25px;
  font-size: 12px;
  color: #666;
}
.lemmaWgt-lemmaTitle .lemma-desc {
  font-size: 16px;
  color: #626675;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}
.second-container .flower-sender:hover {
  outline: 0;
}
.second-container .flower-sender {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: transparent;
}
.flower-sender-dark:hover {
  box-shadow: 0 0 0;
}
.flower-sender {
  position: relative;
  min-width: 81px;
  float: right;
  height: 73px;
  text-align: center;
  background: #fff;
  cursor: pointer;
  z-index: 100;
  font-size: 12px;
  font-family: "Microsoft Yahei";
}
.flower-sender .bg {
  display: inline-block;
  min-width: 52px;
  text-align: center;
  box-shadow: none;
  padding: 46px 15px 6px;
  position: relative;
}
.flower-sender .flower-tip {
  width: 36px;
  height: 25px;
  position: absolute;
  background-image: url(../assets/flower-tip_8d377fd.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: -5px;
  right: -6px;
  z-index: 100;
}
.flower-sender .bg em {
  display: block;
  font-style: normal;
  width: 55px;
  height: 45px;
  background-size: cover;
  background-image: url(../assets/flower-list_0c7a140.png);
  background-repeat: no-repeat;
  background-position: 0 center;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -26px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: none;
  transition: none;
  opacity: 0.85;
}
.flower-sender .bg .numBox {
  width: 120px;
  height: 18px;
  position: absolute;
  left: 50%;
  margin-left: -60px;
  margin-top: 6px;
  text-align: center;
}
.flower-sender .bg .numBox .count.deep-color {
  color: #ff546e;
}
.flower-sender .bg .numBox .count {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  line-height: 12px;
  font-weight: 500;
}
.flower-sender .bg i {
  display: block;
  font-style: normal;
  font-size: 16px;
  color: #f18167;
  line-height: 21px;
}
.second-container .second-wrapper {
  padding-left: 30px;
}
.video-list-container {
  position: relative;
  max-width: 846px;
  height: 153px;
}

.second-container .miaodong-logo {
  width: auto;
  height: 360px;
  position: absolute;
  bottom: -40px;
  right: -53px;
}

.star-mod-top {
  background: #fff;
  border-bottom: none;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 0 0 30px;
  position: relative;
  width: 1108px;
  top: 0;
  border-radius: 12px;
}
.star-top-widget {
  float: left;
  margin: 8px 20px 0 0;
}
.star-relation {
  height: 200px;
  width: 321px;
  margin-top: 18px;
}
.hot-album,
.star-brand {
  height: 200px;
  width: 431px;
  margin-top: 18px;
}
.star-top-hd {
  height: 60px;
  position: relative;
}
.star-top-hd h2,
.star-top-hd .title {
  color: #222;
  font-size: 20px;
  line-height: 60px;
}
.star-mod-top .module-paging {
  border: 1px solid #d1d1d1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  height: 21px;
  position: absolute;
  right: 0;
  top: 21px;
  width: 48px;
}
.star-mod-top .module-paging .prev_off {
  background-position: -75px -30px;
}
.star-mod-top .module-paging .prev {
  border-right: 1px solid #e5e5e6;
}
.star-mod-top .module-paging a {
  background: url(../assets/t0180ec38896600d3eb.gif) no-repeat -50px -30px;
  float: left;
  height: 19px;
  width: 23px;
}
.star-mod-top .module-paging .next {
  background-position: -50px -50px;
}
.star-mod-top .module-paging a {
  background: url(../assets/t0180ec38896600d3eb.gif) no-repeat -50px -30px;
  float: left;
  height: 19px;
  width: 23px;
}
.star-top-bd {
  height: 140px;
  overflow: hidden;
  position: relative;
}
.star-top-bd ul {
  left: 0;
  position: absolute;
  top: 0;
}
.star-top-bd li:first-child {
  margin: 0 !important;
}
.star-top-bd li:first-child {
  margin: 0 !important;
}
.star-top-bd li {
  border: 1px solid #d9e0e7;
  float: left;
  height: 140px;
  margin-left: 10px;
  position: relative;
  width: 100px;
}
.star-top-bd img {
  height: 138px;
  width: 100px;
}
.star-top-bd .mask {
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.star-top-bd p,
.star-top-bd .mask {
  bottom: 0;
  color: #fff;
  height: 22px;
  left: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  width: 100px;
  font-size: 12px;
}
.news-hd {
  color: #222;
  font-size: 18px;
  line-height: 46px;
}
.news-bd ul {
  padding-top: 2px;
  width: 235px;
}

.columns {
  width: 264px;
  display: block;
  line-height: 22px;
  margin-top: 8px;
  padding: 13px 0 10px 0;
}
.bor-ccc,
.columns,
.bor-c_dl dl {
  border: 1px #e9e9e9 solid;
  background-color: #fff;
}
.col-h2 {
  font-size: 20px;
  line-height: 22px;
  padding: 0 0 12px 0;
  border-bottom: #e9e9e9 solid 1px;
  margin: 0 25px 10px 25px;
}

dl {
  margin-top: 0;
  margin-bottom: 21px;
}
dd {
  margin-left: 0;
}
dd,
dt {
  line-height: 1.5;
}
.twhp2 .a-img1 {
  margin-left: -60px;
  margin-top: 4px;
  float: left;
}
img {
  vertical-align: middle;
}
.col-dl dt {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 100;
}
.ctxx .twhp2 > dt > a {
  display: inline-block;
  min-width: 50%;
}
.twhp2 em {
  font-style: normal;
  display: inline-block;
}
.f12 {
  font-size: 12px;
}
.ctxx .twhp2 > dd > .gray9 {
  display: inline-block;
  width: 50%;
}
.gray9 {
  color: #999;
}
.gray {
  color: #666;
}
.u-stars {
  display: inline-block;
}
.doc-aside.group2 .col-ul {
  margin-bottom: 0;
}
.ctxx .col-ul {
  border-top: #e9e9e9 solid 1px;
  margin-top: 0;
  padding-top: 15px;
}
.col-ul,
.col-dl,
.col-p {
  margin: 8px 6px 12px 25px;
  padding: 0 0 3px 0;
}
.twhp2 {
  margin-left: 85px;
  min-height: 60px;
}
.doc-aside.group2 .col-ul > li {
  margin-bottom: 5px;
}
.col-ul li {
  clear: both;
  list-style: none;
}

.newslist-content {
  width: 1108px;
  margin: 0 auto;
}
.newslist-content .newslist-left {
  width: 812px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.newslist-content .newslist-right {
  width: 280px;
  float: right;
}
.border-bg {
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
}
.newsdetail-left .detail-header {
  border-bottom: 0;
}
.newsdetail-left .detail-header {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #eee;
}
.newsdetail-left .detail-content {
  padding: 20px 10px 0px;
}
.newsdetail-left .detail-page {
  padding: 20px;
  height: 104px;
}
.news-info {
  width: 280px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.newsdetail-left .detail-header .detail-title h1 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.widget {
  margin-bottom: 10px;
  clear: both;
  position: relative;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  box-shadow: 0 5px 10px 0 rgba(146, 146, 146, 0.1);
  padding: 22px;
}
.widget h3 {
  font-size: 18px;
  color: #282828;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 18px;
  margin-bottom: 28px;
  position: relative;
}
.widget h3:after {
  content: "";
  background-color: #ff8200;
  left: 0;
  width: 50px;
  height: 2px;
  bottom: -2px;
  position: absolute;
}
.widge_tags a {
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 17px;
  margin: 0 10px 7px 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.66);
  background-color: rgba(255, 255, 255, 0.97);
}

.module {
  position: relative;
}
.module .mod-title h3 {
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #333;
  padding: 0;
  margin: 0;
}
.module .mod-title h3:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 18px;
  margin-right: 6px;
  background-color: #ff8200;
  float: left;
}
.module .mod-content {
  margin-top: 20px;
  color: #333;
  padding: 0px 14px 28px;
}
.basic-info,
.details-info {
  overflow: hidden;
}
.clearfloat {
  zoom: 1;
}
.basic-info li,
.goods-list li {
  width: 50%;
  line-height: 35px;
  color: #333;
  border-bottom: 1px dashed #dadada;
  white-space: nowrap;
  float: left;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.basic-info-mask,
.details-info-mask {
  line-height: 40px;
  position: relative;
  text-align: center;
}
.wbpro-side-card-3.col-4 {
  padding: 24px 14px 14px;
}
.SideCard3_c1 {
  background: rgba(105, 138, 246, 0.1);
  color: #698af6;
}
.SideCard3_c2 {
  background: rgba(79, 201, 230, 0.1);
  color: #4fc9e6;
}
.SideCard3_c3 {
  background: rgba(255, 189, 4, 0.1);
  color: #ffbd04;
}
.SideCard3_c4 {
  background: rgba(255, 130, 0, 0.1);
  color: #ff8200;
}
.SideCard3_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 26px;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.wbpro-side-card-3 .wbpro-textcut {
  margin-top: 8px;
}
.wbpro-side-opt {
  height: 38px;
}
.SideCard3_btn {
  margin-top: 22px;
  background: #f9f9f9;
  border-radius: 4px;
}
.SideCard3_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 48px;
}
.basic-info b {
  width: auto;
}
</style>
